import React, { useState, createContext } from "react";

export const StoreContext = createContext({});

export const StoreContextProvider = ({ children }) => {
  const [information, setInformation] = useState({
   partySize: null,
   bookDateStr: null,
   sessionId: null,
  });
  const [tableInformation, setTableInformation] = useState({
    selectedTables: null
  });

  const [contact, setContact] = useState({
    name: null,
    email: null,
    mobilePhone: null,
    specialRequirement: null
  });

  const [nova, setNova] = useState({
    quantidade_funcionarios: undefined,
    nome_rh: undefined,
    email_rh: undefined,
    telefone_rh: undefined
  });

  const [conveniada, setConveniada] = useState({
    cheque: undefined,
    rg: undefined
  });

  const store = {
    information: [information, setInformation],
    tableInformation: [tableInformation, setTableInformation],
    contact: [contact, setContact],
    nova: [nova, setNova],
    conveniada: [conveniada, setConveniada]
  };
 // console.log(store);
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
