import React, { useContext, useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  StepConnector,
  CircularProgress,
} from "@material-ui/core";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Checkbox from "@material-ui/core/Checkbox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import moment from "moment";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Check } from "@material-ui/icons";

import Information from "./Information";
import TableSelect from "./TableSelect";
import { StoreContext } from "./Context/store";
import Contact from "./Contact";

import { useHistory } from "react-router-dom";
import {
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import API from "util/API";
import constants from "util/constants";
import {  useDispatch } from "react-redux";
import { loadStripe } from '@stripe/stripe-js/pure';
import { saveBookingSummary } from 'util/utils';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  instructions2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "10vw",
    marginRight: "7vw",
  },
  buttonLayout: {
    marginLeft: "7rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#2A8BFF",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#2A8BFF",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#2A8BFF",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#2A8BFF",
    zIndex: 1,
    fontSize: 20,
  },
  progress: {
    position: "absolute",
  },
  footer: {
    marginTop: 16,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const informationSchema = yup.object().shape({
  partySize: yup
    .number()
    .required("Please select a party size")
    .positive()
    .integer(),
  bookDateStr: yup.string().required("Please select a valid date"),
  sessionId: yup.string().required("Please select a time slot"),
});

const selectTableSchema = yup.object().shape({
  selectedTables: yup
    .array()
    .of(yup.string())
    .required("Please select a table"),
});

const contactSchema = yup.object().shape({
  name: yup.string().required("Please enter your name"),
  email: yup.string().required("Please enter your email"),
});

const novaSchema = yup.object().shape({
  quantidade_funcionarios: yup.string().required("Este campo é obrigatório."),
  nome_rh: yup.string().required("Este campo é obrigatório."),
  email_rh: yup.string().required("Este campo é obrigatório."),
  telefone_rh: yup.string().required("Este campo é obrigatório."),
});

function getSteps() {
  return ["Time", "Table", "Contact", "Review"];
}

const StepperForm = (props) => {
  const { restaurantId } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const [buttonLoading, setButtonLoading] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [checkTerms, setCheckTerms] = useState(false);
  const [restaurant, setRestaurant] = useState(null);

  console.log("stripe2")

  const getBookingAvailableSessions = async (inputData) => {
    try {
      const { partySize, bookDateStr } = inputData;

      API.defaults.headers.common["clientId"] = constants.CLIENT_ID;
      const response = await API.get(
        `/restaurantBookingSessions?restaurantId=${restaurantId}&dateStr=${bookDateStr}&partySize=${partySize}`
      );
      const data = await response.data;
      if (data && data.sessions) {
        // console.log('sessions', data);
        setSessions(data.sessions);
        setRestaurant(data.restaurant)
        if (data.sessions.length === 0) {
          showError("Cannot find a table");
        }
      }
      setButtonLoading(false);
    } catch (err) {
     // console.log(`error response=${JSON.stringify(err.response)}, error message=${err.message}`);
      setButtonLoading(false);
      if(err.response && err.response.status === 400 && err.response.data && err.response.data.message) {
        showError(err.response.data.message);
      } else {
        showError("Cannot find a table");
      }
      
    }
  };

  useEffect(() => {}, [props]);

  const { information, tableInformation, contact, nova } = useContext(
    StoreContext
  );
  const informationForm = useForm({
    validationSchema: informationSchema,
  });

  const selectTablesForm = useForm({
    validationSchema: selectTableSchema,
  });

  const contactForm = useForm({
    validationSchema: contactSchema,
  });

  const novaForm = useForm({
    validationSchema: novaSchema,
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = (e) => {
    information[1]({});
    tableInformation[1]({});
    contact[1]({});
    nova[1]({});
    setActiveStep(0);

    let data = { information, tableInformation, contact, nova };
    //console.log(data);
    //alert(JSON.stringify(data));

    // axios
    //   .post("https://jsonplaceholder.typicode.com/posts", data)
    //   .then(response => {
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  };

  const createNewBooking = async () => {
    try {
      const booking = {
        ...information[0],
        partySize: Number(information[0].partySize),
        tables: tableInformation[0].selectedTables,
        ...contact[0],
        startTime: selectedSession.startTime,
        endTime: selectedSession.endTime,
        restaurant
      };
      API.defaults.headers.common["clientId"] = constants.CLIENT_ID;
      const response = await API.post(`/booking`, {
        restaurantId,
        bookingSession: selectedSession,
        booking
      });
      const data = await response.data;
     // console.log("response data", data);

      if (data.sessionId) {
        saveBookingSummary({...booking, id: data.bookingId, hideTable: selectedSession.hideTableNo === true}, restaurantId );
        const stripe = await loadStripe(process.env.REACT_APP_stripe_key, {stripeAccount: data.stripeAccount});;
        const result = await stripe.redirectToCheckout({
            sessionId: response.data.sessionId,
        });

        if (result.error) {
            console.error(result.error);
            showError("Something went wrong, please try again");
        }

    } else {
      if (data && data.bookingId) {
        setBookingId(data.bookingId);
        handleNext();
      }
      await setButtonLoading(false);
    }
      
    } catch (err) {
      setButtonLoading(false);
      console.log(JSON.stringify(err));
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errorCode === 999
      ) {
        showError(err.response.data.error);
      } else {
        showError("Cannot find a table");
      }
    }
  };

  const onSubmit = (data) => {
    //console.log("data", data, "activeStep", activeStep);
    if (activeStep === 0) {
      information[1](data);
      setButtonLoading(true);
      if (!data.sessionId) {
        getBookingAvailableSessions(data);
      } else {
        const idx = sessions.findIndex(
          (session) => session.id === data.sessionId
        );
        setButtonLoading(false);
        console.log("session idx", idx);
        if (idx > -1) {
          setSelectedSession(sessions[idx]);
          const selectedTime = moment(
            `${data.bookDateStr} ${sessions[idx].startTime}`,
            "YYYY-MM-DD HH:mm"
          );
          if (selectedTime.isBefore(new moment())) {
            showError("Please select a future time");
          } else {
            handleNext();
          }
        } else {
          showError("Please select a time slot");
        }
      }
    } else if (activeStep === 1) {
      tableInformation[1](data);
      handleNext();
    } else if (activeStep === 2) {
      contact[1](data);
      handleNext();
    } else if (activeStep === 3) {
      if (!checkTerms) { 
        showError("Please agree the terms and conditions.");
      } else {
        setButtonLoading(true);
        createNewBooking();
      }
      
    }
  };

  function getStepContent(stepIndex) {
    if (stepIndex === 0) {
      return (
        <Information
          formProps={informationForm}
          data={information}
          sessions={sessions}
        />
      );
    } else if (stepIndex === 1) {
      if (sessions.length > 0) {
        return (
          <TableSelect
            formProps={selectTablesForm}
            data={tableInformation}
            session={selectedSession}
            partySize={information[0].partySize}
          />
        );
      } else {
        return "Please select another date.";
      }
    } else if (stepIndex === 2) {
      return <Contact formProps={contactForm} data={contact} />;
    } else if (stepIndex === 3 || stepIndex === 4) {
    } else {
      return "Something went wrong! Please try again.";
    }
  };

  const handleTermsAgreeChange = (event) => {
    setCheckTerms(!checkTerms);
  };

  return (
    <div className={classes.root}>
      <form
        onSubmit={
          activeStep === 0
            ? informationForm.handleSubmit(onSubmit)
            : activeStep === 1
            ? selectTablesForm.handleSubmit(onSubmit)
            : activeStep === 2
            ? contactForm.handleSubmit(onSubmit)
            : activeStep === 3
            ? novaForm.handleSubmit(onSubmit)
            : onSubmit()
        }
      >
        {/*Render stepper bar */}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div>
          {bookingId ? (
            <div>
              <Typography  variant="h5" color="primary" className={classes.instructions2}>
                Your reservation is confirmed and the number is{" "}
                {bookingId.substr(bookingId.length - 6)}
              </Typography>
            </div>
          ) : null}
          {activeStep === steps.length - 1 || activeStep === steps.length ? (
            <div align="inherit">
              <Typography align="inherit" className={classes.instructions2}>
             
                <ul>
                <li class="list-group-item">
                <b>Restaurant Name: </b>{restaurant.name}
                  </li>

                  <li class="list-group-item">
                    <b>Address: </b> {restaurant.address}
                  </li>


                  <li class="list-group-item">
                    <b>Phone: </b>{restaurant.phone}
                  </li>
                  <li class="list-group-item">
                    <b>Date: </b> {information[0].bookDateStr}
                  </li>

                  <li class="list-group-item">
                    <b>Time:</b>{" "}
                    {`${selectedSession.startTime} - ${selectedSession.endTime}`}
                  </li>

                   { selectedSession.hideTableNo === true ? null :
                  <li class="list-group-item">
                    <b>Tables:</b>{" "}
                    {tableInformation[0].selectedTables
                      .map((table) => `#${table.tableNumber}`)
                      .join(", ")}
                  </li>
               }

                  <li class="list-group-item">
                    <b>Party Size: </b> {information[0].partySize}
                  </li>
                  
                  <li class="list-group-item">
                    <b>Guest Name:</b> {contact[0].name}
                  </li>

                  <li class="list-group-item">
                    <b>Email:</b> {contact[0].email}
                  </li>

                  <li class="list-group-item">
                    <b>Mobile Phone:</b>
                    {contact[0].mobilePhone}
                  </li>
                  {contact[0].specialRequirement && contact[0].specialRequirement !== '' ? 
                     <li class="list-group-item">
                     <b>Special Requirements:</b>
                     {contact[0].specialRequirement}
                   </li> : null
                  }
                </ul>
                <br />
              </Typography>

            {activeStep === steps.length - 1 ?
            <>
            <FormControl component="fieldset">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkTerms}
                          onChange={(e) =>
                            handleTermsAgreeChange(e)
                          }
                          name='checkTerms'
                        />
                      }
                      label='I accept terms and conditions.'
                    />
                  </FormGroup>
                
            </FormControl>
            <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                variant="outlined"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={buttonLoading}
              >
                Confirm
                {buttonLoading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
              </>
            : null}
       
             
            </div>
          ) : activeStep === 1 && sessions.length === 0 ? (
            <div className={classes.buttonLayout}>
              <Typography className={classes.instructions}>
                Please select another date. <br />
              </Typography>
              <Button variant="contained" color="primary" onClick={handleReset}>
                Back
              </Button>
            </div>
          ) : (
            <div>
              <div className={classes.instructions}>
                {getStepContent(activeStep)}
              </div>
              <div className={classes.buttonLayout}>
                {activeStep !== steps.length ? (
                  <>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                      variant="outlined"
                      disabled={buttonLoading || activeStep === 0}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={buttonLoading}
                    >
                      {activeStep === steps.length - 1 ? "Confirm" : "Next"}

                      {buttonLoading && (
                        <CircularProgress
                          size={30}
                          className={classes.progress}
                        />
                      )}
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
          )}
         
        </div>
      </form>
    
    </div>
  );
};
/* {conveniada[0].cheque.files.name}*/
export default StepperForm;
