/* example file src/analytics.js */

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'orderbuddyweb',
  plugins: [
    googleAnalytics({
        measurementIds: [process.env.REACT_APP_GA_MEASUREMENT_ID]
      })
  ]
})

/* export the instance for usage in your app */
export default analytics