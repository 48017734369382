import React, { useState, useEffect } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import LinearProgress from "@material-ui/core/LinearProgress";
import { Pie } from 'react-chartjs-2';

import API from 'util/API';
import { showErrorSnackbar } from 'store/actions/snackbarMessage';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
      padding: theme.spacing(2)
  },
  table: {


  },
  row: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1)
  },
}));

const OrderTypeReport = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [orderFromData, setOrderFromData] = useState([]);
  const [orderTypeData, setOrderTypeData] = useState([]);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));


  const formateDate = (date) => {
    return moment(date).format('YYYY-MM-DD')
  };


  const getDateReportData = async (type, callback) => {
     
      if (endDate < startDate) {
          showError('The end date cannot be later than the start date!');
          return;
      }
      
      try {
        
          const startDateStr = formateDate(startDate);
          const endDateStr = formateDate(endDate);
          const rewsponse = await API.get(`/${type}?startDateStr=${startDateStr}&endDateStr=${endDateStr}`);
          const data = await rewsponse.data;
          //console.log('data', data);
         
          if (data) {
            callback(data);
          } else {
            callback([]);
          }

      } catch (err) {
          setIsLoading(false);
          console.log(err);
          callback([]);
          showError('Something went wrong, please try again.');
      }
  };



  const queryDate = async () => {
      setIsLoading(true);
      setOrderFromData([]);
      setOrderTypeData([]);
      await getDateReportData('orderFromReport', (data) => {
        setOrderFromData(data);
      });

      await getDateReportData('orderTypeReport', (data) => {
        
        setOrderTypeData(data.map((item) => {
          return { ...item, type: item.type === null ? 'other': item.type};
        }));
      });
    
      setIsLoading(false);
  };


  const orderFromReportData =  (data) => {
    return {
    labels: data.map(item => item.type),
    datasets: [
      {
        label: 'Order Types',
        data: data.map(type => type.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }
};

  return (


  <>

<div className={classes.root}>
        {isLoading ? <LinearProgress style = {{ marginBottom: 8 }}/> : null}
            <Grid container spacing={1}>
                <Grid
                    item
                    lg={8}
                    md={12}
                    xl={9}
                    xs={12}
                    container direction="row" alignItems="center"
                >
                    <Typography style={{ margin: theme.spacing(1) }}>Start Date</Typography>
                    <DatePicker selected={startDate}  dateFormat="dd/MM/yyyy"
                    onChange={date => setStartDate(date)} />
                    <Typography style={{ margin: theme.spacing(1) }}>End Date</Typography>
                    <DatePicker selected={endDate}  dateFormat="dd/MM/yyyy"
                    onChange={date => setEndDate(date)} />

                    
           <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: theme.spacing(1)}}
            onClick={e => queryDate()}
          >
            Query
        </Button>
                </Grid>
                <Grid
                    item
                    lg={8}
                    md={12}
                    xl={9}
                    xs={12}
                >
                    <Typography style={{ margin: theme.spacing(1) }}>{formateDate(startDate)} - {formateDate(endDate)}</Typography>

                
                    <Pie data={orderFromReportData(orderFromData)} />

                    <Pie data={orderFromReportData(orderTypeData)} />
                   
                    
                </Grid>

            </Grid>
        </div>
    
    
  </>
)

  };

export default OrderTypeReport