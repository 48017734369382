import {
  ADD_OPTION, UPDATE_OPTION_QUANTITY,
  REMOVE_OPTION, CHOOSE_SIZE, CLEAR_DISH, ADD_DISH, UPDATE_QUANTITY, UPDATE_SPECIAL, UPDATE_SUBMIT_STATUS, UPDATE_IS_TAKEAWAY
} from '../actions/dish';


const initialState = {
  dish: null,
  size: null,
  options: [],
  subTotal: null,
  isReqiredSelected: true,
  quantity: 1,
  specialInstructions: '',
  isTakeaway: false,
}

const removeItemWithSlice = (items, index) => {

  let newSelectedOptions = [];

  if (index === 0) {
    newSelectedOptions = newSelectedOptions.concat(items.slice(1));
  } else if (index === items.length - 1) {
    newSelectedOptions = newSelectedOptions.concat(items.slice(0, -1));
  } else if (index > 0) {
    newSelectedOptions = newSelectedOptions.concat(
      items.slice(0, index),
      items.slice(index + 1)
    )
  }
  return newSelectedOptions;
};

const removeSizeOptions = (size, options) => {
  let sizeOptionIds = [];
  let updatedItemIndex;
  size.groups.forEach(group => {
    group.options.forEach(option => {
      sizeOptionIds.push(option.id);
    })
  });

  let newOptions = options;
  sizeOptionIds.forEach(id => {
    updatedItemIndex = options.findIndex(
      item => item.id === id
    );
    newOptions = removeItemWithSlice(newOptions, updatedItemIndex);

  });

  return newOptions;
};

const checkGroupRequired = (groups, options) => {
  if (!groups) {
     return true;
  }
  for (const group of groups) {
     if (!group.options) {
       continue
     }  

    if (group.required) {
      //console.log('group.options.map(option => option.id)', group.options.map(option => option.id));
      const intersectionIds = group.options.map(option => option.id).filter(element => options.map(option => option.id).includes(element));
      const intersectionOptions = options.filter(item => intersectionIds.includes(item.id));
      const selectedCount = intersectionOptions.reduce((count, curItem) => {
        return count + curItem.quantity;
      }, 0);

      console.log('intersection0', intersectionIds, intersectionOptions, selectedCount);
      if (selectedCount === 0) {
        return false;
      }
      if (selectedCount > group.force_max && group.force_max > 0) {
        return false;
      }
      if (selectedCount < group.force_min) {
        return false;
      }
    } else {
      const intersectionIds = group.options.map(option => option.id).filter(element => options.map(option => option.id).includes(element));
      const intersectionOptions = options.filter(item => intersectionIds.includes(item.id));
      const selectedCount = intersectionOptions.reduce((count, curItem) => {
        return count + curItem.quantity;
      }, 0);
      if ((selectedCount > group.force_max && group.force_max !== 0) || (selectedCount > 0 && selectedCount < group.force_min && group.force_min !== 0)) {
        return false;
      }
    }
  }
  return true;
};

const isRequiredOptionChecked = (dish, size, options) => {
  if (!checkGroupRequired(dish.groups, options)) {
    return false;
  }
  if (size && !checkGroupRequired(size.groups, options)) {
    return false;
  }
  return true;
};

const calcPrice = (options, size, basicPrice) => {
  const optionTotal = options.reduce((count, curItem) => {
    return count + Number(curItem.price) * (curItem.quantity ? curItem.quantity : 1);
  }, 0);

  let sizePrice = basicPrice;
  if (size) {
    sizePrice = Number(size.price);
  }
  console.log('optionTotal', optionTotal, sizePrice, options);
  return optionTotal  + sizePrice;

};

const dishReducer = (state = initialState, action) => {
  let updateOptions;
  let updatedItemIndex;
  let currentSize;
  const {type, payload} = action;
  switch (type) {

    case ADD_OPTION:
      updateOptions = [...state.options];
      updateOptions.push({ ...action.payload, quantity: 1 });
      return { ...state, options: updateOptions, subTotal: calcPrice(updateOptions, state.size, state.dish.price), isReqiredSelected: isRequiredOptionChecked(state.dish, state.size, updateOptions) };

    case UPDATE_OPTION_QUANTITY:
      updateOptions = [...state.options];
      updatedItemIndex = updateOptions.findIndex(
        item => item.id === action.payload.id
      );

      if (updatedItemIndex > -1) {
        const updatedItem = updateOptions[updatedItemIndex];
        updatedItem.quantity = action.payload.quantity;
        updateOptions[updatedItemIndex] = updatedItem;
      }

      return { ...state, options: updateOptions, subTotal: calcPrice(updateOptions, state.size, state.dish.price), isReqiredSelected: isRequiredOptionChecked(state.dish, state.size, updateOptions) };

    case REMOVE_OPTION:
      updateOptions = [...state.options];
      updatedItemIndex = updateOptions.findIndex(
        item => item.id === action.payload.id
      );
      //   console.log('updatedItemIndex', updatedItemIndex);
      if (updatedItemIndex === -1) {
        return state;
      }

      let newSelectedOptions = [];

      if (updatedItemIndex === 0) {
        newSelectedOptions = newSelectedOptions.concat(updateOptions.slice(1));
      } else if (updatedItemIndex === updateOptions.length - 1) {
        newSelectedOptions = newSelectedOptions.concat(updateOptions.slice(0, -1));
      } else if (updatedItemIndex > 0) {
        newSelectedOptions = newSelectedOptions.concat(
          updateOptions.slice(0, updatedItemIndex),
          updateOptions.slice(updatedItemIndex + 1)
        )
      }

      return { ...state, options: newSelectedOptions, subTotal: calcPrice(newSelectedOptions, state.size, state.dish.price), isReqiredSelected: isRequiredOptionChecked(state.dish, state.size, newSelectedOptions) };

    case CHOOSE_SIZE:
      updateOptions = [...state.options];
      currentSize = state.size;
      // console.log('currentSize', currentSize, action.payload);
      if (currentSize === action.payload) {
        return state;
      }
      if (!currentSize) {
        // console.log('no currentSize', action.payload);
        return { ...state, size: action.payload, isReqiredSelected: isRequiredOptionChecked(state.dish, action.payload, state.options), subTotal: calcPrice(updateOptions, action.payload, state.dish.price) };
      } else {
        updateOptions = removeSizeOptions(currentSize, updateOptions);
        // console.log('updateOptions', updateOptions);
        return { ...state, size: action.payload, options: updateOptions, isReqiredSelected: isRequiredOptionChecked(state.dish, action.payload, updateOptions), subTotal: calcPrice(updateOptions, action.payload, state.dish.price) };
      }

    case CLEAR_DISH:
      return initialState;

    case ADD_DISH:
      const dish = action.payload;
      let subTotal = dish.price;
      currentSize = null;
      for (const size of dish.sizes) {
        if (size.default) {
          subTotal = Number(size.price);
          currentSize = size;
        }
      }

      let defaultOptions = [];
      for (const group of dish.groups) {
        for (const option of group.options) {
          if (option.default === true) {
            defaultOptions.push({...option, quantity: 1});
            subTotal += option.price;
          }
        }
        
      }
      const tempOptions  = [...state.options, ...defaultOptions];
      return { ...state, options: defaultOptions, dish: payload, subTotal: subTotal, size: currentSize, isReqiredSelected: isRequiredOptionChecked(dish, currentSize, tempOptions), quantity: 1 };

    case UPDATE_QUANTITY:
      return { ...state, quantity: action.payload };

    case UPDATE_SPECIAL:
      return { ...state, specialInstructions: payload };
    case UPDATE_IS_TAKEAWAY:
        return { ...state, isTakeaway: payload };
    case UPDATE_SUBMIT_STATUS:
      return { ...state, isReqiredSelected: payload };

    default:
      return state;
  }
};

export default dishReducer;
