import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";

import clsx from "clsx";

import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { showErrorSnackbar } from "store/actions/snackbarMessage";

import API from "util/API";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
  leftSpace: {
    marginLeft: theme.spacing(1),
  },
}));

const Account = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const history = useHistory();
  const user = useSelector((state) => state.account.user);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  // authRestaurantAdminMiddleWare(history, user);

  // useEffect(() => {
  //   const username = props.match.params.username;
  //   // console.log("username", username);
  //   if (username && accounts && accounts.length > 0) {
  //     const accountIdx = accounts.findIndex(
  //       (item) => item.username === username
  //     );
  //     setSelectedAccount(accounts[accountIdx]);
  //   }
  // }, [props, accounts]);

  const getUser = async () => {
    const username = props.match.params.username;
    console.log("username", username);
    if (username) {
      try {
        const response = await API.get(`/users/${username}`);

        if (response.data && response.data.user) {
          setSelectedAccount(response.data.user);
          setPermissions(response.data.permissions);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (selectedAccount === null || selectedAccount === undefined) {
      getUser();
    }
  }, [props]);

  useEffect(() => {
    const password = Math.random().toString(36).slice(-8);
    setPassword(password);
  }, [props]);

  useEffect(() => {
    // console.log("New account", selectedAccount);
    if (selectedAccount) {
      setFirstName(selectedAccount.firstName);
      setLastName(selectedAccount.lastName);
      setEmail(selectedAccount.email);
      setPhoneNumber(selectedAccount.phoneNumber);
      setUsername(selectedAccount.username);
      setRole(selectedAccount.role ? selectedAccount.role : "");
      if (selectedAccount.permissions) {
        setSelectedPermissions(selectedAccount.permissions);
      }
    }
  }, [selectedAccount, permissions]);

  const getPermissions = async () => {
    try {
      setButtonLoading(true);
      const response = await API.get(`/userPermissions`);
      if (response && response.data && response.data.permissions) {
        setPermissions(response.data.permissions);
      }
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      showError("Something went wrong! Please try again.");
    }
  };

  useEffect(() => {
    if (!props.match.params.username) {
      getPermissions();
    }
  }, [props]);

  const updateFormValues = (event) => {
    event.preventDefault();

    if (password.length < 6) {
      showError("The password should be at least 6 characters");
      return;
    }

    // authRestaurantAdminMiddleWare(history, user);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;
    let restaurantId = "";
    const restaurantIdParam = props.match.params.restaurantId;
    // console.log("restaurantIdParam", restaurantIdParam);
    if (restaurantIdParam) {
      restaurantId = restaurantIdParam;
    }
    if (role === "restaurantGroupAdmin") {
      restaurantId = "dummy";
    }

    let formRequest = {
      firstName: firstName,
      lastName: lastName,
      email: email.toLowerCase(),
      phoneNumber: phoneNumber,
      role: role,
      username: username,

      permissions: selectedPermissions,
    };

    if (!selectedAccount) {
      formRequest = { ...formRequest, password };
    }

    // if (
    //   !selectedAccount &&
    //   (role === "restaurantManager" || role === "restaurantReport") &&
    //   restaurantId === ""
    // ) {
    //   showError("Please select a restaurant first");
    //   return;
    // }

    // if (
    //   restaurantId != "dummy" &&
    //   restaurantId !== "" &&
    //   restaurantId !== account.restaurantId
    // ) {
    //   showError("The restaurant id does not match.");
    //   return;
    // }

    setButtonLoading(true);

    if (selectedAccount) {
      API.put("/user", formRequest)
        .then(() => {
          setButtonLoading(false);
          let returnUrl = "/users";
          if (user.role === "restaurantAdmin") {
            returnUrl = "/myrestaurant";
          }
          history.push(returnUrl);
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            history.push("/sign-in");
          }
          console.log(error);
          setButtonLoading(false);
          showError("Something went wrong! Please try again.");
        });
    } else {
      let postUrl = "/user";
      let returnUrl = "/users";
      if (user.role === "restaurantAdmin") {
        postUrl = "/restaurantManager";
        returnUrl = "/myrestaurant";
      }
      API.post(postUrl, formRequest)
        .then(() => {
          setButtonLoading(false);
          history.push(returnUrl);
        })
        .catch((error) => {
          console.log(error);
          setButtonLoading(false);
          if (error.response && error.response.status === 403) {
            history.push("/sign-in");
          }
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data.username
          ) {
            showError(
              "This username is already taken! Please try another one."
            );
            return;
          }
          showError("Something went wrong! Please try again.");
        });
    }
  };

  const handleTagChange = (event, code) => {
    const foundIndex = selectedPermissions.findIndex(
      (permission) => permission.code === code
    );
    if (foundIndex !== -1) {
      setSelectedPermissions(
        selectedPermissions.filter((permission) => permission.code !== code)
      );
    } else {
      const permission = permissions.find(
        (permission) => permission.code === code
      );
      if (permission) {
        console.log(permission);

        setSelectedPermissions([...selectedPermissions, permission]);
      }
    }
  };

  const isPermissionSelected = (code) => {
    // console.log(JSON.stringify(selectedPermissions));
    return selectedPermissions.find((permission) => permission.code === code);
  };

  return (
    <main className={classes.content}>
      {buttonLoading ? <LinearProgress /> : null}
      <Card className={clsx(classes.root, classes)}>
        <form autoComplete="off" noValidate>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First name"
                  margin="dense"
                  name="firstName"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last name"
                  margin="dense"
                  name="lastName"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  margin="dense"
                  name="phone"
                  type="number"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="User Name"
                  margin="dense"
                  name="userHandle"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              {/* 
                                    {selectedAccount ?
									(<Grid item md={6} xs={12}>
										<TextField
											fullWidth
											label="Old Password"
											margin="dense"
											name="oldPassword"
											variant="outlined"
											value={olPassword}
											onChange={e => setOldPassword(e.target.value)}
										/>
									</Grid>) : null} */}

              {!selectedAccount ? (
                <Grid
                  item
                  md={4}
                  xs={8}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <TextField
                    label="Password"
                    margin="dense"
                    inputProps={{ readOnly: true }}
                    name="passsword"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* add copy button */}
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.leftSpace}
                    onClick={() => {
                      // copy password
                      navigator.clipboard.writeText(password);
                    }}
                  >
                    Copy
                  </Button>
                </Grid>
              ) : null}

              {user && user.role === "admin" ? (
                <Grid item md={6} xs={12}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel htmlFor="filled-age-native-simple">
                      Role
                    </InputLabel>
                    <Select
                      native
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      inputProps={{
                        name: "Role",
                        id: "filled-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={"admin"}>Admin</option>
                      <option value={"menuAdmin"}>Menu Admin</option>
                      <option value={"restaurantAdmin"}>
                        Restaurant Admin
                      </option>
                      <option value={"restaurantGroupAdmin"}>
                        Restaurant Group Admin
                      </option>
                      <option value={"restaurantManager"}>
                        Restaurant Manager
                      </option>
                      <option value={"restaurantStaff"}>
                        Restaurant Staff
                      </option>
                      <option value={"restaurantReport"}>
                        Restaurant Reporting
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}

              { ((user && user.role === "restaurantAdmin") || role === 'restaurantManager' || role === 'restaurantStaff') && permissions.length > 0 ? (
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Permissions</FormLabel>
                    <FormGroup row>
                      {permissions.filter(
                        (permission) => role !== 'restaurantStaff' || (role === 'restaurantStaff' && (permission.code === "obPayment" || permission.code === "menuManagement"))
                      ).map((permission) => {
                        return (
                          <FormControlLabel
                            key={permission.code}
                            control={
                              <Checkbox
                                checked={isPermissionSelected(permission.code)}
                                onChange={(e) =>
                                  handleTagChange(e, permission.code)
                                }
                                name={permission.code}
                              />
                            }
                            label={permission.name}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions />
        </form>
      </Card>

      <Button
        color="primary"
        variant="contained"
        type="submit"
        className={classes.submitButton}
        onClick={updateFormValues}
        disabled={
          buttonLoading ||
          !firstName ||
          !lastName ||
          !email ||
          !phoneNumber ||
          !username ||
          (!role && user.role === "admin") ||
          (!selectedAccount && !password)
        }
      >
        Save Account
        {buttonLoading && (
          <CircularProgress size={30} className={classes.progess} />
        )}
      </Button>
    </main>
  );
};

export default Account;
