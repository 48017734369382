import {
  ADD_DISH_TO_CART,
  REMOVE_DISH_FROM_CART,
  SET_RESTAURANT_ID,
  EMPTY_CART,
  LOAD_CART,
  INCREASE_DISH_QUANTITY,
  LOAD_USER,
  SAVE_USER,
  UPDATE_PAYMENT_TYPE,
  UPDATE_DISH_POSITION,
  LOAD_ORDERS_SUMMARY,
  UPDATE_DISH_TAKEAWAY,
  LOAD_PREVIOUS_CART,
  UPDATE_TILL_PAY_URL,
  UPDATE_MEMBER_ID,
  UPDATE_ADYEN_SESSION,
} from "../actions/order";
import { v4 as uuidv4 } from "uuid";
import { loadOrdersSummary, saveOrdersSummary } from "util/orderUtils";

const CART_VERSION = 3;

const loadCart = (restaurant) => {
  try {
    let serializedCart = localStorage.getItem(
      `http://orderbuddy.net.au:${restaurant.restaurantId}:cart${CART_VERSION}`
    );
    //console.log('serializedCart', serializedCart);
    if (serializedCart === null) {
      return [];
    }

    const { cart, lastUpdateTime } = JSON.parse(serializedCart);
    // console.log(cart, new Date(lastUpdateTime));
    if (
      lastUpdateTime &&
      new Date(lastUpdateTime).getTime() < Date.now() - 1000 * 60 * 60
    ) {
      return [];
    } else {
      return cart;
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

const saveCart = (cart, restaurant) => {
  try {
    let serializedCart = JSON.stringify({ cart, lastUpdateTime: new Date() });
   // console.log(serializedCart);
    localStorage.setItem(
      `http://orderbuddy.net.au:${restaurant.restaurantId}:cart${CART_VERSION}`,
      serializedCart
    );
  } catch (err) {
    console.log(err);
  }
};

const saveCopyCart = (cart, restaurant) => {
  try {
    let serializedCart = JSON.stringify({ cart, lastUpdateTime: new Date() });
   // console.log(serializedCart);
    localStorage.setItem(
      `http://orderbuddy.net.au:${restaurant.restaurantId}:cartorder${CART_VERSION}`,
      serializedCart
    );
  } catch (err) {
    console.log(err);
  }
};

const loadPreviousCart = (restaurantId) => {
  try {
    let serializedCart = localStorage.getItem(
      `http://orderbuddy.net.au:${restaurantId}:cartorder${CART_VERSION}`
    );
    //console.log('serializedCart', serializedCart);
    if (serializedCart === null) {
      return [];
    }

    const { cart, lastUpdateTime } = JSON.parse(serializedCart);
  //   console.log(cart, new Date(lastUpdateTime));
    if (
      lastUpdateTime &&
      new Date(lastUpdateTime).getTime() < Date.now() - 1000 * 60 * 60
    ) {
      return [];
    } else {
      return cart;
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

const userKey = "http://orderbuddy.net.au:user";
const loadUser = () => {
  try {
    let serializedCart = localStorage.getItem(userKey);

    if (serializedCart === null) {
      return [];
    }

    return JSON.parse(serializedCart);
  } catch (err) {
    return [];
  }
};

const saveUser = (user) => {
  try {
    let serializedObj = JSON.stringify(user);
    localStorage.setItem(userKey, serializedObj);
  } catch (err) {
    console.log(err);
  }
};

const initialState = {
  cart: [],
  paymentType: "",
  orderSummary: null,
};




const removeItemWithSlice = (items, index) => {
  let newSelectedOptions = [];

  if (index === 0) {
    newSelectedOptions = newSelectedOptions.concat(items.slice(1));
  } else if (index === items.length - 1) {
    newSelectedOptions = newSelectedOptions.concat(items.slice(0, -1));
  } else if (index > 0) {
    newSelectedOptions = newSelectedOptions.concat(
      items.slice(0, index),
      items.slice(index + 1)
    );
  }
  return newSelectedOptions;
};

const compareOpions = (option1, option2) => {
  if (!option1 && !option2) {
    return true;
  }
  if ((!option1 && option2) || (option1 && !option2)) {
    return false;
  }

  if (option1.id !== option2.id) {
    return false;
  }
  if (option1.price !== option2.price) {
    return false;
  }
  if (option1.quantity !== option2.quantity) {
    return false;
  }
  return true;
};

const compareOptionList = (optionList1, optionList2) => {
  if (!optionList1 && !optionList2) {
    return true;
  }
  if (optionList1 && optionList2 && optionList1.length !== optionList2.length) {
    return false;
  }
  if (optionList1 && !optionList2) {
    return false;
  }
  if (!optionList1 && optionList2) {
    return false;
  }
  for (let i = 0; i < optionList1.length; i++) {
    let match = false;
    for (let j = 0; j < optionList2.length; j++) {
      if (compareOpions(optionList1[i], optionList2[j])) {
        match = true;
        break;
      }
    }
    if (!match) {
      return false;
    }
  }
  return true;
};

const compareSizes = (size1, size2) => {
  if (!size1 && !size2) {
    return true;
  }
  if ((!size1 && size2) || (size1 && !size2)) {
    return false;
  }

  if (size1.id !== size2.id) {
    return false;
  }
  if (size1.price !== size2.price) {
    return false;
  }
  return true;
};

const compareTwoDishes = (dish1, dish2) => {
  if (dish1.dish.id !== dish2.dish.id) {
    return false;
  }
  if (dish1.subTotal !== dish2.subTotal) {
    return false;
  }
  if (dish1.specialInstructions !== dish2.specialInstructions) {
    return false;
  }

  if (dish1.isTakeaway !== dish2.isTakeaway) {
    return false;
  }

  // console.debug('compareSizes(dish1.size, dish2.size)', compareSizes(dish1.size, dish2.size));
  if (!compareSizes(dish1.size, dish2.size)) {
    return false;
  }
  //console.debug('compareOptionList(dish1.options, dish2.options))', compareOptionList(dish1.options, dish2.options));
  if (!compareOptionList(dish1.options, dish2.options)) {
    return false;
  }

  return true;
};

const addToCart = (dish, cart) => {
  const newItem = { ...dish, id: uuidv4() };

  if (cart.length === 0) {
    cart.push(newItem);
    return;
  }

  let idx = null;
  for (let i = 0; i < cart.length; i++) {
    // console.debug('compareTwoDishes(cart[i], dish)', compareTwoDishes(cart[i], dish));
    if (compareTwoDishes(cart[i], dish)) {
      idx = i;
      break;
    }
  }

  if (idx === null) {
    cart.push({ ...dish, id: uuidv4() });
  } else {
    let item = cart[idx];
    item.quantity += dish.quantity;
    cart[idx] = item;
  }
};


const shortUUID = () =>{

  const uuid = uuidv4();
  // remove decoration
  const hexString = uuid.replace(/-/g, "");
  
  const base64String = Buffer.from(hexString, 'hex').toString('base64')
  // console.log("base64:", base64String);
  // remove special characters from the base64 string
  const shortUUID = base64String.replace(/[^a-zA-Z0-9]/g, "");
  
  return shortUUID;
}

const orderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  let updatedCart;
  let updatedItemIndex;
  let updatedItem;
  switch (type) {
    case ADD_DISH_TO_CART:
      //console.log("action.payload", action.payload);
      updatedCart = [...state.cart];

      //updatedCart.push({ ...action.payload, id: uuidv4() });
      addToCart(payload, updatedCart);

      saveCart(updatedCart, state.restaurant);

      return { ...state, cart: updatedCart, orderSummary: null, orderNumber: shortUUID(), isAdded: true};
    case REMOVE_DISH_FROM_CART:
      updatedCart = [...state.cart];
    
      updatedCart = updatedCart.filter((item) => item.id !== payload);

      saveCart(updatedCart, state.restaurant);
      return { ...state, cart: updatedCart, orderNumber: shortUUID() };

    case INCREASE_DISH_QUANTITY:
      updatedCart = [...state.cart];
      updatedItemIndex = updatedCart.findIndex(
        (item) => item.id === payload.id
      );
      updatedItem = {
        ...updatedCart[updatedItemIndex],
      };
      updatedItem.quantity += payload.change;
      updatedCart[updatedItemIndex] = updatedItem;
      saveCart(updatedCart, state.restaurant);
      return { ...state, cart: updatedCart, orderNumber: shortUUID() };
    case UPDATE_DISH_TAKEAWAY:
      updatedCart = [...state.cart];
      updatedItemIndex = updatedCart.findIndex(
        (item) => item.id === payload.id
      );
      updatedItem = {
        ...updatedCart[updatedItemIndex],
        isTakeaway: payload.isTakeaway,
      };
      // console.log('updatedCart', payload.isTakeaway, updatedItem);
      updatedCart[updatedItemIndex] = updatedItem;
      saveCart(updatedCart, state.restaurant);

      return { ...state, cart: updatedCart };

    case SET_RESTAURANT_ID:
      return {
        ...state,
        restaurant: action.payload,
        cart: state.cart.length === 0 ? loadCart(action.payload) : state.cart,
      };

    case SAVE_USER:
      saveUser(action.payload);
      return state;

    case LOAD_USER:
      const user = loadUser();
      return { ...state, user: user };

    case EMPTY_CART:
      try {
        saveCopyCart(state.cart, state.restaurant);
        saveCart([], state.restaurant);
        saveOrdersSummary(payload, state.restaurant);
      } catch (err) {
        console.log(err);
      }
      return { ...state, cart: [], orderNumber: null};

    case LOAD_CART:
      return { ...state, cart: loadCart(payload), orderNumber: shortUUID() };
    case LOAD_PREVIOUS_CART:
     
      if (!state.cart || state.cart.length === 0) {
        //console.log('state.cart', loadPreviousCart(payload));
        return { ...state, cart: loadPreviousCart(payload) };
      } else {
        return state;
      }
     
    case LOAD_ORDERS_SUMMARY:
      return {
        ...state,
        ordersSummary: loadOrdersSummary({ restaurantId: payload }),
      };
    case UPDATE_PAYMENT_TYPE:
      return { ...state, paymentType: action.payload };
    case UPDATE_DISH_POSITION:
      return { ...state, dishRef: payload };
    case UPDATE_TILL_PAY_URL:
      return { ...state, tillPaymentUrl: payload };
    case UPDATE_MEMBER_ID:
      return { ...state, memberId: payload };
    case UPDATE_ADYEN_SESSION:
      return { ...state, adyenSession: payload };
    default:
      return state;
  }
};

export default orderReducer;
