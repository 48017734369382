
import React, { useState } from 'react';

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { Alert, AlertTitle } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";

import FormLabel from "@material-ui/core/FormLabel";

import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import { useSelector } from "react-redux";


import { Grid, TextField } from "@material-ui/core";

import moment from "moment";
import { TimePicker } from "@material-ui/pickers";


function OrderDateTime({ pickupTimeTypeHandler, pickupTimeHandler, selectedDateHandler }) {
    const [pickupTimeType, setPickupTimeType] = useState("now");
    const [pickupTime, setPickupTime] = useState(Date());
    const [selectedDate, setSelectedDate] = useState(Date());
    const [selectedDateSurcharge, setSelectedDateSurcharge] = useState(null);

    const restaurant = useSelector((state) => state.menu.restaurant);

    const handleRadioChange = (event) => {
        setPickupTimeType(event.target.value);
        pickupTimeTypeHandler(event.target.value);
    };

    const updateSelectedDateTime = () => {
      
    }

    const handleDateChange = (date) => {
         console.log('date', date);
        setSelectedDate(date);
        selectedDateHandler(date);
        const surcharge = checkHolidaySurcharge(date);
        //console.log('surcharge', surcharge);
        if (surcharge) {
            setSelectedDateSurcharge(surcharge);
        } else {
            setSelectedDateSurcharge(null);
        }
    };

    const handlePickupChange = (time) => {
        console.log('pickupTime', time);
        const formattedTime = moment(time).format("HH:mm");
        setPickupTime(time);
        pickupTimeHandler(formattedTime);

    };


  
      const checkHolidaySurcharge = (date) => {
        if (!restaurant || !restaurant.holidaySurchargeSettings) {
            return null;
        }
        const holidaySurcharge = restaurant.holidaySurchargeSettings;
       // console.log('holidaySurcharge', holidaySurcharge);
        if (holidaySurcharge.isEnabled === true && checkWeekdaysAndPublicHolidays(moment(date), holidaySurcharge)) {
            return {
                isEnabled: true,
                isPercentage: holidaySurcharge.isPercentage,
                amount: holidaySurcharge.amount
            }
        } else {
            return null;
        }
    };    


  const checkWeekdaysAndPublicHolidays = (today, holidaySurcharge) => {
    const uppercaseWeekdays = holidaySurcharge.weekdays.map((weekday) => weekday.toUpperCase());
    const todayWeekday = today.locale('au').format("dddd").toUpperCase()
    console.log('todayWeekday', todayWeekday);
    if (uppercaseWeekdays.includes(todayWeekday)) {
        return true;
    }

    const todayString = today.format("DD/MM/YYYY");
    if (holidaySurcharge.publicHolidayDays.includes(todayString)) {
        return true;
    }
    return false;
}


    return (
        <React.Fragment>
            <Grid item md={6} xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Choose time</FormLabel>
                    <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="now"
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel
                            value="now"
                            control={<Radio color="primary" />}
                            label="Now"
                        />
                        <FormControlLabel
                            value="chooseTime"
                            control={<Radio color="primary" />}
                            label="Choose time"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {pickupTimeType === "chooseTime" ? (
                <>
                 

                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                            fullWidth
                            label="Choose Time"
                            value={pickupTime}
                            onChange={(time) => handlePickupChange(time)}
                            inputVariant="outlined"
                            ampm={false}
                            format="HH:mm"
                        />
                    </MuiPickersUtilsProvider>
                    </Grid>
                    {restaurant && !restaurant.orderAheadDisabled ?
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    autoOk={true}
                                    id="date-picker-inline"
                                    label="Choose Date "
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "choose date",
                                    }}
                                />
                            </MuiPickersUtilsProvider>

                            {selectedDateSurcharge &&  selectedDateSurcharge.isEnabled === true ?
                  <Paper>
                
                    <Alert severity="info">
                      <AlertTitle>Info</AlertTitle>
                      <strong>Please note a holiday surcharge of {`${selectedDateSurcharge.amount}${selectedDateSurcharge.isPercentage === true ? '% ': 'dollars '} will be added to all bills.`}</strong>
                    </Alert>
                 
                </Paper>: null  
        }
                        </Grid>
                        : null}
                </>
            ) : null}
        </React.Fragment>
    );
}

export default OrderDateTime;