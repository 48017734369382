import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';


import Button from '@material-ui/core/Button';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';



import OrderType from './OrderType';
import { useSelector, useDispatch } from 'react-redux';
import { setOrderSelectedDate } from 'store/actions/restaurant';
import moment from 'moment';

import API from 'util/API';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const OrdersToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();


  const dispatch = useDispatch();

  const setOrderDate = (date) => dispatch(setOrderSelectedDate(date));
  const selectedDate = useSelector((state) => state.restaurant.orderSelectedDate);
  const orderType = useSelector((state) => state.restaurant.orderType);

  const handleDateChange = (date) => {
    console.log('date', date);
    setOrderDate(date)
  };


  const downloadCustomers = async () => {
    try {
      const dateStr = moment(selectedDate).format('DD-MM-YY');
      const rewsponse = await API.get(`/dineCustomers?dateStr=${dateStr}`);
      const data = await rewsponse.data;
      // console.log('data', data);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `customer-${dateStr}.csv`);
      document.body.appendChild(link);
      link.click();


    } catch (err) {
      console.log(err);
    }
  }



  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* 
        <IconButton aria-label="delete"
          onClick={props.deleteProxy}>
          <DeleteIcon />
        </IconButton> */}
   { orderType === 'online' ?
        <Button
          className={classes.importButton}
          color="primary"
          variant="outlined"
          onClick={downloadCustomers}
        >
          Download Customers
        </Button> : null}

        <OrderType />



      </div>
      <div className={classes.row}>
        {/* <SearchInput
          className={classes.searchInput}
          placeholder="Search order"
        /> */}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Order Date "
            autoOk={true}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />

        </MuiPickersUtilsProvider>
        <span className={classes.spacer} />
      </div>
    </div>
  );
};

OrdersToolbar.propTypes = {
  className: PropTypes.string
};

export default OrdersToolbar;
