import {
  SET_TRANSACTION_DETAIL,
  SET_TRANSACTION_PAGE,
  SET_TRANSACTIONS,
  SET_TRANSACTION_IDS
} from '../actions/transaction';

const initialState = {
  transactions: [],
  selectedTransaction: null,
  page: 0,
  transactionsSelectedDate: Date(),
  ids: []
}

const transactionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case SET_TRANSACTION_DETAIL:
      return { ...state, selectedTransaction: payload };
    case SET_TRANSACTION_PAGE:
      return { ...state, page: payload };
    case SET_TRANSACTIONS:
      return { ...state, transactions: payload };
      case SET_TRANSACTION_IDS:
        return { ...state, ids: payload };

    default:
      return state;
  }
};

export default transactionReducer;
