import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import IconButton from '@material-ui/core/IconButton';
import ColorPickerDialog from 'views/Common/ColorPickerDialog';
import 'views/Common//App.css';


import {
    updateMenuGroup

} from 'store/actions/restaurantMenu';
import { useDispatch } from 'react-redux';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '90%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));


const GroupOptionEdit = (props) => {
    const { group, option, editOption, menuId } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [hideForWeb, setHideForWeb] = useState(false);
    const [shortName, setShortName] = useState('');
    const [isColorPickerDialogOpen, setIsColorPickerDialogOpen] = useState(false);
    const [colorHex, setColorHex] = useState(null);

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const updateTheGroup = (updatedGroup) => dispatch(updateMenuGroup(updatedGroup));

    const close = () => {
        editOption(null);
    };


    useEffect(() => {
        if (option) {
            setName(option.name);
            setPrice(option.price);
            setShortName(option.shortName);
            setHideForWeb(option.hideForWeb);
            setIsDefault(option.isDefault);
            if (option.colorHex) {
                setColorHex(option.colorHex);
            }
        }
    }, [option]);

    const isSaveButtonDisabled = () => {
        return !name || isNaN(price)
    };

    const save = () => {
        const updateOption = {
            ...option,
            name,
            shortName,
            price: Number(price),
            groupId: group.id,
            menuId: group.menuId,
            default: isDefault,
            hideForWeb,
            colorHex
        };
        const index = group.options.findIndex(x => x.id === option.id);
        group.options[index] = updateOption;
        updateTheGroup({ ...group, menuId });
        close();
    }


    const handleSwitchChange = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        if (name === 'hideForWeb') {
            setHideForWeb(value);
        }
    };

    const updateTextToLower = () => {
        if (shortName) {
            setShortName(shortName.toLowerCase());
        }
        setName(name.toLowerCase());
    }


    const openColorPickerDialog = () => {
        setIsColorPickerDialogOpen(true);
    };

    const closeColorPickerDialog = () => {
        setIsColorPickerDialogOpen(false);
    };

    const handleColorSelected = (color) => {
        setColorHex(color);
    };

    return (
        <Grid container direction="column" >
            <Grid container direction="row" alignItems="center">
                <Grid item xs={9}>
                    <TextField
                        margin="normal"
                        name="name"
                        fullWidth
                        id="name"
                        label="Name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <IconButton aria-label="eidt" className={classes.margin} edge='end' color="secondary" onClick={updateTextToLower}>
                        <TextFieldsIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
            <TextField
                size="small"
                label="Short Name"
                margin="dense"
                name="shortName"
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                value={shortName}
                onChange={e => setShortName(e.target.value)}

            />

            <TextField
                margin="normal"
                name="price"
                size="small"
                id="price"
                label="Price"
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                value={price}
                onChange={e => setPrice(e.target.value)}
            />


            <Grid container direction="row" alignItems="center">

                <FormControlLabel
                    control={
                        <Switch
                            checked={hideForWeb}
                            onChange={handleSwitchChange}
                            name="hideForWeb"
                            color="primary"
                        />
                    }
                    label="Hide For Web"
                />

                <Checkbox
                    name="allow_quantity"
                    color="primary"
                    value={isDefault}
                    onChange={e => setIsDefault(e.target.checked)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Typography>
                    Pre-select
                </Typography>
                <Tooltip title="This item will be appear as pre-selected in the menu." arrow>
                    <InfoIcon />
                </Tooltip>




            </Grid>

            <div >
                <div className="color-picker-row">
                    <span className="color-picker-button">
                        <Button variant='outlined' onClick={openColorPickerDialog} >
                            Pick a color
                        </Button>
                    </span>
                    <span className="selected-color" style={{ backgroundColor: colorHex }}>{colorHex}</span>
                </div>
                <ColorPickerDialog
                    isOpen={isColorPickerDialogOpen}
                    onClose={closeColorPickerDialog}
                    onColorSelected={handleColorSelected}
                />
            </div>
            <Grid container direction="row" justify="space-between" spacing={1}>
                <Button variant="outlined" onClick={close}>Cancel</Button>
                <Button variant="outlined"
                    disabled={isSaveButtonDisabled()}
                    color="primary" onClick={save}>
                    Save
                </Button>
            </Grid>

        </Grid>
    );
};

export default GroupOptionEdit;