import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import DatePicker from "react-datepicker";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'

import {
    Card,
    CardActions,
    CardContent,
    Divider,
    Button,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";

import clsx from "clsx";

import { useDispatch } from "react-redux";

import API from "util/API";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
    showSuccessSnackbar,
    showErrorSnackbar,
} from "store/actions/snackbarMessage";

import AddMembersDialog from './AddMembersDialog'
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    toolbar: theme.mixins.toolbar,
    root: {
        padding: theme.spacing(3),
    },
    details: {
        display: "flex",
    },
    avatar: {
        height: 110,
        width: 100,
        flexShrink: 0,
        flexGrow: 0,
    },
    locationText: {
        paddingLeft: "15px",
    },
    buttonProperty: {
        position: "absolute",
        top: "50%",
    },
    uiProgess: {
        position: "fixed",
        zIndex: "1000",
        height: "31px",
        width: "31px",
        left: "50%",
        top: "35%",
    },
    progess: {
        position: "absolute",
    },
    uploadButton: {
        marginLeft: "8px",
        margin: theme.spacing(1),
    },
    customError: {
        color: "red",
        fontSize: "0.8rem",
        marginTop: 10,
    },
    submitButton: {

        marginTop: "10px",
        width: 150,
    },
    uploadButton: {
        marginLeft: "8px",
        margin: theme.spacing(1),
    },
    customError: {
        color: "red",
        fontSize: "0.8rem",
        marginTop: 10,
    },
    Media: {},
}));

const NewVoucher = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();


    const {
        
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        if (selectedMembers.length === 0) {
            showError('Please add members');
            return;
        }
        saveVoucher(data);
    };

    const history = useHistory();

    const [uiLoading, setUiLoading] = useState(false);
    const [isPercentageType, setIsPercentageType] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [openAddMember, setOpenAddMember] = useState(false);



    const showError = (msg) => dispatch(showErrorSnackbar(msg));
    const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));





    const toggleIsPercentage = () => {
        setIsPercentageType(!isPercentageType);
    };


    const saveVoucher = async (data) => {
        setButtonLoading(true);
        const effectiveFromDate= moment(data.effectiveFrom);
        const effectiveFrom = effectiveFromDate.format('DD/MM/YYYY');
        
        const expiryDays = Number(data.expiryDays);
        const expireDate = effectiveFromDate.add(expiryDays, "days").format("DD/MM/YYYY");
        console.log('effectiveFrom', effectiveFrom, expireDate);
        const formRequest = {
            voucher: { ...data, type: "online", 
            expiryDays,
            expireDate, 
            isFreeItem: false,
            effectiveFrom,
            status: 'active', 
            amount: data.amount === null ? 0 : Number(data.amount), 
            maxPrice: Number(data.maxPrice),
            percents: data.percents === null ? 0 : Number(data.percents) 
        },
            members: selectedMembers.map(item => item.id)
        };

        try {
            await API.post("/vouchers", formRequest);
            setButtonLoading(false);
            showSuccess("Saved successfully");
            history.go(0);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                props.history.push("/sign-in");
            }
            console.log(JSON.stringify(error));
            setButtonLoading(false);
            showError("Something went wrong, please try again.");
        }

    };

    const openAddMemberDialog = (e) => {
        setOpenAddMember(true);
    };

    const handleClose = () => {
        setOpenAddMember(false);
    };

    const addMembersHandler = (members) => {
        setSelectedMembers(members);
    };

    {
        if (uiLoading === true) {
            return (
                <main className={classes.content}>
                    {uiLoading && (
                        <CircularProgress size={150} className={classes.uiProgess} />
                    )}
                </main>
            );
        } else {
            return (
                <div className={classes.root}>
                    <div className={classes.content}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Card className={clsx(classes.root, classes)}>
                                <Box m={1}>
                                    <Typography variant="h3">
                                        Create new voucher
                                    </Typography>
                                </Box>
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={3}>


                                        <Grid item md={6} xs={12}>
                                            <Typography>Is for one item</Typography>
                                            <Controller
                                                name="forOneItem"
                                                defaultValue={true}
                                                control={control}
                                                render={(props) => (
                                                    <Switch
                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                        checked={props.value}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Typography>Name</Typography>
                                            <Controller
                                                as={TextField}
                                                name="name"
                                                type="text"
                                                control={control}
                                                rules={{ required: true, maxLength: 20 }}
                                            />
                                            {errors.name && (
                                                <Typography>
                                                    <Box color="error.main">Name is required</Box>
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Typography>Description</Typography>
                                            <Controller
                                                as={TextField}
                                                name="description"
                                                multiple
                                                fullWidth
                                                type="text"
                                                control={control}
                                                rules={{ required: true, maxLength: 50 }}
                                            />
                                            {errors.description && (
                                                <Typography>
                                                    <Box color="error.main">Description is required</Box>
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Typography>Is percentage discount</Typography>
                                            <Controller
                                                name="isPercentage"
                                                defaultValue={true}
                                                control={control}
                                                render={(props) => (
                                                    <Switch
                                                        onChange={(e) => { props.onChange(e.target.checked);; toggleIsPercentage(); }}
                                                        checked={props.value}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        {isPercentageType ?
                                            <Grid item md={6} xs={12}>
                                                <Typography>Discount Percentage (%)</Typography>
                                                <Controller
                                                    as={TextField}
                                                    name="percents"
                                                    type="number"
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors.maxAmount && (
                                                    <Typography>
                                                        <Box color="error.main">
                                                            The discount percentage is required.
                                                        </Box>
                                                    </Typography>
                                                )}
                                            </Grid> :

                                            <Grid item md={6} xs={12}>
                                                <Typography>Discount amount</Typography>
                                                <Controller
                                                    as={TextField}
                                                    name="amount"
                                                    type="number"
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors.maxAmount && (
                                                    <Typography>
                                                        <Box color="error.main">
                                                            The discount amount is required.
                                                        </Box>
                                                    </Typography>
                                                )}
                                            </Grid>}

                                        <Grid item md={6} xs={12}>
                                            <Typography>Maximum Amount</Typography>
                                            <Controller
                                                as={TextField}
                                                name="maxPrice"
                                                type="number"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors.maxAmount && (
                                                <Typography>
                                                    <Box color="error.main">
                                                        The maximum amount is required.
                                                    </Box>
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item md={6} xs={12}>

                                            <Typography>Effective from</Typography>
                                            <Controller
                                                name='effectiveFrom'
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <DatePicker
                                                        selected={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />

                                            {errors.effectiveFrom && (
                                                <Typography>
                                                    <Box color="error.main">Effective From is required</Box>
                                                </Typography>
                                            )}


                                        </Grid>


                                        <Grid item md={6} xs={12}>
                                            <Typography>Voucher Expriry days</Typography>
                                            <Controller
                                                as={TextField}
                                                name="expiryDays"
                                                type="number"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    min: 1,
                                                }}
                                            />
                                            {errors.expiryDays && (
                                                <Typography>
                                                    <Box color="error.main">Expriry days is required</Box>
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <AddMembersDialog open={openAddMember} closeHandler={handleClose} addMembers={addMembersHandler} />
                                            <Typography>Selected members</Typography>
                                            <Button
                                                variant="outlined"
                                                color="primary"

                                                size="small"
                                                startIcon={<GroupAddIcon />}

                                                onClick={openAddMemberDialog}
                                            >
                                                Select members
                                            </Button>
                                            <List dense={false}>
                                                {selectedMembers.map(member => (
                                                    <ListItem key={member.id}>

                                                        <ListItemText primary={`${member.name} - ${member.phoneNumber !== undefined ? member.phoneNumber : ''}`} />

                                                    </ListItem>
                                                )
                                                )}
                                            </List>
                                        </Grid>
                                    </Grid>


                                </CardContent>

                                <CardActions />
                            </Card>

                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"

                                className={classes.submitButton}
                                disabled={buttonLoading || selectedMembers.length === 0}
                            >
                                Save
                                {buttonLoading && (
                                    <CircularProgress size={30} className={classes.progess} />
                                )}
                            </Button>
                        </form>
                    </div>
                </div>
            );
        }
    }
};

export default NewVoucher;
