import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { StaffListToolbar, StaffTable } from './components';


import CircularProgress from '@material-ui/core/CircularProgress';


import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import API from 'util/API';
import { updateUserkeyword } from 'store/actions/account';
import {
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import { getStaffList } from "apis/staffAPIs";
import {
  useQuery,

} from "react-query";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));


const StaffList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  
  const [uiLoading, setUiLoading] = useState(false);
  
  const [staffList, setStaffList] = useState([]);
  const history = useHistory();
  
  const updateFilter = (key) => dispatch(updateUserkeyword(key));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const query = useQuery("staffList", getStaffList);

  


  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {

  return (
    <div className={classes.root}>
      <StaffListToolbar/>
      <div className={classes.content}>
        {query && query.data && query.data ? (
        <StaffTable staffList={query.data} />
      ): null}
      </div>
    </div>
  );
  }
};

export default StaffList;
