import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';



import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


import { useSelector, useDispatch } from 'react-redux';
import { setBookingSelectedDate } from 'store/actions/booking';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const BookingsToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();


  const dispatch = useDispatch();

  const setBookingsDate = (date) => dispatch(setBookingSelectedDate(date));
  const selectedDate = useSelector((state) => state.booking.bookingSelectedDate);
 
  const handleDateChange = (date) => {
   setBookingsDate(date)
  };


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* 
        <IconButton aria-label="delete"
          onClick={props.deleteProxy}>
          <DeleteIcon />
        </IconButton> */}
  

    



      </div>
      <div className={classes.row}>
        {/* <SearchInput
          className={classes.searchInput}
          placeholder="Search order"
        /> */}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Booking Date "
            autoOk={true}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />

        </MuiPickersUtilsProvider>
        <span className={classes.spacer} />
      </div>
    </div>
  );
};

BookingsToolbar.propTypes = {
  className: PropTypes.string
};

export default BookingsToolbar;
