import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import { SearchInput } from 'components';
import { useSelector, useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { authAdminAndGroupAdminMiddleWare } from 'util/authAdmin'
import { useHistory } from 'react-router-dom';
import { showSuccessSnackbar, showErrorSnackbar } from 'store/actions/snackbarMessage'
import { updateRestaurantkeyword } from 'store/actions/restaurant';
import API from 'util/API';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const RestaurantsToolbar = props => {
  const { className, ...rest } = props;
  const [uiLoading, setUiLoading] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.account.user);
  const selectedRestaurants = useSelector((state) => state.restaurant.selectedRestaurantIds);
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  const updateFilter = (key) => dispatch(updateRestaurantkeyword(key));

  const classes = useStyles();


  const accessRestaurant = () => {
    console.log('selectedRestaurants', selectedRestaurants);
    if (selectedRestaurants.length !== 1) {
      return;
    }
    authAdminAndGroupAdminMiddleWare(history, user);
    const authToken = localStorage.getItem('AuthToken');
    API.defaults.headers.common['Authorization'] = authToken;
    setUiLoading(true);
    API
      .put('/userRestaurantId', { restaurantId: selectedRestaurants[0] })
      .then((response) => {
        setUiLoading(false);
        showSuccessMessage("Updated successfully!");
      })
      .catch((error) => {
        console.log(error);
        setUiLoading(false);
        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }

        showErrorMessage('Error in updating the data');

      });


  };

  const filterRestaurant = (e) => {
     updateFilter(e.target.value);
  };


  const handleActiveFilter = (e) => {
    if (isFilterActive) {
      updateFilter('');
    } else {
      updateFilter('active');
    }
    setIsFilterActive(!isFilterActive);
    
 };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* <IconButton aria-label="delete">
          <DeleteIcon />
        </IconButton> */}
  

      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search restaurant" 
          onChange={filterRestaurant}
        />
        <Button variant="outlined" color="primary" onClick={handleActiveFilter}>{isFilterActive ? 'See all merchants': 'Filter active subscriptions'}</Button>
      </div>

    </div>
  );
};

RestaurantsToolbar.propTypes = {
  className: PropTypes.string
};

export default RestaurantsToolbar;
