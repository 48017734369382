import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionActions from "@material-ui/core/AccordionActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
}));

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const PromotionTypes = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Grid item md={6} xs={12}>
        <Accordion
          square
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Buy 2...n to get free items</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Set up a promotion for memebers</Typography>
          </AccordionDetails>
          <AccordionActions>
            <NavLink to="/buyNFree" className={classes.textLink}>
            <Button variant="outlined" size="large" color="primary">
              Start
            </Button>
            </NavLink>
          </AccordionActions>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>New member promotion</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>New member discounts</Typography>
          </AccordionDetails>
          <AccordionActions>
          <NavLink to="/newMemberPromotion" className={classes.textLink}>
            <Button variant="outlined" size="large" color="primary">
              Start
            </Button>
            </NavLink> 
          </AccordionActions>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Birthday promotion</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Set up birthday coupons</Typography>
          </AccordionDetails>

          <AccordionActions>
          <NavLink to="/birthdayPromotion" className={classes.textLink}>
            <Button variant="outlined" size="large" color="primary">
              Start
            </Button>
            </NavLink> 
          </AccordionActions>
        </Accordion>
      </Grid>
    </div>
  );
};

export default PromotionTypes;
