import API from 'util/API';


export const queryUsers = async (query) => {
  if(!query || query.length < 4) {
    return [];
  }

  const response = await API.get(`/queryUser?username=${query}`);
  if (response && response.data && response.data.users) {
    return response.data.users;
  }
  return [];
}

export const queryRestaurantUsers = async () => {
  const response = await API.get(`/merchantUsers`);
  if (response && response.data && response.data.users) {
    return response.data.users;
  }
  return [];
}