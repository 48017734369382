import React from "react";


import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import { transformOrderId } from "util/orderUtils";

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  spacer: {
    flexGrow: 1,
  },
  card: {
    margin: theme.spacing(1),
  },
  image: {
    width: "96%",
    height: undefined,

    borderWidth: 0,
    borderRadius: 10,
    margin: theme.spacing(1),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  centeredButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    fontSize: '1.5em', // Increase font size for easier tapping
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

export default function OBPaymentFailure(props) {
  const classes = useStyles();
  
  const reference = () => {
    return transformOrderId(props.match.params.orderNumber);
  };



    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
        
            <Typography>{`Order Number: ${reference()}`}</Typography>
          
          </Toolbar>
        </AppBar>
        <Container className={classes.container}>
          <Typography variant="h5"  color="error" >Payment failed</Typography>
          <Typography variant="body1">We were unable to process your payment</Typography>

          <div className={classes.centeredButton}>
        <Button 
      color="primary" 
      variant="contained" 
      style={{ padding: '10px 20px', fontSize: '16px',marginTop: '30px' }} // Larger tap area and readable text
    >
        <a href={window.location.href} target="_blank">Return to the Orderbuddy App</a>
      </Button>
    </div>
      </Container>
      </div>
    );
  
}
