import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";



import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Typography from "@material-ui/core/Typography";
import DishReport from "./DishReport";

import { showErrorSnackbar } from "store/actions/snackbarMessage";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import API from "util/API";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  table: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const DishesTransactionReport = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const [reportData, setReportData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const [showDishOptions, setShowDishOptions] = useState(true);


  const getReportData = async () => {
    if (endDate < startDate) {
      showError("The end date cannot be later than the start date!");
      return;
    }

    try {
      setIsLoading(true);
      setReportData([]);
      const startDateStr = formateDate(startDate);
      const endDateStr = formateDate(endDate);
      const rewsponse = await API.get(
        `/dishesTransactionReport?startDateStr=${startDateStr}&endDateStr=${endDateStr}&isUsingOption=${showDishOptions}`
      );
      const data = await rewsponse.data;
      
     

      setIsLoading(false);
      if (data) {
        setReportData(data.dishes);
      } else {
        setReportData([]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      setReportData([]);
      showError("Something went wrong, please try again.");
    }
  };

  const formateDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const queryDate = async () => {
    setReportData([]);
    // check start date and end date
    if (startDate > endDate) {
      showError("Start date must be before end date");
      return;
    }
    // check start date and end date is future date
    if (startDate > new Date() || endDate > new Date()) {
      showError("Start date and end date must be before today");
      return;
    }
    // check start date and end date gap is less than 7 days
    if (endDate - startDate > 32 * 24 * 60 * 60 * 1000) {
      showError("Start date and end date gap must be less than 32 days");
      return;
    }

    getReportData();
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <LinearProgress style={{ marginBottom: 8, marginTop: 8 }} />
      ) : null}

      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          container
          direction="row"
          alignItems="center"
        >
          <Typography style={{ margin: theme.spacing(1) }}>
            Start Date
          </Typography>
          <DatePicker
            selected={startDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setStartDate(date)}
          />
          <Typography style={{ margin: theme.spacing(1) }}>End Date</Typography>
          <DatePicker
            selected={endDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setEndDate(date)}
          />

          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: theme.spacing(1) }}
            onClick={(e) => queryDate()}
            disabled={isLoading}
          >
            Query
          </Button>
        </Grid>

        <Grid item md={12} lg={8} xl={6} xs={12}>
      

            <DishReport
              startDate={startDate}
              endDate={endDate}
              reportData={reportData}
              setShowDishOptions={setShowDishOptions}
              top={100}
            
            />
       
        </Grid>
      </Grid>
    </div>
  );
};

export default DishesTransactionReport;
