import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import TableHead from "@material-ui/core/TableHead";
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  table: {},
});

const OrderWechatRefunds = (props) => {
  const { refunds } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableCell colSpan="2">Refund Details</TableCell>
          </TableHead>
          <TableBody>
            {refunds &&
              refunds.length > 0 &&
              refunds.map((item) => (
                <>
                  <TableRow key={item.orderNo}>
                    <TableCell>Refund Status: </TableCell>
                    <TableCell align="left">
                      {item.orderStatus}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Refund Amount: </TableCell>
                    <TableCell align="left">
                      {item.totalAmount && `$${(Number(item.totalAmount) / 100).toFixed(2)}`}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Currency: </TableCell>
                    <TableCell align="left">{item.orderCurrency}</TableCell>
                  </TableRow>

    
                  <TableRow>
                    <TableCell colSpan="2"></TableCell>
                    
                  </TableRow>

                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

OrderWechatRefunds.propTypes = {
  refunds: PropTypes.array.isRequired,
};

export default OrderWechatRefunds;
