import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import LinearProgress from '@material-ui/core/LinearProgress';


import { useHistory } from 'react-router-dom';


import moment from 'moment';




const useStyles = makeStyles({
  table: {
   
  },
});


const OrderStripeRefunds = (props) => {
  const { refunds, order } = props;
  const classes = useStyles();
  const history = useHistory();
  const [buttonLoading, setButtonLoading] = useState(false);


  const orderCreateDate = (order) => {
    const dateFormat = 'DD-MM-YY';
    if ('_seconds' in order.createdAt) {
      return  moment(new Date(order.createdAt._seconds * 1000)).format(dateFormat)
    }
    return moment(order.createdAt).format(dateFormat)
  };

  const createdTime = (created) => {
    if (!created) {
      return '';
    }
    const dateFormat = 'DD/MM/YYYY HH:mm:ss';
   
    return moment(created * 1000).format(dateFormat)
  };


  return (
    <React.Fragment>
      {buttonLoading ?
                <LinearProgress /> : null}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableCell colSpan="2">Refund Details</TableCell>
           
          </TableHead>
          <TableBody>
              { refunds && refunds.data && refunds.data.length > 0 && refunds.data.map(item => 
           (
               <>
           <TableRow>
              <TableCell>Refund Amount: </TableCell>
              <TableCell align="left">{item.amount && `$${(item.amount / 100).toFixed(2)}`}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Currency: </TableCell>
              <TableCell align="left">{item.currency}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Refund Time: </TableCell>
              <TableCell align="left">{createdTime(item.created)}</TableCell>
            </TableRow>

           

            <TableRow>
              <TableCell>Result status: </TableCell>
              <TableCell align="left">{item.status}</TableCell>
            </TableRow>


           
            </>
            ))}
           

          </TableBody>
        </Table>
      </TableContainer>

    </React.Fragment>
  );
};

export default OrderStripeRefunds;
