import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import Radio from '@material-ui/core/Radio';

import FormControlLabel from '@material-ui/core/FormControlLabel';


import { v4 as uuid } from 'uuid';
import { removeItemWithSlice } from 'util/utils'



const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    buttonLowCase: {
        textTransform: "none"
    }
}));

const NewSizes = (props) => {
    const classes = useStyles();


    const [sizes, setSizes] = useState([]);


    useEffect(() => {

        setSizes(props.sizeList);


    }, [props]);

    const updateSizes = (newSizes, needUpdateParent) => {
        const theNewSizes = newSizes.map((item, index) => ({...item, sort: index}));
        setSizes(theNewSizes);
        if (needUpdateParent) {
            props.updateSizes(theNewSizes)
        }
    };


    const addNewSize = () => {
        let newSizes = [...sizes];
        newSizes.push({ id: uuid(), name: '', default: false, groups: [] });
        console.log('newSizes', newSizes);
        updateSizes(newSizes, false);
    };

    const updateName = (value, id) => {
        let newSizes = [...sizes];
        const index = newSizes.findIndex(size => size.id === id);
        if (index !== -1) {
            let size = newSizes[index];
            newSizes[index] = { ...size, name: value };
            updateSizes(newSizes, true);
        }
    };

    const updatePrice = (value, id) => {
        let newSizes = [...sizes];
        const index = newSizes.findIndex(size => size.id === id);
        if (index !== -1) {
            let size = newSizes[index];

            newSizes[index] = { ...size, price: value };
            updateSizes(newSizes, true);
        }
    };

    const deleteSize = (id) => {
        const index = sizes.findIndex(size => size.id === id);
        if (index !== -1) {
            const newSizes = removeItemWithSlice(sizes, index);
            updateSizes(newSizes, true);
        }
    };

    const updateSelected = (id, value) => {
        let newSizes = [...sizes];
        const index = newSizes.findIndex(size => size.id === id);
        if (index !== -1) {
            let size = newSizes[index];
            newSizes[index] = { ...size, default: !size.default };
            updateSizes(newSizes, true);
        }
    };


    return (

        <Grid container direction="column" spacing={2} >


            {sizes && sizes.length > 0 ? <>
                {sizes.map((size) => {
                    return <Grid item container direction="row" spacing={1} key={size.id} alignItems="center">
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Size Name"
                                margin="dense"
                                name="name"
                                variant="outlined"
                                value={size.name}
                                onChange={e => updateName(e.target.value, size.id)}
                            />
                        </Grid>



                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Size Price"
                                margin="dense"
                                name="price"
                                variant="outlined"
                                type="number"
                                value={size.price}
                                onChange={e => updatePrice(e.target.value, size.id)}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <IconButton aria-label="more" className={classes.margin} edge='end' color="secondary" onClick={e => deleteSize(size.id)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                        {/* <Button className={classes.buttonLowCase} variant="outlined" color="primary" size="small" onClick={e => deleteSize(size.id)}>Delete</Button> */}
                        <Grid item xs={2}>
                            <FormControlLabel control={<Radio color="primary" value={size.default} checked={size.default} onClick={e => updateSelected(size.id, e.target.value)} />} label="Selected" />
                        </Grid>


                    </Grid>
                })}
                <Grid item xs={4}>
                    <Button className={classes.buttonLowCase} variant="outlined" color="primary" size="small" onClick={addNewSize}>Add size</Button>
                </Grid>
            </> :
                <Grid item xs={4}>
                    <Button className={classes.buttonLowCase} variant="outlined" color="primary" onClick={addNewSize} size="medium">Multiple Sizes</Button>
                </Grid>
            }

        </Grid>

    );
};

export default NewSizes;
