import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';


import {

  LatestSales
 
} from '../Dashboard/components';


import {


  LatestOrders
 
} from './components';


import { useHistory } from 'react-router-dom';


import API from 'util/API';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));




const AdminDashboard = (props) => {
  const classes = useStyles();
  const history = useHistory();


  const [orderMonthDashboard, setOrderMonthDashboard] = useState([]);
  const [localOrders, setLocalOrders] = useState([]);
  const [onlineOrders, setOnlineOrders] = useState([]);
  const fectchTransactionMonthDashboard = () => {
  
    API
      .get('/adminSalesMonthDashboard')
      .then((response) => {
        //console.log('orderMonthDashboard.data', response.data);
        //loadTodayOrders(response.data)
        setOrderMonthDashboard(response.data);

      })
      .catch((error) => {
        console.log(error);

        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }
      });
  };

  

  const fectchCountReport = () => {
  
    API
      .get('/adminOrderCount')
      .then((response) => {
        
        
         if (response.data) {
             const tempOnlineCounts = response.data.filter(item => item.type === 'online');
             let onlineDict = {};
             tempOnlineCounts.forEach((item) => {
              onlineDict[item.day] = item
             })
             const tempLocalCounts = response.data.filter(item => item.type === 'local');

             let localCounts = [];
             let onlineCounts = [];
             tempLocalCounts.forEach((item) => {
                if (onlineDict[item.day]) {
                  localCounts.push({...item, count: item.count - onlineDict[item.day].count})
                  onlineCounts.push(onlineDict[item.day])
                } else {
                  localCounts.push(item);
                  onlineCounts.push({type: 'online', count: 0, day: item.day})
                }
             })

            
             setOnlineOrders(onlineCounts);
             setLocalOrders(localCounts);
             console.log(onlineCounts, localCounts);
         }
         

      })
      .catch((error) => {
        console.log(error);

        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }
      });
  };

  useEffect(() => {
  
    fectchTransactionMonthDashboard();
    fectchCountReport();

  }, [props]);


  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
 
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestSales orderMonthDashboard={orderMonthDashboard} />
        </Grid>

        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestOrders onlineOrders={onlineOrders} localOrders={localOrders} />
        </Grid>
       
      </Grid>
    </div>
  );
};

export default AdminDashboard;
