import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Typography
} from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {  transformOrderId } from "util/orderUtils";

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {},
    progess: {
        position: 'absolute'
    },
    table: {
        minWidth: 700,
    },
    textLink: {
        textDecoration: 'underline',
    },
    toolsBar: {
        marginBottom: theme.spacing(1)
    }
}));

const TransactionDetail = props => {
    const { className, transaction, currencySymbol, ...rest } = props;

    const classes = useStyles();

    const history = useHistory();
    const page = useSelector((state) => state.transaction.page);



    const getOrderTotal = (order) => {
        const total = order.dishes.reduce((count, curItem) => {
            return count + curItem.quantity * curItem.price;
        }, 0);
        return total.toFixed(2).toString();
    };

    const orderCreateTime = (order) => {
        if ('_seconds' in order.createdAt) {
            return moment(new Date(order.createdAt._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')
        }
        return moment(order.createdAt).format('DD/MM/YYYY HH:mm:ss')
    };

    useEffect(() => {
        if (!transaction) {
            history.push('/transactions');
        }

    }, [transaction]);

    return (
        <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
        >


            <NavLink to={`/transactions?page=${page}`} >

                <Grid container direction="row" className={classes.toolsBar}
                    justify="flex-start"
                    alignItems="center">
                    <ArrowBackIosIcon />
                    <Typography variant="h5">

                        Back
                </Typography>
                </Grid>
            </NavLink>

            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >

                <CardHeader
                    title={`Transaction Number: ${transaction && transaction.uuid}`}
                    subheader="Transaction details"

                />
                <Divider />
                <CardContent>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="spanning table">
                            <TableHead>

                                <TableRow>
                                    <TableCell>Order Number</TableCell>
                                    <TableCell align="left">Order Time</TableCell>
                                    <TableCell align="left">Dish Qty</TableCell>
                                    <TableCell align="left">Subtotal</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transaction && transaction.orders && transaction.orders.filter((order => !order.permanentDeletion)).map((row) => (
                                    <TableRow key={row.orderNumber}>
                                        <TableCell align="left" >
                                            <NavLink to={`/orderDetail/${row.orderNumber}`} className={classes.textLink}>
                                                <Typography variant="body1">
                                                    {transformOrderId(row.orderNumber)}
                                                </Typography>
                                            </NavLink>
                                        </TableCell>
                                        <TableCell align="left" >{orderCreateTime(row)}</TableCell>
                                        <TableCell>{row.dishes.map(dish => dish.quantity).reduce((a, b) => a + b, 0)}</TableCell>

                                        <TableCell align="left">{currencySymbol}{getOrderTotal(row)}</TableCell>

                                    </TableRow>
                                ))}


                                <TableRow>

                                    <TableCell >Transaction Type</TableCell>
                                    <TableCell align="right" colSpan={5}>{transaction && transaction.type}</TableCell>
                                </TableRow>
                                <TableRow>

                                    <TableCell >Total</TableCell>
                                    <TableCell align="right" colSpan={5}>{currencySymbol}{transaction && transaction.totalSpending.toFixed(2).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Table</TableCell>
                                    <TableCell align="right" colSpan={5}>{transaction && transaction.tableNo}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Table Operations</TableCell>
                                    <TableCell align="right" colSpan={5}>{transaction && transaction.operationString}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                                <TableCell >Customer Name:</TableCell>
                                <TableCell align="left">{order && order.user &&order.user.name}</TableCell>
                                <TableCell >Customer PhoneNo. :</TableCell>
                                <TableCell align="let">{order && order.user && order.user.phoneNumber}</TableCell>
                                <TableCell >Customer email:</TableCell>
                                <TableCell align="let">{order && order.user && order.user.email}</TableCell>
                            </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </CardContent>



            </Card>
        </Grid>
    );
};

TransactionDetail.propTypes = {
    className: PropTypes.string,
    transaction: PropTypes.object
};

export default TransactionDetail;
