import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import { SearchInput } from "components";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { useSelector, useDispatch } from "react-redux";

import { NavLink } from "react-router-dom";


import { copyMenu, deleteMenu } from "store/actions/restaurantMenu";
import { useConfirm } from "material-ui-confirm";
import { showErrorSnackbar, showSuccessSnackbar } from "store/actions/snackbarMessage";

import MenuCopyToDialog from "./MenuCopyToDialog";
import API from 'util/API';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  buttonSpacer: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const MenusToolbar = (props) => {
  const { className, selectedMenus, updateSelectedMenu, ...rest } = props;
  const [uiLoading, setUiLoading] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);



  const isLoading = useSelector((state) => state.restaurantMenu.isLoading);
  const user = useSelector((state) => state.account.user);

  const copyTheMenu = (menuId) => dispatch(copyMenu(menuId, null));

  const DeleteTheMenu = (menuId) => dispatch(deleteMenu(menuId));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));


  const [dialogOpen, setDialogOpen] = useState(false);
  const [groupRestaurants, setGroupRestaurants] = useState([]);
  const [checkedRestaurants, setCheckedRestaurants] = useState([]);

  const openDialog = () => {
    setGroupRestaurants(user.restaurants);
    setDialogOpen(true);
  };


  const handleCheckboxChange = (event) => {
    let newArray = [...checkedRestaurants, event.target.value];
    if (checkedRestaurants.includes(event.target.value)) {
      newArray = newArray.filter(restaurant => restaurant !== event.target.value);
    }
    setCheckedRestaurants(newArray);
  };



  const sendToGroup = () => {
    // console.log('selectedMenus', selectedMenus);
    if (!selectedMenus || selectedMenus.length !== 1) {
      showErrorMessage('Please select a menu first.')
      return;
    }
    openDialog();
  };

  useEffect(() => {
    if (isLoading === false) {
      setUiLoading(false);
    }
  }, isLoading);

  const classes = useStyles();

  const handleCopyMenu = () => {
    // console.log("selectedMenus", selectedMenus);
    if (!selectedMenus || selectedMenus.length !== 1) {
      showErrorMessage("Please select a menu first.");
      return;
    }
    setUiLoading(true);
    copyTheMenu(selectedMenus[0]);
    updateSelectedMenu([]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenTest = () => {
    setIsTest(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuDelete = () => {
    console.log("selectedMenus", selectedMenus);
    if (!selectedMenus || selectedMenus.length !== 1) {
      showErrorMessage("Please select only one menu to delete!");
      return;
    }
    confirm({ description: "You are about to delete this menu!" })
      .then(() => {
        setUiLoading(true);
        DeleteTheMenu(selectedMenus[0]);
      })
      .catch(() => {
        /* ... */
      });
  };


  // After selecting the restaurants, close the modal and send the data
  const afterSelectingRestaurants = async () => {
    setDialogOpen(false);
    setUiLoading(true);
    try {

      const result = await API.post('/sendMenu/' + selectedMenus[0], {
        restaurants: checkedRestaurants
      });

      setUiLoading(false);
      showSuccess("Sent successfully!");
    } catch (err) {
      setUiLoading(false);
      console.error(err);
      showErrorMessage('Something went wrong, please try again.')
    }
  };

  const selectAllRestaurants = () => {
    setCheckedRestaurants(groupRestaurants.map(restaurant => restaurant.restaurantId));
  };

  const unselectAllRestaurants = () => {
    setCheckedRestaurants([]);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {user && user.role === "admin" ? (
        <IconButton
          aria-label="delete"
          disabled={uiLoading}
          onClick={handleMenuDelete}
        >
          <DeleteIcon />
        </IconButton>
        ): null}
        {/* <IconButton aria-label="payment" onClick={openPaymentSettings}>
          <AccountBalanceIcon />
        </IconButton>
        <PaymentSettings open={open} /> */}

        <IconButton
          aria-label="copy"
          disabled={uiLoading}
          onClick={handleCopyMenu}
        >
          <FileCopyIcon />
        </IconButton>
        <span className={classes.buttonSpacer} />
        {user && user.role === "admin" ? (
          <>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<FileCopyIcon />}
              onClick={handleClickOpenTest}
              disabled={uiLoading}
              style={{ marginRight: '0.5rem' }}
            >
              Copy To Test
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<FileCopyIcon />}
              onClick={handleClickOpen}
              disabled={uiLoading}

            >
              Copy To
            </Button>



            <MenuCopyToDialog
              open={open}
              isTest={isTest}
              closeHandler={handleClose}
              selectedMenus={selectedMenus}
              updateSelectedMenu={updateSelectedMenu}
            />
            <span className={classes.buttonSpacer} />
          </>
        ) : null}
        {user && user.role === "restaurantGroupAdmin" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<FileCopyIcon />}
              onClick={sendToGroup}
              disabled={uiLoading}
            >
              Send to group
            </Button>
            <span className={classes.buttonSpacer} />
          </>
        ) : null}


        <NavLink to={"/menu"}>
          <Button color="primary" variant="contained" disabled={uiLoading}>
            Add Menu
          </Button>
        </NavLink>
      </div>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select Restaurants</DialogTitle>
        <DialogContent>

          {groupRestaurants.map((restaurant, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  value={restaurant.restaurantId}
                  checked={checkedRestaurants.includes(restaurant.restaurantId)}
                />
              }
              label={restaurant.name}
              key={index}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={selectAllRestaurants} color="primary">
            Select All
          </Button>

          <Button onClick={unselectAllRestaurants} color="primary">
            Unselect All
          </Button>

          <Button variant="contained" onClick={afterSelectingRestaurants} color="primary">
            Send to Selected
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search menu"
        />
      </div>
    </div>
  );
};

MenusToolbar.propTypes = {
  className: PropTypes.string,
};

export default MenusToolbar;
