import React, { useState, useEffect } from "react";


import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import moment from 'moment';

import {

    Switch,
    Grid,

} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3),
    },
  
  
    submitButton: {
      width: 200,
    }
  }));


QrCodeSettings.propTypes = {

};

function QrCodeSettings({ name, updateQRCodeSetting }) {
    const classes = useStyles();

    const [disabled, setDisabled] = useState(false);
    const [disableToday, setDisableToday] = useState(false);
    const [disableDate, setDisableDate] = useState(null);
    const restaurant = useSelector((state) => state.restaurant.selectedRestaurant);

    useEffect(() => {
        
        const setting = restaurant.qrCodeSettings && restaurant.qrCodeSettings.find(item => item.name === name);
        
        if (setting) {
            setDisabled(setting.disabled);
            const todayString = moment().format('DD/MM/YYYY');
            if (todayString === setting.disabledDate) {
                setDisableToday(true);
                setDisableDate(todayString);
            }
        }

    }, [restaurant])
    
   
    const handleSwitchChange = (event) => {
        const controlName = event.target.name;
        const value = event.target.checked;
        if (controlName === 'disabled') {
            setDisabled(value);
            updateQRCodeSetting(name, value, disableDate);
        }

        if (controlName === 'disableToday') {
            setDisableToday(value);
            if (value === true) {
                const todayString = moment().format('DD/MM/YYYY');
                updateQRCodeSetting(name, disabled, todayString);
                setDisableDate(todayString);
            } else {
                setDisableDate(null);
                updateQRCodeSetting(name, disabled, null);
                
            }
           
        }
    };

    return (
        <div>
            <Grid container column spacing={4} alignItems="flex-start" >
                <Grid item md={3} xs={12} >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={disabled}
                                onChange={handleSwitchChange}
                                name="disabled"
                                color="primary"
                            />
                        }
                        label="Disabled"
                    />
                </Grid>             
            </Grid>
        </div>
    );
}

export default QrCodeSettings;