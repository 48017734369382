import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import { POSClientListToolbar, POSClientTable } from "./components";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert } from "@material-ui/lab";

import { getAllPosClients } from "apis/devicesAPIs";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const POSClientList = (props) => {
  const classes = useStyles();


  const { isLoading, isError, data, error } = useQuery(
    "posClientList",
    getAllPosClients
  );

  if (isError) {
    return (
      <main className={classes.content}>
        <Alert severity="error">{`${error.message} `}</Alert>{" "}
      </main>
    );
  }

  if (isLoading === true) {
    return (
      <main className={classes.content}>
        {isLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </main>
    );
  } else {
    return (
      <div className={classes.root}>
        <POSClientListToolbar />
        <div className={classes.content}>
          {data ? <POSClientTable clientList={data}  /> : null}
        </div>
      </div>
    );
  }
};

export default POSClientList;
