import React, { useState, useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { Card, CardActions, CardContent, Divider, Button, Grid, TextField, Typography } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";

import clsx from "clsx";

import { authMiddleWare } from "../../util/auth";
import { useSelector, useDispatch } from "react-redux";

import { updateSelectedRestaurant } from "store/actions/restaurant";

import { RestaurantToolbar } from "./components";
import API from "util/API";
import { useHistory } from "react-router-dom";
import { showErrorSnackbar } from "store/actions/snackbarMessage";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },

  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  toolbar: theme.mixins.toolbar,
  root: {
    padding: theme.spacing(3),
  },
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progress: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
}));

const RestaurantGroup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState("");
  const [altName, setAltName] = useState("");
  const [address, setAddress] = useState("");

  const [abn, setAbn] = useState("");
  const [restaurantAdmin, setRestaurantAdmin] = useState("");
  const [restrauntDescription, setRestrauntDescription] = useState("");
  const [serviceMonthlyPrice, setServiceMonthlyPrice] = useState(49.95);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [uiLoading, setUiLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [urlIdentifier, setUrlIdentifier] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [stripeFeeOnUs, setStripeFeeOnUs] = useState(false);
  const [isApplicationFeeDisabled, setIsApplicationFeeDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [online, setOnline] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState(null);
  const [takeawayPayment, setTakeawayPayment] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [smsVerify, setSmsVerify] = useState(false);
  const [isFranchise, setIsFranchise] = useState(false);
  const [restaurantItems, setRestaurantItems] = useState([]);
  const [options, setOtions] = useState([]);
  const [value, setValue] = useState(null);

  const [paymentPartnerCode, setPaymentPartnerCode] = useState("");
  const [paymentPartnerCredential, setPaymentPartnerCredential] = useState("");
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  const restaurants = useSelector((state) => state.restaurant.restaurants);

  const saveMyRestaurant = (restaurant) => dispatch(updateSelectedRestaurant(restaurant));
  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  const getAllRestrauntIds = async () => {
    const result = await API.get("restaurantIds");
    await setOtions(result.data);
  };

  const deleteRestaurant = (deleteRestaurant) => {
    setRestaurantItems(
      restaurantItems.filter((restaurant) => restaurant.restaurantId !== deleteRestaurant.restaurantId)
    );
  };

  useEffect(() => {
    getAllRestrauntIds();
  }, [props]);

  useEffect(() => {
    const restaurantId = props.match.params.restaurantId;
    console.log("restaurantId", restaurantId);
    if (restaurantId && restaurants && restaurants.length > 0) {
      const restaurantIdx = restaurants.findIndex((item) => item.urlIdentifier === restaurantId);
      setSelectedRestaurant(restaurants[restaurantIdx]);
      saveMyRestaurant(restaurants[restaurantIdx]);
    }
  }, [props, restaurants]);

  useEffect(() => {
    console.log("selectedRestaurant", selectedRestaurant);
    if (selectedRestaurant) {
      setName(selectedRestaurant.name);
      setAltName(selectedRestaurant.altName);
      setAddress(selectedRestaurant.address);
      setRestrauntDescription(selectedRestaurant.restrauntDescription);
      setAbn(selectedRestaurant.abn);
      setUrlIdentifier(selectedRestaurant.urlIdentifier);
      setRestaurantAdmin(selectedRestaurant.restaurantAdmin);
      setPaymentPartnerCode(selectedRestaurant.paymentPartnerCode);
      setPaymentPartnerCredential(selectedRestaurant.paymentPartnerCredential);

      setOnline(selectedRestaurant.isOnline);
      if (selectedRestaurant.takeawayPayment === undefined) {
        setTakeawayPayment(true);
      } else {
        setTakeawayPayment(selectedRestaurant.takeawayPayment);
      }
      if (selectedRestaurant.phone) {
        setPhone(selectedRestaurant.phone);
      }

      if (selectedRestaurant.stripeFeeOnUs !== undefined) {
        setStripeFeeOnUs(selectedRestaurant.stripeFeeOnUs);
      }

      if (selectedRestaurant.latitude !== undefined) {
        setLatitude(selectedRestaurant.latitude);
      }

      if (selectedRestaurant.smsVerify !== undefined) {
        setSmsVerify(selectedRestaurant.smsVerify);
      }

      if (selectedRestaurant.isFranchise !== undefined) {
        setIsFranchise(selectedRestaurant.isFranchise);
      }

      if (selectedRestaurant.longitude !== undefined) {
        setLongitude(selectedRestaurant.longitude);
      }
      if (selectedRestaurant.serviceMonthlyPrice !== undefined && selectedRestaurant.serviceMonthlyPrice !== null) {
        setServiceMonthlyPrice(selectedRestaurant.serviceMonthlyPrice);
      }

      if (
        selectedRestaurant.isApplicationFeeDisabled !== undefined &&
        selectedRestaurant.isApplicationFeeDisabled !== null
      ) {
        setIsApplicationFeeDisabled(selectedRestaurant.isApplicationFeeDisabled);
      }

      if (
        selectedRestaurant.restaurants !== undefined &&
        selectedRestaurant.restaurants !== null
      ) {
        setRestaurantItems(selectedRestaurant.restaurants);
      }

    }
  }, [restaurants, selectedRestaurant]);

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const logoPictureHandler = (event) => {
    event.preventDefault();
    setUiLoading(true);
    authMiddleWare(props.history);
    // const authToken = localStorage.getItem("AuthToken");
    let form_data = new FormData();
    form_data.append("image", image);
    form_data.append("content", content);
    // API.defaults.headers.common["Authorization"] = authToken;
    API.post("/restaurant/image/" + urlIdentifier, form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(() => {
        setUiLoading(false);
        history.push("/restaurants");
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setUiLoading(false);
        setImageError("Error in posting the data");
      });
  };

  const handleErrorClose = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  const updateFormValues = (event) => {
    event.preventDefault();

    if (
      //  urlIdentifier.includes(" ") ||
      urlIdentifier.includes("&") ||
      urlIdentifier.includes("?") ||
      urlIdentifier.includes("%") ||
      urlIdentifier.includes("-") ||
      urlIdentifier.includes("_")
    ) {
      showError("The restaurant Id cannot include a space, %, &, -, _ and ?.");
      return;
    }

    setButtonLoading(true);
    authMiddleWare(props.history);
    const authToken = localStorage.getItem("AuthToken");
    API.defaults.headers.common["Authorization"] = authToken;
    //axios.defaults.headers.common = { Authorization: `${authToken}` };
    const formRequest = {
      name,
      altName,
      address: address,
      restrauntDescription: restrauntDescription,
      abn: abn,
      restaurantAdmin: restaurantAdmin,
      urlIdentifier: urlIdentifier,
      isOnline: online,
      takeawayPayment,
      phone,
      stripeFeeOnUs,
      latitude: Number(latitude),
      longitude: Number(longitude),
      smsVerify,
      isFranchise,
      serviceMonthlyPrice,
      isApplicationFeeDisabled,
      type: 'restaurantGroup',
      restaurants: restaurantItems

    };
    if (selectedRestaurant) {
      API.put("/restaurant/" + urlIdentifier, formRequest)
        .then(() => {
          setButtonLoading(false);
          props.history.push("/restaurants");
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            props.history.push("/sign-in");
          }
          console.log(JSON.stringify(error));
          setButtonLoading(false);
          setErrorMsg(error.msg);
          setOpenErrorSnackbar(true);
        });
    } else {
      API.post("/restaurant", formRequest)
        .then(() => {
          setButtonLoading(false);
          props.history.push("/restaurants");
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            props.history.push("/sign-in");
          }
          console.log(JSON.stringify(error));
          setButtonLoading(false);
          setErrorMsg(error.msg);
          setOpenErrorSnackbar(true);
        });
    }
  };

  {
    if (uiLoading === true) {
      return (
        <main className={classes.content}>
          {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
        </main>
      );
    } else {
      return (
        <div className={classes.root}>
          {selectedRestaurant ? <RestaurantToolbar restaurant={selectedRestaurant} /> : null}
          <div className={classes.content}>
            <Card className={clsx(classes.root, classes)}>
              <form autoComplete="off" noValidate>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    {selectedRestaurant && selectedRestaurant.logo ? (
                      <img src={selectedRestaurant.logo} alt="logo" height={100} width={200} />
                    ) : null}
                    <Grid item md={6} xs={12}>
                      <div>
                        <Typography gutterBottom variant="h4">
                          Upload Restaurant Logo
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          type="submit"
                          size="small"
                          startIcon={<CloudUploadIcon />}
                          className={classes.uploadButton}
                          onClick={logoPictureHandler}
                        >
                          Upload Logo
                        </Button>
                        <input type="file" onChange={handleImageChange} />

                        {imageError ? (
                          <div className={classes.customError}>
                            {" "}
                            Wrong Image Format || Supported Format are PNG and JPG
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Restaurant Name"
                        margin="dense"
                        name="name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Restaurant Alt Name"
                        margin="dense"
                        name="altName"
                        variant="outlined"
                        value={altName}
                        onChange={(e) => setAltName(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <IOSSwitch checked={online} onChange={(e) => setOnline(e.target.checked)} name="online" />
                        }
                        label="Online"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Restaurant description"
                        margin="dense"
                        name="restrauntDescription"
                        variant="outlined"
                        value={restrauntDescription}
                        onChange={(e) => setRestrauntDescription(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Address"
                        margin="dense"
                        name="address"
                        variant="outlined"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Phone"
                        margin="dense"
                        name="phone"
                        variant="outlined"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="ABN"
                        margin="dense"
                        name="abn"
                        type="number"
                        variant="outlined"
                        value={abn}
                        onChange={(e) => setAbn(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Restaurant identifier (used for order website)"
                        margin="dense"
                        name="urlIdentifier"
                        variant="outlined"
                        disabled={selectedRestaurant}
                        value={urlIdentifier}
                        onChange={(e) => setUrlIdentifier(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Retaurant Admin"
                        margin="dense"
                        name="restaurantAdmin"
                        variant="outlined"
                        value={restaurantAdmin}
                        onChange={(e) => setRestaurantAdmin(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Retaurant latitude"
                        margin="dense"
                        name="latitude"
                        variant="outlined"
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Retaurant longitude"
                        margin="dense"
                        name="longitude"
                        variant="outlined"
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                      />
                    </Grid>
                    <Divider />

                    <Grid item md={6} xs={12}>
                      <Typography>
                        Add Retaurants
                      </Typography>
                      <div className={classes.demo}>
                        <List dense={false}>
                          {restaurantItems.map((restaurant) => (
                            <ListItem key={restaurant.name}>
                              <ListItemText primary={restaurant.name} />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={(e) => deleteRestaurant(restaurant)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </div>

                      <Autocomplete
                        id="restaurantId"
                        options={options}
                        getOptionLabel={(option) => option.name}
                        style={{ minWidth: 300 }}
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                          if (newValue) {
                            setRestaurantItems([...restaurantItems, newValue]);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} label="Restaurant" variant="outlined" />}
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions />
              </form>
            </Card>
            <Snackbar open={openErrorSnackbar} autoHideDuration={2000} onClose={handleErrorClose}>
              <Alert onClose={handleErrorClose} severity="error">
                {errorMsg ? errorMsg : "Something went wrong! Please try again."}
              </Alert>
            </Snackbar>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.submitButton}
              onClick={updateFormValues}
              disabled={buttonLoading || !name || !urlIdentifier}
            >
              Save Restaurant Group
              {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
            </Button>
          </div>
        </div>
      );
    }
  }
};

export default RestaurantGroup;
