import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import constants from 'util/constants';

import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import theme from './theme';
import './assets/scss/index.scss';
import validators from './common/validators';

import { chartjs } from './helpers';

import { Provider } from 'react-redux';
import { store } from './store';



import Routes from './Routes';
import firebase from 'firebase/app';
import config from 'util/config';
import { MessageSnackbars } from 'components';
import { ConfirmProvider } from 'material-ui-confirm';
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

import analytics from 'common/analytics'
import ErrorBoundary from './ErrorBoundary'



// Check if firebase app is already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}


const cachedVersionKey = "cachedVersion"

/* Attach listeners once */
analytics.on('page', () => {
  console.log('Page')
})


const newQueryClient = new QueryClient();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

function isCrawler(userAgent) {
  const bots = [
    'bot',
    'crawler',
    'spider',
    'search',
    'googlebot',
    'bingbot',
    'yandexbot'
  ];

  for (let i = 0; i < bots.length; i++) {
    if (userAgent.indexOf(bots[i]) > -1) {
      return true;
    }
  }

  return false;
}

const App = () => {

  const [isBot, setIsBot] = useState(false);
  const cachedVersion = localStorage.getItem(cachedVersionKey);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (isCrawler(userAgent)) {
      setIsBot(true);
    }
    if (cachedVersion && String(constants.WEBSITE_VERSION) !== String(cachedVersion)) {
      localStorage.setItem(cachedVersionKey, constants.WEBSITE_VERSION);
      window.location.reload();
    }
    localStorage.setItem(cachedVersionKey, constants.WEBSITE_VERSION);
  }, []);

  if (isBot) {
    return null;
  }
  return (
    <ErrorBoundary>
    <QueryClientProvider client={newQueryClient}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>

        <MessageSnackbars />
        <ConfirmProvider>
          <Router>
            <div>

              <Routes />

            </div>
          </Router>
        </ConfirmProvider>
      </Provider>
    </ThemeProvider>
    </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
