import { FETCH_RESTAURANTS_SUCCESS, FETCH_ORDERS_SUCCESS, UPDATE_ORDERS_TYPE, 
  FETCH_CUSTOMERS_SUCCESS, SET_ORDER_DETAIL, SET_ORDERS_DATE, 
  SET_SELECTED_RESTAURANT, 
  SET_SELECTED_RESTAURANT_IDS,
  GET_TABLE_QRSTRING,
  UPDATE_FILTER_KEYWORD,
  UPDATE_RESTAURANT_VALUES,
  SET_SELECTED_BOOKINGSESSIONIDS,
  SET_BOOKING_SESSION_DETAIL
 } from '../actions/restaurant';

import { 
  API_SUCCESS,
  API_FAILURE,
  API_START,
  CLEAR_API_STATUS,
} from '../actions/common'

const initialState = {
  restaurants: [],
  filter: null,
  todayOrders: [],
  orderType: 'local', 
  customers: [],
  selectedOrder: null,
  orderSelectedDate: Date(),
  selectedRestaurant: null,
  selectedRestaurantIds: []
}

const restaurantReducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case API_START:
      return { ...state, isLoading: true, err: null, apiSuccess: null };

    case API_SUCCESS:
      console.log('api success');
      return { ...state, apiSuccess: true, isLoading: false };

    case API_FAILURE:
      return { ...state, err: payload, isLoading: false };

    case CLEAR_API_STATUS:
      return { ...state, err: null, apiSuccess: null };

    case FETCH_RESTAURANTS_SUCCESS:
      return { ...state, restaurants: action.payload };

    case FETCH_ORDERS_SUCCESS:
      return { ...state, todayOrders: action.payload };

    case UPDATE_ORDERS_TYPE:
      return { ...state, orderType: action.payload };

    case FETCH_CUSTOMERS_SUCCESS:
      return { ...state, customers: action.payload };
    
    case SET_ORDER_DETAIL:
      return { ...state, selectedOrder: action.payload };
    case SET_BOOKING_SESSION_DETAIL:
      return { ...state, selectedBookingSession: payload };
    case SET_ORDERS_DATE:
        return { ...state, orderSelectedDate: action.payload };
    case SET_SELECTED_RESTAURANT:
      return { ...state, selectedRestaurant: action.payload };
    case SET_SELECTED_RESTAURANT_IDS:
      return { ...state, selectedRestaurantIds: action.payload };
    case SET_SELECTED_BOOKINGSESSIONIDS:
      return { ...state, selectedBookingSessionIds: payload };

    case GET_TABLE_QRSTRING:
      return {...state, tableQrCode: payload.qrCode};

    case UPDATE_FILTER_KEYWORD:
      return { ...state, filter: payload};
    case UPDATE_RESTAURANT_VALUES:
      if (state.selectedRestaurant) {
        return { ...state, selectedRestaurant: {...state.selectedRestaurant, ...payload}};        
      } else {
        return state;
      }
      
    default:
      return state;
  }
};

export default restaurantReducer;
