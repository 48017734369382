import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import { setOrderDetail } from "store/actions/restaurant";
import API from "util/API";

import {
  OrderDetails,
  OrderStripePayments,
  OrderAdyenPayments,
  OrderRefunds,
  OrderStripeRefunds,
  OrderTillPayments,
  OrderTillRefunds,
  OrderAdyenRefunds,
  OrderWechatPayments,
  OrderWechatRefunds,
} from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  progess: {
    position: "absolute",
  },
  table: {
    minWidth: 700,
  },
}));

const OrderDetail = (props) => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const history = useHistory();

  const orderType = useSelector((state) => state.restaurant.orderType);

  const orderId = props.match.params.orderId;
  const selectedDate = useSelector((state) => state.restaurant.orderSelectedDate);
  const showOrderDetail = (order) => dispatch(setOrderDetail(order));
  const order = useSelector((state) => state.restaurant.selectedOrder);

  const fetchOrder = (orderNumber) => {
    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;

    API.get(
      "/order?dateStr=" + moment(selectedDate).format("DD-MM-YY") + `&orderType=${orderType}&orderNumber=${orderNumber}`
    )
      .then((response) => {
        console.log("response.data", response.data);
        //loadTodayOrders(response.data)
        if (response.data) {
          showOrderDetail(response.data);
        }
      })
      .catch((error) => {
        console.log(error);

        if (error.response && error.response.status === 403) {
          history.push("/sign-in");
        }
      });
  };

  useEffect(() => {
    console.log("selectOrder", order);
    if (order) {
      return;
    }
    if (orderId && orderId.trim() !== "") {
      fetchOrder(orderId);
    } else {
      if (!order) {
        history.push("/orders");
      }
    }
  }, [orderId]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={9} xs={12}>
          <OrderDetails order={order} />
        </Grid>

        {order && order.payment ? (
          <Grid item lg={12} md={12} xl={9} xs={12}>
            {order.payment && order.payment.paymentType === "Till" ? (
              <OrderTillPayments payment={order.payment} order={order} />
            ) : null}

            { order.payment && order.payment.paymentType === "Adyen Online"? (
              <OrderAdyenPayments payment={order.payment} order={order} />
            ) : null}


      { order.payment && order.payment.paymentMethod === "Wechat"? (
              <OrderWechatPayments payment={order.payment} order={order} />
            ) : null}

            {order.paymentIntent && order.payment ? (
              <OrderStripePayments payment={order.payment} order={order} />
            ) : null}
          </Grid>
        ) : null}

        {order && (order.refund || (order.payment && order.payment.refunds)) ? (
          <Grid item lg={12} md={12} xl={9} xs={12}>
            {order.paymentIntent && order.payment ? (
              <OrderStripeRefunds refunds={order.payment.refunds} order={order} />
            ) : (
              <OrderRefunds refund={order.refund} order={order} />
            )}
          </Grid>
        ) : null}

        {order && order.tillRefunds && order.tillRefunds.length > 0 ? (
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <OrderTillRefunds tillRefunds={order.tillRefunds} />
          </Grid>
        ) : null}

        {order && order.adyenRefunds && order.adyenRefunds.length > 0 ? (
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <OrderAdyenRefunds adyenRefunds={order.adyenRefunds} />
          </Grid>
        ) : null}

      {order && order.chinaPaymentsRefunds && order.chinaPaymentsRefunds.length > 0 ? (
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <OrderWechatRefunds refunds={order.chinaPaymentsRefunds} />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

OrderDetail.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object,
};

export default OrderDetail;
