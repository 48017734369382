import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import React, {useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showErrorSnackbar } from "store/actions/snackbarMessage";

import {
 
  emptyCart,

} from "store/actions/order";

const AdyenPaymentResult = (props) => {
  const order = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const history = useHistory();

  // get redirectResult and sessionId from url params
  const urlParams = new URLSearchParams(window.location.search);
  const redirectResult = urlParams.get("redirectResult");
  const sessionId = urlParams.get("sessionId");


  console.log("order", order);

  const configuration = {
    environment: "test", // Change to 'live' for the live environment.
    clientKey: "test_4X3IL5DV3ZG2PADDIPM2LT6F74HUTLM3", // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
    analytics: {
      enabled: false, // Set to false to not send analytics data to Adyen.
    },
    session: {
      id: sessionId, // Unique identifier for the payment session.
      sessionData: order.adyenSession.sessionData,
    },
    onPaymentCompleted: (result, component) => {
        console.log("onPaymentCompleted");
       
      console.info(result, component);
    },
    onError: (error, component) => {
      console.error(error.name, error.message, error.stack, component);
      submitPayment();
    },
    // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
    // For example, this is 3D Secure configuration for cards:
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: false,
      },
    },
  };

  const submitPayment = async () => {
    // Create an instance of AdyenCheckout using the configuration object.
    const checkout = await AdyenCheckout(configuration);
    console.log("configuration", configuration);

    checkout.submitDetails({ details: { redirectResult: redirectResult } });
  };

  useEffect(() => {
    console.log(redirectResult);
    if (redirectResult) {
        submitPayment();
    }
    
  }, [redirectResult]);

  return <div id="dropin-container"></div>;
};

export default AdyenPaymentResult;
