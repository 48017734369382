import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const OrderTypes = props => {
  const { className, orderDashboard, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [orderDashboard.takeawayCount, 
          orderDashboard.dineInCount,
          orderDashboard.deliveryCount,
        orderDashboard.pickupCount],
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.error.main,
          theme.palette.warning.main
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: ['Takeaway', 'Dine In', 'Delievery', 'Pick up']
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const totalCount = () => {
     return orderDashboard.takeawayCount + orderDashboard.pickupCount + 
     orderDashboard.deliveryCount + orderDashboard.dineInCount;
  };

  const getPercentages = (count) => {
        if (orderDashboard.orderCount === 0) {
          return '0';
        }
        return `$${(count * 100 / totalCount()).toFixed(0).toString()}`;
  };

  const devices = [
    {
      title: 'Takeaway',
      value: orderDashboard.takeawayCount,
      icon: <LocalMallIcon />,
      color: theme.palette.primary.main,
      percent: getPercentages(orderDashboard.takeawayCount)
    },
    {
      title: 'Dine In',
      value: props.orderDashboard ? props.orderDashboard.dineInCount : 0,
      icon: <RestaurantIcon />,
      color: theme.palette.error.main,
      percent: getPercentages(orderDashboard.dineInCount)
    },
    {
      title: 'Delivery',
      value: props.orderDashboard ? props.orderDashboard.deliveryCount : 0,
      icon: <DirectionsBikeIcon />,
      color: theme.palette.warning.main,
      percent: getPercentages(orderDashboard.deliveryCount)
    }, 
    {
      title: 'Pick up',
      value: props.orderDashboard ? props.orderDashboard.pickupCount : 0,
      icon: <DirectionsBikeIcon />,
      color: theme.palette.secondary.main,
      percent: getPercentages(orderDashboard.deliveryCount)
    }
  ];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
       
        title="Order Types"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />
        </div>
        <div className={classes.stats}>
          {devices.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h4"
              >
                {device.percent}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

OrderTypes.propTypes = {
  className: PropTypes.string
};

export default OrderTypes;
