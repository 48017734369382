import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Grid,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import Switch from "@material-ui/core/Switch";
import { useSelector, useDispatch } from "react-redux";
import { updateRestaurantValues } from "store/actions/restaurant";
import OrderCompleteMessage from "./OrderCompleteMessage";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(1) },
  progess: {
    position: "absolute",
  },
  bottomButtons: {
    marginTop: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1),
  },
}));

const OnlineCheckoutSettings = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const CHARACTER_LIMIT = 100;
  const CHARACTER_LIMIT_BIG = 200;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [hideSpecialRequirement, setHideSpecialRequirement] = useState(false);
  const [foodCourtPaymentRequired, setFoodCourtPaymentRequired] =
    useState(false);
  const [foodCourtPaymentOptional, setFoodCourtPaymentOptional] =
    useState(false);
  const [dineinTakeawayEnabled, setDineinTakeawayEnabled] = useState(false);
  const [foodCourtTakeawayEnabled, setFoodCourtTakeawayEnabled] =
    useState(false);
  const [isDineInMobilePhoneRequired, setIsDineInMobilePhoneRequired] =
    useState(false);
  const [isDineInGuestNumberRequired, setIsDineInGuestNumberRequired] =
    useState(false);
  const [isTillPaymentEnabled, setIsTillPaymentEnabled] = useState(false);
  const [isAdyenPaymentEnabled, setIsAdyenPaymentEnabled] = useState(false);
  const [isWechatPOSPaymentEnabled, setIsWechatPOSPaymentEnabled] =
    useState(false);
  const [isWechatOnlinePaymentEnabled, setIsWechatOnlinePaymentEnabled] =
    useState(false);
  const [isTakingAdyenEftposCharges, setIsTakingAdyenEftposCharges] =
    useState(false);
  const [
    isDisableAutoAcceptOrderWithoutLoc,
    setIsDisableAutoAcceptOrderWithoutLoc,
  ] = useState(false);
  const [allergyNotice, setAllergyNotice] = useState("");
  const [specialTerms, setSpecialTerms] = useState("");
  const [
    postpaidAutoAcceptAmountThreshold,
    setPostpaidAutoAcceptAmountThreshold,
  ] = useState(0);

  const [orderCompleteMessages, setOrderCompleteMessages] = useState(null);

  const handleOrderCompleteMessagesChange = (newValues) => {
    setOrderCompleteMessages(newValues);
  };

  const updateRestaurant = (values) => dispatch(updateRestaurantValues(values));
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  useEffect(() => {
    if (!restaurant) return;
    if (restaurant && restaurant.onlineSettings) {
      const onlineSettings = restaurant.onlineSettings;
      if (
        onlineSettings.hideSpecialRequirement !== null &&
        onlineSettings.hideSpecialRequirement !== undefined
      ) {
        setHideSpecialRequirement(onlineSettings.hideSpecialRequirement);
      }
      if (
        onlineSettings.foodCourtPaymentRequired !== null &&
        onlineSettings.foodCourtPaymentRequired !== undefined
      ) {
        setFoodCourtPaymentRequired(onlineSettings.foodCourtPaymentRequired);
      }

      if (
        onlineSettings.foodCourtPaymentOptional !== null &&
        onlineSettings.foodCourtPaymentOptional !== undefined
      ) {
        setFoodCourtPaymentOptional(onlineSettings.foodCourtPaymentOptional);
      }

      if (
        onlineSettings.dineinTakeawayEnabled !== null &&
        onlineSettings.dineinTakeawayEnabled !== undefined
      ) {
        setDineinTakeawayEnabled(onlineSettings.dineinTakeawayEnabled);
      }

      if (
        onlineSettings.foodCourtTakeawayEnabled !== null &&
        onlineSettings.foodCourtTakeawayEnabled !== undefined
      ) {
        setFoodCourtTakeawayEnabled(onlineSettings.foodCourtTakeawayEnabled);
      }

      if (
        onlineSettings.isDineInGuestNumberRequired !== null &&
        onlineSettings.isDineInGuestNumberRequired !== undefined
      ) {
        setIsDineInGuestNumberRequired(
          onlineSettings.isDineInGuestNumberRequired
        );
      }

      if (
        onlineSettings.isDineInMobilePhoneRequired !== null &&
        onlineSettings.isDineInMobilePhoneRequired !== undefined
      ) {
        setIsDineInMobilePhoneRequired(
          onlineSettings.isDineInMobilePhoneRequired
        );
      }

      if (
        onlineSettings.isTillPaymentEnabled !== null &&
        onlineSettings.isTillPaymentEnabled !== undefined
      ) {
        setIsTillPaymentEnabled(onlineSettings.isTillPaymentEnabled);
      }

      if (
        onlineSettings.specialTerms !== null &&
        onlineSettings.specialTerms !== undefined
      ) {
        setSpecialTerms(onlineSettings.specialTerms);
      }

      if (
        onlineSettings.allergyNotice !== null &&
        onlineSettings.allergyNotice !== undefined
      ) {
        setAllergyNotice(onlineSettings.allergyNotice);
      }

      if (
        onlineSettings.postpaidAutoAcceptAmountThreshold !== null &&
        onlineSettings.postpaidAutoAcceptAmountThreshold !== undefined
      ) {
        setPostpaidAutoAcceptAmountThreshold(
          onlineSettings.postpaidAutoAcceptAmountThreshold
        );
      }

      if (
        onlineSettings.isAdyenPaymentEnabled !== null &&
        onlineSettings.isAdyenPaymentEnabled !== undefined
      ) {
        setIsAdyenPaymentEnabled(onlineSettings.isAdyenPaymentEnabled);
      }

      if ( restaurant.posSettings &&
        restaurant.posSettings.isWechatPOSPaymentEnabled !== null &&
        restaurant.posSettings.isWechatPOSPaymentEnabled !== undefined
      ) {
        setIsWechatPOSPaymentEnabled(
          restaurant.posSettings.isWechatPOSPaymentEnabled
        );
      }

      if (
        restaurant.posSettings &&
        restaurant.posSettings.isTakingAdyenEftposCharges !== null &&
        restaurant.posSettings.isTakingAdyenEftposCharges !== undefined
      ) {
        setIsTakingAdyenEftposCharges(
          restaurant.posSettings.isTakingAdyenEftposCharges
        );
      }

      if (
        restaurant.posSettings &&
        restaurant.posSettings.isDisableAutoAcceptOrderWithoutLoc !== null &&
        restaurant.posSettings.isDisableAutoAcceptOrderWithoutLoc !== undefined
      ) {
        setIsDisableAutoAcceptOrderWithoutLoc(
          restaurant.posSettings.isDisableAutoAcceptOrderWithoutLoc
        );
      }

      if (
        onlineSettings.orderCompleteMessages !== null &&
        onlineSettings.orderCompleteMessages !== undefined
      ) {
        setOrderCompleteMessages(onlineSettings.orderCompleteMessages);
      }

      if (
        onlineSettings.isWechatOnlinePaymentEnabled !== null &&
        onlineSettings.isWechatOnlinePaymentEnabled !== undefined
      ) {
        setIsWechatOnlinePaymentEnabled(
          onlineSettings.isWechatOnlinePaymentEnabled
        );
      }
    }
  }, [restaurant]);

  const isReadySubmit = () => {
    return true;
  };

  const updateSettings = (event) => {
    event.preventDefault();
    if (!isReadySubmit()) {
      return;
    }

    setButtonLoading(true);
    let onlineSettings = {
      hideSpecialRequirement,
      orderCompleteMessages,
    };
    if (restaurant && restaurant.onlineSettings) {
      onlineSettings = {
        ...restaurant.onlineSettings,
        hideSpecialRequirement,
        foodCourtPaymentRequired,
        foodCourtPaymentOptional,
        dineinTakeawayEnabled,
        foodCourtTakeawayEnabled,
        isDineInMobilePhoneRequired,
        isDineInGuestNumberRequired,
        isTillPaymentEnabled,
        isAdyenPaymentEnabled,
        specialTerms,
        allergyNotice,
        postpaidAutoAcceptAmountThreshold,
        orderCompleteMessages,
        isWechatOnlinePaymentEnabled,
      };
    }
    let posSettings = {
      isTakingAdyenEftposCharges,
    };
    if (restaurant && restaurant.posSettings) {
      posSettings = {
        ...restaurant.posSettings,
        isTakingAdyenEftposCharges,
        isDisableAutoAcceptOrderWithoutLoc,
        isWechatPOSPaymentEnabled,
      };
    }

    updateRestaurant({ onlineSettings, posSettings });
    setButtonLoading(false);
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "hideSpecialRequirement") {
      setHideSpecialRequirement(value);
      return;
    }
    if (name === "foodCourtPaymentRequired") {
      setFoodCourtPaymentRequired(value);
      if (value) {
        setFoodCourtPaymentOptional(false);
      }
      return;
    }

    if (name === "foodCourtPaymentOptional") {
      setFoodCourtPaymentOptional(value);
      if (value) {
        setFoodCourtPaymentRequired(false);
      }
      return;
    }

    if (name === "dineinTakeawayEnabled") {
      setDineinTakeawayEnabled(value);
      return;
    }

    if (name === "foodCourtTakeawayEnabled") {
      setFoodCourtTakeawayEnabled(value);
      return;
    }

    if (name === "isDineInGuestNumberRequired") {
      setIsDineInGuestNumberRequired(value);
      return;
    }

    if (name === "isDineInMobilePhoneRequired") {
      setIsDineInMobilePhoneRequired(value);
      return;
    }

    if (name === "isTillPaymentEnabled") {
      setIsTillPaymentEnabled(value);
      if (value === true) {
        setIsAdyenPaymentEnabled(false);
      }
      return;
    }

    if (name === "isAdyenPaymentEnabled") {
      setIsAdyenPaymentEnabled(value);
      if (value === true) {
        setIsTillPaymentEnabled(false);
      }
      return;
    }

    if (name === "isTakingAdyenEftposCharges") {
      setIsTakingAdyenEftposCharges(value);
      return;
    }

    if (name === "isDisableAutoAcceptOrderWithoutLoc") {
      setIsDisableAutoAcceptOrderWithoutLoc(value);
      return;
    }

    if (name === "isWechatPOSPaymentEnabled") {
      setIsWechatPOSPaymentEnabled(value);
      return;
    }

    if (name === "isWechatOnlinePaymentEnabled") {
      setIsWechatOnlinePaymentEnabled(value);
      return;
    }
  };

  return (
    <Card {...rest} className={classes.root}>
      <form>
        <CardHeader
          subheader="Online ordering"
          title="Online Ordering Settings"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap" row>
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={hideSpecialRequirement}
                    onChange={handleSwitchChange}
                    name="hideSpecialRequirement"
                    color="primary"
                  />
                }
                label="Hide Special Requirements"
              />
              {hideSpecialRequirement === true ? (
                <Typography color="error" display="block">
                  Customers are not able to enter requirements and food
                  allergies.
                </Typography>
              ) : null}
            </Grid>

            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={foodCourtPaymentRequired}
                    onChange={handleSwitchChange}
                    name="foodCourtPaymentRequired"
                    color="primary"
                  />
                }
                label="Foodcourt Payment"
              />
            </Grid>

            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={foodCourtPaymentOptional}
                    onChange={handleSwitchChange}
                    name="foodCourtPaymentOptional"
                    color="primary"
                  />
                }
                label="Is Food court payment optional"
              />
            </Grid>
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={dineinTakeawayEnabled}
                    onChange={handleSwitchChange}
                    name="dineinTakeawayEnabled"
                    color="primary"
                  />
                }
                label="Dine-in Takeaway"
              />
            </Grid>

            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={foodCourtTakeawayEnabled}
                    onChange={handleSwitchChange}
                    name="foodCourtTakeawayEnabled"
                    color="primary"
                  />
                }
                label="Foodcourt Takeaway"
              />
            </Grid>

            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isDineInMobilePhoneRequired}
                    onChange={handleSwitchChange}
                    name="isDineInMobilePhoneRequired"
                    color="primary"
                  />
                }
                label="Dine In Checkout Mobile Phone Required"
              />
            </Grid>

            <Grid className={classes.item} item md={4} sm={3} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isDineInGuestNumberRequired}
                    onChange={handleSwitchChange}
                    name="isDineInGuestNumberRequired"
                    color="primary"
                  />
                }
                label="Dine In Checkout Guest Number Required"
              />
            </Grid>

            <Grid className={classes.item} item md={4} sm={3} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isDisableAutoAcceptOrderWithoutLoc}
                    onChange={handleSwitchChange}
                    name="isDisableAutoAcceptOrderWithoutLoc"
                    color="primary"
                  />
                }
                label="Manual Dine in Orders when the location is not provided"
              />
            </Grid>
          </Grid>
          <Divider className={classes.title} />
          <Typography variant="h5" className={classes.title}>
            {" "}
            Payments
          </Typography>
          <Grid container spacing={6} wrap="wrap" row>
            <Grid className={classes.item} item md={3} sm={3} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAdyenPaymentEnabled}
                    onChange={handleSwitchChange}
                    name="isAdyenPaymentEnabled"
                    color="primary"
                  />
                }
                label="Adyen Payment"
              />
            </Grid>

            <Grid className={classes.item} item md={3} sm={3} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isWechatPOSPaymentEnabled}
                    onChange={handleSwitchChange}
                    name="isWechatPOSPaymentEnabled"
                    color="primary"
                  />
                }
                label="Wechat POS Payment"
              />
            </Grid>

       

            <Grid className={classes.item} item md={3} sm={3} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isWechatOnlinePaymentEnabled}
                    onChange={handleSwitchChange}
                    name="isWechatOnlinePaymentEnabled"
                    color="primary"
                  />
                }
                label="Wechat Online Payment"
              />
            </Grid>
            </Grid>

          
          
          <Grid container spacing={3} wrap="wrap" column>
          <Grid className={classes.item} item md={6} sm={6} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isTakingAdyenEftposCharges}
                    onChange={handleSwitchChange}
                    name="isTakingAdyenEftposCharges"
                    color="primary"
                  />
                }
                label="We take EFTPOS surcharge on ourselves"
              />
            </Grid>
            <Grid className={classes.item} item md={6} sm={6} xs={6}>
              <Typography>
                {" "}
                By checking the checkbox, the business or organization would
                indicate their decision to bear the eftpos savings surcharge
                cost internally. Please note that this setting applies only to
                EFTPOS surcharges and will not affect credit card surcharges.
              </Typography>
            </Grid>
          </Grid>

          {/* <Divider className={classes.title}/>
          <Grid className={classes.item} item md={8} sm={6} xs={12}>
            <Button 
              color="primary"
              variant="contained"
              type="submit"
            onClick={handleAdyenOnboarding}> Onboarding Adyen</Button>
            </Grid> */}

          <Divider className={classes.title} />
          <Grid className={classes.item} item md={8} sm={6} xs={12}>
            <TextField
              className={classes.bottomButtons}
              id="allergyNotice"
              label="Allergy Notice (on the dish detail screen)"
              multiline
              rows={4}
              fullWidth
              inputProps={{
                maxlength: CHARACTER_LIMIT_BIG,
              }}
              value={allergyNotice}
              variant="outlined"
              helperText={`${allergyNotice.length}/${CHARACTER_LIMIT_BIG}`}
              placeholder="Allergy Notice"
              onChange={(e) => setAllergyNotice(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid className={classes.item} item md={8} sm={6} xs={12}>
            <TextField
              className={classes.bottomButtons}
              id="specialTerms"
              label="Special Terms (on the checkout screen)"
              multiline
              rows={4}
              fullWidth
              inputProps={{
                maxlength: CHARACTER_LIMIT,
              }}
              value={specialTerms}
              variant="outlined"
              helperText={`${specialTerms.length}/${CHARACTER_LIMIT}`}
              placeholder="Special Terms"
              onChange={(e) => setSpecialTerms(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid className={classes.item} item md={8} sm={6} xs={12}>
            <OrderCompleteMessage
              onValueChange={handleOrderCompleteMessagesChange}
              messages={orderCompleteMessages}
            />
            <Divider />
          </Grid>

          <Grid className={classes.item} item md={8} sm={6} xs={12}>
            <TextField
              className={classes.bottomButtons}
              type="number"
              id="postpaidAutoAcceptAmountThreshold"
              label="Postpaid Auto Accept Amount Threshold"
              value={postpaidAutoAcceptAmountThreshold}
              variant="outlined"
              helperText="Below this amount, the order will be auto accepted"
              placeholder="Threshold"
              onChange={(e) =>
                setPostpaidAutoAcceptAmountThreshold(Number(e.target.value))
              }
            />
          </Grid>
        </CardContent>
        <Divider />

        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={updateSettings}
            disabled={!isReadySubmit() || buttonLoading}
          >
            Update
            {buttonLoading && (
              <CircularProgress size={30} className={classes.progess} />
            )}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

OnlineCheckoutSettings.propTypes = {
  className: PropTypes.string,
};

export default OnlineCheckoutSettings;
