import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Grid,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";


// import TextField from "@material-ui/core/TextField";

import Switch from "@material-ui/core/Switch";
import { useSelector, useDispatch } from "react-redux";
import { updateRestaurantValues } from "store/actions/restaurant";
import CheckMyRestaurant from "../../CheckMyRestaurant";


const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(4) },
  progess: {
    position: "absolute",
  },
  bottomButtons: {
    marginTop: theme.spacing(1),
  },
}));

const POSOrderSettings = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  // const CHARACTER_LIMIT = 100;
  // const CHARACTER_LIMIT_BIG = 200;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disableOrderDishPin, setDisableOrderDishPin] = useState(false);
  const [disablePaymentPin, setDisablePaymentPin] = useState(false);
  const [directTransferLocalDiningOrders, setDirectTransferLocalDiningOrders] = useState(false);


  const updateRestaurant = (values) => dispatch(updateRestaurantValues(values));
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  useEffect(() => {
    if (!restaurant || !restaurant.posOrderingSettings) {
      return;
    }
    const posOrderingSettings = restaurant.posOrderingSettings;

    if (
      posOrderingSettings.disableOrderDishPin !== null &&
      posOrderingSettings.disableOrderDishPin !== undefined
    ) {
      setDisableOrderDishPin(posOrderingSettings.disableOrderDishPin);
    }

    if (
      posOrderingSettings.disablePaymentPin !== null &&
      posOrderingSettings.disablePaymentPin !== undefined
    ) {
      setDisablePaymentPin(posOrderingSettings.disablePaymentPin);
    }

    if (posOrderingSettings.directTransferLocalDiningOrders !== null && posOrderingSettings.directTransferLocalDiningOrders !== undefined) {
      setDirectTransferLocalDiningOrders(posOrderingSettings.directTransferLocalDiningOrders);
    }


  }, [restaurant]);

  const isReadySubmit = () => {
    return true;
  };

  const updateSettings = (event) => {
    event.preventDefault();
    if (!isReadySubmit()) {
      return;
    }

    setButtonLoading(true);

    updateRestaurant({ posOrderingSettings: { disableOrderDishPin, disablePaymentPin, directTransferLocalDiningOrders } });
    setButtonLoading(false);
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "disableOrderDishPin") {
      setDisableOrderDishPin(value);
      return;
    }

    if (name === "disablePaymentPin") {
      setDisablePaymentPin(value);
      return;
    }

    if (name === "directTransferLocalDiningOrders") {
      setDirectTransferLocalDiningOrders(value);
      return;
    }

  };

  return restaurant ? (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader subheader="order settings" title="POS Order settings" />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap" row>
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={disableOrderDishPin}
                    onChange={handleSwitchChange}
                    name="disableOrderDishPin"
                    color="primary"
                  />
                }
                label="Disable order passcode"
              />

            </Grid>

            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={disablePaymentPin}
                    onChange={handleSwitchChange}
                    name="disablePaymentPin"
                    color="primary"
                  />
                }
                label="Disable payment passcode"
              />

            </Grid>

            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={directTransferLocalDiningOrders}
                    onChange={handleSwitchChange}
                    name="directTransferLocalDiningOrders"
                    color="primary"
                  />
                }
                label="Direct transfer local table paid Dining orders to Payment History"
              />
            </Grid>


          </Grid>


        </CardContent>
        <Divider />

        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={updateSettings}
            disabled={!isReadySubmit() || buttonLoading}
          >
            Update
            {buttonLoading && (
              <CircularProgress size={30} className={classes.progess} />
            )}
          </Button>
        </CardActions>
      </form>
    </Card>
  ) : <CheckMyRestaurant />;
};

POSOrderSettings.propTypes = {
  className: PropTypes.string,
};

export default POSOrderSettings;
