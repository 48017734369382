import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {  Grid, MenuItem, Box } from "@material-ui/core";
import ReactHookFormSelect from "./Components/ReactHookFormSelect";
import moment from "moment";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";

import { showErrorSnackbar } from "store/actions/snackbarMessage";

import {  useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "-1rem 0 2rem 0",
    padding: "0 7rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
    marginTop: "auto",
  },
  textField: {
    width: "100%",
  },
  errorMessage: {
    color: "red",
    fontSize: "0.9rem",
    marginTop: "0.2rem",
  },
  select: {
    variant: "outlined",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

function required(displayName) {
  return function validateRequired(value) {
    // console.log("VALIDATING: ", displayName, value);
    return (
      (value !== null && value !== undefined) || `${displayName} is required.`
    );
  };
}

export default function Information({
  formProps: { register, errors, control, setValue, formState },
  data,
  sessions,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { partySize, bookDateStr, sessionId } = data[0];
  const [selectedSession, setSelectedSession] = useState(null);
  const [error, setError] = useState(false);
  const [dateChanged, setDateChanged] = useState(false);
  const [helperText, setHelperText] = useState("");
  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  const [startDate, setStartDate] = useState(null);
  const bookDateFieldName = "bookDateStr";
  const sessionIdFieldName = "sessionId";

  useEffect(() => {
    register(
      { name: "bookDateStr", type: "text" },
      { validate: required("Book date") }
    );
    register({ name: "sessionId", type: "text" });
  }, [register]);

  useEffect(() => {
    if (bookDateStr) {
      setStartDate(moment(bookDateStr, "YYYY-MM-DD"));
    }
  }, [data]);

  useEffect(() => {
    setSelectedSession(sessionId);
  }, [data]);

  useEffect(() => {
    setDateChanged(false);
  }, [sessions]);

  const handleDateChange = (date) => {
    // console.log("date", date);
    if (moment(date).isBefore(new moment().subtract(1, "days"))) {
      showError("Please select a future date");
      return;
    }
    setStartDate(date);
    setValue(bookDateFieldName, moment(date).format("YYYY-MM-DD"));
    setValue(sessionIdFieldName, null);
    setDateChanged(true);
  };

  const handleRadioChange = (event) => {
    console.log("event.target.value", event.target.value);
    setSelectedSession(event.target.value);
    setValue(sessionIdFieldName, event.target.value);
    setHelperText(" ");
    setError(false);
  };

  const getSessionTitle = (session) => {
    if (session.disableEndTime === true) {
      return `${session.startTime} ${session.depositRequired ? '(deposit: $' + session.deposit + ')' : ''}`;
    } else {
      return `${session.startTime} - ${session.endTime} ${session.depositRequired ? '(deposit: $' + session.deposit + ')' : ''}`;
    }
  };

  const changePartySize = (event) => {
    console.log("event.target.value", event.target.value);
    
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item md={12} xs={12}>
            <ReactHookFormSelect
              id="partySize"
              name="partySize"
              className={classes.textField}
              label="Party Size"
              control={control}
              error={!!errors.partySize}
              defaultValue={partySize || ""}
              variant="outlined"
              margin="normal"
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="11">11</MenuItem>
              <MenuItem value="12">12</MenuItem>
              <MenuItem value="13">13</MenuItem>
              <MenuItem value="14">14</MenuItem>
              <MenuItem value="15">15</MenuItem>
              <MenuItem value="16">16</MenuItem>
              <MenuItem value="17">17</MenuItem>
              <MenuItem value="18">18</MenuItem>
              <MenuItem value="19">19</MenuItem>
              <MenuItem value="20">20</MenuItem>
            </ReactHookFormSelect>
            {errors.partySize && (
              <p className={classes.errorMessage}>{errors.partySize.message}</p>
            )}

          </Grid>
          <Grid item md={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date"
                autoOk={true}
                value={startDate}
                onChange={handleDateChange}
                error={errors.hasOwnProperty(bookDateFieldName)}
                helperText={errors.bookDateStr && errors.bookDateStr.message}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {sessions && sessions.length > 0 && !dateChanged ? (
            <Grid item md={12} xs={12}>
              <FormControl component="fieldset" error={error}>
                <FormLabel component="legend">Time slots</FormLabel>
                <RadioGroup
                  aria-label="session"
                  name="customized-radios"
                  value={selectedSession}
                  onChange={handleRadioChange}
                >
                  {sessions.map((session) => {
                    return (
                      <FormControlLabel
                        key={session.id}
                        value={session.id}
                        control={<Radio />}
                        label={getSessionTitle(session)}
                      />
                    );
                  })}
                </RadioGroup>
                <FormHelperText>{helperText}</FormHelperText>
              </FormControl>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Fragment>
  );
}
