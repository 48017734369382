import React, { useState }from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';



const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    padding: theme.spacing(2), 
    margin: theme.spacing(2)
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function NewCategory() {
  const classes = useStyles();
  const [categoryDescription, setCategoryDescription] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [altName, setAltName] = useState('');

   
  return (
    <Card className={classes.root}>
      <CardContent>
      <Grid item md={6} xs={12}>
											<TextField
												fullWidth
												label="Category Name"
												margin="dense"
												name="categoryName"
												variant="outlined"
												value={categoryName}
												onChange={e => setCategoryName(e.target.value)}
											/>
										</Grid>

                        <Grid item md={6} xs={12}>
											<TextField
												fullWidth
												label="Category Description"
												margin="dense"
												name="categoryDescription"
												variant="outlined"
												value={categoryDescription}
												onChange={e => setCategoryDescription(e.target.value)}
											/>
										</Grid>

                    <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Category Alternative Name"
            margin="dense"
            name="altName"
            variant="outlined"
            value={altName}
            onChange={e => setAltName(e.target.value)} 
            onDragStart={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Grid>
      </CardContent>
      <CardActions>
      <Button  variant="contained">Cancel</Button>  <Button  variant="contained">Save</Button>
      </CardActions>
    </Card>
  );
}
