import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StarIcon from "@material-ui/icons/Star";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Checkbox } from "@material-ui/core";

import { addOption, removeOption, updateOptionQty } from "store/actions/dish";
import { useSelector, useDispatch } from "react-redux";
import { filterOption } from "util/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inner: {
    minWidth: 1050,
  },
  nested: {
    // marginLeft: theme.spacing(2),
    padding: 0,
  },
  listHeader: {
    fontSize: 14,
  },
  cellSize: {
    fontSize: 16,
  },
  buttonG: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
    overflow: "auto",
  },
}));

const DishGroup = (props) => {
  const { className, group, currencySymbol } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  
  //console.log('group', group);

  const selectOption = (option) => dispatch(addOption(option));
  const unSelectOption = (option) => dispatch(removeOption(option));
  const changeOptionQty = (id, qty) => dispatch(updateOptionQty(id, qty));

  const selectedOptions = useSelector((state) => state.dish.options);
  const hidedDishes = useSelector((state) => state.menu.hidedDishes);

  // console.log("hidedDishes", hidedDishes);

  const requiredText = () => {
    if (props.group.required) {
      return ` - Required min-${props.group.force_min} max-${props.group.force_max}`;
    }
    if (props.group.force_max !== 0 && props.group.force_min !== 0) {
      return ` - Optional min-${props.group.force_min} max-${props.group.force_max}`;
    }
    if (props.group.force_max !== 0 && props.group.force_min === 0) {
      return ` - Optional max-${props.group.force_max}`;
    }
    if (props.group.force_max === 0 && props.group.force_min !== 0) {
      return ` - Optional min-${props.group.force_min}`;
    }
    return "";
  };



  const findIndex = (option) => {
    return selectedOptions.findIndex((item) => item.id === option.id);
  };

  const currentOptionQty = (option) => {
    const currentIndex = findIndex(option);
    if (currentIndex === -1) {
      return 0;
    }
    return selectedOptions[currentIndex].quantity;
  };

  const onlyChooseOne = (option) => {
    props.group.options.forEach((option) => {
      unSelectOption(option);
    });
    selectOption(option);
  };

  const handleSelectOne = (event, option) => {
   

    const selectedIndex = findIndex(option);

    // console.log('add option', option, selectedIndex);
    if (selectedIndex === -1) {
      if (props.group.force_max == 1) {
        onlyChooseOne(option);
        return;
      } else {
        selectOption(option);
      }
     
    } else {
      unSelectOption(option);
    }
  };

  const increaseQuantity = (e, option) => {
    changeOptionQty(option.id, currentOptionQty(option) + 1);
  };

  const decreaseQuantity = (e, option) => {
    const qty = currentOptionQty(option);
    if (qty === 1) {
      return;
    }
    // props.optionQuanityCallback(props.option, quantity - 1);
    changeOptionQty(option.id, qty - 1);
  };

  return (
    <List dense={true} disablePadding={true}>
      <ListItem dense={true}>
        {props.group.required ? (
          <ListItemIcon>
            <StarIcon color="primary" />
          </ListItemIcon>
        ) : null}
        <Typography color={props.group.required ? "primary" : "text.primary"}>
          {props.group.name} {requiredText()}
        </Typography>
      </ListItem>

      {group &&
        group.options &&
        group.options.filter((option) => {
           return filterOption(option, hidedDishes);
        }).map((option) => (
          <ListItem dense={true} key={option.id}>
            <Checkbox
              checked={findIndex(option) !== -1}
              color="primary"
              onChange={(event) => handleSelectOne(event, option)}
              value="true"
            />

            <Typography> {`${option.name} ${currencySymbol}${option.price}`} </Typography>

            {findIndex(option) !== -1 && props.group.allow_quantity ? (
              <React.Fragment>
                <ButtonGroup variant="contained" className={classes.buttonG}>
                  <Button
                    disabled={currentOptionQty(option) === 1}
                    onClick={(e) => decreaseQuantity(e, option)}
                    color="secondary"
                  >
                    -
                  </Button>

                  <Button
                    disabled={
                      currentOptionQty(option) === props.group.force_max
                    }
                    onClick={(e) => increaseQuantity(e, option)}
                    color="primary"
                  >
                    +
                  </Button>
                </ButtonGroup>
                {`  ${currentOptionQty(option)}x `}
              </React.Fragment>
            ) : null}
          </ListItem>
        ))}
      <Divider light />
    </List>
  );
};

export default DishGroup;
