import React from "react";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 400,
    margin: 20,
    padding: 10,
  },
  detail: {
    marginBottom: 12,
  },
  detailHeader: {
    fontFamily: "Arial, sans-serif", // Change to your preferred header font
    color: "#333", // Dark grey color for text
    fontSize: "1.1rem",
    fontWeight: "bold",
    marginBottom: "4px",
  },
  detailContent: {
    fontFamily: '"Courier New", Courier, monospace', // Change to your preferred content font
    color: "#555", // Slightly lighter grey
    fontSize: "1rem",
    marginLeft: "20px",
  },
  currency: {
    color: "#007BFF", // Bootstrap primary blue for highlighting currency
  },
  numeric: {
    color: "#28A745", // Bootstrap success green for numbers
  },
});

const GrantDetailsCard = ({ grant }) => {
  const classes = useStyles();

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    }).format(amount);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          variant="h5"
          component="h2"
          className={classes.detailHeader}
        >
          Grant Details
        </Typography>
        <Typography className={classes.detailContent}>
          Grant Amount:{" "}
          <span className={classes.numeric}>
            {formatCurrency(grant.amount.value / 100, grant.amount.currency)}
          </span>
        </Typography>
        <Typography className={classes.detailContent}>
          Fee:{" "}
          <span className={classes.numeric}>
            {formatCurrency(
              grant.fee.amount.value / 100,
              grant.fee.amount.currency
            )}
          </span>
        </Typography>
        <Typography className={classes.detailContent}>
          <div className={classes.detailHeader}>Balances:</div>
          <div>
            <span className={classes.currency}>Currency:</span> AUD
          </div>
          <div>
            <span className={classes.currency}>Fee:</span>
            <span className={classes.numeric}>
              {formatCurrency(
                grant.balances.fee / 100,
                grant.balances.currency
              )}
            </span>
          </div>
          <div>
            <span className={classes.currency}>Principal:</span>
            <span className={classes.numeric}>
              {formatCurrency(
                grant.balances.principal / 100,
                grant.balances.currency
              )}
            </span>
          </div>
          <div>
            <span className={classes.currency}>Total:</span>
            <span className={classes.numeric}>
              {formatCurrency(
                grant.balances.total / 100,
                grant.balances.currency
              )}
            </span>
          </div>
        </Typography>
        <Typography className={classes.detailContent}>
          Status: {grant.status}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default GrantDetailsCard;
