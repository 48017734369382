import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Checkbox from "@material-ui/core/Checkbox";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";

import { saveMenuGroupOption } from "store/actions/restaurantMenu";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";

// import AddMenuItemDialog from "./AddMenuItemDialog";

import LinkIcon from "@material-ui/icons/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const NewGroupOptionLink = (props) => {
  const { group } = props;

  const dispatch = useDispatch();
  const saveOption = (values) => dispatch(saveMenuGroupOption(values));

  const categories = useSelector(
    (state) => state.restaurantMenu.menuCategories
  );

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [hideForWeb, setHideForWeb] = useState(false);
  const [shortName, setShortName] = useState("");
  const [openAddMenuItem, setOpenAddMenuItem] = useState(false);
  const [menuItem, setMenuItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  

  const close = () => {
    props.setIsNewOptionLink(false);
  };

  const isSaveButtonDisabled = () => {
    return !name || !price || !menuItem;
  };

  const save = () => {
    // console.log('menuItem', menuItem)
    const option = {
      name,
      price: Number(price),
      shortName,
      groupId: group.id,
      menuId: group.menuId,
      default: isDefault,
      sort: 999,
      hideForWeb,
      menuItemId: menuItem ? menuItem.id : null,
      menuItemName: menuItem ? menuItem.name : null,
      category: selectedCategory,
    };
    saveOption(option);
    close();
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    if (name === "hideForWeb") {
      setHideForWeb(value);
    }
  };

  const openAddMenuItemDialog = (e) => {
    setOpenAddMenuItem(true);
  };

  const handleClose = () => {
    setOpenAddMenuItem(false);
  };

  const findMenuItem = (selectedItem) => {
    const newItem = categories
    .find((cat) => cat.name === selectedCategory)
    .items.find((item) => item.id === selectedItem);
    return newItem;
};

  const addItemHandler = (id) => {
    setSelectedItem(id);
    const item = findMenuItem(id);
    if (!item) {
      return;
    }
   
    setMenuItem(item);
    if (item) {
      setPrice(Number(item.price));
      setName(item.name);
      if(item.shortName) {
        setShortName(item.shortName);
      }
    }
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row">
        <div>
          {/* <AddMenuItemDialog
            open={openAddMenuItem}
            closeHandler={handleClose}
            menuId={group.menuId}
            addMenuItem={addItemHandler}
          /> */}

<Divider />
        Select a Category
        <br />
        <Select
          fullWidth
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          placeholder="Select Category"
        >
          {categories.map((cat) => (
            <MenuItem key={cat.name} value={cat.name}>
              {cat.name}
            </MenuItem>
          ))}
        </Select>

        Select an Item
        <br />
        <Select
          fullWidth
          value={selectedItem}
          onChange={(e) => addItemHandler(e.target.value)}
          placeholder="Select Item"
        >
          {selectedCategory &&
            categories
              .find((cat) => cat.name === selectedCategory)
              .items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
        </Select>

          <Typography>Linked item: {menuItem ? menuItem.name : ""}</Typography>

          {/* <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<LinkIcon />}
            onClick={openAddMenuItemDialog}
          >
            Link a menu item
          </Button> */}
        </div>

        <TextField
          margin="normal"
          name="name"
          fullWidth
          id="name"
          label="Name"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          size="small"
          label="Short Name"
          margin="dense"
          name="shortName"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={shortName}
          onChange={(e) => setShortName(e.target.value)}
        />

        <TextField
          margin="normal"
          name="price"
          size="small"
          id="price"
          label="Price"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
   

      <Grid container direction="row" alignItems="center">
      <FormControlLabel
            control={
              <Switch
                checked={hideForWeb}
                onChange={handleSwitchChange}
                name="hideForWeb"
                color="primary"
              />
            }
            label="Hide For Web"
          />
        </Grid>
        <Checkbox
          name="allow_quantity"
          color="primary"
          value={isDefault}
          onChange={(e) => setIsDefault(e.target.checked)}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        <Typography>Pre-select</Typography>
        <Tooltip
          title="This item will be appear as pre-selected in the menu."
          arrow
        >
          <InfoIcon />
        </Tooltip>
      </Grid>
      <Grid container direction="row" justify="space-between" spacing={1}>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={isSaveButtonDisabled()}
          color="primary"
          onClick={save}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewGroupOptionLink;
