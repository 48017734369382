import API from 'util/API';


export const getPrinters = async () => {
  const response = await API.get('/myprinters');
  if (response && response.data && response.data.printers) {
    return response.data.printers;
  }
  return [];
};


export const getTableInfo = async () => {
  const response = await API.get('/tableInfo');
  if (response && response.data && response.data.tableInfo) {
    return response.data.tableInfo;
  }
  return null;
};

// get printerZones
export const getPrinterZones = async () => {
  const response = await API.get('/printerZones');
  if (response && response.data && response.data) {
    return response.data;
  }
  return [];
};

// update printerZone
export const updatePrinterZone = async (zone) => {
   await API.post(`/printerZone/${zone.id}`, zone);
};

// create printerZone
export const createPrinterZone = async (zone) => {
   await API.post(`/printerZone`, zone);
};

// delete printerZone
export const deletePrinterZone = async (zoneId) => {
   await API.delete(`/printerZone/${zoneId}`);
};




