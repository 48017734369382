import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Grid
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { StoreContext } from "../../context/store";

//import { getInitials } from 'helpers';
import { useHistory } from 'react-router-dom';
import { setTransactionDetail, setTransactionPage, setTransactionIds } from 'store/actions/transaction';
import { useDispatch, useSelector } from 'react-redux';

import { setOrderDetail } from 'store/actions/restaurant';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
   // minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textLink: {
    textDecoration: 'underline',
  },
}));

const VouchersTable = props => {
  const { className, vouchers, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectIds } = useContext(
    StoreContext
  );

  const [selectedPromotions, setSelectedPromotions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);


  const updateTransactionDetail = (transaction) => dispatch(setTransactionDetail(transaction));
  const updateTransactionIds = (ids) => dispatch(setTransactionIds(ids));
  const showOrderDetail = (order) => dispatch(setOrderDetail(order));
  const setPage = (page) => dispatch(setTransactionPage(page));
  const page = useSelector((state) => state.transaction.page);

  const handleSelectAll = event => {
    const { transactions } = props;

    let selectedRestaurants;

    if (event.target.checked) {
      selectedRestaurants = transactions.map(order => order.id);
    } else {
      selectedRestaurants = [];
    }

    setSelectedPromotions(selectedRestaurants);
  };

  const handleSelectOne = (event, promotionId) => {
    const selectedIndex = selectedPromotions.indexOf(promotionId);
    let newSelectedPromotions = [];

    if (selectedIndex === -1) {
      newSelectedPromotions = newSelectedPromotions.concat(selectedPromotions, promotionId);
    } else if (selectedIndex === 0) {
      newSelectedPromotions = newSelectedPromotions.concat(selectedPromotions.slice(1));
    } else if (selectedIndex === selectedPromotions.length - 1) {
      newSelectedPromotions = newSelectedPromotions.concat(selectedPromotions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPromotions = newSelectedPromotions.concat(
        selectedPromotions.slice(0, selectedIndex),
        selectedPromotions.slice(selectedIndex + 1)
      );
    }

    setSelectedPromotions(newSelectedPromotions);
    selectIds[1](newSelectedPromotions);
   
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getTimeString = (date) => {
    if ('_seconds' in date) {
      return moment(new Date(date._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')
    }
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
  };


  const getTimeString2 = (date) => {
    if (!date) {
      return '';
    }
   // console.log('date', date);
    if (`${date}`.includes('/')) {
      return date;
    }
    
    if ((typeof date === 'object') &&'_seconds' in date) {
      return moment(new Date(date._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')
    }
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  };

  const openTransactionDetail = (e, transaction) => {
    updateTransactionDetail(transaction);
    history.push('/transaction');
  };


  const transferLocalDishes = (dishes) => {
    let newDishes = [];
    dishes.forEach((dish) => {
        newDishes.push({ dish: dish, quantity: dish.quantity, subTotal: dish.subTotal, specialString: dish.specialString });
    });
    return newDishes;
};



  
  const openPromotion = (promotion) => {
    if (promotion.type === 'buyNFree') {
      history.push(`/buyNFree/${promotion.id}`);
    }  
    
  };

  return (
    <Grid
  
    lg={12}
    md={12}
    xl={12}
    xs={12}
  >
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        {/* <PerfectScrollbar> */}
          <div className={classes.inner}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                     {/* <Checkbox
                      checked={selectedTransactions.length === transactions.length}
                      color="primary"
                      indeterminate={
                        selectedTransactions.length > 0 &&
                        selectedTransactions.length < transactions.length
                      }
                      onChange={handleSelectAll}
                    />  */}
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Member Name</TableCell>
                  <TableCell>Updated</TableCell>
                                     
                </TableRow>
              </TableHead>
              <TableBody>
                {vouchers && vouchers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(voucher => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={voucher.voucherId}
                    selected={selectedPromotions.indexOf(voucher.voucherId) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedPromotions.indexOf(voucher.voucherId) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, voucher.voucherId)}
                        value="true"
                      />
                    </TableCell>
                  

                    <TableCell>

                      <Typography color = {voucher.status === 'active' ? 'primary' : ''}>
                        {voucher.status}
                        </Typography>
                    </TableCell>
                    
                   

                    <TableCell>
                    <Button onClick={e => openPromotion(voucher)}>
                    <div className={classes.textLink}>
                      {voucher.name}
                      </div>
                      </Button>
                      </TableCell>
                    <TableCell>{voucher.description}</TableCell>

                    <TableCell>{voucher.userName}</TableCell>

                    <TableCell> {voucher.updatedAt && getTimeString2(voucher.updatedAt)}</TableCell>

                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          </div>
        {/* </PerfectScrollbar> */}
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={vouchers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
    </Grid>
  );
};

VouchersTable.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired
};

export default VouchersTable;
