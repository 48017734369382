import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';


import { useSelector, useDispatch } from 'react-redux';


import {
  updateCategories,
  updateAllCategories
} from 'store/actions/restaurantMenu';

export default function MoveCategoryDialog(props) {
  const {open, selectedMenuItem, closeHandler} = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [uiLoading, setUiLoading] = useState(false);
  
  const categories = useSelector((state) => state.restaurantMenu.menuCategories);
  const updateTheAllCategories = (allCategories) => dispatch(updateAllCategories(allCategories));
  const updateNewCategories = (newCategories) => dispatch(updateCategories(newCategories));
  

  const handleClose = () => {
    closeHandler();
  };

 const handleMenuItemMove =  () => {
  const categoryId = value.id;
  const currentCategory = categories.find(c => c.id === selectedMenuItem.categoryId);
  
  let images = {};
  let image = null;
  console.log("images", currentCategory.images);
  if (currentCategory.images) {
    images = currentCategory.images;
    if(currentCategory.images[selectedMenuItem.id]) {
      image = currentCategory.images[selectedMenuItem.id];
      // remove image from current category
      delete images[selectedMenuItem.id];
    }
      

  }
  // console.log("update images", images);
  
  const currentItems = currentCategory.items.filter(item => item.id !== selectedMenuItem.id);
  const currentCategoryData = {...currentCategory, items: currentItems, images: images};

  const newCategory = categories.find(c => c.id === categoryId);
  let newImages = newCategory.images ? newCategory.images : {};
  if (image) {
    newImages[selectedMenuItem.id] = image;
  }

  // console.log("newImages", newImages);

  const newItems = [...newCategory.items, {...selectedMenuItem, categoryId}];
  const newCategoryData = {...newCategory, items: newItems, images: newImages};

  // update category in categories
  const newCategories = categories.map(c => {
    if (c.id === currentCategory.id) {
      return currentCategoryData;
    }
    if (c.id === newCategory.id) {
      return newCategoryData;
    }
    return c;
  }
  );
  if (!newCategories || newCategories.length === 0) {
    return;
  }
  
  updateNewCategories(newCategories);
  updateTheAllCategories({ menuId: selectedMenuItem.menuId, categories: newCategories });
  closeHandler();
  
     
 };


  return (
    <div>
    
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select a category to move to</DialogTitle>
        <DialogContent>
        
        <Autocomplete
      id="categoryId"
      options={categories}
      getOptionLabel={(option) => option.name}
      style={{ minWidth: 300 }}

      value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
  
      renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
    />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleMenuItemMove} 
          disabled={uiLoading}
           color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
