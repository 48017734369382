import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { removeItemWithSlice } from "util/utils";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: "-1rem 0 2rem 0",
    padding: "0 3rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
    marginTop: "auto",
  },
  textField: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  errorMessage: {
    color: "red",
    fontSize: "0.9rem",
    marginTop: "0.2rem",
  },

  card: {
    margin: "1rem 1rem 0.4rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  
  }
}));

export default function TableSelect({
  formProps: { register, errors, setValue },
  data,
  session,
  partySize,
}) {
  const classes = useStyles();
  const { selectedTables } = data[0];
  const [currentTables, setCurrentTables] = useState([]);
  const selectedTablesFieldName = "selectedTables";

  const checkTableCapacity = (tables) => {
    if (tables === null || tables === undefined || tables.length === 0) {
      return "Please select a table";
    }
    const maxCapacity = tables.reduce((count, curItem) => {
      return count + Number(curItem.guestNumber);
    }, 0);
    const minCapacity = tables.reduce((count, curItem) => {
      return count + Number(curItem.minNumOfGuests);
    }, 0);
    console.log("maxCapacity", maxCapacity, "minCapacity", minCapacity, tables);
    if (Number(partySize) >= minCapacity && Number(partySize) <= maxCapacity) {
      return true;
    } else {
      return "Please check tables maximum and minimum capacity requirements.";
    }
  };

  const tablesRequired = () => {
    return function validateRequired(value) {
      // console.log("VALIDATING: ", displayName, value);
      return checkTableCapacity(value);
    };
  };

  useEffect(() => {
    if (selectedTables) {
      setCurrentTables(selectedTables);
    }
  }, [data]);

  useEffect(() => {
    register(
      { name: selectedTablesFieldName, type: "object" },
      { validate: tablesRequired() }
    );
  }, [register]);

  const handleTableChange = (event, tableId, index) => {
    const idx = currentTables.findIndex((table) => table.id === tableId);
    if (idx !== -1) {
      const newTables = removeItemWithSlice(currentTables, idx);
      setCurrentTables(newTables);
      setValue(selectedTablesFieldName, newTables);
    } else {
      let tables = [...currentTables];
      const table = session.tables[index];
      if (table) {
        tables.push(table);
      }
      setCurrentTables(tables);
      setValue(selectedTablesFieldName, tables);
    }
  };

  const isTableChecked = (tableId) => {
    return currentTables.findIndex((table) => table.id === tableId) > -1;
  };

  const tableTitle = (currentSession, table, index) => {

    if (currentSession.hideTableNo === true) {
      const seqence = index + 1;
      return `Option ${seqence}`;
    } else {
      return `Table#: ${table.tableNumber}`;
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item md={12} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Tables</FormLabel>
              {session.tables.map((table, index) => {
                return (
                  <Card className={classes.card}>
                    <CardContent>
                      <FormGroup row key={table.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isTableChecked(table.id)}
                              onChange={(e) =>
                                handleTableChange(e, table.id, index)
                              }
                              name={table.id}
                            />
                          }
                          label={
                            <div>
                              <Typography variant="title" display='block' style={{ margin: 10 }}>
                                {tableTitle(session, table, index)}
                              </Typography>
                              <Typography variant="body">
                                <Grid container direction='row' justify="center">
                                  <PeopleIcon color="primary" /> Max:
                                  {table.guestNumber} <PersonIcon color="primary" />
                                  Min: {table.minNumOfGuests}
                                </Grid>
                              </Typography>

                            </div>
                          }
                        />
                      </FormGroup>
                   
                    </CardContent>

                  </Card>

                );
              })}

              {errors.selectedTables && (
                <p className={classes.errorMessage}>
                  {errors.selectedTables.message}
                </p>
              )}
            </FormControl>
          </Grid>
        </Grid>


      </div>
    </Fragment>
  );
}
