import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { RestaurantsToolbar, RestaurantsTable } from './components';

import CircularProgress from '@material-ui/core/CircularProgress';


import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRestaurantsSuccess } from '../../store/actions/restaurant';

import { authAdminAndGroupAdminMiddleWare } from 'util/authAdmin'
import { updateRestaurantkeyword } from 'store/actions/restaurant';

import API from 'util/API';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));


const RestaurantList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiLoading, setUiLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const loadRestaurants= (restaurants) => dispatch(fetchRestaurantsSuccess(restaurants));
  const updateFilter = (key) => dispatch(updateRestaurantkeyword(key));
  const restaurants = useSelector((state) => state.restaurant.restaurants);
  const history = useHistory();

  const user = useSelector((state) => state.account.user);
  authAdminAndGroupAdminMiddleWare(history, user);

  useEffect(() => {
    // authAdminMiddleWare(history, user);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;
    setUiLoading(true);
    API
      .get('/restaurants')
      .then((response) => {
        loadRestaurants(response.data)
        setUiLoading(false);
        updateFilter('');
      })
      .catch((error) => {
        console.log(error);
        setUiLoading(false);
        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }
           
        setErrorMsg('Error in retrieving the data');

      });
  }, [props]);



  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {

  return (
    <div className={classes.root}>
       <RestaurantsToolbar />
      <div className={classes.content}>
        <RestaurantsTable restaurants={restaurants} />
      </div>
    </div>
  );
  }
};

export default RestaurantList;
