import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import { useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import green from "@material-ui/core/colors/green";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const RestaurantToolbar = (props) => {
  const { className, restaurant, ...rest } = props;

  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const user = useSelector((state) => state.account.user);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />


        {user && user.role === "admin" ? (
          <>
            {restaurant && restaurant.adyenPaymentSettings ? (
              <NavLink to="/adyenSettings">
                <Tooltip title="Adyen">
                  <IconButton aria-label="Adyen settings">
                    <AccountBalanceIcon style={{ color: green[700] }} />
                  </IconButton>
                </Tooltip>
              </NavLink>
            ) : null}

        {/* <NavLink to="/restaurantSettings" className={classes.textLink}>
          <Tooltip title="settings">
            <IconButton aria-label="settings">
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to="/openHours" className={classes.textLink}>
          <Tooltip title="open hours">
            <IconButton aria-label="open hours">
              <AccessTimeIcon />
            </IconButton>
          </Tooltip>
        </NavLink> */}

        <NavLink
          to={"/userAdd/" + props.restaurant.urlIdentifier}
          className={classes.textLink}
        >
          <Tooltip title="restaurant manager">
            <IconButton aria-label="add restaurant manager">
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        {/* <NavLink
          to={`/qrcodes/${props.restaurant.urlIdentifier}`}
          className={classes.textLink}
        >
          <Tooltip title="QR Code">
            <IconButton aria-label="qr code">
              <AssignmentReturnedIcon />
            </IconButton>
          </Tooltip>
        </NavLink> */}


            {/* {props.restaurant.menuToken2 &&
            props.restaurant.menuToken2.trim() !== "" ? (
              <Button
                className={classes.searchInput}
                color="primary"
                variant="contained"
                disabled={buttonLoading}
                onClick={() =>
                  fetchMenu(props.restaurant, props.restaurant.menuToken2)
                }
              >
                Transfer Secondary Menu
              </Button>
            ) : null} */}

            {/* <Button
              color="primary"
              variant="contained"
              disabled={buttonLoading}
              onClick={() =>
                fetchMenu(props.restaurant, props.restaurant.menuToken)
              }
            >
              Transfer Main Menu
            </Button> */}
          </>
        ) : null}
      </div>
    </div>
  );
};

RestaurantToolbar.propTypes = {
  className: PropTypes.string,
};

export default RestaurantToolbar;
