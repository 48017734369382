
export const SET_TRANSACTION_DETAIL = 'SET_TRANSACTION_DETAIL';

export const setTransactionDetail = transaction => ({
    type: SET_TRANSACTION_DETAIL,
    payload: transaction
});


export const SET_TRANSACTION_PAGE = 'SET_TRANSACTION_PAGE';
export const setTransactionPage = page => ({
    type: SET_TRANSACTION_PAGE,
    payload: page
});

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const setTransactions = transactions => ({
    type: SET_TRANSACTIONS,
    payload: transactions
});


export const SET_TRANSACTION_IDS = 'SET_TRANSACTION_IDS';
export const setTransactionIds = ids => ({
    type: SET_TRANSACTION_IDS,
    payload: ids
});



