import React, { useState, createContext } from "react";

import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/styles";
import DatePicker from "react-datepicker";
import { TimesheetsToolbar, TimesheetsTable } from "./components";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import { Button } from '@material-ui/core';


import {  useDispatch } from "react-redux";


import {  showErrorSnackbar } from "store/actions/snackbarMessage";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { getTimesheetList, deleteTimesheet } from "apis/staffAPIs";

import { useConfirm } from "material-ui-confirm";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const UserContext = createContext();

const TimesheetList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const [uiLoading, setUiLoading] = useState(false);
  
  const [selectTimesheetIds, setSelectTimesheetIds] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [queryStartDate, setQueryStartDate] = useState(new Date());
  const [queryendDate, setQueryEndDate] = useState(new Date());

  
  
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const queryClient = useQueryClient();

  
  const theme = useTheme();



  const deletMutation = useMutation(deleteTimesheet, {
    onSuccess: () => {
      queryClient.invalidateQueries("timesheetList");
    },
  });

  

  const processTimesheets = (timesheetList) => {
    const sortedTimesheets = timesheetList.sort((a, b) => {
      if (!a.timesheetVersion) {
        let timeA = a.recordTime;
        let timeB = b.recordTime;
        if (typeof a.recordTime === "object" ) {
          timeA = a.recordTime._seconds;
        }
        if (typeof b.recordTime === "object" ) {
          timeB = b.recordTime._seconds;
        }
        return Number(timeA) - Number(timeB);
      } else {
        return Number(a.recordTime) - Number(b.recordTime);
      }
    });

    // group timesheet by staffNumber
    const groupedTimesheets = sortedTimesheets.reduce((acc, timesheet) => {
      if (!acc[timesheet.staffNumber]) {
        acc[timesheet.staffNumber] = [];
      }
      acc[timesheet.staffNumber].push(timesheet);
      return acc;
    }, {});

    let results = [];

    // loop through each staffNumber
    Object.keys(groupedTimesheets).forEach((staffNumber) => {
      const timesheets = groupedTimesheets[staffNumber];

    // loop filter timesheet to update type
    timesheets.forEach((timesheet, index) => {
      if (!timesheet.type) {
        if ((index + 1) % 2 === 1) {
          timesheet.type = "checkin";
        } else {
          timesheet.type = "checkout";
          if (index - 1 >= 0) {
            const clockinTime = moment(new Date(timesheets[index - 1].createdAt._seconds * 1000));
            const clockoutTime = moment(new Date(timesheet.createdAt._seconds * 1000));
            const duration = moment.duration(clockoutTime.diff(clockinTime));
            const hours = duration.asHours().toFixed(2);
            
            timesheet.hours = hours;
            timesheet.checkinTime = timesheets[index - 1].recordTime;
            timesheet.checkoutTime = timesheet.recordTime;
          }
        }
      } else {
        if (timesheet.type === "checkout" && index - 1 >= 0 && timesheets[index - 1].type === "checkin") {
          const lastTimesheet = timesheets[index - 1];
          let clockinTime;
          if (typeof lastTimesheet.recordTime === "object" && "_seconds" in lastTimesheet.recordTime) {
            clockinTime = moment(new Date(lastTimesheet.recordTime._seconds * 1000));
          } else {
            clockinTime = moment(new Date(lastTimesheet.recordTime * 1000));
          }
          
          const clockoutTime = moment(new Date(timesheet.recordTime * 1000));

          const duration = moment.duration(clockoutTime.diff(clockinTime));
          // console.log("duration", duration.asHours(), clockoutTime, clockinTime);
          const hours = duration.asHours().toFixed(2);

          timesheet.hours = hours;
          timesheet.checkinTime = lastTimesheet.recordTime;
          timesheet.checkoutTime = timesheet.recordTime;
        }
      }

    });
     // add timesheets to results
      results = results.concat(timesheets);
  });
  // console.log(JSON.stringify(results));
  return results;
};
  

  const { isLoading, isError, data, error } = useQuery(["timesheetList", queryStartDate, queryendDate], () =>
    getTimesheetList(queryStartDate, queryendDate, processTimesheets)
  );

  if (isError) {
    return <Alert severity="error">{`${error.message} `}</Alert>;
  }

  if (isLoading) {
    return <CircularProgress size={150} className={classes.uiProgess} />;
  }



  const delTimesheet = async () => {
    if (!selectTimesheetIds || selectTimesheetIds.length !== 1) {
      showErrorMessage("Please select one timesheet to delete");
      return;
    }

    confirm({
      description: "You are about to delete this item!",
    })
      .then(() => {
        const timesheet = data.find((item) => item.id === selectTimesheetIds[0]);
        // console.log(timesheet);
        let dateStr;
        if (typeof timesheet.recordTime === "object" && "_seconds" in timesheet.recordTime) {
          dateStr = moment(timesheet.recordTime._seconds * 1000).format("DD-MM-YY");
        } else {
          dateStr = moment(timesheet.recordTime * 1000).format("DD-MM-YY");
        }

        // console.log(timesheet, dateStr);
        deletMutation.mutate({dateStr, id:selectTimesheetIds[0]});
      })
      .catch(() => {
        /* ... */
      });
  };

  const queryDate =  () => {
    // compart startDay and endDay
    if (startDate > endDate) {
      showErrorMessage("Start date must be less than end date");
      return;
    }
    // check startDay and endDay is not future date
    const today = new Date();
    if (startDate > today || endDate > today) {
      showErrorMessage("Start date and end date must be less than today");
      return;
    }
    // check startDay and endDay gap is less than 28 days
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 28) {
      showErrorMessage("Start date and end date gap must be less than 28 days");
      return;
    }


   setQueryStartDate(startDate);
   setQueryEndDate(endDate);
};

 
  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {
    return (
      <UserContext.Provider value={selectTimesheetIds}>
        <div className={classes.root}>
          <Grid container spacing={3} alignItems="center">
            <Typography
              style={{
                margin: theme.spacing(1),
              }}
            >
              Start Date
            </Typography>
            <DatePicker selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} />
            <Typography
              style={{
                margin: theme.spacing(1),
              }}
            >
              
              End Date
            </Typography>
            <DatePicker selected={endDate} dateFormat="dd/MM/yyyy" onChange={(date) => setEndDate(date)} />
            <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: theme.spacing(1)}}
            onClick={e => queryDate()}
          >
            Query
        </Button>
          </Grid>
          <TimesheetsToolbar deleteProxy={delTimesheet} />
          <div className={classes.content}>
            <TimesheetsTable timesheets={data} updateIds={setSelectTimesheetIds} />
          </div>
        </div>
      </UserContext.Provider>
    );
  }
};

export default TimesheetList;
