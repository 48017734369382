import React, { useState, useEffect } from "react";
import Dish from "./Dish";
import { Section } from "react-scroll-section";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { filterVisibility, decodeRestaurantId } from "util/utils";

import { checkDishIsInActive } from 'util/orderUtils';


import Firebase from "firebase";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {

    width: '100%',
    margin: 0,
    overflowX:'hidden'
  },
  centered: {
    width: '100%',
    height: '500',
    justifyContent: "center",
    alignItems: "center",
  },
  images: {
    width: "100%",
    height: undefined,

    borderWidth: 0,
    borderRadius: 10,
    
  
    marginTop: theme.spacing(1),
  },
  retryImage: {
    width: 64,
    height: 64,
    borderWidth: 0,
    borderRadius: 10,
    
  
    marginTop: theme.spacing(1),
  },
}));

const CategorySections = (props) => {
  const classes = useStyles();
  const { categories, restaurantId } = props;
  const [filteredDishes, setFilteredDishes] = useState([]);
  const restaurant = useSelector((state) => state.menu.restaurant);
  const [imageVisible, setImageVisible] = useState(false);
  const todayString = moment().format('YYYY-MM-DD');

  const checkInactiveMenu = () => {
    //console.log('restaurantId', restaurantId);
    let ref = Firebase.database().ref(`inactiveMenus/${decodeRestaurantId(restaurantId)}`);
    //console.log('ref', ref);
    ref.on("value", (snapshot) => {
      //console.log('snapshot', snapshot.val());
      if (snapshot && snapshot.val() && snapshot.val().dishIds) {
        const inactiveIds = snapshot.val().dishIds;
        setFilteredDishes(inactiveIds);
      } else {
        setFilteredDishes([]);
      }
    });
  };

  useEffect(() => {
    checkInactiveMenu();
  }, [props]);

  const itemImage = (category, itemId) => {
    const allImages = categories.map((category) => category.images);
    const filterImages = allImages.filter(
      (images) => images && itemId in images
    );
    if (filterImages && filterImages.length > 0) {
      return filterImages[0][itemId];
    }

    return null;
  };

  return (
    <div className={classes.root}>
      {imageVisible ? null : (
        <>
          {restaurant && restaurant.logo ? (
            <img className={classes.images} src={restaurant.logo} alt="logo" />
          ) : null}
          {categories.length === 0 ? (
        <Grid container className={classes.centered} vertical>
            <img
              className={classes.retryImage}
              src={`/static/images/refresh.png`}
              alt="refresh"
            />
            <Typography variant="body" color="textSecondary" align="center">
              Please refresh the page and try again.
            </Typography>
            </Grid>
          ) : null}
        </>
      )}

      {categories &&
        categories.map((category) => {
          return (
            <>
            <div key={category.id}>
            
           <Box  p={1} bgcolor="action.selected">
           <Typography variant="h6" >{category.name}</Typography>
          
           </Box>
           
            <Section id={category.id} key={category.id} >
              {category.items &&
                category.items
                  .filter((dish) => {                  
                    return checkDishIsInActive(dish, filteredDishes, todayString) && filterVisibility(dish);
                  })

                  .map((item) => {
                    return (
                      <Dish
                      key={item.id}
                        item={item}
                        image={itemImage(
                          categories[props.categoryIndex],
                          item.id
                        )}
                      />
                    );
                  })}
            </Section>
            </div>
            </>
          );
        })}
    </div>
  );
};

export default CategorySections;
