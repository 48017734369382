
const isOrderInRestaurant = (realTableId) => {
    return (
      !isTakeaway(realTableId) && !isDelivery(realTableId) && !isPickup(realTableId) && !isPickupOrDelivery(realTableId) && !isFoodcourt(realTableId)
    );
  };

  const isTakeaway = (realTableId) => {
    return realTableId === "takeaway";
  };

  const isDelivery = (realTableId) => {
    return realTableId === "delivery";
  };

  const isPickup = (realTableId) => {
    return realTableId === "pickup";
  };

  const isPickupOrDelivery = (realTableId) => {
    return realTableId === "pickup-delivery";
  };

  const isFoodcourt = (realTableId) => {
    return realTableId === "foodcourt";
  };

  const isTakeawayDeliverPickup = (realTableId) => {
      return isTakeaway(realTableId) || isPickup(realTableId) || isPickupOrDelivery(realTableId) || isDelivery(realTableId);
  }

  exports.isOrderInRestaurant = isOrderInRestaurant;
  exports.isTakeaway = isTakeaway;
  exports.isDelivery = isDelivery;
  exports.isPickup = isPickup;
  exports.isPickupOrDelivery = isPickupOrDelivery;
  exports.isFoodcourt = isFoodcourt;
  exports.isTakeawayDeliverPickup = isTakeawayDeliverPickup;