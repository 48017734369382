import React,  { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';


import API from 'util/API';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


 const RestaurantManagerList = (props) => {
  const { transaction } = props;
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state) => state.account.user);
  const [managers, setManagers] = useState([]);


  const getMyRestaurantManagers = () => {
    // authRestaurantAdminMiddleWare(history, user);
    // const authToken = localStorage.getItem('AuthToken');
    // API.defaults.headers.common['Authorization'] = authToken;
    
    API
        .get('/restaurantManagers')
        .then((response) => {
            //console.log(response.data); 
            setManagers(response.data);
        })
        .catch((error) => {
            if (error.response && error.response.status === 403 && props.history) {
                props.history.push('/sign-in');
            }
            console.log('error', JSON.stringify(error));
        });

};

useEffect(() => {
 getMyRestaurantManagers();
}, [props]);
  
  const paymentTime = (time) => {
    if(!time) {
      return '';
    }
    if ('_seconds' in time) {
      return  moment(new Date(time._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')
    }
    return moment(time).format('DD/MM/YYYY HH:mm:ss')
  };

  return (
   
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
      { managers.length > 0 ?
        <TableHead>
          <TableRow>
            <TableCell>Manager Username </TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Phone</TableCell>
            <TableCell align="right">Update Time</TableCell>          
          </TableRow>
        </TableHead>
        : null }
        <TableBody>
          {managers && managers.map((row) => (
            <TableRow key={row.email}>  
            <TableCell>
            <NavLink to={`/user/${row.username}`}>
           {row.username}
           </NavLink>
           {row.permissions && row.permissions.length > 0 ? row.permissions.map((permission) => (
             <div>{permission.name}</div>
            )) : null}
          </TableCell>

              <TableCell align="right">
                {row.email}
              </TableCell>
              <TableCell align="right">{row.phoneNumber}</TableCell>
              <TableCell align="right">{paymentTime(row.updatedAt)}</TableCell>
          
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> 
  );
};

export default RestaurantManagerList;
