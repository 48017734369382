import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import { SearchInput } from "components";

import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import { updateUserkeyword } from "store/actions/account";
import { useConfirm } from "material-ui-confirm";
import { deletePOSClient } from "apis/devicesAPIs";
import { useMutation, QueryClient } from "react-query";

import { updateSelectedUserIds } from "store/actions/account";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const POSClientListToolbar = (props) => {
  const { className, ...rest } = props;
  const confirm = useConfirm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedUserIds = useSelector((state) => state.account.selectedUserIds);
 
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  const updateFilter = (key) => dispatch(updateUserkeyword(key));
  const updateUserIds = (ids) => dispatch(updateSelectedUserIds(ids));

  const queryClient = new QueryClient();
 
  // delete mutation
  const deleteMutation = useMutation(deletePOSClient, {
    onSuccess: (data) => {
      showSuccessMessage("POS Client deleted successfully!");
      setButtonLoading(false);
      queryClient.invalidateQueries("posClientList");
      updateUserIds([]);
      // reload url using window.location.reload()
      window.location.reload();
    },
    onError: (error) => {
      showErrorMessage("Something went wrong!");
      setButtonLoading(false);
    },
  });

  const deleteItem = () => {
    // console.log("selectedUserIds", selectedUserIds);
    if (!selectedUserIds || selectedUserIds.length === 0) {
      showErrorMessage("Please select a client!");
      return;
    }

    confirm({ description: "You are about to delete this client!" })
      .then(() => {
        setButtonLoading(true);
        deleteMutation.mutate(selectedUserIds[0]);
        
      })
      .catch(() => {
        /* ... */
      });
  };

  const filterUsers = (e) => {
    updateFilter(e.target.value);
  }; 



  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <IconButton
          aria-label="delete"
          onClick={deleteItem}
          disabled={buttonLoading}
        >
          <DeleteIcon />
        </IconButton>

     
        <NavLink to={"/posClient"}>
          <Button color="primary" variant="contained">
            Add POS Client
          </Button>
        </NavLink>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search POS clients"
          onChange={filterUsers}
        />
      </div>
    </div>
  );
};

POSClientListToolbar.propTypes = {
  className: PropTypes.string,
};

export default POSClientListToolbar;
