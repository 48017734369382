import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';
import { authMiddleWare } from 'util/auth';
import constants from 'util/constants';
import { useHistory } from 'react-router-dom';

import API from 'util/API';



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles(() => ({
  root: {},
  progess: {
		position: 'absolute'
	},
}));

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const history = useHistory();

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });
  

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const isReadySubmit = () => {
      return values.password.trim().length > 0  && values.password === values.confirm;
  };

  const handleErrorClose = (event, reason) => {
		setOpenErrorSnackbar(false);
  };
  
  const handleClose = (event, reason) => {
    setOpenSuccessSnackbar(false);
  }

  const updatePassword = (event) => {
    event.preventDefault();
    if(!isReadySubmit()) {
         return
    }
    // authMiddleWare(props.history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;
    setButtonLoading(true);
    API
				.put('/userPwd', { password: values.password, confirmPassword: values.confirm})
				.then(() => {
					setButtonLoading(false);
					setOpenSuccessSnackbar(true);
				})
				.catch((error) => {
					if (error.response && error.response.status === 403) {
            history.push('/sign-in');
					}
					console.log(JSON.stringify(error));
					setButtonLoading(false);
					setOpenErrorSnackbar(true)
				});
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Password"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            error={values.password !== values.confirm}
            label="Confirm password"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.confirm}
            variant="outlined"
            helperText="Passwords do not match"
          />
        </CardContent>
        <Divider />
        <Snackbar open={openSuccessSnackbar} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Updated successfully
        </Alert>
            </Snackbar>

            <Snackbar open={openErrorSnackbar} autoHideDuration={2000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Something went wrong! Please try again.
        </Alert>
            </Snackbar>
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={updatePassword}
            disabled={!isReadySubmit() || buttonLoading}
          >
            Update
            {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
