import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { useSelector, useDispatch } from 'react-redux';
import { updateMenuCategory, clearAPIStatus } from 'store/actions/restaurantMenu';

import isInteger from 'lodash/isInteger';

import { showErrorSnackbar } from 'store/actions/snackbarMessage'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ColorPickerDialog from 'views/Common/ColorPickerDialog';
import 'views/Common//App.css';


const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

}));

const CategoryEdit = (props) => {
  const { category, menuId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [categoryDescription, setCategoryDescription] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [altName, setAltName] = useState('');

  const [cookingPriority, setCookingPriority] = useState(1);
  const [hideForWeb, setHideForWeb] = useState(false);
  const updateCategory = (values) => dispatch(updateMenuCategory(values));
  const apiSuccess = useSelector((state) => state.restaurantMenu.apiSuccess);
  const clearApiStatus = () => dispatch(clearAPIStatus());
  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  const [isColorPickerDialogOpen, setIsColorPickerDialogOpen] = useState(false);
  const [colorHex, setColorHex] = useState(null);

  const close = () => {
    props.editItem(false);
  };

  useEffect(() => {
    if (category) {
      setCategoryName(category.name);
      if (category.altName) {
        setAltName(category.altName);
      }
      setCategoryDescription(category.description);
      if (category.cookingPriority) {
        setCookingPriority(category.cookingPriority);
      } else {
        setCookingPriority(1);
      }
      if (category.hideForWeb) {
        setHideForWeb(category.hideForWeb);
      }
      if (category.colorHex) {
        setColorHex(category.colorHex);
      }

    }

  }, [props, category]);

  const save = () => {
    if (!category || !menuId) {
      return;
    }
    console.log(`cookingPriority===${cookingPriority}===`);
    if (!isInteger(Number(cookingPriority))) {
      showError('Please input an interger for the cooking priority')
      return;
    }
    const newCategory = {

      name: categoryName,
      description: categoryDescription,
      cookingPriority: Number(cookingPriority),
      id: category.id,
      altName,
      menuId,
      hideForWeb,
      colorHex


    };
    updateCategory(newCategory);
  }

  if (apiSuccess) {
    clearApiStatus();
    close()
  }


  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    if (name === 'hideForWeb') {
      setHideForWeb(value);
    }
  };

  const openColorPickerDialog = () => {
    setIsColorPickerDialogOpen(true);
  };

  const closeColorPickerDialog = () => {
    setIsColorPickerDialogOpen(false);
  };

  const handleColorSelected = (color) => {
    setColorHex(color);
  };

  return (
    <Card className={classes.root}>
      <CardContent draggable
        onDragStart={e => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Category Name"
            margin="dense"
            name="categoryName"
            variant="outlined"
            value={categoryName}
            onChange={e => setCategoryName(e.target.value)}
            onDragStart={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Category Alternative Name"
            margin="dense"
            name="altName"
            variant="outlined"
            value={altName}
            onChange={e => setAltName(e.target.value)}
            onDragStart={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Category Description"
            margin="dense"
            name="categoryDescription"
            variant="outlined"
            value={categoryDescription}
            onChange={e => setCategoryDescription(e.target.value)}
            onDragStart={e => {
              console.log(e);
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Cooking Priority"
            margin="dense"
            name="cookingPriority"
            variant="outlined"
            value={cookingPriority}
            onChange={e => setCookingPriority(e.target.value)}
            onDragStart={e => {
              console.log(e);
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={hideForWeb}
                onChange={handleSwitchChange}
                name="hideForWeb"
                color="primary"
              />
            }
            label="Hide For Web"
          />
        </Grid>
        <Grid item md={6} xs={12}>

          <div >
            <div className="color-picker-row">
              <span className="color-picker-button">
                <Button variant='outlined' onClick={openColorPickerDialog} >
                  Pick a color
                </Button>
              </span>
              <span className="selected-color" style={{ backgroundColor: colorHex }}>{colorHex}</span>
            </div>
            <ColorPickerDialog
              isOpen={isColorPickerDialogOpen}
              onClose={closeColorPickerDialog}
              onColorSelected={handleColorSelected}
            />
          </div>


        </Grid>



      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={close}>Cancel</Button>
        <Button variant="outlined" color="primary" onClick={save}>Save</Button>
      </CardActions>
    </Card>
  );
};

export default CategoryEdit;
