import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ItemsDialog from "./ItemsDialog";
import { useQuery } from "react-query";
import {
  getPrinters
} from "apis/printAPIs";

import { Divider } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import {
  clearAPIStatus,
  updateMenu,
  getMenuCategories,
} from "store/actions/restaurantMenu";
import { removeItemWithSlice } from "util/utils";
import { v4 as uuidv4 } from "uuid";
import { showErrorSnackbar } from "store/actions/snackbarMessage";
import { useConfirm } from "material-ui-confirm";
import CookingCategoryDialog from "./CookingCategoryDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: theme.spacing(1),
    minHeight: 500,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },

  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
  margin: {
    marginLeft: theme.spacing(1),
  },
}));

const CookingCategoriesTable = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const classes = useStyles();
  const [cookingCategoryName, setCookingCategoryName] = useState("");
  const [printer, setPrinter] = useState("");
  const [isForLabelPrint, setIsForLabelPrint] = useState(false);
  const [printCopies, setPrintCopies] = useState(1);
  const [isForKDS, setIsForKDS] = useState(false);
  const apiSuccess = useSelector((state) => state.restaurantMenu.apiSuccess);
  const clearApiStatus = () => dispatch(clearAPIStatus());
  const menu = useSelector((state) => state.restaurantMenu.menu);
  const updateTheMenu = (menu, menuId) => dispatch(updateMenu(menu, menuId));
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const [isEditing, setIsEditing] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [categoryItems, setCategoryItems] = useState({});
  const loadMenuCategories = (menuId) => dispatch(getMenuCategories(menuId));
  const menuCategories = useSelector(
    (state) => state.restaurantMenu.menuCategories
  );
  const [isItemsDialogOpen, setIsItemsDialogOpen] = useState(false);
  const [dialogItems, setDialogItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // console.log('menu.id', menu, menu && menu.id);

  useEffect(() => {
    if (menu && menu.id) {
      loadMenuCategories(menu.id);
    }
  }, [menu]);

  const categoryItemsMemo = useMemo(() => {
    const newCategoryItems = {};

    if (menuCategories) {
      for (const category of menuCategories) {
        if (category.items) {
          for (const item of category.items) {
            if (item.cookingCategory && item.cookingCategory !== "") {
              if (!newCategoryItems[item.cookingCategory]) {
                newCategoryItems[item.cookingCategory] = [];
              }
              if (!newCategoryItems[item.cookingCategory].includes(item)) {
                newCategoryItems[item.cookingCategory].push(item);
              }
            }
            if (item.printCategory && item.printCategory !== "") {
              const printCategories = item.printCategory.split(";");
              for (const printCategory of printCategories) {
                if (!newCategoryItems[printCategory]) {
                  newCategoryItems[printCategory] = [];
                }
                if (!newCategoryItems[printCategory].includes(item)) {
                  newCategoryItems[printCategory].push(item);
                }
              }
            }
          }
        }
      }
    }
    return newCategoryItems;
  }, [menuCategories]);

  useEffect(() => {
    setCategoryItems(categoryItemsMemo);
  }, [categoryItemsMemo]);

  const showItems = (category) => {
    if (categoryItems[category.name]) {
      setDialogItems(categoryItems[category.name]);
      setSelectedCategory(category);
      // console.log('categoryItems[category.name]', JSON.stringify(categoryItems[category.name]));
      setIsItemsDialogOpen(true);
    }
  };

  // State for dialogue
  const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const openNewCategoryDialog = () => {
    setIsNewCategoryDialogOpen(true);
    setCategoryToEdit(null);
    resetForm(); // Reset the form
  };
  const closeNewCategoryDialog = () => setIsNewCategoryDialogOpen(false);
  const openEditCategoryDialog = (category) => {
    setCategoryToEdit(category);
    setIsNewCategoryDialogOpen(true);
  };

  const handleSaveCategory = (categoryData) => {
    // If categoryToEdit is not null, it's an edit operation
    console.log("categoryData", categoryData, menu, menu.id);
    if (categoryToEdit) {
      const updatedCategories = menu.cookingCategories.map((category) => {
        if (category.id === categoryToEdit.id) {
          return { ...category, ...categoryData };
        }
        return category;
      });
      updateTheMenu({ cookingCategories: updatedCategories }, menu.id);
    } else {
      // Add logic here to save the new category
      const newCategory = {
        id: uuidv4(),
        ...categoryData,
      };
      const updatedCategories = menu.cookingCategories
        ? [...menu.cookingCategories, newCategory]
        : [newCategory];
      updateTheMenu({ cookingCategories: updatedCategories }, menu.id);
    }

    closeNewCategoryDialog();
  };

  // console.log('menu.cookingCategories', menu.cookingCategories);

  

  if (apiSuccess) {
    clearApiStatus();
  }

  const handleDelete = (category) => {
    confirm({ description: "You are about to delete this item!" })
      .then(() => {
        const deleteIndx = menu.cookingCategories.findIndex(
          (item) => item === category
        );
        if (deleteIndx !== -1) {
          const categories = removeItemWithSlice(
            menu.cookingCategories,
            deleteIndx
          );
          updateTheMenu({ cookingCategories: categories }, menu.id);
          setCookingCategoryName("");
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  const resetForm = () => {
    setCategoryToEdit(null);
    setCookingCategoryName("");
    setPrinter("");
    setPrintCopies(1);
    setIsForKDS(false);
    setIsForLabelPrint(false);
  };



  useEffect(() => {
    if (isEditing) {
      setCookingCategoryName(editingCategory.name);
      setPrinter(editingCategory.printer);
      setIsForKDS(editingCategory.isForKDS);
      setIsForLabelPrint(editingCategory.isForLabelPrint);
      setPrintCopies(editingCategory.printCopies);
    } else {
      setCookingCategoryName("");
      setPrinter("");
      setIsForKDS(false);
      setIsForLabelPrint(false);
      setPrintCopies(1);
    }
  }, [isEditing, editingCategory]);



  // console.log('printers', printers, menu.cookingCategories);

  const { data: printers, isLoading } = useQuery("printers", getPrinters);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <Button
          variant="contained"
          color="primary"
          onClick={openNewCategoryDialog}
        >
          New Print/Cooking Category
        </Button>

        <CookingCategoryDialog
          isOpen={isNewCategoryDialogOpen}
          onClose={closeNewCategoryDialog}
          onSave={handleSaveCategory}
          categoryToEdit={categoryToEdit}
          resetForm={resetForm}
          printers={printers}
        />

        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Items</TableCell>
                <TableCell>Printer</TableCell>
                <TableCell>KDS</TableCell>
                <TableCell>Label Print</TableCell>
                <TableCell>Print Copies</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {menu &&
                menu.cookingCategories &&
                menu.cookingCategories.map((category) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={category.id ? category.id : category}
                  >
                    <TableCell>
                      <Button
                        className={classes.textLink}
                        style={{ textTransform: "none" }}
                        onClick={() => openEditCategoryDialog(category)}
                      >
                        {category.name ? category.name : category}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.textLink}
                        style={{ textTransform: "none" }}
                        onClick={(e) => showItems(category)}
                      >
                        {categoryItems[category.name] &&
                          categoryItems[category.name].length}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {category.printer ? category.printer : ""}
                    </TableCell>
                    <TableCell>{category.isForKDS ? "Yes" : (category.isForKDS == undefined ? "YES" : "NO")}</TableCell>
                    <TableCell>
                      {category.isForLabelPrint ? "Yes" : "No"}
                    </TableCell>

                    <TableCell>
                      {category.printCopies ? category.printCopies : ""}
                    </TableCell>

                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        edge="end"
                        color="secondary"
                        onClick={(e) => handleDelete(category)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <ItemsDialog
            isOpen={isItemsDialogOpen}
            onClose={() => setIsItemsDialogOpen(false)}
            initialItems={dialogItems}
            categories={menuCategories}
            selectedCookingCategory={selectedCategory}
          />
        </div>
      </CardContent>
    </Card>
  );
};

CookingCategoriesTable.propTypes = {
  className: PropTypes.string,
  // cookingCategories: PropTypes.array.isRequired
};

export default CookingCategoriesTable;
