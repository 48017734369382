import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import { SearchInput } from "components";

import { NavLink } from "react-router-dom";
import API from "util/API";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { authAdminMiddleWare } from "util/authAdmin";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import { updateUserkeyword } from "store/actions/account";
import { useConfirm } from "material-ui-confirm";
import AddRestaurantsDialog from './AddRestaurantsDialog'

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const POSListToolbar = (props) => {
  const { className, ...rest } = props;
  const confirm = useConfirm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.account.user);
  const selectedUserIds = useSelector((state) => state.account.selectedUserIds);
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  const updateFilter = (key) => dispatch(updateUserkeyword(key));

  const deleteOneUser = async () => {
  
    setButtonLoading(true);
    try {
    await API.delete(`/staff/${selectedUserIds[0]}`)
      .then((response) => {
        console.log(response.data);
        setButtonLoading(false);
        showSuccessMessage("Deleted successfully!");
        history.push("/staffList");
      })
    }
      catch(error) {
        if (error.response && error.response.status === 403) {
          history.push("/sign-in");
        }
        console.log("error", JSON.stringify(error));
        setButtonLoading(false);
        showErrorMessage("Error in deleting the user");
      };
  };

  const deleteUser = () => {
    console.log("selectedUserIds", selectedUserIds);
    if (selectedUserIds && selectedUserIds.length !== 1) {
      return;
    }

    confirm({ description: "You are about to delete this user!" })
      .then(() => {
        deleteOneUser();
      })
      .catch(() => {
        /* ... */
      });
  };

  const filterUsers = (e) => {
    updateFilter(e.target.value);
  }; 

  const addRestaurants = () => {
      if(selectedUserIds.length !== 1) {
        showErrorMessage("Please select only one restaurant group account.");
        return;
      }
      setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        {/* <IconButton
          aria-label="delete"
          onClick={deleteUser}
          disabled={buttonLoading}
        >
          <DeleteIcon />
        </IconButton>

     

        <NavLink to={"/staff"}>
          <Button color="primary" variant="contained">
            Add Staff
          </Button>
        </NavLink> */}
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search POS"
          onChange={filterUsers}
        />
      </div>
    </div>
  );
};

POSListToolbar.propTypes = {
  className: PropTypes.string,
};

export default POSListToolbar;
