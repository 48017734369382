import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
// import { DataGrid } from '@mui/x-data-grid';
import SearchBar from "material-ui-search-bar";


import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  
  TablePagination,
  
  Grid
} from '@material-ui/core';

//import { getInitials } from 'helpers';
import { useHistory } from 'react-router-dom';
import { setTransactionDetail, setTransactionPage, setTransactionIds } from 'store/actions/transaction';
import { useDispatch, useSelector } from 'react-redux';

import { setOrderDetail } from 'store/actions/restaurant';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textLink: {
    textDecoration: 'underline',
  },
}));


const StaffActivityTable = props => {
  const { className, staffActivities, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [keyword, setKeyword] = useState('');

  
  const updateTransactionIds = (ids) => dispatch(setTransactionIds(ids));
  
  const setPage = (page) => dispatch(setTransactionPage(page));
  const page = useSelector((state) => state.transaction.page);



  const handleSelectOne = (event, transactionId) => {
    const selectedIndex = selectedTransactions.indexOf(transactionId);
    let newSelectedTransactions = [];

    if (selectedIndex === -1) {
      newSelectedTransactions = newSelectedTransactions.concat(selectedTransactions, transactionId);
    } else if (selectedIndex === 0) {
      newSelectedTransactions = newSelectedTransactions.concat(selectedTransactions.slice(1));
    } else if (selectedIndex === selectedTransactions.length - 1) {
      newSelectedTransactions = newSelectedTransactions.concat(selectedTransactions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedTransactions = newSelectedTransactions.concat(
        selectedTransactions.slice(0, selectedIndex),
        selectedTransactions.slice(selectedIndex + 1)
      );
    }

    setSelectedTransactions(newSelectedTransactions);
    updateTransactionIds(newSelectedTransactions);

  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };




  const getTimeString2 = (date) => {
    
    if (!date) {
      return '';
    }
    // console.log('date', date);
    if (`${date}`.includes('.')) {
      return  moment(new Date(Number(date) * 1000)).format('DD/MM/YYYY HH:mm:ss');
    }

    if ((typeof date === 'object') && '_seconds' in date) {
      return moment(new Date(date._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')
    }
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  };



  const filterActivities = staffActivities.filter(activity => {
    if (keyword === '') {
      return true;
    }
    const lowcaseKey = keyword.toLocaleLowerCase();
    if (activity.id.toLowerCase().includes(lowcaseKey)) {
      return true;
    }
    if (activity.operationType.toLowerCase().includes(lowcaseKey)) {
      return true;
    }

    if (activity.operationName.toLowerCase().includes(lowcaseKey)) {
      return true;
    }

    if (activity.staffName.toLowerCase().includes(lowcaseKey)) {
      return true;
    }

  


    return false;
  });


  return (
    <Grid

      lg={12}
      md={12}
      xl={12}
      xs={12}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          {/* <PerfectScrollbar> */}
          <div className={classes.inner}>



            <SearchBar
              value={keyword}
              onChange={(newValue) => setKeyword(newValue)}
              onRequestSearch={() => console.log(keyword)}
            />

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      {/* <Checkbox
                      checked={selectedTransactions.length === transactions.length}
                      color="primary"
                      indeterminate={
                        selectedTransactions.length > 0 &&
                        selectedTransactions.length < transactions.length
                      }
                      onChange={handleSelectAll}
                    /> */}
                    </TableCell>
                    <TableCell>Activity Type</TableCell>
                    <TableCell>Activity Content</TableCell>
                    <TableCell>Update Time</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Staff Name</TableCell>
                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffActivities && filterActivities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(staffActivity => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={staffActivity.id}
                      selected={selectedTransactions.indexOf(staffActivity.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedTransactions.indexOf(staffActivity.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, staffActivity.id)}
                          value="true"
                        />
                      </TableCell>


                      <TableCell>

                       {staffActivity.operationType}
                      </TableCell>

                      <TableCell>{staffActivity.operationName} {staffActivity.details}</TableCell>
                      <TableCell>{getTimeString2(staffActivity.updatedAt)}</TableCell>

                      <TableCell>{`$${staffActivity.price.toFixed(2)}`}</TableCell>

                      <TableCell>

                      {staffActivity.staffName}

                      </TableCell>
                   
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filterActivities.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>

    </Grid>
  );
};

StaffActivityTable.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired
};

export default StaffActivityTable;
