import moment from 'moment';
const timeFormat = 'HH:mm';

const DayOfWeekMapping = { 'Mon': 1, 'Tue': 2, 'Wed': 3, 'Thu': 4, 'Fri': 5, 'Sat': 6, 'Sun': 7 };

const compareTwoTimes = (current, start, end) => {
	const format = timeFormat;


	const beforeTime = moment(start, format);
	const afterTime = moment(end, format);
	const orderTime  = moment(current, format);
	//console.log(beforeTime, afterTime, orderTime);

	if (orderTime.isBetween(beforeTime, afterTime)) {
		return true;
	} else {
		return false;
	}
};


export const filterOpenHours = (openHours, currentTime = moment()) => {
	if (!openHours || (openHours.openHoursItems.length === 0 && openHours.closeDaysItems.length === 0)) {
		return true;
	}
	const currentDateStr = currentTime.format('DD/MM/YYYY');
	const currentDay = currentTime.locale('au').format('dddd');
	const currentTimeStr = currentTime.format(timeFormat);
	//console.log(currentDateStr, currentDay, openHours.closeDaysItems);

	if (openHours.closeDaysItems) {
		for (const dateStr of openHours.closeDaysItems) {
			//console.log('dateStr', dateStr, currentDateStr === dateStr);
			if (currentDateStr === dateStr) {
				return false;
			}
		}
	}

	if (!openHours.openHoursItems || openHours.openHoursItems.length === 0) {
		return true;
	}

	for (const openHour of openHours.openHoursItems) {
	//	console.log('openHour', openHour, openHour.weekdays.indexOf(currentDay));
		let flag = false;
		if (openHour.weekdays.indexOf(currentDay) !== -1) {
			flag = true;
		}

		if (flag && compareTwoTimes(currentTimeStr, openHour.openStart, openHour.openEnd)) {
			return true;
		}

	}
	return false;

};


export const removeItemWithSlice = (items, index) => {

	let newSelectedOptions = [];

	if (index === 0) {
		newSelectedOptions = newSelectedOptions.concat(items.slice(1));
	} else if (index === items.length - 1) {
		newSelectedOptions = newSelectedOptions.concat(items.slice(0, -1));
	} else if (index > 0) {
		newSelectedOptions = newSelectedOptions.concat(
			items.slice(0, index),
			items.slice(index + 1)
		)
	}
	return newSelectedOptions;
};




export const updateItemsWithIndexAndItem = (items, index, item) => {

	let newSelectedOptions = [];

	if (index === 0) {
		newSelectedOptions.push(item);
		newSelectedOptions = newSelectedOptions.concat(items.slice(1));
	} else if (index === items.length - 1) {
		newSelectedOptions = newSelectedOptions.concat(items.slice(0, -1));
		newSelectedOptions.push(item)
	} else if (index > 0) {
		newSelectedOptions = newSelectedOptions.concat(
			items.slice(0, index), [item],
			items.slice(index + 1)
		)
	}
	return newSelectedOptions;
};

export const timeString = (value) => {

	var hours = (value / 60);
	var rhours = Math.floor(hours);
	var minutes = (hours - rhours) * 60;
	var rminutes = Math.round(minutes);

	return `${rhours}: ${rminutes.toString().length === 1 ? '0' + rminutes.toString() : rminutes}`;
};


const checkDayIsInWeekDays = (currentDate, days) => {
	const iosDays = days.map(day => { return DayOfWeekMapping[day] });
	//console.log('iosDays', iosDays);
	if (iosDays.find(day => currentDate.isoWeekday() === day)) {
		return true;
	} else {
		return false;
	}
};


export const filterOption = (option, hidedDishes, now = moment()) => {
	if(option.hideForWeb === true) {
		return false;
	  }

	  if (option.menuItemId && hidedDishes && hidedDishes.length > 0) {

	  const filterHideDishes = hidedDishes.filter(dish => dish.menuItemId === option.menuItemId);

	  if(filterHideDishes.length > 0) {
		const hideDish = filterHideDishes[0];
		// format moment with a locale
		const currentDate = now.locale('au');
		const nowStr = currentDate.format('YYYY-MM-DD');
		
		if (hideDish.effectiveDate === '' || !hideDish.effectiveDate || hideDish.effectiveDate  === nowStr)
		return false;
	  } 
	}
	 // console.log('option', option.image);
	  return true;

};


export const filterVisibility = (menuObj, now = moment()) => {
	if(menuObj.active_end === 1440) {
	//	console.log('filterVisibility start', menuObj.active_end, menuObj.active_begin, menuObj);
	}
	
	if (!menuObj.active) {
		if (menuObj.hideDays && menuObj.hideDays.length > 0) {
			return !checkDayIsInWeekDays(now, menuObj.hideDays);
		} else {
			return false;
		}

	}
	if (menuObj.hideForWeb === true) {
		return false;
	}

	if (menuObj.active === true && menuObj.hidden_until) {
		const hiddenUtilDate = moment(menuObj.hidden_until);
		if (now > hiddenUtilDate) {
			return true;
		} else {
			return false;
		}
	}

	if (menuObj.active && ((!menuObj.active_begin && Number(menuObj.active_begin) !== 0) || !menuObj.active_end)) {
		
		return true;
	}
	if (menuObj.active && menuObj.active_begin >= 0 && menuObj.active_end >= 0) {
		const m = now;
		const minutes = (m.hour() * 60) + m.minute();
		//console.log('minutes', minutes);
		if (Number(menuObj.active_end) >= 1440) {
			const activeEnd = Number(menuObj.active_end) - 1440;
			
			if (minutes >= menuObj.active_begin || minutes < activeEnd) {
				if (!menuObj.activeWeekDays || menuObj.activeWeekDays.length === 0) {
					return true;
				} else {
					return checkDayIsInWeekDays(m, menuObj.activeWeekDays);
				}
			} else {
				return false;
			}
		} else if (minutes >= menuObj.active_begin && minutes < menuObj.active_end) {
			if (!menuObj.activeWeekDays || menuObj.activeWeekDays.length === 0) {
				return true;
			} else {
				return checkDayIsInWeekDays(m, menuObj.activeWeekDays);
			}

		} else {
			return false;
		}
	}
	return true;
};


export const loadTableId = (restaurantId) => {
	try {
		let serializedCart = localStorage.getItem(`http://orderbuddy.net.au:${restaurantId}:table`);

		if (serializedCart === null) {
			return null;
		}

		return serializedCart;
	}
	catch (err) {
		return null;
	}
};

export const isDineInTable = (tableId) => {
	if (tableId === 'pickup' || tableId === 'takeaway' || tableId === 'delivery' || tableId === 'pickup-delivery' || tableId === 'foodcourt') {
		return false;
	} else {
		return true;
	}
};

export const loadBookingSummary = (restaurantId) => {
	try {
		let serializedBooking = localStorage.getItem(`http://orderbuddy.net.au:${restaurantId}:booking`);

		if (serializedBooking === null) {
			return null;
		}

		return JSON.parse(serializedBooking);
	}
	catch (err) {
		console.error(err);
		return null;
	}
};

export const saveBookingSummary = (booking, restaurantId) => {
	try {

		if (booking) {
			let serializedBooking = JSON.stringify(booking);
			localStorage.setItem(`http://orderbuddy.net.au:${restaurantId}:booking`, serializedBooking);
		}
	} catch (err) {
		console.error(err);
	}
};

export const decodeRestaurantId = (menuId) => {
	if(!menuId) {
		return null;
	}
	let restaurantId = menuId;
	if (menuId.includes('_')) {
		restaurantId = menuId.split('_')[0];
	}
	return restaurantId;

}

function ciEquals(a, b) {
    return typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        : a === b;
}

export const compareStringCaseInsensitive = (string1, string2) => {
	if (!string1 || !string2) {
		return false
	}
	if(string1 === undefined || string2 === undefined) {
		return false
	}

	return ciEquals(string1.trim(), string2.trim());

}




  




