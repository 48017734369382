import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersToolbar, UsersTable } from './components';


import CircularProgress from '@material-ui/core/CircularProgress';


import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAccountsSuccess } from 'store/actions/account';
import API from 'util/API';
import { updateUserkeyword } from 'store/actions/account';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));


const UserList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  
  const [uiLoading, setUiLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const loadAccounts = (accounts) => dispatch(fetchAccountsSuccess(accounts));
  const history = useHistory();
  const account = useSelector((state) => state.account);
  const updateFilter = (key) => dispatch(updateUserkeyword(key));
  
  useEffect(() => {
    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;
    setUiLoading(true);
    API
      .get('/users')
      .then((response) => {
        loadAccounts(response.data)
        setUiLoading(false);
        updateFilter('');
      })
      .catch((error) => {
        console.log(error);
        setUiLoading(false);
        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
      
        }
        
        
        setErrorMsg('Error in retrieving the data');

      });
  }, [props]);

  


  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {

  return (
    <div className={classes.root}>
      <UsersToolbar/>
      <div className={classes.content}>
        <UsersTable users={account.accounts} />
      </div>
    </div>
  );
  }
};

export default UserList;
