import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";

import { NavLink } from 'react-router-dom';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useSelector, useDispatch } from "react-redux";
import { setOrderSelectedDate } from "store/actions/restaurant";
import { useConfirm } from "material-ui-confirm";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import TransactionDeleteDialog from "./TransactionDeleteDialog";



const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  rightSpacer: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const TransactionsToolbar = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");

  const setOrderDate = (date) => dispatch(setOrderSelectedDate(date));
  const selectedDate = useSelector(
    (state) => state.restaurant.orderSelectedDate
  );
  const selectedTransactionIds = useSelector((state) => state.transaction.ids);
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const handleDateChange = (date) => {
    console.log("date", date);  
    setOrderDate(date);
    history.push("/staffActivities");
  };

  const deleteTransactions = () => {
    //console.log("selectedTransactionIds", selectedTransactionIds);
    if (selectedTransactionIds && selectedTransactionIds.length === 0) {
      showErrorMessage("Please select a transaction.");
      return;
    }

    setDeleteDialogOpen(true);

    // confirm({ description: "You are about to delete transactions!" })
    //   .then(() => {
    //     setButtonLoading(true);
    //     props.deleteProxy(selectedTransactionIds, () => {
    //       setButtonLoading(false);
    //     })
    //   })
    //   .catch(() => {});
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const deleteTran = () => {
    if (deleteReason.length === 0) {
      showErrorMessage('Please select or enter a reason.');
      return;
   }
    props.deleteProxy(deleteReason);
  };



  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <NavLink to={'/transactions'}>
        <Button variant="outlined" color="primary">Transactions</Button>
        </NavLink>
        <span className={classes.rightSpacer} />

        {/* <IconButton
          aria-label="delete"
          onClick={deleteTransactions}
          disabled={buttonLoading}
        >
          <DeleteIcon />
        </IconButton> */}

      </div>
      <div className={classes.row}>
        {/* <SearchInput
          className={classes.searchInput}
          placeholder="Search order"
        /> */}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            autoOk={true}
            id="date-picker-inline"
            label="Activity Date "
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <span className={classes.spacer} />
        <TransactionDeleteDialog open={deleteDialogOpen} deleteProxy={deleteTran} close={closeDeleteDialog} updateReason={setDeleteReason} reason={deleteReason}/>
      </div>
    </div>
  );
};

TransactionsToolbar.propTypes = {
  className: PropTypes.string,
};

export default TransactionsToolbar;
