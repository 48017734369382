import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

import { OrdersToolbar, OrdersTable } from './components';

import CircularProgress from '@material-ui/core/CircularProgress';


import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTodayOrders } from 'store/actions/restaurant';


import API from 'util/API';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));




const OrderList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiLoading, setUiLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const loadTodayOrders= (orders) => dispatch(fetchTodayOrders(orders));

  const todayOrders = useSelector((state) => state.restaurant.todayOrders);
  const orderType = useSelector((state) => state.restaurant.orderType);
  const selectedDate = useSelector((state) => state.restaurant.orderSelectedDate);
  const history = useHistory();
  // authMiddleWare(history);

  const fectchOrders = (type) => {
    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;
    setUiLoading(true);
    API
      .get('/todayOrder?type=' + type + '&dateStr=' + moment(selectedDate).format('DD-MM-YY'))
      .then((response) => {
        //console.log('response.data', response.data);
        loadTodayOrders(response.data)
        setUiLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setUiLoading(false);
        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }
           
        setErrorMsg('Error in retrieving the data');

      });
  };

  const selectOrders = (ids) => {
     if(ids.length > 0) {
       setSelectedOrderIds(ids);
     }
  };

  const deleteOrders = () => {
    console.log('deleteOrders', selectedOrderIds);
    if (selectedOrderIds.length === 0) {
      return;
    }
    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;
    setUiLoading(true);
    const formRequest = {
      ids: selectedOrderIds,
      type: orderType,
      dateStr: moment().format('DD-MM-YY')
    }
    API
      .put('/deleteOrders', formRequest)
      .then((response) => {
        fectchOrders(orderType);
      })
      .catch((error) => {
        console.log(error);
        setUiLoading(false);
        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }
           
        setErrorMsg('Error in deleteOrders');

      });


  };

  useEffect(() => {
    fectchOrders(orderType);
  }, [orderType, selectedDate]);




  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {

  return (
    <div className={classes.root}>
      <OrdersToolbar deleteProxy= {deleteOrders} />
      <div className={classes.content}>
        <OrdersTable orders={todayOrders} selectorders={selectOrders} />
      </div>
    </div>
  );
  }
};

export default OrderList;
