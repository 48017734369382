import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Alert } from "@material-ui/lab";

import { useQuery, useQueryClient } from "react-query";
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import clsx from "clsx";

import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "store/actions/snackbarMessage";

import {
  getNewKioskCode,
  getKiosk,
  createKiosk,
  updateKiosk,
  getMerchantTerminals,
} from "apis/devicesAPIs";
import NewTables from "./NewTables";
const CHARACTER_LIMIT = 30;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
}));

const Kiosk = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [isMobilePhoneNumberRequired, setIsMobilePhoneNumberRequired] =
    useState(false);
  const [isNameRequired, setIsNameRequired] = useState(false);

  const [isPaymentRequired, setIsPaymentRequired] = useState(false);
  const [message, setMessage] = useState("");
  const [timeoutSeconds, setTimeoutSeconds] = useState(60);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [kioskNumber, setKioskNumber] = useState("");
  const [terminalNumber, setTerminalNumber] = useState("");
  const [selectedKiosk, setSelectedKiosk] = useState(null);
  const [qrTypes, setQrTypes] = useState([]);
  const [tables, setTables] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);

  const queryClient = useQueryClient();

  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));

  const kioskId = props.match.params.kioskId;

  const getNewKioskNumber = async () => {
    try {
      setButtonLoading(true);
      const data = await getNewKioskCode();
      if (data) {
        setKioskNumber(data.newKioskNumber);
        setQrTypes(data.qrTypes);
      }
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      showError("Something went wrong! Please go back and try again.");
    }
  };

  const getKioskDetails = async () => {
    try {
      setButtonLoading(true);
      const kiosk = await getKiosk(kioskId);
      // console.log(JSON.stringify(kiosk));
      if (kiosk) {
        setSelectedKiosk(kiosk);
        setKioskNumber(kiosk.code);
        setTables(kiosk.tables);
        setTerminalNumber(kiosk.terminalNumber);
        setIsMobilePhoneNumberRequired(kiosk.isMobilePhoneNumberRequired);
        setIsNameRequired(kiosk.isNameRequired);
        setIsPaymentRequired(kiosk.isPaymentRequired);
        setTimeoutSeconds(kiosk.timeoutSeconds);
        setMessage(kiosk.message);
        setQrTypes(kiosk.qrTypes);
      }
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      showError("Something went wrong! Please go back and try again.");
    }
  };

  const createNewKiosk = async () => {
    try {
      const kiosk = {
        isMobilePhoneNumberRequired,
        isNameRequired,
        timeoutSeconds: parseInt(timeoutSeconds),
        isPaymentRequired,
        code: kioskNumber,
        tables,
        terminalNumber,
        message,
      };

      setButtonLoading(true);
      await createKiosk(kiosk);
      history.push("/kioskDevices");
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      showError("Something went wrong! Please go back and try again.");
    }
  };

  const updateKioskDetails = async () => {
    try {
      const kiosk = {
        isMobilePhoneNumberRequired,
        isNameRequired,
        timeoutSeconds: parseInt(timeoutSeconds),
        isPaymentRequired,
        code: kioskNumber,
        tables,
        message,
        terminalNumber,
        id: kioskId,
      };

      setButtonLoading(true);
      const response = await updateKiosk(kiosk);
      showSuccessMessage("Kiosk updated successfully!");
      history.push(`/kioskDevices`);
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      showError("Something went wrong! Please go back and try again.");
    }
  };

  useEffect(() => {
    if (!kioskId) {
      getNewKioskNumber();
    } else {
      getKioskDetails();
    }
  }, [props]);

  const updateFormValues = (event) => {
    event.preventDefault();

    if (!selectedKiosk) {
      createNewKiosk();
    } else {
      updateKioskDetails();
    }
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "isMobilePhoneNumberRequired") {
      setIsMobilePhoneNumberRequired(value);
      return;
    }
    if (name === "isNameRequired") {
      setIsNameRequired(value);
      return;
    }
    if (name === "isPaymentRequired") {
      setIsPaymentRequired(value);
      return;
    }
  };

  const {
    isLoading,
    isError,
    data: terminalData,
    error,
  } = useQuery("terminalList", getMerchantTerminals);
  console.log("terminalData", terminalData);

  if (isError) {
    return <Alert severity="error">{`${error.message} `}</Alert>;
  }

  if (isLoading) {
    return <CircularProgress size={150} className={classes.uiProgess} />;
  }

  return (
    <main className={classes.content}>
      {buttonLoading ? <LinearProgress /> : null}
      <Card className={clsx(classes.root, classes)}>
        <CardHeader title="Kiosk" subheader="Create or edit the Kiosk" />
        <form autoComplete="off" noValidate>
          <CardContent>
            <Grid container spacing={3} direction="column">
              <Grid item md={3} xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMobilePhoneNumberRequired}
                      onChange={handleSwitchChange}
                      name="isMobilePhoneNumberRequired"
                      color="primary"
                    />
                  }
                  label="Mobile phone number required"
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isNameRequired}
                      onChange={handleSwitchChange}
                      name="isNameRequired"
                      color="primary"
                    />
                  }
                  label="Name required"
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isPaymentRequired}
                      onChange={handleSwitchChange}
                      name="isPaymentRequired"
                      color="primary"
                    />
                  }
                  label="Payment required"
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  label="Timeout (seconds)"
                  margin="dense"
                  name="timeoutSeconds"
                  variant="outlined"
                  type="number"
                  value={timeoutSeconds}
                  onChange={(e) => setTimeoutSeconds(e.target.value)}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <FormControl fullWidth variant="outlined" margin="dense">
                  <InputLabel htmlFor="terminalNumber">
                    Terminal Number
                  </InputLabel>
                  <Select
                    native
                    label="Terminal Number"
                    value={terminalNumber}
                    onChange={(e) => setTerminalNumber(e.target.value)}
                    inputProps={{
                      name: "terminalNumber",
                      id: "terminalNumber",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {terminalData.map((terminal) => (
                      <option key={terminal.store} value={terminal.terminal}>
                        {terminal.terminal}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3} xs={6}>
                <TextField
                  className={classes.bottomButtons}
                  id="message"
                  label="Message (on the home screen)"
                  multiline
                  rows={4}
                  fullWidth
                  inputProps={{
                    maxlength: CHARACTER_LIMIT,
                  }}
                  value={message}
                  variant="outlined"
                  helperText={`${
                    message ? message.length : "0"
                  }/${CHARACTER_LIMIT}`}
                  placeholder="Enter message here"
                  onChange={(e) => setMessage(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item md={3} xs={6}>
                <Typography>Kiosk Code: {kioskNumber}</Typography>
              </Grid>
              <NewTables
                qrTypes={qrTypes}
                updateTables={setTables}
                tableList={tables}
              />
            </Grid>
          </CardContent>
          <Divider />
          <CardActions />
        </form>
      </Card>

      <Button
        color="primary"
        variant="contained"
        type="submit"
        className={classes.submitButton}
        onClick={updateFormValues}
        disabled={
          buttonLoading || !tables || !tables.length === 0 || !kioskNumber
        }
      >
        Save Kiosk
        {buttonLoading && (
          <CircularProgress size={30} className={classes.progess} />
        )}
      </Button>
    </main>
  );
};

export default Kiosk;
