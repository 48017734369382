import React, { useState, useEffect, useCallback } from "react";

import GroupDisplay from "./GroupDisplay";
import GroupEdit from "./GroupEdit";
import GroupOptionEdit from "./GroupOptionEdit";

const Group = (props) => {
  const { group, menuId } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [editOption, setEditOption] = useState(null);

  const handleEdit = (flag) => {
    setIsEdit(flag);
  };

  const handleEditOption = (option) => {
    setEditOption(option);
  };

  return (
    <>
      {isEdit ? (
        <GroupEdit group={group} editItem={handleEdit} menuId={menuId} />
      ) : (
        <GroupDisplay group={group} editItem={handleEdit} delete={props.delete} menuId={menuId}  editOption={handleEditOption}/>
      )}
      {editOption !== null ? <GroupOptionEdit option={editOption} group={group} menuId={menuId}  editOption={handleEditOption}/> : null}
    </>
  );
};

export default Group;
