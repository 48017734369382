import { nanoid } from 'nanoid'

export const getSurchargeSettingsList = (restaurant) => {
    if (restaurant && restaurant.surchargeSettings) {
      return  restaurant.surchargeSettings;
    }
    if (restaurant && restaurant.holidaySurcharge) {
      return [{
        publicHolidayDays: restaurant.holidaySurcharge.publicHolidayDays,
        weekdays: restaurant.holidaySurcharge.weekdays,
        isEnabled: restaurant.holidaySurcharge.isEnabled,
        isPercentage: restaurant.holidaySurcharge.isPercentage,
        amount: restaurant.holidaySurcharge.amount,
        name: "Holiday/weekend Surcharge",
        surchargeDescription: "Holiday/weekend Surcharge",
        id: 'holidaySurcharge',
      }];
    }
    return [];
 } 
