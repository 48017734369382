import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import FileCopyIcon from "@material-ui/icons/FileCopy";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import EditIcon from "@material-ui/icons/Edit";
import { useConfirm } from "material-ui-confirm";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";

import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import BuildIcon from "@material-ui/icons/Build";

import { default as NewGroupOption } from "../NewGroupOption";

import NewGroupOptionLink from "../NewGroupOption/NewGroupOptionLink";

import { default as TargetBox } from "../TargetBox";

import {
  saveMenuGroupOptions,
  saveMenuGroupOption,
  clearAPIStatus,
  saveMenuGroup,
} from "store/actions/restaurantMenu";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";

import Option from "./Option";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "90%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  table: {
    width: "100%",
    tableLayout: "auto",
  },
  nameHeader: {
    flex: 3,
    padding: "6px",
  },
  priceHeader: {
    flex: 2,
    padding: "6px",
  },
  actionHeader: {
    flex: 1,
    padding: "6px",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f7f7f7",
    },
  },
}));

const GroupDisplay = (props) => {
  const { group, menuId, editOption } = props;
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const saveOptions = (values) => dispatch(saveMenuGroupOptions(values));
  const saveTheMenuGroup = (values) => dispatch(saveMenuGroup(values));
  const clearApiStatus = () => dispatch(clearAPIStatus());

  const apiSuccess = useSelector((state) => state.restaurantMenu.apiSuccess);
  const [isNewOption, setIsNewOption] = useState(false);
  const [isNewOptionLink, setIsNewOptionLink] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const categories = useSelector(
    (state) => state.restaurantMenu.menuCategories
  );

  const saveOption = (values) => dispatch(saveMenuGroupOption(values));

  const [options, setOptions] = useState([]);

  if (apiSuccess) {
    clearApiStatus();
  }

  useEffect(() => {
    if (props.group.options) {
      setOptions(props.group.options);
    }
  }, [props]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const openNewOption = () => {
    setIsNewOption(true);
  };

  const openNewOptionLink = () => {
    setIsNewOptionLink(true);
  };

  const moveOption = (dragIndex, hoverIndex) => {
    console.log("options", options, dragIndex, hoverIndex);
    let tempOptions = [...options];
    [tempOptions[dragIndex], tempOptions[hoverIndex]] = [
      tempOptions[hoverIndex],
      tempOptions[dragIndex],
    ];
    const newOptions = tempOptions.map((option, index) => {
      return { ...option, sort: index };
    });
    saveOptions({ options: newOptions, menuId, groupId: group.id });
    setOptions(newOptions);
  };

  const removeItemWithSlice = (items, index) => {
    let newSelectedOptions = [];

    if (index === 0) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(1));
    } else if (index === items.length - 1) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(0, -1));
    } else if (index > 0) {
      newSelectedOptions = newSelectedOptions.concat(
        items.slice(0, index),
        items.slice(index + 1)
      );
    }
    return newSelectedOptions;
  };

  const copyOption = (oldOption) => {
    const newOption = {
      ...oldOption,
      name: `copy of ${oldOption.name}`,
      sort: 999,
      menuId,
      groupId: group.id,
      id: uuid(),
    };
    saveOption(newOption);
  };

  const deleteOption = (name) => {
    const updatedItemIndex = options.findIndex((item) => item.name === name);
    if (updatedItemIndex !== -1) {
      const newOptions = removeItemWithSlice(options, updatedItemIndex);
      saveOptions({ options: newOptions, menuId: menuId, groupId: group.id });
      setOptions(newOptions);
    }
  };

  const handleDrop = useCallback((index, item) => {
    console.log("handledrop", index, item);
  }, []);

  const handleDelete = () => {
    confirm({ description: "All group items will also be deleted!" })
      .then(() => {
        props.delete(group.id);
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleItemEdit = () => {
    props.editItem(true);
  };

  const handleItemCopy = () => {
    if (!group) {
      console.error("group is missing", group);
      return;
    }
    let options = [];
    if (group.options) {
      options = group.options.map((option) => ({
        ...option,
        id: uuid(),
      }));
    }

    var newGroup = {
      ...group,
      name: `copy of ${group.name}`,
      options,
    };
    delete group.id;
    saveTheMenuGroup(newGroup);
  };

  const checkOption = (option) => {
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      for (let j = 0; j < category.items.length; j++) {
        const item = category.items[j];

        if (option.name === item.name && option.menuItemId !== item.id) {
            console.log('found a match', option.name, category.name, item.name, item.id, option.menuItemId)
          return { ...option, menuItemId: item.id, category: category.name };
        }
      }
    }
    return null;
  };

  const fixBrokenLinks = () => {
    confirm({
      description:
        "Do you wish to repair broken links? This action will identify menu items that share the same name as the options.",
    })
      .then(() => {
        let fixedCount = 0;
        const newOptions = options.map((option) => {
          if (option.menuItemId) {
            const newOption = checkOption(option);
            if (newOption) {
              fixedCount++;
              return newOption;
            }
          }
          return option;
        });
        if (fixedCount > 0) {
          saveOptions({ options: newOptions, menuId, groupId: group.id });
          setOptions(newOptions);
        } else {
          alert("No broken links found");
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            alignContent="flex-start"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                edge="start"
                size="small"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography> {group.name}</Typography>
              {group.hideForWeb === true ? <VisibilityOffIcon /> : null}
            </Grid>
            <Grid>
              <IconButton
                aria-label="eidt"
                className={classes.margin}
                edge="end"
                color="secondary"
                onClick={handleItemCopy}
              >
                <FileCopyIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="eidt"
                className={classes.margin}
                edge="end"
                color="secondary"
                onClick={handleItemEdit}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="delete"
                className={classes.margin}
                edge="end"
                color="secondary"
                onClick={handleDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="fix broken links"
                className={classes.margin}
                edge="end"
                color="secondary"
                onClick={fixBrokenLinks}
              >
                <BuildIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {isNewOption ? (
              <NewGroupOption group={group} setIsNewOption={setIsNewOption} />
            ) : isNewOptionLink ? (
              <NewGroupOptionLink
                group={group}
                setIsNewOptionLink={setIsNewOptionLink}
              />
            ) : (
              <Grid container direction="column">
                {
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="options table">
                      <TableBody>
                        {options.map((option, index) => {
                          return (
                            <TableRow key={option.id} className={classes.row}>
                              <TargetBox
                                accept={["option"]}
                                dragType={"option"}
                                key={option.name}
                                index={index}
                                id={option.name}
                                onDrop={(item) => handleDrop(index, item)}
                                moveCard={moveOption}
                              >
                                <Option
                                  group={group}
                                  option={option}
                                  key={option.name}
                                  deleteOption={deleteOption}
                                  copyOption={copyOption}
                                  editOption={editOption}
                                />
                              </TargetBox>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </Grid>
            )}
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={openNewOption}
            >
              Add choice
            </Button>

            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={openNewOptionLink}
            >
              Add Link choice
            </Button>
          </CardActions>
        </Collapse>
      </Card>
    </div>
  );
};

export default GroupDisplay;
