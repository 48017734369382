import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";


import FormControlLabel from "@material-ui/core/FormControlLabel";

import { useSelector, useDispatch } from 'react-redux';
import { updateMenuItem, clearAPIStatus } from 'store/actions/restaurantMenu';
import NewSizes from '../NewMenuItem/NewSizes'
import { isNumber } from 'underscore'
import { showErrorSnackbar } from 'store/actions/snackbarMessage'
import ColorPickerDialog from 'views/Common/ColorPickerDialog';


const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const MenuItemEdit = (props) => {
  const { menuId, categoryId, menuItem } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [altName, setAltName] = useState('');
  const [shortName, setShortName] = useState('');
  const [colorHex, setColorHex] = useState(null);
  const [price, setPrice] = useState('');
  const [productId, setProductId] = useState("");
  const [isOpenTableItem, setIsOpenTableItem] = useState(false);
  const [isOpenTableSharedItem, setIsOpenTableSharedItem] = useState(false);
  const [isColorPickerDialogOpen, setIsColorPickerDialogOpen] = useState(false);

  const [gstFree, setGstFree] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [displayNumber, setDisplayNumber] = useState('');
  const [ingredients, setIngredients] = useState('');


  const apiSuccess = useSelector((state) => state.restaurantMenu.apiSuccess);
  const updateTheMenuItem = (item, values) => dispatch(updateMenuItem(item, values));
  const clearApiStatus = () => dispatch(clearAPIStatus());
  const categories = useSelector((state) => state.restaurantMenu.menuCategories);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  useEffect(() => {
    if (props.menuItem) {
      const savedMenuItem = props.menuItem;
      setName(savedMenuItem.name);
      setAltName(savedMenuItem.altName);
      setIngredients(savedMenuItem.ingredients);
      setDisplayNumber(savedMenuItem.displayNumber);
      setPrice(savedMenuItem.price);
      setDescription(savedMenuItem.description);
      setProductId(savedMenuItem.productId);
      setSizes(savedMenuItem.sizes);
      if (savedMenuItem.gstFree) {
        setGstFree(savedMenuItem.gstFree);
      }
      if (savedMenuItem.isOpenTableItem !== undefined) {
        setIsOpenTableItem(savedMenuItem.isOpenTableItem);
      }

      if (savedMenuItem.isOpenTableSharedItem !== undefined && savedMenuItem.isOpenTableSharedItem !== null) {
        setIsOpenTableSharedItem(savedMenuItem.isOpenTableSharedItem);
      }

      if (savedMenuItem.shortName !== undefined && savedMenuItem.shortName !== null) {
        setShortName(savedMenuItem.shortName);
      }

      if (savedMenuItem.colorHex) {
        setColorHex(savedMenuItem.colorHex);
      }
    }

  }, [props]);


  const save = () => {
    if (!isNumber(Number(price))) {
      showError('Please enter a valiad number in the price field.');
      return;
    }

    const updateSizes = !sizes ? [] : sizes.map(size => {
      return { ...size, price: Number(size.price) };
    });
    if (menuItem && menuItem.id != null) {
      const updateMenuItem = {
        name,
        description,
        altName,
        shortName,
        price: Number(price),
        sizes: updateSizes,
        productId,
        ingredients,
        gstFree,
        displayNumber,
        isOpenTableItem,
        isOpenTableSharedItem,
        colorHex
      };
      // console.log('sizes', sizes, updateMenuItem);

      updateTheMenuItem({ ...menuItem, ...updateMenuItem, categoryId, menuId }, categories);
    }

  }

  if (apiSuccess) {
    clearApiStatus();
    props.editItem(false);
  }

  const close = () => {
    props.editItem(false);
  };


  const checkSizes = () => {
    if (!sizes) {
      return;
    }
    if (sizes.length === 0) {
      return false;
    }

    for (let i = 0; i < sizes.length; i++) {
      if (sizes[i].name === undefined || sizes[i].price === undefined) {
        return true;
      }
    }
    return false;

  };
  const handleGstChange = (event) => {
    setGstFree(event.target.checked);
  };

  const openColorPickerDialog = () => {
    setIsColorPickerDialogOpen(true);
  };

  const closeColorPickerDialog = () => {
    setIsColorPickerDialogOpen(false);
  };

  const handleColorSelected = (color) => {
    setColorHex(color);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Name"
              margin="dense"
              name="name"
              variant="outlined"
              value={name}
              onChange={e => setName(e.target.value)}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>


          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Alt Name"
              margin="dense"
              name="altName"
              type="text"
              variant="outlined"
              value={altName}
              onChange={e => setAltName(e.target.value)}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>


          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Short Name"
              margin="dense"
              name="shortName"
              type="text"
              variant="outlined"
              value={shortName}
              onChange={e => setShortName(e.target.value)}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Description"
              margin="dense"
              name="description"
              type="text"
              variant="outlined"
              value={description}
              onChange={e => setDescription(e.target.value)}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Price"
              margin="dense"
              name="price"
              variant="outlined"
              type="number"
              value={price}
              onChange={e => setPrice(e.target.value)}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Display Number"
              margin="dense"
              name="displayNumber"
              variant="outlined"

              value={displayNumber}
              onChange={e => setDisplayNumber(e.target.value)}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>


          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Product Id"
              margin="dense"
              name="productId"
              variant="outlined"
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={gstFree}
                  onChange={handleGstChange}
                  name="gstFree"
                />
              }
              label="GST free"
            />
          </Grid>


          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Ingredients (For Kitchen Only)"
              margin="dense"
              name="ingredients"
              variant="outlined"

              value={ingredients}
              onChange={e => setIngredients(e.target.value)}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isOpenTableItem}
                  onChange={e => setIsOpenTableItem(e.target.checked)}
                  name="isOpenTableItem"
                  color="primary"
                />
              }
              label="Is Open table Item"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isOpenTableSharedItem}
                  onChange={e => setIsOpenTableSharedItem(e.target.checked)}
                  name="isOpenTableSharedItem"
                  color="primary"
                />
              }
              label="Is Open table Shared Item"
            />
          </Grid>


          <Grid item md={6} xs={12}>

            <div >
              <div className="color-picker-row">
                <span className="color-picker-button">
                  <Button variant='outlined' onClick={openColorPickerDialog} >
                    Pick a color
                  </Button>
                </span>
                <span className="selected-color" style={{ backgroundColor: colorHex }}>{colorHex}</span>
              </div>
              <ColorPickerDialog
                isOpen={isColorPickerDialogOpen}
                onClose={closeColorPickerDialog}
                onColorSelected={handleColorSelected}
              />
            </div>


          </Grid>
          <Divider />
          <NewSizes updateSizes={setSizes} sizeList={sizes} />
        </Grid>

      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={close}>Cancel</Button>
        <Button variant="outlined" color="primary"
          disabled={!name ||
            !price ||
            checkSizes()
          }

          onClick={save}>Save</Button>
      </CardActions>
    </Card>
  );
};

export default MenuItemEdit;