import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import {
    Card,
    CardHeader,
    CardContent,
    List,
    Divider,
    ListItem,
} from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';


import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(() => ({
    root: {},
    progess: {
        position: 'absolute'
    },
    table: {
        minWidth: 700,
    },
}));

const OrderDetail = props => {
    const { className, order, ...rest } = props;

    const classes = useStyles();

    const history = useHistory();
    // const order = useSelector((state) => state.restaurant.selectedOrder);

    const user = useSelector((state) => state.account.user);
  const currencySymbol = (user && user.currencySymbol) ? user.currencySymbol : "$";


    const itemTotal = () => {
        if (order) {
            return order.order.items.filter((item) => item.dish.isDeletedDish !== true).reduce((count, curItem) => {
                return count + curItem.quantity * curItem.subTotal;
            }, 0);
        } else {
            return 0
        }
    };


    const getCustomerName = () => {
        if (order && order.user && order.user.name) {
            return order.user.name;
        }
        if (order && order.customerName) {
            return order.customerName;
        }
    };

    const getCustomerPhoneNumber = () => {
        if (order && order.user && order.user.phoneNumber) {
            return order.user.phoneNumber;
        }
        if (order && order.customerPhone) {
            return order.customerPhone;
        }
    };

    const getCustomerEmail = () => {
        if (order && order.user && order.user.email) {
            return order.user.email;
        }
        if (order && order.customerEmail) {
            return order.customerEmail;
        }
    };

    const getCustomerPostcode = () => {
        if (order && order.user && order.user.postcode) {
            return order.user.postcode;
        }
        if (order && order.customerPostcode) {
            return order.customerPostcode;
        }
    };

    const getCustomerAddress = () => {
        if (order && order.user && order.user.address) {
            return order.user.address;
        }
        if (order && order.address && order.address) {
            return order.address;
        }
    };

    const getCustomerNotes = () => {
     
        if (order && order.order && order.order.notes) {
            return order.order.notes;
        }
        return '';
    };

    const getDishStatus = (row) => {
        console.log('dish==', row.dish);
        if (row.dish.isRefunded === true) {
            return 'Refunded';
        }
        if (row.dish.isDeletedDish === true) {
            return 'Deleted';
        }
        return '';
    };

    return (
        <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
        >
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >

                <CardHeader
                    subheader="Order details"
                    title={`Order Number ${order && order.id}`}
                />
                <Divider />
                <CardContent>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="spanning table">
                            <TableHead>

                                <TableRow>
                                    <TableCell>Qty.</TableCell>
                                    <TableCell align="right">Item</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Options</TableCell>
                                    <TableCell align="right">Size</TableCell>
                                    <TableCell align="right">Special Instructions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order && order.order.items.filter(item => item.permanentDeletion !== true && item.isVoidedDish !== true).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.quantity}</TableCell>
                                        <TableCell align="right" style={{ maxWidth: 150 }}>{row.dish.name}{row.dish.description}</TableCell>
                                        <TableCell align="right">{currencySymbol}{row.dish && row.dish.price && Number(row.dish.price).toFixed(2).toString()}</TableCell>
                                        <TableCell align="right">{getDishStatus(row)}</TableCell>
                                        <TableCell align="right" style={{ maxWidth: 150 }}>
                                            <List dense
                                                disablePadding>
                                                {row.options && row.options.map(option => {
                                                    return <ListItem key={option.id}>
                                                        {`${option.name} x ${option.quantity} ${currencySymbol}${option.price}`}
                                                    </ListItem>
                                                })}
                                            </List>

                                        </TableCell>
                                        <TableCell align="right" style={{ maxWidth: 150 }}>{row.size ? `${row.size.name} ${currencySymbol}${row.size.price}` : ''}</TableCell>
                                        <TableCell align="right" style={{ maxWidth: 150 }}>{row.specialInstructions}{row.specialString && row.specialString} </TableCell>
                                    </TableRow>
                                ))}

                                <TableRow>

                                    <TableCell colSpan="2">Subtotal</TableCell>
                                    <TableCell align="right" colSpan={5}>{currencySymbol}{itemTotal().toFixed(2).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Table</TableCell>
                                    <TableCell align="left">{order && order.restaurant && order.restaurant.tableId}</TableCell>
                                    <TableCell>Choose Time</TableCell>
                                    <TableCell align="left">{order && order.order && order.order.pickupTime}</TableCell>

                                    <TableCell>Choose Date</TableCell>
                                    <TableCell align="left">{order && order.order && order.order.selectedDate}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell >Customer Name:</TableCell>
                                    <TableCell align="left">{getCustomerName()}</TableCell>
                                    <TableCell >Customer PhoneNo. :</TableCell>
                                    <TableCell align="let">{getCustomerPhoneNumber()}</TableCell>
                                    <TableCell >Customer Email:</TableCell>
                                    <TableCell align="let" colSpan={2}>{getCustomerEmail()}</TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell >Customer Postcode:</TableCell>
                                    <TableCell align="let">{getCustomerPostcode()}</TableCell>
                                    <TableCell >Customer Address:</TableCell>
                                    <TableCell align="let">{getCustomerAddress()}</TableCell>
                                    <TableCell >Customer Notes:</TableCell>
                                    <TableCell align="let">{getCustomerNotes()}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </CardContent>



            </Card>
        </Grid>
    );
};

OrderDetail.propTypes = {
    className: PropTypes.string,
    order: PropTypes.object
};

export default OrderDetail;
