import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment';

import {
  TodaySales,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  LatestSales,
  DishReport, 
} from './components';

import { SaleHoursReport } from '../Reports';


import { useHistory } from 'react-router-dom';

import LinearProgress from "@material-ui/core/LinearProgress";
import { useSelector } from "react-redux";


import API from 'util/API';
import palette from 'theme/palette';
import { useQuery } from "react-query";
import { getTodayDashboard } from "apis/reportAPIs";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));


const Dashboard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  
  const [customerDashboard, seCustomerDashboard] = useState(null);
  const [orderMonthDashboard, setOrderMonthDashboard] = useState([]);
  
  const [hourReportData, setHourReportData] = useState({});
  const [dishTodayCount, setDishTodayCount] = useState(0);
  const [dishReport, setDishReport] = useState([]);
  const user = useSelector((state) => state.account.user);
  const currencySymbol = (user && user.currencySymbol) ? user.currencySymbol : "$";

  const fectchCustomerDashboard = () => {
    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;
    // instance.defaults.withCredentials = true;
    API
      .get('/customerDashboard', {
        withCredentials: true,
        credentials: 'include'
        })
      .then((response) => {
        //console.log('customer response.data', response.data);
        //loadTodayOrders(response.data)
        seCustomerDashboard(response.data.rows);

      })
      .catch((error) => {
        console.log(error);

        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }
      });
  };

  const formateDate = (date) => {
    return moment(date).format('YYYY-MM-DD')
  };

  const buildHourDataSet = (data) => {
 
    const labelList = data.map(item => item.hour);
    setHourReportData( {
      labels: labelList,
      datasets: [
        {
          label: 'Sales',
          backgroundColor: palette.primary.main,
          data: data.map(item => item.amount)
        }
      ]
    });
  };

  const getHourReportData = async () => {
   
    try {
       
        const startDateStr = formateDate(Date());
        const endDateStr = formateDate(Date());
        const rewsponse = await API.get(`/salesHoursReport?startDateStr=${startDateStr}&endDateStr=${endDateStr}`);
        const data = await rewsponse.data;
        //console.log('data', data);
   
        if (data) {
          buildHourDataSet(data);
        } else {
            setHourReportData({});
        }

    } catch (err) {
       
        setHourReportData({});
    
    }
};


  const fectchTransactionMonthDashboard = () => {
    // setIsLoading(true);
    API
      .get('/salesMonthDashboard', {
        withCredentials: true,
        credentials: 'include'
        })
      .then((response) => {
        //console.log('orderMonthDashboard.data', response.data);
        //loadTodayOrders(response.data)
        setOrderMonthDashboard(response.data.rows);
        // setIsLoading(false);

      })
      .catch((error) => {
        console.log(error);
        // setIsLoading(false);
        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }
      });
  };

  const totalCount = (data) => {
    return  data.reduce((count, curItem) => {
        return count + curItem.count;
      }, 0);

}

  const getDateReportData = async () => {
   

    try {
        // setIsLoading(true);
        setDishReport([]);
        const startDateStr = formateDate(new Date());
        const endDateStr = formateDate(new Date());
        const rewsponse = await API.get(`/dishReport?startDateStr=${startDateStr}&endDateStr=${endDateStr}`);
        const data = await rewsponse.data;
       // console.log('data', data);
      //  setIsLoading(false);
        if (data) {
           setDishReport(data);
           setDishTodayCount(totalCount(data));
           
        } else {
          setDishReport([]);
        }


    } catch (err) {
        // setIsLoading(false);
        console.log(err);
        setDishReport([]);
       
    }
};


  const getTodayData = () => {
    // console.log('orderMonthDashboard', orderMonthDashboard);
    if (!orderMonthDashboard || orderMonthDashboard.length === 0) {
      return null;
    }
    const filterDates = orderMonthDashboard.filter(item => {
       return moment(item.date).isSame(moment(), 'day');

    });
   
    if (filterDates.length !== 1) {
       return null;
    } else {
      return filterDates[0];
    }
  };

  const isTodaySaleIncreased = () => {
    const count = orderMonthDashboard.length;
     if (count < 2) {
       return null;
     }
     return orderMonthDashboard[count - 1].amount > orderMonthDashboard[count - 2].amount;
  }

  // useEffect(() => {
  //   //fectchOrderTypes();
  //   //fectchTodayTransaction();
  //   if(user && user.role !== 'restaurantManager') {
  //   fectchCustomerDashboard();
  //   fectchTransactionMonthDashboard();
  //   getHourReportData();  
  //   getDateReportData();
  //   }
  // }, [props, user]);

  const { data, isLoading, error } = useQuery({
    queryKey: 'todaySalesReport',
    queryFn: () => getTodayDashboard(),
    enabled: user && user.role !== 'restaurantManager',
    cacheTime: 5 * 60 * 1000, // 5 minutes
  });



  return (

    <div className={classes.root}>
      {isLoading ? <LinearProgress style = {{ marginBottom: 8 }}/> : null}
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TodaySales todayData={data ? data.todaySales : 0} currencySymbol={currencySymbol}/>
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers todayCash={data ? data.todayCash : 0} currencySymbol={currencySymbol} />
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress todayData={data ? data.todayRefunds : 0} currencySymbol={currencySymbol}/>
        </Grid>

  

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit weekTotalSales={data ? data.weekTotalSales : 0} currencySymbol={currencySymbol}/>
        </Grid>

       

        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={12}
        >
          <SaleHoursReport weekReports={data ? data.weekReports : []} currencySymbol={currencySymbol} />
        </Grid>

       

        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={12}
        >
          <LatestSales weekReports={data ? data.weekReports : []} currencySymbol={currencySymbol} />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <DishReport  user={user} currencySymbol={currencySymbol}/>
        </Grid>
       
     
      </Grid>
    </div>
  );
};

export default Dashboard;
