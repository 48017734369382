import React, { useState } from 'react';
import ListContext from './ListContext';

const ListProvider = ({ children }) => {
  const [searchText, setSearchText] = useState('');
  const [selecteItemId, setSelectedItemId] = useState(null);

  return (
    <ListContext.Provider value={{ searchText, setSearchText, selecteItemId, setSelectedItemId }}>
      {children}
    </ListContext.Provider>
  );
};

export default ListProvider;
