import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import orderReducer from './reducers/orderReducers'
import menuReducer from './reducers/menuReducer'
import accountReducer from './reducers/accountReducer'
import restaurantReducer from './reducers/restaurantReducer'
import dishReducer from './reducers/dishReducer'
import transactionReducer from './reducers/transactionReducer'
import uiReducer from './reducers/uiReducer'
import restaurantMenuReducer from './reducers/restaurantMenuReducer'
import bookingReducer from './reducers/bookingReducer'


const rootReducer = combineReducers({
    menu: menuReducer,
    order: orderReducer,
    account: accountReducer,
    restaurant: restaurantReducer,
    dish: dishReducer,
    transaction: transactionReducer,
    ui: uiReducer,
    restaurantMenu: restaurantMenuReducer,
    booking: bookingReducer
  })


export const store = createStore(
    rootReducer,
    applyMiddleware(thunk),
//    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

//store.subscribe(() => { console.log(store.getState()) });