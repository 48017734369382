import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import RestaurantQRCode from './RestaurantQRCode';
import TableQRCode from './TableQRCode'
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import LinearProgress from "@material-ui/core/LinearProgress";

import { useHistory } from "react-router-dom";

import { showSuccessSnackbar, showErrorSnackbar } from 'store/actions/snackbarMessage'

import API from "util/API";

const useStyles = makeStyles((theme) => ({

    root: {
        padding: theme.spacing(3)
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        height: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

const initialValues = [{
    name: 'pickup-delivery',
    disabled: false,
    disabledDate: null
},
{
    name: 'takeaway',
    disabled: false,
    disabledDate: null
},
{
    name: 'pickup',
    disabled: false,
    disabledDate: null
},
{
    name: 'delivery',
    disabled: false,
    disabledDate: null
},
{
    name: 'foodcourt',
    disabled: false,
    disabledDate: null
},
{
    name: 'booking',
    disabled: false,
    disabledDate: null
},
{
    name: 'dinein',
    disabled: false,
    disabledDate: null
},
];


const QrCodes = (props) => {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();
    const restaurantId = props.match.params.restaurantId;
    const restaurant = useSelector((state) => state.restaurant.selectedRestaurant);
    const [uiLoading, setUiLoading] = useState(false);
    const showError = (msg) => dispatch(showErrorSnackbar(msg));
    const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
    const user = useSelector((state) => state.account.user);
    const [qrSettings, setQrSettings] = useState(initialValues);


    useEffect(() => {
        if(restaurant && restaurant.qrcodeSettings) {
            setQrSettings(restaurant.qrcodeSettings)
        }
    
    }, [restaurant]);


   

    const updateQRCodeSetting = (name, disabled, dateString) => {
        const  filteredSettings = qrSettings.filter(item => item.name !== name);
        const newSetting = {
            name,
            disabled,
            disabledDate: dateString
        };
       // console.log('newSetting', newSetting);
        setQrSettings([...filteredSettings, newSetting]);
    }


    const updateQRCode = () => {
        if (!restaurant) {
            showError('Please go back to the restaurant screen and try again.');
            return;
        }

          console.log('qrSettings', qrSettings);

        setUiLoading(true);
        API.put("/updateRestaurantValues/", {
            qrCodeSettings: qrSettings,
        })
            .then((response) => {
                setUiLoading(false);
                showSuccess('Updated successfully');
                if (user.role === "restaurantAdmin") {
                    history.push("/myRestaurant");
                } else {
                    history.push("/restaurants");
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    props.history.push("/sign-in");
                }
                console.log(JSON.stringify(error));
                setUiLoading(false);

            });
    };


    const data = [
        {
            name: 'pickup-delivery',
            code: '36f8126f806be0dc9f717f701855a394'
        },
        {
            name: 'takeaway',
            code: 'a0dd323a91c94382bd97261abebd8268'
        },
        {
            name: 'pickup',
            code: '4153876530a8959826e9c0796be24d3a'
        },
        {
            name: 'delivery',
            code: '75687ce9093f8f8a98d85c9f7c36ee57'
        },
        {
            name: 'foodcourt',
            code: 'f28e2bc40c4c98b33f952aed3fdd0d6d'
        },
        {
            name: 'booking',
            code: 'restaurantBooking'
        },
        {
            name: 'queue',
            code: 'queue'
        }
    ];

    return (
        <div className={classes.root}>
            {restaurant ?
                (
                    <div>
                     {uiLoading ? <LinearProgress /> : null}
                        <Grid item md={6} xs={12}>
                            <Button
                                className={classes.submitButton}
                                variant="contained"
                                onClick={updateQRCode}
                                color="primary"
                                disabled={ uiLoading}
                            >
                                Save
                            </Button>
                        </Grid>
                        {data.map(item => {
                            return <RestaurantQRCode restaurantId={restaurantId} item={item} key={item.code} updateQRCodeSetting={updateQRCodeSetting}/>;
                        })}
                        <TableQRCode restaurantId={restaurantId} updateQRCodeSetting={updateQRCodeSetting}/>
                        
                        </div>
                ) :
                (
                    <Typography color='error'>
                        Please go back to the restaurant screen.
                    </Typography>
                )
            }

        </div>
    );
}


export default QrCodes;