import React, { useState, useEffect } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import BottomNavigation from "@material-ui/core/BottomNavigation";

import EmojiFoodBeverageIcon from "@material-ui/icons/EmojiFoodBeverage";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";

import LinearProgress from "@material-ui/core/LinearProgress";

import FormLabel from "@material-ui/core/FormLabel";
import constants from "util/constants";

import clsx from "clsx";

import { Card, CardContent, Divider, Grid, TextField } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import {
  setRestaurantId,
  emptyCart,
  saveUser,
  loadUser,
  updateTillPaymentUrl,
} from "store/actions/order";
import axios from "axios";

import { usePosition } from "use-position";

import { mobileModel, browserName, browserVersion } from "react-device-detect";

import {
  loadTableId,
  checkMobilePhone,
  checkAustraliaPostcode,
  checkNumber,
} from "util/orderUtils";


import CallButton from "../Online/components/CallButton";
import Payments from "./Payments";
import OrderDateTime from "./OrderDateTime";

import { filterOpenHours, decodeRestaurantId } from "util/utils";
import {
  isOrderInRestaurant,
  isTakeaway,
  isDelivery,
  isPickup,
  isPickupOrDelivery,
  isFoodcourt,
} from "util/restaurantUtils";
import { showErrorSnackbar } from "store/actions/snackbarMessage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    size: 12,
  },

  leftMargin: {
    marginLeft: theme.spacing(2),
  },

  formTitle: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  formContent: {
    flexGrow: 1,
    margin: theme.spacing(2),
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    // marginBottom: theme.spacing(2)
  },
  item: {
    width: "100%",
    // maxWidth: 360,
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  progress: {
    position: "absolute",
  },
}));
const captchaKey = process.env.REACT_APP_RECAPTCHA;

const Checkout = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [guestNum, setGuestNum] = useState("");
  const [address, setAddress] = useState("");
  const [pickupTimeType, setPickupTimeType] = useState("now");
  const [pickupTime, setPickupTime] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyCodeError, setVerifyCodeError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [postcode, setPostcode] = useState("");
  const [notes, setNotes] = useState("");
  const [sid, setSid] = useState("");
  const [foodAllergies, setFoodAllergies] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(Date());
  const [realTableId, setRealTableId] = useState(null);
  const [chooseType, setChooseType] = useState("pickup");

  const updateRestaurantId = (restaurantId) =>
    dispatch(setRestaurantId(restaurantId));
  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  const history = useHistory();
  const [successLink, setSuccessLink] = useState("/orderSuccess");
  const updateUser = (user) => dispatch(saveUser(user));
  const loadSavedUser = () => dispatch(loadUser());
  const updateTillPayUrl = (url) => dispatch(updateTillPaymentUrl(url));
  const menuTableId = useSelector((state) => state.menu.tableId);
  const restaurant = useSelector((state) => state.menu.restaurant);
  const memberId = useSelector((state) => state.order.memberId);

  const goBack = (e) => {
    props.history.goBack();
  };
  const order = useSelector((state) => state.order);
  const savedUser = useSelector((state) => state.order.user);

  const id = props.match.params.menuId;
  const tableId = props.match.params.tableId;

  const watch = true;
  const { latitude, longitude, timestamp, accuracy, error } = usePosition(
    watch,
    { enableHighAccuracy: true }
  );

  useEffect(() => {
    loadSavedUser();
  }, [props]);

  useEffect(() => {
    // Add reCaptcha
   
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${captchaKey}`;

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (savedUser) {
      setName(savedUser.name);
      setEmail(savedUser.email);
      setPhoneNumber(savedUser.phoneNumber ? savedUser.phoneNumber : "");
      setGuestNum(savedUser.guestNum ? savedUser.guestNum : "");
      setAddress(savedUser.address);
    }
  }, [savedUser]);

  useEffect(() => {
    const successLinkTarget = `/orderSuccess/${id}/${tableId}`;
    //console.log('successLinkTarget', successLinkTarget);
    setSuccessLink(successLinkTarget);

    if (order.cart.length === 0 && id && tableId) {
      updateRestaurantId({
        restaurantId: decodeRestaurantId(id),
        tableId: tableId,
      });
    }
    setSelectedDate(Date());

    //console.log('menuTableId', realTableId, id, menuTableId);
    setupTableId();
  }, [props]);

  const setupTableId = () => {
    if (!menuTableId) {
      setRealTableId(loadTableId(decodeRestaurantId(id)));
      // console.log('realTableId get', realTableId, id);
    } else {
      setRealTableId(menuTableId);
    }
  };

  const isDineRequired = () => {
    //console.log('realTableId', realTableId);
    return (
      String(realTableId).split(";").length === 2 &&
      String(realTableId).split(";")[1] === "required"
    );
  };

  const isDineinPaymentOptional = () => {
    // console.log('realTableId', realTableId);
    return (
      String(realTableId).split(";").length === 2 &&
      String(realTableId).split(";")[1] === "optional"
    );
  };

  function isPositiveInteger(n) {
    return n >>> 0 === parseFloat(n);
  }

  const isGuestNumberShown = () => {
    if (
      isOrderInRestaurant(realTableId) &&
      restaurant &&
      restaurant.onlineSettings &&
      restaurant.onlineSettings.isDineInGuestNumberRequired &&
      restaurant.onlineSettings.isDineInGuestNumberRequired === true
    ) {
      return true;
    }
    return false;
  };

  const isMobileNumbeHidden = () => {
    if (
      isOrderInRestaurant(realTableId) &&
      (!restaurant ||
        !restaurant.onlineSettings ||
        !restaurant.onlineSettings.isDineInMobilePhoneRequired)
    ) {
      return true;
    }
    return false;
  };

  const needCheckGuestNum = () => {
    // console.log('isDineMobileAndGuestNumberOptional()', isDineMobileAndGuestNumberOptional());
    if (!isGuestNumberShown()) {
      // console.log("not dine in");
      return false;
    }

    if (isDineMobileAndGuestNumberOptional()) {
      return false;
    }

    // console.log("guestNum", guestNum);
    if (guestNum.trim() === "") {
      return true;
    }

    return !isPositiveInteger(Number(guestNum));
  };

  const needCheckAddress = () => {
    if (
      isDelivery(realTableId) ||
      (isPickupOrDelivery(realTableId) && chooseType === "delivery")
    ) {
      return !address;
    } else {
      return false;
    }
  };

  const isPaymentRequired = () => {
    // console.log('isFoodcourt', isFoodcourt(realTableId), restaurant.onlineSettings, realTableId);
    return (
      isDelivery(realTableId) ||
      isPickup(realTableId) ||
      isPickupOrDelivery(realTableId) ||
      isDineRequired() ||
      (isFoodcourt(realTableId) &&
        restaurant &&
        restaurant.onlineSettings &&
        restaurant.onlineSettings.foodCourtPaymentRequired === true)
    );
  };

  const needCheckPayment = () => {
    return (
      isPaymentRequired() &&
      (!order.paymentType || order.paymentType.trim() === "")
    );
  };

  const needCheckDateTime = () => {
    // console.log('selectedDate', pickupTimeType, pickupTime.trim(), selectedDate, !selectedDate);
    if (pickupTimeType === "now" || isOrderInRestaurant(realTableId)) {
      return false;
    }
    if (pickupTime.trim() === "" || !selectedDate) {
      return true;
    }
    return false;
  };

  const showTakeawayPayment = () => {
    //console.log('restaurant', restaurant);
    return (
      isTakeaway(realTableId) &&
      restaurant &&
      (restaurant.takeawayPayment === undefined ||
        restaurant.takeawayPayment === true)
    );
  };

  const showFoodcourtPayment = () => {
    //console.log('restaurant', restaurant);
    return (
      isFoodcourt(realTableId) &&
      restaurant &&
      restaurant.onlineSettings &&
      restaurant.onlineSettings.foodCourtPaymentOptional === true
    );
  };

  const handleTypeChange = (event) => {
    setChooseType(event.target.value);
  };

  const getOrderFoodTime = () => {
    if (pickupTimeType === "now") {
      return moment();
    }

    const selectedDateStr = moment(selectedDate).format("DD/MM/YYYY");
    return moment(`${selectedDateStr} ${pickupTime}`, "DD/MM/YYYY HH:mm");
  };

  const isDineMobileAndGuestNumberOptional = () => {
    return (
      isOrderInRestaurant(realTableId) &&
      restaurant &&
      restaurant.isDineMobileOptional === true
    );
  };

  const isMobileNumberNotValid = () => {
    if (isMobileNumbeHidden()) {
      return false;
    }
    if (
      isOrderInRestaurant(realTableId) &&
      isDineMobileAndGuestNumberOptional() &&
      phoneNumber === ""
    ) {
      return false;
    }

    return !checkMobilePhone(phoneNumber);
  };

  const checkOpenHours = () => {
    if (restaurant && restaurant.openHours) {
      const orderTime = getOrderFoodTime();
      // console.log(orderTime);
      return filterOpenHours(restaurant.openHours, orderTime);
    }

    return true;
  };

  const updatePhoneNumber = (mobilePhone) => {
    setPhoneNumber(mobilePhone);
    if (!checkMobilePhone(mobilePhone)) {
      setPhoneNumberError("The mobile number is invalid");
    } else {
      setPhoneNumberError("");
    }
  };

  const updateVerifyCode = (code) => {
    setVerifyCode(code);
    if (!checkNumber(code)) {
      setVerifyCodeError("The code is invalid");
    } else {
      setVerifyCodeError("");
    }
  };

  const showErrorMessage = () => {
    showError("Something went wrong! Please try again.");
  };

  const isPostcodeRequired = () => {
    return (
      (isDelivery(realTableId) || isPickupOrDelivery(realTableId)) &&
      postcode === ""
    );
  };

  const isVerifyCodeValid = () => {
    if (restaurant && restaurant.smsVerify === true) {
      if (verifyCode.trim() === "" || checkNumber(verifyCode) === false) {
        return false;
      }
    }
    return true;
  };

  const sendVerifyCode = () => {
    if (!checkMobilePhone(phoneNumber)) {
      showError("Please enter a valid mobile phone number");
      return;
    }
    setCheckLoading(true);

    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(captchaKey, { action: "submit" })
        .then(function (token) {
          (async () => {
            try {
              const response = await instance.post("/sendCode", {
                mobilePhone: phoneNumber,
                recaptcha: token,
                restaurantId: order.restaurant.restaurantId,
              });
              if (response && response.data && response.data.sid) {
                setSid(response.data.sid);
              }
              setCheckLoading(false);
            } catch (err) {
              setCheckLoading(false);
              showError("Something went wrong! Please try again.");
            }
          })();
        });
    });
  };

  const getSurcharges = (cartItemTotal) => {
    let surcharges = [];
    if (
      restaurant &&
      restaurant.holidaySurcharge &&
      restaurant.holidaySurcharge.isEnabled === true
    ) {
      let holidaySurcharge = 0;
      if (restaurant.holidaySurcharge.isPercentage === true) {
        const percents = Number(restaurant.holidaySurcharge.amount);
        holidaySurcharge = (cartItemTotal * percents) / 100;
      } else {
        holidaySurcharge = Number(restaurant.holidaySurcharge.amount);
      }

      surcharges.push({
        name: "holiday/weekend surcharge",
        tyep: "holiday",
        amount: holidaySurcharge.toFixed(2),
      });
    }
    return surcharges;
  };

  const placeOrder = (event) => {
    event.preventDefault();
    if (!checkOpenHours()) {
      showError(
        "The merchant is now not operating, Please order after opening."
      );
      return;
    }

    if (isPostcodeRequired()) {
      showError("Please enter a postcode.");
      return;
    }

    if (isMobileNumberNotValid()) {
      showError("Please enter a valid mobile phone number.");
      return;
    }

    if (postcode.length > 0 && !checkAustraliaPostcode(postcode)) {
      showError("Please enter a valid postcode.");
      return;
    }

    setButtonLoading(true);
    instance.defaults.headers.common["restaurantId"] =
      order.restaurant.restaurantId;
    instance.defaults.headers.common["menuId"] = id;
    instance.defaults.headers.common["clientId"] = constants.CLIENT_ID;

    const cartItemTotal = order.cart.reduce((count, curItem) => {
      return count + curItem.quantity * curItem.subTotal;
    }, 0);

    const formRequest = {
      user: {
        name,
        email,
        phoneNumber,
        guestNum: guestNum.trim() === "" ? "0" : guestNum.trim(),
        address,
        postcode,
        foodAllergies,
        geolocation: {
          latitude,
          longitude,
          timestamp,
          accuracy,
          error,
        },
      },
      order: {
        items: order.cart,
        pickupTimeType: pickupTimeType,
        pickupTime: pickupTime,
        selectedDate: moment(selectedDate).format("DD/MM/YYYY"),
        total: Number(cartItemTotal.toFixed(2)),
        paymentType: order.paymentType,
        isPaymentRequired: isPaymentRequired(),
        type: isOrderInRestaurant(realTableId) ? "dinein" : realTableId,
        realTableId,
        chooseType,
        notes,
        orderTime: moment().format("DD/MM/YYYY HH:mm:ss"),
        surcharges: getSurcharges(cartItemTotal),
      },
      restaurant: order.restaurant,
      menuId: id,
      browser: `${browserName} ${browserVersion}`,
      mobileModel: mobileModel,
      verifyCode,
      sid,
      memberId
    };
    updateUser(formRequest.user);
    try {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(captchaKey, { action: "submit" })
          .then(function (token) {
            (async () => {
              try {
                const response = await instance.post("/placeOrder", {
                  ...formRequest,
                  recaptcha: token,
                });

                //console.log(response.data);

                setButtonLoading(false);
                dispatch(
                  emptyCart({
                    ...formRequest.order,
                    id: response.data.reference,
                  })
                );
                
                  if (
                    response.data.isTillPayment === true &&
                    response.data.payUrl &&
                    response.data.payUrl.trim().length > 0
                  ) {
                    updateTillPayUrl(response.data.payUrl);
                    history.push(
                      `/tillPayment/${id}/${tableId}/${response.data.reference}`
                    );
                    return;
                  }
                  if (
                    response.data.payUrl &&
                    response.data.payUrl.trim().length > 0
                  ) {
                    window.location.assign(response.data.payUrl);
                  } else {
                    history.push(
                      `${successLink}/${response.data.reference}/local`
                    );
                  }
                
              } catch (err) {
                console.error(error);
                setButtonLoading(false);
                showErrorMessage();
              }
            })();
          });
      });
    } catch (error) {
      console.error(error);
      setButtonLoading(false);
      showErrorMessage();
    }
  };

  const handleDateChange = (date) => {
    // console.log('date', date);
    setSelectedDate(date);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={goBack}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Grid alignItems="center" xs={12} container justify="center">
            <Grid item alignItems="center">
              <CallButton />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {buttonLoading ? <LinearProgress /> : null}

      <Card className={clsx(classes.root, classes, classes.formContent)}>
        <form noValidate>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Name (required)"
                  margin="dense"
                  name="name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {isGuestNumberShown() ? (
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={`Number of guests (${
                      isDineMobileAndGuestNumberOptional() === true
                        ? "Optional"
                        : "required"
                    })`}
                    margin="dense"
                    name="guestNum"
                    type="number"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={guestNum}
                    onChange={(e) => setGuestNum(e.target.value)}
                  />
                </Grid>
              ) : null}

              {isPickupOrDelivery(realTableId) ? (
                <Grid item md={6} xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Choose pickup or delivery
                    </FormLabel>
                    <RadioGroup
                      value={chooseType}
                      aria-label="payment choice"
                      name="chooseType"
                      onChange={handleTypeChange}
                    >
                      <FormControlLabel
                        value="pickup"
                        control={<Radio color="primary" />}
                        label="Pickup"
                      />
                      <FormControlLabel
                        value="delivery"
                        control={<Radio color="primary" />}
                        label="Delivery"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              ) : null}

              {isDelivery(realTableId) ||
              (isPickupOrDelivery(realTableId) && chooseType === "delivery") ? (
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Address (required)"
                    margin="dense"
                    name="address"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
              ) : null}

              {isDelivery() ||
              isTakeaway(realTableId) ||
              isPickup(realTableId) ||
              isPickupOrDelivery(realTableId) ? (
                <OrderDateTime
                  pickupTimeTypeHandler={setPickupTimeType}
                  pickupTimeHandler={setPickupTime}
                  selectedDateHandler={setSelectedDate}
                />
              ) : null}

              {isMobileNumbeHidden() ? null : (
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={`Mobile Number (${
                      isDineMobileAndGuestNumberOptional() === true
                        ? "Optional"
                        : "required"
                    })`}
                    margin="dense"
                    name="phone"
                    type="number"
                    error={isMobileNumberNotValid()}
                    helperText={phoneNumberError}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={phoneNumber}
                    onChange={(e) => updatePhoneNumber(e.target.value)}
                  />

                  {restaurant && restaurant.smsVerify === true ? (
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={sendVerifyCode}
                      >
                        Send Code (SMS verification is required)
                      </Button>{" "}
                      {checkLoading && (
                        <CircularProgress
                          size={20}
                          className={classes.progess}
                        />
                      )}
                    </>
                  ) : null}
                </Grid>
              )}

              {restaurant && restaurant.smsVerify === true ? (
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Enter your code"
                    margin="dense"
                    name="verifyCode"
                    type="number"
                    error={!checkNumber(verifyCode)}
                    helperText={verifyCodeError}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={verifyCode}
                    onChange={(e) => updateVerifyCode(e.target.value)}
                  />
                </Grid>
              ) : null}

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email (Optional, For receiving the order)"
                  margin="dense"
                  name="email"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={`Postcode (${
                    isPostcodeRequired() ? "Required" : "Optional"
                  })`}
                  margin="dense"
                  name="postcode"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    isPostcodeRequired() ? "Please enter a postcode" : ""
                  }
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                />
              </Grid>
              {restaurant &&
              restaurant.onlineSettings &&
              restaurant.onlineSettings.specialTerms &&
              restaurant.onlineSettings.specialTerms.length > 0 ? (
                <Grid item md={6} xs={12}>
                  <Paper className={classes.bottomMargin}>
                    <Alert severity="warning">
                      <strong>{restaurant.onlineSettings.specialTerms}</strong>
                    </Alert>
                  </Paper>
                </Grid>
              ) : null}

              {isPaymentRequired() ||
              showTakeawayPayment() ||
              showFoodcourtPayment() ? (
                <Grid item md={6} xs={12}>
                  <Payments
                    restaurant={restaurant}
                    optional={isTakeaway(realTableId) || showFoodcourtPayment()}
                  />
                </Grid>
              ) : null}

             
            </Grid>
          </CardContent>
        </form>
      </Card>

      <Box color="text.secondary">
        <Typography variant="caption" display="inline">
          <p className={classes.leftMargin}>
            By placing this order you accept the
            <br />-{" "}
            <a href="/privacypolicy.html" target="_blank">
              Data Processing Policy
            </a>
            {/* <br />
            - End User License Agreement of the Online Ordering System */}
            <br />-{" "}
            <a href="/terms.html" target="_blank">
              Restaurant Terms of Service
            </a>
            <br />
            This site is protected by reCAPTCHA and the Google{" "}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">
              Terms of Service
            </a>{" "}
            apply.
          </p>
        </Typography>
      </Box>

      <BottomNavigation className={classes.stickToBottom}>
        <Button
          size="large"
          variant="contained"
          fullWidth
          color="primary"
          textPrimary="secondary"
          onClick={placeOrder}
          disabled={
            buttonLoading ||
            !name ||
            needCheckGuestNum() ||
            needCheckDateTime() ||
            needCheckAddress() ||
            isPostcodeRequired() ||
            isMobileNumberNotValid() ||
            needCheckPayment() ||
            order.cart.length === 0 ||
            !isVerifyCodeValid()
          }
          startIcon={<EmojiFoodBeverageIcon />}
        >
          {" "}
          Place Order
        </Button>
      </BottomNavigation>
    </div>
  );
};

export default Checkout;
