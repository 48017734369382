import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { showErrorSnackbar } from 'store/actions/snackbarMessage'
import { useDispatch } from 'react-redux';
import { copyMenu } from 'store/actions/restaurantMenu';

import API from 'util/API';

export default function MenuCopyToDialog(props) {
  const {open, selectedMenus, updateSelectedMenu, isTest } = props;
  const dispatch = useDispatch();
  const [options, setOtions] = useState([]);
  const [value, setValue] = React.useState(null);
  const [uiLoading, setUiLoading] = useState(false);
  
  const copyTheMenu = (menuId, toRestaurantId, isCopyToTest) => dispatch(copyMenu(menuId, toRestaurantId, isCopyToTest));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const handleClose = () => {
    props.closeHandler();
  };

 const getAllRestrauntIds = async () => {
  const url = isTest ? 'restaurantIds?env=test' : 'restaurantIds';

    const result = await API.get(url);
    setOtions(result.data)
     
 };

 const handleCopyToMenu = () => {
    console.log('selectedMenus', selectedMenus);
    if (!selectedMenus || selectedMenus.length !== 1) {
      showErrorMessage('Please select a menu first.')
      return;
    }
    if (!value || !value.restaurantId) {
        showErrorMessage('Please select a restaurant.')
        return;
      }
    setUiLoading(true);
    copyTheMenu(selectedMenus[0], value.restaurantId, isTest);
    updateSelectedMenu([]);
    handleClose();

  };

 useEffect(() => {
     getAllRestrauntIds();
 }, [props])

  return (
    <div>
    
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select a restaurant to copy to</DialogTitle>
        <DialogContent>
        
        <Autocomplete
      id="restaurantId"
      options={options}
      getOptionLabel={(option) => option.name}
      style={{ minWidth: 300 }}

      value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
  
      renderInput={(params) => <TextField {...params} label="Restaurant" variant="outlined" />}
    />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCopyToMenu} 
          disabled={uiLoading}
           color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
