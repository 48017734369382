// ZoneManagement.js

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from "@material-ui/core";

import {
  
  getPrinterZones,
  createPrinterZone,
  deletePrinterZone,
} from "apis/printAPIs";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "store/actions/snackbarMessage";

const ZoneDialog = ({ isOpen, onClose, onAddZone, floors, printers }) => {
  const dispatch = useDispatch();
  const [zoneType, setZoneType] = useState(""); // 'floorTable' or 'logic'
  const [zoneName, setZoneName] = useState("");
  const [zoneDescription, setZoneDescription] = useState("");
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [selectedTables, setSelectedTables] = useState([]);
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const relatedTables =
    selectedFloor && selectedFloor !== ""
      ? floors.find((area) => area.name === selectedFloor)?.tables
      : [];

  const handleAdd = () => {
    // find the printer object

    // validate the zone
    if (zoneName === "") {
      showErrorMessage("Please enter a zone name");
      return;
    }
    if (zoneType === "floorTable" && selectedFloor === null) {
      showErrorMessage("Please select a floor");
      return;
    }
    // if (zoneType === "floorTable" && selectedTables.length === 0) {
    //   showErrorMessage("Please select at least one table");
    //   return;
    // }
    if (selectedPrinter === "") {
      showErrorMessage("Please select a printer");
      return;
    }

    const printer = printers.find(
      (printer) => printer.printerName === selectedPrinter
    );
    const newZone = {
      zoneType,
      zoneName,
      zoneDescription,
      zoneFloor: selectedFloor,
      zoneTables: selectedTables,
      printer,
      id: new Date().getTime(), // rudimentary ID generation
    };
    onAddZone(newZone);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Create Zone</DialogTitle>
      <DialogContent>
        <Select
          value={zoneType}
          onChange={(e) => setZoneType(e.target.value)}
          fullWidth
        >
          <MenuItem value="floorTable">Floor and Table Based</MenuItem>
          <MenuItem value="logic">Logic Zone</MenuItem>
        </Select>
        <TextField
          label="Zone Name"
          value={zoneName}
          onChange={(e) => setZoneName(e.target.value)}
          fullWidth
        />
        {zoneType === "floorTable" && (
          <>
            <Select
              value={selectedFloor}
              onChange={(e) => {
                setSelectedFloor(e.target.value);
                setSelectedTables([]); // Clear table selection when changing floor
              }}
            >
              {floors?.map((floor) => (
                <MenuItem key={floor.name} value={floor.name}>
                  {floor.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              multiple
              value={selectedTables}
              onChange={(e) => setSelectedTables(e.target.value)}
            >
              {relatedTables.map((tableNumber) => (
                <MenuItem key={tableNumber} value={tableNumber}>
                  {tableNumber}
                </MenuItem>
              ))}
            </Select>
          </>
        )}

        <TextField
          label="Zone Description"
          value={zoneDescription}
          onChange={(e) => setZoneDescription(e.target.value)}
          fullWidth
        />
        <Select
          value={selectedPrinter}
          onChange={(e) => setSelectedPrinter(e.target.value)}
        >
          {printers.map((printer) => (
            <MenuItem key={printer.printerName} value={printer.printerName}>
              {printer.printerName}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add Printer Zone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ZoneManagement = ({ floors, printers }) => {
  const [isZoneDialogOpen, setZoneDialogOpen] = useState(false);

  const createZoneMutation = useMutation(createPrinterZone);
  const zonesListName = "printerZones";

  const queryClient = useQueryClient();
  const { data: zones, isLoading } = useQuery(zonesListName, getPrinterZones);

  const handleAddZone = (zoneData) => {
    createZoneMutation.mutate(zoneData, {
      onSuccess: () => {
        setZoneDialogOpen(false);
        queryClient.invalidateQueries(zonesListName);
      },
    });
    
  };

  const deleteZoneMutation = useMutation(deletePrinterZone);

  const handleDeleteZone = (zoneId) => {
    deleteZoneMutation.mutate(zoneId, {
      onSuccess: () => {
        setZoneDialogOpen(false);
        queryClient.invalidateQueries(zonesListName);
      },
    });
   
  };

  

  if (isLoading) return <LinearProgress />;

  return (
    <div>
      <Button onClick={() => setZoneDialogOpen(true)} color="primary">
        New Printer Zone
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Floor/Tables</TableCell>
            <TableCell>Printer</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {zones.map((zone) => (
            <TableRow key={zone.id}>
              <TableCell>{zone.zoneType}</TableCell>
              <TableCell>{zone.zoneName}</TableCell>
              <TableCell>
                {zone.zoneType === "floorTable"
                  ? `${zone.zoneFloor} ${zone.zoneTables.join(", ")}`
                  : ""}
              </TableCell>
              <TableCell>{zone.printer && zone.printer.printerName}</TableCell>
              <TableCell>{zone.zoneDescription}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteZone(zone.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ZoneDialog
        floors={floors}
        printers={printers}
        isOpen={isZoneDialogOpen}
        onClose={() => setZoneDialogOpen(false)}
        onAddZone={handleAddZone}
      />
    </div>
  );
};

export default ZoneManagement;
