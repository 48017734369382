import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

import { TransactionsToolbar,  StaffActivityTable } from './components';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setTransactions, setTransactionPage } from 'store/actions/transaction';
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import API from 'util/API';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%'
  }
}));




const StaffActivityList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiLoading, setUiLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const selectedTransactionIds = useSelector((state) => state.transaction.ids);

  
  const orderType = useSelector((state) => state.restaurant.orderType);
  const selectedDate = useSelector((state) => state.restaurant.orderSelectedDate);
  const setPage = (page) => dispatch(setTransactionPage(page));
  const [staffActivities, setStaffActivities] = useState([]);

  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const history = useHistory();

  const getPage = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get('page');
  }


  const fetchTransactions = async () => {
    setUiLoading(true);
    //console.log("selectedDate", moment(selectedDate).format("DD-MM-YY"));
    try {
      const response = await API.get(
        "/staffActivities?dateStr=" + moment(selectedDate).format("DD-MM-YY")
      );
      //console.log(response.data);
      setUiLoading(false);
      if (response && response.data && response.data.staffActivityList) {
        setStaffActivities(response.data.staffActivityList);
        
        if (!getPage()) {
          setPage(0);
        }
      }
    } catch (error) {
      console.log(error);
      setUiLoading(false);
      if (error.response && error.response.status === 403) {
        history.push("/sign-in");
      }

      setErrorMsg("Error in retrieving the data");
  }
};

 


  const updateTransactions = async (reason) => {
    if (!selectedTransactionIds || selectedTransactionIds.length === 0) {
      return
    }
    console.log('deleteReason', reason);
    setUiLoading(true);

    try {
      const dateStr = moment(selectedDate).format("DD-MM-YY");
      const rewsponse = await API.post(`/updateTransactions`, {transactionIds:selectedTransactionIds, dateStr, reason});
      const data = await rewsponse.data;
      fetchTransactions();
    
      
    } catch (err) {
      console.log(err);
      setUiLoading(false);
     
    }
  };


 

  useEffect(() => {
    if (!getPage()) {
      fetchTransactions();
    }
   
  }, [props]);



  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {

    return (
      <div className={classes.root}>
        <TransactionsToolbar deleteProxy={updateTransactions} />
        <div className={classes.content}>
          <StaffActivityTable staffActivities={staffActivities} />
        </div>
      </div>
    );
  }
};

export default StaffActivityList;

