import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { TableRow, TableSortLabel } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
// import {
//     PieChart,
//     Pie,
//     Cell,
//     Tooltip,
//     Legend,
//     ResponsiveContainer,
//   } from "recharts";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


const useStyles = makeStyles((theme) => ({
  root: {},
  table: {},
  top: {
    marginTop: theme.spacing(1),
  },
  categoryRow: {
    backgroundColor: "#f5f5f5", // Change the background color as desired
  },
}));

const PaymentTypes = (props) => {
  const { reportData } = props;
  const classes = useStyles();
  const user = useSelector((state) => state.account.user);
  const currencySymbol = (user && user.currencySymbol) ? user.currencySymbol : "$";

  const [isLoading, setIsLoading] = useState(false);
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884d8",
    "#8dd1e1",
    "#82ca9d",
    "#a4de6c",
    "#d0ed57",
    "#ffc658",
  ];
  //   const colors = ['#8884d8', '#82ca9d', '#ffc658', '#FF8042', '#FFBB28', '#0088FE', '#FFD700', '#FF4500', '#4B0082', '#2E8B57'];


  function calculatStatistics(groupedDict) {
    const totalSales = groupedDict.reduce(
      (sum, category) => {
        if (category !== 'Adjustments Before Payments' && category.items && category.items.length > 0) {
          return sum + category.total;
        } else {
          return sum;
        }
      },
      0
    );


    const result = groupedDict.filter(item => item.total).map(({ category, items, total }) => {

      const updatedItems = items.map((item) => ({
        ...item,
        percentage: (item.amount / totalSales * 100).toFixed(2),
      }));

      // sort updatedItems by percentage
      updatedItems.sort((a, b) => {
        return Number(b.percentage) - Number(a.percentage);
      });

      return {
        category,
        categoryTotal: total.toFixed(2),
        items: updatedItems,
      };
    });



    return result;
  }



  const groupedData = calculatStatistics(reportData);


  function renderBarChart(data) {


    const itemPercentageData = data.flatMap((category) => {

      return category.items.map((item) => {

        return {
          name: item.type,
          value: Number(Math.abs(item.amount)).toFixed(2),
          percentage: Math.abs(Number(item.percentage)),
        };
      });


    });

    // sort by value
    itemPercentageData.sort((a, b) => {
      return Number(b.value) - Number(a.value);
    });

    return (




      <ResponsiveContainer width="100%" height={400}>
        <BarChart layout="vertical" data={itemPercentageData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" width={150} />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" label={{ position: "right" }}>
            {itemPercentageData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }



  const csvData = () => {
    let data = [];
    data.push(["Type", "Total"]);

    reportData.forEach((group) => {
      group.items.forEach((item) => {
        if (item && item.amount !== null && item.amount !== undefined) {
          data.push([
            item.type,
            item.amount.toFixed(2),
          ]);
        }
      });
    });
    //console.log('data', data);
    return data;
  };

  const [sortConfig, setSortConfig] = useState({
    key: "total",
    direction: "desc",
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    const sortableItems = groupedData.map((group) => {
      return {
        ...group,
        items: [...group.items].sort((a, b) => {
          if (sortConfig.direction === "asc") {
            return a[sortConfig.key] - b[sortConfig.key];
          } else {
            return b[sortConfig.key] - a[sortConfig.key];
          }
        }),
      };
    });

    return sortableItems;
  }, [groupedData, sortConfig]);

  return (
    <div className={classes.root}>
      <h3
        style={{
          fontSize: "1.5rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        Payment Types Breakdown
      </h3>
      <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        {renderBarChart(groupedData)}
      </div>
      <h3
        style={{
          fontSize: "1.5rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        Payment Types Data
      </h3>

      <div style={{ paddingBottom: "1rem" }}>
        {reportData && reportData.length > 0 ? (
          <CSVLink filename={"payment-types.csv"} data={csvData()}>
            <Button variant="outlined" color="primary">
              Download CSV
            </Button>
          </CSVLink>
        ) : null}
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Payment Type</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === "amount"}
                  direction={sortConfig.direction}
                  onClick={() => handleSort("amount")}
                >
                  Total{" "}
                </TableSortLabel>
              </TableCell>

              <TableCell>
                {" "}
                <TableSortLabel
                  active={sortConfig.key === "percentage"}
                  direction={sortConfig.direction}
                  onClick={() => handleSort("percentage")}
                >
                  Percentage of Sales{" "}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((group, index) => (
              <React.Fragment key={index}>
                <TableRow className={classes.categoryRow}>
                  <TableCell style={{ fontWeight: "bold" }} colSpan={6}>
                    {group.category} (Total: {currencySymbol}{group.categoryTotal})
                  </TableCell>
                </TableRow>
                {group.items.map((item, itemIndex) => (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Typography variant="body1">{item.type}</Typography>

                    </TableCell>
                    <TableCell>{currencySymbol}{item.amount.toFixed(2)}</TableCell>

                    {/* <TableCell>{item.productId || '-'}</TableCell> */}
                    <TableCell>{item.percentage}%</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


    </div>
  );
};

export default PaymentTypes;
