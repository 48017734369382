const PrintType = {
    OrderList: 'OrderList',
    KitchenDocket: 'KitchenDocket',
    DishCategory: 'DishCategory',
    Bill: 'Bill',
    Invoice: 'Invoice',
  };


  // create enum for orderTypes
const OrderType = {
    DineIn: 'DineIn',
    TakeAway: 'TakeAway',
    Delivery: 'Delivery',
    DeliveryPartner: 'DeliveryPartner',
    Foodcourt: 'Foodcourt',
  };

  const L10n = {
    settingsDisplayModeName: "Print name",
    settingsDisplayModeAltname: "Print alternative name",
    settingsDisplayModeShortName: "Print short name",
    settingsDisplayModeBoth: "Print both name and alternative name"
};

const OBNameDisplayMode = {
    showName: "Show name",
    showAlternativeName: "Show alternative name",
    showShortName: "Show short name",
    showBoth: "Show both",
    
    displayName(mode) {
        // console.log(mode, 'mode');
        switch (mode) {
            case this.showName:
                return L10n.settingsDisplayModeName;
            case this.showAlternativeName:
                return L10n.settingsDisplayModeAltname;
            case this.showShortName:
                return L10n.settingsDisplayModeShortName;
            case this.showBoth:
                return L10n.settingsDisplayModeBoth;
            default:
                throw new Error("Invalid mode");
        }
    }
};



export { OrderType, PrintType, OBNameDisplayMode };

