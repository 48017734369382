import { makeStyles } from '@material-ui/core/styles';
import tinycolor from 'tinycolor2';
import Chip from '@material-ui/core/Chip';
import { red, green, blue, yellow, pink, cyan, grey } from '@material-ui/core/colors';

function getColor(weekday) {
    const colors = {
      'Monday': red[500], // Red
      'Tuesday': green[500], // Green
      'Wednesday': blue[500], // Blue
      'Thursday': yellow[500], // Yellow
      'Friday': pink[500], // Magenta
      'Saturday': cyan[500], // Cyan
      'Sunday': grey[500], // White
    };
  
    return colors[weekday] || grey[500]; // Default color (gray) if day is not recognized
  }
  

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => getColor(props.weekday),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.getContrastText(grey[50]),
  },
}));


function WeekdayChip({ weekday }) {
    const classes = useStyles({ weekday });
  
    return <Chip label={weekday} className={classes.root} />
  }

function SurchargeWeekday(props) {
//   const classes = useStyles();

  return (
    <div>
       {props.weekdays.map((weekday, index) => <WeekdayChip key={index} weekday={weekday} />)}
    </div>
  );
}

export default SurchargeWeekday;