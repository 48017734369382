import React, { useState, useEffect } from "react";

import { makeStyles, styled } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import Grid from "@material-ui/core/Grid";

import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import MoreIcon from "@material-ui/icons/More";
import { useConfirm } from "material-ui-confirm";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import yellow from "@material-ui/core/colors/yellow";
import teal from "@material-ui/core/colors/teal";
import lime from "@material-ui/core/colors/lime";
import deepPurple from "@material-ui/core/colors/deepPurple";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PrintIcon from "@material-ui/icons/Print";
import LabelIcon from "@material-ui/icons/Label";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import TabletIcon from '@material-ui/icons/Tablet';

import { default as MenuItemOptions } from "../MenuItemOptions";

import { default as TargetBox } from "../TargetBox";

import MenuItemImage from "./MenuItemImage";
import { useSelector, useDispatch } from "react-redux";

import { removeItemWithSlice } from "util/utils";
import { minutesToString } from "util/menuUtils";

import { updateMenuItem, deleteMenuPhoto } from "store/actions/restaurantMenu";
import MenuMiscSettings from "../Category/MenuMiscSettings";
import MoveCategoryDialog from "./MoveCategoryDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  details: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  category: {
    width: "90%",
  },
  content: {
    width: "100%",
    background: "red",
  },
  cover: {
    width: 50,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 50,
    width: 50,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  paper: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(2),
    border: "1px dashed green",
    marginBottom: ".5rem",
  },
  placeholder: {
    backgroundColor: "#f7f7f7",
    border: "1px dotted #f7f7f7",
  },
  sizeClass: {
    height: "50",
    width: "100%",
  },
  optionsClass: {
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginRightBottom: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const GroupChip = styled(Chip)({
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",

  color: "white",
});

const MenuItemDisplay = (props) => {
  const { menuItem, index, categoryId, menuId } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const [groupList, setGroupList] = useState([]);
  const [sizeList, setSizeList] = useState([]);

  const confirm = useConfirm();
  const [imageUpload, setImageUpload] = useState(false);
  const [openMenOptions, setOpenMenOptions] = useState(false);
  const [openMenuSettings, setOpenMenuSettings] = useState(false);
  const [openMove, setOpenMove] = useState(false);

  const updateTheMenuItem = (item, values) =>
    dispatch(updateMenuItem(item, values));
  const menuImages = useSelector((state) => state.restaurantMenu.menuImages);
  const categories = useSelector(
    (state) => state.restaurantMenu.menuCategories
  );
  const allGroups = useSelector((state) => state.restaurantMenu.menuGroups);

  const deleteMenuImage = (values) => dispatch(deleteMenuPhoto(values));

  const getGroupName = (groupId) => {
    //console.log('allGroups', allGroups);
    const groupIdx = allGroups.findIndex((g) => g.id === groupId);
    if (groupIdx !== -1) {
      return allGroups[groupIdx].name;
    } else {
      return "No name";
    }
  };

  useEffect(() => {
    if (menuItem.groups) {
      setGroupList(menuItem.groups);
    }

    if (menuItem.sizes) {
      setSizeList(menuItem.sizes);
    }
  }, [menuItem.groups]);

  useEffect(() => {
    if (menuItem.sizes) {
      setSizeList(menuItem.sizes);
    }
  }, [menuItem.sizes]);

  const handleDelete = (chipToDelete) => () => {
    console.log("handle group Delete");

    const newGroups = menuItem.groups.filter(
      (group) => group.id !== chipToDelete.id
    );
    updateTheMenuItem({ ...menuItem, groups: newGroups, menuId }, categories);
  };

  const moveGroup = (dragIndex, hoverIndex) => {
    console.log("groups", menuItem.groups, dragIndex, hoverIndex);
    let tempGroups = [...menuItem.groups];
    [tempGroups[dragIndex], tempGroups[hoverIndex]] = [
      tempGroups[hoverIndex],
      tempGroups[dragIndex],
    ];
    const newGroups = tempGroups.map((group, sortIndex) => {
      return { ...group, sort: sortIndex };
    });
    props.moveGroup(index, newGroups);
  };

  const deleteImage = () => {
    //console.log('deleteImage', menuId, categoryId, menuItem.id);
    deleteMenuImage({ menuId, categoryId, menuItemId: menuItem.id });
  };

  const openImageUpload = () => {
    setImageUpload(true);
  };

  const openMenuOptions = () => {
    setOpenMenOptions(true);
  };

  const closeImageUpload = () => {
    setImageUpload(false);
  };

  const closeMenuOptions = () => {
    setOpenMenOptions(false);
  };

  const openMenuSettingsDialog = () => {
    setOpenMenuSettings(true);
  };

  const closeMenuSettings = () => {
    setOpenMenuSettings(false);
  };

  const handleItemDelete = () => {
    confirm({ description: "You are about to delete this item!" })
      .then(() => {
        props.deleteItem(menuItem.id);
      })
      .catch(() => {
        /* ... */
      });
  };

  const openMenuItemMove = () => {
    setOpenMove(true);
    
  }
  const handleMoveClose = () => {
    setOpenMove(false);
  };

  const itemImage = () => {
    // console.log('menuItem', menuItem, menuImages);
    const index = menuImages.findIndex(
      (image) => image.menuItemId === menuItem.id
    );
    if (index !== -1) {
      return menuImages[index].imageUrl;
    }

    if (menuItem.image) {
      return menuItem.image;
    }
    return null;
  };

  const showWeekDays = (activeDays) => {
    let weekdays = "";
    if (activeDays && activeDays.length > 0) {
      weekdays = `on ${activeDays.join(",")}`;
    }
    return weekdays;
  };

  const hideWeekDays = (hideDays) => {
    let weekdays = "";
    if (hideDays && hideDays.length > 0) {
      weekdays = `on ${hideDays.join(",")}`;
    }
    return weekdays;
  };

  const handleSizeDelete = (e, chipToDelete) => {
    console.log("handleSizeDelete");
    const updatedItemIndex = menuItem.sizes.findIndex(
      (item) => item.id === chipToDelete.id
    );
    if (updatedItemIndex !== -1) {
      const newSizeItems = removeItemWithSlice(
        menuItem.sizes,
        updatedItemIndex
      );
      const newItem = { ...menuItem, sizes: newSizeItems };
      updateTheMenuItem(newItem, categories);
    }
  };

  const moveSize = () => {};

  const handleItemEdit = () => {
    props.editItem(true);
  };

  const handleSizeGroupDrop = (index, item) => {
    if (!item || !item.item) {
      return;
    }
    //console.log('category handledrop', index, item, category.items);
    let size = menuItem.sizes[index];
    //console.log('menuItem', menuItem);
    if (size.groups) {
      let newGroups = [...size.groups];
      if (newGroups.filter((group) => group.id === item.item.id).length > 0) {
        return;
      }
      newGroups.push({ id: item.item.id, sort: 999 });
      size.groups = newGroups;
    } else {
      size.groups = [{ id: item.item.id, sort: 999 }];
    }
    let newSizes = [...menuItem.sizes];
    newSizes[index] = size;
    updateTheMenuItem({ ...menuItem, sizes: newSizes }, categories);
  };

  const handleMenuItemGroupDrop = (index, item) => {
    if (!item || !item.item) {
      return;
    }
    let newItem = { ...menuItem };

    //console.log('menuItem', menuItem);
    let newGroups;
    if (newItem.groups) {
      newGroups = [...menuItem.groups];
      if (newGroups.filter((group) => group.id === item.item.id).length > 0) {
        return;
      }
      newGroups.push({ id: item.item.id, sort: 999 });
    } else {
      newGroups = [{ id: item.item.id, sort: 999 }];
    }
    updateTheMenuItem({ ...menuItem, groups: newGroups }, categories);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          directions="column"
          alignContent="space-between"
          spacing={2}
        >
          <Grid
            item
            container
            direction="row"
            xs={12}
            alignContent="space-between"
            spacing={1}
          >
            <Grid item>
              <Grid
                className={classes.placeholder}
                item
                container
                direction="column"
              >
                {itemImage() ? (
                  <>
                    <img
                      src={itemImage()}
                      alt="image"
                      height={100}
                      width={100}
                    />
                    <Grid item container direction="row">
                      <IconButton
                        aria-label="more"
                        className={classes.margin}
                        edge="end"
                        color="secondary"
                        onClick={deleteImage}
                      >
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>

                      <IconButton
                        aria-label="more"
                        className={classes.margin}
                        edge="end"
                        color="secondary"
                        onClick={openImageUpload}
                      >
                        <CloudUploadIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </>
                ) : (
                  <IconButton
                    aria-label="more"
                    className={classes.margin}
                    edge="end"
                    color="secondary"
                    onClick={openImageUpload}
                  >
                    <CloudUploadIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>
              <MenuItemImage
                open={imageUpload}
                handImageClose={closeImageUpload}
                menuId={menuId}
                categoryId={categoryId}
                menuItemId={menuItem.id}
              />
            </Grid>

            <Grid item xs={6} spacing={2} justifyContent="space-between">
              <Grid className={classes.margin}>
                <Typography component="h5" variant="h5">
                {menuItem.colorHex ?
                    <span className="selected-color" style={{ backgroundColor: menuItem.colorHex }}>
                  {menuItem.name} - ${menuItem.price}
                  </span>  :   `${menuItem.name} - ${menuItem.price}`}
                  {menuItem.gstFree === true ? "(GST Free)" : ""}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {menuItem.altName}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {menuItem.description}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                spacing={1}
                justifyContent="space-between"
                className={classes.margin}
              >
                {menuItem.tags && menuItem.tags.length > 0
                  ? menuItem.tags.map((tag) => {
                      return (
                        <Chip
                          size="small"
                          icon={<LabelIcon />}
                          key={tag}
                          label={tag}
                          style={{ backgroundColor: teal[300] }}
                          className={classes.marginRightBottom}
                        />
                      );
                    })
                  : null}
                {!menuItem.active ? (
                  <Chip
                    size="small"
                    label={`Hidden from menu ${hideWeekDays(
                      menuItem.hideDays
                    )}`}
                    style={{ backgroundColor: yellow[700] }}
                    className={classes.marginRightBottom}
                  />
                ) : null}
                {menuItem.hidden_until ? (
                  <Chip
                    size="small"
                    label={`Hidden Util ${new Date(
                      menuItem.hidden_until
                    ).toLocaleString()}`}
                    style={{ backgroundColor: yellow[700] }}
                    className={classes.marginRightBottom}
                  />
                ) : null}

                {menuItem.active_begin && menuItem.active_end ? (
                  <Chip
                    size="small"
                    label={`Show only from ${minutesToString(
                      menuItem.active_begin
                    )} - ${minutesToString(menuItem.active_end)} ${showWeekDays(
                      menuItem.activeWeekDays
                    )}`}
                    style={{ backgroundColor: yellow[700] }}
                    className={classes.marginRightBottom}
                  />
                ) : null}
                {menuItem.cookingCategory ? (
                  <Chip
                    size="small"
                    icon={<TabletIcon />}
                    label={menuItem.cookingCategory}
                    style={{ backgroundColor: lime[500] }}
                    className={classes.marginRightBottom}
                  />
                ) : null}

                {menuItem.printCategory
                  ? menuItem.printCategory.split(";").map((category) => {
                      return (
                        <Chip
                          size="small"
                          icon={<PrintIcon />}
                          key={category}
                          label={category}
                          style={{ backgroundColor: deepPurple[200] }}
                          className={classes.marginRightBottom}
                        />
                      );
                    })
                  : null}

                {menuItem.isTakeawayDisabled ? (
                  <Chip
                    size="small"
                    icon={<BlockIcon />}
                    key={"isTakeawayDisabled"}
                    label={"Takeaway"}
                    style={{ backgroundColor: grey[200] }}
                    className={classes.marginRightBottom}
                  />
                ) : null}

                {menuItem.isForPrintingDisabled ? (
                  <Chip
                    size="small"
                    icon={<BlockIcon />}
                    key={"isForPrintingDisabled"}
                    label={"Print"}
                    style={{ backgroundColor: grey[200] }}
                    className={classes.marginRightBottom}
                  />
                ) : null}

                {menuItem.isForKDSDisabled ? (
                  <Chip
                    size="small"
                    icon={<BlockIcon />}
                    key={"isForKDSDisabled"}
                    label={"KDS"}
                    style={{ backgroundColor: grey[200] }}
                    className={classes.marginRightBottom}
                  />
                ) : null}

                {menuItem.isMainCourse === true ? (
                  <Chip
                    size="small"
                    icon={<RestaurantIcon />}
                    key={"isForKDSDisabled"}
                    label={"Main"}
                    style={{ backgroundColor: red[200] }}
                    className={classes.marginRightBottom}
                  />
                ) : null}
                {menuItem.promotionTags && menuItem.promotionTags.length > 0 ? (
                  
                  menuItem.promotionTags.map((tag) => {
                    return (
                      <Chip
                        size="small"
                        key={tag}
                        label={tag}
                        style={{ backgroundColor: teal[300] }}
                        className={classes.marginRightBottom}
                      />
                    );
                  }
                  )
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <MenuItemOptions
                open={openMenOptions}
                handClose={closeMenuOptions}
                menuId={menuId}
                categoryId={categoryId}
                menuItem={menuItem}
              />
              <MenuMiscSettings
                open={openMenuSettings}
                handClose={closeMenuSettings}
                menuItem={menuItem}
                menuId={menuId}
                categoryId={categoryId}
              />

              <IconButton
                aria-label="more"
                className={classes.margin}
                color="secondary"
                onClick={openMenuSettingsDialog}
              >
                <SettingsOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="more"
                className={classes.margin}
                edge="end"
                color="secondary"
                onClick={openMenuOptions}
              >
                <MoreIcon fontSize="small" />
              </IconButton>

              <IconButton
                aria-label="eidt"
                className={classes.margin}
                edge="end"
                color="secondary"
                onClick={handleItemEdit}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="delete"
                className={classes.margin}
                edge="end"
                color="secondary"
                onClick={handleItemDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>

              <IconButton
                aria-label="move"
                className={classes.margin}
                edge="end"
                color="secondary"
                onClick={openMenuItemMove}
              >
                <MoveToInboxIcon fontSize="small" />
              </IconButton>

              <MoveCategoryDialog
              open={openMove}
              closeHandler={handleMoveClose}
              selectedMenuItem={menuItem}
             
            />
              
            </Grid>
          </Grid>
        </Grid>
        {sizeList.length > 0 ? (
          <Grid item container direction="column">
            {/* <Paper variant="outlined" className={classes.paper}> */}

            <Typography>Sizes</Typography>
            {sizeList.map((data, index) => {
              let icon;
              return (
                data && (
                  <TargetBox
                    accept={["size", "group"]}
                    dragType={"size"}
                    key={data.id}
                    index={index}
                    id={data.id}
                    onDrop={(item) => handleSizeGroupDrop(index, item)}
                    moveCard={moveSize}
                  >
                    <Box key={data.id} className={classes.sizeClass}>
                      {/* // icon={icon}
                                    // label={`${data.name} $${data.price}`}
                                    // onDelete={handleSizeDelete(data)}
                                    // className={classes.chip} */}

                      <Typography>{`${data.name} $${data.price}`}</Typography>
                      {data.groups &&
                        data.groups.map((group) => {
                          return (
                            <Chip
                              key={group.id}
                              label={getGroupName(group.id)}
                            />
                          );
                        })}
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        edge="end"
                        color="secondary"
                        onClick={(e) => handleSizeDelete(e, data)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </TargetBox>
                )
              );
            })}
            {/* </Paper> */}
          </Grid>
        ) : null}
        <Typography>Options</Typography>
        <TargetBox
          accept={["group"]}
          dragType={"item"}
          index={index}
          id={menuItem.id}
          onDrop={(item) => handleMenuItemGroupDrop(index, item)}
          className={classes.optionsClass}
        >
          <Grid>
            <Paper component="ul" square className={classes.paper}>
              {groupList.map((data, index) => {
                let icon;
                // console.log('data', data);
                return (
                  data && (
                    <TargetBox
                      accept={["menuGroup"]}
                      dragType={"menuGroup"}
                      key={data.id}
                      index={index}
                      id={data.id}
                      moveCard={moveGroup}
                    >
                      <li key={data.id}>
                        <GroupChip
                          icon={icon}
                          size="small"
                          label={getGroupName(data.id)}
                          onDelete={handleDelete(data)}
                          className={classes.chip}
                        />
                      </li>
                    </TargetBox>
                  )
                );
              })}
            </Paper>
          </Grid>

        </TargetBox>
      </CardContent>
    </Card>
  );
};

export default MenuItemDisplay;
