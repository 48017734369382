import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

//import { getInitials } from 'helpers';
import { NavLink } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textLink: {
		textDecoration: 'underline',
	},
}));

const CustomersTable = props => {
  const { className, customers, ...rest } = props;

  const classes = useStyles();

  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { customers } = props;

    let selectedRestaurants;

    if (event.target.checked) {
      selectedRestaurants = customers.map(customer => customer.id);
    } else {
      selectedRestaurants = [];
    }

    setSelectedRestaurants(selectedRestaurants);
  };

  const handleSelectOne = (event, urlIdentifier) => {
    const selectedIndex = selectedRestaurants.indexOf(urlIdentifier);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedRestaurants, urlIdentifier);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedRestaurants.slice(1));
    } else if (selectedIndex === selectedRestaurants.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedRestaurants.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedRestaurants.slice(0, selectedIndex),
        selectedRestaurants.slice(selectedIndex + 1)
      );
    }

    setSelectedRestaurants(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };




  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
     
        <PerfectScrollbar>
          <div className={classes.inner}>
          
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRestaurants.length === customers.length}
                      color="primary"
                      indeterminate={
                        selectedRestaurants.length > 0 &&
                        selectedRestaurants.length < customers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created At</TableCell>
      
                </TableRow>
              </TableHead>
              <TableBody>
                {customers && customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(customer => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={customer.id}
                    selected={selectedRestaurants.indexOf(customer.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRestaurants.indexOf(customer.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, customer.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                   
                          <Typography variant="body1">{customer.name}</Typography>
                           
                    </TableCell>
                    <TableCell>{customer.phoneNumber}</TableCell>
                    <TableCell>
                      {customer.email}
                    </TableCell>
                    <TableCell>{moment(new Date(customer.createdAt._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')}</TableCell>                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

CustomersTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default CustomersTable;
