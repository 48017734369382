import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { showErrorSnackbar } from "store/actions/snackbarMessage";

// import TextField from "@material-ui/core/TextField";

import Switch from "@material-ui/core/Switch";
import { useSelector, useDispatch } from "react-redux";
import { updateRestaurantValues } from "store/actions/restaurant";
import CheckMyRestaurant from "../../CheckMyRestaurant";
import API from "util/API";
import POSPrintTypeSettings from "./POSPrintTypeSettings";
import { OrderType, PrintType, OBNameDisplayMode } from "./printTypes";

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(4) },
  progess: {
    position: "absolute",
  },
  bottomButtons: {
    marginTop: theme.spacing(1),
  },
}));




const initialSettings = [
  {
    printType: PrintType.OrderList,
    orderTypes: [
      { name: OrderType.DineIn, enabled: true, printer: null, printCopies: 1  },
      { name: OrderType.TakeAway, enabled: true, printer: null, printCopies: 1 },
      { name: OrderType.Delivery, enabled: true, printer: null, printCopies: 1 },
      { name: OrderType.DeliveryPartner, enabled: true, printer: null, printCopies: 1 },
      { name: OrderType.Foodcourt, enabled: true, printer: null, printCopies: 1 },
    ],
    nameDisplayMode: OBNameDisplayMode.showBoth,
  },
  {
    printType: PrintType.KitchenDocket,
    orderTypes: [
      { name: OrderType.DineIn, enabled: true, printer:null, printCopies: 1 },
      { name: OrderType.TakeAway, enabled: true, printer: null , printCopies: 1},
      { name: OrderType.Delivery, enabled: true, printer: null , printCopies: 1},
      { name: OrderType.DeliveryPartner, enabled: true, printer: null , printCopies: 1},
      { name: OrderType.Foodcourt, enabled: true, printer: null, printCopies: 1},
    ],
    nameDisplayMode: OBNameDisplayMode.showBoth,
  },
  {
    printType: PrintType.DishCategory,
    orderTypes: [
      { name: OrderType.DineIn, enabled: true},
      { name: OrderType.TakeAway, enabled: true },
      { name: OrderType.Delivery, enabled: true },
      { name: OrderType.DeliveryPartner, enabled: true },
      { name: OrderType.Foodcourt, enabled: true },
    ],
    nameDisplayMode: OBNameDisplayMode.showShortName,
  },
  {
    printType: PrintType.Bill,
    orderTypes: [],
    nameDisplayMode: OBNameDisplayMode.showBoth,
    printer: null, printCopies: 1,
  },
  {
    printType: PrintType.Invoice,
    orderTypes: [],
    nameDisplayMode: OBNameDisplayMode.showBoth,
    printer: null, printCopies: 1,
  },
];



const POSPrintSettings = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoiceLogoUrl, setInvoiceLogoUrl] = useState(null);

  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  // const CHARACTER_LIMIT = 100;
  // const CHARACTER_LIMIT_BIG = 200;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disableChangeTablePrint, setDisableChangeTablePrint] = useState(false);
  const [excludeTaxInvoiceZeroItem, setExcludeTaxInvoiceZeroItem] = useState(false);
  const [printTypeSettings, setPrintTypeSettings] = useState(initialSettings);


  const validatePrinTypeSettings = (settings) => {
    for (const printTypeSetting of settings) {
      // Skip validation for DishCategory
      if (printTypeSetting.printType === PrintType.DishCategory) {
        continue;
      }
  
      for (const orderTypeSetting of printTypeSetting.orderTypes) {
        // If the order type is enabled but no printer is selected
        if (orderTypeSetting.enabled && !orderTypeSetting.printer) {
          return {
            valid: false,
            message: `Please select a printer for ${printTypeSetting.printType} of ${orderTypeSetting.name} order type.`,
          };
        }
      }
    }
    
    return { valid: true, message: '' };
  };

  // default invoice template const 
  const invoiceTemplatString = `# {{merchant name}}
{{merchant address=36=}}
{{merchant phone}} {{merchant email}}
ABN: {{merchant abn}}
## Table: {{table number}}
staff: {{staff name}}
--------------------------------------------------
## TAX INVOICE                #:{{invoice number}}
--------------------------------------------------
\items beign
{{item name=34=}}
{{item altName=34=}} 
{{item quantity}} x {{item price=20=}}{{item total}}
{{item options}}
\items end
--------------------------------------------------
                Subtotal: {{subtotal}}
\adjustments beign   
{{adjustment type=33=}}{{adjustment amount}} 
\adjustments end
                                                     
                    **Total: {{total}}**
            GST inclusive: {{gst}}
                     Cash: {{cash}}
                   Change: {{change}}
--------------------------------------------------
Paid Time: {{paid time}}`;

  const [invoiceTemplate, setInvoiceTemplate] = useState(invoiceTemplatString);

  const updateRestaurant = (values) => dispatch(updateRestaurantValues(values));
  const restaurant = useSelector((state) => state.restaurant.selectedRestaurant);

  useEffect(() => {
    if (
      !restaurant ||
      !restaurant.posPrintSettings ||
      !restaurant.posPrintSettings.types ||
      !restaurant.posPrintSettings.types
    ) {
      return;
    }
    const posPrintSettings = restaurant.posPrintSettings.types;
    // console.log("posPrintSettings", posPrintSettings);
    const changeTablePrintSetting = posPrintSettings.find((c) => c.printType === "swithTable");
    if (changeTablePrintSetting !== null && changeTablePrintSetting !== undefined) {
      setDisableChangeTablePrint(!changeTablePrintSetting.isPrintEnabled);
    }

    const invoicePrintSetting = posPrintSettings.find((c) => c.printType === "invoice");
    if (invoicePrintSetting !== null && invoicePrintSetting !== undefined) {
      setExcludeTaxInvoiceZeroItem(invoicePrintSetting.excludeZeroItem);
      if (invoicePrintSetting.printTemplateString) {
        setInvoiceTemplate(invoicePrintSetting.printTemplateString);
      }
      if (invoicePrintSetting.printImageUrl) {
        setInvoiceLogoUrl(invoicePrintSetting.printImageUrl);
      }
    }

     if(restaurant.posPrintSettings.printTypeSettings !== null && restaurant.posPrintSettings.printTypeSettings !== undefined) {
      // if restaurant.posPrintSettings.printTypeSettings misses bill and invoice, we need to add them
      const printTypeSettings = restaurant.posPrintSettings.printTypeSettings;
      const billPrintTypeSetting = printTypeSettings.find((c) => c.printType === PrintType.Bill);
      if (billPrintTypeSetting === null || billPrintTypeSetting === undefined) {
        printTypeSettings.push({
          printType: PrintType.Bill,
          orderTypes: [],
          nameDisplayMode: OBNameDisplayMode.showBoth,
          printer: null, printCopies: 1,
        });
      }
      const invoicePrintTypeSetting = printTypeSettings.find((c) => c.printType === PrintType.Invoice);
      if (invoicePrintTypeSetting === null || invoicePrintTypeSetting === undefined) {
        printTypeSettings.push({
          printType: PrintType.Invoice,
          orderTypes: [],
          nameDisplayMode: OBNameDisplayMode.showBoth,
          printer: null, printCopies: 1,
        });
      }
      setPrintTypeSettings(restaurant.posPrintSettings.printTypeSettings);
     } 
     
  }, [restaurant]);

  const isReadySubmit = () => {
    return true;
  };

  const updateSettings = (event) => {
    event.preventDefault();

    const validationResult = validatePrinTypeSettings(printTypeSettings);

  
  
    if (!validationResult.valid) {
      console.log(validationResult.message);
      showError(validationResult.message);
      return;
    }

    if (!isReadySubmit()) {
      return;
    }

    setButtonLoading(true);
    const posPrintSettings = [
      { printType: "swithTable", isPrintEnabled: !disableChangeTablePrint },
      { printType: "invoice", excludeZeroItem: excludeTaxInvoiceZeroItem, printImageUrl: invoiceLogoUrl, isPrintEnabled: true }
    ];

    updateRestaurant({ posPrintSettings: { types: posPrintSettings, printTypeSettings } });
    setButtonLoading(false);
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "disableChangeTablePrint") {
      setDisableChangeTablePrint(value);
      return;
    }

    if (name === "excludeTaxInvoiceZeroItem") {
      setExcludeTaxInvoiceZeroItem(value);
      return;
    }
  };

  const handleChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("image", image);
    try {
      const result = await API.post("/restaurant/invoiceUpload", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      setLoading(false);
      console.log("result", result);
      if (result.data && result.data.imageUrl) {
        setInvoiceLogoUrl(result.data.imageUrl);
      }
    } catch (error) {
      setLoading(false);
      showError("Failed to upload image");
      console.error(error);
    }
  };

  return restaurant ? (
    <Card {...rest} className={clsx(classes.root, className)}>

        <CardHeader subheader="printing settings" title="POS printing settings" />
        <Divider />
        <CardContent>
        <POSPrintTypeSettings printTypeSettings={printTypeSettings} setPrintTypeSettings={setPrintTypeSettings} />
        <Divider />

          <Grid container spacing={6} wrap="wrap" row>
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={disableChangeTablePrint}
                    onChange={handleSwitchChange}
                    name="disableChangeTablePrint"
                    color="primary"
                  />
                }
                label="Disable change table print"
              />
            </Grid>

            <Grid className={classes.item} item md={12} sm={12} xs={12}>
              <Typography variant="h5">Tax invoice print settings</Typography>

              <Divider />
            </Grid>

            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              {invoiceLogoUrl ? (
                <img src={invoiceLogoUrl} alt="invoice logo" />
              ) : (
                <Typography variant="h5">No invoice image</Typography>
              )}

              <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleChange} />
                <button type="submit" disabled={loading}>
                  {loading ? "Uploading..." : "Upload"}
                </button>
              </form>
            </Grid>

            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={excludeTaxInvoiceZeroItem}
                    onChange={handleSwitchChange}
                    name="excludeTaxInvoiceZeroItem"
                    color="primary"
                  />
                }
                label="Exclude zero priced items for Tax Invoice"
              />
            </Grid>
{/* 
            <Grid className={classes.item} item md={12} sm={12} xs={12}>
              <TextField
                id="invoiceTemplate"
                label="Invoice template"
                multiline
                rows={23}
                fullWidth
                variant="outlined"
                value={invoiceTemplate}
                placeholder="Invoice template"
                onChange={(e) => setInvoiceTemplate(e.target.value)}
              />
            </Grid> */}
          </Grid>
        </CardContent>
        <Divider />

        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={updateSettings}
            disabled={!isReadySubmit() || buttonLoading}
          >
            Update
            {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
          </Button>
        </CardActions>
   
    </Card>
  ) : (
    <CheckMyRestaurant />
  );
};

POSPrintSettings.propTypes = {
  className: PropTypes.string,
};

export default POSPrintSettings;
