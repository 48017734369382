import React from "react";
import PropTypes from "prop-types";

import { Divider } from "@material-ui/core";

import OnlineCheckoutSettings from "./OnlineCheckoutSettings";
import FirstPageDialogSettings from "./FirstPageDialogSettings";
import PaymentRequiredQRCodeTable from "./PaymentReqiredQRCodes";
import { useSelector } from "react-redux";

import CheckMyRestaurant from "../../CheckMyRestaurant";

const OnlineSettings = (props) => {
  const { className, ...rest } = props;
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  return (
    <div>
      {restaurant ? (
        <>
          <FirstPageDialogSettings />
          <Divider />
          <OnlineCheckoutSettings />
          <Divider />
          <PaymentRequiredQRCodeTable />
        </>
      ) : (
        <CheckMyRestaurant />
      )}
    </div>
  );
};

OnlineSettings.propTypes = {
  className: PropTypes.string,
};

export default OnlineSettings;
