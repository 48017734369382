import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import { Box, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";

import POSPartnerSettings from "./POSPartnerSettings";
import POSDeliveryPartnerSettings from "./POSDeliveryPartnerSettings";
import POSDiscountSettings from "./POSDiscountSettings";
import POSOrderSettings from "./POSOrderSettings";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const POSSettings = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  return restaurant ? (
    <div className={classes.root}>
      <POSDeliveryPartnerSettings />
      <Divider />
      <POSPartnerSettings />
      <Divider />
      <POSDiscountSettings />
      <Divider />
      <POSOrderSettings />

    </div>
  ) : (
    <Box>
    <Typography color="error">
      Please go back to the MyRestaurant screen.
    </Typography>
    </Box>
  );
};

POSSettings.propTypes = {
  className: PropTypes.string,
};

export default POSSettings;
