import API from 'util/API';


export const getInvoice = async (restaurantId, orderId) => {
  if(!restaurantId || !orderId) {
    return null;
  }

  const response = await API.get(`/invoice?restaurantId=${restaurantId}&orderId=${orderId}`);
  if (response && response.data && response.data.invoice) {
    return response.data.invoice;
  }
  return null;
};


export const fetchWechatOrderStatus = async (restaurantId, orderNo) => {
  if(!restaurantId || !orderNo) {
    console.error('missing restaurantId or orderNo');
    return null;
  }

  const response = await API.get(`/wechatPaymentStatus?restaurantId=${restaurantId}&orderNo=${orderNo}`);
  if (response && response.data && response.data.orderStatus) {
    return response.data.orderStatus;
  }
  return null;
};

