import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Creatable from 'react-select/creatable';
import {

    showErrorSnackbar,
  } from "store/actions/snackbarMessage";
  import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {},
    progess: {
      position: "absolute",
    },
    table: {},
    qtyCell: {
      width: 10,
    },
    content: {
      height: 250,
    },
  }));
  

const TimesheetDeleteDialog = (props) => {
  const { open, deleteProxy, close, updateReason, reason } = props;
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isbuttonDisabled, setIsButtonDisabled] = useState(true);
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const reasons = ["Wrong transaction", "Refund", "Testing"];

  const handleClose = () => {
    close()
  };

  const handleDeleteTran = () => {
  
    deleteProxy();
    close()
  };

  const checkButtonDisabled = () => {
      console.log('checkButtonDisabled', reason);
        if (reason.length === 0) {
          setIsButtonDisabled(true);
          return;
        } 
        setIsButtonDisabled(false);
        
  };



  const handleChange = (newValue, actionMeta) => {
      
      if (newValue) {
        updateReason(newValue.value);
      } else {
       updateReason('');
      }
      checkButtonDisabled();
  };

  const handleInputChange = (inputValue, actionMeta) => {
    
      if (inputValue) {
        updateReason(inputValue);
      } else {
       // updateReason('');
      }
      checkButtonDisabled();
  };

  return (
    <div>
      <Dialog
       fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"You are about to delete transactions!"}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>

            <Typography>
                Please select or enter a reason to delete.
                </Typography> 
            <Creatable
              labelId="demo-simple-select-filled-label"
              id="deleteReason"
              isClearable
              onChange={handleChange}
              onInputChange={handleInputChange}
              
              options={reasons.map(reason => ({value:reason, label: reason}))}
            />
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button  color="primary" onClick={handleDeleteTran} disabled={isbuttonDisabled}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TimesheetDeleteDialog;
