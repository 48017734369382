import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/styles";
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";

import { showSuccessSnackbar,  showErrorSnackbar } from 'store/actions/snackbarMessage'
import { useSelector, useDispatch } from 'react-redux';

import API from 'util/API';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1000,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

const AddMembersDialog = (props) => {
  const classes = useStyles();
  const { open, closeHandler, addMembers } = props;
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [members, setMembers] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [uiLoading, setUiLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
  const selectedUserIds = useSelector((state) => state.account.selectedUserIds);
  const accounts = useSelector((state) => state.account.accounts);

  const handleClose = () => {
    closeHandler();
  };

 

  const queryMembers = async (e) => {
    if (phoneNumber.trim().length === 0 && firstName.trim().length === 0 && lastName.trim().length === 0) {
       showErrorMessage('Please enter a phone number or first name or last name')
       return;
    }

    try {
      setButtonLoading(true);
      const response = await API.post('/searchMemeber', { phoneNumber, firstName, lastName });
      console.log('response.data', response.data);
      if (response && response.data &&  response.data.searchResult) {
        setOptions(response.data.searchResult);
      }
      setButtonLoading(false);
      
    } catch (err) {
      setButtonLoading(false);
      console.error(err);
      showErrorMessage('Something went wrong, please try again.')
      
    }

  }

  const updateRestaurantGroup = async () => {
    const payload = {
      restaurants: members,
      username: currentAccount.username
    };
    try {
      const result = await API.put('/updateRestaurantGroup', payload);
      setUiLoading(false);
      handleClose();
    } catch (err) {
      setUiLoading(false);
      console.error(err);
      showErrorMessage('Something went wrong, please try again.')
      handleClose();
    }
  };

  const addMembersHandler = () => {
   
    if (members.length === 0) {
      showErrorMessage('Please search and select a member')
      return;
    }
    addMembers(members);
    handleClose();


  };

  useEffect(() => {
  
  }, [props])

  useEffect(() => {
    // console.log('selectedUserIds', selectedUserIds);
    // if (selectedUserIds.length !== 1) {
    //   console.error('no ids');
    //   return;
    // }
    // const selectedUsers = accounts.filter(user => user.username === selectedUserIds[0]);
    // console.log('selectedUsers', selectedUsers);
    // if (selectedUsers.length !== 1) {
    //   console.error('no users');
    //   return;
    // }

    // setCurrentAccount(selectedUsers[0]);
    // if (selectedUsers[0].restaurants) {
    //   setMembers(selectedUsers[0].restaurants);
    // }

  }, [selectedUserIds, accounts]);

  const deleteMember = (deleteMember) => {
    setMembers(members.filter(member => member.id !== deleteMember.id));
  };

  return (
    <div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add memebers</DialogTitle>
        <DialogContent>

          <div className={classes.demo}>
            <Grid containter spacing={4}>
              <Grid item>
                <TextField

                  label="Mobile Phone"
                  margin="dense"
                  name="mobilePhone"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField

                  label="First Name"
                  margin="dense"
                  name="firstName"
                  variant="outlined"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField

                  label="Last Name"
                  margin="dense"
                  name="lastName"
                  variant="outlined"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={buttonLoading}
                  onClick={queryMembers}
                >
                  search
                  {buttonLoading && (
                    <CircularProgress size={30} className={classes.progess} />
                  )}
                </Button>
              </Grid>
            </Grid>
            <List dense={false}>
              {members.map(member => (
                <ListItem key={member.id}>

                  <ListItemText primary={`${member.name} - ${member.phoneNumber !== undefined ? member.phoneNumber : ''}`} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={e => deleteMember(member)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
              )}
            </List>
          </div>

          <Autocomplete
            id="memeberId"
            options={options}
            getOptionLabel={(option) => `${option.name} - ${option.phoneNumber !== undefined ? option.phoneNumber : ''}`}
            style={{ minWidth: 400 }}

            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              if (newValue) {
                setMembers([...members, newValue]);
              }
            }}

            renderInput={(params) => <TextField {...params} label="Members" variant="outlined" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={addMembersHandler}
            disabled={uiLoading || buttonLoading}
            color="primary">
            Add
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddMembersDialog;
