import API from 'util/API';
import { showSuccessSnackbar, showErrorSnackbar } from 'store/actions/snackbarMessage'
import { v4 as uuid } from 'uuid';
import { updateItemsWithIndexAndItem } from 'util/utils'

export const TOGGLE_NEW_CATEGORY = 'TOGGLE_NEW_CATEGORY';
export const GET_MY_MENUS = 'GET_MY_MENUS';
export const GET_MY_MENU_SUCCESS = 'GET_MY_MENU';
export const GET_MY_MENUS_SUCCESS = 'GET_MY_MENUS_SUCCESS';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILURE = 'API_FAILURE';
export const API_START = 'API_START';
export const CLEAR_API_STATUS = 'CLEAR_API_STATUS';

export const SAVE_MENU_CATEGORY_SUCCESS = 'SAVE_MENU_CATEGORY_SUCCESS';
export const SAVE_MENU_GROUP_SUCCESS = 'SAVE_MENU_GROUP_SUCCESS';
export const GET_MENU_CATEGORIES_SUCCESS = 'GET_MENU_CATEGORIES_SUCCESS';
export const GET_MENU_GROUPS_SUCCESS = 'GET_MENU_GROUPS_SUCCESS';
export const UPDATE_MENU_CATEGORIES_SUCCESS = 'UPDATE_MENU_CATEGORIES_SUCCESS';
export const TOGGLE_NEW_GROUP = 'TOGGLE_NEW_GROUP';
export const SAVE_MENU_GROUP_OPTION_SUCCESS = "SAVE_MENU_GROUP_OPTION_SUCCESS";
export const SAVE_MENU_GROUP_OPTIONS_SUCCESS = "SAVE_MENU_GROUP_OPTIONS_SUCCESS";
export const SAVE_MENU_ITEM_SUCCESS = "SAVE_MENU_ITEM_SUCCESS";
export const SAVE_MENU_ITEMS_SUCCESS = "SAVE_MENU_ITEMS_SUCCESS";
export const DELETE_MENU_CATEGORY_SUCCESS = "DELETE_MENU_CATEGORY_SUCCESS";
export const UPLOAD_MENU_IMAGE_SUCCESS = "UPLOAD_MENU_IMAGE_SUCCESS";
export const UPDATE_MENU_CATEGORY_SUCCESS = "UPDATE_MENU_CATEGORY_SUCCESS";
export const UPDATE_MENU_ITEM = "UPDATE_MENU_ITEM";
export const UPDATE_MENU = "UPDATE_MENU";
export const COPY_MENU_SUCCCESS = "COPY_MENU_SUCCCESS";
export const DELETE_MENU_SUCCCESS = "DELETE_MENU_SUCCCESS";


export const toggleNewCategory = () => ({
    type: TOGGLE_NEW_CATEGORY
});

export const toggleNewGroup = () => ({
    type: TOGGLE_NEW_GROUP
});


export const getMyMenus = () => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.get('/myMenus').then(
            response => dispatch({ type: GET_MY_MENUS_SUCCESS, payload: response.data }),
            err => dispatch({ type: API_FAILURE, err })
        );
    };
};




export const getMyMenu = (menuId) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.get('/myMenu/' + menuId).then(
            response => dispatch({ type: GET_MY_MENU_SUCCESS, payload: response.data }),
            err => dispatch({ type: API_FAILURE, err })
        );
    };
};


export const saveMenu = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.post('/myMenu', values).then(
            response => {
                dispatch(showSuccessSnackbar('Saved successfully!'))
                dispatch({ type: API_SUCCESS });
            },
            err => dispatch({ type: API_FAILURE, err })
        );
    };
};

export const copyMenu = (menuId, toRestaurantId, isCopyToTest) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.post('/copyMenu/' + menuId, {restaurantId: toRestaurantId, isCopyToTest}).then(
            response => {
                dispatch(showSuccessSnackbar('Copied successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: COPY_MENU_SUCCCESS, payload: {newMenu: response.data, toRestaurantId}});
            },
            err => {
                dispatch(showErrorSnackbar('Error in copying the menu'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};

export const deleteMenu = (menuId) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.delete('/myMenu/' + menuId).then(
            response => {
                dispatch(showSuccessSnackbar('Deleted successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: DELETE_MENU_SUCCCESS, payload: menuId })
            },
            err => {
                dispatch(showErrorSnackbar('Error in deleting the menu'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};



export const updateMenu = (values, menuId) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.put('/myMenu/' + menuId, values).then(
            response => {
                dispatch(showSuccessSnackbar('Updated successfully!'));
                dispatch({ type: API_SUCCESS });
                dispatch({ type: UPDATE_MENU, payload: { ...values, id: menuId } });

            }
            , err => {
                dispatch(showErrorSnackbar('Error in updating the data'));
                dispatch({ type: API_FAILURE, err });
            }
        );
    };
};



export const clearAPIStatus = () => ({
    type: CLEAR_API_STATUS
});


// Menu Categories

export const saveMenuCategory = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.post('/menuCategory', values).then(
            response => {
                dispatch(showSuccessSnackbar('Saved successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: SAVE_MENU_CATEGORY_SUCCESS, payload: { ...values, id: response.data.id } });
            },
            err => {
                dispatch(showErrorSnackbar('Error in saving the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};

export const updateMenuCategory = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.put('/menuCategory', values).then(
            response => {
                dispatch(showSuccessSnackbar('Saved successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: UPDATE_MENU_CATEGORY_SUCCESS, payload: { ...values } });
            },
            err => {
                dispatch(showErrorSnackbar('Error in saving the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};



export const saveMenuGroup = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.post('/menuGroup', values).then(
            response => {
                dispatch(showSuccessSnackbar('Saved successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: SAVE_MENU_GROUP_SUCCESS, payload: { ...values, id: response.data.id } });
            },
            err => {
                dispatch(showErrorSnackbar('Error in saving the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};


export const UPDATE_MENU_GROUP_SUCCESS = "UPDATE_MENU_GROUP_SUCCESS";
export const updateMenuGroup = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.put('/menuGroup', values).then(
            response => {
                dispatch(showSuccessSnackbar('Updated successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: UPDATE_MENU_GROUP_SUCCESS, payload: values });
            },
            err => {
                dispatch(showErrorSnackbar('Error in saving the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};


export const DELETE_MENU_GROUP_SUCCCESS = "DELETE_MENU_GROUP_SUCCCESS";
export const deleteMenuGroup = (menuId, groupId) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.delete(`/menuGroup/${menuId}/${groupId}`).then(
            response => {
                dispatch(showSuccessSnackbar('Delete successfully!'))
                //  dispatch({ type: API_SUCCESS});
                dispatch({ type: DELETE_MENU_GROUP_SUCCCESS, payload: groupId });

            },
            err => {
                dispatch(showErrorSnackbar('Error in deleting the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};

export const saveMenuGroupOption = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.post('/menuGroupOption', values).then(
            response => {
                dispatch(showSuccessSnackbar('Saved successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: SAVE_MENU_GROUP_OPTION_SUCCESS, payload: response.data });
            },
            err => {
                dispatch(showErrorSnackbar('Error in saving the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};

export const saveMenuGroupOptions = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.post('/menuGroupOptions', values).then(
            response => {
                dispatch(showSuccessSnackbar('Saved successfully!'))
                dispatch({ type: API_SUCCESS });
            },
            err => {
                dispatch(showErrorSnackbar('Error in saving the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};

export const getMenuCategories = (menuId) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.get('/menuCategories/' + menuId).then(
            response => dispatch({ type: GET_MENU_CATEGORIES_SUCCESS, payload: response.data }),
            err => dispatch({ type: API_FAILURE, err })
        );
    };
};

export const getMenuGroups = (menuId) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.get('/menuGroups/' + menuId).then(
            response => dispatch({ type: GET_MENU_GROUPS_SUCCESS, payload: response.data }),
            err => dispatch({ type: API_FAILURE, err })
        );
    };
};

export const updateCategories = (categories) => ({
    type: UPDATE_MENU_CATEGORIES_SUCCESS,
    payload: categories
})

export const saveMenuItem = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.post('/menuItem', { ...values, id: uuid() }).then(
            response => {
                dispatch(showSuccessSnackbar('Saved successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: SAVE_MENU_ITEM_SUCCESS, payload: { ...values, id: response.data.id } });
            },
            err => {
                dispatch(showErrorSnackbar('Error in saving the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};

export const saveMenuItems = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.post('/menuItems', values).then(
            response => {
                dispatch(showSuccessSnackbar('Saved successfully!'))
                dispatch({ type: SAVE_MENU_ITEMS_SUCCESS, payload: values });
            },
            err => {
                dispatch(showErrorSnackbar('Error in saving the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};


export const deleteMenuCategory = (menuId, categoryId) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.delete(`/menuCategory/${menuId}/${categoryId}`).then(
            response => {
                dispatch(showSuccessSnackbar('Delete successfully!'))
                // dispatch({ type: API_SUCCESS});

            },
            err => {
                dispatch(showErrorSnackbar('Error in deleting the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};


export const uploadMenuPhoto = ({ menuId, categoryId, menuItemId, form_data }) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;

        return API.post('/menuItem/image', form_data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                menuId,
                categoryId, menuItemId

            }
        }).then(
            response => {
                dispatch(showSuccessSnackbar('Upload successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: UPLOAD_MENU_IMAGE_SUCCESS, payload: response.data });

            },
            err => {
                dispatch(showErrorSnackbar('Error in uploading the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};

export const DELETE_MENU_IMAGE_SUCCCESS = "DELETE_MENU_IMAGE_SUCCCESS";
export const deleteMenuPhoto = ({ menuId, categoryId, menuItemId }) => {
    console.log('deleteMenuPhoto', menuId, categoryId, menuItemId);
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;

        return API.delete('/menuItem/image', {
            headers: {
                menuId,
                categoryId, menuItemId

            }
        }).then(
            response => {
                console.log('delete response', response);
                dispatch({ type: API_SUCCESS });
                dispatch({ type: DELETE_MENU_IMAGE_SUCCCESS, payload: {menuItemId} });

            },
            err => {
                dispatch(showErrorSnackbar('Error in deleting the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};

export const publishMenu = (menuId, qrCodeType) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        let url = `/publishMenu/${menuId}`;
        if (qrCodeType && qrCodeType.length > 0) {
            url =  `/publishMenu/${menuId}/${qrCodeType}`
        }

        return API.put(url).then(
            response => {
                dispatch(showSuccessSnackbar('Published successfully!'))
                // dispatch({ type: API_SUCCESS});

            },
            err => {
                dispatch(showErrorSnackbar('Error in publish the menu'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};

export const unpublishQRMenu = (menuId, qrCodeType) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        let url = `/unpublishMenu/${menuId}`;
        if (qrCodeType && qrCodeType.length > 0) {
            url =  `/unpublishMenu/${menuId}/${qrCodeType}`
        }

        return API.delete(url).then(
            response => {
                dispatch(showSuccessSnackbar('Unpublished successfully!'))
                // dispatch({ type: API_SUCCESS});

            },
            err => {
                dispatch(showErrorSnackbar('Error in publish the menu'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};


export const publishSecondaryMenu = (menuId) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.put(`/publishSecondaryMenu/${menuId}`).then(
            response => {
                dispatch(showSuccessSnackbar('Published successfully!'))
                // dispatch({ type: API_SUCCESS});

            },
            err => {
                dispatch(showErrorSnackbar('Error in publish the menu'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};




const updateMenuItemInState = (menuItem, categories) => {
    const categoryIdx = categories.findIndex(item => item.id === menuItem.categoryId);
    //console.log('categoryIdx', categoryIdx);
    if (categoryIdx !== -1) {
        let category = categories[categoryIdx];
        if (category.items) {
            //  console.log('category.items', category.items);
            const itemIdx = category.items.findIndex(item => item && item.id && item.id === menuItem.id);
            
            if (itemIdx !== -1) {
                return updateItemsWithIndexAndItem(category.items, itemIdx, menuItem);
            }
        }
    }
    return null;
}

export const updateMenuItem = (menuItem, categories) => {
    return (dispatch) => {
        const items = updateMenuItemInState(menuItem, categories).filter(element => element);
        console.log("updated items", items, menuItem);
        if (items === null) {
            dispatch(showErrorSnackbar('Error in saving the data'));
            return;
        }
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        const values = { items, categoryId: menuItem.categoryId, menuId: menuItem.menuId };
        return API.post('/menuItems', values).then(
            response => {
                dispatch(showSuccessSnackbar('Saved successfully!'))
                dispatch({ type: API_SUCCESS });
                dispatch({ type: SAVE_MENU_ITEMS_SUCCESS, payload: values });
            },
            err => {
                dispatch(showErrorSnackbar('Error in saving the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};



export const UPDATE_ALL_MENU_ITEMS_BY_GROUP = "UPDATE_ALL_MENU_ITEMS_BY_GROUP";

export const updateAllMenuItemsByGroup = (group) => {
    return { type: UPDATE_ALL_MENU_ITEMS_BY_GROUP, payload: group };
};



export const updateAllCategories = ({menuId, categories}) => {
    return (dispatch) => {
       
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        const values = { menuId, categories };
        return API.put('/menuCategories', values).then(
            response => {
                dispatch(showSuccessSnackbar('Updated successfully!'))
                dispatch({ type: API_SUCCESS });
               // dispatch({ type: GET_MENU_CATEGORIES_SUCCESS, payload: values });
            },
            err => {
                dispatch(showErrorSnackbar('Error in updating the data'));
                dispatch({ type: API_FAILURE, err })
            }
        );
    };
};
