import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/styles";

import { deepOrange, deepPurple } from "@material-ui/core/colors";
// import LocalDiningIcon from '@material-ui/icons/LocalDining';

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { useSelector, useDispatch } from "react-redux";
import API from "util/API";
import { loadTableId, isDineInTable } from 'util/utils';
import { ShakeHorizontal } from 'reshake'
import { showSuccessSnackbar,  showErrorSnackbar } from 'store/actions/snackbarMessage'


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(1),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 16,
  },
  price: {
    fontSize: 20,
    fontWeight: "bold",
  },
  chipSize: {
    fontSize: 10,
    marginLeft: 8,
  },
  pos: {
    marginBottom: 12,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  editText: {
    fontSize: 12,
  },
  buttonMargin: {
    marginLeft: 10,
    marginRight: 10,
  },
  buttonPadding: {
    padding: "10px 30px 10px 30px",
  },
  dishImage: {
    width: 100,
    height: 100,
    borderWidth: 0,
    borderRadius: 10,
  },
  fab: {
    right: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  typography: {
    padding: theme.spacing(2),
  },
  divider: {
    width: "90%",
    marginRight: theme.spacing(1),
  },
}));


const CallButton = (props) => {


  const [shakeActive, setShakeActive] = useState(false);
  const dispatch = useDispatch();
  const [tableId, setTableId] = useState(null);
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  const restaurantId = useSelector((state) => state.menu.restaurantId);
  const restaurant = useSelector((state) => state.menu.restaurant);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));

  useEffect(() => {
    if (restaurantId) {
        setTableId(loadTableId(restaurantId));
    }

  }, [restaurantId])

  const sendMessage = async (serviceType) => {
    if (!restaurantId) {
        return false;
    }

    if (!tableId) {

    }

    const formRequest = {
        tableId,
        serviceType,
    }

    try {
      const rewsponse = await API.post("/newMessage", formRequest, {
        headers: { restaurantId },
      });
      const data = await rewsponse.data;
      if (data) {
        showSuccess('The request has been sent successfully');
        setShakeActive(true);
      }
    } catch (err) {
     // console.error(JSON.stringify(err));
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errorCode === 10
      ) {
        showError(err.response.data.message);
      } else {
        showError('Something went wrong. Please try again');
      }
    }
  };

  const isBellShowing = () => {
      if (!restaurantId || !tableId) {
          return false;
      }
    //const tableId = loadTableId(restaurantId);
   
    return restaurant && isDineInTable(tableId) && 
    restaurant.serviceBell &&
    restaurant.serviceBell === true && 
    restaurant.serviceTypes && restaurant.serviceTypes.length > 0
  }

  const choseServiceType = (serviceType) => {
    popupState.close()
    sendMessage(serviceType)
  };
  

  return (
    <React.Fragment>
      <div>
        {isBellShowing() ? (
          <>
            <Button {...bindTrigger(popupState)}>
            <ShakeHorizontal >
      <span>
              <img src="/images/bell_icon.png" />
              </span>
              </ShakeHorizontal>
            </Button>
            <Menu {...bindMenu(popupState)}>
              {restaurant.serviceTypes &&
                restaurant.serviceTypes.map((serviceType) => {
                  return (
                    <MenuItem key={serviceType} onClick={e => choseServiceType(serviceType)}>
                      {serviceType}
                    </MenuItem>
                  );
                })}
               
            
            </Menu>
          </>
        ) : null }
      </div>
    </React.Fragment>
  );
};

export default CallButton;
