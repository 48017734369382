import React, { useState, useEffect, useCallback } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreIcon from '@material-ui/icons/More';
import yellow from '@material-ui/core/colors/yellow';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import { removeItemWithSlice, updateItemsWithIndexAndItem } from 'util/utils'
import { minutesToString } from "util/menuUtils";

import {
    default as NewMenuItem
} from '../NewMenuItem'

import {
    default as MenuItem
} from '../MenuItem'

import {
    default as TargetBox
} from '../TargetBox'

import {
    saveMenuItems,

} from 'store/actions/restaurantMenu';
import {  useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import CategoryOptions from './CategoryOptions'
import MenuMiscSettings from './MenuMiscSettings'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '90%',
    },
    details: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    category: {
        width: '80%',
    },
    content: {
        width: '100%',
        background: 'red'

    },
    cover: {
        width: 50,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 50,
        width: 50,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

const Category = (props) => {
    const { category, menuId } = props;
    const classes = useStyles();

    const dispatch = useDispatch();
    const saveItems = (values) => dispatch(saveMenuItems(values));


    const [isNewMenuItem, setIsNewMenuItem] = useState(false)
    const [editMenuItem, setEditMenuItem] = useState(null)

    const [expanded, setExpanded] = useState(false);
    const [itemList, setItemList] = useState([]);
 
    const confirm = useConfirm();
    const [openMenuOptions, setOpenMenuOptions] = useState(false);
    const [openMenuSettings, setOpenMenuSettings] = useState(false);

    useEffect(() => {
        if (category.items) {
            setItemList(category.items);
        }

    }, [category.items]);



    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const openNewMenuItem = () => {
        setIsNewMenuItem(true);

    };

    const closeNewMenuItem = () => {
        setIsNewMenuItem(false);

    };

    const updateItemList = (newItems) => {
        console.log('updateItemList');
        saveItems({ items: newItems, menuId: menuId, categoryId: category.id });
        setItemList(newItems);
    };

    const moveItem = (dragIndex, hoverIndex) => {
        console.log('moveItem', dragIndex, hoverIndex);
        let tempItems = [...itemList];
        [tempItems[dragIndex], tempItems[hoverIndex]] = [tempItems[hoverIndex], tempItems[dragIndex]];
        const newItems = tempItems.map((item, index) => {
            return { ...item, sort: index }
        });
        updateItemList(newItems);
    }



    const deleteItem = (id) => {
        const updatedItemIndex = itemList.findIndex(
            item => item.id === id
        );
        if (updatedItemIndex !== -1) {
            const newItems = removeItemWithSlice(itemList, updatedItemIndex)
            updateItemList(newItems);
        }
    };

    const handleDrop = useCallback(
        (index, item) => {
            if (!item || !item.item) {
                return;
            }
            //console.log('category handledrop', index, item, category.items);
            let menuItem = category.items[index];
            //console.log('menuItem', menuItem);
            if (menuItem.groups) {
                let newGroups = [...menuItem.groups];
                if (newGroups.filter(group => group.id === item.item.id).length > 0) {
                    return
                }
                newGroups.push(item.item);
                menuItem.groups = newGroups;
            } else {
                menuItem.groups = [item.item];
            }
            let newItems = [...category.items];
            newItems[index] = menuItem;
            updateItemList(newItems);
        }, []);


    const moveGroup = (index, newGroups) => {
        let menuItem = category.items[index];
        //console.log('menuItem', menuItem);
        if (menuItem.groups) {
            menuItem.groups = newGroups;
            let newItems = [...category.items];
            newItems[index] = menuItem;
            updateItemList(newItems);
        }

    };

    const handleItemEdit = () => {
        props.editItem(true);
    };

    const handleDelete = () => {
        confirm({ description: 'You are about to delete this category!' })
            .then(() => {
                props.deleteCategory(category.id);
            })
            .catch(() => { /* ... */ });
    };

    const itemImage = (menuItemId) => {
        //console.log('itemImage', menuItemId, category.images);
        if (!category.images) {
            return
        }
        // const index = category.images.findIndex(image => image.menuItemId === menuItemId);
        // //console.log('itemImage', menuItemId, category.images, index);
        // if (index !== -1) {

        //     return category.images[index].imageUrl;
        // }
        return category.images[menuItemId];
    };


    const openMenuOptionsDialog = () => {
        setOpenMenuOptions(true);
    };

    const closeMenuOptions = () => {
        setOpenMenuOptions(false);
    };


    const openMenuSettingsDialog = () => {
        setOpenMenuSettings(true);
    };

    const closeMenuSettings = () => {
        setOpenMenuSettings(false);
    };


    const showWeekDays = (activeDays) => {
        let weekdays = '';
        if (activeDays && activeDays.length > 0) {
           weekdays = `on ${activeDays.join(',')}`;
        }
        return weekdays;
    };

    const hideWeekDays = (hideDays) => {
        let weekdays = '';
        if (hideDays && hideDays.length > 0) {
           weekdays = `on ${hideDays.join(',')}`;
        }
        return weekdays;
    };


    const handleMenuItemEdit = (menuItem) => {
        setEditMenuItem(menuItem);
        setIsNewMenuItem(true);
    };


    const handleMenuItemUpdate = (menuItem) => {
        console.log('handleMenuItemUpdate', menuItem)
        const itemsList = [...category.items]
        const itemIdx = itemsList.findIndex(item => item.id === menuItem.id);
        if (itemIdx !== -1) {
            const newItemsList = updateItemsWithIndexAndItem(itemList, itemIdx, menuItem);
            console.log('newItemsList', newItemsList);
            saveItems({ items: newItemsList, menuId: menuId, categoryId: category.id });
        }
    };

    return (
        <Card className={classes.root} >

            <CardContent >
                <Grid container directions="row" alignContent="space-between" spacing={2} >
                    <Grid item className={classes.category}>
                        <Grid container directions="row" alignItems="center">
                        <Typography component="h5" variant="h5" >

                        {category.colorHex ?
                    <span className="selected-color" style={{ backgroundColor: category.colorHex }}>

                            {category.name}{category.items ? `(${category.items.length} items)` : ''}
                        </span>
                        : `${category.name}${category.items ? `(${category.items.length} items)` : ''}`}
                            
                        </Typography>
                    
                        {category.hideForWeb === true ? <VisibilityOffIcon/>: null }
                        </Grid>
                        <Box>
                        <Typography variant="h6" color="textSecondary">
                        {category.cookingPriority ? `Cooking priority: ${category.cookingPriority}` : ''}
                            </Typography>
                            </Box>
                        <Typography variant="subtitle1" color="textSecondary">
                            {category.altName ? category.altName : ''}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {category.description}
                        </Typography>
                        {!category.active ?
                            <Chip

                                label={`Hidden from menu ${hideWeekDays(category.hideDays)}`}

                                style={{ backgroundColor: yellow[700] }}
                            /> : null}
                        {category.hidden_until != null && category.hidden_until !== undefined ?
                            <Chip

                                label={`Hidden Util ${new Date(category.hidden_until).toLocaleString()}`}

                                style={{ backgroundColor: yellow[700] }}
                            /> : null}

                        {category.active_begin !== null && category.active_begin !== undefined && category.active_end ?
                            <Chip

                                label={`Show only from ${minutesToString(category.active_begin)} - ${minutesToString(category.active_end)}  ${showWeekDays(category.activeWeekDays)}`}

                                style={{ backgroundColor: yellow[700] }}
                            /> : null}
                    </Grid>

                    <Grid item>

                    <IconButton aria-label="more" className={classes.margin}  color="secondary" onClick={openMenuSettingsDialog}>
                            <SettingsOutlinedIcon fontSize="small" />
                        </IconButton>

                        <IconButton aria-label="more" className={classes.margin} edge='end' color="secondary" onClick={openMenuOptionsDialog}>
                            <MoreIcon fontSize="small" />
                        </IconButton>

                        <IconButton aria-label="eidt" className={classes.margin} edge='end' color="secondary">
                            <EditIcon fontSize="small" onClick={handleItemEdit} />
                        </IconButton>
                        <IconButton aria-label="delete" className={classes.margin} edge='end' color="secondary" onClick={handleDelete}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                        <CategoryOptions open={openMenuOptions} handClose={closeMenuOptions} category={category} menuId={menuId}
                        />
                         <MenuMiscSettings open={openMenuSettings} handClose={closeMenuSettings} category={category} menuId={menuId}/>

                    </Grid>

                    <Grid item  >
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            edge='end'

                        >

                            <ExpandMoreIcon />
                        </IconButton>
                    </Grid>
                </Grid>

            </CardContent>
            <Divider />

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>

                    <Grid container direction="column">
                        {

                            itemList.map((item, index) => {

                                return item && <TargetBox
                                    accept={['item']}
                                    dragType={'item'}
                                    key={item.id}
                                    index={index}
                                    id={item.id}
                                    moveCard={moveItem}
                                >
                                    <MenuItem menuItem={{ ...item, image: itemImage(item.id) }} key={item.id}
                                        index={index} moveGroup={moveGroup}
                                        categoryId={category.id} menuId={menuId}
                                        deleteItem={deleteItem} editItem={handleMenuItemEdit} />
                                </TargetBox>
                            })
                        }

                        {
                            isNewMenuItem ?
                                <NewMenuItem category={category} menuId={menuId} close={closeNewMenuItem} menuItem={editMenuItem} editItem={handleMenuItemUpdate} />

                                : null

                        }
                    </Grid>


                </CardContent>
                <Divider />
                <CardActions>

                    <Button variant="outlined" size="small" color="primary" onClick={openNewMenuItem}>
                        Add Item to {category.name}
                    </Button>
                </CardActions>
            </Collapse>

        </Card>
    );
};

export default Category;
