import React, { useState, useEffect, useCallback } from "react";

import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid, Button, Box, Paper, Typography } from "@material-ui/core";

import {
  NewCategory,
  Category,
  TargetBox,
  SourceBox,
  NewGroup,
  Group,
} from "./components";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleNewCategory,
  getMenuCategories,
  toggleNewGroup,
  getMenuGroups,
  deleteMenuGroup,
  deleteMenuCategory,
  updateCategories,
  updateAllCategories,
} from "store/actions/restaurantMenu";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import Sticky from "react-stickynode";
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    backgroundColor: "#f7f7f7",
    display: "flex",
    flexGrow: 1,

    flexDirection: "column",
    flexShrink: 0,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  flexSection: {
    display: "flex",
    flexDirection: "row",
  },

  flexColScroll: {
    minHeight: "100%",
  },

  flexNoShrink: {
    flexShrink: 0,
  },
  leftContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: "#f7f7f7",
    flex: 3,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  rightContainer: {
    flex: 1,
    width: '20%',
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(1),

    zIndex: 1,
  },
  rightContent: {
    flex: 1,
    padding: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const MenuCategroy = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const openNewCategory = () => dispatch(toggleNewCategory());
  const loadMenuCategories = (menuId) => dispatch(getMenuCategories(menuId));
  const loadMenuGroups = (menuId) => dispatch(getMenuGroups(menuId));
  const openNewGroup = () => dispatch(toggleNewGroup());
  const deleteGroup = (menuId, groupId) =>
    dispatch(deleteMenuGroup(menuId, groupId));
  const deleteCategory = (menuId, categoryId) =>
    dispatch(deleteMenuCategory(menuId, categoryId));
  const updateNewCategories = (newCategories) =>
    dispatch(updateCategories(newCategories));

  const updateTheAllCategories = (allCategories) =>
    dispatch(updateAllCategories(allCategories));

  const showNewCategory = useSelector(
    (state) => state.restaurantMenu.showNewCategory
  );
  const showNewGroup = useSelector(
    (state) => state.restaurantMenu.showNewGroup
  );
  const categories = useSelector(
    (state) => state.restaurantMenu.menuCategories
  );
  const groups = useSelector((state) => state.restaurantMenu.menuGroups);
  const [categoryList, setCategoryList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const menuId = props.match.params.menuId;


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    loadMenuCategories(menuId);
    loadMenuGroups(menuId);
  }, [props]);

  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  useEffect(() => {
    setGroupList(groups);
  }, [groups]);

  const createNewCategory = () => {
    openNewCategory();
  };

  const moveCategory = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = categoryList[dragIndex];
      const newSortedCategories = update(categoryList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      setCategoryList(newSortedCategories);
      updateTheAllCategories({
        menuId,
        categories: newSortedCategories.map((category, index) => {
          return { ...category, sort: index };
        }),
      });
    },
    [categoryList]
  );

  const removeItemWithSlice = (items, index) => {
    let newSelectedOptions = [];

    if (index === 0) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(1));
    } else if (index === items.length - 1) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(0, -1));
    } else if (index > 0) {
      newSelectedOptions = newSelectedOptions.concat(
        items.slice(0, index),
        items.slice(index + 1)
      );
    }
    return newSelectedOptions;
  };

  const handleDrop = useCallback((index, item) => {
    console.log("handledrop", index, item);
  }, []);

  const removeGroupFromAllCategories = (groupId) => {
    console.log("removeGroupFromAllCategories", groupId, categories.length);
    let updateCategoryIds = new Set();
    let updateCategories = [];
    let newCategories = [];

    categories.forEach((category) => {
      let newItems = [];

      //console.log('category==', category.id);
      for (let item of category.items) {
        let newGroups = [];
        for (let group of item.groups) {
          //console.log('grouid==', group.id, item.id, category.id);
          if (group.id === groupId) {
            //console.log('grouid=======', group.id);
            updateCategoryIds.add(category.id);
          } else {
            newGroups.push(group);
          }
        }
        let newSizes = [];
        if (item.sizes) {
          for (let size of item.sizes) {
            let sizeGroups = [];
            if (size.groups) {
              for (let group of size.groups) {
                if (group.id === groupId) {
                  updateCategoryIds.add(category.id);
                } else {
                  sizeGroups.push(group);
                }
              }
            }
            newSizes.push({ ...size, groups: sizeGroups });
          }
        }

        let newItem = { ...item, groups: newGroups, sizes: newSizes };
        newItems.push(newItem);
      }

      if (updateCategoryIds.has(category.id)) {
        const newCategory = { ...category, items: newItems };
        updateCategories.push(newCategory);
        newCategories.push(newCategory);
      } else {
        newCategories.push(category);
      }
    });

    if (updateCategories.length > 0) {
      updateTheAllCategories({ menuId, categories: updateCategories });
      setCategoryList(newCategories);
    }
  };

  const handleGroupDelete = (groudId) => {
    console.log("handleGroupDelete", groudId);
    const updatedItemIndex = groups.findIndex((item) => item.id === groudId);
    if (updatedItemIndex !== -1) {
      removeGroupFromAllCategories(groudId);
      deleteGroup(menuId, groudId);
    }
  };

  const handleCategoryDelete = (categoryId) => {
    const updatedItemIndex = categories.findIndex(
      (item) => item.id === categoryId
    );
    if (updatedItemIndex !== -1) {
      const newCategories = removeItemWithSlice(categories, updatedItemIndex);
      try {
        deleteCategory(menuId, categoryId);
        updateNewCategories(newCategories);
      } catch (err) {
        console.log(err);
      }
      // setCategoryList(newCategories);
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <Grid container>
          <DndProvider backend={HTML5Backend}>
          <Grid item xs={12} sm={8}>
          <Box style={{ maxHeight: isSmallScreen ? '50vh' : '100vh', overflow: 'auto' }}>
                {categoryList.map((category, index) => (
                  <TargetBox
                    accept={["box", "card"]}
                    dragType={"card"}
                    key={category.id}
                    index={index}
                    id={category.id}
                    onDrop={(item) => handleDrop(index, item)}
                    moveCard={moveCategory}
                  >
                    <Category
                      category={category}
                      key={category.id}
                      menuId={menuId}
                      deleteCategory={handleCategoryDelete}
                    />
                  </TargetBox>
                ))}

                {showNewCategory ? <NewCategory menuId={menuId} /> : null}

                <Button variant="contained" onClick={createNewCategory}>
                  Add Category
                </Button>
              </Box>
            </Grid>

            <Grid
             item xs={12} sm={4}
              className={classes.rightContainer}
            >
             <Box style={{ maxHeight: isSmallScreen ? '50vh' : '100vh', overflow: 'auto' }}>
                <Sticky enabled={true} top={isSmallScreen ? 0 : 50}>
                  <Paper elevation={3} className={classes.rightContent}>
                    <Typography variant="h5">Choices And Addons</Typography>

                    <Grid container direction="column">
                      {groupList.sort((a, b) => a.sort < b.sort).map((group) => (
                        <SourceBox key={group.id} item={group}>
                          <Grid item key={group.id}>
                            <Group
                              group={group}
                              delete={handleGroupDelete}
                              menuId={menuId}
                            />
                          </Grid>
                        </SourceBox>
                      ))}
                    </Grid>

                    {showNewGroup ? <NewGroup menuId={menuId} /> : null}
                    <Button variant="contained" onClick={openNewGroup}>
                      Add New Group
                    </Button>
                  </Paper>
                </Sticky>
              </Box>
            </Grid>
          </DndProvider>
        </Grid>
      </div>
    
    </div>
  );
};

export default MenuCategroy;
