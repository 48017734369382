import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Grid
} from '@material-ui/core';

import { StoreContext } from "../../context/store";

//import { getInitials } from 'helpers';
import { useHistory } from 'react-router-dom';
import { setTransactionDetail, setTransactionPage } from 'store/actions/transaction';
import { useDispatch, useSelector } from 'react-redux';




const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
   // minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textLink: {
    textDecoration: 'underline',
  },
}));

const PromotionsTable = props => {
  const { className, promotions, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectIds } = useContext(
    StoreContext
  );

  const [selectedPromotions, setSelectedPromotions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);


  
 
  const setPage = (page) => dispatch(setTransactionPage(page));
  const page = useSelector((state) => state.transaction.page);



  const handleSelectOne = (event, promotionId) => {
    const selectedIndex = selectedPromotions.indexOf(promotionId);
    let newSelectedPromotions = [];

    if (selectedIndex === -1) {
      newSelectedPromotions = newSelectedPromotions.concat(selectedPromotions, promotionId);
    } else if (selectedIndex === 0) {
      newSelectedPromotions = newSelectedPromotions.concat(selectedPromotions.slice(1));
    } else if (selectedIndex === selectedPromotions.length - 1) {
      newSelectedPromotions = newSelectedPromotions.concat(selectedPromotions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPromotions = newSelectedPromotions.concat(
        selectedPromotions.slice(0, selectedIndex),
        selectedPromotions.slice(selectedIndex + 1)
      );
    }

    setSelectedPromotions(newSelectedPromotions);
    selectIds[1](newSelectedPromotions);
   
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getTimeString = (date) => {
    if ('_seconds' in date) {
      return moment(new Date(date._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')
    }
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
  };


  
  const openPromotion = (promotion) => {
    if (promotion.type === 'buyNFree') {
      history.push(`/buyNFree/${promotion.id}`);
    } 
    
    if (promotion.type === 'newMember') {
      history.push(`/newMemberPromotion/${promotion.id}`);
    }
    if (promotion.type === 'birthday') {
      history.push(`/birthdayPromotion/${promotion.id}`);
    }  
    
  };

  return (
    <Grid
  
    lg={12}
    md={12}
    xl={12}
    xs={12}
  >
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        {/* <PerfectScrollbar> */}
          <div className={classes.inner}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                     {/* <Checkbox
                      checked={selectedTransactions.length === transactions.length}
                      color="primary"
                      indeterminate={
                        selectedTransactions.length > 0 &&
                        selectedTransactions.length < transactions.length
                      }
                      onChange={handleSelectAll}
                    />  */}
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  {/* <TableCell>Coupon</TableCell>
                  <TableCell>Used</TableCell> */}
                  <TableCell>Updated</TableCell>
                  {/* <TableCell><MoreVertIcon/></TableCell>                       */}
                </TableRow>
              </TableHead>
              <TableBody>
                {promotions && promotions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(promotion => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={promotion.id}
                    selected={selectedPromotions.indexOf(promotion.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedPromotions.indexOf(promotion.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, promotion.id)}
                        value="true"
                      />
                    </TableCell>
                  

                    <TableCell>

                      <Typography color = {promotion.isEnabled ? 'primary' : ''}>
                        {promotion.isEnabled ? 'Enabled': 'Disabled'}
                        </Typography>
                    </TableCell>
                    
                    <TableCell>{promotion.type}</TableCell>

                    <TableCell>
                    <Button onClick={e => openPromotion(promotion)}>
                    <div className={classes.textLink}>
                      {promotion.name}
                      </div>
                      </Button>
                      </TableCell>
                    {/* <TableCell>{promotion.coupon}</TableCell>

                    <TableCell></TableCell> */}

                    <TableCell> {promotion.updatedAt && getTimeString(promotion.updatedAt)}</TableCell>

                    {/* <TableCell>

                    <MoreVertIcon/>

                    </TableCell> */}
                    
                    

                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          </div>
        {/* </PerfectScrollbar> */}
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={promotions.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
    </Grid>
  );
};

PromotionsTable.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired
};

export default PromotionsTable;
