import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import LinearProgress from '@material-ui/core/LinearProgress';


import { useHistory } from 'react-router-dom';


import { authMiddleWare } from 'util/auth'
import moment from 'moment';
import RefundAmount from './RefundAmount'

import API from 'util/API';




const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


const OrderPayments = (props) => {
  const { payment, order } = props;
  const classes = useStyles();
  const history = useHistory();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    authMiddleWare(history);

  }, [props]);


  const orderCreateDate = (order) => {
    const dateFormat = 'DD-MM-YY';
    if ('_seconds' in order.createdAt) {
      return  moment(new Date(order.createdAt._seconds * 1000)).format(dateFormat)
    }
    return moment(order.createdAt).format(dateFormat)
  };

  const doRefundAmount = (amount) => {
    console.log('refundalling');
    if (!payment || !order) {
      console.log('payment or order is nil');
      return;
    }
    
    const formRequest = {
      partnerOrderId: payment.partner_order_id,
      refundAmount: amount,
      orderDate: orderCreateDate(order)
    };
 
    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;

    setButtonLoading(true);
    API
    .post('/onlinePaymentRefund', formRequest)
    .then((response) => {
        console.log(response.data);
        setButtonLoading(false);
        history.replace('/orders');
    })
    .catch((error) => {
        console.log(error);
        setButtonLoading(false);
       // setOpenErrorSnackbar(true)
    });
  };

  const refundAll = () => {
   doRefundAmount(payment.real_fee);
  };

  const refundAmount = () => {
      setOpen(true);
  };

  return (
    <React.Fragment>
      {buttonLoading ?
                <LinearProgress /> : null}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableCell>Payment Details</TableCell>
            <TableCell align="right">

            <Button variant="outlined" color="primary"
            onClick={refundAll}
            disabled={
              buttonLoading || 
              !payment ||
              payment.result_code === 'FULL_REFUND' ||
              payment.result_code === 'PARTIAL_REFUND'
            }
            >
        Refund All
      </Button>

      <Button variant="outlined" color="primary"
            onClick={refundAmount}
            disabled={
              buttonLoading || 
              !payment ||
              payment.result_code === 'FULL_REFUND'
            }
            >
        Refund Amount
      </Button>
      <RefundAmount open={open}  total={payment.real_fee} proxy={doRefundAmount}/> 
            </TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Payment Result Code: </TableCell>
              <TableCell align="left">{payment.result_code}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Payment Return Code: </TableCell>
              <TableCell align="left">{payment.return_code}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Channel: </TableCell>
              <TableCell align="left">{payment.channel}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Channel Order Id: </TableCell>
              <TableCell align="left">{payment.channel_order_id}</TableCell>
            </TableRow>


            <TableRow>
              <TableCell>Create Time: </TableCell>
              <TableCell align="left">{payment.create_time}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Currency: </TableCell>
              <TableCell align="left">{payment.currency}</TableCell>
            </TableRow>

           { payment.customer_info  ?
           <>

            <TableRow>
              <TableCell>Customer Alipay Account: </TableCell>
              <TableCell align="left">{payment.customer_info && payment.customer_info.alipay_account}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Customer Alipay User Id: </TableCell>
              <TableCell align="left">{payment.customer_info && payment.customer_info.alipay_user_id}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Customer Bank </TableCell>
              <TableCell align="left">{payment.customer_info && payment.customer_info.bank}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Customer Card Alias </TableCell>
              <TableCell align="left">{payment.customer_info && payment.customer_info.card_alias}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Customer Card Country: </TableCell>
              <TableCell align="left">{payment.customer_info && payment.customer_info.card_country}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Customer Card Scheme: </TableCell>
              <TableCell align="left">{payment.customer_info && payment.customer_info.card_scheme}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Customer Card Type: </TableCell>
              <TableCell align="left">{payment.customer_info && payment.customer_info.card_type}</TableCell>
            </TableRow>
            </>
            : null }

            <TableRow>
              <TableCell>Input fee: </TableCell>
              <TableCell align="left">${(payment.input_fee / 100).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Order Description: </TableCell>
              <TableCell align="left">{payment.order_description}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Payment Order Id: </TableCell>
              <TableCell align="left">{payment.order_id}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Order Number: </TableCell>
              <TableCell align="left">{payment.partner_order_id}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Pay Time: </TableCell>
              <TableCell align="left">{payment.pay_time}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Rate: </TableCell>
              <TableCell align="left">{payment.rate}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Real fee: </TableCell>
              <TableCell align="left">${(payment.real_fee / 100).toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Settlement Date: </TableCell>
              <TableCell align="left">{payment.settlement_date}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total Fee: </TableCell>
              <TableCell align="left">${(payment.total_fee / 100).toFixed(2)}</TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

    </React.Fragment>
  );
};

export default OrderPayments;
