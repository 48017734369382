import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { ServiceBellTypesTable } from './components';



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));


const ServiceBellTypesList = (props) => {
  const classes = useStyles();




  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <ServiceBellTypesTable  />
      </div>
    </div>
  );
  
};

export default ServiceBellTypesList;
