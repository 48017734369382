
import API from 'util/API';
import { showSuccessSnackbar, showErrorSnackbar } from 'store/actions/snackbarMessage'
import { 
    API_SUCCESS,
    API_FAILURE,
    API_START,
  } from './common'

export const FETCH_RESTAURANTS_SUCCESS = 'FETCH_RESTAURANTS_SUCCESS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS__SUCCESS';
export const UPDATE_ORDERS_TYPE = 'UPDATE_ORDERS_TYPE';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const SET_ORDER_DETAIL = 'SET_ORDER_DETAIL';
export const SET_ORDERS_DATE = 'SET_ORDERS_DATE';
export const SET_SELECTED_RESTAURANT = 'SET_SELECTED_RESTAURANT';
export const SET_SELECTED_RESTAURANT_IDS = 'SET_SELECTED_RESTAURANT_IDS';


export const fetchRestaurantsSuccess = restaurants => ({
    type: FETCH_RESTAURANTS_SUCCESS,
    payload: restaurants
});

export const fetchTodayOrders = orders => ({
    type: FETCH_ORDERS_SUCCESS,
    payload: orders
});

export const fetchCustomers = customers => ({
    type: FETCH_CUSTOMERS_SUCCESS,
    payload: customers
});

export const updateOrderType = type => ({
    type: UPDATE_ORDERS_TYPE,
    payload: type
});

export const setOrderDetail = order => ({
    type: SET_ORDER_DETAIL,
    payload: order
});

export const SET_BOOKING_SESSION_DETAIL = 'SET_BOOKING_SESSION_DETAIL';
export const setBookingSessionDetail = session => ({
    type: SET_BOOKING_SESSION_DETAIL,
    payload: session
});

export const setOrderSelectedDate = date => ({
    type: SET_ORDERS_DATE,
    payload: date
});

export const updateSelectedRestaurant = restaurant => ({
    type: SET_SELECTED_RESTAURANT,
    payload: restaurant
});

export const updateSelectedRestaurantIds = ids => ({
    type: SET_SELECTED_RESTAURANT_IDS,
    payload: ids
});

export const SET_SELECTED_BOOKINGSESSIONIDS = 'SET_SELECTED_BOOKINGSESSIONIDS';
export const updateSelectedBookingSessionIds = ids => ({
    type: SET_SELECTED_BOOKINGSESSIONIDS,
    payload: ids
});


export const GET_TABLE_QRSTRING = 'GET_TABLE_QRSTRING';
export const getTableQrString = (tableNumber, isPaymentRequired) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.get(`/getTableQrString?tableNumber=${tableNumber}&isPaymentRequired=${isPaymentRequired}`, ).then(
            
            response => {
                //dispatch(showSuccessSnackbar('Success!'))
                dispatch({ type: GET_TABLE_QRSTRING, payload: response.data }); 
            },
            err => dispatch({ type: API_FAILURE, err })
        );
    };
};


export const UPDATE_FILTER_KEYWORD = 'UPDATE_FILTER_KEYWORD';
export const updateRestaurantkeyword = keyword => ({
    type: UPDATE_FILTER_KEYWORD,
    payload: keyword
});

export const UPDATE_RESTAURANT_VALUES = 'UPDATE_RESTAURANT_VALUES';
export const updateRestaurantValues = (values) => {
    return (dispatch) => {
        dispatch({ type: API_START });
        // const authToken = localStorage.getItem('AuthToken');
        // API.defaults.headers.common['Authorization'] = authToken;
        return API.put('/updateRestaurantValues/', values).then(
            response => {
                dispatch(showSuccessSnackbar('Updated successfully!'));
                dispatch({ type: API_SUCCESS });
                dispatch({ type: UPDATE_RESTAURANT_VALUES, payload: values });

            }
            , err => {
                dispatch(showErrorSnackbar('Error in updating the data'));
                dispatch({ type: API_FAILURE, err });
            }
        );
    };
};
