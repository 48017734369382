import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import {
  Card,
  TextField,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Switch from "@material-ui/core/Switch";

import { useSelector, useDispatch } from "react-redux";

import { removeItemWithSlice } from "util/utils";
import API from "util/API";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
    minHeight: 500,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },

  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const POSDiscountSettings = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [uiLoading, setUiLoading] = useState(false);
  const [isPercentage, setIsPercentage] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountName, setDiscountName] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [sort, setSort] = useState(0);
  const [discounts, setDiscounts] = useState([]);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  const save = () => {
    if (!restaurant) {
      showError("Please select a restaurant first.");
      return;
    }

    if (discountName.trim() === "") {
      showError("The discount name cannot be empty");
      return;
    }

    if (isPercentage === true && discountPercentage.trim() === "") {
      showError("The discount percentage cannot be empty");
      return;
    }

    if (isPercentage === true && (!Number(discountPercentage) || Number(discountPercentage) <= 0)) {
      showError("The discount percentage is not a valid number");
      return;
    }

    if (isPercentage === false && discountAmount.trim() === "") {
      showError("The discount amount cannot be empty");
      return;
    }

    if (isPercentage === false && (!Number(discountAmount) || Number(discountAmount) <= 0)) {
      showError("The discount amount is not a valid number");
      return;
    }

    if (discounts) {
      const index = discounts.findIndex((item) => {
        if (item.name.toLowerCase() === discountName.toLowerCase()) {
          return true;
        }
        return false;
      });

      if (index !== -1) {
        showError("The discount name alreay exists.");
        return;
      }
    }

    let items = [];
    const item = {
      name: discountName,
      percentage: Number(discountPercentage),
      amount: Number(discountAmount),
      sort: Number(sort),
    };

    if (discounts.length > 0) {
      items = [...discounts, item];
    } else {
      items = [item];
    }
    setDiscounts(items);
    updateRestaurantSettings(items);
  };

  const resetForm = () => {
    setDiscountName("");
    setDiscountPercentage("");
    setIsPercentage(false);
    setSort(discounts.length);
    setDiscountAmount(0);
  };

  const updateRestaurantSettings = (items) => {
    if (!restaurant) {
      showError("Please go back to the restaurant screen and try again.");
      return;
    }

    setUiLoading(true);
    API.put("/updateRestaurantValues/", {
      posSettings: {
        ...restaurant.posSettings,
        discounts: items.sort((a, b) => a.sort - b.sort),
      },
    })
      .then((response) => {
        setUiLoading(false);
        showSuccess("Updated successfully");
        resetForm();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          props.history.push("/sign-in");
        }
        console.log(JSON.stringify(error));
        setUiLoading(false);
      });
  };

  const handleDelete = (key) => {
    const deleteIndx = discounts.findIndex((item) => item.name === key);
    if (deleteIndx !== -1) {
      const numbers = removeItemWithSlice(discounts, deleteIndx);
      setDiscounts(numbers);
      updateRestaurantSettings(numbers);
      resetForm();
    }
  };



  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "isPercentage") {
      setIsPercentage(value);
    }
  };

  useEffect(() => {
    if (
      restaurant &&
      restaurant.posSettings &&
      restaurant.posSettings.discounts
    ) {
      setDiscounts(restaurant.posSettings.discounts);
      setSort(restaurant.posSettings.discounts.length);
    }
  }, [restaurant]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subheader="" title="POS discount settings" />

      <CardContent className={classes.content}>
        {uiLoading ? <LinearProgress /> : null}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item>
            <TextField
              label="Discount Name"
              margin="dense"
              name="discountName"
              variant="outlined"
              value={discountName}
              onChange={(e) => setDiscountName(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={isPercentage}
                  onChange={handleSwitchChange}
                  name="isPercentage"
                  color="primary"
                />
              }
              label="Is Percentage Discount"
            />
          </Grid>
          {isPercentage ? (
            <Grid item>
              <TextField
                label="Discount %"
                margin="dense"
                name="discountPercentage"
                variant="outlined"
                type="number"
                value={discountPercentage}
                onChange={(e) => setDiscountPercentage(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid item>
              <TextField
                label="Discount Amount"
                margin="dense"
                name="discountAmount"
                variant="outlined"
                type="number"
                value={discountAmount}
                onChange={(e) => setDiscountAmount(e.target.value)}
              />
            </Grid>
          )}

          <Grid item>
            <TextField
              label="Sort (ascending)"
              margin="dense"
              name="sort"
              variant="outlined"
              type="number"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
            />
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={uiLoading}
              onClick={save}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="5">POS Discount Types</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurant &&
                discounts.length > 0 &&
                discounts
                  .sort((a, b) => a.sort - b.sort)
                  .map((item) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={item.name}
                    >
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.percentage > 0 ? 'Percentage discount': 'Amount discount'}</TableCell>
                      <TableCell>{item.percentage ? item.percentage : item.amount}</TableCell>
                      <TableCell>{item.sort}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete"
                          className={classes.margin}
                          edge="end"
                          color="secondary"
                          onClick={(e) => handleDelete(item.name)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

POSDiscountSettings.propTypes = {
  className: PropTypes.string,
  // cookingCategories: PropTypes.array.isRequired
};

export default POSDiscountSettings;
