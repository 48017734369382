import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { SearchInput } from 'components';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { useSelector, useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';

import { useHistory } from 'react-router-dom';



const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  buttonSpacer: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const CookingCategoriesToolbar = props => {
  const { className, ...rest } = props;
  const [uiLoading, setUiLoading] = useState(false);
  const dispatch = useDispatch();
  
  
  
 

  const classes = useStyles();



  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <IconButton aria-label="delete">
          <DeleteIcon />
        </IconButton>
        {/* <IconButton aria-label="payment" onClick={openPaymentSettings}>
          <AccountBalanceIcon />
        </IconButton>
        <PaymentSettings open={open} /> */}

        <IconButton aria-label="copy"
          disabled={uiLoading} >
          <FileCopyIcon />
        </IconButton>

        <Button variant="outlined" color="primary" >Publish</Button>
        <span className={classes.buttonSpacer} />
        <NavLink to={'/menu'}>
          <Button
            color="primary"
            variant="contained"
          >
            Add Menu
        </Button>
        </NavLink>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search menu"
        />
      </div>

    </div>
  );
};

CookingCategoriesToolbar.propTypes = {
  className: PropTypes.string
};

export default CookingCategoriesToolbar;
