import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { useSelector } from 'react-redux';

const theme1 = createMuiTheme({
  palette: {
    primary: { main :'#f46a9f'},
  },
    secondary: {
      main: '#ffebee',
    },
});


const theme2 = createMuiTheme({
  palette: {
    primary: { main :'#3f51b5'},
  },
    secondary: {
      main: '#f50057',
    },
});


const theme3 = createMuiTheme({
  palette: {
    primary: { main :'#db3d00'},
  },
    secondary: {
      main: '#ffd6bd',
    },
});


const theme4 = createMuiTheme({
  palette: {
    primary: { main :'#FCCB00'},
  },
    secondary: {
      main: '#ffd64e',
    },
});


const theme5 = createMuiTheme({
  palette: {
    primary: { main :'#a8c17f'},
  },
    secondary: {
      main: '#fce1d7',
    },
});


const theme6 = createMuiTheme({
  palette: {
    primary: { main :'#1273de'},
  },
    secondary: {
      main: '#c4e3fc',
    },
});


const theme7 = createMuiTheme({
  palette: {
    primary: { main :'#c5b1ea'},
  },
    secondary: {
      main: '#fcd9d9',
    },
});

const theme8 = createMuiTheme({
  palette: {
    primary: { main :'#212121'},
    
  },
    secondary: {
      main: '#ffca28',
      

    },
});

const theme9 = createMuiTheme({
  palette: {
    primary: { main :'#fafafa'},
    
  },
    secondary: {
      main: '#263238',
      

    },
});

const themeMapping = {'#db3e00': theme3, '#fccb00': theme4, 
'#a8c17f': theme5, '#1273de': theme6, '#c5b1ea': theme7, '#f46a9f': theme1, '#3f51b5': theme2, '#212121': theme8, '#fafafa': theme9};


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    height: '100%'
  }
}));

const Online = props => {
  const { children } = props;

  const classes = useStyles();
  const restaurant = useSelector((state) => state.menu.restaurant);

  return (
    <ThemeProvider theme={restaurant && restaurant.themeColor && themeMapping[restaurant.themeColor.toLowerCase()]  ? themeMapping[restaurant.themeColor.toLowerCase()] : theme1}>
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
    </ThemeProvider>
  );
};

Online.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Online;
