import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import Select from "react-select";
import { showErrorSnackbar } from "store/actions/snackbarMessage";
import { useSelector, useDispatch } from "react-redux";

const CookingCategoryDialog = (props) => {
  const { isOpen, onClose, onSave, categoryToEdit, printers } = props;

  const initialState = {
    name: categoryToEdit ? categoryToEdit.name : "",
    printer: categoryToEdit ? categoryToEdit.printer : "",
    printCopies: categoryToEdit ? Number(categoryToEdit.printCopies) : 1,
    isForKDS: categoryToEdit ? categoryToEdit.isForKDS : false,
    isForLabelPrint: categoryToEdit ? categoryToEdit.isForLabelPrint : false,
    printerObject: categoryToEdit ? categoryToEdit.printerObject : null,
  };

  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const menu = useSelector((state) => state.restaurantMenu.menu);
  const [maxHeight, setMaxHeight] = useState("500px");

  useEffect(() => {
    if (categoryToEdit) {
      setFormData(categoryToEdit);
    } else {
      setFormData(initialState);
    }
  }, [categoryToEdit]);

  useEffect(() => {
    if (printers && printers.length > 0) {
      const printerHeight = 36; // height of each printer option
      const maxHeight = printers.length * printerHeight + 300; // add extra height for other elements
      setMaxHeight(`${maxHeight}px`);
    }
  }, [printers]);

  const handleSave = () => {
    // Validation
    if (!formData.name.trim()) {
      showError("The category name cannot be empty.");
      return;
    }

    if (
      !formData.isForKDS &&
      (!isNaN(formData.copies) || Number(formData.copies) < 1)
    ) {
      showError("The print copies should be a positive integer.");
      return;
    }

    if (menu.cookingCategories && !categoryToEdit) {
      const index = menu.cookingCategories.findIndex((category) => {
        // check category is object

        if (typeof category === "object" && category.id) {
          if (category.name.toLowerCase() === formData.name.toLowerCase()) {
            return true;
          }
        } else {
          if (
            typeof category === "string" &&
            category.toLowerCase() === formData.name.toLowerCase()
          ) {
            return true;
          }
        }
        return false;
      });

      if (index !== -1) {
        showError("The category alreay exists.");
        return;
      }
    }

    onSave(formData);
    setFormData(initialState);
    // resetForm();
  };

  function CookingCategoryEditInstructions() {
    return (
      <>
        <Typography variant="h5" style={{ marginLeft: 20, marginTop: 20 }}>
          {" "}
          Guideline:
        </Typography>
        <Typography variant="body1" style={{ marginLeft: 20, marginTop: 8 }}>
          <ol>
            <li>The category is for KDS, and dishes category printing.</li>
            <li>
              If the printer is not selected, this category won't be used for
              printing.
            </li>
            <li>
              After the print category is updated, menu publishing and reimport
              on the POS are required.
            </li>
          </ol>
        </Typography>
      </>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {categoryToEdit ? "Edit Cooking Category" : "Add New Cooking Category"}
      </DialogTitle>
      <DialogContent style={{ height: maxHeight }}>
        <TextField
          fullWidth
          label="Cooking Category Name"
          margin="dense"
          name="cookingCategoryName"
          variant="outlined"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />

        {/* Select for Printer */}
        {printers && printers.length > 0 && (
          <Select
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            value={{ value: formData.printer, label: formData.printer }}
            onChange={(selectedOption) =>
              setFormData({ ...formData, printer: selectedOption.value, printerObject: selectedOption })
            }
            options={printers.map((printer) => ({
              value: printer.printerName,
              label: `${printer.printerName}-${printer.ipAddress}`,
            }))}
          />
        )}

        <TextField
          fullWidth
          label="Print Copies"
          margin="dense"
          name="printCopies"
          variant="outlined"
          type="number"
          value={formData.printCopies}
          onChange={(e) =>
            setFormData({ ...formData, printCopies: Number(e.target.value) })
          }
        />

        <FormControlLabel
          control={
            <Switch
              checked={formData.isForKDS}
              onChange={(e) =>
                setFormData({ ...formData, isForKDS: e.target.checked })
              }
              name="isForKDS"
              color="primary"
            />
          }
          label="KDS Category"
        />

        <FormControlLabel
          control={
            <Switch
              checked={formData.isForLabelPrint}
              onChange={(e) =>
                setFormData({ ...formData, isForLabelPrint: e.target.checked })
              }
              name="isForLabelPrint"
              color="primary"
            />
          }
          label="Label Print"
        />

        {CookingCategoryEditInstructions()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          {categoryToEdit ? "Save Changes" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookingCategoryDialog;
