import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { withStyles } from "@material-ui/core/styles";
import API from "util/API";
import { showErrorSnackbar } from "store/actions/snackbarMessage";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import { transformOrderId } from "util/orderUtils";

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  spacer: {
    flexGrow: 1,
  },
  card: {
    margin: theme.spacing(1),
  },
  image: {
    width: "96%",
    height: undefined,

    borderWidth: 0,
    borderRadius: 10,
    margin: theme.spacing(1),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  centeredButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    fontSize: '1.5em', // Increase font size for easier tapping
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

export default function OBPaymentSuccess(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();


  const [checkAdyenPayment, setCheckAdyenPayment] = useState(false);


  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const reference = () => {
    return transformOrderId(props.match.params.orderNumber);
  };

  const getUrlParam = (key) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
  };

  const imageName = () => {
  
     "/static/images/success_promo.png";
  };

  useEffect(() => {
    if (window && window.parent) {
      const redirectResultType = getUrlParam("type");
      if (redirectResultType === "success") {
        window.parent.postMessage(
          { type: "payment_success", success: true },
          "*"
        );
      }
    }
  }, [props]);


  const handleSuccessResult = () => {
    console.log("Payment completed");
    const orderNumber = props.match.params.reference;


    // emptyOrderCart({ ...order.adyenSession.order.order, id: orderNumber });
  };

  const updateAdyenPaymentDetails = async (redirectResult) => {
    try {
      const result = await API.post(`adyenPaymentDetails`, {
        payload: {
          details: { redirectResult },
        },
      });
      setCheckAdyenPayment(false);
      if (result && result.data && result.data.resultCode === "Authorised") {
        handleSuccessResult();
      } else {
        showError("Payment failed, please try again.");
      
      }
    } catch (error) {
      showError("Payment failed, please try again.");
     
    }
  };

  useEffect(() => {
    // check url params inlcude  redirectResult
    const redirectResult = getUrlParam("redirectResult");
    if (redirectResult) {
      setCheckAdyenPayment(true);
      updateAdyenPaymentDetails(redirectResult);
    }
  }, [props]);

  if (checkAdyenPayment === true) {
    return (
      <main className={classes.content}>
        <CircularProgress size={150} className={classes.uiProgess} />
      </main>
    );
  } else {
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
        
            <Typography>{`Order Number: ${reference()}`}</Typography>
          
          </Toolbar>
        </AppBar>
        <div className={classes.centeredButton}>
        <Button 
      color="primary" 
      variant="contained" 
      style={{ padding: '10px 20px', fontSize: '16px',marginTop: '30px' }} // Larger tap area and readable text
    >
        <a href={window.location.href} target="_blank">Return to the Orderbuddy App</a>
      </Button>
    </div>
      </div>
    );
  }
}
