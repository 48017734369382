import React, { useState, useRef, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import CreatableSelect from "react-select/creatable";

import { useSelector, useDispatch } from "react-redux";

import { default as ReactSelect } from "react-select";

import { default as MenuVisibility } from "../MenuVisibility";

import { saveMenuItems, updateMenu } from "store/actions/restaurantMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    minWidth: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

const CategoryOptions = (props) => {
  const classes = useStyles();
  const { open, menuId, category } = props;
  const dispatch = useDispatch();
  const [printCategoryList, setPrintCategoryList] = useState([]);

  const [cookingCategory, setCookingCategory] = useState("");
  const selectPrintCategoryRef = useRef();
  const selectPromotionTagsRef = useRef();

  const [promotionTags, setPromotionTags] = useState([]);
  const updateTheMenu = (menu, menuId) => dispatch(updateMenu(menu, menuId));

  const menu = useSelector((state) => state.restaurantMenu.menu);
  const saveItems = (values) => dispatch(saveMenuItems(values));

  const handleClose = () => {
    props.handClose();
  };

  const updateItemList = (cookingCate) => {
    console.log("updateItemList");
    let cate = cookingCate;
    if (cookingCate === "None") {
      cate = null;
    }
    const items = category.items.map((item) => {
      return { ...item, cookingCategory: cate };
    });
    saveItems({ items, menuId: menuId, categoryId: category.id });
  };

  useEffect(() => {
    if (category) {
      const existingPromotionTags = (
        category.promotionTags ? category.promotionTags : []
      ).map((tag) => {
        return { value: tag, label: tag };
      });
      setPromotionTags(existingPromotionTags);
    }
  }, [category]);

  const handleCookingCategoryChange = (event) => {
    updateItemList(event.target.value);
    setCookingCategory(event.target.value);
  };

  const cookingCategories = () => {
    if (menu && menu.cookingCategories && menu.cookingCategories.length > 0) {
      return menu.cookingCategories.map((category) => {
        if (category.id) {
          return { value: category.name, label: category.name };
        } else {
          return { value: category, label: category };
        }
      });
    } else {
      return [];
    }
  };

  const getPromotionTags = () => {
    if (menu && menu.promotionTags && menu.promotionTags.length > 0) {
      return menu.promotionTags.map((tag) => {
        return { value: tag, label: tag };
      });
    } else {
      return [];
    }
  };

  const handlePrintCategoryChange = (options) => {
    console.log("handlePrintCategoryChange", options);
    if (!options || options == null) {
      return;
    }

    const allPrintCategories = options.map((option) => option.value).join(";");
    const items = category.items.map((item) => {
      return { ...item, printCategory: allPrintCategories };
    });
    saveItems({ items, menuId: menuId, categoryId: category.id });
  };

  const onClearPrintCategory = () => {
    selectPrintCategoryRef.current.select.clearValue();
    setPrintCategoryList(null);
    const items = category.items.map((item) => {
      return { ...item, printCategory: null };
    });
    saveItems({ items, menuId: menuId, categoryId: category.id });
  };


  const onClearPromotionTags = () => {
    selectPrintCategoryRef.current.select.clearValue();
    setPromotionTags(null);

    const items = category.items.map((item) => {
      return { ...item, promotionTags: [] };
    });
    saveItems({
      items,
      menuId: menuId,
      categoryId: category.id,
      promotionTags: [],
    });
  };
  const handlePromotionTagsChange = (selectedOptions) => {
    console.log("handlePromotionTagsChange", selectedOptions);
    setPromotionTags(selectedOptions);

    const existingPromotionTags = category.promotionTags
      ? category.promotionTags
      : [];
    const newPromotionTags = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    const items = category.items.map((item) => {
      // remove old promotion tags and add new promotion tags
      const itemPromotionTags = item.promotionTags ? item.promotionTags : [];
      const allPromotionTags = itemPromotionTags.filter(
        (tag) => !existingPromotionTags.includes(tag)
      );
      allPromotionTags.push(...newPromotionTags);
      // remove duplicates
      const uniquePromotionTags = [...new Set(allPromotionTags)];
      return { ...item, promotionTags: uniquePromotionTags };
    });
    saveItems({
      items,
      menuId: menuId,
      categoryId: category.id,
      promotionTags: newPromotionTags,
    });
    const existingMenuPromotionTags = menu.promotionTags
      ? menu.promotionTags
      : [];
    existingMenuPromotionTags.push(...newPromotionTags);
    const allMenuPromotionTags = [...new Set(existingMenuPromotionTags)];
    updateTheMenu({ ...menu, promotionTags: allMenuPromotionTags }, menuId);
  };

  const handleRemoveCookingCategories = () => {
    const items = category.items.map((item) => {
      return { ...item, cookingCategory: null };
    });
    saveItems({ items, menuId: menuId, categoryId: category.id });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Category Options</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Grid
                container
                direction="column"
                className={classes.root}
                spacing={3}
              >
                <Grid item>
                  <Typography gutterBottom variant="h5">
                    Promotion Tags
                  </Typography>
                  <CreatableSelect
                  ref={selectPromotionTagsRef}
                    isMulti
                    value={promotionTags}
                    onChange={handlePromotionTagsChange}
                    options={getPromotionTags()}
                  />
                     <Button
                    size="small"
                    startIcon={<ClearIcon />}
                    variant="outlined"
                    onClick={onClearPromotionTags}
                    style={{ marginTop: 1 }}
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid item xs={12} container direction="column">
                  <MenuVisibility
                    visibilityObj={category}
                    category={category}
                  />
                </Grid>

                {/* <Grid item>
                                    <FormControl variant="filled" className={classes.formControl}>
                                    <Typography gutterBottom variant="h5">KDS Cooking Category</Typography>
                                        
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="cookingCategory"
                                            value={cookingCategory}
                                            onChange={handleCookingCategoryChange}
                                        >
                                            <MenuItem value="None">
                                                <em>None</em>
                                            </MenuItem>
                                            {menu && menu.cookingCategories && menu.cookingCategories.map(category => {
                                                return <MenuItem key={category.id ? category.id : category} value={category.id ? category.name : category}>{category.name ? category.name : category}</MenuItem>
                                            })}
                                            
                                          
                                        </Select>
                                    </FormControl>

                                </Grid> */}
    <Divider />
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRemoveCookingCategories}
                  >
                    Remove Cooking Categories
                  </Button>
                </Grid>

                <Divider />
                <Grid item style={{ height: 200 }}>
                  <Typography gutterBottom variant="h5">
                    Print Category
                  </Typography>
                  {menu &&
                  menu.cookingCategories &&
                  menu.cookingCategories.length > 0 ? (
                    <ReactSelect
                      ref={selectPrintCategoryRef}
                      isMulti
                      defaultValue={printCategoryList}
                      options={cookingCategories()}
                      className="basic-multi-select"
                      onChange={handlePrintCategoryChange}
                      classNamePrefix="select Cooking category"
                    />
                  ) : null}
                  <Button
                    size="small"
                    startIcon={<ClearIcon />}
                    variant="outlined"
                    onClick={onClearPrintCategory}
                    style={{ marginTop: 1 }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CategoryOptions;
