import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RestaurantQRCode from './RestaurantQRCode';
import { getTableQrString } from 'store/actions/restaurant';
import Typography from '@material-ui/core/Typography';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useSelector, useDispatch } from 'react-redux';
import { showErrorSnackbar } from 'store/actions/snackbarMessage'
import QrCodeSettings from './QrCodeSettings';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const TableQRCode = (props) => {
  const { restaurantId, updateQRCodeSetting } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [tableNumber, setTableNumber] = useState('');
  const [item, setItem] = useState(null);
  const [paymentRequired, setPaymentRequired] = useState('notRequired');

  const tableQrCode = useSelector((state) => state.restaurant.tableQrCode);
  const getTheTableQrString = (num, isPaymentRequired) => dispatch(getTableQrString(num, isPaymentRequired));
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  useEffect(() => {
    if (tableQrCode) {
      setItem({ name: tableNumber, code: tableQrCode });
    }

  }, [tableQrCode]);

  const handGetTableQrString = () => {
    if (tableNumber.trim() === '') {
      showError('The table number should only include letters and numbers');
      return;
    }
    if (tableNumber.indexOf(' ') > -1 ||
      tableNumber.indexOf('?') > -1 ||
      tableNumber.indexOf('&') > -1 ||
      tableNumber.indexOf('$') > -1 ||
      tableNumber.indexOf(';') > -1) {
      showError('The table number should only include letters and numbers');
      return;
    }
    getTheTableQrString(tableNumber, paymentRequired);
  }

  const updateTableNumber = (value) => {
    setTableNumber(value);
    setItem(null);

  };

  const handleChange = (event) => {
    setPaymentRequired(event.target.value);
  };

  return (
    <div className={classes.root}>

      <Grid container spacing={3} direction="column">
        <Grid item >
          <Paper className={classes.paper}>
            <Typography variant="h5" >Dine in QR Code</Typography>

            <QrCodeSettings name={'dinein'} updateQRCodeSetting= {updateQRCodeSetting}/>

            <TextField id="outlined-basic"
              value={tableNumber}
              onChange={e => updateTableNumber(e.target.value)}
              label="Table Number" variant="outlined" />

            <FormControl component="fieldset" style={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}>
              <FormLabel component="legend">Online Payment Option</FormLabel>
              <RadioGroup value={paymentRequired} aria-label="payment choice" name="paymentOption" onChange={handleChange}>
                <FormControlLabel value="notRequired" control={<Radio />} label="Not Required" />
                <FormControlLabel value="required" control={<Radio />} label="Required" />
                {/* <FormControlLabel value="optional" control={<Radio />} label="Optional" /> */}
              </RadioGroup>
            </FormControl>


            <Button variant="outlined" color="primary" onClick={handGetTableQrString}>
              OK
            </Button>
          </Paper>
        </Grid>
        <Grid item>
          {tableNumber !== '' && item ?
            <RestaurantQRCode restaurantId={restaurantId} item={item} tableNumber={tableNumber} />
            : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default TableQRCode;
