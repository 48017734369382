import API from 'util/API';
import constants from 'util/constants';

export const logError= async ({title, message}) => {
  try {
    const currentUrl = window.location.href ? window.location.href : "";
   await API.post(`/utils/logError`, {title, message: `${message}, version:${constants.WEBSITE_VERSION}, url: ${currentUrl}`} );
  } catch (error) {
    console.log("error", error);
  }
};



