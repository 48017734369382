import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import API from "util/API";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LinearProgress from "@material-ui/core/LinearProgress";

import Button from "@material-ui/core/Button";

import moment from 'moment';
// import indigo from '@material-ui/core/colors/indigo';

import {
  
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import {  useDispatch } from "react-redux";

import { useConfirm } from "material-ui-confirm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "80ch",
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  progress: {
    position: "absolute",
  },
}));

const AccountSubscription = ({ subscription }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const [uiLoading, setUiLoading] = useState(false);
  const dispatch = useDispatch();
  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  const doSubscriptionCancel = async (subscriptionId) => {
    try {
      setUiLoading(true);
      const response = await API.post("/cancelSubscription", {
        subscriptionId,
      });

      if (response && response.data) {
      }
      setUiLoading(false);
    } catch (err) {
      setUiLoading(false);
      console.log(err);
      showError("Cannot cancel this subscription");
    }
  };

  const getSubscriptionInvoice = async (subscriptionId, customer, priceId) => {
    try {
      setUiLoading(true);
      const response = await API.get(`/subscriptionInvoice?subscriptionId=${subscriptionId}&customer=${customer}&priceId=${priceId}`);

      if (response && response.data) {

      }
      setUiLoading(false);
    } catch (err) {
      setUiLoading(false);
      console.log(err);
      showError("Cannot cancel this subscription");
    }
  };

  const cancelSubcription = (subscriptionId) => {
    confirm({
      description:
        "You are about to cancel this subscription and you are not able to use this service!",
    })
      .then(() => {
        doSubscriptionCancel(subscriptionId);
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <FastfoodIcon />
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
            <Typography variant="h5">{
            // <a
            //   href={`${ process.env.REACT_APP_STRIPE_SUBSCRIBTION_URL}/subscriptions/${subscription.id}`}
            // >
              `${subscription.product.name}`
            // </a>
          }
          <br/>
          {`$${subscription.plan.amount / 100} / ${subscription.plan.interval}`}
            </Typography>
            
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
              style={{whiteSpace: 'normal'}}
                component="span"
                variant="body1"
                className={classes.inline}
             
             
              >
                Status: {subscription.status}
                <br />
                Current period start:{" "}
              {moment(new Date(subscription.current_period_start * 1000)).format('DD/MM/YYYY')}
              
              </Typography>
            
            </React.Fragment>
          }
        />
        {/* <ListItemSecondaryAction>
          {subscription.status === "active" ? (
            <>
              <Button
                disabled={uiLoading}
                variant="outline"
                onClick={(e) => getSubscriptionInvoice(subscription.id, subscription.customer, subscription.plan.id)}
              >
                get invoice
              </Button>
              {uiLoading && (
                <CircularProgress size={20} className={classes.progess} />
              )}
            </>
          ) : null}
        </ListItemSecondaryAction> */}
      </ListItem>

      <Divider variant="inset" component="li" />
    </>
  );
};

const Account = () => {
  const classes = useStyles();
  const [subscriptions, setSubscriptions] = useState([]);
  const [uiLoading, setUiLoading] = useState(false);
  const dispatch = useDispatch();
  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setUiLoading(true);
        const response = await API.get("/subscriptions");

        if (response && response.data && response.data.subscriptions) {
          // console.log(
          //   "response.data.subscriptions.data",
          //   JSON.stringify(response.data.subscriptions.data)
          // );
          setSubscriptions(response.data.subscriptions);
        }
        setUiLoading(false);
      } catch (err) {
        setUiLoading(false);
        console.log(err);
        showError("Cannot get products");
      }
    };
    fetchData();
  }, []);

  if (!subscriptions) {
    return "";
  }
  
  return (
    <div className={classes.root}>
      <Typography variant="h3">Account Subscriptions</Typography>
      {uiLoading ? <LinearProgress /> : null}
      <br/>

     <NavLink to="/prices"><Button  variant="outlined" size="large" color="primary"> Add a subscription </Button> </NavLink>
     <br/>
     <br/>
      <Typography variant="h4">Subscriptions</Typography>

      <div id="subscriptions">
        <List dense={true}>
          {subscriptions
            .map((s) => {
              return <AccountSubscription key={s.id} subscription={s} />;
            })}
        </List>
      </div>
    </div>
  );
};

export default withRouter(Account);
