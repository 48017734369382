export const ADD_DISH_TO_CART = 'ADD_DISH_TO_CART';
export const REMOVE_DISH_FROM_CART = 'REMOVE_DISH_FROM_CART';
export const SET_RESTAURANT_ID = 'SET_RESTAURANT_ID';
export const EMPTY_CART = 'EMPTY_CART';
export const ADD_OPTION = 'ADD_OPTION';
export const LOAD_CART = 'LOAD_CART';
export const INCREASE_DISH_QUANTITY = 'INCREASE_DISH_QUANTITY';
export const SAVE_USER = 'SAVE_USER';
export const LOAD_USER = 'LOAD_USER';
export const UPDATE_PAYMENT_TYPE = 'UPDATE_PAYMENT_TYPE';

export const addDishToCart = dish => ({
  type: ADD_DISH_TO_CART,
  payload: dish
});

export const removeDishFromCart = dishId => ({
  type: REMOVE_DISH_FROM_CART,
  payload: dishId
});

export const setRestaurantId = restaurant => ({
  type: SET_RESTAURANT_ID,
  payload: restaurant
});

export const emptyCart = (order) => ({
  type: EMPTY_CART,
  payload: order
});

export const addOption = option => ({
  type: ADD_OPTION,
  payload: option
});

export const loadCart = (restaurant) => ({
  type: LOAD_CART,
  payload: restaurant
});

export const LOAD_ORDERS_SUMMARY = 'LOAD_ORDER_SUMMARY';
export const loadOrdersSummary = (restaurant) => ({
  type: LOAD_ORDERS_SUMMARY,
  payload: restaurant
});

export const increaseDishQty = (payload) => ({
  type: INCREASE_DISH_QUANTITY,
  payload
}); 

export const saveUser = (user) => ({
  type: SAVE_USER,
  payload: user
}); 

export const loadUser = () => ({
  type: LOAD_USER
}); 

export const updatePaymentType = (type) => ({
  type: UPDATE_PAYMENT_TYPE,
  payload: type
}); 


export const UPDATE_DISH_POSITION = 'UPDATE_DISH_POSITION';
export const updateDishPosition = (position) => ({
  type: UPDATE_DISH_POSITION,
  payload: position
}); 


export const UPDATE_DISH_TAKEAWAY = 'UPDATE_DISH_TAKEAWAY';
export const updateDishTakeaway = (id, isTakeaway) => ({
  type: UPDATE_DISH_TAKEAWAY,
  payload: {id, isTakeaway }
}); 

export const LOAD_PREVIOUS_CART = 'LOAD_PREVIOUS_CART';
export const loadPreviousCart = (restaurantId) => ({
  type: LOAD_PREVIOUS_CART,
  payload: restaurantId
});

export const UPDATE_TILL_PAY_URL = 'UPDATE_TILL_PAY_URL';
export const updateTillPaymentUrl = (url) => ({
  type: UPDATE_TILL_PAY_URL,
  payload: url
});


export const UPDATE_MEMBER_ID = 'UPDATE_MEMBER_ID';
export const updateMemberId = (memberId) => ({
  type: UPDATE_MEMBER_ID,
  payload: memberId
});

export const UPDATE_ADYEN_SESSION = 'UPDATE_ADYEN_SESSION';
export const updateAdyen = (sessaonData) => ({
  type: UPDATE_ADYEN_SESSION,
  payload: sessaonData
});

