import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import TableRow from "@material-ui/core/TableRow";

import RemoveIcon from "@material-ui/icons/Remove";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";


import IconButton from "@material-ui/core/IconButton";
import { useConfirm } from 'material-ui-confirm';

import { useDispatch } from "react-redux";
import { removeDishFromCart, updateDishTakeaway, increaseDishQty } from "store/actions/order";
import MuiTableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Switch from "@material-ui/core/Switch";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  itemText: {
    fontSize: 14,
  },
  row: {
    paddingBottom: 50,
  },
  fabDecrease: {
    width: 30,
    height: 30,
  },
}));

const getOptionString = (orderItem) => {
  let descList = [];
  if (orderItem.size) {
    descList.push(orderItem.size.name.split("#")[0].trim());
  }

  const optionDescList = orderItem.options.map((option) => {
    let quantityStr = "";
    if (option.quantity > 1) {
      quantityStr = " " + option.quantity + "x";
    }
    return option.name + quantityStr;
  });

  if (optionDescList.length > 0) {
    descList.push(...optionDescList);
  }

  if (
    orderItem.specialInstructions &&
    orderItem.specialInstructions.trim() !== ""
  ) {
    descList.push(orderItem.specialInstructions);
  }

  if (descList.length > 0) {
    return descList.join(",");
  } else {
    return "";
  }
};

const CartItem = (props) => {
  const { item, canDishesTakeaway, currencySymbol } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const updateQunaityDish = (itemId, change) => dispatch(increaseDishQty({id: itemId, change}));
  const removeDish = (itemId) => dispatch(removeDishFromCart(itemId));
  const updateTakeaway = (id, value) => dispatch(updateDishTakeaway(id, value));
  const confirm = useConfirm();


  const updateQuanity = (change) => {
    const id = item.id;
    if (Number(item.quantity) + Number(change) === 0) {
      confirm({ description: 'You are about to delete this item!' })
      .then(() => {
        removeDish(id);  
      })
      .catch(() => { /* ... */ });
     
    } else {
      updateQunaityDish(id, change);
    }
    
  }

  const increaseQuantity = () => {
    updateQuanity(1);
  };

  const decreaseQuantity = () => {
    updateQuanity(-1);
  };
 

  const getItemPrice = () => {
    return (item.subTotal * item.quantity).toFixed(2).toString();
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "isForTakeaway") {
      console.log("isForTakeaway");
      updateTakeaway(item.id, value);
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.row}>
        <TableCell
          align="left"
          className={classes.itemText}
          paddingNone
          sizeSmall
        >
          {item.quantity}x
        </TableCell>
        <TableCell
          align="left"
          paddingNone
          sizeSmall
          className={classes.itemText}
        >
          {`${item.dish.name}${item.isTakeaway === true ? "(takeaway)" : ""}`}
          <Typography color="textSecondary" className={classes.itemText}>
            {item.dish.altName
              ? item.dish.altName
              : item.dish.description}
          </Typography>
          <Typography variant="body2" className={classes.itemText}>
            {getOptionString(props.item)}
          </Typography>
        </TableCell>
        <TableCell
          alignLeft
          sizeSmall
          className={classes.itemText}
        >{`${currencySymbol}${getItemPrice()}`}</TableCell>
        <TableCell alignLeft>
          {canDishesTakeaway === true && item.dish.isTakeawayDisabled !== true ? (
            <>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={item.isTakeaway}
                    onChange={handleSwitchChange}
                    name="isForTakeaway"
                    color="primary"
                  />
                }
                label=""
              />

              <Typography>takeaway</Typography>
            </>
          ) : null}{" "}
          
          

          <Grid container direction="column" justify="space-between">
          <IconButton
            aria-label="decrease quantity"
            className={classes.margin}
                onClick={decreaseQuantity}
              >
                <RemoveIcon  size="small" />
              </IconButton>
            
              <IconButton
                color="primary"
                aria-label="increase quantity"
                size="small"
                onClick={increaseQuantity}
              >
                <AddIcon  size="small" />
              </IconButton>
            </Grid>

          {/* <IconButton
            aria-label="decrease quantity"
            className={classes.margin}
            onClick={updateQuanity.bind(null, -1)}
          >
            <ExposureNeg1Icon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="increase quantity"
            className={classes.margin}
            onClick={updateQuanity.bind(null, 1)}
          >
            <AddCircleOutlineRoundedIcon fontSize="small" />
          </IconButton> */}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default CartItem;
