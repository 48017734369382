import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/styles";
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';

import { showErrorSnackbar } from 'store/actions/snackbarMessage'
import { useSelector, useDispatch } from 'react-redux';

import API from 'util/API';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

 const AddRestaurantsDialog = (props) => {
  const classes = useStyles();
  const {open, selectedMenus, updateSelectedMenu} = props;
  const dispatch = useDispatch();
  const [options, setOtions] = useState([]);
  const [value, setValue] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [uiLoading, setUiLoading] = useState(false);
  
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  const selectedUserIds = useSelector((state) => state.account.selectedUserIds);
  const accounts = useSelector((state) => state.account.accounts);

  const handleClose = () => {
    props.closeHandler();
  };

 const getAllRestrauntIds = async () => {

    const result = await API.get('restaurantIds');
    await setOtions(result.data)
     
 };

 const updateRestaurantGroup = async () => {
      const payload = {
         restaurants,
         username: currentAccount.username
      };
      try {
        const result = await API.put('/updateRestaurantGroup', payload);
        setUiLoading(false);
        handleClose();
      } catch (err) {
        setUiLoading(false);
         console.error(err);
         showErrorMessage('Something went wrong, please try again.')
         handleClose();
      }
 };

 const addRestaurantIdToUser = () => {
   console.log('currentAccount', currentAccount);
   console.log('restaurantIds', restaurants);
    if (!currentAccount) {
      showErrorMessage('Please select a restaurant group admin account.')
      return;
    }
    if (currentAccount.role !== 'restaurantGroupAdmin') {
      showErrorMessage('Please select a restaurant group admin account.')
      return;
    }

    if (restaurants.length === 0) {
      showErrorMessage('Please add a restaurant.')
      return;
    }
    setUiLoading(true);
    updateRestaurantGroup();
    

  };

 useEffect(() => {
     getAllRestrauntIds();
 }, [props])

 useEffect(() => {
  console.log('selectedUserIds', selectedUserIds);
   if (selectedUserIds.length !== 1) {
     console.error('no ids');
     return;
   } 
   const selectedUsers = accounts.filter(user => user.username === selectedUserIds[0]);
   console.log('selectedUsers', selectedUsers);
   if (selectedUsers.length !== 1) {
    console.error('no users');
     return;
   }

   setCurrentAccount(selectedUsers[0]);
   if (selectedUsers[0].restaurants) {
     setRestaurants(selectedUsers[0].restaurants);
   }

 }, [selectedUserIds, accounts]);

 const deleteRestaurant = (deleteRestaurant) => {
    setRestaurants(restaurants.filter(restaurant => restaurant.restaurantId !== deleteRestaurant.restaurantId));
 };

  return (
    <div>
    
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select a restaurant to add</DialogTitle>
        <DialogContent>

        <div className={classes.demo}>
            <List dense={false}>
              {restaurants.map(restaurant =>  (
                <ListItem key={restaurant}>
               
                  <ListItemText primary={restaurant.name}/>
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={e => deleteRestaurant(restaurant)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                )
              )}
            </List>
          </div>
        
        <Autocomplete
      id="restaurantId"
      options={options}
      getOptionLabel={(option) => option.name}
      style={{ minWidth: 300 }}

      value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (newValue) {
            setRestaurants([...restaurants, newValue]);
          }
        }}
  
      renderInput={(params) => <TextField {...params} label="Restaurant" variant="outlined" />}
    />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={addRestaurantIdToUser} 
          disabled={uiLoading}
           color="primary">
          Add
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddRestaurantsDialog;
