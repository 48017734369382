import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import EditIcon from '@material-ui/icons/Edit';
import 'views/Common//App.css';
import { useSelector } from "react-redux";

import TableCell from '@material-ui/core/TableCell';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '90%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    row: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f7f7f7',
        },
    },
    nameHeader: {
        flex: 3,
      },
      priceHeader: {
        flex: 2,
      },
      actionHeader: {
        flex: 1,
        padding: '6px',
      },
}));

const Option = (props) => {
    const { option, deleteOption, copyOption, editOption } = props;
    const classes = useStyles();
    const categories = useSelector(
        (state) => state.restaurantMenu.menuCategories
      );

    const deleteOptionHandle = () => {
        deleteOption(option.name)
    }



    const handleOptionCopy = () => {
        if (!option) {
            console.error('option is missing', option);
            return;
        }

        copyOption(option);
    };

    const handleOptionEdit = () => {
        editOption(option);
    };

    const checkIfOptionLinkIsBroken = (menuItemId) => {
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];
            for (let j = 0; j < category.items.length; j++) {
                const item = category.items[j];
                if (item.id === menuItemId) {
                    return false;
                }
               
            }
        }
        return true;
    }

    const getLinkLabel = (checkOption) => {
        if (!checkOption) {
            return '';
        }
        if (!checkOption.menuItemId) {
            return '';
        }
        if (checkIfOptionLinkIsBroken(checkOption.menuItemId)) {
            return '(broken link)';
        }
        if (checkOption.category && checkOption.category !== '') {
            return `(link - ${checkOption.category})`;
        }
        return '(link)';
    }

    return (
<>
       
<TableCell className={classes.nameHeader}>
        {option.colorHex ? (
            <span className="selected-color" style={{ backgroundColor: option.colorHex }}>
                {`${option.name}${getLinkLabel(option)}`}
            </span>
        ) : (
            `${option.name}${getLinkLabel(option)}`
        )}
        <Box>
            {option.hideForWeb === true ? <VisibilityOffIcon /> : null}
        </Box>
    </TableCell>

    <TableCell className={classes.priceHeader} align="right">
        ${option.price}
    </TableCell>

    <TableCell className={classes.actionHeader} align="right">
        <IconButton aria-label="eidt" className={classes.margin} edge="end" color="secondary" onClick={handleOptionEdit}>
            <EditIcon fontSize="small" />
        </IconButton>
    </TableCell>

    <TableCell className={classes.actionHeader} align="right">
        <IconButton aria-label="eidt" className={classes.margin} edge="end" color="secondary" onClick={handleOptionCopy}>
            <FileCopyIcon fontSize="small" />
        </IconButton>
    </TableCell>

    <TableCell className={classes.actionHeader} align="right">
        <IconButton onClick={deleteOptionHandle}>
            <DeleteIcon />
        </IconButton>
    </TableCell>
    </>
    );
};

export default Option;