import API from 'util/API';
import moment from "moment";
import toast from 'react-hot-toast'

export const getStaffList = async () => {
  const response = await API.get('/staffList');
  if (response && response.data && response.data.staffList) {
    return response.data.staffList;
  }
  return [];
};


export const upsertTimesheet = async (timesheet) => {
  return await API.post('/timesheet', timesheet);
};



export const getTimesheetList = async (startDate, endDate, processTimesheets) => {
  // validate startDateStr and endDateStr to make sure endDateStr > startDateStr

  if (!startDate || !endDate) {
    toast.error("Please select a date range");
    return [];
  }

  if (endDate < startDate) {
    toast.error("End date must be greater than start date");
    return [];
  }

  const startDateStr = moment(startDate).format("DD-MM-YY");
  const endDateStr = moment(endDate).format("DD-MM-YY");


  const response = await API.get(`/timesheets?startDateStr=${startDateStr}&endDateStr=${endDateStr}`);
  if (response && response.data && response.data.timesheets && processTimesheets) {
    return processTimesheets(response.data.timesheets);
  }
  return [];
};


export const deleteTimesheet = async ({dateStr, id}) => {
  console.log("deleteTimesheet", dateStr, id);
  return await API.put(
    `/timesheet/${id}`, {
      dateStr,
    }
  );

};