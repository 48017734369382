import React,  { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import constants from 'util/constants';
import { authMiddleWare } from 'util/auth'
import  TransactionAdjustments  from './TransactionAdjustments';

import API from 'util/API';



const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

 const TransactionPayments = (props) => {
  const {transaction} = props;
  const classes = useStyles();
  const history = useHistory();
  const [orderTransactions, seOrderTransactions] = useState(null);
  const order = useSelector((state) => state.restaurant.selectedOrder);

  const orderCreateTime = () => {
    if ('_seconds' in order.createdAt) {
      return  moment(new Date(order.createdAt._seconds * 1000)).format('YYYY-MM-DD')
    }
    return moment(order.createdAt).format('YYYY-MM-DD')
  };


  const fectchOrderTransactions = () => {
    if (!order) {
      return;
    }
    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;
  
    API
      .get('/orderTransactions?dateStr=' + orderCreateTime()  + "&id=" + order.uuid)
      .then((response) => {
        console.log('response.data', response.data);
        //loadTodayOrders(response.data)
        if (response.data) {
          seOrderTransactions(response.data);
        }
    
      })
      .catch((error) => {
        console.log(error);
  
        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }
      });
  };

  useEffect(() => {
   // fectchOrderTransactions();
  
  }, [props, order]);
  
  const paymentTime = (time) => {
    if ('_seconds' in time) {
      return  moment(new Date(time._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')
    }
    return moment(time).format('DD/MM/YYYY HH:mm:ss')
  };

  return (
    <React.Fragment>
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Payment Type </TableCell>
            <TableCell align="right">Pay Amount</TableCell>
            <TableCell align="right">Time</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {transaction && transaction.paymentTypes.map((row) => (
            <TableRow key={row.type}>
              <TableCell component="th" scope="row">
                {row.type}
              </TableCell>
              <TableCell align="right">${row.paymentAmount.toFixed(2)}</TableCell>
              <TableCell align="right">{paymentTime(row.time)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TransactionAdjustments  transaction={transaction} />

    </React.Fragment>
  );
};

export default TransactionPayments;
