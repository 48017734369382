import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TableHead from '@material-ui/core/TableHead';
import LinearProgress from '@material-ui/core/LinearProgress';


import { useHistory } from 'react-router-dom';


import { authMiddleWare } from 'util/auth'


const useStyles = makeStyles({
  table: {
    
  },
});


const OrderRefunds = (props) => {
  const { refund } = props;
  const classes = useStyles();
  const history = useHistory();
  const [buttonLoading] = useState(false);

  useEffect(() => {
    authMiddleWare(history);

  }, [props, history]);


  return (
    <React.Fragment>
      {buttonLoading ?
                <LinearProgress /> : null}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableCell colSpan="2">Refund Details</TableCell>
           
          </TableHead>
          <TableBody>
              { refund && refund.map(item => 
           (
               <>
           <TableRow>
              <TableCell>Refund Amount: </TableCell>
              <TableCell align="left">{item.amount && `$${(item.amount / 100).toFixed(2)}`}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Currency: </TableCell>
              <TableCell align="left">{item.currency}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Refund Id: </TableCell>
              <TableCell align="left">{item.partner_refund_id}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Result code: </TableCell>
              <TableCell align="left">{item.result_code}</TableCell>
            </TableRow>


            <TableRow>
              <TableCell>Return Code: </TableCell>
              <TableCell align="left">{item.return_code}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan="2"></TableCell>
            </TableRow>
            </>
            ))}
           

          </TableBody>
        </Table>
      </TableContainer>

    </React.Fragment>
  );
};

export default OrderRefunds;
