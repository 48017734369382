import { logError } from "apis/utilAPIs";
import React from "react";
import {  withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    marginBottom: theme.spacing(1),
  },
  message: {
    marginBottom: theme.spacing(2),
  },
});

 class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, info) {
    console.log("error", error);
    this.setState({ hasError: true, error, info });
    try {
      console.error("Error caught by boundary:", error, info);
      //get current url
      
      const errorData = {
        title: `Order Component Error: `,
        message: `Error caught by boundary error  info: ${JSON.stringify(info && info.componentStack ? info.componentStack : info)}`,
      };
      logError(errorData);
    } catch (error) {
      console.log("error", error);
    }
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { fallbackComponent: FallbackComponent, children } = this.props;
    const { classes } = this.props;

    if (hasError) {
      // Render the fallback UI provided by the parent component
      if (FallbackComponent) {
        return <FallbackComponent error={error} errorInfo={errorInfo} />;
      }
      // If no fallback component is provided, render a default error message and reload button to reload the page

      return (
        <div className={classes.root}>
          <Typography variant="h6" className={classes.message}>
            An error has occurred. Please try reloading the page.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
          >
            Reload
          </Button>
        </div>
      );
    }

    // If no error has occurred, render the children as normal
    return children;
  }
}

export default withStyles(styles, { withTheme: true })(ErrorBoundary);