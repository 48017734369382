import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


import RefundAmount from "../OrderPayments/RefundAmount";

import API from "util/API";

import { checkOrderRefundPermission } from "util/userUtils";
import { showErrorSnackbar } from 'store/actions/snackbarMessage'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const OrderWechantPayments = (props) => {
  const { payment, order } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.account.user);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));





  const doRefundAmount = (amount) => {
    // console.log("refundalling");
    if (!payment || !order) {
      console.log("payment or order is nil");
      return;
    }

    const formRequest = {
      reference: payment.orderNo,
      refundAmount: Number(amount),
      refundFee: Number(payment.surchargeAmount),
      payment: payment,
    };

    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;

    setButtonLoading(true);
    API.post("/onlinePaymentRefund", formRequest)
      .then((response) => {
        console.log(response.data);
        setButtonLoading(false);
        history.replace("/orders");
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
        // setOpenErrorSnackbar(true)
      });
  };

  const refundAll = () => {
    if (payment.orderAmount) {
      doRefundAmount(payment.orderAmount);
    } else {
      console.log("payment total amount is nil");
      showError("Payment total amount is nil");
    }

  };

  const refundAmount = () => {
    setOpen(true);
  };

  const isPaymentSuccess = (payment) => {
   if (!payment) {
      return false;
    
   }
    return payment.orderStatus === "Success";
  }; 


  return (
    <React.Fragment>
      {buttonLoading ? <LinearProgress /> : null}
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableCell>Payment Details</TableCell>
            <TableCell align="right">
              {user && checkOrderRefundPermission(user) && isPaymentSuccess(payment)? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={refundAll}
                    disabled={
                      buttonLoading ||
                      !payment
                    }
                  >
                    Refund All
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={refundAmount}
                    disabled={
                      buttonLoading ||
                      !payment 
                    }
                  >
                    Refund Amount
                  </Button>
                </>
              ) : null}
              <RefundAmount
                open={open}
                total={payment.totalAmount}
                proxy={doRefundAmount}
              />
            </TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Payment Result: </TableCell>
              <TableCell align="left">{isPaymentSuccess(payment) ? 'Payment Success' : 'Payment Failure'}</TableCell>
            </TableRow>

          
        
 

                <TableRow>
                  <TableCell>Merchant reference </TableCell>
                  <TableCell align="left">
                    {payment.merchantOrderNo}
                  </TableCell>
                </TableRow>
             
           
     

            <TableRow>
              <TableCell>Currency: </TableCell>
              <TableCell align="left">{payment.orderCurrency}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total Amount: </TableCell>
              <TableCell align="left">
                ${(payment.totalAmount / 100).toFixed(2)}
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default OrderWechantPayments;
