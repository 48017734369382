import React, { useState, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useConfirm } from 'material-ui-confirm';
import { NavLink } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Chip,
  Typography,
  TablePagination,
} from "@material-ui/core";


import { updateSelectedUserIds } from "store/actions/account";
import { useDispatch, useSelector } from "react-redux";
import ListContext from '../../ListContext';
import SurchargeWeekday from "./SurchargeWeekday";
import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const SurchargeTable = (props) => {
  const { className, surcharges, assignTerminal, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [selectedItems, setSelectedItems] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const updateUserIds = (ids) => dispatch(updateSelectedUserIds(ids));
  const { setSelectedItemId } = useContext(ListContext);
  const theme = useTheme();

  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const user = useSelector((state) => state.account.user);

  const handleClickTerminal = (terminal) => {
    // setSelectedTerminal(terminal);
    // setOpenAssignDialog(true);
    //show confirm dialog
    //if yes, call assign API

    confirm({ description: `You are about to assign this terminal (${terminal}) to the merchant (${user.restaurantId})` })
    .then(() => {
      assignTerminal(terminal);
    })
    .catch(() => { /* ... */ });

  };

  const handleCloseAssignDialog = () => {
    setOpenAssignDialog(false);
  };

  const handleAssign = async () => {
    // Call your assign API here
    // For example: await assignTerminalToStore(selectedTerminal, selectedStore);
    setOpenAssignDialog(false);
  };

  

  const handleSelectAll = (event) => {
    

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = surcharges.map((user) => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedItems(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedItems(newSelectedUsers);
    if(newSelectedUsers.length === 1) {
      setSelectedItemId(newSelectedUsers[0]);
    } else {
      setSelectedItemId(null);
    }
    // setSelectedItemId(id);
    // updateUserIds(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  // const filterStores = () => {
  //   if (!searchText || searchText === null || searchText.trim() === "") {
  //     return surcharges;
  //   }
  //   const searchTextLower = searchText.toLowerCase();

  //   return surcharges.filter(
  //     (item) =>
  //       (item.store && item.store.toLowerCase().includes(searchTextLower)) ||
  //       (item.inStoreTerminals && item.inStoreTerminals.map(terminal => terminal.toLowerCase).includes(searchTextLower)) 
  //   );
  // };

  const getTimeString = (date) => {
    if (typeof date === "object" && '_seconds' in date) {
      return moment(new Date(date._seconds * 1000)).format('DD/MM/YYYY')
    }
    return moment(date * 1000).format('DD/MM/YYYY HH:mm')
  };


  return (
    <Grid lg={12} md={12} xl={12} xs={12}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          {/* <PerfectScrollbar> */}
          <div className={classes.inner}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedItems.length === surcharges.length}
                        color="primary"
                        indeterminate={
                          selectedItems.length > 0 &&
                          selectedItems.length < surcharges.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Value</TableCell>
                   
                    <TableCell>Weekday</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {surcharges
                    .slice(page * rowsPerPage, rowsPerPage * page + rowsPerPage)
                    .map((surcharge, index) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={index}
                      
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedItems.indexOf(surcharge.id) !== -1
                            }
                            color="primary"
                            onChange={(event) =>
                              handleSelectOne(event, surcharge.id)
                            }
                            value="true"
                          />
                        </TableCell>
                        <TableCell>

                        <NavLink to={`/surcharge/${surcharge.id}`} >
                          <Typography className={classes.textLink} variant="body1">
                          {surcharge.name}
                          </Typography>
                          </NavLink>
                        </TableCell>

                        <TableCell>
                          {surcharge.isEnabled ? 'Yes': 'No'}
                        </TableCell>
                        <TableCell>
                        {surcharge.isPercentage ? surcharge.amount + '%': `$${surcharge.amount}`}
                          </TableCell>
                        
                      
                          
                        <TableCell>
                          {surcharge.weekdays && surcharge.weekdays.length > 0 ? (
                           <SurchargeWeekday weekdays={surcharge.weekdays} />
                          
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                {/* <Dialog open={openAssignDialog} onClose={handleCloseAssignDialog}>
        <DialogTitle>Assign Store</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              value={selectedStore}
              onChange={(event) => setSelectedStore(event.target.value)}
            >
              {stores.map((store) => (
                <FormControlLabel
                  key={store.store}
                  value={store.store}
                  control={<Radio />}
                  label={store.store}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAssignDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAssign} color="primary">
            Assign
          </Button>
        </DialogActions>
      </Dialog> */}
              </Table>
            </TableContainer>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={surcharges.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

SurchargeTable.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.array.isRequired,
};

export default SurchargeTable;
