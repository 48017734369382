import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { SearchInput } from 'components';

import { NavLink } from 'react-router-dom';
import { CSVLink } from "react-csv";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const OrdersToolbar = props => {
  const { className, customers, ...rest } = props;

  const classes = useStyles();

  const csvData = () => {
       
    let data = [];
    data.push(['Name', 'Phone', 'Email']);
   
    customers.forEach(customer => {

      
                data.push([customer.name, customer.phoneNumber, customer.email]);
            
            
      
    });
    console.log('data', data);
    return data;
       

};

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <IconButton aria-label="delete">
          <DeleteIcon />
        </IconButton>

 
{/* 
        <NavLink to={'/restaurant'}>
          <Button
            color="primary"
            variant="contained"
          >
            Add Restaurant
        </Button>
        </NavLink> */}
         <CSVLink  filename={"customers.csv"}
                    data={csvData()}><Typography>Download CSV</Typography></CSVLink> 
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search customer"
        />
      </div>
    </div>
  );
};

OrdersToolbar.propTypes = {
  className: PropTypes.string
};

export default OrdersToolbar;
