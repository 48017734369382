import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from "react-redux";
import { clearSnackbar } from "store/actions/snackbarMessage";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const MessageSnackbars = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { successSnackbarMessage, successSnackbarOpen , errorSnackbarOpen, errorSnackbarMessage} = useSelector(
    state => state.ui
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }



  return (
    <div className={classes.root}>
      <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
        {successSnackbarMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          { errorSnackbarMessage }
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MessageSnackbars;
