import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid } from "@material-ui/core";
import InputMask from "react-input-mask";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "-1rem 0 2rem 0",
    padding: "0 7rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0"
    },
    [theme.breakpoints.down("md")]: {
      padding: "0"
    },
    marginTop: "auto"
  },
  textField: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  errorMessage: {
    color: "red",
    fontSize: "0.9rem",
    marginTop: "0.2rem"
  }
}));

export default function Contact({ formProps: { register, errors }, data }) {
  const classes = useStyles();
  const { name, email, mobilePhone } = data[0];

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item md={12} xs={12}>
            <TextField
              id="name"
              label="Name"
              name="name"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              inputRef={register({
                required: "Please enter your name",
                pattern: {
                  value: /^[A-Za-z ]+$/,
                  message: "Please enter a valid name"
                }
              })}
              error={!!errors.name}
              defaultValue={name}
            />
            {errors.name && (
              <p className={classes.errorMessage}>{errors.name.message}</p>
            )}
          </Grid>
          <Grid item md={12} xs={12}>

          <TextField
              id="email"
              label="Email"
              name="email"
              type="email"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              inputRef={register({
                required: "Please enter your email",
                pattern: {
                  value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                  message: "please enter a valid email"
                }
              })}
              error={!!errors.email}
              defaultValue={email}
            />
            {errors.email && (
              <p className={classes.errorMessage}>{errors.email.message}</p>
            )} 
          </Grid>
          <Grid item md={12} xs={12}>
           
                <TextField
                  id="mobilePhone"
                  label="Mobile Phone"
                  name="mobilePhone"
                  type="number"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  inputRef={register({
                    required: "Please enter your mobile phone",
                  
                  })}
                  error={!!errors.mobilePhone}
                  defaultValue={mobilePhone}
                />
           
            {errors.mobilePhone && (
              <p className={classes.errorMessage}>{errors.mobilePhone.message}</p>
            )}
          </Grid>
          <Grid item md={12} xs={12}>
           
                <TextField
                  id="specialRequirement"
                  label="Special Requirements"
                  name="specialRequirement"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  inputRef={register({
                    
                    
                  })}
                />
          </Grid>

          
        </Grid>
      </div>
    </Fragment>
  );
}
