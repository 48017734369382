import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode.react';
import GetAppIcon from '@material-ui/icons/GetApp';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import mergeImages from 'merge-images';

import { useSelector } from 'react-redux';

import QrCodeSettings from './QrCodeSettings';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        height: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const RestaurantQRCode = (props) => {
    const classes = useStyles();
    
    const { restaurantId, item, tableNumber, updateQRCodeSetting } = props;
    // const [base64, setBase64] = useState(null);
    const restaurant = useSelector((state) => state.restaurant.selectedRestaurant);

    const getTemplateName = (name) => {
        if (!(['pickup-delivery', 'takeaway', 'pickup', 'delivery'].includes(name))) {
            return 'dinein';
        } else {
            return name;
        }
    };

    const merge = (id) => {
        const imageNamePrefix = getTemplateName(item.name);
        var resizedCanvas = document.createElement("canvas");
        var resizedContext = resizedCanvas.getContext("2d");
        let size = 602;
        let x = 326;
        let y = 378;
        if (imageNamePrefix === 'dinein') {
            size = 420;
            x = 262;
            y = 310;

        }
        resizedCanvas.height = size;
        resizedCanvas.width = size;
        const canvas = document.getElementById(id);
        resizedContext.drawImage(canvas, 0, 0, size, size);

        const pngUrl = resizedCanvas.toDataURL("image/png")


        mergeImages([{ src: `/static/images/${imageNamePrefix}-template.png`, x: 0, y: 0 }, { src: pngUrl, x: x, y: y }])
            .then(b64 => {
                let downloadCanvas = document.getElementById(`canvas-${item.name}`);
                downloadCanvas.setAttribute('id', `canvas-${item.name}`);
                if (imageNamePrefix === 'dinein') {
                    downloadCanvas.width = 946;
                    downloadCanvas.height = 1312;
                } else {
                    downloadCanvas.width = 1240;
                    downloadCanvas.height = 1754;
                }

                var ctx = downloadCanvas.getContext("2d");
                var image = new Image();

                image.src = b64;
                image.onload = function () {
                    let nameX = 473;
                    let nameY = 1530;
                    let altNameX = 429;
                    let altNameY = 1600;
                    let midX = 620;
                    ctx.drawImage(image, 0, 0);
                    if (tableNumber) {
                        midX = 473;
                        ctx.font = "60pt Calibri";
                        ctx.fillStyle = "#FFFFFF";
                        ctx.textAlign = "center";
                        ctx.fillText(tableNumber, 660, 926);

                        nameX = 427;
                        nameY = 1020;
                        altNameX = 427;
                        altNameY = 1080;

                    }

                    if (restaurant && restaurant.name && restaurant.name.trim() !== '') {
                        ctx.font = "40pt Calibri";
                        ctx.fillStyle = "#FFFFFF";
                        ctx.textBaseline = 'middle';
                        ctx.textAlign = "center";

                        ctx.fillText(restaurant.name, midX, nameY);


                    }

                    if (restaurant && restaurant.altName && restaurant.altName.trim() !== '') {
                        ctx.font = "40pt Calibri";
                        ctx.fillStyle = "#FFFFFF";
                        ctx.textBaseline = 'middle';
                        ctx.textAlign = "center";
                        ctx.fillText(restaurant.altName, midX, altNameY);

                    }


                    downloadQRType(`canvas-${item.name}`);
                };

                // setBase64(b64)
            });
    }

    const downloadPickupQR = () => {
        merge(item.name);
    };

    const downloadQRType = (id) => {
        console.log('id', id);
        const canvas = document.getElementById(id);
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${restaurantId}-${item.name}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const getQRCodeValue = (item) => {
        if (!restaurant) {
            return '';
        }
        if (item.name === 'booking' || item.name === 'queue') {
            return `https://${window.location.hostname}/${item.code}/${encodeURI(restaurantId)}`;
        } else {
            const urlString = `https://${window.location.hostname}/online/${encodeURI(restaurantId)}/${item.code}`;
            if (!restaurant.qrCodeTypes || restaurant.qrCodeTypes.length === 0) {
                return urlString;
            }
            const filterResult = restaurant.qrCodeTypes.filter((code) => {
                return code === item.name;
            });

            if (!filterResult || filterResult.length === 0) {
                return urlString;
            }
            const newRestaurantId = `${restaurantId}_${item.name}`;

            return `https://${window.location.hostname}/online/${encodeURI(newRestaurantId)}/${item.code}`;

        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

    return (
        <div className={classes.root}>
            <Grid>
                <Paper className={classes.paper} >
                    <Typography component="h5" variant="h5">
                        {capitalizeFirstLetter(item.name)} QR Code
                    </Typography>
                    <QrCodeSettings name={item.name} updateQRCodeSetting= {updateQRCodeSetting}/>

                    <div className={classes.controls}>
                        <IconButton aria-label="download" onClick={downloadPickupQR}>
                            <GetAppIcon /> <a > Download </a>
                        </IconButton>

                        <canvas id={`canvas-${item.name}`}></canvas>

                    </div>


                    <div>


                        <QRCode
                            id={item.name}
                            value={getQRCodeValue(item)}
                            size={280}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"H"}
                            includeMargin={true}
                            imageSettings={{
                                src: "/static/images/orderbuddy.jpeg",
                                x: null,
                                y: null,
                                height: 28,
                                width: 28,
                                excavate: true,
                            }}
                        />


                    </div>
                    <a href={getQRCodeValue(item)} target="_blank">{`Click here to open URL`}</a>

                </Paper>


            </Grid>
        </div>
    );
}


export default RestaurantQRCode;