import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import LinearProgress from "@material-ui/core/LinearProgress";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import clsx from "clsx";


import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { showErrorSnackbar, showSuccessSnackbar } from "store/actions/snackbarMessage";

import API from "util/API";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
}));

const Staff = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [staffNumber, setStaffNumber] = useState("");
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [role, setRole] = useState("");

  const history = useHistory();
  
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));

  const staffId = props.match.params.staffId;

  const getPermissionsAndNewStaffNumber = async () => {
    try {
      setButtonLoading(true);
      const response = await API.get(`/staffNumber`);
      if (
        response &&
        response.data &&
        response.data.permissions &&
        response.data.newStaffNumber
      ) {
        setPermissions(response.data.permissions);
        setStaffNumber(response.data.newStaffNumber);
      }
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      showError("Something went wrong! Please go back and try again.");
    }
  };

  const getStaffDetails = async () => {
    try {
      setButtonLoading(true);
      const response = await API.get(`/staff/${staffId}`);
      if (
        response &&
        response.data &&
        response.data.staff 
      ) {
        setSelectedStaff(response.data.staff);
        setStaffNumber(response.data.staff.staffNumber);
        setPermissions(response.data.permissions);
      }
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      showError("Something went wrong! Please go back and try again.");
    }
  };

  const createStaff = async () => {
    try {
      const staff = {
        firstName,
        lastName,
        email: email.toLowerCase(),
        phoneNumber,
        staffNumber,
        permissions: selectedPermissions,
        
      };

      setButtonLoading(true);
      const response = await API.post("/staff", { staff });
      history.push("/staffList");
      setButtonLoading(false);

    } catch (err) {
      setButtonLoading(false);
      showError("Something went wrong! Please go back and try again.");
    }
  };

  const updateStaff = async () => {
    try {
      const staff = {
        firstName,
        lastName,
        email: email.toLowerCase(),
        phoneNumber,
        staffNumber,
        permissions: selectedPermissions,
        
      };

      setButtonLoading(true);
      const response = await API.post(`/staff/${staffId}`, { staff });
      showSuccessMessage("Staff updated successfully!");
      history.push(`/staffList`);
      setButtonLoading(false);
      
    } catch (err) {
      setButtonLoading(false);
      showError("Something went wrong! Please go back and try again.");
    }
  };

  useEffect(() => {
    if (!staffId) {
      getPermissionsAndNewStaffNumber();
    } else {
      getStaffDetails()
    }
    
    
  }, [props]);

  useEffect(() => {
   
    if (selectedStaff) {
      setFirstName(selectedStaff.firstName);
      setLastName(selectedStaff.lastName);
      setEmail(selectedStaff.email);
      setPhoneNumber(selectedStaff.phoneNumber);
      setSelectedPermissions(selectedStaff.permissions);
      
    }
  }, [selectedStaff]);

  const updateFormValues = (event) => {
    event.preventDefault();


    if (!selectedStaff) {
      createStaff();
    } else {
      updateStaff();
    }
  };

  const handleTagChange = (event, code) => {
    const foundIndex = selectedPermissions.findIndex(
      (permission) => permission.code === code
    );
    if (foundIndex !== -1) {
   
        setSelectedPermissions(selectedPermissions.filter(permission => permission.code !== code));
    
    } else {
      const permission = permissions.find(
        (permission) => permission.code === code
      );
      if (permission) {
        console.log(permission);

        setSelectedPermissions([...selectedPermissions, permission]);
      }
    }
  };

  const isPermissionSelected = (code) => {
    // console.log(JSON.stringify(selectedPermissions));
    return selectedPermissions.find((permission) => permission.code === code);
  };

  return (
    <main className={classes.content}>
      {buttonLoading ? <LinearProgress /> : null}
      <Card className={clsx(classes.root, classes)}>
        <CardHeader title="Staff" subheader="Create or edit a new POS user" />
        <form autoComplete="off" noValidate>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  label="First name"
                  margin="dense"
                  name="firstName"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  label="Last name"
                  margin="dense"
                  name="lastName"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  margin="dense"
                  name="phone"
                  type="number"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography>Staff Number: {staffNumber}</Typography>
                
             
             
              </Grid>

              <Grid item md={3} xs={6}></Grid>

              {permissions.length > 0 ? (
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Permissions</FormLabel>
                    <FormGroup row>
                      {permissions.map((permission) => {
                        return (
                          <FormControlLabel
                            key={permission.code}
                            control={
                              <Checkbox
                                checked={isPermissionSelected(permission.code)}
                                onChange={(e) =>
                                  handleTagChange(e, permission.code)
                                }
                                name={permission.code}
                              />
                            }
                            label={permission.name}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions />
        </form>
      </Card>

      <Button
        color="primary"
        variant="contained"
        type="submit"
        className={classes.submitButton}
        onClick={updateFormValues}
        disabled={
          buttonLoading ||
          !firstName ||
          !lastName ||
          !email ||
          !phoneNumber ||
          !staffNumber
          
        }
      >
        Save Staff
        {buttonLoading && (
          <CircularProgress size={30} className={classes.progess} />
        )}
      </Button>
    </main>
  );
};

export default Staff;
