// create a new timesheet component
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  FormGroup,
  Box,
} from "@material-ui/core";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import DatePicker from "react-datepicker";
import FormLabel from "@material-ui/core/FormLabel";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker } from "@material-ui/pickers";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { useDispatch } from "react-redux";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { getStaffList, upsertTimesheet } from "apis/staffAPIs";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
  leftSpace: {
    marginLeft: theme.spacing(1),
  },
}));

const Timesheet = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [timesheetType, setTimesheetType] = useState("checkin");
  const [staffNumber, setStaffNumber] = useState("");
  const [timesheetId, setTimesheetId] = useState(null);
  const [clockTime, setClockTime] = useState(new Date());
  const [clockDate, setClockDate] = useState(new Date());
  const [buttonLoading, setButtonLoading] = useState(false);
  const [remarks, setRemarks] = useState("");
  const CHARACTER_LIMIT = 100;
  const history = useHistory();
  const theme = useTheme();

  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const handleTypeChange = (event) => {
    setTimesheetType(event.target.value);
  };

  const handleStaffChange = (event) => {
    console.log(event.target.value);
    setStaffNumber(event.target.value);
  };

  const queryClient = useQueryClient();

  const { isLoading, isError, data, error } = useQuery("staffList", getStaffList);


  const mutation = useMutation(upsertTimesheet, {
    onSuccess: () => {
      queryClient.invalidateQueries("timesheetList");
    },
  });

  const updateTimesheet = () => {
   // console.log("Updating timesheet", data, staffNumber);
    const staff = data.find((staff) => staff.staffNumber === Number(staffNumber));
    // format clockDate to dd-mm-yy using moment
    if (!staff) {
      showErrorMessage("Staff not found, please refresh the page.");
      return;
      
    }
    const clockDateStr = moment(clockDate).format("DD-MM-YY");
  
    const timesheet = {
      id: timesheetId,
      type: timesheetType,
      staffNumber: Number(staffNumber),
      clockTime: moment(clockTime).format("HH:mm"),
      clockDate: clockDateStr,
      remarks: remarks,
      firstName: staff.firstName,
      lastName: staff.lastName,
    };
    mutation.mutate(timesheet);
  };

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return <CircularProgress size={150} className={classes.uiProgess} />;
  }

  if (mutation.isSuccess) {
    showSuccessMessage("Timesheet updated successfully");
    history.push("/timesheets");
  }

  return (
    <main className={classes.content}>
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            {mutation.isError ? <div>An error occurred: {mutation.error.message}</div> : null}
            <Grid item md={6} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Timesheet Type</FormLabel>
                <RadioGroup
                  aria-label="timesheet type"
                  defaultValue="checkin"
                  name="radio-buttons-group"
                  row
                  onChange={handleTypeChange}
                >
                  <FormControlLabel
                    value="checkin"
                    control={<Radio checked={timesheetType === "checkin"} />}
                    label="checkin"
                  />
                  <FormControlLabel
                    value="checkout"
                    control={<Radio checked={timesheetType === "checkout"} />}
                    label="checkout"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Staff</FormLabel>
                <RadioGroup aria-label="choose staff" name="radio-buttons-group" row onChange={handleStaffChange}>
                  {data.map((staff) => (
                    <FormControlLabel
                      key={staff.staffNumber}
                      value={staff.staffNumber}
                      control={<Radio checked={staffNumber === String(staff.staffNumber)} />}
                      label={`${staff.firstName} ${staff.lastName}`}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>


            <Grid item md={6} xs={12}>
              <Typography style={{ margin: theme.spacing(1) }}>
                Clock Date
              </Typography>
              <DatePicker
                selected={clockDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setClockDate(date)}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormGroup row>
                <Box mb={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box mr={1}>
                      <Typography>Clock Time:</Typography>
                    </Box>
                    <TimePicker value={clockTime} name="openStart" onChange={setClockTime} />
                  </MuiPickersUtilsProvider>
                </Box>
              </FormGroup>
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                className={classes.bottomButtons}
                id="Remarks"
                label="Remarks"
                multiline
                rows={4}
                fullWidth
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
                value={remarks}
                variant="outlined"
                helperText={`${remarks.length}/${CHARACTER_LIMIT}`}
                onChange={(e) => setRemarks(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitButton}
                onClick={updateTimesheet}
                disabled={isLoading || mutation.isLoading || !staffNumber || !timesheetType || !clockTime}>
                Save Timesheet
                {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </main>
  );
};

Timesheet.propTypes = {};

export default Timesheet;
