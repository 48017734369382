import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';

import { SearchInput } from 'components';
import { useSelector, useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import {  showErrorSnackbar } from 'store/actions/snackbarMessage'
import { updateSelectedBookingSessionIds, setBookingSessionDetail } from 'store/actions/restaurant';
import API from 'util/API';
import { useConfirm } from 'material-ui-confirm';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const BookingSessionsToolbar = props => {
  const { className, ...rest } = props;
  const [uiLoading, setUiLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const user = useSelector((state) => state.account.user);
  const selectedSessionIds = useSelector((state) => state.restaurant.selectedBookingSessionIds);
  
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  const updateBookingSessionDetails = (session) => dispatch(setBookingSessionDetail(session));
 
  const updateBookingSessionIds = (ids) => dispatch(updateSelectedBookingSessionIds(ids));

  const classes = useStyles();

  const deleteSession = async () => {
    // console.log('selectedRestaurants', selectedSessionIds);
    
    setUiLoading(true);
    try {
    const response = await API
      .delete('/bookingSession/' + selectedSessionIds[0]);
      setUiLoading(false);
     if (response && response.data) {
      updateBookingSessionIds([]);
      history.push('/bookingSessions');
     } else {
      showErrorMessage('Error in deleting the session');
     }
    } catch (error) {
      setUiLoading(false);
      console.log(error);
      if (error.response && error.response.status === 403) {
        history.push('/sign-in');
      }
      showErrorMessage('Error in deleting the session');
    }
      
  };

 const deleteBookingSession = () => {
  if (!selectedSessionIds || selectedSessionIds.length !== 1) {
    showErrorMessage('Please only one session');
    return;
  }
  confirm({ description: 'You are about to delete this item!' })
  .then(() => {
    deleteSession()   
  })
  .catch(() => { /* ... */ });
 };

 const createNewBooking = () => {
  updateBookingSessionDetails(null);
  history.push('/bookingSession')
 }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />



        <IconButton aria-label="delete" onClick={deleteBookingSession} >
          <DeleteIcon />
        </IconButton>
   

        <NavLink to="/bookingSettings" className={classes.textLink}>
          <Tooltip title="Booking Settings">
            <IconButton aria-label="Booking settings">
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </NavLink>
      
          <Button
            color="primary"
            variant="contained"
            onClick={createNewBooking}
          >
            Add Booking Session
        </Button>
    
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search restaurant" 
          
        />
      </div>

    </div>
  );
};

BookingSessionsToolbar.propTypes = {
  className: PropTypes.string
};

export default BookingSessionsToolbar;
