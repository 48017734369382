import React, { useState, useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ClearIcon from '@material-ui/icons/Clear';
import CreatableSelect from "react-select/creatable";

import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";

import { useSelector, useDispatch } from "react-redux";
import { saveMenuItem, updateMenuItem, updateMenu } from "store/actions/restaurantMenu";

import { default as MenuVisibility } from "../MenuVisibility";
import { compareStringCaseInsensitive } from "util/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    minWidth: 500,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

const MenuItemOptions = (props) => {
  const classes = useStyles();
  const { open, menuItem } = props;

  const dispatch = useDispatch();

  const saveTheMenuItem = (values) => dispatch(saveMenuItem(values));
  const updateTheMenuItem = (item, values) =>
    dispatch(updateMenuItem(item, values));

  const categories = useSelector(
    (state) => state.restaurantMenu.menuCategories
  );
  const menu = useSelector((state) => state.restaurantMenu.menu);
    
  const [promotionTags, setPromotionTags] = useState([]);
  const updateTheMenu = (menu, menuId) => dispatch(updateMenu(menu, menuId));

  const [cookingCategory, setCookingCategory] = useState(null);
  const [printCategoryList, setPrintCategoryList] = useState([]);
  const [Hot, setHot] = useState(false);
  const [Spicy, setSpicy] = useState(false);
  const [Hotter, setHotter] = useState(false);
  const [Hottest, setHottest] = useState(false);
  const [Cold, setCold] = useState(false);
  const [Vegetarian, setVegetarian] = useState(false);
  const [Vegan, setVegan] = useState(false);
  const [GlutenFree, setGlutenFree] = useState(false);
  const [Halal, setHalal] = useState(false);
  const [Recommended, setRecommended] = useState(false);
  const [Seasonal, setSeasonal] = useState(false);
  const [VGO, setVGO] = useState(false);
  const [GFO, setGFO] = useState(false);
  const [DairyFree, setDairyFree] = useState(false);
  const [NutsFree, setNutsFree] = useState(false);
  const [DukkahNF, setDukkahNF] = useState(false);
  const selectPrintCategoryRef = useRef();
  const selectPromotionRef = useRef();
  const selectCookingCategoryRef = useRef();

  const onClearCookingCategory = () => {
    selectCookingCategoryRef.current.select.clearValue();
    setCookingCategory(null);
    const newMenuItem = { ...menuItem, cookingCategory: null };
    updateTheMenuItem(newMenuItem, categories);
  };

  const onClearPrintCategory = () => {
      selectPrintCategoryRef.current.select.clearValue();
      setPrintCategoryList(null);
    const newMenuItem = { ...menuItem, printCategory: null };
    updateTheMenuItem(newMenuItem, categories);
  
  };


  const onClearPromotionTags = () => {
    const newMenuItem = { ...menuItem, promotionTags: []};
    updateTheMenuItem(newMenuItem, categories);

};

  useEffect(() => {
    if (menuItem.promotionTags){
      const existingPromotionTags = (menuItem.promotionTags ? menuItem.promotionTags : []).map( tag => { return { value: tag, label: tag }});
      setPromotionTags(existingPromotionTags);
    }

    if (menuItem.tags) {
      // console.log("menuItem.tags=", menuItem.tags);
      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Hot")
        ) > -1
      ) {
        setHot(true);
      } else {
        setHot(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Spicy")
        ) > -1
      ) {
        setSpicy(true);
      } else {
        setSpicy(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Hotter")
        ) > -1
      ) {
        setHotter(true);
      } else {
        setHotter(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Hottest")
        ) > -1
      ) {
        setHottest(true);
      } else {
        setHottest(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Vegetarian")
        ) > -1
      ) {
        setVegetarian(true);
      } else {
        setVegetarian(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Vegan")
        ) > -1
      ) {
        setVegan(true);
      } else {
        setVegan(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Gluten Free")
        ) > -1
      ) {
        setGlutenFree(true);
      } else {
        setGlutenFree(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Halal")
        ) > -1
      ) {
        setHalal(true);
      } else {
        setHalal(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Cold")
        ) > -1
      ) {
        setCold(true);
      } else {
        setCold(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Recommended")
        ) > -1
      ) {
        setRecommended(true);
      } else {
        setRecommended(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Seasonal")
        ) > -1
      ) {
        setSeasonal(true);
      } else {
        setSeasonal(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "VGO")
        ) > -1
      ) {
        setVGO(true);
      } else {
        setVGO(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "GFO")
        ) > -1
      ) {
        setGFO(true);
      } else {
        setGFO(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Dairy Free")
        ) > -1
      ) {
        setDairyFree(true);
      } else {
        setDairyFree(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Nuts Free")
        ) > -1
      ) {
        setNutsFree(true);
      } else {
        setNutsFree(false);
      }

      if (
        menuItem.tags.findIndex((tag) =>
          compareStringCaseInsensitive(tag, "Dukkah-NF")
        ) > -1
      ) {
        setDukkahNF(true);
      } else {
        setDukkahNF(false);
      }
    }

    if (menuItem.cookingCategory) {
      setCookingCategory({
        value: menuItem.cookingCategory,
        label: menuItem.cookingCategory,
      });
    } else {
      setCookingCategory(null);
    }
    if (menuItem.printCategory) {
      setPrintCategoryList(
        menuItem.printCategory.split(";").map((category) => ({
          value: category,
          label: category,
        }))
      );
    } else {
      setPrintCategoryList([]);
    }
  }, [menuItem]);

  const handleClose = () => {
    props.handClose();
  };

  const handleCopy = () => {
    const newMenuItem = { ...menuItem, name: `Copy of ${menuItem.name}` };
    saveTheMenuItem(newMenuItem);
  };

  const handleTagChange = (event, tag) => {
    let tagSet = new Set();
    if (menuItem.tags) {
      tagSet = new Set(menuItem.tags);
    }
    if (tag === "Hot") {
      setHot(event.target.checked);
    }
    if (tag === "Spicy") {
      setSpicy(event.target.checked);
    }

    if (tag === "Hotter") {
      setHotter(event.target.checked);
    }

    if (tag === "Hottest") {
      setHottest(event.target.checked);
    }

    if (tag === "Cold") {
      setCold(event.target.checked);
    }
    if (tag === "Vegetarian") {
      setVegetarian(event.target.checked);
    }
    if (tag === "Vegan") {
      setVegan(event.target.checked);
    }
    if (tag === "Gluten Free") {
      setGlutenFree(event.target.checked);
    }
    if (tag === "Halal") {
      setHalal(event.target.checked);
    }

    if (tag === "Recommended") {
      setRecommended(event.target.checked);
    }
    if (tag === "Seasonal") {
      setSeasonal(event.target.checked);
    }

    if (tag === "VGO") {
      setVGO(event.target.checked);
    }
    if (tag === "GFO") {
      setGFO(event.target.checked);
    }

    if (tag === "Dairy Free") {
      setDairyFree(event.target.checked);
    }

    if (tag === "Nuts Free") {
      setNutsFree(event.target.checked);
    }

    if (tag === "Dukkah-NF") {
      setDukkahNF(event.target.checked);
    }

    if (event.target.checked) {
      tagSet.add(tag);
    } else {
      tagSet.delete(tag);
    }
    updateTheMenuItem({ ...menuItem, tags: [...tagSet] }, categories);
  };

  const cookingCategories = () => {
    // console.log( menu.cookingCategories);
    if (menu && menu.cookingCategories && menu.cookingCategories.length > 0) {
      return menu.cookingCategories.map((category) => {
        // check category is object or string
        if (typeof category === 'object' && category.name) {
          return { value: category.name, label: category.name };
        } else {
          return { value: category, label: category };
        }
      });
    } else {
      return [];
    }
  };
  // console.log("menu.cookingCategories=", menu.cookingCategories, cookingCategories());
  const handleCookingCategoryChange = (options) => {
    console.log("handleCookingCategoryChange", options);

    if (!options || options == null) {
      return;
    }

    //const allCookingCategories = options.map(option => option.value).join(';')
    const newMenuItem = { ...menuItem, cookingCategory: options.value };
    updateTheMenuItem(newMenuItem, categories);
    setCookingCategory(options.value);
  };

  const handlePrintCategoryChange = (options) => {
    console.log("handlePrintCategoryChange", options);
    if (!options || options == null) {
      return;
    }

    const allPrintCategories = options.map((option) => option.value).join(";");
    setPrintCategoryList(options);
    const newMenuItem = { ...menuItem, printCategory: allPrintCategories };
    updateTheMenuItem(newMenuItem, categories);
  };

  const getPromotionTags = () => {
    if (menu && menu.promotionTags && menu.promotionTags.length > 0) {
      return menu.promotionTags.map((tag) => {
        return { value: tag, label: tag };
      });
    } else {
      return [];
    }
  };


  const handlePromotionTagsChange = (selectedOptions) => {
    setPromotionTags(selectedOptions);
    if (!selectedOptions || selectedOptions === null) {
      return;
    }
    
    const newPromotionTags = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    
    const newMenuItem = { ...menuItem, promotionTags: newPromotionTags};
    updateTheMenuItem(newMenuItem, categories);

    const existingMenuPromotionTags = menu.promotionTags ? menu.promotionTags : [];
    existingMenuPromotionTags.push(...newPromotionTags);
    const allMenuPromotionTags = [...new Set(existingMenuPromotionTags)];
    updateTheMenu({ ...menu, promotionTags: allMenuPromotionTags }, menu.id);
  };

  const handleRemoveCookingCategories = () => {
    const newMenuItem = { ...menuItem, cookingCategory: null };
    updateTheMenuItem(newMenuItem, categories);
    setCookingCategory(null);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Menu Item Options</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Grid
                container
                direction="column"
                className={classes.root}
                spacing={3}
              >
                <Grid item container spacing={2} alignItems="center">
                  <Grid>
                    <IconButton
                      aria-label="copy"
                      edge="start"
                      color="secondary"
                      onClick={handleCopy}
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                  <Grid>
                    {" "}
                    <Typography gutterBottom variant="subtitle1">
                      Duplicate
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography gutterBottom variant="h5">
                    Promotion Tags
                  </Typography>
                  <CreatableSelect
                  ref={selectPromotionRef}
                    isMulti
                    value={promotionTags}
                    onChange={handlePromotionTagsChange}
                    options={getPromotionTags()}
                  />
                   <Button startIcon={<ClearIcon />} size="small" variant="contained" onClick={onClearPromotionTags} style= {{marginTop: 1}}>
                    Clear
                  </Button>
                </Grid>

                <Divider />
                <Grid item xs={12} container direction="column">
                  <MenuVisibility
                    visibilityObj={menuItem}
                    menuItem={menuItem}
                  />
                </Grid>
                <Divider />
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRemoveCookingCategories}
                  >
                    Remove Cooking Categories
                  </Button>
                </Grid>

                {/* <Divider />
                <Grid item>
                  <Typography gutterBottom variant="h5">
                    KDS Category
                  </Typography>
                  {menu &&
                  menu.cookingCategories &&
                  menu.cookingCategories.length > 0 ? (
                    <Select
                      ref={selectCookingCategoryRef}
                      defaultValue={cookingCategory}
                      options={cookingCategories()}
                      className="basic-multi-select"
                      onChange={handleCookingCategoryChange}
                      classNamePrefix="select Cooking category"
                    />
                  ) : null}
                  <Button size="small" startIcon={<ClearIcon />}  variant="outlined" onClick={onClearCookingCategory} style= {{marginTop: 1}}>
                    Clear
                  </Button>
                </Grid> */}

                <Divider />
                <Grid item>
                  <Typography gutterBottom variant="h5">
                    Print Categores
                  </Typography>
                  {menu &&
                  menu.cookingCategories &&
                  menu.cookingCategories.length > 0 ? (
                    <Select
                      ref={selectPrintCategoryRef}
                      isMulti
                      defaultValue={printCategoryList}
                      options={cookingCategories()}
                      className="basic-multi-select"
                      onChange={handlePrintCategoryChange}
                      classNamePrefix="select Cooking category"
                    />
                  ) : null}
                    <Button startIcon={<ClearIcon />} size="small" variant="contained" onClick={onClearPrintCategory} style= {{marginTop: 1}}>
                    Clear
                  </Button>
                </Grid>

                <Divider />
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Tags</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Hot}
                            onChange={(e) => handleTagChange(e, "Hot")}
                            name="Hot"
                          />
                        }
                        label="Hot"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Spicy}
                            onChange={(e) => handleTagChange(e, "Spicy")}
                            name="Spicy"
                          />
                        }
                        label="🌶️"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Hotter}
                            onChange={(e) => handleTagChange(e, "Hotter")}
                            name="Hotter"
                          />
                        }
                        label="🌶️🌶️"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Hottest}
                            onChange={(e) => handleTagChange(e, "Hottest")}
                            name="Hottest"
                          />
                        }
                        label="🌶️🌶️🌶️"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Cold}
                            onChange={(e) => handleTagChange(e, "Cold")}
                            name="Cold"
                          />
                        }
                        label="Cold"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Vegetarian}
                            onChange={(e) => handleTagChange(e, "Vegetarian")}
                            name="Vegetarian"
                          />
                        }
                        label="Vegetarian"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Vegan}
                            onChange={(e) => handleTagChange(e, "Vegan")}
                            name="Vegan"
                          />
                        }
                        label="Vegan"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={GlutenFree}
                            onChange={(e) => handleTagChange(e, "Gluten Free")}
                            name="GlutenFree"
                          />
                        }
                        label="GlutenFree"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Halal}
                            onChange={(e) => handleTagChange(e, "Halal")}
                            name="Halal"
                          />
                        }
                        label="Halal"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Recommended}
                            onChange={(e) => handleTagChange(e, "Recommended")}
                            name="Recommended"
                          />
                        }
                        label="Recommended"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Seasonal}
                            onChange={(e) => handleTagChange(e, "Seasonal")}
                            name="Seasonal"
                          />
                        }
                        label="Seasonal"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={VGO}
                            onChange={(e) => handleTagChange(e, "VGO")}
                            name="VGO"
                          />
                        }
                        label="VGO"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={GFO}
                            onChange={(e) => handleTagChange(e, "GFO")}
                            name="GFO"
                          />
                        }
                        label="GFO"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={DairyFree}
                            onChange={(e) => handleTagChange(e, "Dairy Free")}
                            name="DairyFree"
                          />
                        }
                        label="Dairy Free"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={NutsFree}
                            onChange={(e) => handleTagChange(e, "Nuts Free")}
                            name="NutsFree"
                          />
                        }
                        label="Nuts Free"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={DukkahNF}
                            onChange={(e) => handleTagChange(e, "Dukkah-NF")}
                            name="DukkahNF"
                          />
                        }
                        label="Dukkah-NF"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MenuItemOptions;
