import React, { useState, useEffect } from 'react';


import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

import Slider from '@material-ui/core/Slider';
import Select from 'react-select'

import { useSelector, useDispatch } from 'react-redux';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import { updateMenuCategory, updateMenuItem } from 'store/actions/restaurantMenu';
import { minutesToString } from "util/menuUtils";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    minWidth: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  main: {
    minHeight: 300
  }
}));




const marks = [
  {
    value: 0,
    label: '00:00',
  },

  {
    value: 720,
    label: '12:00',
  },
  {
    value: 1440,
    label: '24:00',
  },
  {
    value: 2160,
    label: '12:00',
  },
  {
    value: 2880,
    label: '24:00',
  },
];


function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};


const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => { return { value: day, label: day }; });

const MenuVisibility = (props) => {
  const classes = useStyles();
  const { menuItem, visibilityObj, category } = props
  const dispatch = useDispatch();


  const [visibility, setVisibility] = useState('')

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [time, setTime] = useState([0, 1440]);
  const [activeDuration, setActiveDuration] = useState(null);
  const categories = useSelector((state) => state.restaurantMenu.menuCategories);

  const updateCategory = (values) => dispatch(updateMenuCategory(values));
  const updateTheMenuItem = (item, values) => dispatch(updateMenuItem(item, values));


  const handleChange = (values) => {
    console.log('values', values);
    if (category) {
      const newCategory = { ...category, ...values }

      updateCategory(newCategory);

    }
    if (menuItem) {
      let newMenuItem = { ...menuItem, ...values }

      updateTheMenuItem(newMenuItem, categories);

    }
  };


  const updateHiddenUtil = (date) => {
    const updateObj = {
      active: true, active_begin: null,
      active_end: null,
      activeWeekDays: null,
      hidden_until: date.toISOString()
    };
    handleChange(updateObj);
  };

  const updateShowOnly = (newTime) => {
    let updateObj = {
      active: true, active_begin: newTime[0],
      active_end: newTime[1],
      hidden_until: null,
      active_days: null,
      activeWeekDays: null,
    };
    if (activeDuration) {
      updateObj = {
        ...activeDuration, active: true, active_begin: newTime[0],
        active_end: newTime[1]
      }
    }
   // console.log('updateObj time', updateObj);
    setActiveDuration(updateObj);
  };

  const getActiveDays = () => {
    if (activeDuration && activeDuration.activeWeekDays) {
      return activeDuration.activeWeekDays.map(day => ( {value: day, label: day}));
    } else {
      return [];
    }
  };

  const getHideDays = () => {
    if (visibilityObj && visibilityObj.hideDays) {
      return visibilityObj.hideDays.map(day => ( {value: day, label: day}));
    } else {
      return [];
    }
  };

  const selectHideWeekDays = (options) => {
    console.log('selectHideWeekDays', options);
    if (!options || options == null) {
      return;
    }

    const hideDays = options.map(day => day.value);

    handleChange({hideDays});
  };

  const selectShowOnlyFromWeekDays = (options) => {
    console.log('selectShowOnlyFromWeekDays', options);
    if (!options || options == null) {
      return;
    }

    const selectedDays = options.map(day => day.value);

    let updateObj = {
      active: true, active_begin: null,
      active_end: null,
      hidden_until: null,
      active_days: selectedDays
    };
    if (activeDuration) {
      updateObj = { ...activeDuration, active: true, activeWeekDays: selectedDays }
    }
   // console.log('updateObj', updateObj);
    setActiveDuration(updateObj);
  };

  const handleShowFromUpdate = () => {
    if (activeDuration !== null) {
      handleChange(activeDuration);
    }

  };

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.value);
    let updateObj = null;
    if (event.target.value === 'hidden') {
      updateObj = {
        active: false, active_begin: null,
        active_end: null,
        active_days: null,
        activeWeekDays: null,
        hidden_until: null
      };
      handleChange(updateObj);
    } else if (event.target.value === 'hideUtil') {

      updateHiddenUtil(selectedDate);

    } else if (event.target.value === 'showOnlyFrom') {
      updateShowOnly(time);
    } else if (event.target.value === 'active') {
      const updateObj = {
        active: true, active_begin: null,
        active_end: null,
        hidden_until: null,
        hideDays: null,
        active_days: null, 
        activeWeekDays: null,
      };
      handleChange(updateObj);
    }

  };

  useEffect(() => {
    if (!visibilityObj) {
      return;
    }
    console.log('visibilityObj', visibilityObj);
    if (visibilityObj.hidden_until) {
      setVisibility('hideUtil');
      setSelectedDate(new Date(visibilityObj.hidden_until));
    } else if (visibilityObj.active_begin !== null && visibilityObj.active_end !== null) {
      setVisibility('showOnlyFrom');
      //setTime([visibilityObj.active_begin, visibilityObj.active_end]);
      const activeObj = {
        active: visibilityObj.active, active_begin: visibilityObj.active_begin, 
        active_end: visibilityObj.active_end,
        hidden_until: visibilityObj.hidden_until, 
        activeWeekDays: visibilityObj.activeWeekDays
      };
      setActiveDuration(activeObj);
    } else if (!visibilityObj.active) {
      setVisibility('hidden');
    } else {
      setVisibility('active');
    }

  }, [props]);

  const handleTimeChange = (event, newValue) => {
    setTime(newValue);
    updateShowOnly(newValue);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    updateHiddenUtil(newDate);
  };


  return (

    <Grid xs={12} container className={classes.main}>
      <Grid item>


        <FormControl component="fieldset">
          <FormLabel component="legend"> Visibility</FormLabel>
          <RadioGroup aria-label="Visibility" name="visibility" value={visibility} onChange={handleVisibilityChange}>
            <FormControlLabel value="active" control={<Radio />} label="Active" />
            <FormControlLabel value="hidden" control={<Radio />} label="Hidden From menu" />

            <Select
                    maxMenuHeight={250}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    isMulti
                    defaultValue={getHideDays()}
                    options={weekDays}
                    className="basic-multi-select"
                    onChange={selectHideWeekDays}
                    classNamePrefix="Select Hide days"
                  /> 
            <FormControlLabel value="hideUtil" control={<Radio />} label="Hide until ..." />
            {visibility === 'hideUtil' ? <>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  variant="inline"
                  label=""
                  value={selectedDate}
                  onChange={handleDateChange}
                />


              </MuiPickersUtilsProvider>
            </> : null}
            <FormControlLabel value="showOnlyFrom" control={<Radio />} label="Show only from ..." />
            {visibility === 'showOnlyFrom' ? <>

              <div className={classes.root}>
                <Typography id="range-slider" gutterBottom>
                  Time: {`${minutesToString(time[0])} - ${minutesToString(time[1])}`} <Button onClick={handleShowFromUpdate} color="primary" variant="outlined">
                    Update
          </Button>
                </Typography>
                <Slider
                  ValueLabelComponent={ValueLabelComponent}
                  value={time}
                  step={15}
                  min={0}
                  max={2880}
                  marks={marks}
                  onChange={handleTimeChange}
                  valueLabelDisplay="auto"
                  aria-labelledby="discrete-slider-always"
                  valueLabelFormat={minutesToString}
                />
                <Grid item>
                  <Typography gutterBottom variant="h5">Week days</Typography>

                  <Select
                    maxMenuHeight={250}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    isMulti
                    defaultValue={getActiveDays()}
                    options={weekDays}
                    className="basic-multi-select"
                    onChange={selectShowOnlyFromWeekDays}
                    classNamePrefix="Select Acitve days"
                  />



                </Grid>

              </div>


            </> : null}

          </RadioGroup>
        </FormControl>
      </Grid>


    </Grid>


  );
};

export default MenuVisibility;