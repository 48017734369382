import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const Bookings = (props) => {
  const { className, bookings, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedBookings, setSelectedBookings] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const openBookingDetail = (e, session) => {
    //  updateBookingSessionDetails(session);
    history.push(`/bookingSession/${session.sessionId}`);
  };

  const handleSelectOne = (event, urlIdentifier) => {
    const selectedIndex = selectedBookings.indexOf(urlIdentifier);
    let newSelectedBookings = [];

    if (selectedIndex === -1) {
      newSelectedBookings = newSelectedBookings.concat(
        selectedBookings,
        urlIdentifier
      );
    } else if (selectedIndex === 0) {
      newSelectedBookings = newSelectedBookings.concat(
        selectedBookings.slice(1)
      );
    } else if (selectedIndex === selectedBookings.length - 1) {
      newSelectedBookings = newSelectedBookings.concat(
        selectedBookings.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedBookings = newSelectedBookings.concat(
        selectedBookings.slice(0, selectedIndex),
        selectedBookings.slice(selectedIndex + 1)
      );
    }
    setSelectedBookings(newSelectedBookings);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Start Time - End Time</TableCell>
                    <TableCell>Guests</TableCell>
                    <TableCell>Tables</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Special Requirements</TableCell>
                    <TableCell>Created Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookings
                    .slice(page * rowsPerPage, rowsPerPage * page + rowsPerPage)
                    .map((booking) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={booking.id}
                        selected={selectedBookings.indexOf(booking.id) !== -1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedBookings.indexOf(booking.id) !== -1
                            }
                            color="primary"
                            onChange={(event) =>
                              handleSelectOne(event, booking.id)
                            }
                            value="true"
                          />
                        </TableCell>
                        <TableCell>
                          {/* <Button
                            onClick={(e) => openBookingDetail(e, booking)}
                          >
                            <div className={classes.textLink}> */}
                              <Typography variant="body1">
                                {booking.name}
                              </Typography>
                            {/* </div>
                          </Button> */}
                        </TableCell>
                        <TableCell>{booking.status}</TableCell>
                        <TableCell>{`${booking.startTime} - ${booking.endTime}`}</TableCell>
                        <TableCell>{booking.partySize}</TableCell>
                        <TableCell>{booking.tables.map((table) => table.tableNumber).join(', ')}</TableCell>
                        <TableCell>{booking.email}</TableCell>
                        <TableCell>{booking.mobilePhone}</TableCell>
                        <TableCell>{booking.specialRequirement}</TableCell>

                        <TableCell>
                          {booking.createdAt}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={bookings.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

Bookings.propTypes = {};

export default Bookings;
