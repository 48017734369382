import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import { useSelector, useDispatch } from "react-redux";

import Switch from "@material-ui/core/Switch";

import FormControl from "@material-ui/core/FormControl";
import { saveMenuItems, updateMenuItem } from "store/actions/restaurantMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    minWidth: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

const MenuMiscSettings = (props) => {
  const classes = useStyles();
  const { open, menuId, menuItem, category, categoryId } = props;
  const dispatch = useDispatch();
  const categories = useSelector(
    (state) => state.restaurantMenu.menuCategories
  );
  const updateTheMenuItem = (item, values) =>
    dispatch(updateMenuItem(item, values));
  const [isTakeawayDisabled, setIsTakeawayDisabled] = useState(false);
  const [isForPrintingDisabled, setIsForPrintingDisabled] = useState(false);
  const [isForKDSDisabled, setIsForKDSDisabled] = useState(false);
  const [isMainCourse, setIsMainCourse] = useState(false);

  const menu = useSelector((state) => state.restaurantMenu.menu);
  const saveItems = (values) => dispatch(saveMenuItems(values));

  const handleClose = () => {
    props.handClose();
  };

  const handleSave = () => {
    updateItemList();
    props.handClose();
  };

  const updateItemList = () => {
    if (category) {
      const items = category.items.map((item) => {
        return {
          ...item,
          isTakeawayDisabled,
          isForPrintingDisabled,
          isForKDSDisabled,
          isMainCourse,
        };
      });
      saveItems({ items, menuId: menuId, categoryId: category.id });
    } else {
      updateTheMenuItem(
        {
          ...menuItem,
          isTakeawayDisabled,
          isForPrintingDisabled,
          isForKDSDisabled,
          isMainCourse,
          categoryId,
          menuId,
        },
        categories
      );
    }
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "isTakeawayDisabled") {
      setIsTakeawayDisabled(value);
      return;
    }
    if (name === "isForPrintingDisabled") {
      setIsForPrintingDisabled(value);
      return;
    }

    if (name === "isForKDSDisabled") {
      setIsForKDSDisabled(value);
      return;
    }

    if (name === "isMainCourse") {
      setIsMainCourse(value);
      return;
    }
  };

  useEffect(() => {
    if (menuItem) {
      setIsTakeawayDisabled(menuItem.isTakeawayDisabled);
      setIsForPrintingDisabled(menuItem.isForPrintingDisabled);
      setIsForKDSDisabled(menuItem.isForKDSDisabled);
      setIsMainCourse(menuItem.isMainCourse);
    }
  }, [menuItem]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Misc Settings</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Grid
                container
                direction="column"
                className={classes.root}
                spacing={3}
              >
                <Grid item xs={6} container direction="column">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isTakeawayDisabled}
                        onChange={handleSwitchChange}
                        name="isTakeawayDisabled"
                        color="primary"
                      />
                    }
                    label="Not For Takeaway"
                  />
                </Grid>

                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isForPrintingDisabled}
                        onChange={handleSwitchChange}
                        name="isForPrintingDisabled"
                        color="primary"
                      />
                    }
                    label="Not For Category Printing"
                  />
                </Grid>

                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isForKDSDisabled}
                        onChange={handleSwitchChange}
                        name="isForKDSDisabled"
                        color="primary"
                      />
                    }
                    label="Not For KDS"
                  />
                </Grid>


                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isMainCourse}
                        onChange={handleSwitchChange}
                        name="isMainCourse"
                        color="primary"
                      />
                    }
                    label="Main Course"
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MenuMiscSettings;
