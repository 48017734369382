import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { Bookings, BookingsToolbar } from './components';

import CircularProgress from '@material-ui/core/CircularProgress';



import { useSelector, useDispatch } from 'react-redux';


import API from 'util/API';
import {
  
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));


const BookingsList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const [uiLoading, setUiLoading] = useState(false);
  
  const [bookings, setBookings] = useState([])

  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  
  const selectedDate = useSelector((state) => state.booking.bookingSelectedDate);

  


  const getBookings = async () => {
    setUiLoading(true);

    try {
      const response = await API.get(`/bookings?dateStr=${moment(selectedDate).format('YYYY-MM-DD')}`);
      if (response && response.data && response.data.bookings) {
        console.log(response.data);
        setUiLoading(false);
        setBookings(response.data.bookings);
      } else {
        setUiLoading(false);
        showError("Something went wrong, please try again");
      }

    }catch (err) {
      setUiLoading(false);
      showError("Something went wrong, please try again");
    }

  };

  useEffect(() => {
    getBookings();
  }, [selectedDate]);


  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {

  return (
    <div className={classes.root}>
      <BookingsToolbar /> 
      <div className={classes.content}>
        <Bookings bookings={bookings} />
      </div>
    </div>
  );
  }
};

export default BookingsList;
