import React, { useState , useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import API from 'util/API';
import isEmpty from 'lodash/isEmpty';
import { showSuccessSnackbar,  showErrorSnackbar } from 'store/actions/snackbarMessage'

const useStyles = makeStyles(() => ({
  root: {}
}));



const AccountDetails = props => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const account = useSelector((state) => state.account.user);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState({
    firstName:  '',
    lastName: '',
    phone: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    if (account) {
      setValues({...values, firstName: account.firstName, lastName: account.lastName, email: account.email, phone: account.phoneNumber});
    }
  
  }, [account]);

  const isValid = () => {
     if (isEmpty(values.firstName.trim())) {
       return false;
     }
     if (isEmpty(values.firstName.trim())) {
      return false;
    }
    if (isEmpty(values.phone.trim())) {
      return false;
    }
    return true;
  };

  const updateMyDetails = () => {
    setIsLoading(true);
    API.put('/userDetail', values)
    .then((response) => {
      setIsLoading(false);
      showSuccess("Updated the details successfully!");
    })
    .catch((error) =>{
      setIsLoading(false);
      console.log(error);
      showError("Something went wrong, please try again.");
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                disabled
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
        
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={ !isValid()  || isLoading }
            onClick={e => updateMyDetails()}
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
