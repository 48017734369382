import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";

import { Alert, AlertTitle } from "@material-ui/lab";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import clsx from "clsx";


import {  useDispatch } from "react-redux";
import API from "util/API";

import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import {
  checkMobilePhone,
} from "util/orderUtils";

import {  transformOrderId } from "util/orderUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  submitButton: {
    width: 200,
  },
}));

const QueueForm = (props) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    guestNum: 0,
    phoneNumber: "",
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [reference, setReference] = useState(null);
  const [waitingMinutes, setWaitingMinutes] = useState(null);

  const [uiLoading, setUiLoading] = useState(false);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));

  const [values, setValues] = useState(initialState);
  const [restaurant, setRestaurant] = useState(null);

  const restaurantId = props.match.params.restaurantId;
  const captchaKey = process.env.REACT_APP_RECAPTCHA;

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${captchaKey}`;

    document.body.appendChild(script);
  }, []);

  const getRestaurantinfo = async () => {

    try {
      const response = await API.get(`/restaurantInfo?restaurantId=${restaurantId}`);
      console.log('response', response);
      if (response && response.data && response.data.restaurant) {
        setRestaurant(response.data.restaurant);
      }
     
    } catch (err) {
      console.log(err);
      showError("Something went wrong, please try it later.");
    }

  };

  useEffect(() => {
    if (restaurantId && restaurantId !== '') {
      getRestaurantinfo();
    }
  }, restaurantId);



  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value, type } = e.target;
    console.log("name", name, value, type);
    if (type === "checkbox") {
      setValues({ ...values, [name]: !values[name] });
    } else {
      setValues({ ...values, [name]: value });
    }
  };


  const submitForm = async () => {
    setUiLoading(true);
    window.grecaptcha && window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(captchaKey, { action: "submit" })
        .then(function (token) {
          (async () => {
            try {
              const response = await API.post(`/queuePosition`, {
                recaptcha: token,
                ...values,
                restaurantId
              });
              setUiLoading(false);
              if (response && response.data && response.data.reference) {
                setReference(response.data.reference);
                showSuccess('You have reserved a position successfully')
              }
              if (response && response.data && response.data.waitingMinutes) {
                setWaitingMinutes(response.data.waitingMinutes);
              }
            } catch (err) {
              console.log(err);
              setUiLoading(false);
              showError("Something went wrong, please try it later.");
            }
          })();
        });
    });
  };





  const updatePhoneNumber = (mobilePhone) => {
    setValues({ ...values, phoneNumber: mobilePhone });
    if (!checkMobilePhone(mobilePhone)) {
      setPhoneNumberError("The mobile number is invalid");
    } else {
      setPhoneNumberError("");
    }
  };

  return (
    <div>
      {uiLoading ? <LinearProgress /> : null}
      <Card className={clsx(classes.root, classes)}>
        <CardHeader
          title={`${restaurant ? restaurant.name : ''} online queue`}
          subheader="submit the form to preserve your position in the queue"
        />
        <CardContent>
          {reference === null ?
            <>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Name (required)"
                    margin="dense"
                    name="name"
                    variant="outlined"
                    value={values.name}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Number of guests (required)"
                    margin="dense"
                    name="guestNum"
                    type="number"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.guestNum}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile Number (required)"
                    margin="dense"
                    name="phone"
                    type="number"
                    error={!checkMobilePhone(values.phoneNumber)}
                    helperText={phoneNumberError}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.phoneNumber}
                    onChange={(e) => updatePhoneNumber(e.target.value)}
                  />
                </Grid>
              </Grid>

              <CardActions>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  disabled={Number(values.guestNum) === 0 || values.name.trim().length === 0 || !checkMobilePhone(values.phoneNumber)}
                  onClick={submitForm}
                  color="primary"
                >
                  Submit
                </Button>
              </CardActions>
            </> : <Paper>

              <Alert severity="success">
                <AlertTitle>Info</AlertTitle>
                <strong>Your reference number is {transformOrderId(reference)}{waitingMinutes && `, and the waiting time is about ${waitingMinutes} minutes.`}</strong>
              </Alert>

            </Paper>}
        </CardContent>
      </Card>
    </div>
  );
};

export default QueueForm;
