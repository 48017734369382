import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { CustomersToolbar, CustomersTable } from './components';

import CircularProgress from '@material-ui/core/CircularProgress';


import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomers } from 'store/actions/restaurant';

import { authMiddleWare } from 'util/auth'

import API from 'util/API';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));



const CustomerList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiLoading, setUiLoading] = useState(false);
  // const [errorMsg, setErrorMsg] = useState('');
  const loadCustomers= (allCustomer) => dispatch(fetchCustomers(allCustomer));

  const customersList = useSelector((state) => state.restaurant.customers);

  const history = useHistory();
  authMiddleWare(history);

  const fectchCustomers = () => {
    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;
    setUiLoading(true);
    API
      .get('/customers')
      .then((response) => {
        //console.log('response.data', response.data);
        loadCustomers(response.data)
        setUiLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setUiLoading(false);
        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }
           
        // setErrorMsg('Error in retrieving the data');

      });
  };


  useEffect(() => {
    fectchCustomers();
  }, [props]);


  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {

  return (
    <div className={classes.root}>
      <CustomersToolbar customers={customersList}/>
      <div className={classes.content}>
        <CustomersTable customers={customersList} />
      </div>
    </div>
  );
  }
};

export default CustomerList;
