import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";



import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Typography from "@material-ui/core/Typography";
import PaymentTypes from "./PaymentTypes";
import PaymentTypesByDate from "./PaymentTypesByDate";

import { showErrorSnackbar } from "store/actions/snackbarMessage";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import API from "util/API";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  table: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const PaymentTypesReport = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const [reportData, setReportData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [dailyReport, setDailyReport] = useState(false);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));


  const changeDailyReport = (event) => {
    setReportData([]);
    setDailyReport(event.target.checked);
  };

  const getReportData = async () => {
    if (endDate < startDate) {
      showError("The end date cannot be later than the start date!");
      return;
    }

    try {
      setIsLoading(true);
      setReportData([]);
      const startDateStr = formateDate(startDate);
      const endDateStr = formateDate(endDate);
      const rewsponse = await API.get(
        `/paymentTypesReport?startDateStr=${startDateStr}&endDateStr=${endDateStr}&dailyReport=${dailyReport}`
      );
      const data = await rewsponse.data;



      setIsLoading(false);
      if (data) {
        setReportData(data);
      } else {
        setReportData([]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      setReportData([]);
      showError("Something went wrong, please try again.");
    }
  };

  const formateDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const queryDate = async () => {
    setReportData([]);
    // check start date and end date
    if (startDate > endDate) {
      showError("Start date must be before end date");
      return;
    }
    // check start date and end date is future date
    if (startDate > new Date() || endDate > new Date()) {
      showError("Start date and end date must be before today");
      return;
    }
    // check start date and end date gap is less than 7 days
    if (endDate - startDate > 32 * 24 * 60 * 60 * 1000) {
      showError("Start date and end date gap must be less than 32 days");
      return;
    }

    getReportData();
  };

  const syncPOSData = async () => {
    try {
      setIsLoading(true);
      const endDateStr = formateDate(endDate);
      const rewsponse = await API.post(
        `/uploadPOSTransactions`,
        {
          transactionDateStr: endDateStr,
        }
      );
      const data = await rewsponse.data;
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      showError("Something went wrong, please try again.");
    }
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <LinearProgress style={{ marginBottom: 8, marginTop: 8 }} />
      ) : null}

      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          container
          direction="row"
          alignItems="center"
        >
          <Typography style={{ margin: theme.spacing(1) }}>
            Start Date
          </Typography>
          <DatePicker
            selected={startDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setStartDate(date)}
          />
          <Typography style={{ margin: theme.spacing(1) }}>End Date</Typography>
          <DatePicker
            selected={endDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setEndDate(date)}
          />

          <FormControlLabel
            style={{ marginLeft: theme.spacing(1) }}
            control={
              <Checkbox
                checked={dailyReport}
                onChange={(e) => changeDailyReport(e)}
                name="dailyReport"
                color="primary"
              />
            }
            label="Daily Report"
          />

          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: theme.spacing(1) }}
            onClick={(e) => queryDate()}
            disabled={isLoading}
          >
            Query
          </Button>

          <Button
            color="primary"
            variant="outlined"
            style={{ marginLeft: theme.spacing(1) }}
            onClick={(e) => syncPOSData()}
            disabled={isLoading}
          >
            Sync POS End Date Data
          </Button>
        </Grid>

        <Grid item md={12} lg={8} xl={6} xs={12}>

          {dailyReport ? (
            <PaymentTypesByDate reportData={reportData}   startDate={startDate}
            endDate={endDate} /> ):
          <PaymentTypes
            startDate={startDate}
            endDate={endDate}
            reportData={reportData}


          />
          }

        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentTypesReport;
