import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TimerOffIcon from '@material-ui/icons/TimerOff';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

const OpenHoursList = props => {

    const classes = useStyles();
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    useEffect(() => {
        console.log('props', props);
    }, [props]);

    const deleteOpenHourItem = (id) => {
        props.deleteOpenHourItem(id);
    }

    const deleteCloseDateItem = (date) => {
        props.deleteCloseDateItem(date);
    }




    return (

        <div className={classes.root}>



            {props.openHoursItems && props.openHoursItems.length > 0 ?
                <Grid item lg={3}
                    sm={6}
                    xl={3}
                    xs={12}>
                    <Typography variant="h6" className={classes.title}>
                        Open Hours
          </Typography>

                    <List>
                        {props.openHoursItems.map(item => {
                            return (
                                <ListItem key={item.id}>
                                    <ListItemAvatar>

                                        <AccessTimeIcon />

                                    </ListItemAvatar>
                                    <Typography variant="h6" style={{ display: 'inline-block' }}>
                                        {item.weekdays.join(',')}
                                        {`${item.openStart} - ${item.openEnd}`}
                                    </Typography>


                                    <IconButton edge="end" aria-label="delete" onClick={() => deleteOpenHourItem(item.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <ListItemSecondaryAction>

                                    </ListItemSecondaryAction>
                                </ListItem>)
                        })}
                    </List>

                </Grid>
                : null}

            {props.closeDaysItems && props.closeDaysItems.length > 0 ?
                <Grid item lg={3}
                    sm={6}
                    xl={3}
                    xs={12}>
                    <Typography variant="h6" className={classes.title}>
                        Close Dates
          </Typography>
                    <div className={classes.demo}>
                        <List dense={dense}>
                            {props.closeDaysItems.map(item => {
                                return (
                                    <ListItem  key={item}>
                                        <ListItemAvatar>

                                            <TimerOffIcon />

                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete" onClick={() => deleteCloseDateItem(item)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>)
                            })}
                        </List>
                    </div>
                </Grid>
                : null}


        </div>
    );
};

export default OpenHoursList;