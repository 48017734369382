import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";


import API from "util/API";

import { CSVLink } from "react-csv";
import moment from "moment-timezone";

import { LatestSales } from "../../Dashboard/components";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  table: {},
  textLink: {
    textDecoration: 'underline',
  },
}));

const SalesReport = (props) => {
  const classes = useStyles();
  const [reportData, setReportData] = useState([]);
  const [salesMonthDashboard, setSalesMonthDashboard] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timezone, setTimezone] = useState('Australia/Sydney');

  const getSalesData = async () => {
    const authToken = localStorage.getItem("AuthToken");
    API.defaults.headers.common["Authorization"] = authToken;
    setIsLoading(true);
    try {
      const response = await API.get("/salesReport");
      const data = await response.data;
      //console.log('data', data);
      setReportData(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getSalesData();
  }, [props]);

  const fectchTransactionMonthDashboard = (monthStr) => {
    setIsLoading(true);
    API.get("/salesMonthDashboard?monthStr=" + monthStr, {
      withCredentials: true,
      credentials: "include",
    })
      .then((response) => {
        //console.log('orderMonthDashboard.data', response.data);
        //loadTodayOrders(response.data)
        setSalesMonthDashboard(response.data.rows);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const showMonthDetailReport = (monthStr) => {
    fectchTransactionMonthDashboard(monthStr);
  };


  const getRows = () => {
    let rows = [];
    reportData.forEach((item) => {
      const monthString = moment(item.month).format("MM/YYYY");

      rows.push(
        <TableRow key={monthString}>
          <TableCell component="th" scope="row">
            <Link href="#" onClick={(e) => showMonthDetailReport(monthString)}>
              {monthString}
            </Link>
          </TableCell>
          <TableCell align="right">
            {/* <Link href="#" onClick={e => downloadSales(item.month)} > */}$
            {item.amount}
            {/* </Link> */}
          </TableCell>
        </TableRow>
      );
    });

    return rows;
  };

  const csvData = () => {
    //console.log('keys', keys);
    let data = [];
    data.push(["month", "Sales"]);

    reportData.forEach((item) => {
      const monthString = moment(item.month).format("MM/YYYY");
      if (item.amount && Number(item.amount)) {
        data.push([monthString, item.amount.toFixed(2)]);
      }
    });

    // console.log('data', data);
    return data;
  };

  const csvMonthData = () => {
    //console.log('keys', keys);
    let data = [];
    data.push(["Date", "Sales"]);

    salesMonthDashboard.forEach((item) => {
      const monthString = moment(item.date).tz(timezone).format("DD/MM/YYYY");
      if (item.amount && Number(item.amount)) {
        data.push([monthString, item.amount.toFixed(2)]);
      }
    });

    // console.log('data', data);
    return data;
  };

  return (
    <div className={classes.root}>
      {isLoading ? <LinearProgress style={{ marginBottom: 8 }} /> : null}
      <Grid container spacing={1}>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <Box>
            <CSVLink filename={"sales-report.csv"} data={csvData()}>
              <Typography variant='h5' className={classes.textLink}>Download CSV</Typography>
            </CSVLink>
          </Box>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="sales table">
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell align="right">Sales</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{getRows()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <CSVLink filename={"sales-month-report.csv"}
            data={csvMonthData()}>
            <Typography variant='h5' className={classes.textLink}>Download CSV</Typography></CSVLink>

          <LatestSales orderMonthDashboard={salesMonthDashboard} />

        </Grid>
      </Grid>
    </div>
  );
};

export default SalesReport;
