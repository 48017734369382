import React, { useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';


import PaymentIcon from '@material-ui/icons/Payment';
import Chip from '@material-ui/core/Chip';

import {  useSelector, useDispatch } from 'react-redux';
import { updatePaymentType } from 'store/actions/order';




const useStyles = makeStyles((theme) => ({
  formControl: {
   
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

const Payments = (props) => {
  const { optional, restaurant } = props;

  const dispatch = useDispatch();

  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [error] = React.useState(false);
  const order = useSelector((state) => state.order);
  
  const setPaymentType = (type) => dispatch(updatePaymentType(type));

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setPaymentType(event.target.value);
  };

  useEffect(() => {
    if (order.paymentType) {
      setValue(order.paymentType);
    }
  }
  , [order.paymentType]);

  const isPaypalEnabled = () => {
    // console.log(restaurant.onlineSettings)
       if (restaurant && restaurant.onlineSettings && restaurant.onlineSettings.isPaypalEnabled === true) {
         return true;
       }
       return false;
  };


  return (
    <>
    <Grid>
    <Chip className={classes.formTitle} variant="outlined" color="primary" size="small" icon={<PaymentIcon />} label='Payment Method' />
    </Grid>
    <Grid style={{ marginTop: 4}}>
      <FormControl component="fieldset" error={error} className={classes.formControl}>
        
        <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
        <FormControlLabel value="card" control={<Radio color="primary" />} label={
           <div>
            <PaymentIcon />
         </div>
          } />
          {/* <FormControlLabel value="alipay" control={<Radio color="primary"/>} label={
            <img alt='alipay' style={{width:30, height:30 }}src='/images/icons/alipay.png'/>} />
          */}
          { restaurant.onlineSettings && restaurant.onlineSettings.isWechatOnlinePaymentEnabled === true ?
          <FormControlLabel value="wechat" control={<Radio color="primary"/>} label={
            <img alt='wechat pay' style={{width:30, height:30 }}src='/images/icons/wechatpay.png'/>} />: null
        } 
          { optional ? 
          <FormControlLabel value="payAtCounter" control={<Radio color="primary"/>} label="Pay at Counter" /> : null}
          
          { isPaypalEnabled() ?  <FormControlLabel value="paypal" control={<Radio color="primary"/>} label={
            <img alt='paypal' style={{width:60, height:60 }}src='/images/icons/paypal.png'/>} /> : null}
        </RadioGroup>
      </FormControl>
      </Grid>
     </>
  )
}

export default Payments;
