
export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';
export const SNACKBAR_ERROR = 'SNACKBAR_ERROR';

export const showSuccessSnackbar = message => ({
    type: SNACKBAR_SUCCESS,
    payload: message
    });

  export const showErrorSnackbar = message => ({
    type: SNACKBAR_ERROR,
    payload: message
    });
  
  export const clearSnackbar = () => ({
    type: SNACKBAR_CLEAR
    });