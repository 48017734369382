import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import PaymentIcon from "@material-ui/icons/Payment";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SettingsIcon from "@material-ui/icons/Settings";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import NextWeekIcon from "@material-ui/icons/NextWeek";
// import AppleIcon from "@material-ui/icons/Apple";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
// import PieChartIcon from "@material-ui/icons/PieChart";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import ExploreIcon from "@material-ui/icons/Explore";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DevicesIcon from "@material-ui/icons/Devices";
import TabletIcon from "@material-ui/icons/Tablet";
import TabletMacIcon from "@material-ui/icons/TabletMac";
import ContactlessIcon from "@material-ui/icons/Contactless";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { FcSmartphoneTablet } from "react-icons/fc";

import { Profile, SidebarNav, Logout } from "./components";

import { useSelector } from "react-redux";
import {
  checkMyRestaurantPermission,
  checkMenuPermission,
  checkOrdersPermission,
  checkTransactionPermission,
  checkReportsPermission,
  checkBookingPermission,
  checkStaffPermission,
  checkDishOrderReportPermission,
} from "util/userUtils";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const user = useSelector((state) => state.account.user);

  let pages;

  const marketingSection = {
    title: "Marketing",
    href: "",
    icon: <ExploreIcon />,
    children: [
      {
        title: "Promotions",
        href: "/promotions",
        icon: <LocalOfferIcon />,
      },
      {
        title: "Vouchers",
        href: "/newVoucher",
        icon: <LocalActivityIcon />,
      },
    ],
  };

  const dashboardSection = {
    title: "Dashboard",
    href: "/dashboard",
    icon: <DashboardIcon />,
    children: [],
  };

  const myRestaurantSection = {
    title: "My Restaurant",
    href: "/myrestaurant",
    icon: <FastfoodIcon />,
    children: [],
  };

  const transactionSection = {
    title: "Transactions",
    href: "/transactions",
    icon: <PaymentIcon />,
    children: [],
  };

  const orderSection = {
    title: "Orders",
    href: "/orders",
    icon: <ShoppingBasketIcon />,
    children: [],
  };
  const menuSection = {
    title: "Menus",
    href: "/mymenus",
    icon: <RestaurantIcon />,
    children: [],
  };

  const bookingSection = {
    title: "Booking",
    href: "",
    icon: <AssignmentIcon />,
    children: [
      {
        title: "Booking Sessions",
        href: "/bookingSessions",
        icon: <LibraryBooksIcon />,
      },
      {
        title: "Bookings",
        href: "/bookings",
        icon: <ConfirmationNumberIcon />,
      },
    ],
  };

  const accountSection = {
    title: "Account",
    href: "/account",
    icon: <AccountBoxIcon />,
    children: [],
  };

  const settingsSection = {
    title: "Settings",
    href: "/settings",
    icon: <SettingsIcon />,
    children: [],
  };

  const reportsSection = (user) => {
    const reportItems = [
      {
        title: "Sales Report",
        href: "/salesReport",
        icon: <ShowChartIcon />,
      },
      {
        title: "Payment Channels",
        href: "/paymentTypesReport",
        icon: <AccountBalanceWalletIcon />,
      },

      // {
      //   title: "Sales Details",
      //   href: "/salesDetailsReport",
      //   icon: <PieChartIcon />,
      // },

      {
        title: "Dish Report(Order)",
        href: "/dishesReport",
        icon: <EqualizerIcon />,
      },
      {
        title: "Dish Report(Transaction)",
        href: "/dishesTransactionReport",
        icon: <EqualizerIcon />,
      },
      {
        title: "Order Type",
        href: "/orderTypeReport",
        icon: <DonutSmallIcon />,
      },
    ];

    if (!checkDishOrderReportPermission(user)) {
      reportItems.splice(2, 1);
    }

    const reports = {
      title: "Reports",
      href: "",
      icon: <AssessmentIcon />,
      children: reportItems,
    };
    return reports;
  };

  const staffSection = {
    title: "Staff",
    href: "",
    icon: <PeopleIcon />,
    children: [
      {
        title: "Staff List",
        href: "/staffList",
        icon: <PeopleIcon />,
      },
      {
        title: "Staff Activities",
        href: "/staffActivities",
        icon: <AssignmentIndIcon />,
      },
      {
        title: "Timesheets",
        href: "/timesheets",
        icon: <WatchLaterIcon />,
      },
    ],
  };

  //console.log('(user.email', user && user.email);
  const groupAdminMarketAndReportSections =
    user && user.email === "steven.fu@aupremium.com.au"
      ? [marketingSection, reportsSection(user)]
      : [reportsSection(user)];

  if (user) {
    if (user.role && user.role === "admin") {
      pages = [
        {
          title: "Admin",
          href: "",
          icon: <NextWeekIcon />,
          children: [
            {
              title: "Dashboard",
              href: "/adminDashboard",
              icon: <DashboardIcon />,
            },
            // {
            //   title: "Device useage",
            //   href: "/deviceUsage",
            //   icon: <AppleIcon />,
            // },
          ],
        },
        {
          title: "Dashboard",
          href: "/dashboard",
          icon: <DashboardIcon />,
          children: [],
        },
        {
          title: "Users",
          href: "/users",
          icon: <PeopleIcon />,
          children: [],
        },
        {
          title: "Customers",
          href: "/customers",
          icon: <PeopleAltIcon />,
          children: [],
        },
        {
          title: "Restaurants",
          href: "/restaurants",
          icon: <FastfoodIcon />,
          children: [],
        },
        {
          title: "Devices",
          href: "",
          icon: <DevicesIcon />,
          children: [
            {
              title: "POS Clients",
              href: "/posClients",
              icon: <FcSmartphoneTablet />,
            },
            {
              title: "Display Clients",
              href: "/displayClients",
              icon: <FcSmartphoneTablet />,
            },
            {
              title: "POS Devices",
              href: "/posDevices",
              icon: <TabletIcon />,
            },
            {
              title: "Kiosks",
              href: "/kioskDevices",
              icon: <TabletMacIcon />,
            },
          ],
        },
        {
          title: "Adyen Payments",
          href: "",
          icon: <AccountBalanceIcon />,
          children: [
            {
              title: "Adyen Terminals",
              href: "/terminalDevices",
              icon: <ContactlessIcon />,
            },
            {
              title: "Reports",
              href: "/adyenReports",
              icon: <FileCopyIcon />,
            },
          ],
        },
        {
          title: "My Restaurant",
          href: "/myrestaurant",
          icon: <FastfoodIcon />,
          children: [],
        },
        {
          title: "Subscriptions",
          href: "/subscriptionList",
          icon: <MonetizationOnIcon />,
          children: [],
        },
        {
          title: "Transactions",
          href: "/transactions",
          icon: <PaymentIcon />,
          children: [],
        },
        {
          title: "Orders",
          href: "/orders",
          icon: <ShoppingBasketIcon />,
          children: [],
        },
        {
          title: "Menus",
          href: "/mymenus",
          icon: <RestaurantIcon />,
          children: [],
        },

        {
          title: "Booking",
          href: "",
          icon: <AssignmentIcon />,
          children: [
            {
              title: "Booking Sessions",
              href: "/bookingSessions",
              icon: <LibraryBooksIcon />,
            },
            {
              title: "Bookings",
              href: "/bookings",
              icon: <ConfirmationNumberIcon />,
            },
          ],
        },
        staffSection,
        marketingSection,

        reportsSection(user),
        {
          title: "Account",
          href: "/account",
          icon: <AccountBoxIcon />,
          children: [],
        },
        {
          title: "Settings",
          href: "/settings",
          icon: <SettingsIcon />,
          children: [],
        },
      ];
    } else if (user.role && user.role === "menuAdmin") {
      pages = [
        
       
        {
          title: "Restaurants",
          href: "/restaurants",
          icon: <FastfoodIcon />,
          children: [],
        },
      
      
        {
          title: "My Restaurant",
          href: "/myrestaurant",
          icon: <FastfoodIcon />,
          children: [],
        },
       
       
        {
          title: "Menus",
          href: "/mymenus",
          icon: <RestaurantIcon />,
          children: [],
        },
        {
          title: "Settings",
          href: "/settings",
          icon: <SettingsIcon />,
          children: [],
        },
      ];
    }  else if (user.role && user.role === "restaurantGroupAdmin") {
      pages = [
        // {
        //   title: 'Admin',
        //   href: '',
        //   icon: <NextWeekIcon />,
        //   children: [
        //     {
        //       title: 'Dashboard',
        //       href: '/adminDashboard',
        //       icon: <DashboardIcon />
        //     },
        //     {
        //       title: 'Device useage',
        //       href: '/deviceUsage',
        //       icon: <AppleIcon />
        //     }
        //   ]
        // },
        {
          title: "Dashboard",
          href: "/dashboard",
          icon: <DashboardIcon />,
          children: [],
        },

        {
          title: "Customers",
          href: "/customers",
          icon: <PeopleAltIcon />,
          children: [],
        },
        {
          title: "Restaurants",
          href: "/restaurants",
          icon: <FastfoodIcon />,
          children: [],
        },
        {
          title: "Transactions",
          href: "/transactions",
          icon: <PaymentIcon />,
          children: [],
        },
        {
          title: "Orders",
          href: "/orders",
          icon: <ShoppingBasketIcon />,
          children: [],
        },
        {
          title: "Menus",
          href: "/mymenus",
          icon: <RestaurantIcon />,
          children: [],
        },

        {
          title: "Booking",
          href: "",
          icon: <AssignmentIcon />,
          children: [
            {
              title: "Booking Sessions",
              href: "/bookingSessions",
              icon: <LibraryBooksIcon />,
            },
            {
              title: "Bookings",
              href: "/bookings",
              icon: <ConfirmationNumberIcon />,
            },
          ],
        },
        staffSection,
        ...groupAdminMarketAndReportSections,
        {
          title: "Account",
          href: "/account",
          icon: <AccountBoxIcon />,
          children: [],
        },
        {
          title: "Settings",
          href: "/settings",
          icon: <SettingsIcon />,
          children: [],
        },
      ];
    } else if (user.role && user.role === "restaurantAdmin") {
      pages = [
        dashboardSection,

        {
          title: "Customers",
          href: "/customers",
          icon: <PeopleAltIcon />,
          children: [],
        },
        myRestaurantSection,
        transactionSection,
        orderSection,
        menuSection,

        bookingSection,
        staffSection,
        marketingSection,
        reportsSection(user),
        accountSection,
        settingsSection,
      ];
    } else if (user.role && user.role === "restaurantReport") {
      pages = [dashboardSection, reportsSection(user)];
    } else if (user.role && user.role === "restaurantManager") {
      if (!user.permissions || user.permissions.length === 0) {
        pages = [accountSection, settingsSection];
      } else {
        pages = [accountSection, settingsSection];

        if (checkOrdersPermission(user)) {
          pages.unshift(orderSection);
        }

        if (checkTransactionPermission(user)) {
          pages.unshift(transactionSection);
        }

        if (checkMenuPermission(user)) {
          pages.unshift(menuSection);
        }

        if (checkReportsPermission(user)) {
          pages.unshift(reportsSection(user));
        }

        if (checkBookingPermission(user)) {
          pages.unshift(bookingSection);
        }
        if (checkStaffPermission(user)) {
          pages.unshift(staffSection);
        }

        if (checkMyRestaurantPermission(user)) {
          pages.unshift(myRestaurantSection);
        }
      }
    } else {
      pages = [];
    }
  } else {
    pages = [];
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
        <Logout />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
