import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";


import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";

import { NavLink } from "react-router-dom";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import { useSelector, useDispatch } from "react-redux";
import { setOrderSelectedDate } from "store/actions/restaurant";

import { checkDelTransactionsPermission, checkStaffPermission } from "util/userUtils";

import { showErrorSnackbar } from "store/actions/snackbarMessage";
import TransactionDeleteDialog from "./TransactionDeleteDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  rightSpacer: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const TransactionsToolbar = (props) => {
  const { className, canDeleteTransactions, canDeleteInvoiceTransctions, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const transactions = useSelector((state) => state.transaction.transactions);

  const setOrderDate = (date) => dispatch(setOrderSelectedDate(date));
  const selectedDate = useSelector((state) => state.restaurant.orderSelectedDate);
  const selectedTransactionIds = useSelector((state) => state.transaction.ids);
  const user = useSelector((state) => state.account.user);
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const handleDateChange = (date) => {
    // console.log("date", date);
    setOrderDate(date);
    history.push("/transactions");
  };

  const deleteTransactions = () => {
    //console.log("selectedTransactionIds", selectedTransactionIds);
    if (selectedTransactionIds && selectedTransactionIds.length === 0) {
      showErrorMessage("Please select a transaction.");
      return;
    }

    // check transaction is inovice printed or not
    const selectedTransactions = transactions.filter((transaction) =>
      selectedTransactionIds.includes(transaction.uuid)
    );

    const hasInvoicePrintedTransactions =
      selectedTransactions.filter((transaction) => transaction.isInvoicePrinted).length > 0;

    // console.log('Deleting transactions', selectedTransactionIds, hasInvoicePrintedTransactions, selectedTransactions, transactions);
    if (!canDeleteInvoiceTransctions && hasInvoicePrintedTransactions) {
      showErrorMessage("Cannot delete transactions with invoice printed");
      return;
    }

    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const deleteTran = () => {
    if (deleteReason.length === 0) {
      showErrorMessage("Please select or enter a reason.");
      return;
    }
    props.deleteProxy(deleteReason);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        {user && checkStaffPermission(user) ? (
          <>
            <NavLink to={"/staffActivities"}>
              <Button variant="outlined" color="primary">
                Staff Activities
              </Button>
            </NavLink>
            <span className={classes.rightSpacer} />
          </>
        ) : null}

        {user && checkDelTransactionsPermission(user) && canDeleteTransactions === true ? (
         <Button variant="outlined" color="primary" onClick={deleteTransactions} disabled={buttonLoading}>
         Remove Invalid Transactions
       </Button>
          // <IconButton aria-label="delete" >
          //   <DeleteIcon />
          // </IconButton>
        ) : null}
      </div>
    
      <div className={classes.row}>
        {/* <SearchInput
          className={classes.searchInput}
          placeholder="Search order"
        /> */}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            autoOk={true}
            id="date-picker-inline"
            label="Transaction Date "
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <span className={classes.spacer} />
        <TransactionDeleteDialog
          open={deleteDialogOpen}
          deleteProxy={deleteTran}
          close={closeDeleteDialog}
          updateReason={setDeleteReason}
          reason={deleteReason}
        />
      </div>
     
    </div>
  );
};

TransactionsToolbar.propTypes = {
  className: PropTypes.string,
};

export default TransactionsToolbar;
