import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import moment from "moment";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const OperationHistory = (props) => {
  const { transaction, currencySymbol } = props;
  const classes = useStyles();
  const history = useHistory();

  const paymentTime = (time) => {
    if (!time) {
      return "";
    }

     console.log('date', time);
    if (typeof time !== "object") {
      return moment(new Date(Number(time) * 1000)).format(
        "DD/MM/YYYY HH:mm:ss"
      );
    }
    if (typeof time === "object"  && "_seconds" in time) {
      return moment(new Date(time._seconds * 1000)).format(
        "DD/MM/YYYY HH:mm:ss"
      );
    }
    return moment(time).format("DD/MM/YYYY HH:mm:ss");
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Operation Type </TableCell>
            <TableCell align="right">Operation Detail</TableCell>
            <TableCell align="right">Operation Amount</TableCell>
            <TableCell align="right">Update Time</TableCell>
            <TableCell align="right">Update By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transaction &&
            transaction.operationHistory &&
            transaction.operationHistory.map((row) => (
              <TableRow key={row.operationType}>
                <TableCell>{row.operationType}</TableCell>

                <TableCell align="right">{row.operationName}</TableCell>
                <TableCell align="right">{currencySymbol}{row.price.toFixed(2)}</TableCell>
                <TableCell align="right">
                  {paymentTime(row.updatedAt)}
                </TableCell>
                <TableCell align="right">{row.updatedBy}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OperationHistory;
