import React, { useState, useEffect } from "react";

import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  LinearProgress,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import { useHistory } from "react-router-dom";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";

import moment from "moment";

import CloseDateList from "./CloseDateList";
import API from "util/API";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const CloseDates = (props) => {
  const classes = useStyles();
  const { closeDates, sessions, isLoading} = props;
  const dispatch = useDispatch();
  const initialState = {
    closeDate: Date(),
  };

  const history = useHistory();

  const [closeDaysItems, setCloseDaysItems] = useState([]);
  const [uiLoading, setUiLoading] = useState(false);

  const [values, setValues] = useState(initialState);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
  const [selectedSessions, setSelectedSessions] = useState([]);

  useEffect(() => {
    if (closeDates) {
      console.log('closeDates', closeDates);
      setCloseDaysItems(closeDates);
    }
  }, [closeDates]);

  // merge session id from close days items
  const getCloseDates = (items) => {
    const closeDates = {};
    items.forEach((closeDate) => {
      if (closeDate.sessions) {
        closeDate.sessions.forEach((session) => {
          if (session.sessionId in closeDates) {
            closeDates[session.sessionId] = [
              ...closeDates[session.sessionId],
              closeDate.date,
            ];
          } else {
            closeDates[session.sessionId] = [closeDate.date];
          }
        });
      }
    });
    return getCloseDatesList(closeDates);
  };

  // transfer dictionary to a list
  const getCloseDatesList = (closeDates) => {
    const closeDatesList = [];
    Object.keys(closeDates).forEach((sessionId) => {
      closeDatesList.push({
        sessionId,
        closeDates: closeDates[sessionId],
      });
    });
    return closeDatesList;
  };



  const updateCloseDates = async () => {
    try {
      setUiLoading(true);
      const response = await API.post("/bookingSessions", {
        closeDates: getCloseDates(closeDaysItems),
        sessionIds: selectedSessions.map((session) => session.id)
      });

      setUiLoading(false);
      showSuccess("Close Dates Updated Successfully");
    } catch (error) {
      console.log(JSON.stringify(error));
      setUiLoading(false);
      showError("Error in updating the close dates");
    }
  };

  const handleDateChange = (date) => {
    console.log("date", date);

    setValues({ ...values, closeDate: date });
  };

  const addNewCloseDate = () => {
    const newDate = moment(values.closeDate).format("DD/MM/YYYY");
    const newItem = {
      date: newDate,
      sessions: selectedSessions.map((s) => ({
        sessionId: s.id,
        sessionName: s.name,
      })),
    };

    let updateItems = [...closeDaysItems];
    updateItems = updateItems.filter((item) => item.date !== newDate);

    updateItems.push(newItem);

    setCloseDaysItems(updateItems);

    console.log("closeDaysItems", closeDaysItems);
  };

  const removeItemWithSlice = (items, index) => {
    let newSelectedOptions = [];

    if (index === 0) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(1));
    } else if (index === items.length - 1) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(0, -1));
    } else if (index > 0) {
      newSelectedOptions = newSelectedOptions.concat(
        items.slice(0, index),
        items.slice(index + 1)
      );
    }
    return newSelectedOptions;
  };

  const deleteCloseDateItem = (selectedItem) => {
    const idx = closeDaysItems.findIndex(
      (item) => item.date === selectedItem.date
    );
    console.log("idx", idx, closeDaysItems, selectedItem);
    if (idx === -1) {
      return;
    }
    setCloseDaysItems(removeItemWithSlice(closeDaysItems, idx));
  };

  const handleTagChange = (event, code) => {
    const foundIndex = selectedSessions.findIndex(
      (session) => session.id === code
    );
    if (foundIndex !== -1) {
      setSelectedSessions(
        selectedSessions.filter((session) => session.id !== code)
      );
    } else {
      const session = sessions.find((item) => item.id === code);
      if (session) {
        console.log(session);

        setSelectedSessions([...selectedSessions, session]);
      }
    }
  };

  const isSessionSelected = (code) => {
    // console.log(JSON.stringify(selectedPermissions));
    return selectedSessions.find((session) => session.id === code);
  };

  return (
    <div>
      {uiLoading ? <LinearProgress /> : null}
      <Card className={clsx(classes.root, classes)}>
        <CardHeader
          title="Booking Close Dates"
          subheader="Update close dates"
        />
        <CardContent>
          <Grid item>
            <CloseDateList
              closeDaysItems={closeDaysItems}
              deleteCloseDateItem={deleteCloseDateItem}
            />
          </Grid>

          <Grid item>
            <FormGroup row>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  name="closeDate"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Choose Close Date "
                  value={values.closeDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "choose date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormGroup>
          </Grid>

          <Grid item>
            {sessions.length > 0 ? (
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Booking Sessions</FormLabel>
                  <FormGroup row>
                    {sessions.map((session) => {
                      return (
                        <FormControlLabel
                          key={session.id}
                          control={
                            <Checkbox
                              checked={isSessionSelected(session.id)}
                              onChange={(e) => handleTagChange(e, session.id)}
                              name={session.name}
                            />
                          }
                          label={session.name}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>

          <Grid item>
            <FormGroup row>
              <Button
                variant="outlined"
                color="primary"
                onClick={addNewCloseDate}
              >
                Add New Close Date
              </Button>
            </FormGroup>
          </Grid>

          <CardActions>
            <Button
              variant="contained"
              onClick={updateCloseDates}
              color="primary"
              disabled={uiLoading || isLoading}
            >
              Save
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};

export default CloseDates;
