import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CloseDates from "./CloseDates/CloseDates";
import SettingDetails from "./SettingDetails";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "store/actions/snackbarMessage";
import { useDispatch } from "react-redux";
import API from "util/API";
import LinearProgress from "@material-ui/core/LinearProgress";



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const BookingSettings = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uiLoading, setUiLoading] = useState(false);
  const [closeDates, setCloseDates] = useState([]);
  const [bookingSessions, setBookingSessions] = useState([]);
  const [bookingSettings, setBookingSettings] = useState(null);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));

  const getBookingSettings = async () => {
    try {
      setUiLoading(true);
      const response = await API.get("/bookingSettings");
      console.log("response", response);
      setUiLoading(false);
      if (response && response.data) {
        if (response.data.settings) {
          setBookingSettings(response.data.settings);
        }
        if (response.data.closeDates) {
          setCloseDates(response.data.closeDates);
        }
        if (response.data.sessions) {
          setBookingSessions(response.data.sessions);
        }
      }
    } catch (err) {
      console.log(err);
      setUiLoading(false);
      showError("Something went wrong, please try again later");
    }   
  };



  const updateSettings = async (changes) => {
    try {
      setUiLoading(true);
      let updates = changes;
      if (bookingSettings) {
        updates = {
          ...bookingSettings,
          ...updates
        };
      }
      const response = await API.post("/bookingSettings", updates);

      setUiLoading(false);
      showSuccess("Settings Updated Successfully");
    } catch (error) {
      console.log(JSON.stringify(error));
      setUiLoading(false);
      showError("Error in updating the settings");
    }
  };

  useEffect(() => {
    getBookingSettings();
  }, [props]);

  return (
    <div className={classes.root}>
       {uiLoading ? <LinearProgress style = {{ marginBottom: 8 }}/> : null}
       <Grid container spacing={4} direction="column">
         <Grid item>
      <CloseDates closeDates={closeDates} sessions={bookingSessions} isLoading={uiLoading} />
      </Grid>
      <Grid item>
      
      <SettingDetails settings={bookingSettings} updateSettings={updateSettings} isLoading={uiLoading}/>
      </Grid>
      </Grid>
    </div>
  );
};

BookingSettings.propTypes = {
  className: PropTypes.string,
};

export default BookingSettings;
