import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import {
  Card,
  TextField,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import { useSelector, useDispatch } from "react-redux";
import { updateRestaurantValues } from "store/actions/restaurant";
import { removeItemWithSlice } from "util/utils";

import { showErrorSnackbar } from "store/actions/snackbarMessage";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: theme.spacing(1),
    minHeight: 500,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },

  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const ServiceBellTypesTable = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [serviceType, setServiceType] = useState("");
  const updateServiceBellTypes = (types) => dispatch(updateRestaurantValues(types));
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  const save = () => {
    if (!restaurant) {
      showError("Please open my restaurant first.");
      return;
    }

    if (serviceType.trim() === "") {
      showError("The service type cannot be empty");
      return;
    }

    let serviceTypes = [];
    if (restaurant.serviceTypes) {
      serviceTypes = [...restaurant.serviceTypes, serviceType];
    } else {
      serviceTypes = [serviceType];
    }
    updateServiceBellTypes({ serviceTypes });
    setServiceType("");
  };

  const handleDelete = (type) => {
    const deleteIndx = restaurant.serviceTypes.findIndex(
      (item) => item === type
    );
    if (deleteIndx !== -1) {
      const serviceTypes = removeItemWithSlice(
        restaurant.serviceTypes,
        deleteIndx
      );
      updateServiceBellTypes({ serviceTypes });
      setServiceType('');
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item md={3} xs={6}>
            <TextField
              fullWidth
              label="Service Type"
              margin="dense"
              name="serviceType"
              variant="outlined"
              value={serviceType}
              onChange={(e) => setServiceType(e.target.value)}
            />
          </Grid>

          <Grid item>
            <Button variant="outlined" color="primary" onClick={save}>
              Save
            </Button>
          </Grid>
        </Grid>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service Type</TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurant &&
                restaurant.serviceTypes &&
                restaurant.serviceTypes.map((type) => (
                  <TableRow className={classes.tableRow} hover key={type}>
                    <TableCell>
                      <Typography>{type}</Typography>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        edge="end"
                        color="secondary"
                        onClick={(e) => handleDelete(type)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

ServiceBellTypesTable.propTypes = {
  className: PropTypes.string,
  // cookingCategories: PropTypes.array.isRequired
};

export default ServiceBellTypesTable;
