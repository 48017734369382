
import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearProgress from "@material-ui/core/LinearProgress";


import { showErrorSnackbar } from 'store/actions/snackbarMessage';
import { useDispatch } from 'react-redux';
import { getTodayDishReport } from "apis/reportAPIs";
import { useQuery } from "react-query";



const useStyles = makeStyles((theme) => ({
    root: {

    },
    table: {


    },
    top: {
        marginTop: theme.spacing(1)
    }
}));



const DishReport = (props) => {
    const { user, currencySymbol } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
  
    
    const [reportData, setReportData] = useState([]);
    const theme = useTheme();
    const showError = (msg) => dispatch(showErrorSnackbar(msg));


    
    const { data, isLoading, error } = useQuery({
        queryKey: 'todayDishReport',
        queryFn: () => getTodayDishReport(),
        enabled: user && user.role !== 'restaurantManager',
        cacheTime: 1 * 60 * 1000, // 1 minutes
      });
    


    return (
        <div className={classes.root}>
        {isLoading ? <LinearProgress style = {{ marginBottom: 8,  marginTop: 8 }}/> : null}
          <Typography variant="h5" gutterBottom>
               Today Dish Report
            </Typography>
       
            <TableContainer component={Paper} className={classes.top}>
                <Table className={classes.table} aria-label="dish table">
               
                  
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data  && data.length > 0 && data.map((item, index) => {
                            return (
                                <TableRow key={item.name}>
                                    <TableCell component="th" scope="row" >

                                        {index + 1}

                                    </TableCell>
                                  
                                    <TableCell align="left">
                                    <Typography>
                                        {item.dishName}
                                        </Typography>
                                        <Typography>
                                        {item.altName}
                                        </Typography>
                                        </TableCell>
                                    <TableCell align="right">{item.totalCount}</TableCell>
                                    <TableCell align="right">{currencySymbol}{!isNaN(item.totalSales) ? Number(item.totalSales).toFixed(2): item.totalSales}</TableCell>

                                </TableRow>
                            )
                        })}


                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
}

export default DishReport;
