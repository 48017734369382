import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { updateMenuItem } from "store/actions/restaurantMenu";
import {  useDispatch } from "react-redux";




const useStyles = makeStyles((theme) => ({
  compactListItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    // Adjust these values as needed
  },
}));

const ItemsDialog = ({
  isOpen,
  onClose,
  initialItems,
  selectedCookingCategory,
  categories,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedCate, setSelectedCate] = useState(null);
  const [deletedItems, setDeletedItems] = useState([]);
  const [addedItems, setAddedItems] = useState([]);



  const updateTheMenuItem = (item, values) =>
  dispatch(updateMenuItem(item, values));
  //   console.log("initialItems", initialItems);

  React.useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  React.useEffect(() => {
    setSelectedCate(selectedCookingCategory);
  }, [selectedCookingCategory]);

  const handleAddItem = () => {
    if (!selectedCategory || !selectedItem) {
      console.log("selectedCategory", selectedCategory, selectedItem);
      return;
    }

    const newItem = categories
      .find((cat) => cat.name === selectedCategory)
      .items.find((item) => item.id === selectedItem);
    if (newItem) {
      setItems([...items, newItem]);
      setAddedItems([...addedItems, newItem]);
      setSelectedItem("");
    } else {
      console.log("selectedCategory", selectedCategory, selectedItem);
    }
  };

  const handleDeleteItem = (itemId) => {
    const itemToDelete = items.find(item => item.id === itemId);
    setDeletedItems([...deletedItems, itemToDelete]);
    setItems(items.filter((item) => item.id !== itemId));
  };

  const updateChangedItems = () => {
    const isForKDS = selectedCookingCategory.isForKDS;
    const updatedItems = addedItems.map(item => {
        if (isForKDS) {
            return {...item, cookingCategory: selectedCookingCategory.name};
        } else {
            // append selectedCookingCategory.name to item.printCategory if not already there and sperated by semicolon
            if (item.printCategory) {
            const printCategories = item.printCategory.split(';');
            if (printCategories.includes(selectedCookingCategory.name)) {
                return item;
            }
            return {...item, printCategory: item.printCategory + ';' + selectedCookingCategory.name};
        } else {
            return {...item, printCategory: selectedCookingCategory.name};
        }
        }
    });

    const deletedItemIds = deletedItems.map(item => {
        if (isForKDS)  {
            return {...item, cookingCategory: null};
        } else {
            // remove selectedCookingCategory.name from item.printCategory if it is there
            if (item.printCategory) {
                const printCategories = item.printCategory.split(';');
                if (printCategories.includes(selectedCookingCategory.name)) {
                    return {...item, printCategory: printCategories.filter(cat => cat !== selectedCookingCategory.name).join(';')};
                }
            } else {
                if (item.cookingCategory === selectedCookingCategory.name) {
                    return {...item, cookingCategory: null};
                }
            }
            return item;
        }
    }
    );

    const changedItems = [...updatedItems, ...deletedItemIds];
    console.log("changedItems", changedItems);
    for (const item of changedItems) {
        updateTheMenuItem(item, categories);
    }
    setAddedItems([]);
    setDeletedItems([]);
    onClose();

    };


        


  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Cooking/Print Category - {selectedCate && selectedCate.name}
      </DialogTitle>
      <DialogContent style={{ height: 600, width: 800 }}>
        <List disablePadding dense={true}>
          {items.map((item) => (
            <ListItem key={item.id} className={classes.compactListItem}>
              {item.name} -{item.altName}
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteItem(item.id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        Select a Category
        <br />
        <Select
          fullWidth
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          placeholder="Select Category"
        >
          {categories.map((cat) => (
            <MenuItem key={cat.name} value={cat.name}>
              {cat.name}
            </MenuItem>
          ))}
        </Select>
        Select an Item
        <br />
        <Select
          fullWidth
          value={selectedItem}
          onChange={(e) => setSelectedItem(e.target.value)}
          placeholder="Select Item"
        >
          {selectedCategory &&
            categories
              .find((cat) => cat.name === selectedCategory)
              .items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
        </Select>
        <Button onClick={handleAddItem} color="primary">
          Add Item
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={updateChangedItems} color="primary">
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemsDialog;
