export const ADD_OPTION = 'ADD_OPTION';
export const UPDATE_OPTION_QUANTITY = 'UPDATE_OPTION_QUANTITY';
export const REMOVE_OPTION = 'REMOVE_OPTION';
export const CHOOSE_SIZE= 'CHOOSE_SIZE';
export const CLEAR_DISH= 'CLEAR_DISH';
export const ADD_DISH= 'ADD_DISH';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY'
export const UPDATE_SPECIAL= 'UPDATE_SPECIAL'
export const UPDATE_SUBMIT_STATUS= 'UPDATE_SUBMIT_STATUS'


export const addOption = (option) => ({
    type: ADD_OPTION,
    payload: option
});

export const removeOption = option => ({
    type: REMOVE_OPTION,
    payload: option
});

export const updateOptionQty = (id, quantity) => ({
    type: UPDATE_OPTION_QUANTITY,
    payload: {id: id, quantity: quantity}
});

export const chooseSize = size => ({
    type: CHOOSE_SIZE,
    payload: size
});

export const clearDish = () => ({
    type: CLEAR_DISH
});

export const addDish = (dish) => ({
    type: ADD_DISH,
    payload: dish
});

export const updateQuantity = (newQuantity) => ({
    type: UPDATE_QUANTITY,
    payload: newQuantity
});

export const updateSpecial = (requirement) => ({
    type: UPDATE_SPECIAL,
    payload: requirement
});

export const updateSubmitStatus = (flag) => ({
    type: UPDATE_SUBMIT_STATUS,
    payload: flag
});

export const UPDATE_IS_TAKEAWAY= 'UPDATE_IS_TAKEAWAY'
export const updateIsFortakeaway = (isTakeaway) => ({
    type: UPDATE_IS_TAKEAWAY,
    payload: isTakeaway
});

