import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import Divider from "@material-ui/core/Divider";

import palette from 'theme/palette';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import SaleHoursReport from "../SaleHours";

import API from "util/API";
import { showErrorSnackbar } from "store/actions/snackbarMessage";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  table: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const SalesDetaislReport = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reportData, setReportData] = useState({});
  const [hourReportData, setHourReportData] = useState({});
  const [dateReportData, setDateReportData] = useState([]);
  const [selectedWeekday, setSelectedWeekday] = useState('All');
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const [hourTotal, setHoursTotal] = useState(0);


  useEffect(() => {
    buildDateDataSet();
  }, [dateReportData, selectedWeekday]);

  const formateDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const buildHourDataSet = (data) => {
    const total = data.reduce(function(sum, current) {  
      return sum + current.amount;
    }, 0);
    setHoursTotal(total);

    const labelList = data.map(item => item.hour);
    setHourReportData({
      labels: labelList,
      datasets: [
        {
          label: 'Sales',
          backgroundColor: palette.primary.main,
          data: data.map(item => item.amount)
        }
      ]
    });
  };


  const buildDateDataSet = () => {
    let weekdaysData = dateReportData;


    if (selectedWeekday !== 'All') {
      weekdaysData = weekdaysData.filter(item => item.weeday === selectedWeekday);
    }

    setReportData({
      labels: weekdaysData.map(item => item.date.format('DD/MM')),
      datasets: [
        {
          label: 'Sales',
          backgroundColor: palette.primary.main,
          data: weekdaysData.map(item => item.amount)
        }
      ]
    });
  };


  const getHourReportData = async () => {
    if (endDate < startDate) {
      showError("The end date cannot be later than the start date!");
      return;
    }

    try {
      const startDateStr = formateDate(startDate);
      const endDateStr = formateDate(endDate);
      const rewsponse = await API.get(
        `/salesHoursReport?startDateStr=${startDateStr}&endDateStr=${endDateStr}`
      );
      const data = await rewsponse.data;
      //console.log('data', data);

      if (data) {
        buildHourDataSet(data);
      } else {
        setHourReportData({});
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      setHourReportData({});
      showError("Something went wrong, please try again.");
    }
  };


  const getDateReportData = async () => {
    if (endDate < startDate) {
      showError("The end date cannot be later than the start date!");
      return;
    }

    try {
      const startDateStr = formateDate(startDate);
      const endDateStr = formateDate(endDate);
      const rewsponse = await API.get(
        `/salesByDates?startDateStr=${startDateStr}&endDateStr=${endDateStr}`
      );
      const data = await rewsponse.data;
      //console.log('data', data);

      if (data) {
        setDateReportData(data.map(item => ({ ...item, weeday: moment(item.date).format('ddd'), date: moment(item.date) })));
      } else {
        setReportData({});
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      setReportData({});
      showError("Something went wrong, please try again.");
    }
  };

  const queryDate = async () => {
    setIsLoading(true);
    setHourReportData([]);
    setReportData([]);
    await getDateReportData();
    await getHourReportData();
    setIsLoading(false);
  };

  const handleRadioChange = (event) => {
    setSelectedWeekday(event.target.value);
  };

  return (
    <div className={classes.root}>
      {isLoading ? <LinearProgress style={{ marginBottom: 8 }} /> : null}
      <Grid container spacing={1}>
        <Grid
          item
          lg={6}
          md={8}
          xl={6}
          xs={12}
          container
          direction="row"
          alignItems="center"
        >
          <Typography style={{ margin: theme.spacing(1) }}>
            Start Date
          </Typography>
          <DatePicker
            selected={startDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setStartDate(date)}
          />
          <Typography style={{ margin: theme.spacing(1) }}>End Date</Typography>
          <DatePicker
            selected={endDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setEndDate(date)}
          />

          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: theme.spacing(1) }}
            onClick={(e) => queryDate()}
          >
            Query
          </Button>
        </Grid>
        <Grid item lg={8} md={8} xl={8} xs={12}>
          <Typography style={{ margin: theme.spacing(1) }}>
            {formateDate(startDate)} - {formateDate(endDate)}
          </Typography>

          <SaleHoursReport data={hourReportData} title='Order Hour Report' total={hourTotal} />
          <Divider style={{ marginTop: 8, marginBottom: 8 }} />

          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Weekday Filter</FormLabel>
            <RadioGroup aria-label="weekday" name="weekday" value={selectedWeekday} onChange={handleRadioChange} row>
              <FormControlLabel value="Mon" control={<Radio />} label="Mon" />
              <FormControlLabel value="Tue" control={<Radio />} label="Tue" />
              <FormControlLabel value="Wed" control={<Radio />} label="Wed" />
              <FormControlLabel value="Thu" control={<Radio />} label="Thu" />
              <FormControlLabel value="Fri" control={<Radio />} label="Fri" />
              <FormControlLabel value="Sat" control={<Radio />} label="Sat" />
              <FormControlLabel value="Sun" control={<Radio />} label="Sun" />
              <FormControlLabel value="All" control={<Radio />} label="All" />
            </RadioGroup>

          </FormControl>

          <SaleHoursReport data={reportData} title='Sales Weekday Report' />

        </Grid>
      </Grid>
    </div>
  );
};

export default SalesDetaislReport;
