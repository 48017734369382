import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { useHistory } from "react-router-dom";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";

import moment from "moment";
import { v4 as uuid } from "uuid";

import OpenHoursList from "./OpenHoursList";
import API from "util/API";
import { authMiddleWare } from "../../util/auth";
import { useSelector } from "react-redux";
import { TimePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const OpenHours = (props) => {
  const classes = useStyles();

  const initialState = {
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true,
    openStart: "",
    openEnd: "",
    closeDate: Date(),
  };

  const history = useHistory();

  const [openHoursItems, setOpenHoursItems] = useState([]);
  const [closeDaysItems, setCloseDaysItems] = useState([]);
  const [uiLoading, setUiLoading] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const [values, setValues] = useState(initialState);
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );
  const user = useSelector((state) => state.account.user);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  useEffect(() => {
    if (restaurant && restaurant.openHours) {
      setOpenHoursItems(restaurant.openHours.openHoursItems);
      setCloseDaysItems(restaurant.openHours.closeDaysItems);
    }
  }, restaurant);

  const handStartTimeChange = (date) => {
    setStartTime(date);
    setValues({
      ...values,
      openStart: moment(date).format("HH:mm"),
      openEnd: moment(endTime).format("HH:mm"),
    });
  };

  const handEndTimeChange = (date) => {
    setEndTime(date);
    setValues({
      ...values,
      openStart: moment(startTime).format("HH:mm"),
      openEnd: moment(date).format("HH:mm"),
    });
  };

  const handleErrorClose = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value, type } = e.target;
    console.log("name", name, value, type);
    if (type === "checkbox") {
      setValues({ ...values, [name]: !values[name] });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const updateOpenHours = () => {
    setValues({
      ...values,
      openStart: moment(startTime).format("HH:mm"),
      openEnd: moment(endTime).format("HH:mm"),
    });
    console.log(values.openStart, values.openEnd);
    authMiddleWare(props.history);
    const authToken = localStorage.getItem("AuthToken");
    API.defaults.headers.common["Authorization"] = authToken;
    setUiLoading(true);
    API.put("/restaurantOpenHours/" + restaurant.urlIdentifier, {
      openHours: { openHoursItems, closeDaysItems },
    })
      .then((response) => {
        setUiLoading(false);
        if (user.role === "restaurantAdmin") {
          history.push("/myRestaurant");
        } else {
          history.push("/restaurants");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          props.history.push("/sign-in");
        }
        console.log(JSON.stringify(error));
        setUiLoading(false);
        setOpenErrorSnackbar(true);
      });
  };

  const handleDateChange = (date) => {
    console.log("date", date);

    setValues({ ...values, closeDate: date });
  };

  const isOpenHoursInValid = () => {
    console.log(values.openStart, values.openEnd);
    return moment(endTime).isSameOrBefore(moment(startTime));
  };

  const addNewOpenHours = () => {
    if (isOpenHoursInValid()) {
      return;
    }
    let weekdays = [];
    if (values.Monday) weekdays.push("Monday");
    if (values.Tuesday) weekdays.push("Tuesday");
    if (values.Wednesday) weekdays.push("Wednesday");
    if (values.Thursday) weekdays.push("Thursday");
    if (values.Friday) weekdays.push("Friday");
    if (values.Saturday) weekdays.push("Saturday");
    if (values.Sunday) weekdays.push("Sunday");

    const newItem = {
      id: uuid(),
      weekdays,
      openStart: values.openStart,
      openEnd: values.openEnd,
    };

    let updateItems = [...openHoursItems];

    updateItems.push(newItem);

    setOpenHoursItems(updateItems);

    console.log("openHoursItems", openHoursItems);
  };

  const addNewCloseDate = () => {
    const newItem = moment(values.closeDate).format("DD/MM/YYYY");
    let updateItems = [...closeDaysItems];
    if (updateItems.findIndex((item) => item === newItem) > -1) {
      return;
    }

    updateItems.push(newItem);

    setCloseDaysItems(updateItems);

    console.log("closeDaysItems", closeDaysItems);
  };

  const removeItemWithSlice = (items, index) => {
    let newSelectedOptions = [];

    if (index === 0) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(1));
    } else if (index === items.length - 1) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(0, -1));
    } else if (index > 0) {
      newSelectedOptions = newSelectedOptions.concat(
        items.slice(0, index),
        items.slice(index + 1)
      );
    }
    return newSelectedOptions;
  };

  const deleteOpenHourItem = (id) => {
    const idx = openHoursItems.findIndex((item) => item.id === id);
    if (idx === -1) {
      return;
    }
    setOpenHoursItems(removeItemWithSlice(openHoursItems, idx));
  };

  const deleteCloseDateItem = (date) => {
    const idx = closeDaysItems.findIndex((item) => item === date);
    console.log("idx", idx, closeDaysItems, date);
    if (idx === -1) {
      return;
    }
    setCloseDaysItems(removeItemWithSlice(closeDaysItems, idx));
  };

  return (
    <div>
      {uiLoading ? <LinearProgress /> : null}
      <Card className={clsx(classes.root, classes)}>
        <CardHeader
          title="Open Hours"
          subheader="Update open hours and close dates"
        />
        <CardContent>
          <Grid item>
            <OpenHoursList
              openHoursItems={openHoursItems}
              closeDaysItems={closeDaysItems}
              deleteOpenHourItem={deleteOpenHourItem}
              deleteCloseDateItem={deleteCloseDateItem}
            />
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Monday}
                    onChange={handleInputChange}
                    name="Monday"
                    color="primary"
                  />
                }
                label="Monday"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Tuesday}
                    onChange={handleInputChange}
                    name="Tuesday"
                    color="primary"
                  />
                }
                label="Tuesday"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Wednesday}
                    onChange={handleInputChange}
                    name="Wednesday"
                    color="primary"
                  />
                }
                label="Wednesday"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Thursday}
                    onChange={handleInputChange}
                    name="Thursday"
                    color="primary"
                  />
                }
                label="Thursday"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Friday}
                    onChange={handleInputChange}
                    name="Friday"
                    color="primary"
                  />
                }
                label="Friday"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Saturday}
                    onChange={handleInputChange}
                    name="Saturday"
                    color="primary"
                  />
                }
                label="Saturday"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Sunday}
                    onChange={handleInputChange}
                    name="Sunday"
                    color="primary"
                  />
                }
                label="Sunday"
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
             
              <Box mb={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Box mr={1}>
                    <Typography>Open Time:</Typography>
                  </Box>
                  <TimePicker
                    value={startTime}
                    name="openStart"
                    onChange={handStartTimeChange}
                  />
                  <Box mr={1}>
                    <Typography>Close Time:</Typography>
                  </Box>

                  <TimePicker
                    value={endTime}
                    name="openEnd"
                    onChange={handEndTimeChange}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <Button
                variant="outlined"
                color="primary"
                disabled={isOpenHoursInValid()}
                onClick={addNewOpenHours}
              >
                Add New Open Hours
              </Button>
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup row>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  name="closeDate"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Choose Close Date "
                  value={values.closeDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "choose date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup row>
              <Button
                variant="outlined"
                color="primary"
                onClick={addNewCloseDate}
              >
                Add New Close Date
              </Button>
            </FormGroup>
          </Grid>
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={2000}
            onClose={handleErrorClose}
          >
            <Alert onClose={handleErrorClose} severity="error">
              Something went wrong! Please try again.
            </Alert>
          </Snackbar>
          <CardActions>
            <Button
              variant="contained"
              onClick={updateOpenHours}
              color="primary"
            >
              Save
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};

export default OpenHours;
