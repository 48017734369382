import moment from "moment";
import { isNumber } from "underscore";
import {
  isOrderInRestaurant,
  isFoodcourt,
} from "util/restaurantUtils";

export const loadTableId = (restaurantId) => {
  try {
    let serializedCart = localStorage.getItem(
      `http://orderbuddy.net.au:${restaurantId}:table`
    );

    if (serializedCart === null) {
      return null;
    }

    return serializedCart;
  } catch (err) {
    return null;
  }
};

export const loadRestaurant = (restaurantId) => {
  try {
    let serializedRestaurant = localStorage.getItem(
      `http://orderbuddy.net.au:${restaurantId}`
    );

    if (serializedRestaurant === null) {
      return null;
    }

    return serializedRestaurant;
  } catch (err) {
    return null;
  }
};

export const transformOrderId = (reference) => {
 return reference.substr(reference.length - 4).toUpperCase();
};

export const filterLocalOrders = (orders, now, limit) => {
  return orders.filter((item) => {
    const orderTime = moment(item.orderTime, "DD/MM/YYYY HH:mm:ss");
    const duration = moment.duration(now.diff(orderTime));

    const hours = duration.asHours();
    // console.log('orderTime', orderTime, hours);
    return hours <= limit;
  });
};

export const saveOrdersSummary = (order, restaurant) => {
  try {
    if (!order) {
      return;
    }
    let orders = loadOrdersSummary(restaurant);
    if (!orders) {
      orders = [];
    }
    // console.log("orders", orders, restaurant);
    const todayOrders = filterLocalOrders(orders, moment(), 4);

   // console.log("todayOrders", todayOrders);

    orders = [order, ...todayOrders.slice(0, 10)];
    let serializedOrder = JSON.stringify(orders);
    localStorage.setItem(
      `http://orderbuddy.net.au:${restaurant.restaurantId}:orders`,
      serializedOrder
    );
  } catch (err) {
    console.error(err);
  }
};

export const loadOrdersSummary = (restaurant) => {
  let restaurantId = restaurant;
  if (restaurant.restaurantId) {
    restaurantId = restaurant.restaurantId;
  }
  try {
    let serializedOrder = localStorage.getItem(
      `http://orderbuddy.net.au:${restaurantId}:orders`
    );

    if (serializedOrder === null) {
      return null;
    }
    // console.log("JSON.parse(serializedOrder)", JSON.parse(serializedOrder));
    return JSON.parse(serializedOrder);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const checkMobilePhone = (mobilePhone) => {
  const number = Number(mobilePhone);
  if (isNaN(number) || !isNumber(number)) {
    return false;
  }
  if (mobilePhone.length === 9 && mobilePhone.startsWith("4")) {
    return true;
  }
  if (mobilePhone.length === 10 && mobilePhone.startsWith("0")) {
    return true;
  }

  return false;
};

export const checkNumber = (inputString) => {
  const number = Number(inputString);
  if (isNaN(number) || !isNumber(number)) {
    return false;
  }
  return true;
};

export const checkAustraliaPostcode = (postcode) => {
  const number = Number(postcode);
  if (isNaN(number) || !isNumber(number)) {
    return false;
  }
  if (postcode.length === 4) {
    return true;
  }

  return false;
};

export const isTakeawayEnabled = (realTableId, restaurantData) => {
  if (isOrderInRestaurant(realTableId)) {
    if (
      restaurantData &&
      restaurantData.onlineSettings &&
      restaurantData.onlineSettings.dineinTakeawayEnabled === true
    ) {
      return true;
    }
  }

  if (isFoodcourt(realTableId)) {
    if (
      restaurantData &&
      restaurantData.onlineSettings &&
      restaurantData.onlineSettings.foodCourtTakeawayEnabled === true
    ) {
      return true;
    }
  }
  return false;
};


export const checkDishIsInActive  = (dish, filteredDishes, todayString) => {
  if (!filteredDishes || filteredDishes.length === 0) { 
    return true;
  }
  if(typeof filteredDishes[0] === 'object' && !Array.isArray(filteredDishes[0]) && filteredDishes[0] !== null) {
    const items = filteredDishes.filter((item) => item.menuItemId === dish.id);

    if (items.length > 0 && (items[0].effectiveDate === '' || items[0].effectiveDate === todayString)) {
      return false;
    }

  } else {
    if (filteredDishes.indexOf(dish.id) > -1) {
      return false;
    }
  }
  return true;
};


export const filterInactionDishes = (items, filteredDishes) => {
  if (!filteredDishes || filteredDishes.length === 0) {
    return items;
  }
  const todayString = moment().format('YYYY-MM-DD');

  const results = items.filter(dish => {
    return checkDishIsInActive(dish, filteredDishes, todayString);

  });
  
  return results;

};
