import React from "react";
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea,
  Button,
  CardActions,
  Grid,

} from "@material-ui/core";

 const useStyles = makeStyles((theme) => ({

  root: {
    minWidth: 275,
    maxWidth: 300, // Adjusting maximum width to control spacing
    margin: theme.spacing(2),
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 16px rgba(0,0,0,0.2)'
    }
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    fontFamily: 'Arial', // More professional looking font for titles
    color: '#3f51b5' // This is a Material-UI blue, adjust color as needed
  },
  detail: {
    fontSize: 16,
    fontFamily: 'Roboto', // Default Material-UI font for details
    margin: '5px 0', // Reduced margin for a compact look
  },
  button: {
    margin: '20px auto', // Adjusted to auto to center the button
    display: 'block' // Ensures button is treated as a block element for centering
  }
}));

const GrantCard = ({ grant, index, selectedGrantId, onSelect }) => {
  const classes = useStyles();
  const isSelected = grant.id === selectedGrantId;

  // const formatCurrency = (amount, currency) => `${currency} ${amount.toFixed(2)}`;
  const formatDate = date => new Date(date).toLocaleDateString();

  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency,
    }).format(amount);
  };
  if (grant === undefined) {
    return null;
  }

  return (
    <Grid container justify="center" spacing={2}> 
      <Grid item>
        <Card className={`${classes.root} ${isSelected ? 'selected' : ''}`}>
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              {`Offer ${index + 1}`}
            </Typography>
            <Typography className={classes.detail}>
              Contract Type: {grant.contractType === "loan" ? "Cash Advance" : "Standard Loan"}
            </Typography>
            <Typography className={classes.detail}>
              Principal Amount: {formatCurrency(grant.amount.value / 100, grant.amount.currency)}
            </Typography>
            <Typography className={classes.detail}>
              Fee: {formatCurrency(grant.fee.amount.value / 100, grant.fee.amount.currency)}
            </Typography>
            <Typography className={classes.detail}>
              Repayment Threshold: {formatCurrency(grant.repayment.threshold.amount.value / 100, grant.repayment.threshold.amount.currency)}
            </Typography>
            <Typography className={classes.detail}>
              Repayment Expected Term: {grant.repayment.term.estimatedDays} days
            </Typography>
            <Typography className={classes.detail}>
              Starts At: {formatDate(grant.startsAt)}
            </Typography>
            <Typography className={classes.detail}>
              Expires At: {formatDate(grant.expiresAt)}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={(e) => {
                e.stopPropagation();
                console.log('Button clicked without selecting the card');
                onSelect(grant.id)
              }}
            >
              Select Offer
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GrantCard;
