import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
// import { DataGrid } from '@mui/x-data-grid';
import SearchBar from "material-ui-search-bar";

import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Grid,
} from "@material-ui/core";

//import { getInitials } from 'helpers';
import { useHistory } from "react-router-dom";
import { setTransactionDetail, setTransactionPage, setTransactionIds } from "store/actions/transaction";
import { useDispatch, useSelector } from "react-redux";

import { setOrderDetail } from "store/actions/restaurant";
import { CSVLink } from "react-csv";


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
  totalRow: {
    backgroundColor: "#f5f5f5",
  },
}));

const TimesheetsTable = (props) => {
  const { className, timesheets, updateIds, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [keyword, setKeyword] = useState("");

  const updateTransactionDetail = (transaction) => dispatch(setTransactionDetail(transaction));
  const updateTransactionIds = (ids) => dispatch(setTransactionIds(ids));
  const showOrderDetail = (order) => dispatch(setOrderDetail(order));
  const setPage = (page) => dispatch(setTransactionPage(page));
  const page = useSelector((state) => state.transaction.page);

  const handleSelectAll = (event) => {
    let selectedRestaurants;

    if (event.target.checked) {
      selectedRestaurants = timesheets.map((order) => order.id);
    } else {
      selectedRestaurants = [];
    }

    setSelectedTransactions(selectedRestaurants);
  };

  // const handleSelectOneOld = (event, transactionId) => {
  //   const selectedIndex = selectedTransactions.indexOf(transactionId);
  //   let newSelectedTransactions = [];

  //   if (selectedIndex === -1) {
  //     newSelectedTransactions = newSelectedTransactions.concat(selectedTransactions, transactionId);
  //   } else if (selectedIndex === 0) {
  //     newSelectedTransactions = newSelectedTransactions.concat(selectedTransactions.slice(1));
  //   } else if (selectedIndex === selectedTransactions.length - 1) {
  //     newSelectedTransactions = newSelectedTransactions.concat(selectedTransactions.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelectedTransactions = newSelectedTransactions.concat(
  //       selectedTransactions.slice(0, selectedIndex),
  //       selectedTransactions.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelectedTransactions(newSelectedTransactions);
  //   updateIds(newSelectedTransactions);
  // };

  const handleSelectOne = (event, id) => {
    setSelectedTransactions([id]);
    updateIds([id]);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const getTimeString = (date) => {
    if (!date) {
      return "";
    }

    // check date is object

    if (typeof date === "object" && "_seconds" in date) {
      return moment(new Date(date._seconds * 1000)).format("DD/MM/YYYY HH:mm:ss");
    }
    return moment(Number(date) * 1000).format("DD/MM/YYYY HH:mm:ss");
  };

  const filtertimesheets = timesheets
    ? timesheets.filter((timesheet) => {
        if (keyword === "") {
          return true;
        }
        const lowcaseKey = keyword.toLocaleLowerCase();
        if (timesheet.id.toLowerCase().includes(lowcaseKey)) {
          return true;
        }
        if (timesheet.firstName.toLowerCase().includes(lowcaseKey)) {
          return true;
        }

        if (timesheet.lastName.toLowerCase().includes(lowcaseKey)) {
          return true;
        }

        return false;
      })
    : [];

  const csvData = () => {
    let data = [];
    data.push(["Name", "Checkin", "Checkout", "Hours"]);

    timesheets.forEach((item, index) => {
      const name = `${item.firstName} ${item.lastName}`;

      if (item.hours) {
        if (!item.timesheetVersion) {
          let checkoutTime = "";
          if (item && item.checkoutTime && typeof item.checkoutTime === "object" && "_seconds" in item.checkoutTime) {
            checkoutTime = moment(new Date(item.checkoutTime._seconds * 1000)).format("DD/MM/YYYY HH:mm:ss");
          } else if (item && item.checkoutTime && typeof item.checkoutTime === "number") {
            checkoutTime = moment(new Date(item.checkoutTime * 1000)).format("DD/MM/YYYY HH:mm:ss");
          }

          let checkinTime = "";
          if (item && item.checkinTime && typeof item.checkinTime === "object" && "_seconds" in item.checkinTime) {
            checkinTime = moment(new Date(item.checkinTime._seconds * 1000)).format("DD/MM/YYYY HH:mm:ss");
          } else if (item.checkinTime && typeof item.checkinTime === "number") {
            checkinTime = moment(new Date(item.checkinTime * 1000)).format("DD/MM/YYYY HH:mm:ss");
          }
         
          data.push([name, checkinTime, checkoutTime, Number(item.hours)]);
        } else  {
          const checkinTime = moment(new Date(item.checkinTime * 1000)).format("DD/MM/YYYY HH:mm:ss");

          const checkoutTime = moment(new Date(item.checkoutTime * 1000)).format("DD/MM/YYYY HH:mm:ss");

          data.push([name, checkinTime, checkoutTime, Number(item.hours)]);
        }
      }
    });
    //console.log('data', data);
    return data;
  };

  const itemRow = (timesheet) => {
    // console.log("timesheet", timesheet);
    return (
      <TableRow
        className={classes.tableRow}
        hover
        key={timesheet.id}
        selected={selectedTransactions.indexOf(timesheet.id) !== -1}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedTransactions.indexOf(timesheet.id) !== -1}
            color="primary"
            onChange={(event) => handleSelectOne(event, timesheet.id)}
            value="true"
          />
        </TableCell>

        <TableCell>{`${timesheet.firstName} ${timesheet.lastName}`}</TableCell>

        <TableCell>{timesheet.type}</TableCell>
        <TableCell>{getTimeString(timesheet.recordTime)}</TableCell>
        <TableCell>{timesheet.hours}</TableCell>
      </TableRow>
    );
  };

  const totalRow = (timesheet) => {
    return (
      <TableRow className={classes.totalRow} hover key={timesheet.id}>
        <TableCell colSpan="2">{`${timesheet.name}`}</TableCell>

        <TableCell colSpan="2">{timesheet.day}</TableCell>

        <TableCell>{timesheet.total}</TableCell>
      </TableRow>
    );
  };

  return (
    <Grid md={12} xl={12} xs={12}>
      {timesheets && timesheets.length > 0 ? (
        <CSVLink filename={"timesheets.csv"} data={csvData()}>
          <Button color="primary" variant="contained">
            Download CSV
          </Button>
        </CSVLink>
      ) : null}
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          {/* <PerfectScrollbar> */}
          <div className={classes.inner}>
            <SearchBar
              value={keyword}
              onChange={(newValue) => setKeyword(newValue)}
              onRequestSearch={() => console.log(keyword)}
            />

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      {/* <Checkbox
                      checked={selectedTransactions.length === transactions.length}
                      color="primary"
                      indeterminate={
                        selectedTransactions.length > 0 &&
                        selectedTransactions.length < transactions.length
                      }
                      onChange={handleSelectAll}
                    /> */}
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Clock Time</TableCell>
                    <TableCell>Hours</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timesheets &&
                    filtertimesheets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((timesheet) => {
                      if (timesheet.type === "checkout" || timesheet.type === "checkin") {
                        return itemRow(timesheet);
                      } else {
                        return totalRow(timesheet);
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filtertimesheets.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

TimesheetsTable.propTypes = {
  className: PropTypes.string,
  timesheets: PropTypes.array.isRequired,
};

export default TimesheetsTable;
