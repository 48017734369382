import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";

import { useSelector, useDispatch } from "react-redux";
import {
  saveMenuItem,
  clearAPIStatus,
} from "store/actions/restaurantMenu";
import NewSizes from "./NewSizes";
import { showErrorSnackbar } from "store/actions/snackbarMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const NewMenuItem = (props) => {
  const { menuId, category } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [cookingCategory, setCookingCategory] = useState(null);
  const [printCategoryList, setPrintCategoryList] = useState([]);
  const [printCategory, setPrintCategory] = useState(null);
  const [name, setName] = useState("");
  const [productId, setProductId] = useState("");
  const [altName, setAltName] = useState("");
  const [price, setPrice] = useState("");
  const [shortName, setShortName] = useState('');
  const [gstFree, setGstFree] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [displayNumber, setDisplayNumber] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [isOpenTableItem, setIsOpenTableItem] = useState(false);
  const [isOpenTableSharedItem, setIsOpenTableSharedItem] = useState(false);

  const saveTheMenuItem = (values) => dispatch(saveMenuItem(values));
  const apiSuccess = useSelector((state) => state.restaurantMenu.apiSuccess);

  const menu = useSelector((state) => state.restaurantMenu.menu);

  const clearApiStatus = () => dispatch(clearAPIStatus());

  const showError = (msg) => dispatch(showErrorSnackbar(msg));

  const save = () => {
    const updateSizes = sizes.map((size) => {
      return { ...size, price: Number(size.price) };
    });
    if(menu && menu.cookingCategories && menu.cookingCategories.length > 0) {
      if(printCategoryList.length === 0) {
        showError('Please select a print cooking category or print categories.');
        return;
      }
    }

    // sizes have two default === true
    const defaultSize = updateSizes.filter((size) => size.default === true);
    if (defaultSize.length > 1) {
      showError("Only one size can be default");
      return;
    }

    const newMenuItem = {
      name,
      description,
      altName,
      shortName,
      price: Number(price),
      sort: 999,
      menuId,
      categoryId: category.id,
      sizes: updateSizes,
      ingredients,
      gstFree,
      productId,
      displayNumber,
      active: true,
      active_begin: null,
      active_end: null,
      hidden_until: null,
      tags: [],
      groups: [],
      isOpenTableItem,
      isOpenTableSharedItem,
      cookingCategory,
      printCategory
    };
    saveTheMenuItem(newMenuItem);
  };

  if (apiSuccess) {
    clearApiStatus();
    props.close();
  }

  const close = () => {
    props.close();
  };



  const checkSizes = () => {
   // console.log('sizes', sizes);
    if (sizes.length === 0) {
      return false;
    }

    for (let i = 0; i < sizes.length; i++) {
      if (sizes[i].name === undefined || sizes[i].price === undefined) {
        return true;
      }
    }
    return false;
  };

  const handleGstChange = (event) => {
    setGstFree(event.target.checked);
  };

  const cookingCategories = () => {
    if (menu && menu.cookingCategories && menu.cookingCategories.length > 0) {
      return menu.cookingCategories.map((category) => {
        if (category.id) {
          return { value: category.name, label: category.name };
        } else {
          return { value: category, label: category };
        }
      });
    } else {
      return [];
    }
  };

  // const handleCookingCategoryChange = (options) => {
  //   // console.log("handleCookingCategoryChange", options);
 
  //    if (!options || options == null) {
  //      return;
  //    }
 
  //    //const allCookingCategories = options.map(option => option.value).join(';')
  //    setCookingCategory(options.value);
  //  };

   const handlePrintCategoryChange = (options) => {
    console.log("handlePrintCategoryChange", options);
    if (!options || options == null) {
      return;
    }

    const allPrintCategories = options.map((option) => option.value).join(";");
    setPrintCategoryList(options);
    setPrintCategory(allPrintCategories);
    
    
  };

  return (
    <Card
      className={classes.root}
      draggable
      onDragStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Name"
              margin="dense"
              name="name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Alt Name"
              margin="dense"
              name="altName"
              variant="outlined"
              value={altName}
              onChange={(e) => setAltName(e.target.value)}
              onDragStart={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Short Name"
              margin="dense"
              name="shortName"
              type="text"
              variant="outlined"
              value={shortName}
              onChange={e => setShortName(e.target.value)} 
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Description"
              margin="dense"
              name="description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onDragStart={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Price"
              margin="dense"
              name="price"
              variant="outlined"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Display Number"
              margin="dense"
              name="displayNumber"
              variant="outlined"
              value={displayNumber}
              onChange={(e) => setDisplayNumber(e.target.value)}
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>


          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Product Id"
              margin="dense"
              name="productId"
              variant="outlined"
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={gstFree}
                  onChange={handleGstChange}
                  name="gstFree"
                />
              }
              label="GST free"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Ingredients (For Kitchen Only)"
              margin="dense"
              name="ingredients"
              variant="outlined"
              value={ingredients}
              onChange={(e) => setIngredients(e.target.value)}
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isOpenTableItem}
                  onChange={e => setIsOpenTableItem(e.target.checked)}
                  name="openTableItem"
                  color="primary"
                />
              }
              label="Is Open table Item"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isOpenTableSharedItem}
                  onChange={e => setIsOpenTableSharedItem(e.target.checked)}
                  name="isOpenTableSharedItem"
                  color="primary"
                />
              }
              label="Is Open table Shared Item"
            />
          </Grid>

          {menu &&
                  menu.cookingCategories &&
                  menu.cookingCategories.length > 0 ? (
                    <>
                    {/* <Grid item>
                    <Typography gutterBottom variant="h5">
                      Print Cooking Category <br />
                      (for dish category print and each category has a printer)
                    </Typography>
                    {menu &&
                    menu.cookingCategories &&
                    menu.cookingCategories.length > 0 ? (
                      <Select
                        defaultValue={cookingCategory}
                        options={cookingCategories()}
                        className="basic-multi-select"
                        onChange={handleCookingCategoryChange}
                        classNamePrefix="select Cooking category"
                      />
                    ) : null}
                  </Grid>
  
                  <Divider /> */}
                  <Grid item>
                    <Typography gutterBottom variant="h5">
                      Multiple Categores Print (print dish on multiple printers)
                    </Typography>
                    {menu &&
                    menu.cookingCategories &&
                    menu.cookingCategories.length > 0 ? (
                      <Select
                        isMulti
                        defaultValue={printCategoryList}
                        options={cookingCategories()}
                        className="basic-multi-select"
                        onChange={handlePrintCategoryChange}
                        classNamePrefix="select Cooking category"
                      />
                    ) : null}
                  </Grid>
                  </>
                 ) : null}

          <Divider />
          <NewSizes updateSizes={setSizes} sizeList={sizes} />
        </Grid>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          disabled={name === '' || price === '' || checkSizes()}
          onClick={save}
        >
          Save
        </Button>
      </CardActions>
    </Card>
  );
};

export default NewMenuItem;
