import React from "react";
import { makeStyles } from "@material-ui/styles";

import { PaymentTypeListToolbar, PaymentTypeTable } from "./components";

import { Typography, Box } from '@material-ui/core';

import ListProvider from './ListProvider';

import { useQuery } from "react-query";
import { getAllPaymentTypes } from "apis/paymentAPIs";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import { useDispatch } from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const PaymentTypeList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));


  const { isLoading, isError, data, error } = useQuery(
    "paymentTypes",
    getAllPaymentTypes
  );

  


  if (isLoading) {
    return (
      <main className={classes.content}>

        <CircularProgress size={150} className={classes.uiProgess} />

      </main>
    );
  }

  if (isError) {
    showErrorMessage(error.message);
  }
  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" gutterBottom>
        POS Payment types Settings
      </Typography>
      <ListProvider>
        <PaymentTypeListToolbar paymentTypes={data} />
        <div className={classes.content}>
          <PaymentTypeTable paymentTypes={data} />
        </div>
      </ListProvider>
      <Box mt={5} className={classes.footer}>
        <Typography variant="h6" gutterBottom>
          Payment Types: A Quick Guide
        </Typography>
        <Typography variant="body1" paragraph>
          1. Payment Types are utilized for the Orderbuddy POS checkout process..
        </Typography>
        <Typography variant="body1" paragraph>
          2. The types are arranged according to the 'sort' field. The smaller the number, the higher its position in the list.
        </Typography>
        <Typography variant="body1" paragraph>
          3. If a particular type is disabled, it will not appear in the POS checkout.
        </Typography>
      </Box>
    </div>
  );
  // }
};

export default PaymentTypeList;
