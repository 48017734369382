import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import CartItem from "./components/CartItem";

import { useSelector, useDispatch } from "react-redux";
import { setRestaurantId } from "store/actions/order";
import MuiTableCell from "@material-ui/core/TableCell";
import Divider from "@material-ui/core/Divider";
import CallButton from "../Online/components/CallButton";
import { decodeRestaurantId } from "util/utils";

import { loadTableId, loadRestaurant } from "util/orderUtils";
import { isTakeawayEnabled } from "util/orderUtils";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbarButtons: {
    marginLeft: "auto",
    color: "white",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  tablePadding: {
    padding: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    size: 12,
  },
  item: {
    width: "100%",
    // maxWidth: 360,
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  subTotal: {
    fontSize: 20,
  },
  tableHeader: {
    borderBottom: "none",
    height: 54,
  },
  table: {
    borderBottom: "none",
  },
  grow: {
    flexGrow: 1,
    height: 54,
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const Cart = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkoutLink, setCheckoutLink] = useState("/checkout");
  const updateRestaurantId = (restaurantId) =>
    dispatch(setRestaurantId(restaurantId));
  const id = props.match.params.menuId;
  const tableId = props.match.params.tableId;
  const menuTableId = useSelector((state) => state.menu.tableId);
  const restaurantData = useSelector((state) => state.menu.restaurant);
  const [realTableId, setRealTableId] = useState(null);
  const [canDishesTakeaway, setCanDishesTakeaway] = useState(false);

  const goBack = (e) => {
    props.history.goBack();
  };
  const order = useSelector((state) => state.order);
  const cart = order.cart;

  const setupTableId = () => {
    if (!menuTableId) {
      setRealTableId(loadTableId(decodeRestaurantId(id)));
      // console.log('realTableId get', realTableId, id);
    } else {
      setRealTableId(menuTableId);
    }
  };

  const getCurrencySymbol = () => {
       if(restaurantData && restaurantData.currencySymbol) {
        return restaurantData.currencySymbol;
       } else {
        const restaurant = loadRestaurant(decodeRestaurantId(id));
        if(restaurant && restaurant.currencySymbol) {
          return restaurant.currencySymbol;
        } else {
          return '$';
        }
       }
  };

  useEffect(() => {
    if (!realTableId) {
      return;
    }

    setCanDishesTakeaway(isTakeawayEnabled(realTableId, restaurantData));
 
  }, [realTableId]);

  const cartItemTotal = order.cart.reduce((count, curItem) => {
    return count + curItem.quantity * curItem.subTotal;
  }, 0);

  const getSurcharges = () => {
    let surcharges = [];
    if (
      restaurantData &&
      restaurantData.holidaySurcharge &&
      restaurantData.holidaySurcharge.isEnabled === true
    ) {
      let holidaySurcharge = 0;
      if (restaurantData.holidaySurcharge.isPercentage === true) {
        const percents = Number(restaurantData.holidaySurcharge.amount);
        holidaySurcharge = (cartItemTotal * percents) / 100;
      } else {
        holidaySurcharge = Number(restaurantData.holidaySurcharge.amount);
      }

      surcharges.push({
        name: restaurantData.holidaySurcharge.name,
        tyep: "holiday",
        amount: holidaySurcharge,
      });
    }
    return surcharges;
  };

  useEffect(() => {
    let checkoutPath = 'checkout';
    if (restaurantData && restaurantData.onlineSettings && restaurantData.onlineSettings.isTillPaymentEnabled=== true) {
       checkoutPath = 'tillCheckout';
    }
    const checkoutLinkTarget = `/${checkoutPath}/${id}/${tableId}`;
    console.log("checkoutLinkTarget", checkoutLinkTarget);
    setCheckoutLink(checkoutLinkTarget);
    setupTableId();

    if (cart && cart.length === 0 && id && tableId) {
      updateRestaurantId({
        restaurantId: decodeRestaurantId(id),
        tableId: tableId,
      });
    }
  }, [props]);

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            onClick={goBack}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <ArrowBackIosIcon />
          </IconButton>

          <Grid alignItems="center" xs={12} container justify="center">
            <Grid item alignItems="center">
              <CallButton />
            </Grid>
          </Grid>

          <NavLink to={checkoutLink}>
            <WhiteTextTypography variant="h6">Continue</WhiteTextTypography>
          </NavLink>
        </Toolbar>
        <Paper className={classes.grow} square={true} style={{ padding: 4 }}>
          <Table
            className={classes.tableHeader}
            aria-label="a cart table"
            padding="none"
          >
            <TableRow>
              <TableCell align="left" sizeSmall>
                Qty
              </TableCell>
              <TableCell align="left">Item</TableCell>
              <TableCell alignLeft>Price</TableCell>
              <TableCell></TableCell>
              
            </TableRow>
          </Table>
        </Paper>
      </AppBar>

      <TableContainer className={classes.tablePadding}>
        <Table className={classes.table} aria-label="a cart table">
          <TableBody>
            {cart.map((item) => {
              return (
                  <>
                <CartItem
                  item={item}
                  key={item.id}
                  canDishesTakeaway={canDishesTakeaway}
                  currencySymbol={getCurrencySymbol()}
                />

                <TableRow>
                <TableCell colSpan="4">
                  {" "}
                  <Divider light />
                </TableCell>
                </TableRow>
                </>
              );
            })}

          

            <TableRow>
              <TableCell align="center" colSpan="2">
                <Typography color="textPrimary">Subtotal:</Typography>
              </TableCell>
              <TableCell align="left" className={classes.subTotal}>
                {getCurrencySymbol() + cartItemTotal.toFixed(2).toString()}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            {getSurcharges().map((surcharge) => {
              return (
                <TableRow>
                  <TableCell align="center" colSpan="2">
                    <Typography color="textPrimary">
                      {surcharge.name}:
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.subTotal}>
                    {getCurrencySymbol() + surcharge.amount.toFixed(2).toString()}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              );
            })}

            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left" colSpan="3">
                <Typography variant="caption" color="text.warning">
                  Additional charges may apply
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Cart;
