
const checkPermission = (user, permissionCode) => {
   if (user === null || user === undefined) {
      return false;
   }
   if (user.role !== null && (user.role === 'admin' || user.role === 'restaurantAdmin' || user.role === 'restaurantGroupAdmin')) {
       return true;
   }


   if (user.permissions === null || user.permissions === undefined || user.permissions.length === 0) {
      return false;
   }
    const permissionCodes = user.permissions.map(permission => permission.code);
    return permissionCodes.includes(permissionCode);
};

exports.checkPermission = checkPermission;

const PERMISSION_MYRESTAURANT = "myRestaurant";
exports.checkMyRestaurantPermission = (user) => {
   return checkPermission(user, PERMISSION_MYRESTAURANT);
};

const PERMISSION_MENU = "menuManagement";
exports.checkMenuPermission = (user) => {
   return checkPermission(user, PERMISSION_MENU);
};


const PERMISSION_ORDERS= "orders";
exports.checkOrdersPermission = (user) => {
   return checkPermission(user, PERMISSION_ORDERS);
};


const PERMISSION_TRANSACTIONS = "transactions";
exports.checkTransactionPermission = (user) => {
   return checkPermission(user, PERMISSION_TRANSACTIONS);
};

const PERMISSION_ORDER_REFUNDS = "orderRefunds";
exports.checkOrderRefundPermission = (user) => {
   return checkPermission(user, PERMISSION_ORDER_REFUNDS);
};

const PERMISSION_DEL_TRANSACTIONS = "delTransactions";
exports.checkDelTransactionsPermission = (user) => {
   return checkPermission(user, PERMISSION_DEL_TRANSACTIONS);
};

const PERMISSION_REPORTS = "reports";
exports.checkReportsPermission = (user) => {
   return checkPermission(user, PERMISSION_REPORTS);
};

const PERMISSION_BOOKING = "bookingManagement";
exports.checkBookingPermission = (user) => {
   return checkPermission(user, PERMISSION_BOOKING);
};

const PERMISSION_STAFF= "staffManagement";
exports.checkStaffPermission = (user) => {
   return checkPermission(user, PERMISSION_STAFF);
};

const PERMISSION_DISH_REPORT= "dishReportByOrders";
exports.checkDishOrderReportPermission = (user) => {
   return checkPermission(user, PERMISSION_DISH_REPORT);
};


