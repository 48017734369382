import React, { useState, useEffect} from "react";
import { makeStyles } from "@material-ui/styles";

import { SurchargeListToolbar, SurchargeTable } from "./components";


import { Alert } from "@material-ui/lab";

import { Typography, Box } from '@material-ui/core';

import ListProvider from './ListProvider';
import { getSurchargeSettingsList } from "./surchargeUtils";



import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const SurchargeList = (props) => {
  const classes = useStyles();
  

  const dispatch = useDispatch();
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );
  const [surcharges, setSurcharges] = useState([]);

  

  useEffect(() => {
    setSurcharges(getSurchargeSettingsList(restaurant));
  }, [restaurant]);





   if (!restaurant) {
      return (
        <main className={classes.content}>
          <Alert severity="error">Please select a restaurant</Alert>{" "}
        </main>
      );
    }
   

    return (
      <div className={classes.root}>
           <Typography variant="h4" component="h1" gutterBottom>
        Surcharge Settings
      </Typography>
         <ListProvider>
        <SurchargeListToolbar surcharges={surcharges}/>
        <div className={classes.content}>
         <SurchargeTable surcharges={surcharges} /> 
        </div>
        </ListProvider>
        <Box mt={5} className={classes.footer}>
        <Typography variant="h6" gutterBottom>
          Surcharges: A Quick Guide
        </Typography>
        <Typography variant="body1" paragraph>
          1. Surcharges apply to both online and local orders.
        </Typography>
        <Typography variant="body1" paragraph>
          2. If multiple surcharges are applicable on the same day, only the higher surcharge is applied.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Altering surcharge settings during operations is discouraged as it could impact payments.
        </Typography>
      </Box>
      </div>
    );
  // }
};

export default SurchargeList;
