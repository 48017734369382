import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";

import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { useHistory } from "react-router-dom";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useSelector, useDispatch } from "react-redux";
import { setOrderSelectedDate } from "store/actions/restaurant";
import { useConfirm } from "material-ui-confirm";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import TransactionDeleteDialog from "./TransactionDeleteDialog";

import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { StoreContext } from "../../context/store";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const VouchersToolbar = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");

  const { selectIds } = useContext(
    StoreContext
  );

  const setOrderDate = (date) => dispatch(setOrderSelectedDate(date));
  const selectedDate = useSelector(
    (state) => state.restaurant.orderSelectedDate
  );
  const selectedTransactionIds = useSelector((state) => state.transaction.ids);
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const handleDateChange = (date) => {
    console.log("date", date);
    setOrderDate(date);
    history.push("/transactions");
  };

  const deletePromotion = () => {
    //console.log("selectedTransactionIds", selectedTransactionIds);
    if (selectIds && selectIds[0] && selectIds[0].length !== 1) {
      showErrorMessage("Please select a prmotion.");
      return;
    }

    

    confirm({ description: "You are about to delete promotions!" })
      .then(() => {
        setButtonLoading(true);
        props.deleteProxy(selectIds[0], () => {
          setButtonLoading(false);
        })
      })
      .catch(() => {});
  };





  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />


        <NavLink to="/newVoucher" className={classes.textLink}>
          <Tooltip title="new voucher">
            <IconButton aria-label="new voucher">
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

 

  
      

  
      </div>

    </div>
  );
};

VouchersToolbar.propTypes = {
  className: PropTypes.string,
};

export default VouchersToolbar;
