import React, { useState, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useConfirm } from "material-ui-confirm";

import {
  Card,
  CardActions,
  CardContent,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TablePagination,
} from "@material-ui/core";

import { updateSelectedUserIds } from "store/actions/account";
import { useDispatch, useSelector } from "react-redux";
import SearchContext from "../../SearchContext";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
  terminalButton: {
    marginBottom: theme.spacing(1),

    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  returnButton: {
    // marginLeft: theme.spacing(1),
    backgroundColor: "#f44336",
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const TerminalTable = (props) => {
  const {
    className,
    stores,
    isStandaloneTerminal,
    assignTerminal,
    updateTerminalToIntegration,
    updateTerminalToStandalone,
    ...rest
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const updateUserIds = (ids) => dispatch(updateSelectedUserIds(ids));
  const { searchText } = useContext(SearchContext);
  const theme = useTheme();

  // const [openAssignDialog, setOpenAssignDialog] = useState(false);
  // const [selectedTerminal, setSelectedTerminal] = useState(null);
  // const [selectedStore, setSelectedStore] = useState(null);
  const user = useSelector((state) => state.account.user);

  const handleClickTerminal = (terminal) => {
    // setSelectedTerminal(terminal);
    // setOpenAssignDialog(true);
    //show confirm dialog
    //if yes, call assign API

    confirm({
      description: `You are about to assign this terminal (${terminal}) to the merchant (${user.restaurantId})`,
    })
      .then(() => {
        assignTerminal(terminal);
      })
      .catch(() => {
        /* ... */
      });
  };

  

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const filterStores = () => {
    if (!searchText || searchText === null || searchText.trim() === "") {
      return stores;
    }
    const searchTextLower = searchText.toLowerCase();

    return stores.filter(
      (item) =>
        (item.store && item.store.toLowerCase().includes(searchTextLower)) ||
        (item.inStoreTerminals &&
          item.inStoreTerminals
            .map((terminal) => terminal.toLowerCase()).join(" ")
            .includes(searchTextLower))
    );
  };


  function handleReturnToOrderBuddy(terminal) {
    confirm({
      description: `You are about to assign this terminal (${terminal}) back to the Orderbuddy inventory.`,
    })
      .then(() => {
        assignTerminal(terminal, true);
      })
      .catch(() => {
        /* ... */
      });
  }

  const handleStandardTerminal = (terminal) => {
    confirm({
      description: `You are about to update this terminal (${terminal}) to a standalone terminal.`,
    })
      .then(() => {
        updateTerminalToStandalone(terminal);
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleIntegrationTerminal = (terminal) => {
    confirm({
      description: `You are about to update this store's surcharge and split configuration.`,
    })
      .then(() => {
        updateTerminalToIntegration(terminal);
      })
      .catch(() => {
        /* ... */
      });
  };

  const getTerminalColor = (terminal) => {
    if (terminal.startsWith("S1F2")) {
      return "#4caf50";
    }
    if (terminal.startsWith("P400")) {
      // purple
      return "#9c27b0";
    }
    return "#2196f3";
  };



  return (
    <Grid lg={12} md={12} xl={12} xs={12}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          {/* <PerfectScrollbar> */}
          <div className={classes.inner}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.length === stores.length}
                        color="primary"
                        indeterminate={
                          selectedUsers.length > 0 &&
                          selectedUsers.length < stores.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell> */}
                    <TableCell>Store</TableCell>
                    <TableCell>Terminals</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterStores()
                    .slice(page * rowsPerPage, rowsPerPage * page + rowsPerPage)
                    .map((store) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={store.store}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedUsers.indexOf(store.store) !== -1
                            }
                            color="primary"
                            onChange={(event) =>
                              handleSelectOne(event, store.store)
                            }
                            value="true"
                          />
                        </TableCell> */}
                        <TableCell>{store.store}</TableCell>
                        <TableCell>
                          {store.inStoreTerminals
                            ? store.inStoreTerminals.map((terminal) => {
                                const color = getTerminalColor(terminal);

                                // const buttonClass = classes.terminalButton ? classes.terminalButton({ color }) : "";
                                return (
                                  <div
                                    key={terminal}
                                    className={classes.buttonContainer}
                                  >
                                    <Button
                                      variant="contained"
                                      className={classes.terminalButton}
                                      style={{ backgroundColor: color }}
                                      onClick={() =>
                                        handleClickTerminal(terminal)
                                      }
                                    >
                                      {terminal}
                                    </Button>
                                    {isStandaloneTerminal ? (
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.returnButton}
                                        onClick={() =>
                                          handleIntegrationTerminal(terminal)
                                        }
                                      >
                                        Update POS split config
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.returnButton}
                                        onClick={() =>
                                          handleStandardTerminal(terminal)
                                        }
                                      >
                                        Change to standalone
                                      </Button>
                                    )}

                                    {store.status !== "Inventory" ? (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.returnButton}
                                        onClick={() =>
                                          handleReturnToOrderBuddy(terminal)
                                        }
                                      >
                                        Return
                                      </Button>
                                    ) : null}
                                  </div>
                                );
                              })
                            : ""}
                        </TableCell>

                        <TableCell>{store.status}</TableCell>

                        <TableCell>{store.description}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                {/* <Dialog open={openAssignDialog} onClose={handleCloseAssignDialog}>
        <DialogTitle>Assign Store</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              value={selectedStore}
              onChange={(event) => setSelectedStore(event.target.value)}
            >
              {stores.map((store) => (
                <FormControlLabel
                  key={store.store}
                  value={store.store}
                  control={<Radio />}
                  label={store.store}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAssignDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAssign} color="primary">
            Assign
          </Button>
        </DialogActions>
      </Dialog> */}
              </Table>
            </TableContainer>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filterStores().length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[50, 100, 200]}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

TerminalTable.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.array.isRequired,
};

export default TerminalTable;
