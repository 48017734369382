import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { CookingCategoriesTable } from './components';





const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));


const CookingCategoryList = (props) => {
  const classes = useStyles();
  

  //const [uiLoading, setUiLoading] = useState(false);
  
  //const [menus, setMenus] = useState([]);
  // / authAdminMiddleWare(history, user);



  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <CookingCategoriesTable  />
      </div>
    </div>
  );
  
};

export default CookingCategoryList;
