// Import necessary modules
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useLocation, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    uiProgess: {
          position: 'fixed',
          zIndex: '1000',
          height: '31px',
          width: '31px',
          left: '50%',
          top: '35%'
      },
      progressContainer: {
        height: '100vh', // Take full height of the viewport
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
  }));

const H5WechatPay = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    // Function to get the query parameter
    const getQueryParam = (param) => {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get(param);
    };

    // Extract the 'url' query parameter
    const encodedUrl = getQueryParam('url');
    if (encodedUrl) {
      // Decode from Base64
      const decodedUrl = atob(encodedUrl);
    //   console.log('Decoded URL:', decodedUrl);
      
      // Redirect to the decoded URL
      window.location.href = decodedUrl;
    } else {
      // Handle the case where 'url' parameter is not present or is invalid
      console.error('URL parameter is missing or invalid.');
    }
  }, [location, history]);

  return (

    <div className={classes.progressContainer}>
      <CircularProgress size={150}/>
    </div>
    // <CircularProgress size={150} className={classes.progressContainer} />
  );
};

export default H5WechatPay;
