import API from 'util/API';


export const getTodayDashboard = async () => {
  const response = await API.get('/getTodaySalesReport');
  if (response && response.data && response.data) {
    return response.data;
  }
  return null;
};



export const getTodayDishReport = async () => {
  const response = await API.get('/getTodayDishReport');
  if (response && response.data && response.data.dishes) {
    return response.data.dishes;
  }
  return null;
};


