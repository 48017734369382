import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Paper
} from "@material-ui/core";

import { loadBookingSummary } from 'util/utils';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  instructions2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "10vw",
    marginRight: "7vw",
  },
  buttonLayout: {
    marginLeft: "7rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },
  topLayout: {
    margin: "4rem 0",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0",
    },
  },
  paperLayout: {
    padding: "1rem",

    marginTop: "5rem",
    marginBottom: "5rem",
    margin: "auto",
    border: "1px solid #ebedf0",
    borderRadius: "4px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "3rem",
    },
  },
}));



const BookingSuccess = (props) => {

  const classes = useStyles();
  const [booking, setBooking] = useState(null);

  const restaurantId = props.match.params.restaurantId;

  useEffect(() => {
    const bookingSummary = loadBookingSummary(restaurantId);
    setBooking(bookingSummary);

  }, [restaurantId])



  return (
    <>
      {booking ?
        (<Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.topLayout}
        >
          <Grid item display="flex" md={11} xs={11}>
            <Paper className={classes.paperLayout}>
              <Grid container>
                <div>

                  <div>
                    <Typography variant="h5" color="primary" className={classes.instructions2}>
                      Your reservation is confirmed and the number is{" "}
                      {booking.id.substr(booking.id.length - 6)}
                    </Typography>
                  </div>


                  <div align="inherit">
                    <Typography align="inherit" className={classes.instructions2}>

                      <ul>
                        <li class="list-group-item">
                          <b>Restaurant Name: </b>{booking.restaurant.name}
                        </li>

                        <li class="list-group-item">
                          <b>Address: </b> {booking.restaurant.address}
                        </li>


                        <li class="list-group-item">
                          <b>Phone: </b>{booking.restaurant.phone}
                        </li>
                        <li class="list-group-item">
                          <b>Date: </b> {booking.bookDateStr}
                        </li>

                        <li class="list-group-item">
                          <b>Time:</b>{" "}
                          {`${booking.startTime} - ${booking.endTime}`}
                        </li>

                        {booking.hideTable === true ? null :
                          <li class="list-group-item">
                            <b>Tables:</b>{" "}
                            {booking.tables
                              .map((table) => `#${table.tableNumber}`)
                              .join(", ")}
                          </li>
                        }

                        <li class="list-group-item">
                          <b>Party Size: </b> {booking.partySize}
                        </li>

                        <li class="list-group-item">
                          <b>Guest Name:</b> {booking.name}
                        </li>

                        <li class="list-group-item">
                          <b>Email:</b> {booking.email}
                        </li>

                        <li class="list-group-item">
                          <b>Mobile Phone:</b>
                          {booking.mobilePhone}
                        </li>
                        {booking.specialRequirement && booking.specialRequirement !== '' ?
                          <li class="list-group-item">
                            <b>Special Requirements:</b>
                            {booking.specialRequirement}
                          </li> : null
                        }
                      </ul>
                      <br />
                    </Typography>

                  </div>

                </div>
              </Grid>
            </Paper>
          </Grid>

          <Grid item>
            <Typography variant="caption" align="inherit" classes={classes.footer}>
              Copyright OrderBuddy© 2023 All Rights Reserved.
            </Typography>
          </Grid>

        </Grid>)
        : null}
    </>
  );
};

export default BookingSuccess;
