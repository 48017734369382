import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";


import { SearchInput } from "components";


import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";


import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import { updateUserkeyword } from "store/actions/account";
import { useConfirm } from "material-ui-confirm";
import SearchContext from '../../SearchContext';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const TerminalListToolbar = (props) => {
  const { className, ...rest } = props;
  const confirm = useConfirm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.account.user);
  const selectedUserIds = useSelector((state) => state.account.selectedUserIds);
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  const updateFilter = (key) => dispatch(updateUserkeyword(key));
  const { searchText, setSearchText } = useContext(SearchContext);

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const deleteOneUser = async () => {
  
    setButtonLoading(true);
  }

  const deleteUser = () => {
    console.log("selectedUserIds", selectedUserIds);
    if (selectedUserIds && selectedUserIds.length !== 1) {
      return;
    }

    confirm({ description: "You are about to delete this user!" })
      .then(() => {
        deleteOneUser();
      })
      .catch(() => {
        /* ... */
      });
  };

  const filterUsers = (e) => {
    updateFilter(e.target.value);
  }; 

  const addRestaurants = () => {
      if(selectedUserIds.length !== 1) {
        showErrorMessage("Please select only one restaurant group account.");
        return;
      }
      setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        {/* <IconButton
          aria-label="delete"
          onClick={deleteUser}
          disabled={buttonLoading}
        >
          <DeleteIcon />
        </IconButton>

     

        <NavLink to={"/staff"}>
          <Button color="primary" variant="contained">
            Add Staff
          </Button>
        </NavLink> */}
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search POS"
          value={searchText}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

TerminalListToolbar.propTypes = {
  className: PropTypes.string,
};

export default TerminalListToolbar;
