import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import {

  Button,

} from "@material-ui/core";


import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(4) },
  progess: {
    position: "absolute",
  },
  bottomButtons: {
    marginTop: theme.spacing(1),
  },
}));

const CheckMyRestaurant = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
 

  return (
    <div className={classes.root}>

      <NavLink to="/myrestaurant" className={classes.textLink}>
        <Button
          color="primary"
          variant="outlined"
        >
          Back

        </Button>
      </NavLink>
      <Typography variant="h3" color="error">Please go to MyRestaurant first.</Typography>
    </div>
  );
};

CheckMyRestaurant.propTypes = {
  className: PropTypes.string,
};

export default CheckMyRestaurant;
