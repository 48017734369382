import { useState } from "react";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showErrorSnackbar } from "store/actions/snackbarMessage";
import API from "util/API";
import { emptyCart } from "store/actions/order";
import { makeStyles } from "@material-ui/core/styles";
import { logError } from "apis/utilAPIs";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { v4 as uuid } from 'uuid';
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  pay: {
    margin: theme.spacing(1),
  },
}));

const AdyenPayment = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const emptyOrderCart = (msg) => dispatch(emptyCart(msg));
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);


  // console.log("order", order);

  const id = props.match.params.menuId;
  const tableId = props.match.params.tableId;

  //   function handleOnChange(state, component) {
  //     state.isValid // True or false. Specifies if all the information that the shopper provided is valid.
  //     state.data // Provides the data that you need to pass in the `/payments` call.
  //     component // Provides the active component instance that called this event.
  // }

  const handleSuccessResult = () => {
    // console.log("Payment completed");
    const orderNumber = order.adyenSession.orderNumber;
    emptyOrderCart({ ...order.adyenSession.order.order, id: orderNumber });

    history.push(`/orderSuccess/${id}/${tableId}/${orderNumber}`);

  };

  const handleOnAdditionalDetails = async (state, dropinComponent) => {
    // console.log('state', state);
    try {
      setIsSubmitting(true);
      const result = await API.post(`adyenPaymentDetails`, {
        payload: state.data,
      });
      if (result && result.data && result.data.action) {
        dropinComponent.handleAction(result.data.action);
      } else if (
        result &&
        result.data &&
        result.data.resultCode === "Authorised"
      ) {
        handleSuccessResult();
      } else {
        showError("The payment failed, please try again");
      }
    } catch (error) {
      logError(error);
      showError("Payment failed, please try again");
      history.goBack();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOnSubmit = async (state, dropinComponent) => {
    try {
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);

      if (!state.isValid) {
        setIsSubmitting(false);
        showError("Invalid payment details");
        return;
      }

      const restaurantId = id.includes("-") ? id.split("-")[0] : id;

       // Generate an idempotency key
       const idempotencyKey = uuid();
      // call API to make the payment
      const payload = {
        orderNumber: order.adyenSession.orderNumber,
        yearStr: order.adyenSession.yearStr,
        dateStr: order.adyenSession.dateStr,
        restaurantId,
        tableId: tableId,
        stateData: state.data,
        shopperEmail: order.user.email,
        idempotencyKey,
        browserInfo: {
          userAgent: navigator.userAgent,
          javaEnabled: navigator.javaEnabled(),
          acceptHeader: 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8',
          language: navigator.language, // Add the browser language here
          colorDepth: window.screen.colorDepth,
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
          timeZoneOffset: new Date().getTimezoneOffset(),
        },
      };

      const response = await API.post("/adyenPayments", payload);
      // console.log(response.data);

      if (response.data.action) {
        // Drop-in handles the action object from the /payments response
        dropinComponent.handleAction(response.data.action);
      } else {
        if (response.data.resultCode === "Authorised") {
          handleSuccessResult();
        } else {
          showError("Payment failed, please try again");
          try {
            const errorData = {
              title: `adyent online payment error code: `,
              message: `payload= ${JSON.stringify(payload)}, response=${JSON.stringify(response.data)}`,
            };
            logError(errorData);
          } catch (error) {
            console.error(error);
          }
        }
      }
    } catch (error) {
      console.error(error);
      try {
        const errorData = {
          title: `adyent online payment: `,
          message: `Error: ${JSON.stringify(error)}`,
        };
        logError(errorData);
      } catch (error) {
        console.error(error);
      }
      showError("Payment failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  const googlePayConfiguration = {
    amount: {
        value: (Number(order.adyenSession.orderTotal) * 100).toFixed(0),
        currency: order.adyenSession.currency
    },
    countryCode: order.adyenSession.countryCode || "AU",
    //Set this to PRODUCTION when you're ready to accept live payments
    environment: order.adyenSession.environment
};

  const configuration = {
    environment: order.adyenSession.environment,
    paymentMethodsResponse: order.adyenSession.paymentMethodResults,
    clientKey: order.adyenSession.clientKey,
    locale: order.adyenSession.locale || "en_AU",
    analytics: {
      enabled: false, // Set to false to not send analytics data to Adyen.
    },
    paymentMethodsConfiguration: {
      applepay: {
        amount: {
          value: (Number(order.adyenSession.orderTotal) * 100).toFixed(0),
          currency: order.adyenSession.currency,
        },
        countryCode: order.adyenSession.countryCode || "AU",
      },
      googlepay: googlePayConfiguration,
      card: {
        showPayButton: true,
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: false,
        amount: {
          value: (Number(order.adyenSession.orderTotal) * 100).toFixed(0),
          currency: order.adyenSession.currency
            ? order.adyenSession.currency
            : "AUD",
        },
      },
    },

    showPayButton: true,
    onSubmit: handleOnSubmit,
    // onChange: handleOnChange, // Your function for handling onChange event
    onAdditionalDetails: handleOnAdditionalDetails,
    // onPaymentCompleted: (result, component) => {
    //     console.log("onPaymentCompleted");
    //     if (result.resultCode === "Authorised") {
    //         console.log("Payment completed");
    //         const orderNumber = order.adyenSession.orderNumber;
    //         emptyOrderCart({...order.adyenSession.order.order, id: orderNumber});
    //         history.push(
    //             `/orderSuccess/${id}/${tableId}/${orderNumber}/local`
    //           );

    //     } else {
    //         console.log("Payment failed");
    //         showError("Payment failed, please try again");
    //         history.goBack();
    //     }
    //   console.info(result, component);
    // },
    onError: (error, component) => {
      console.error(error.name, error.message, error.stack, component);
      prepareForPayment();
    },
  };

  const prepareForPayment = async () => {
    // Create an instance of AdyenCheckout using the configuration object.
    const checkout = await AdyenCheckout(configuration);
    // console.log("configuration", configuration);
    if (!document.getElementById("dropin-container")) {
      return;
    }

    // Create an instance of Drop-in and mount it to the container you created.
    const dropinComponent = checkout
      .create("dropin")
      .mount("#dropin-container");

    // console.log("dropinComponent", dropinComponent);
  };

  useEffect(() => {
    // console.log(order);
    if (order && order.adyenSession) {
      const container = document.getElementById("dropin-container");
      if (container) {
        prepareForPayment();
      }
    }
  }, [order]);

  const goBack = (e) => {
    props.history.goBack();
  };

  // get order number last 4 characters with uppercase
  const orderNumber = () => {
    return order.adyenSession.orderNumber
      ? order.adyenSession.orderNumber.slice(-4).toUpperCase()
      : "";
  };

  return (

    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={goBack}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography>{`Order Number: ${orderNumber()}`}</Typography>
        </Toolbar>
      </AppBar>
      <Helmet>
        <script
          src="https://pay.google.com/gp/p/js/pay.js"
          type="text/javascript"
        />
      </Helmet>
      <div id="dropin-container" className={classes.pay}></div>
    </div>
  );
};

export default AdyenPayment;
