export const FETCH_ACCOUNTS_PENDING = 'FETCH_ACCOUNTS_PENDING';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_ERROR = 'FETCH_ACCOUNTS_ERROR';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const SET_SELECTED_USERIDS = 'SET_SELECTED_USERIDS';


export const fetchAccountsPending = () => ({
    type: FETCH_ACCOUNTS_PENDING
});

export const fetchAccountsSuccess = accounts => ({
    type: FETCH_ACCOUNTS_SUCCESS,
    payload: accounts
});

export const fetchUserSuccess = user => ({
    type: FETCH_USER_SUCCESS,
    payload: user
});

export const fetchAccountsError = error => ({
    type: FETCH_ACCOUNTS_ERROR,
    payload: error
});

export const updateSelectedUserIds = ids => ({
    type: SET_SELECTED_USERIDS,
    payload: ids
});

export const UPDATE_FILTER_KEYWORD = 'UPDATE_FILTER_KEYWORD';
export const updateUserkeyword = keyword => ({
    type: UPDATE_FILTER_KEYWORD,
    payload: keyword
});

