import React, { useEffect } from "react";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Switch,
} from "@material-ui/core";
import { getPrinters, getPrinterZones } from "apis/printAPIs";
import { useQuery } from "react-query";
import { makeStyles } from "@material-ui/core/styles";

import { PrintType, OBNameDisplayMode } from "./printTypes";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
}));

function POSPrintTypeSettings(props) {
  const { printTypeSettings, setPrintTypeSettings } = props;
  const classes = useStyles();

  const { data: printers, isLoading } = useQuery("printers", getPrinters);
  const { data: printerZones, isLoading: isZoneLoading } = useQuery(
    "printerZones",
    getPrinterZones
  );

  useEffect(() => {
    if (printers && printers.length > 0) {
      // update all printers when name is the same
      const updatedSettings = printTypeSettings.map((pt) => {
        let printer = null;
        if (pt.printer) {
          printer = printers.find(
            (p) => p.printerName === pt.printer.printerName
          );
        }

        const ptPrinterZones = pt.printerZones
          ? pt.printerZones.map((pz) => {
              const zonePrinter = printers.find(
                (p) => p.printerName === pz.printer.printerName
              );
              if (zonePrinter) {
                return { ...pz, printer: zonePrinter };
              }
              return pz;
            })
          : null;

        return {
          ...pt,
          orderTypes: pt.orderTypes.map((orderType) => {
            const orderTypePrinter = printers.find(
              (p) =>
                orderType.printer &&
                p.printerName === orderType.printer.printerName
            );
            // update printZones printers
            const printerZones = orderType.printerZones
              ? orderType.printerZones.map((pz) => {
                  const zonePrinter = printers.find(
                    (p) => p.printerName === pz.printer.printerName
                  );
                  if (zonePrinter) {
                    return { ...pz, printer: zonePrinter };
                  }
                  return pz;
                })
              : null;

            if (orderTypePrinter) {
              return {
                ...orderType,
                printer: orderTypePrinter,
                printerZones,
              };
            }
            return orderType;
          }),
          printer: printer,
          printerZones: ptPrinterZones,
        };
      });
      setPrintTypeSettings(updatedSettings);
    }
  }, [printers]);

  const handleCheckChange = (printType, orderTypeName) => (event) => {
    const updatedSettings = printTypeSettings.map((pt) => {
      if (pt.printType === printType) {
        return {
          ...pt,
          orderTypes: pt.orderTypes.map((orderType) => {
            if (orderType.name === orderTypeName) {
              return {
                ...orderType,
                enabled: event.target.checked,
              };
            }
            return orderType;
          }),
        };
      }
      return pt;
    });
    setPrintTypeSettings(updatedSettings);
  };

  const handlePrinterChange = (printType, orderTypeName) => (event) => {
    const selectedPrinter = printers.find(
      (p) => p.printerName === event.target.value
    );
    const updatedSettings = printTypeSettings.map((pt) => {
      if (pt.printType === printType) {
        return {
          ...pt,
          orderTypes: pt.orderTypes.map((orderType) => {
            if (orderType.name === orderTypeName) {
              return {
                ...orderType,
                printer: selectedPrinter,
              };
            }
            return orderType;
          }),
        };
      }
      return pt;
    });
    setPrintTypeSettings(updatedSettings);
  };

  const handlePrintCopyChange = (printType, orderTypeName) => (event) => {
    const updatedSettings = printTypeSettings.map((pt) => {
      if (pt.printType === printType) {
        return {
          ...pt,
          orderTypes: pt.orderTypes.map((orderType) => {
            if (orderType.name === orderTypeName) {
              return {
                ...orderType,
                printCopies: parseInt(event.target.value, 10) || 1, // Default to 1 if no valid number
              };
            }
            return orderType;
          }),
        };
      }
      return pt;
    });
    setPrintTypeSettings(updatedSettings);
  };

  const handleNameDisplayModeChange = (printType) => (event) => {
    const updatedSettings = printTypeSettings.map((pt) => {
      if (pt.printType === printType) {
        return {
          ...pt,
          nameDisplayMode: event.target.value,
        };
      }
      return pt;
    });

    setPrintTypeSettings(updatedSettings);
  };

  const handleBillPrinterChange = (printType) => (event) => {
    const selectedPrinter = printers.find(
      (p) => p.printerName === event.target.value
    );

    const updatedSettings = printTypeSettings.map((pt) => {
      if (pt.printType === printType) {
        return {
          ...pt,
          printer: selectedPrinter,
        };
      }
      return pt;
    });

    setPrintTypeSettings(updatedSettings);
  };

  const handleBillPrinterCopiesChange = (printType) => (event) => {
    const updatedSettings = printTypeSettings.map((pt) => {
      if (pt.printType === printType) {
        return {
          ...pt,
          printCopies: parseInt(event.target.value, 10) || 1,
        };
      }
      return pt;
    });

    setPrintTypeSettings(updatedSettings);
  };

  const handlePrinterZoneChange = (printType, orderTypeName) => (event) => {
    // console.log(event.target.value);
    const selectedPrinterZones = [];
    event.target.value.forEach((pz) => {
      const zone = printerZones.find((p) => p.id === pz);
      if (zone) {
        selectedPrinterZones.push(zone);
      }
    });

    console.log("selectedPrinterZones", selectedPrinterZones);
    const updatedSettings = printTypeSettings.map((pt) => {
      if (pt.printType === printType) {
        return {
          ...pt,
          orderTypes: pt.orderTypes.map((orderType) => {
            if (orderType.name === orderTypeName) {
              return {
                ...orderType,
                printerZones: selectedPrinterZones,
              };
            }
            return orderType;
          }),
        };
      }
      return pt;
    });

    setPrintTypeSettings(updatedSettings);
  };

  const handleBillPrinterZoneChange = (printType) => (event) => {
    // console.log(event.target.value);
    const selectedPrinterZones = [];
    event.target.value.forEach((pz) => {
      const zone = printerZones.find((p) => p.id === pz);
      if (zone) {
        selectedPrinterZones.push(zone);
      }
    });

    console.log("selectedPrinterZones", selectedPrinterZones);
    const updatedSettings = printTypeSettings.map((pt) => {
      if (pt.printType === printType) {
        return {
          ...pt,
          printerZones: selectedPrinterZones,
        };
      }
      return pt;
    });

    setPrintTypeSettings(updatedSettings);
  };

  const handleDishPrintTypePicturePrintChange = (event) => {
    const updatedSettings = printTypeSettings.map((pt) => {
      if (pt.printType === PrintType.DishCategory) {
        return {
          ...pt,
          disablePicturePrint: !event.target.checked,
        };
      }
      return pt;
    });

    setPrintTypeSettings(updatedSettings);
  };

  if (isLoading || isZoneLoading) return <LinearProgress />;

  return (
    <Grid container spacing={4}>
      {printTypeSettings.map((printTypeSetting, index) => (
        <Grid item xs={12} md={6} key={printTypeSetting.printType}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {printTypeSetting.printType} Print Settings
              </Typography>

              {printTypeSetting.orderTypes.map((orderTypeSetting) => (
                <Grid container spacing={2} key={orderTypeSetting.name}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={orderTypeSetting.enabled}
                          onChange={handleCheckChange(
                            printTypeSetting.printType,
                            orderTypeSetting.name
                          )}
                        />
                      }
                      label={orderTypeSetting.name}
                    />
                  </Grid>

                  {printTypeSetting.printType !== PrintType.DishCategory && (
                    <>
                      <Grid item xs={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel>Select printer</InputLabel>
                          <Select
                            value={
                              (orderTypeSetting.printer &&
                                orderTypeSetting.printer.printerName) ||
                              ""
                            }
                            onChange={handlePrinterChange(
                              printTypeSetting.printType,
                              orderTypeSetting.name
                            )}
                          >
                            {printers.map((printer) => (
                              <MenuItem
                                key={printer.printerName}
                                value={printer.printerName}
                              >
                                {printer.printerName}
                              </MenuItem>
                            ))}
                            <MenuItem value=""> None </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <InputLabel>Printer Zones</InputLabel>
                          <Select
                            multiple
                            value={
                              orderTypeSetting.printerZones
                                ? orderTypeSetting.printerZones.map(
                                    (zone) => zone.id
                                  )
                                : []
                            }
                            onChange={handlePrinterZoneChange(
                              printTypeSetting.printType,
                              orderTypeSetting.name
                            )}
                          >
                            {printerZones.map((zone) => (
                              <MenuItem key={zone} value={zone.id}>
                                {zone.zoneName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Print Copies"
                          type="number"
                          value={orderTypeSetting.printCopies}
                          onChange={handlePrintCopyChange(
                            printTypeSetting.printType,
                            orderTypeSetting.name
                          )}
                          inputProps={{ min: "1" }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}

              {printTypeSetting.printType === PrintType.DishCategory ? (
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            !(printTypeSetting.disablePicturePrint
                              ? true
                              : false)
                          }
                          onChange={handleDishPrintTypePicturePrintChange}
                          name="picturePrint"
                          color="primary"
                        />
                      }
                      label="Picture Print"
                    />
                  </FormControl>
                </Grid>
              ) : null}

              {printTypeSetting.printType === PrintType.Bill ||
              printTypeSetting.printType === PrintType.Invoice ? (
                <Grid item xs={12}>
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Select printer</InputLabel>
                        <Select
                          value={
                            (printTypeSetting.printer &&
                              printTypeSetting.printer.printerName) ||
                            ""
                          }
                          onChange={handleBillPrinterChange(
                            printTypeSetting.printType
                          )}
                        >
                          {printers.map((printer) => (
                            <MenuItem
                              key={printer.printerName}
                              value={printer.printerName}
                            >
                              {printer.printerName}
                            </MenuItem>
                          ))}
                          <MenuItem value=""> None </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Printer Zones</InputLabel>
                        <Select
                          multiple
                          value={
                            printTypeSetting.printerZones
                              ? printTypeSetting.printerZones.map(
                                  (zone) => zone.id
                                )
                              : []
                          }
                          onChange={handleBillPrinterZoneChange(
                            printTypeSetting.printType
                          )}
                        >
                          {printerZones.map((zone) => (
                            <MenuItem key={zone} value={zone.id}>
                              {zone.zoneName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Print Copies"
                        type="number"
                        value={printTypeSetting.printCopies}
                        onChange={handleBillPrinterCopiesChange(
                          printTypeSetting.printType
                        )}
                        inputProps={{ min: "1" }}
                      />
                    </Grid>
                  </>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Dish Name Print</InputLabel>
                  <Select
                    fullWidth
                    value={printTypeSetting.nameDisplayMode}
                    onChange={handleNameDisplayModeChange(
                      printTypeSetting.printType
                    )}
                  >
                    {Object.values(OBNameDisplayMode)
                      .filter((value) => typeof value !== "function")
                      .map((mode) => (
                        <MenuItem key={mode} value={mode}>
                          {OBNameDisplayMode.displayName(mode)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default POSPrintTypeSettings;
