import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { updateStoreSettings } from "apis/paymentAPIs";
import { useQueryClient } from "react-query";

const EditRatesDialog = ({ open, onClose, storeSettings, showSuccessMessage, showErrorMessage }) => {
  const [visaRate, setVisaRate] = useState('');
  const [amexRate, setAmexRate] = useState('');
  const [eftposRate, setEftposRate] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isEftposPercentage, setIsEftposPercentage] = useState(true);
  const [askConfirmation, setAskConfirmation] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (open && storeSettings) {
      setVisaRate(getBrandRate(storeSettings, 'visa'));
      setAmexRate(getBrandRate(storeSettings, 'amex'));
      const eftposConfig = getBrandConfig(storeSettings, 'eftpos_australia');
      setIsEftposPercentage(!!eftposConfig.percentage);
      setEftposRate(eftposConfig.percentage || eftposConfig.amount || '');
    }
  }, [open, storeSettings]);

  const getBrandRate = (storeSettings, brand) => {
    if (!storeSettings || !storeSettings.configurations) return '';
    const configurations = storeSettings.configurations.filter((config) => config.brand === brand);
    if (configurations.length === 0) return '';
    const config = configurations[0];
    if (config.currencies.length === 0) return '';
    return config.currencies[0].percentage ? config.currencies[0].percentage : config.currencies[0].amount;
  };

  const getBrandConfig = (storeSettings, brand) => {
    if (!storeSettings || !storeSettings.configurations) return {};
    const configurations = storeSettings.configurations.filter((config) => config.brand === brand);
    if (configurations.length === 0) return {};
    const config = configurations[0];
    if (config.currencies.length === 0) return {};
    return config.currencies[0];
  };

  const handleEditRatesConfirm = async () => {
    try {
      setButtonLoading(true);
      let storeSettings = {
        askConfirmation: askConfirmation,
        chargeRates: {
          visaRate: parseFloat(visaRate),
          amexRate: parseFloat(amexRate),
        }
      };
      if (isEftposPercentage) {
        storeSettings.chargeRates.eftposRate = parseFloat(eftposRate);
      } else {
        storeSettings.chargeRates.eftposAmount = parseFloat(eftposRate);
      }
      await updateStoreSettings(storeSettings);
      showSuccessMessage("Successfully updated rates");
      queryClient.invalidateQueries('storeSettings');
      onClose();
    } catch (error) {
      console.error(error);
      showErrorMessage("Failed to update rates");
    } finally {
      setButtonLoading(false);
    }
  };


  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="edit-rates-dialog-title">
      <DialogTitle id="edit-rates-dialog-title">Edit Surcharge Rates</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={askConfirmation}
              onChange={(e) => setAskConfirmation(e.target.checked)}
              color="primary"
            />
          }
          label="Ask Confirmation"
        />
        <TextField
          margin="dense"
          id="visaRate"
          label="Visa and Mastercard Rate (%)"
          type="number"
          value={visaRate}
          onChange={(e) => setVisaRate(e.target.value)}
          fullWidth
        />
        <TextField
          margin="dense"
          id="amexRate"
          label="Amex Rate (%)"
          type="number"
          value={amexRate}
          onChange={(e) => setAmexRate(e.target.value)}
          fullWidth
        />
        <FormControlLabel
          control={
            <Switch
              checked={isEftposPercentage}
              onChange={(e) => setIsEftposPercentage(e.target.checked)}
              color="primary"
            />
          }
          label={isEftposPercentage ? "EFTPOS Australia Rate (%)" : "EFTPOS Australia Amount ($)"}
        />
        <TextField
          margin="dense"
          id="eftposRate"
          label={isEftposPercentage ? "EFTPOS Australia Rate (%)" : "EFTPOS Australia Amount ($)"}
          type="number"
          value={eftposRate}
          onChange={(e) => setEftposRate(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleEditRatesConfirm} disabled={buttonLoading} color="primary">
          Update Rates
          {buttonLoading && <CircularProgress size={30} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRatesDialog;