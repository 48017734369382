import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Switch from "@material-ui/core/Switch";

import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { useSelector, useDispatch } from 'react-redux';
import { saveMenuGroup, toggleNewGroup, clearAPIStatus } from 'store/actions/restaurantMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        margin: theme.spacing(1)
    },
    content: {
        flexDirection: 'column'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    toggleContainer: {
        margin: theme.spacing(2, 0),
    },
}));

const NewGroup = (props) => {
    const { menuId } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [allow_quantity, setAllow_quantity] = useState(false);
    const [force_min, setForce_min] = useState(null);
    const [sort, setSort] = useState(99);
    const [force_max, setForce_max] = useState(null);
    const [required, setRequired] = useState('optional')
    const [hideForWeb, setHideForWeb] = useState(false);

    const [name, setName] = useState('');
    const apiSuccess = useSelector((state) => state.restaurantMenu.apiSuccess);


    const saveTheMenuGroup = (values) => dispatch(saveMenuGroup(values));
    const closeNewGroup = () => dispatch(toggleNewGroup());
    const clearApiStatus = () => dispatch(clearAPIStatus());


    if (apiSuccess) {
        clearApiStatus();
        closeNewGroup();
    }

    const isRequired = () => {
        return required === 'mandatory';
    }

    const save = () => {
        const group = {
            menuId,
            name,
            required: isRequired(),
            allow_quantity,
            force_min: Number(force_min),
            force_max: Number(force_max),
            sort: Number(sort),
            hideForWeb

        };
        saveTheMenuGroup(group);
        close();
    }

    const close = () => {
        closeNewGroup();
    };



    const isSaveButtonDisabled = () => {
        if (force_max < 0 || force_min < 0) {
            return true;
        }

        if (!name || name.trim() === '') {
            return true;
        }
        return false;
    };

    const updateAllowQuantity = (event) => {
        setAllow_quantity(!allow_quantity);
    };


    const handleRequiredChange = (event) => {

        setRequired(event.target.value);
    };


    const handleSwitchChange = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        if (name === 'hideForWeb') {
            setHideForWeb(value);
        }
    };


    return (
        <Card className={classes.root} draggable
            onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
            }}>
            <CardHeader

                title="new group"

            />

            <CardContent>
                <Grid container>
                    <TextField
                        fullWidth
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="outlined-basic" label="Group Name" variant="outlined" />

                    <FormControl component="fieldset">

                        <RadioGroup defaultValue="optional" aria-label="required" value={required} name="required" onChange={handleRequiredChange}>
                            <FormControlLabel value="optional" control={<Radio />} label="Optional" />
                            <FormControlLabel value="mandatory" control={<Radio />} label="Mandatory" />
                        </RadioGroup>
                    </FormControl>

                    <Grid container direction="row">

                        <Grid item xs={8}>
                            <Typography>
                                Allow adding same choice multiple times
                            </Typography>

                            <Tooltip title="If active, a client can select the same choice more than once." arrow>
                                <InfoIcon />
                            </Tooltip>

                        </Grid>
                        <Grid item>
                            <Checkbox
                                name="allow_quantity"
                                color="primary"
                                checked={allow_quantity}

                                onChange={e => updateAllowQuantity(e)}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>
                    </Grid>
                    <Box>

                        <TextField
                            margin="normal"
                            name="force_min"
                            size="small"
                            id="force_min"
                            label="Force minimum"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={force_min}
                            onChange={e => setForce_min(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            name="force_max"
                            size="small"
                            id="force_max"
                            label="Force maximum"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={force_max}
                            onChange={e => setForce_max(e.target.value)}
                        />


                        <TextField
                            margin="normal"
                            name="sort"
                            size="small"
                            id="sort"
                            label="Sort"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={sort}
                            onChange={e => setSort(e.target.value)}
                        />
                    </Box>
                </Grid>
                <Grid >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={hideForWeb}
                                onChange={handleSwitchChange}
                                name="hideForWeb"
                                color="primary"
                            />
                        }
                        label="Hide For Web"
                    />
                </Grid>
            </CardContent>
            <CardActions >
                <Button variant="outlined" onClick={close}>Cancel</Button>
                <Button variant="outlined"
                    disabled={isSaveButtonDisabled()}
                    color="primary" onClick={save}>
                    Save
                </Button>
            </CardActions>

        </Card>
    );
};

export default NewGroup;