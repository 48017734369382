import React, { useState, useEffect }  from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';


import { options } from './chart';


import moment from 'moment';
import palette from 'theme/palette';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const LatestSales = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [data, setData] = useState({});


const buildDataSet = (monthData) => {
  if(!monthData) {
    return;
  }
  console.log('monthData', monthData);
  const labelList = monthData.map(item => moment(item.date).format('D MMM'));
  setData( {
    labels: labelList,
    datasets: [
      {
        label: 'sales',
        backgroundColor: palette.primary.main,
        data: monthData.map(item => item.amount.toFixed(2))
      }, 
    ]
  });
};


useEffect(() => {
  buildDataSet(props.orderMonthDashboard);
  
}, [props]);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={
        //   <Button
        //     size="small"
        //     variant="text"
        //   >
        //     Last 7 days <ArrowDropDownIcon />
        //   </Button>
        // }
        title="Latest Sales"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      {/* <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Overview <ArrowRightIcon />
        </Button>
      </CardActions> */}
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
