import React, { useState, createContext } from "react";

export const StoreContext = createContext({});

export const StoreContextProvider = ({ children }) => {
  const [selectIds, setSelectIds] = useState([]);
  

  const store = {
    selectIds: [selectIds, setSelectIds],
  };
  console.log(store);
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
