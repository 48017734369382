import React  from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';


import { options } from './chart';
import moment from 'moment';



const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const newOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

const SaleHours = props => {
  const { className, weekReports, ...rest} = props;

  const classes = useStyles();
  // current date format is dd/mm/yyyy, need to convert to mm/dd/ with week day
   const labelList = weekReports.map(item => moment(item.date, 'DD/MM/YYYY').format('DD/MM ddd'));
  const data = {
    datasets: [
      {
        backgroundColor: '#8884d8',
        data: weekReports.map((item) => Number(item.total.toFixed(2))),
        label: 'Total',
        maxBarThickness: 20,
        minBarLength: 2,
      }
    ],
    labels: labelList,
  };
 
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={
        //   <Button
        //     size="small"
        //     variant="text"
        //   >
        //     Last 7 days <ArrowDropDownIcon />
        //   </Button>
        // }
        title={`Weekly Sales Overview`}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      {/* <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Overview <ArrowRightIcon />
        </Button>
      </CardActions> */}
    </Card>
  );
};

SaleHours.propTypes = {
  className: PropTypes.string
};

export default SaleHours;
