import React, { useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import GrantCard from "./GrantCard";
import GrantDetailsCard from "./GrantDetailsCard";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getGrantOffers, requestGrantOffer } from "apis/paymentAPIs";
import { Alert } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  nextButton: {
    margin: 20,
    padding: "10px 30px",
    fontSize: "1rem",
  },
  title: {
    margin: "20px 0",
    textAlign: "center",
  },
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const GrantOfferList = () => {
  const [selectedGrantId, setSelectedGrantId] = useState(null);
  const dispatch = useDispatch();
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  const queryClient = useQueryClient();
  queryClient.invalidateQueries("grantOffers");

  const handleSelectGrant = (id) => {
    setSelectedGrantId(id);
    requestGrantMutaion(id);
  };

  const { mutate: requestGrantMutaion, isLoading: isRequestingGrant } =
    useMutation(requestGrantOffer, {
      onSuccess: () => {
        dispatch(showSuccessSnackbar("Successfully requested a grant."));
        queryClient.invalidateQueries("grantOffers");
      },
      onError: (error) => {
        dispatch(showErrorSnackbar(error.message));
      },
    });

  const handleNext = () => {
    console.log("selectedGrantId", selectedGrantId);
    if (!selectedGrantId) return;

    requestGrantMutaion(selectedGrantId);
  };

  const classes = useStyles();
  const { isLoading, isError, data, error } = useQuery(
    "grantOffers",
    getGrantOffers
    // {
    //   staleTime: 0, // immediately mark data as stale
    //   cacheTime: 1, // almost instant cache garbage collection
    //   refetchOnWindowFocus: "always", // ensure data is refetched even if it was fetched recently
    // }
  );

  if (isError) {
    return <Alert severity="error">{`${error.message} `}</Alert>;
  }

  if (isLoading || isRequestingGrant) {
    return <CircularProgress size={150} className={classes.uiProgess} />;
  }

  if (data && !data.grants && data.stauts && data.stauts !== "") {
    dispatch(
      showSuccessSnackbar(
        "Capability to receive grants added, Please refresh the page to see the grant offers."
      )
    );
  }
  // console.log("data", data);

  return (
    <>
      {data.grantOffers.length > 0 ? (
        <>
          <Typography variant="h4" component="h2" className={classes.title}>
            Select an Offer
          </Typography>
          <Grid container spacing={3}>
            {data.grantOffers.map((grant, index) => (
              <>
            
                <Grid item xs={12} sm={6} md={4} key={grant.id}>
                  <GrantCard
                    grant={grant}
                    index={index}
                    selectedGrantId={selectedGrantId}
                    onSelect={handleSelectGrant}
                  />
                </Grid>
              </>
            ))}
          </Grid>
          <Grid container justify="center" style={{ marginTop: '20px' }}>
        <Button
          component={Link} 
          to="/capitalFaq" 
          variant="outlined"
          color="primary"
        >
          Visit FAQ Page
        </Button>
      </Grid>

        </>
      ) : (
        <Alert severity="info">No grant offers available at the moment.</Alert>
      )}
      {data && data.grants && data.grants.length > 0 && (
        <GrantDetailsCard grant={data.grants[0]} />
      )}
    </>
  );
};

export default GrantOfferList;
