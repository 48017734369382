import {
    API_SUCCESS, API_START, API_FAILURE
} from '../actions/restaurantMenu';

import { showSuccessSnackbar, showErrorSnackbar } from 'store/actions/snackbarMessage';
import API from 'util/API';



export const UPDATE_MENU_CATEGORIES_GROUP_SUCCESS = "UPDATE_MENU_CATEGORIES_GROUP_SUCCESS";
export const updateAllCategories = (categories) => async (dispatch, getState) => {
    if (!categories || categories.length === 0) {
        console.log('categories is empty');
        return;
    }
    try {
        dispatch({ type: API_START });
        const authToken = localStorage.getItem('AuthToken');
        API.defaults.headers.common['Authorization'] = authToken;
        const response = await API.put('/menuCategories', categories);
        const message = await response.json();
        dispatch(showSuccessSnackbar('Saved successfully!'))
        dispatch({ type: API_SUCCESS });
        dispatch({ type: UPDATE_MENU_CATEGORIES_GROUP_SUCCESS, payload: categories });
        // then(
        //     response => {
        //         dispatch(showSuccessSnackbar('Saved successfully!'))
        //         dispatch({ type: API_SUCCESS });
        //         dispatch({ type: UPDATE_MENU_CATEGORY_SUCCESS, payload: { ...values } });
        //     },
        //     err => {
        //         dispatch(showErrorSnackbar('Error in saving the data'));
        //         dispatch({ type: API_FAILURE, err })
        //     }
        // );

  
    } catch (err) {
        console.log('err', err);
        dispatch(showErrorSnackbar('Error in saving the data'));
        dispatch({ type: API_FAILURE, err })
    }
}