import React,  { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


 const TransactionAdjustments = (props) => {
  const { transaction } = props;
  const classes = useStyles();
  const history = useHistory();



  
  const paymentTime = (time) => {
    if ('_seconds' in time) {
      return  moment(new Date(time._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')
    }
    return moment(time).format('DD/MM/YYYY HH:mm:ss')
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Adjustment Type </TableCell>
            <TableCell align="right">Percentage</TableCell>
            <TableCell align="right">Adjustment amount</TableCell>
            <TableCell align="right">Time</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {transaction && transaction.adjustments && transaction.adjustments.map((row) => (
            <TableRow key={row.adjustmentType}>
              <TableCell component="th" scope="row">
                {row.adjustmentType}
              </TableCell>
              <TableCell align="right">{row.percentageValue}</TableCell>
              <TableCell align="right">${row.price.toFixed(2)}</TableCell>
              <TableCell align="right">{paymentTime(row.timeCreated)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionAdjustments;
