import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '90%',
        margin: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const faqs = [
    { question: "What is Adyen Capital / [Platform] Capital?", answer: "Adyen is the facilitating partner behind the Capital product. We advise platforms to explicitly make clear we are the facilitating bank behind the service." },
    { question: "Who is Adyen?", answer: "Adyen is the facilitating partner behind the Capital product. We advise platforms to explicitly make clear we are the facilitating bank behind the service." },
    { question: "Who is eligible for Capital?", answer: "To be eligible for Capital, you must have been actively trading with us for at least 3 months. The volume of trades must exceed £1000 per month for 3 consecutive months. You are a business or sole proprietorship." },
    { question: "Does the Capital offer expire?", answer: "Yes, the offers do expire. Please view the validity period listed with the offer to see how long you can think about the offer before it expires." },
    { question: "How long does it take to receive funds on my account?", answer: "Funds are made available to your account within minutes of agreeing to the terms and conditions. If you want the funds to be paid out to an external bank account, the funds will arrive within 1 business day of requesting it." },
    { question: "I haven’t been offered Capital, does that mean I’m not eligible?", answer: "Not necessarily. To be eligible for Capital, you must meet the following requirements: You must have been actively trading with us for at least 3 months. The volume of trades must exceed £1000 per month for 3 consecutive months. You are a business or sole proprietorship." },
    { question: "Will applying for Capital affect my credit rating?", answer: "The Capital product does not require you to provide your personal credit rating and does not affect your credit score." },
    { question: "How do you determine a capital size for my business?", answer: "Adyen provides proactive risk assessments based on your payments processing data. The outcome of the assessment results in the offers displayed to you." },
    { question: "What can I use the funds for?", answer: "The business financing provided through Capital can be used for business purposes. This can range from buying stock, buying new machines, investing in advertisement to covering for temporary operational disruptions." },
    { question: "What are the costs of getting business financing?", answer: "The amount you remit is the business financing including a flat transparent fee. This means the costs of the service are communicated in advance of the service to you." },
    { question: "Where will the financing be paid?", answer: "This is a platform specific question." },
    { question: "How does repayment work?", answer: "The repayment amount is a fixed percentage deducted from your sales before daily payout. The repayments are deducted daily from net captured volume — captures minus refunds and chargebacks. The percentage we deduct is specific to the received capital and is communicated to you in the capital offer." },
    { question: "When do I need to repay the amount I owe?", answer: "You repay a repayment percentage over your daily sales. This means you repay more on busy days and repay less when business is slow." },
    { question: "Can I pay my Capital early?", answer: "Currently this functionality is not available yet. The amounts due for repayment will be deducted from the payouts due to you on a daily basis." },
    { question: "Can I change the payment terms?", answer: "We understand that sometimes unexpected events occur and we are here to assist. Please direct your request to the following email address: creditriskops@adyen.com. Adyen, as the ultimate capital provider, will assist in addressing your query." },
    { question: "What does the Threshold amount entail?", answer: "The threshold repayment amount is the amount against which we monitor the repayment. If the total 30-day repayment drops below this threshold, we may take additional action to make sure that the user remains on track." },
    { question: "What do the following terms mean?", answer: "We recommend you to review the terms from the Capital Terms and Conditions and list the terms that may be new to your user for further explanation." },
];



const CapitalFaqs = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h2" component="h2" gutterBottom>
                Captial FAQs
            </Typography>
            {faqs.map((faq, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {faq.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}

export default CapitalFaqs;
