import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar, Footer } from './components';


import { useDispatch } from 'react-redux';
import { fetchUserSuccess } from 'store/actions/account';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';


import API from 'util/API';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));



const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  const [uiLoading, setUiLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();
  const updateUser = (user) => dispatch(fetchUserSuccess(user));
  const history = useHistory();

  useEffect(() => {
		//authMiddleWare(history);
		// const authToken = localStorage.getItem('AuthToken');
	
		API
			.get('/user')
			.then((response) => {
			//	console.log(response.data);
			updateUser(response.data.userCredentials);

		//	setLoginName(`${response.data.userCredentials.firstName} ${response.data.userCredentials.lastName}`);
			
				setUiLoading(false)
				// profilePicture: response.data.userCredentials.imageUrl

			})
			.catch((error) => {
				if (error.response && error.response.status === 403) {
					history.push('/sign-in')
				}
				console.log(error);
				setErrorMsg('Error in retrieving the data');
			});
  }, [props]);
  


	if (uiLoading === true) {
		return (
			<div className={classes.root}>
				{uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
			</div>
		);
	} else {

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
    }
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
