import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { TableRow, TableSortLabel } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { CSVLink } from "react-csv";
// import {
//     PieChart,
//     Pie,
//     Cell,
//     Tooltip,
//     Legend,
//     ResponsiveContainer,
//   } from "recharts";


const useStyles = makeStyles((theme) => ({
  root: {},
  table: {},
  top: {
    marginTop: theme.spacing(1),
  },
  categoryRow: {
    backgroundColor: "#f5f5f5", // Change the background color as desired
  },
}));

const PaymentTypesByDate  = (props) => {
  const { reportData } = props;
  const classes = useStyles();


  //   const colors = ['#8884d8', '#82ca9d', '#ffc658', '#FF8042', '#FFBB28', '#0088FE', '#FFD700', '#FF4500', '#4B0082', '#2E8B57'];


  // function calculatStatistics(groupedDict) {
  //   const totalSales = groupedDict.reduce(
  //     (sum, category) => {
  //       if (category !== 'Adjustments Before Payments') {
  //         return sum + category.total;
  //       } else {
  //         return sum;
  //       }
  //     },
  //     0
  //   );


  //   const result = groupedDict.filter(item => item.total).map(({ category, items, total }) => {

  //     const updatedItems = items.map((item) => ({
  //       ...item,
  //       percentage: (item.amount / totalSales * 100).toFixed(2),
  //     }));

  //     // sort updatedItems by percentage
  //     updatedItems.sort((a, b) => {
  //       return Number(b.percentage) - Number(a.percentage);
  //     });

  //     return {
  //       category,
  //       categoryTotal: total.toFixed(2),
  //       items: updatedItems,
  //     };
  //   });



  //   return result;
  // }



  // const groupedData = calculatStatistics(reportData);


  // function renderBarChart(data) {


  //   const itemPercentageData = data.flatMap((category) => {

  //     return category.items.map((item) => {

  //       return {
  //         name: item.type,
  //         value: Number(Math.abs(item.amount)).toFixed(2),
  //         percentage: Math.abs(Number(item.percentage)),
  //       };
  //     });


  //   });

    // sort by value
  //   itemPercentageData.sort((a, b) => {
  //     return Number(b.value) - Number(a.value);
  //   });

  //   return (




  //     <ResponsiveContainer width="100%" height={400}>
  //       <BarChart layout="vertical" data={itemPercentageData}>
  //         <CartesianGrid strokeDasharray="3 3" />
  //         <XAxis type="number" />
  //         <YAxis dataKey="name" type="category" width={150} />
  //         <Tooltip />
  //         <Legend />
  //         <Bar dataKey="value" label={{ position: "right" }}>
  //           {itemPercentageData.map((entry, index) => (
  //             <Cell
  //               key={`cell-${index}`}
  //               fill={COLORS[index % COLORS.length]}
  //             />
  //           ))}
  //         </Bar>
  //       </BarChart>
  //     </ResponsiveContainer>
  //   );
  // }
 const sortedData = React.useMemo(() => {
    const sortableItems = reportData.sort((a, b) => {
     // compare date string (DD/MM/YYYY) in descending order
      return new Date(b.date.split("/").reverse().join("-")) - new Date(a.date.split("/").reverse().join("-"));
    });
    return sortableItems;
  }, [reportData]);



  const csvData = () => {
    let data = [];
    data.push(["Type", "Total", "Date"]);

    reportData.forEach((item) => {

        if (item && item.amount !== null && item.amount !== undefined) {
          data.push([
            item.type,
            item.amount.toFixed(2),
            item.date,
          ]);
        }
    });
    //console.log('data', data);
    return data;
  };


  return (
    <div className={classes.root}>
  
   
      <h3
        style={{
          fontSize: "1.5rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        Payment Types Data
      </h3>

      <div style={{ paddingBottom: "1rem" }}>
        {reportData && reportData.length > 0 ? (
          <CSVLink filename={"payment-types.csv"} data={csvData()}>
            <Button variant="outlined" color="primary">
              Download CSV
            </Button>
          </CSVLink>
        ) : null}
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Payment Type</TableCell>
              <TableCell>
                <TableSortLabel>
                  Total
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel>
                 Date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body1">{item.type}</Typography>

                    </TableCell>
                    <TableCell>${item.amount.toFixed(2)}</TableCell>

                    {/* <TableCell>{item.productId || '-'}</TableCell> */}
                    <TableCell>{item.date}</TableCell>
                  </TableRow>

            ))}
          </TableBody>
        </Table>
      </TableContainer>


    </div>
  );
};

export default PaymentTypesByDate;
