export const minutesToString = (value) => {
    let hours = (value / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    if (rhours > 24) {
      rhours = rhours - 24;
    }

    return `${rhours}: ${rminutes.toString().length === 1 ? '0' + rminutes.toString() : rminutes}`;
  };

  export const loadMenu = (restaurantId, tableId) => {
    try {
      let serializedItem = localStorage.getItem(
        `http://orderbuddy.net.au:${restaurantId}-${tableId}:menu`
      );
  
      if (serializedItem === null) {
        return null;
      }
  
      return JSON.parse(serializedItem);
    } catch (err) {
      console.error(err);
      return null;
    }
  };


  export const saveMenu = (tableId, restaurantId, menu) => {
    try {
      if (!tableId || !restaurantId || !menu) {
        console.error("invalid params");
        return;
      }
      console.log('saving menu',tableId,restaurantId,menu);
     
      let serializedMenu = JSON.stringify(menu);
      localStorage.setItem(
        `http://orderbuddy.net.au:${restaurantId}-${tableId}:menu`,
        serializedMenu
      );
    } catch (err) {
      console.error(err);
    }
  };