import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {  useDispatch } from 'react-redux';
import { uploadMenuPhoto } from 'store/actions/restaurantMenu';


const MenuItemImage = (props) => {
    const { open, menuId, categoryId, menuItemId } = props
    const dispatch = useDispatch();
    const uploadPhoto = (values) => dispatch(uploadMenuPhoto(values));

    const [crop, setCrop] = useState({  aspect: 1 / 1 });
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [imageRef, setImageRef] = useState(null);
  
    const [image, setImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [content, setContent] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);



    const handleClose = () => {
        props.handImageClose();
    };

    const handleImageChange = (event) => {
		setImage(event.target.files[0])
	};

    const uploadThePhoto = () => {
        if (!image || !croppedImage) {
            console.log('No images');
            return;
        }

        let form_data = new FormData();
        form_data.append('image', croppedImage);
        form_data.append('content', content);
	
        const data = {
            menuId, categoryId, menuItemId, form_data
        };
        uploadPhoto(data);
    }

    const onImageLoaded = image => {
        setImageRef(image);
    }


    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
                
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImg= new File([u8arr], filename, {type:mime});
        setCroppedImage(croppedImg); 
    }


    const getCroppedImg = (image, crop)  => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
         )
    
        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })
    };

    const onCropComplete = crop => {
       
        if (image && crop.width && crop.height) {
            const croppedImageUrl = getCroppedImg(imageRef, crop)
            setCroppedImageUrl(croppedImageUrl);
        }
        setCompletedCrop(crop);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Upload dish image</DialogTitle>
                <DialogContent>
                    <form>
             
                    <input type="file" onChange={handleImageChange}/>
       
                        
                        </form>
                        {/* <img src={image && URL.createObjectURL(image)}/> */}

                        <ReactCrop src={image && URL.createObjectURL(image)} crop={crop} 
                        onImageLoaded={onImageLoaded}
                        onChange={newCrop => setCrop(newCrop)} 
                        onComplete={onCropComplete}/>
                    </DialogContent>
               
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
          </Button>
                    <Button onClick={uploadThePhoto} 
                      disabled={!completedCrop?.width || !completedCrop?.height}
                    color="primary">
                        Upload
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MenuItemImage;