import React, { useState, useEffect } from "react";

import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Button,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Disclaimer = (props) => {
  const classes = useStyles();
  const CHARACTER_LIMIT = 100;
  const { settings, updateSettings, isLoading } = props;

  const [disclaimer, setDisclaimer] = useState("");
  const [declineTodayBooking, setDeclineTodayBooking] = useState(false);

  useEffect(() => {
    if (settings && settings.disclaimer && settings.disclaimer !== undefined) {
      setDisclaimer(settings.disclaimer);
    }

    if (settings && settings.declineTodayBooking !== null && settings.declineTodayBooking !== undefined) {
      setDeclineTodayBooking(settings.declineTodayBooking);
    }
  }, [settings]);

  const updateChanges = () => {
    updateSettings({ disclaimer, declineTodayBooking });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "declineTodayBooking") {
      setDeclineTodayBooking(value);
      return;
    }
  };

  return (
    <div>
      <Card className={clsx(classes.root, classes)}>
        <CardHeader title="Booking Disclaimer" subheader="" />
        <CardContent>
          <Grid item md={3} xs={6}>
            <TextField
              multiline
              rows={4}
              label="Disclaimer"
              fullWidth
              name="disclaimer"
              variant="outlined"
              value={disclaimer}
              inputProps={{
                maxlength: CHARACTER_LIMIT,
              }}
              onChange={(e) => setDisclaimer(e.target.value)}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={declineTodayBooking}
                  onChange={handleSwitchChange}
                  name="declineTodayBooking"
                  color="primary"
                />
              }
              label="Decline today's bookings"
            />
          </Grid>

          <CardActions>
            <Button
              variant="contained"
              onClick={updateChanges}
              color="primary"
              disabled={isLoading}
            >
              Save
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};

export default Disclaimer;
