import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

//import { getInitials } from 'helpers';
import { NavLink } from 'react-router-dom';
import { updateSelectedRestaurantIds } from 'store/actions/restaurant'
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
   // minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textLink: {
		textDecoration: 'underline',
	},
}));

const RestaurantsTable = props => {
  const { className, restaurants, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const updateRestaurantIds = (ids) => dispatch(updateSelectedRestaurantIds(ids));
  const restaurantFilter = useSelector((state) => state.restaurant.filter);

  const handleSelectAll = event => {
 
    let selectedRestaurants;

    if (event.target.checked) {
      selectedRestaurants = restaurants.map(restaurant => restaurant.urlIdentifier);
    } else {
      selectedRestaurants = [];
    }

    setSelectedRestaurants(selectedRestaurants);
  };

  const handleSelectOne = (event, urlIdentifier) => {
    const selectedIndex = selectedRestaurants.indexOf(urlIdentifier);
    let newSelectedRestaurants = [];

    if (selectedIndex === -1) {
      newSelectedRestaurants = newSelectedRestaurants.concat(selectedRestaurants, urlIdentifier);
    } else if (selectedIndex === 0) {
      newSelectedRestaurants = newSelectedRestaurants.concat(selectedRestaurants.slice(1));
    } else if (selectedIndex === selectedRestaurants.length - 1) {
      newSelectedRestaurants = newSelectedRestaurants.concat(selectedRestaurants.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRestaurants = newSelectedRestaurants.concat(
        selectedRestaurants.slice(0, selectedIndex),
        selectedRestaurants.slice(selectedIndex + 1)
      );
    }

    setSelectedRestaurants(newSelectedRestaurants);
    updateRestaurantIds(newSelectedRestaurants);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const filterRestaurants = () => {
      if (!restaurantFilter || restaurantFilter === null || restaurantFilter.trim() === '' ) {
        return restaurants;
      }

      return restaurants.filter(restaurant => 
        (restaurant.name && restaurant.name.toLowerCase().includes(restaurantFilter)) 
        || (restaurant.altName && restaurant.altName.includes(restaurantFilter))
        || (restaurant.restaurantAdmin && restaurant.restaurantAdmin.includes(restaurantFilter))
        || (restaurant.description && restaurant.description.toLowerCase().includes(restaurantFilter))
        || (restaurant.urlIdentifier && restaurant.urlIdentifier.toLowerCase().includes(restaurantFilter))
      ); 
           
  };
  // console.log(restaurants);

  const paymentString = (restaurant) => {
    if (restaurant.stripeAccountId) {
      return `Stripe-${restaurant.stripeAccountId}`;
    }
    if(restaurant.onlineSettings && restaurant.onlineSettings.isTillPaymentEnabled) {
      return 'Till';
    }
    return 'None';
    
  }




  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    {/* <Checkbox
                      checked={selectedRestaurants.length === restaurants.length}
                      color="primary"
                      indeterminate={
                        selectedRestaurants.length > 0 &&
                        selectedRestaurants.length < restaurants.length
                      }
                      onChange={handleSelectAll}
                    /> */}
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Alt Name</TableCell>
                  <TableCell>Restaurant Id</TableCell>
                  <TableCell>Restaurant Admin</TableCell>
                  <TableCell>Online Payment</TableCell>
                  <TableCell>Address</TableCell>
                 
                  <TableCell>Create date</TableCell>
                  <TableCell>Updated Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterRestaurants().slice(page * rowsPerPage, rowsPerPage * page + rowsPerPage).map(restaurant => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={restaurant.urlIdentifier}
                    selected={selectedRestaurants.indexOf(restaurant.urlIdentifier) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRestaurants.indexOf(restaurant.urlIdentifier) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, restaurant.urlIdentifier)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell >
                      {restaurant.type === "restaurantGroup" ? (
                      <NavLink to={`/restaurantGroup/${restaurant.urlIdentifier}`}>
                        <div className={classes.textLink}>
                          <Typography variant="body1">{restaurant.name}</Typography>
                        </div>
                      </NavLink>
                      ) : (
                        <NavLink to={`/restaurant/${restaurant.urlIdentifier}`}>
                        <div className={classes.textLink}>
                          <Typography variant="body1">{restaurant.name}</Typography>
                        </div>
                      </NavLink>
                      )
                      }
                    </TableCell>
                    <TableCell>{restaurant.altName ? restaurant.altName : ''}</TableCell>
                    <TableCell>{restaurant.urlIdentifier}</TableCell>
                    <TableCell>{restaurant.restaurantAdmin}</TableCell>
                    <TableCell>{paymentString(restaurant)}</TableCell>
                    <TableCell>
                      {restaurant.address}
                    </TableCell>
                  
                    <TableCell>
                      {moment(new Date(restaurant.createdAt._seconds * 1000)).format('DD/MM/YYYY')}
                    </TableCell>

                    <TableCell>                      
                      {restaurant.updateAt  && restaurant.updateAt._seconds && moment(new Date(restaurant.updateAt._seconds * 1000)).format('DD/MM HH:mm')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={filterRestaurants().length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

RestaurantsTable.propTypes = {
  className: PropTypes.string,
  restaurants: PropTypes.array.isRequired
};

export default RestaurantsTable;
