import React from 'react'
import { useDrag } from 'react-dnd'
//import { ItemTypes } from './ItemTypes'
const ItemTypes = {
    BOX: 'group',
  }
const style = {
  display: 'inline-block',
  border: '0px solid #f7f7f7',
  padding: '0.5rem 0.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}
 const SourceBox = (props) => {
  const [, drag] = useDrag({ item: { type: ItemTypes.BOX, item: props.item } })
  return (
    <div ref={drag} style={style}>
     {props.children}
    </div>
  )
};

export default SourceBox;
