// utils/API.js
import constants from 'util/constants';

import axios from "axios";

 const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: constants.TIMEOUT
  });

  instance.defaults.headers.common["clientId"] = constants.CLIENT_ID;
  instance.defaults.headers.common["websiteVersion"] = constants.WEBSITE_VERSION;

  instance.defaults.withCredentials = true;

  export default instance;
