import React, { useState } from 'react';


import MenuItemDisplay from './MenuItemDisplay';
import MenuItemEdit from './MenuItemEdit';



const MenuItem = (props) => {
    const { menuItem, index, categoryId, menuId } = props;

 
    const [isEdit, setIsEdit] = useState(false)

    const handleEdit = (flag) => {
        setIsEdit(flag);
    }
   

    return (
        <>
        { isEdit ? <MenuItemEdit  menuId={menuId} menuItem={menuItem} categoryId={categoryId} index={index} editItem={handleEdit}/> : 
         <MenuItemDisplay menuId={menuId} menuItem={menuItem} categoryId={categoryId} index={index} 
         moveGroup={props.moveGroup}
         editItem={handleEdit}  deleteItem={props.deleteItem} />
    }
    </>
    );
};

export default MenuItem;
