


export const SET_BOOKINGS_DATE = 'SET_BOOKINGS_DATE';


export const setBookingSelectedDate = date => ({
    type: SET_BOOKINGS_DATE,
    payload: date
});


