import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useHistory } from "react-router-dom";

import {useDispatch, useSelector } from 'react-redux';


import moment from "moment";
import RefundAmount from "../OrderPayments/RefundAmount";

import { showSuccessSnackbar,  showErrorSnackbar } from 'store/actions/snackbarMessage'
import API from "util/API";

import {
  checkOrderRefundPermission,
} from "util/userUtils";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const OrderTillPayments = (props) => {
  const { payment, order } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
  const user = useSelector((state) => state.account.user);

  // useEffect(() => {
  //   authMiddleWare(history);
  // }, [props]);

  const orderCreateDate = (order) => {
    const dateFormat = "DD-MM-YY";
    if ("_seconds" in order.createdAt) {
      return moment(new Date(order.createdAt._seconds * 1000)).format(
        dateFormat
      );
    }
    return moment(order.createdAt).format(dateFormat);
  };



  const doRefundAmount = (amount) => {
    console.log("refundalling");
    if (!payment || !order) {
      console.log("payment or order is nil");
      return;
    }

    const formRequest = {
      
      refundAmount: amount,
      payment: payment,
      orderNumber: order.id,
      orderDate: orderCreateDate(order),
    };

    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;

    setButtonLoading(true);
    API.post("/onlinePaymentRefund", formRequest)
      .then((response) => {
        console.log(response.data);
        setButtonLoading(false);
        showSuccess("Refund Successful");
        history.replace("/orders");
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
        showError('Something went wrong with the refund');
      });
  };

  const refundAll = () => {
    if (payment.amount) {
      doRefundAmount(payment.amount);
    } else {
      showError('Something went wrong with the amount');
    }
  };

  const refundAmount = () => {
    setOpen(true);
  };


  const tillRefundsTotal = () => {
    if (!order || !order.tillRefunds || order.tillRefunds.length === 0) {
      return 0;
    }
    let total = 0;
    order.tillRefunds.forEach((refund) => {
      if (refund.result === 'OK') {
        total += Number(refund.amount);
      }
    });
    return total.toFixed(2);

  }

  const isFullRefund = (refundTotal) => {
    if (!order || !payment) {
      return false;
    }
    
    const paymentAmount = Number(payment.amount).toFixed(2);
    return String(refundTotal) === paymentAmount;
  }

  const hideRefundAllButton = () => {
    if (!order || !payment) {
      return true;
    }
    const refundTotal = tillRefundsTotal();
    
    if (isFullRefund(refundTotal)|| (refundTotal > 0 && refundTotal < Number(payment.amount))) {
      return true;
    }
    return false;
  }


  return (
    <React.Fragment>
      {buttonLoading ? <LinearProgress /> : null}
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableCell>Payment Details</TableCell>
            <TableCell align="right">

            { user && checkOrderRefundPermission(user) ? 
        <>
              <Button
                variant="outlined"
                color="primary"
                onClick={refundAll}
                disabled={
                  buttonLoading ||
                  hideRefundAllButton()
                }
              >
                Refund All
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={refundAmount}
                disabled={
                  buttonLoading ||
                  !payment ||
                  isFullRefund(tillRefundsTotal())
                }
              >
                Refund Amount
              </Button>
              </>
              : null }
              <RefundAmount
                open={open}
                isTill={true}
                total={Number(payment.amount)}
                proxy={doRefundAmount}
              />

            </TableCell>
          </TableHead>
          <TableBody>
          <TableRow>
              <TableCell>Payment Type </TableCell>
              <TableCell align="left">Till</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Payment Result Code: </TableCell>
              <TableCell align="left">{payment.result}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Payment Method </TableCell>
              <TableCell align="left">{payment.paymentMethod}</TableCell>
            </TableRow>

            {payment && payment.customer ? (
              <>
                <TableRow>
                  <TableCell>Name </TableCell>
                  <TableCell align="left">{`${payment.customer.firstName ? payment.customer.firstName : ""} ${payment.customer.lastName ? payment.customer.lastName : ""}`}</TableCell>
                </TableRow>
              </>
            ) : null}
            {payment && payment.returnData ? (
              <>
                <TableRow>
                  <TableCell>Bank: </TableCell>
                  <TableCell align="left">
                    {payment.returnData.binBank}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Brand: </TableCell>
                  <TableCell align="left">
                    {payment.returnData.binBrand}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country: </TableCell>
                  <TableCell align="left">
                    {payment.returnData.binCountry}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Card Holder: </TableCell>
                  <TableCell align="left">
                    {payment.returnData.cardHolder}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Card last 4 digits: </TableCell>
                  <TableCell align="left">
                    {payment.returnData.lastFourDigits}
                  </TableCell>
                </TableRow>
              </>
            ) : null}
            <TableRow>
              <TableCell>Currency: </TableCell>
              <TableCell align="left">{payment.currency}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Amount: </TableCell>
              <TableCell align="left">
                {payment.amount && `$${payment.amount}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default OrderTillPayments;
