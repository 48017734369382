import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";

import IconButton from "@material-ui/core/IconButton";

import { useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { showErrorSnackbar } from "store/actions/snackbarMessage";
import TimesheetDeleteDialog from "./TimesheetDeleteDialog";
import { AddOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const TimesheetsToolbar = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  

  
  const selectedTransactionIds = useSelector((state) => state.transaction.ids);

  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const deleteTimesheets = () => {
  

    props.deleteProxy();
  };

  const addTimesheet = () => {
    history.push("/timesheet/add");
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const deleteTimesheet = () => {
  
    props.deleteProxy();
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <IconButton aria-label="delete" onClick={deleteTimesheets} disabled={buttonLoading}>
          <DeleteIcon />
        </IconButton>


        <IconButton aria-label="add" onClick={addTimesheet} disabled={buttonLoading}>
          <AddOutlined />
        </IconButton>
      
      </div>
      <div className={classes.row}>
        {/* <SearchInput
          className={classes.searchInput}
          placeholder="Search order"
        /> */}

        <TimesheetDeleteDialog
          open={deleteDialogOpen}
          deleteProxy={deleteTimesheet}
          close={closeDeleteDialog}
          updateReason={setDeleteReason}
          reason={deleteReason}
        />
      </div>
    </div>
  );
};

TimesheetsToolbar.propTypes = {
  className: PropTypes.string,
};

export default TimesheetsToolbar;
