import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  makeStyles,
  Container,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { fetchWechatOrderStatus } from "apis/orderAPIs";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  loader: {
    margin: theme.spacing(2),
  },
}));

const fetchPaymentStatus = async ({ queryKey }) => {
  const [restaurantId, orderNo] = queryKey;
  const orderStatus = await fetchWechatOrderStatus(restaurantId, orderNo);
  if (!orderStatus) {
    throw new Error("Network response was not ok");
  }
  return orderStatus;
};

const PaymentStatus = () => {
  const classes = useStyles();
  const history = useHistory();
  const { restaurantId, tableId, orderNumber, orderNo } = useParams();
  // get url query params isAppOrder
  const query = new URLSearchParams(window.location.search);
  const isAppOrder = query.get("isAppOrder");

  const userAgent = navigator.userAgent || window.opera;

  const isAndroid = /android/i.test(userAgent);

  const { data, error, isLoading } = useQuery(
    [restaurantId, orderNo],
    fetchPaymentStatus,
    {
      refetchInterval: 5000, // Poll every 5 seconds
      enabled: true,
    }
  );

  const openApp = (appUrl) => {
    const link = document.createElement('a');
        link.style.display = 'none';
        link.href = appUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  };

  useEffect(() => {
    if (data) {
      if (data === "Success") {
        const successUrl = `/obPaymentSuccess/${orderNumber}`;
        if (isAppOrder === "true") {
          window.location.href = successUrl;
          // if (isAndroid) {
          //   const host = window.location.origin;
          //   const finalUrl = `${host}${successUrl}`;
          //   openApp(finalUrl);
          // } else {
          //   window.location.href = successUrl;
          // }
        } else {
          history.push(
            `/orderSuccess/${encodeURIComponent(
              restaurantId
            )}/${tableId}/${orderNumber}?type=success`
          );
        }
      } else {
        if (isAppOrder === "true") {
          const failureUrl = `/obPaymentError/${orderNumber}`;
          window.location.href = failureUrl;

          // const host = window.location.origin;
          // const finalUrl = `${host}${failureUrl}`;
          // if (isAndroid) {
          //   openApp(finalUrl);
          // } else {
          //   window.location.href = failureUrl;
          // }
        } else {
          history.push(
            `/online/${encodeURIComponent(
              restaurantId
            )}/${tableId}/${orderNumber}?type=error`
          );
        }
      }
    }
  }, [data, history]);

  if (isAndroid) {
 {/* <div className={classes.centeredButton}>
        <Button 
      color="primary" 
      variant="contained" 
      style={{ padding: '10px 20px', fontSize: '16px',marginTop: '30px' }} // Larger tap area and readable text
    >
        <a href={window.location.href}>Return to the Orderbuddy App</a>
      </Button>
    </div> */}
  }

  if (isLoading) {
    return (
      <Container className={classes.container}>
        <CircularProgress className={classes.loader} />
        <Typography variant="h6">Checking payment status...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className={classes.container}>
        <Typography variant="h6" color="error">
          Error fetching payment status
        </Typography>
      </Container>
    );
  }

  return null;
};

export default PaymentStatus;
