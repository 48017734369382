import React from "react";
import { Container, Typography, Box, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(2),
    textTransform: "none",
  },
  icon: {
    width: 150,
    height: "auto",
  },
  link: {
    display: "inline-block",
    overflow: "hidden",
    borderRadius: "13px",
    width: "250px",
    height: "83px",
    margin: theme.spacing(2),
  },
  image: {
    borderRadius: "13px",
    width: "250px",
    height: "83px",
  },
  linkAndroid: {
    display: "inline-block",
    overflow: "hidden",
    width: "260",
    height: "101px",
    margin: theme.spacing(2),
  },
  imageAndroid: {
    width: "260",
    height: "101px",
  },
}));

const AppIntroduction = () => {
  const classes = useStyles();
  const theme = useTheme();
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  const isAndroid = /android/i.test(userAgent);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const iosLink = (<>
   <Grid item>
   <a
          href="https://apps.apple.com/us/app/orderbuddy-app/id1599842262?itsct=apps_box_badge&amp;itscg=30200"
          className={classes.link}
        >
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1711670400"
            alt="Download on the App Store"
            className={classes.image}
          />
        </a>
        </Grid>
  </>)

    const androidLink = (<>
     <Grid item>
    <a href="https://play.google.com/store/apps/details?id=au.com.orderbuddy.orderbuddyapp&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" className={classes.linkAndroid}>
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            className={classes.imageAndroid}
          />
        </a></Grid>
    </>)

  return (
    <Container maxWidth="sm">
    <Box my={0}>
        {/* <Typography variant="h5" align="center" gutterBottom>
        Welcome to Orderbuddy App!
      </Typography> */}
      <Grid container justifyContent="center">
      <img
            src="/static/images/obappIntroduction.webp"
            alt="Orderbuddy App"
            style={{ width: isSmallScreen ? "100%" : "auto", maxWidth: "450px" }}
          />
        {isIOS && iosLink}
        {isAndroid && androidLink}
        {!isIOS && !isAndroid && ( <> {iosLink} {androidLink} </> )}
      </Grid>
      </Box>
    </Container>
  );
};

export default AppIntroduction;
