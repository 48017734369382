
import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DatePicker from "react-datepicker";
import API from 'util/API';
import { showErrorSnackbar } from 'store/actions/snackbarMessage';
import { useDispatch } from 'react-redux';

import LinearProgress from "@material-ui/core/LinearProgress";

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    table: {


    },
}));



const DishReport = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [reportData, setReportData] = useState([]);
    const showError = (msg) => dispatch(showErrorSnackbar(msg));


    const getDateReportData = async () => {
     
        try {
            setIsLoading(true);
            const startDateStr = formateDate(startDate);
            const endDateStr = formateDate(endDate);
            const rewsponse = await API.get(`/dishReport?startDateStr=${startDateStr}&endDateStr=${endDateStr}`);
            const data = await rewsponse.data;
           // console.log('data', data);
            if (data) {
                setReportData(data);
            } else {
                setReportData([]);
            }


        } catch (err) {
            console.log(err);
            setReportData([]);
        }
    };

    const formateDate = (date) => {
        return moment(date).format('YYYY-MM-DD')
      };

      const queryDate = () => {
        getDateReportData()  
    };
    

    return (
        <div className={classes.root}>
        {isLoading ? <LinearProgress style = {{ marginBottom: 8 }}/> : null}
          
                <Grid
                    item
                    lg={8}
                    md={12}
                    xl={9}
                    xs={12}
                    container direction="row" alignItems="center"
                >
                    <Typography style={{ margin: theme.spacing(1) }}>Start Date</Typography>
                    <DatePicker selected={startDate}  dateFormat="dd/MM/yyyy"
                    onChange={date => setStartDate(date)} />
                    <Typography style={{ margin: theme.spacing(1) }}>End Date</Typography>
                    <DatePicker selected={endDate}  dateFormat="dd/MM/yyyy"
                    onChange={date => setEndDate(date)} />

                    
           <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: theme.spacing(1)}}
            onClick={e => queryDate()}
          >
            Query
        </Button>
                </Grid>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="dish table">
                    {/* {showtotal && showtotal === true ? */}
                        <TableHead>
                            <TableRow>
                                <TableCell  align="right" colSpan={3} component="th" scope="row" >
                                    <Typography>
                                    {formateDate(startDate)} - {formateDate(endDate)}
                                    </Typography>
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        {/* : null} */}
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.length > 0 && reportData.slice(0, 10).map((item, index) => {
                            return (
                                <TableRow key={item.name}>
                                    <TableCell component="th" scope="row" >

                                        {index + 1}

                                    </TableCell>
                                    <TableCell align="right">{item.name}</TableCell>
                                    <TableCell align="right">{item.count}</TableCell>

                                </TableRow>
                            )
                        })}


                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
}

export default DishReport;
