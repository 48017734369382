import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';

import {  deepPurple } from '@material-ui/core/colors';

import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const account = useSelector((state) => state.account.user);

  const user = {
    name: `${account ? account.firstName : ''} ${account ? account.lastName : ''}`,
    avatar: '/images/avatars/avatar_5.png',
    bio: account ? account.role : '',
    restaurantId: (account  && account.restaurantId) ? account.restaurantId : ''
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt= "last name"
        className={classes.avatar}
        component={RouterLink}
        src="/broken-image.jpg"
        to="/settings"
      >
        {account && account.lastName.length > 0 && account.lastName[0].toUpperCase()}
      </Avatar>
      <Typography
        className={classes.name}
        variant="h4"
      >
        {user.name}
      </Typography>
      <Typography variant="body2">{user.restaurantId}</Typography>
      <Typography variant="body2">{user.bio}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
