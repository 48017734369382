import { SET_BOOKINGS_DATE
 } from '../actions/booking';

import { 
  API_SUCCESS,
  API_FAILURE,
  API_START,
} from '../actions/common'

const initialState = {
 
  bookingSelectedDate: Date(),

}

const bookingReducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case API_START:
      return { ...state, isLoading: true, err: null, apiSuccess: null };

    case API_SUCCESS:
      console.log('api success');
      return { ...state, apiSuccess: true, isLoading: false };

    case API_FAILURE:
      return { ...state, err: payload, isLoading: false };

    
    case SET_BOOKINGS_DATE:
        return { ...state, bookingSelectedDate: payload };
    
    default:
      return state;
  }
};

export default bookingReducer;
