import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const AddressForm = ({address, setAddress}) => {
  const handleChange = (field) => (event) => {
    setAddress({
      ...address,
      [field]: event.target.value,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={6} lg={6}>
        <TextField
          label="Country"
          variant="outlined"
          fullWidth
          value={address.country}
          onChange={handleChange('country')}
        />
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <TextField
          label="Line 1"
          variant="outlined"
          fullWidth
          value={address.line1}
          onChange={handleChange('line1')}
        />
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <TextField
          label="Line 2"
          variant="outlined"
          fullWidth
          value={address.line2}
          onChange={handleChange('line2')}
        />
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <TextField
          label="Line 3"
          variant="outlined"
          fullWidth
          value={address.line3}
          onChange={handleChange('line3')}
        />
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <TextField
          label="Suburb or City"
          variant="outlined"
          fullWidth
          value={address.city}
          onChange={handleChange('city')}
        />
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <TextField
          label="State or Province"
          variant="outlined"
          fullWidth
          value={address.stateOrProvince}
          onChange={handleChange('stateOrProvince')}
        />
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <TextField
          label="Postal Code"
          variant="outlined"
          fullWidth
          value={address.postalCode}
          onChange={handleChange('postalCode')}
        />
      </Grid>
    </Grid>
  );
}

export default AddressForm;
