import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";

import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import clsx from "clsx";

import { useDispatch } from "react-redux";

import API from "util/API";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  root: {
    padding: theme.spacing(3),
  },
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  Media: {},
}));

const BuyNFreePromotion = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const promotionId = props.match.params.promotionId;

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    saveOrUpdatePromotion(data);
  };

  const history = useHistory();

  const [uiLoading, setUiLoading] = useState(false);
  const [promotion, setPromotion] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));

  useEffect(() => {
    getPromotion(promotionId);
  }, [promotionId]);

  const getPromotion = async (promoId) => {
    if (!promoId || promoId.length === 0) {
      return;
    }
    setUiLoading(true);
    try {
      const response = await API.get(`/promotions/${promoId}`);
      if (response && response.data && response.data.promotion) {
        setPromotion(response.data.promotion);
      }
      setUiLoading(false);
    } catch (err) {
      setUiLoading(false);
      showError("Something went wrong, please try again.");
    }
  };

  useEffect(() => {
    if (promotion) {
      setTimeout(() => {
        setValue("isEnabled", promotion.isEnabled);
        setValue("name", promotion.name);
        setValue("description", promotion.description);
        setValue("maxAmount", promotion.maxAmount);
        setValue("expiryDays", promotion.expiryDays);
      });
    }
  }, [promotion, setValue]);

  const saveOrUpdatePromotion = (data) => {
    setButtonLoading(true);

    const formRequest = {
      promotion: { ...data, type: "buyNFree", isFreeItem: true },
      promotionId,
    };

    try {
      API.post("/promotions", formRequest);
      setButtonLoading(false);
      showSuccess("Saved successfully");
      history.push("/promotions");
    } catch (error) {
      if (error.response && error.response.status === 403) {
        props.history.push("/sign-in");
      }
      console.log(JSON.stringify(error));
      setButtonLoading(false);
      showError("Something went wrong, please try again.");
    }
  };

  {
    if (uiLoading === true) {
      return (
        <main className={classes.content}>
          {uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else {
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card className={clsx(classes.root, classes)}>
                <Box m={1}>
                  <Typography variant="h3">
                    {" "}
                    Buy N get a free Item Promotion
                  </Typography>
                </Box>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Typography>Enabled</Typography>
                      <Controller
                        name="isEnabled"
                        defaultValue={true}
                        control={control}
                        render={(props) => (
                          <Switch
                            onChange={(e) => props.onChange(e.target.checked)}
                            checked={props.value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Typography>Name</Typography>
                      <Controller
                        as={TextField}
                        name="name"
                        type="text"
                        control={control}
                        rules={{ required: true, maxLength: 20 }}
                      />
                      {errors.name && (
                        <Typography>
                          <Box color="error.main">Name is required</Box>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Typography>Description</Typography>
                      <Controller
                        as={TextField}
                        name="description"
                        type="text"
                        multiline
                        control={control}
                        rules={{ required: true, maxLength: 100 }}
                      />
                      {errors.description && (
                        <Typography>
                          <Box color="error.main">Description is required</Box>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Typography>Maximum Amount</Typography>
                      <Controller
                        as={TextField}
                        name="maxAmount"
                        type="number"
                        control={control}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.maxAmount && (
                        <Typography>
                          <Box color="error.main">
                            The maximum amount is required.
                          </Box>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Typography>Voucher expriry days</Typography>
                      <Controller
                        as={TextField}
                        name="expiryDays"
                        type="number"
                        control={control}
                        rules={{
                          required: true,
                          min: 1,
                        }}
                      />
                      {errors.expiryDays && (
                        <Typography>
                          <Box color="error.main">Expriry days is required</Box>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions />
              </Card>

              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitButton}
                disabled={buttonLoading}
              >
                Save
                {buttonLoading && (
                  <CircularProgress size={30} className={classes.progess} />
                )}
              </Button>
            </form>
          </div>
        </div>
      );
    }
  }
};

export default BuyNFreePromotion;
