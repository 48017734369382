import { FETCH_ACCOUNTS_SUCCESS, FETCH_USER_SUCCESS, 
  SET_SELECTED_USERIDS,
  UPDATE_FILTER_KEYWORD } from '../actions/account';

const initialState = {
  accounts: [],
  selectedUserIds: [],
  filter: null,
}

const accountReducer = (state = initialState, action) => {
  const {type, payload} = action
  
  switch (type) {
    case FETCH_ACCOUNTS_SUCCESS:
    
      return { ...state, accounts: action.payload };
    case FETCH_USER_SUCCESS:
      return { ...state, user: action.payload };

    case SET_SELECTED_USERIDS:
      return { ...state, selectedUserIds: action.payload };

      case UPDATE_FILTER_KEYWORD:
        return { ...state, filter: payload};

    default:
      return state;
  }
};

export default accountReducer;
