import React, { useState, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useConfirm } from 'material-ui-confirm';

import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,

  TablePagination,
} from "@material-ui/core";


import { updateSelectedUserIds } from "store/actions/account";
import { useDispatch, useSelector } from "react-redux";
import ListContext from '../../ListContext';


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const PaymentTypeTable = (props) => {
  const { className, paymentTypes, assignTerminal, ...rest } = props;

  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  
  const { setSelectedItemId } = useContext(ListContext);

  
  

  const handleSelectAll = (event) => {
    

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = paymentTypes.map((user) => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedItems(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedItems(newSelectedUsers);
    if(newSelectedUsers.length === 1) {
      setSelectedItemId(newSelectedUsers[0]);
    } else {
      setSelectedItemId(null);
    }
    // setSelectedItemId(id);
    // updateUserIds(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  // const filterStores = () => {
  //   if (!searchText || searchText === null || searchText.trim() === "") {
  //     return paymentTypes;
  //   }
  //   const searchTextLower = searchText.toLowerCase();

  //   return paymentTypes.filter(
  //     (item) =>
  //       (item.store && item.store.toLowerCase().includes(searchTextLower)) ||
  //       (item.inStoreTerminals && item.inStoreTerminals.map(terminal => terminal.toLowerCase).includes(searchTextLower)) 
  //   );
  // };



  return (
    <Grid lg={12} md={12} xl={12} xs={12}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          {/* <PerfectScrollbar> */}
          <div className={classes.inner}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedItems.length === paymentTypes.length}
                        color="primary"
                        indeterminate={
                          selectedItems.length > 0 &&
                          selectedItems.length < paymentTypes.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Payment Type</TableCell>
                    <TableCell>Surcharge Rate (%)</TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Sort</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentTypes
                    .slice(page * rowsPerPage, rowsPerPage * page + rowsPerPage)
                    .map((paymentType, index) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={index}
                      
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedItems.indexOf(paymentType.paymentTypeId) !== -1
                            }
                            color="primary"
                            onChange={(event) =>
                              handleSelectOne(event, paymentType.paymentTypeId)
                            }
                            value="true"
                          />
                        </TableCell>
                        <TableCell>

                     
                          {paymentType.paymentType}
                     
                          
                        </TableCell>
                        <TableCell>{paymentType.surchargeRate}</TableCell>
                        <TableCell>
                          {paymentType.enabled ? 'Yes': 'No'}
                        </TableCell>
                        <TableCell>{paymentType.sort}</TableCell>
                        <TableCell>{paymentType.description}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>

              </Table>
            </TableContainer>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={paymentTypes.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

PaymentTypeTable.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.array.isRequired,
};

export default PaymentTypeTable;
