import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const RefundAmount = props => {
  const { total, isTill} = props;
  const [open, setOpen] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  console.log('open', open, );


  const handleClose = () => {
    setOpen(false);
  };

  const doRefund = () => {
    setOpen(false);
    if (isTill) {
      props.proxy(refundAmount);
    } else {
      props.proxy(Number(refundAmount * 100).toFixed(0));
    }
    
  };

 useEffect(() => {
     setOpen(props.open);
 },[props])

  return (
    <div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Refund Amount</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            value={refundAmount}
            id="refundAmount"
            label="Refund Amount"
            type="number"
            onChange={e => setRefundAmount(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={doRefund} 
           disabled={
               refundAmount > total  || 
               refundAmount === 0
           }
          color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RefundAmount;