import React, { useState, useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Select from "react-select";
import moment from 'moment-timezone';

import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import clsx from "clsx";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "react-query";
import { queryUsers } from "apis/userAPIs";

import { useSelector, useDispatch } from "react-redux";

import { updateSelectedRestaurant } from "store/actions/restaurant";

import { RestaurantToolbar } from "./components";
import API from "util/API";
import { useHistory } from "react-router-dom";
import { showErrorSnackbar } from "store/actions/snackbarMessage";
import AddressField from "components/AddressField";

function useUsernameOptions(inputValue) {
  return useQuery(["usernames", inputValue], () => queryUsers(inputValue));
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },

  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  toolbar: theme.mixins.toolbar,
  root: {
    padding: theme.spacing(3),
  },
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progress: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
}));

const Restaurant = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [altName, setAltName] = useState("");
  const [address, setAddress] = useState("");
  const [addressObject, setAddressObject] = useState(null);

  const [abn, setAbn] = useState("");
  const [taxName,  setTaxName] = useState("");
  const [taxPercentage, setTaxPercentage] = useState(10);
  const [restaurantAdmin, setRestaurantAdmin] = useState("");
  const [restrauntDescription, setRestrauntDescription] = useState("");
  const [serviceMonthlyPrice, setServiceMonthlyPrice] = useState(49.95);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [uiLoading, setUiLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [urlIdentifier, setUrlIdentifier] = useState("");
  const [idError, setIdError] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [stripeFeeOnUs, setStripeFeeOnUs] = useState(false);
  const [isApplicationFeeEnabled, setIsApplicationFeeEnabled] = useState(false);
  
  const [fantuanStoreId, setFantuanStoreId] = useState("");
  const [chinaPaymentsMerchantCode, setChinaPaymentsMerchantCode] = useState("");
  const [applicationFee, setApplicationFee] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [online, setOnline] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState(null);
  const [takeawayPayment, setTakeawayPayment] = useState(false);
  const [phone, setPhone] = useState("");
  const [postCode, setPostcode] = useState("");
  const [state, setState] = useState("");
  const [smsVerify, setSmsVerify] = useState(false);
  const [isFranchise, setIsFranchise] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  // const [adyenMerchantCode, setAdyenMerchantCode] = useState("");
  const [disableAdyenMinimumSurcharge, setDisableAdyenMinimumSurcharge] =
    useState(false);

  const [timezone, setTimezone] = useState("Australia/Sydney");
  const [tz, setTz] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const timezoneOptions = moment.tz.names().map(zone => ({ label: zone, value: zone }));
  const [selectedType, setSelectedType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  // console.log("selectedCategory, selectedType", selectedCategory, selectedType);

  const [paymentPartnerCode, setPaymentPartnerCode] = useState("");
  const [paymentPartnerCredential, setPaymentPartnerCredential] = useState("");
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [onlinePaymentSurchargeRate, setOnlinePaymentSurchargeRate] =
    useState(0);
  const [inPersonPaymentSurchargeRate, setInPersonPaymentSurchargeRate] =
    useState(0);
  const { data: usernameOptions } = useUsernameOptions(restaurantAdmin);
  const user = useSelector((state) => state.account.user);

  const handleRateChange = (setter) => (event) => {
    const value = event.target.value;
    if (value === "" || /^0\.\d{0,3}$|^[1-9]\d*(\.\d{0,3})?$/.test(value)) {
      setter(value);
    }
  };

  const restaurants = useSelector((state) => state.restaurant.restaurants);

  const saveMyRestaurant = (restaurant) =>
    dispatch(updateSelectedRestaurant(restaurant));
  const showError = (msg) => dispatch(showErrorSnackbar(msg));



  useEffect(() => {
    const restaurantId = props.match.params.restaurantId;
    // console.log("restaurantId", restaurantId);
    if (restaurantId && restaurants && restaurants.length > 0) {
      const restaurantIdx = restaurants.findIndex(
        (item) => item.urlIdentifier === restaurantId
      );
      setSelectedRestaurant(restaurants[restaurantIdx]);
      saveMyRestaurant(restaurants[restaurantIdx]);
    }
  }, [props, restaurants]);

  useEffect(() => {
    // console.log("selectedRestaurant", selectedRestaurant);
    if (selectedRestaurant) {
      setName(selectedRestaurant.name);
      setAltName(selectedRestaurant.altName);
      setAddress(selectedRestaurant.address);
      setRestrauntDescription(selectedRestaurant.restrauntDescription);
      setAbn(selectedRestaurant.abn);
      setUrlIdentifier(selectedRestaurant.urlIdentifier);
      setRestaurantAdmin(selectedRestaurant.restaurantAdmin);
      setPaymentPartnerCode(selectedRestaurant.paymentPartnerCode);
      setPaymentPartnerCredential(selectedRestaurant.paymentPartnerCredential);

      setOnline(selectedRestaurant.isOnline);
      if (selectedRestaurant.takeawayPayment === undefined) {
        setTakeawayPayment(true);
      } else {
        setTakeawayPayment(selectedRestaurant.takeawayPayment);
      }
      if (selectedRestaurant.phone) {
        setPhone(selectedRestaurant.phone);
      }

      if (selectedRestaurant.stripeFeeOnUs !== undefined) {
        setStripeFeeOnUs(selectedRestaurant.stripeFeeOnUs);
      }

      if (selectedRestaurant.latitude !== undefined) {
        setLatitude(selectedRestaurant.latitude);
      }

      if (selectedRestaurant.smsVerify !== undefined) {
        setSmsVerify(selectedRestaurant.smsVerify);
      }

      if (selectedRestaurant.isFranchise !== undefined) {
        setIsFranchise(selectedRestaurant.isFranchise);
      }

      if (selectedRestaurant.longitude !== undefined) {
        setLongitude(selectedRestaurant.longitude);
      }
      if (
        selectedRestaurant.serviceMonthlyPrice !== undefined &&
        selectedRestaurant.serviceMonthlyPrice !== null
      ) {
        setServiceMonthlyPrice(selectedRestaurant.serviceMonthlyPrice);
      }

      if (
        selectedRestaurant.isApplicationFeeEnabled !== undefined &&
        selectedRestaurant.isApplicationFeeEnabled !== null
      ) {
        setIsApplicationFeeEnabled(selectedRestaurant.isApplicationFeeEnabled);
      }

      if (
        selectedRestaurant.applicationFee !== undefined &&
        selectedRestaurant.applicationFee !== null &&
        !isNaN(selectedRestaurant.applicationFee)
      ) {
        setApplicationFee(Number(selectedRestaurant.applicationFee));
      }

      if (
        selectedRestaurant.restaurantCategory !== undefined &&
        selectedRestaurant.restaurantCategory !== null
      ) {
        setSelectedCategory({
          value: selectedRestaurant.restaurantCategory,
          label: selectedRestaurant.restaurantCategory,
        });
      }

      if (
        selectedRestaurant.restaurantType !== undefined &&
        selectedRestaurant.restaurantType !== null
      ) {
        setSelectedType({
          value: selectedRestaurant.restaurantType,
          label: splitCamelCase(selectedRestaurant.restaurantType),
        });
      }

 

      if (
        selectedRestaurant.onlinePaymentSurchargeRate !== undefined &&
        selectedRestaurant.onlinePaymentSurchargeRate !== null
      ) {
        setOnlinePaymentSurchargeRate(
          selectedRestaurant.onlinePaymentSurchargeRate
        );
      }

      if (
        selectedRestaurant.inPersonPaymentSurchargeRate !== undefined &&
        selectedRestaurant.inPersonPaymentSurchargeRate !== null
      ) {
        setInPersonPaymentSurchargeRate(
          selectedRestaurant.inPersonPaymentSurchargeRate
        );
      }

      if (
        selectedRestaurant.addressObject !== undefined &&
        selectedRestaurant.addressObject !== null
      ) {
        setAddressObject(selectedRestaurant.addressObject);
      }

      if (
        selectedRestaurant.disableAdyenMinimumSurcharge !== undefined &&
        selectedRestaurant.disableAdyenMinimumSurcharge !== null
      ) {
        setDisableAdyenMinimumSurcharge(
          selectedRestaurant.disableAdyenMinimumSurcharge
        );
      }

      if (
        selectedRestaurant.taxName !== undefined &&
        selectedRestaurant.taxName !== null
      ) {
        setTaxName(selectedRestaurant.taxName);
      }

      if (
        selectedRestaurant.taxPercentage !== undefined &&
        selectedRestaurant.taxPercentage !== null
      ) {
        setTaxPercentage(selectedRestaurant.taxPercentage);
      }

      if (selectedRestaurant.fantuanStoreId !== undefined && selectedRestaurant.fantuanStoreId !== null) {
        setFantuanStoreId(selectedRestaurant.fantuanStoreId);
      }

      if (selectedRestaurant.chinaPaymentsMerchantCode !== undefined && selectedRestaurant.chinaPaymentsMerchantCode !== null) {
        setChinaPaymentsMerchantCode(selectedRestaurant.chinaPaymentsMerchantCode);
      }

      if (selectedRestaurant.merchantId !== undefined && selectedRestaurant.merchantId !== null) {
        setMerchantId(selectedRestaurant.merchantId);
      }
    }
  }, [restaurants, selectedRestaurant]);

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    if (!value) {
      setNameError("Restaurant name is required");
    } else if (value.length < 3) {
      setNameError("Restaurant name must be at least 3 characters long");
    } else {
      setNameError("");
    }
  };

  function isValidUrlIdentifier(urlIdentifier) {
    const regex = /^[a-zA-Z0-9]+$/;
    const result = regex.test(urlIdentifier);
    if (!result) {
      setIdError("The restaurant Id cannot include a space, %, &, -, _ and ?.");
    }
    return result;
  }

  const handleIdChange = (e) => {
    const value = e.target.value;
    setUrlIdentifier(value);
    if (isValidUrlIdentifier(value)) {
      setIdError("");
    }
  };

  const logoPictureHandler = (event) => {
    event.preventDefault();
    setUiLoading(true);
    //authMiddleWare(props.history);
    // const authToken = localStorage.getItem("AuthToken");
    let form_data = new FormData();
    form_data.append("image", image);
    form_data.append("content", content);
    // API.defaults.headers.common["Authorization"] = authToken;
    API.post("/restaurant/image/" + urlIdentifier, form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(() => {
        setUiLoading(false);
        history.push("/restaurants");
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setUiLoading(false);
        setImageError("Error in posting the data");
      });
  };

  const handleErrorClose = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  const updateFormValues = (event) => {
    event.preventDefault();

    if (!selectedRestaurant && !isValidUrlIdentifier(urlIdentifier)) {
      return;
    }

    // check taxPercentage is ineteger

    if(!Number.isInteger(Number(taxPercentage))) {
      setErrorMsg("Tax percentage must be an integer");
      setOpenErrorSnackbar(true);
      return;
    }

    if (Number(taxPercentage) < 0 || Number(taxPercentage) > 100) {
      setErrorMsg("Tax percentage must be between 0 and 100");
      setOpenErrorSnackbar(true);
      return;
    }

    setButtonLoading(true);
    // authMiddleWare(props.history);
    // const authToken = localStorage.getItem("AuthToken");
    // API.defaults.headers.common["Authorization"] = authToken;
    //axios.defaults.headers.common = { Authorization: `${authToken}` };
    const formRequest = {
      name,
      altName,
      address: address,
      restrauntDescription: restrauntDescription,
      abn: abn,
      taxName,
      taxPercentage: Number(taxPercentage),
      restaurantAdmin: restaurantAdmin,
      urlIdentifier: urlIdentifier,
      paymentPartnerCredential: paymentPartnerCredential,
      paymentPartnerCode: paymentPartnerCode,
      isOnline: online,
      takeawayPayment,
      phone,
      stripeFeeOnUs,
      latitude: Number(latitude),
      longitude: Number(longitude),
      smsVerify,
      isFranchise,
      type: "restaurant",
      serviceMonthlyPrice,
      // isApplicationFeeEnabled,
      applicationFee: Number(applicationFee),
      restaurantType:
        selectedType && selectedType.value ? selectedType.value : null,
      restaurantCategory:
        selectedCategory && selectedCategory.value
          ? selectedCategory.value
          : null,
      fantuanStoreId,
      onlinePaymentSurchargeRate: Number(onlinePaymentSurchargeRate),
      inPersonPaymentSurchargeRate: Number(inPersonPaymentSurchargeRate),
      addressObject: addressObject,
      disableAdyenMinimumSurcharge,
      timezone,
      chinaPaymentsMerchantCode,
      merchantId,
    };
    if (selectedRestaurant) {
      API.put("/restaurant/" + urlIdentifier, formRequest)
        .then(() => {
          setButtonLoading(false);
          props.history.push("/restaurants");
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            props.history.push("/sign-in");
          }
          console.log(JSON.stringify(error));
          setButtonLoading(false);
          setErrorMsg(error.msg);
          setOpenErrorSnackbar(true);
        });
    } else {
      API.post("/restaurant", formRequest)
        .then(() => {
          setButtonLoading(false);
          props.history.push("/restaurants");
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            props.history.push("/sign-in");
          }
          console.log(JSON.stringify(error));
          setButtonLoading(false);
          setErrorMsg(error.msg);
          setOpenErrorSnackbar(true);
        });
    }
  };

  //splitCamelCase
  const splitCamelCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
      .replace(/^./, (str) => {
        return str.toUpperCase();
      });
  };

  const restaurantTypes = [
    "Bakery",
    "Beverages",
    "BreakfastAndBrunch",
    "BubbleTea",
    "Buffet",
    "Cafe",
    "CasualDining",
    "Coffee",
    "FamilyStyle",
    "FastFood",
    "FineDining",
    "FishChips",
    "Halal",
    "IceCreamAndFrozenYoghurt",
    "JuiceAndSmoothies",
    "Seafood",
    "Steakhouse",
    "Vegetarian",
  ].map((type) => ({ value: type, label: splitCamelCase(type) }));

  const restaurantCategories = [
    "Arabian",
    "Australian",
    "Chinese",
    "Indian",
    "Italian",
    "Japanese",
    "Korean",
    "Lebanese",
    "Malaysian",
    "Mediterranean",
    "Mexican",
    "Persian",
    "Portuguese",
    "Thai",
    "Turish",
    "Vietnamese",
  ].map((category) => ({ value: category, label: category }));

  const handleAddressChange = (newAddress) => {
    const addressString = `${newAddress.line1} ${newAddress.line2} ${newAddress.line3} ${newAddress.city} ${newAddress.stateOrProvince} ${newAddress.country} ${newAddress.postalCode}`;
    setAddress(addressString);
    setAddressObject(newAddress);
    if (newAddress.location) {
      setLatitude(newAddress.location.lat);
      setLongitude(newAddress.location.lng);
    }
  };

  const handleSelectChange = selectedOption => {
    // console.log('selectedOption', selectedOption);
    setTimezone(selectedOption.value);
  };

  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </main>
    );
  } else {
    return (
      <div className={classes.root}>
        {selectedRestaurant ? (
          <RestaurantToolbar restaurant={selectedRestaurant} />
        ) : null}
        <div className={classes.content}>
          <Card className={clsx(classes.root, classes)}>
            <form autoComplete="off" noValidate>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {selectedRestaurant && selectedRestaurant.logo ? (
                    <img
                      src={selectedRestaurant.logo}
                      alt="logo"
                      height={100}
                      width={200}
                    />
                  ) : null}
                  <Grid item md={6} xs={12}>
                    <div>
                      <Typography gutterBottom variant="h4">
                        Upload Restaurant Logo
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        size="small"
                        startIcon={<CloudUploadIcon />}
                        className={classes.uploadButton}
                        onClick={logoPictureHandler}
                      >
                        Upload Logo
                      </Button>
                      <input type="file" onChange={handleImageChange} />

                      {imageError ? (
                        <div className={classes.customError}>
                          {" "}
                          Wrong Image Format || Supported Format are PNG and JPG
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Restaurant Name *"
                      margin="dense"
                      name="name"
                      variant="outlined"
                      value={name}
                      error={!!nameError}
                      helperText={nameError}
                      onChange={handleNameChange}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Restaurant Alt Name"
                      margin="dense"
                      name="altName"
                      variant="outlined"
                      value={altName}
                      onChange={(e) => setAltName(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={online}
                          onChange={(e) => setOnline(e.target.checked)}
                          name="online"
                        />
                      }
                      label="Online"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Restaurant description"
                      margin="dense"
                      name="restrauntDescription"
                      variant="outlined"
                      value={restrauntDescription}
                      onChange={(e) => setRestrauntDescription(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Address"
                      margin="dense"
                      name="address"
                      variant="outlined"
                      disabled
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <AddressField
                      onAddressChange={handleAddressChange}
                      addressObject={addressObject}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Phone"
                      margin="dense"
                      name="phone"
                      variant="outlined"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Tax Number"
                      margin="dense"
                      name="abn"
                      variant="outlined"
                      value={abn}
                      onChange={(e) => setAbn(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Tax Name"
                      margin="dense"
                      name="taxName"
                      
                      variant="outlined"
                      value={taxName}
                      onChange={(e) => setTaxName(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Tax Percentage %"
                      margin="dense"
                      name="taxPercentage"
                      type="number"
                      variant="outlined"
                      value={taxPercentage}
                      onChange={(e) => setTaxPercentage(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Restaurant identifier (used for order website) *"
                      margin="dense"
                      name="urlIdentifier"
                      variant="outlined"
                      disabled={selectedRestaurant}
                      value={urlIdentifier}
                      onChange={handleIdChange}
                      error={!!idError}
                      helperText={idError}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>


                    <Select
                      className={classes.select}
                      value={{ label: timezone, value: timezone }}
                      onChange={handleSelectChange}
                      options={timezoneOptions}
                      placeholder="Select a timezone"
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                      }}
                    />


                  </Grid>

                  <Grid item md={6} xs={12}>

                    <Autocomplete
                      id="username-autocomplete"
                      options={usernameOptions || []}
                      getOptionLabel={(option) => option.username}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=" Merchant Admin"
                          variant="outlined"
                          value={restaurantAdmin}
                          fullWidth
                        />
                      )}
                      onInputChange={(event, value) => {
                        setRestaurantAdmin(value);
                      }}
                    />
                    {/* <TextField
                      fullWidth
                      label="Retaurant Admin"
                      margin="dense"
                      name="restaurantAdmin"
                      variant="outlined"
                      value={restaurantAdmin}
                      onChange={(e) => setRestaurantAdmin(e.target.value)}
                    /> */}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Retaurant latitude"
                      margin="dense"
                      name="latitude"
                      variant="outlined"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Retaurant longitude"
                      margin="dense"
                      name="longitude"
                      variant="outlined"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Select
                      value={selectedType}
                      onChange={setSelectedType}
                      options={restaurantTypes}
                      placeholder="Select Restaurant Type"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Select
                      value={selectedCategory}
                      onChange={setSelectedCategory}
                      options={restaurantCategories}
                      placeholder="Select Category"
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={takeawayPayment}
                          onChange={(e) => setTakeawayPayment(e.target.checked)}
                          name="takeawayPayment"
                        />
                      }
                      label="Takeaway Online Payment"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={stripeFeeOnUs}
                          onChange={(e) => setStripeFeeOnUs(e.target.checked)}
                          name="stripeFeeOnUs"
                        />
                      }
                      label="Stripe fee on us"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={smsVerify}
                          onChange={(e) => setSmsVerify(e.target.checked)}
                          name="smsVerify"
                        />
                      }
                      label="SMS Verification"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={isFranchise}
                          onChange={(e) => setIsFranchise(e.target.checked)}
                          name="isFranchise"
                        />
                      }
                      label="Is Franchise"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={disableAdyenMinimumSurcharge}
                          onChange={(e) =>
                            setDisableAdyenMinimumSurcharge(e.target.checked)
                          }
                          name="disableAdyenMinimumSurcharge"
                        />
                      }
                      label="Disable Adyen minimum surcharge"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Service monthly price"
                      margin="dense"
                      type="number"
                      name="serviceMonthlyPrice"
                      variant="standard"
                      value={serviceMonthlyPrice}
                      onChange={(e) =>
                        setServiceMonthlyPrice(Number(e.target.value))
                      }
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Online Payment surcharge rate (%)"
                      margin="dense"
                      type="text"
                      name="onlinePaymentSurchargeRate"
                      variant="standard"
                      value={onlinePaymentSurchargeRate}
                      onChange={handleRateChange(setOnlinePaymentSurchargeRate)}
                      inputProps={{ inputMode: "decimal" }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="In person payment surcharge rate (%)"
                      margin="dense"
                      type="text"
                      name="inPersonPaymentSurchargeRate"
                      variant="standard"
                      value={inPersonPaymentSurchargeRate}
                      onChange={handleRateChange(
                        setInPersonPaymentSurchargeRate
                      )}
                      inputProps={{ inputMode: "decimal" }}
                    />
                  </Grid>
{/* 
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Hubster Store ID"
                      margin="dense"
                      name="hubsterStoreId"
                      variant="outlined"
                      value={hubsterStoreId}
                      onChange={(e) => setHubsterStoreId(e.target.value)}
                    />
                  </Grid> */}

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Fantuan Shop ID"
                      margin="dense"
                      name="fantuanStoreId"
                      variant="outlined"
                      value={fantuanStoreId}
                      onChange={(e) => setFantuanStoreId(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="China Payments Merchant Code"
                      margin="dense"
                      name="chinaPaymentsMerchantCode"
                      variant="outlined"
                      value={chinaPaymentsMerchantCode}
                      onChange={(e) => setChinaPaymentsMerchantCode(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Orderbuddy App Merchant Id"
                      margin="dense"
                      name="merchantId"
                      variant="outlined"
                      value={merchantId}
                      onChange={(e) => setMerchantId(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions />
            </form>
          </Card>
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={2000}
            onClose={handleErrorClose}
          >
            <Alert onClose={handleErrorClose} severity="error">
              {errorMsg ? errorMsg : "Something went wrong! Please try again."}
            </Alert>
          </Snackbar>
          { user && user.role === 'admin' ?
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className={classes.submitButton}
            onClick={updateFormValues}
            disabled={buttonLoading || !name || !urlIdentifier || !timezone}
          >
            Save Restaurant
            {buttonLoading && (
              <CircularProgress size={30} className={classes.progess} />
            )}
          </Button> : null }
        </div>
      </div>
    );
  }
};

export default Restaurant;
