import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";



import { v4 as uuid } from "uuid";
import { removeItemWithSlice } from "util/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  buttonLowCase: {
    textTransform: "none",
  },
}));

const BookingTables = (props) => {
  const classes = useStyles();

  const [tables, setTables] = useState([]);

  useEffect(() => {
    if (props.tablesList) {
        setTables(props.tablesList);
    }
    
  }, [props]);

  const addNewTable = () => {
    let newTables = [...tables];
    newTables.push({ id: uuid(), tableNumber: "", guestNumber: 0 });
    console.log("newTables", newTables);
    //  props.updateSizes(newSizes)
    setTables(newTables);
  };

  const updateTableNum = (value, id) => {
    let newTables = [...tables];
    const index = newTables.findIndex((table) => table.id === id);
    if (index !== -1) {
      let table = newTables[index];
      newTables[index] = { ...table, tableNumber: value };
      props.updateTables(newTables);
      setTables(newTables);
    }
  };

  const updateGuestNum = (value, id) => {
    
    let newTables = [...tables];
    const index = newTables.findIndex((table) => table.id === id);
    if (index !== -1) {
      let table = newTables[index];

      newTables[index] = { ...table, guestNumber: value };
      props.updateTables(newTables);
      setTables(newTables);
    }
  };

  const updateMinGuestNum = (value, id) => {
    
    let newTables = [...tables];
    const index = newTables.findIndex((table) => table.id === id);
    if (index !== -1) {
      let table = newTables[index];

      newTables[index] = { ...table, minNumOfGuests: value };
      props.updateTables(newTables);
      setTables(newTables);
    }
  };

  const deleteTable = (id) => {
    const index = tables.findIndex((table) => table.id === id);
    if (index !== -1) {
      const newTables = removeItemWithSlice(tables, index);
      props.updateTables(newTables);
      setTables(newTables);
    }
  };



  return (

    <Grid container direction="column" spacing={2}>
      {tables && tables.length > 0 ? (
        <>
          {tables.map((table) => {
            return (
              <Grid
                item
                container
                direction="row"
                spacing={1}
                key={table.id}
                alignItems="center"
              >
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    label="Table Number"
                    margin="dense"
                    name="tableNumber"
                    variant="outlined"
                    value={table.tableNumber}
                    onChange={(e) => updateTableNum(e.target.value, table.id)}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    label="Guest Number"
                    margin="dense"
                    name="guestNumber"
                    variant="outlined"
                    type="number"
                    value={table.guestNumber}
                    onChange={(e) => updateGuestNum(e.target.value, table.id)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    label="Guest Min Number"
                    margin="dense"
                    name="minNumOfGuests"
                    variant="outlined"
                    type="number"
                    value={table.minNumOfGuests}
                    onChange={(e) => updateMinGuestNum(e.target.value, table.id)}
                  />
                </Grid>

                <Grid item xs={2}>
                  <IconButton
                    aria-label="more"
                    className={classes.margin}
                    edge="end"
                    color="secondary"
                    onClick={(e) => deleteTable(table.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={4}>
            <Button
              className={classes.buttonLowCase}
              variant="outlined"
              color="primary"
              size="small"
              onClick={addNewTable}
            >
              Add Table
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item xs={4}>
          <Button
            className={classes.buttonLowCase}
            variant="outlined"
            color="primary"
            onClick={addNewTable}
            size="medium"
          >
            Add Tables
          </Button>
        </Grid>
      )}
    </Grid>

  );
};

export default BookingTables;
