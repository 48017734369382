import React from "react";
import { makeStyles } from "@material-ui/styles";

import { KioskListToolbar, KioskTable } from "./components";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert } from "@material-ui/lab";

import { getAllKiosks } from "apis/devicesAPIs";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const KioskList = (props) => {
  const classes = useStyles();

  const { isLoading, isError, data, error } = useQuery(
    "KioskList",
    getAllKiosks
  );

  if (isError) {
    return (
      <main className={classes.content}>
        <Alert severity="error">{`${error.message} `}</Alert>{" "}
      </main>
    );
  }

  if (isLoading === true) {
    return (
      <main className={classes.content}>
        {isLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </main>
    );
  } else {
    return (
      <div className={classes.root}>
        <KioskListToolbar />
        <div className={classes.content}>
          {data ? <KioskTable deviceList={data} /> : null}
        </div>
      </div>
    );
  }
};

export default KioskList;
