import React, { useEffect } from "react";

import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { List, ListItem, Box } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import { Alert } from "@material-ui/lab";

import { transformOrderId } from "util/orderUtils";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadOrdersSummary } from "store/actions/order";
import QRCode from "qrcode.react";
import { decodeRestaurantId } from "util/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  progess: {
    position: "absolute",
  },
  table: {},
  qtyCell: {
    width: 10,
  },
  nameCell: {
    width: 200,
  },
}));

const OrderDetail = (props) => {
  const { className, ...rest } = props;
  const ordersSummary = useSelector((state) => state.order.ordersSummary);
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();
  // const order = useSelector((state) => state.restaurant.selectedOrder);
  // console.log('document.referrer', document.referrer);

  const restaurant = useSelector((state) => state.menu.restaurant);

  const id = props.match.params.menuId;
  const tableId = props.match.params.tableId;
  const loadOrder = (restaurant) => dispatch(loadOrdersSummary(restaurant));

  const itemTotal = (orderSummary) => {
    if (orderSummary &&  orderSummary.items) {
      return orderSummary.items.reduce((count, curItem) => {
        return count + curItem.quantity * curItem.subTotal;
      }, 0);
    } else {
      return 0;
    }
  };

  const goBack = (e) => {
    history.push("/online/" + id + "/" + tableId);
  };

  useEffect(() => {
    if (id && tableId) {
      //loadOrder({ restaurantId: id, tableId });
      loadOrder(decodeRestaurantId(id));
    }
  }, [props]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={goBack}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="body1">Back</Typography>
        </Toolbar>
      </AppBar>
      <Grid item lg={12} md={12} xl={9} xs={12}>
        {ordersSummary &&
          ordersSummary.map((orderSummary) => (
            <TableContainer
              component={Paper}
              key={orderSummary.id}
              style={{ marginTop: theme.spacing(1) }}
            >
              <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan="2">{`Order Number: ${
                      orderSummary && transformOrderId(orderSummary.id)
                    }`}</TableCell>
                    <TableCell>{orderSummary.orderTime}</TableCell>
                    <TableCell>{orderSummary.realTableId ? orderSummary.realTableId : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell paddingNone>Qty.</TableCell>
                    <TableCell align="left">Item</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderSummary && orderSummary.items &&
                    orderSummary.items
                      .filter((item) => item.permanentDeletion !== true && item.isVoidedDish !== true)
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell paddingNone className={classes.qtyCell}>
                            {row.quantity}
                          </TableCell>
                          <TableCell width="40%" align="left">
                            <Typography inline variant="body2">
                              {row.dish.name +
                                (row.size ? "( " + row.size.name + " )" : "")}
                            </Typography>{" "}
                            <Typography inline variant="body2">
                              {row.dish.altName ? row.dish.altName : ""}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography inline>
                              {restaurant && restaurant.currencySymbol}
                              {row.size
                                ? row.size.price.toFixed(2).toString()
                                : row.dish.price.toFixed(2).toString()}
                            </Typography>
                          </TableCell>

                          <TableCell align="right">
                            <List dense disablePadding>
                              {row.options &&
                                row.options.map((option) => {
                                  return (
                                    <ListItem key={option.id}>
                                      {`${option.name} x ${option.quantity} ${restaurant && restaurant.currencySymbol}${option.price}`}
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </TableCell>
                        </TableRow>
                      ))}

                  <TableRow>
                    <TableCell>Subtotal</TableCell>
                    <TableCell align="right" colSpan={2}>
                      {restaurant && restaurant.currencySymbol}{itemTotal(orderSummary).toFixed(2).toString()}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  {orderSummary &&
                    orderSummary.surcharges &&
                    orderSummary.surcharges.map((surcharge) => (
                      <TableRow key={surcharge.name}>
                        <TableCell>{surcharge.name}</TableCell>
                        <TableCell align="right" colSpan={2}>
                        {restaurant && restaurant.currencySymbol}{surcharge.amount}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}

                  <TableRow>
                    <TableCell align="left" colSpan="3">
                      <Typography variant="caption" color="warning.main">
                        Additional charges may apply
                      </Typography>
                      {!orderSummary.paymentType ||
                      orderSummary.paymentType === "" ? (
                        <Typography>
                          <Box color="error.main">Not Paid</Box>
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan="3" align="right">
                      {restaurant &&
                      restaurant.onlineSettings &&
                      restaurant.onlineSettings.specialTerms &&
                      restaurant.onlineSettings.specialTerms.length > 0 ? (
                        <Paper className={classes.bottomMargin}>
                          <Alert severity="warning">
                            <strong>
                              {restaurant.onlineSettings.specialTerms}
                            </strong>
                          </Alert>
                        </Paper>
                      ) : null}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left" colSpan="3">
                      <div>
                        <QRCode
                          id={transformOrderId(orderSummary.id)}
                          value={orderSummary.id}
                          size={280}
                          bgColor={"#ffffff"}
                          fgColor={"#000000"}
                          level={"H"}
                          includeMargin={true}
                          imageSettings={{
                            src: "/static/images/orderbuddy.jpeg",
                            x: null,
                            y: null,
                            height: 28,
                            width: 28,
                            excavate: true,
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ))}
      </Grid>
    </div>
  );
};

export default OrderDetail;
