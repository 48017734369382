import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import {
  Card,
  TextField,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useSelector, useDispatch } from "react-redux";

import { removeItemWithSlice } from "util/utils";
import API from "util/API";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
    minHeight: 500,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },

  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const PaymentRequiredQRCodeTable = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [uiLoading, setUiLoading] = useState(false);
  const [tableNumber, setTableNumber] = useState("");
  const [tableNumbers, setTableNumbers] = useState([]);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  const save = () => {
    if (!restaurant) {
      showError("Please select a restaurant first.");
      return;
    }

    if (tableNumber.trim() === "") {
      showError("The table number cannot be empty");
      return;
    }

    if (tableNumbers) {
      const index = tableNumbers.findIndex((tableNo) => {
        if (tableNo.toLowerCase() === tableNumber.toLowerCase()) {
          return true;
        }
        return false;
      });

      if (index !== -1) {
        showError("The table number alreay exists.");
        return;
      }
    }

    let tableNos = [];

    if (tableNumbers.length > 0) {
      tableNos = [...tableNumbers, tableNumber];
    } else {
      tableNos = [tableNumber];
    }
    setTableNumbers(tableNos);
    updatePaymentTables(tableNos);
  };

  const updatePaymentTables = (tableNos) => {
    if (!restaurant) {
      showError("Please go back to the restaurant screen and try again.");
      return;
    }

    setUiLoading(true);
    API.put("/updateRestaurantValues/", {
      paymentRequiredTableNumbers: tableNos,
    })
      .then((response) => {
        setUiLoading(false);
        showSuccess("Updated successfully");
        setTableNumber("");
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          props.history.push("/sign-in");
        }
        console.log(JSON.stringify(error));
        setUiLoading(false);
      });
  };

  const handleDelete = (tableNo) => {
    const deleteIndx = tableNumbers.findIndex((item) => item === tableNo);
    if (deleteIndx !== -1) {
      const numbers = removeItemWithSlice(tableNumbers, deleteIndx);
      setTableNumbers(numbers);
      updatePaymentTables(numbers);
      setTableNumber("");
    }
  };

  useEffect(() => {
    if (restaurant && restaurant.paymentRequiredTableNumbers) {
      setTableNumbers(restaurant.paymentRequiredTableNumbers);
    }
  }, [restaurant]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subheader="" title="Payment Required Table Settings" />

      <CardContent className={classes.content}>
        {uiLoading ? <LinearProgress /> : null}
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item md={3} xs={6}>
            <TextField
              fullWidth
              label="Table Number"
              margin="dense"
              name="tableNumber"
              variant="outlined"
              value={tableNumber}
              onChange={(e) => setTableNumber(e.target.value)}
            />
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={uiLoading}
              onClick={save}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Payment Required Tables</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurant &&
                tableNumbers.length > 0 &&
                tableNumbers.map((tableNo) => (
                  <TableRow className={classes.tableRow} hover key={tableNo}>
                    <TableCell>{tableNo}</TableCell>

                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        edge="end"
                        color="secondary"
                        onClick={(e) => handleDelete(tableNo)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

PaymentRequiredQRCodeTable.propTypes = {
  className: PropTypes.string,
  // cookingCategories: PropTypes.array.isRequired
};

export default PaymentRequiredQRCodeTable;
