import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { ChromePicker } from 'react-color';

const ColorPickerDialog = ({ isOpen, onClose, onColorSelected }) => {
  const [currentColor, setCurrentColor] = useState('#ffffff');

  const handleColorChange = (color) => {
    setCurrentColor(color.hex);
  };

  const handleColorSelection = () => {
    onColorSelected(currentColor);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Choose a color</DialogTitle>
      <DialogContent>
        <ChromePicker color={currentColor} onChange={handleColorChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleColorSelection}>Select</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColorPickerDialog;
