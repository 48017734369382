import React, { useState, useEffect, useContext } from "react";

import moment from "moment";
import { makeStyles } from "@material-ui/styles";

import { PromotionsToolbar, PromotionsTable } from "./components";

import CircularProgress from "@material-ui/core/CircularProgress";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { StoreContextProvider } from "./context/store";

import { setTransactions, setTransactionPage } from "store/actions/transaction";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import API from "util/API";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const PromotiontionList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiLoading, setUiLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const selectedTransactionIds = useSelector((state) => state.transaction.ids);

  const orderType = useSelector((state) => state.restaurant.orderType);
  const selectedDate = useSelector(
    (state) => state.restaurant.orderSelectedDate
  );
  const setPage = (page) => dispatch(setTransactionPage(page));

  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const history = useHistory();

  const getPage = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get("page");
  };

  const fetchPromotions = async () => {
    setUiLoading(true);

    try {
      const response = await API.get("/promotions");
      
      if (response && response.data && response.data.promotions) {
        setPromotions(response.data.promotions);
      }

      setUiLoading(false);
    } catch (error) {
      console.error(error);
      setUiLoading(false);
      if (error.response && error.response.status === 403) {
        history.push("/sign-in");
      }

      showErrorMessage("Error in retrieving the data");
    }
  };

  const selectOrders = (ids) => {
    if (ids.length > 0) {
      setSelectedOrderIds(ids);
    }
  };

  const deletePromotion = async (ids, callback) => {
    if (!ids || ids.length !== 1) {
      return;
    }
    
    setUiLoading(true);

    try {
     
      const rewsponse = await API.delete(`/promotions/${ids[0]}`);
      callback();
      fetchPromotions();
    } catch (err) {
      console.err(err);
      callback();
      setUiLoading(false);
    }
  };

  useEffect(() => {
    fetchPromotions();
  }, [props]);

  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </main>
    );
  } else {
    return (
      <div className={classes.root}>
        <StoreContextProvider>
        <PromotionsToolbar deleteProxy={deletePromotion} />
        <div className={classes.content}>
          <PromotionsTable promotions={promotions} />
        </div>
        </StoreContextProvider>
      </div>
    );
  }
};

export default PromotiontionList;
