import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import { SearchInput } from 'components';
import { useSelector, useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { authAdminAndGroupAdminMiddleWare } from 'util/authAdmin'
import { useHistory } from 'react-router-dom';
import { showSuccessSnackbar, showErrorSnackbar } from 'store/actions/snackbarMessage'
import { updateRestaurantkeyword } from 'store/actions/restaurant';
import API from 'util/API';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  rightSpacer: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const RestaurantsToolbar = props => {
  const { className, ...rest } = props;
  const [uiLoading, setUiLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.account.user);
  const selectedRestaurants = useSelector((state) => state.restaurant.selectedRestaurantIds);
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  const updateFilter = (key) => dispatch(updateRestaurantkeyword(key));

  const classes = useStyles();


  const accessRestaurant = () => {
    console.log('selectedRestaurants', selectedRestaurants);
    if (selectedRestaurants.length !== 1) {
      return;
    }
    authAdminAndGroupAdminMiddleWare(history, user);
    // const authToken = localStorage.getItem('AuthToken');
    // API.defaults.headers.common['Authorization'] = authToken;
    setUiLoading(true);
    API
      .put('/userRestaurantId', { restaurantId: selectedRestaurants[0] })
      .then((response) => {
        setUiLoading(false);
        showSuccessMessage("Updated successfully!");
      })
      .catch((error) => {
        console.log(error);
        setUiLoading(false);
        if (error.response && error.response.status === 403) {
          history.push('/sign-in');
        }

        showErrorMessage('Error in updating the data');

      });


  };

  const filterRestaurant = (e) => {
     updateFilter(e.target.value);
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* <IconButton aria-label="delete">
          <DeleteIcon />
        </IconButton> */}
   

        <IconButton aria-label="acccess restaurant" onClick={accessRestaurant}
          disabled={uiLoading} >
          <TransferWithinAStationIcon />
        </IconButton>


       { user && user.role === 'admin' ?
       (
         <>
        <NavLink to={'/restaurantGroup'}>
        <Button
          color="primary"
          variant="contained"
        >
          Add Restaurant Group
      </Button>
      </NavLink>
      <span className={classes.rightSpacer} />
        <NavLink to={'/restaurant'}>
          <Button
            color="primary"
            variant="contained"
          >
            Add Restaurant
        </Button>
        </NavLink>
        </>
        ) : null }
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search restaurant" 
          onChange={filterRestaurant}
        />
      </div>

    </div>
  );
};

RestaurantsToolbar.propTypes = {
  className: PropTypes.string
};

export default RestaurantsToolbar;
