import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Button,
} from "@material-ui/core";
import API from "util/API";
import {
  // showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import {  useDispatch } from "react-redux";


import LinearProgress from "@material-ui/core/LinearProgress";

import FastfoodIcon from "@material-ui/icons/Fastfood";
import indigo from '@material-ui/core/colors/indigo';

import { loadStripe } from '@stripe/stripe-js/pure';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(2)
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.success.dark,
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
  progress: {
    position: "absolute",
  },
}));

const Products = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const [prices, setPrices] = useState([]);
  // const [subscriptionData, setSubscriptionData] = useState(null);
  
  const [uiLoading, setUiLoading] = useState(false);

  const classes = useStyles();
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  
  

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        setUiLoading(true);
        const response = await API.get("/getProducts");
        // console.log(response);

        if (response && response.data && response.data.prices) {
          setPrices(response.data.prices);
        }
        setUiLoading(false);
      } catch (error) {
        setUiLoading(false);
        showError("Cannot get products");
      }
    };
    fetchPrices();
  }, [props]);



  const createSubscriptionSession = async (priceId) => {
    try {
      setUiLoading(true);
      const response = await API.post("/createSubscribeCheckoutSession", {
        priceId,
      });

      if (
        response && response.data && response.data.sessionId
        
      ) {
        const stripe = await loadStripe(
          process.env.REACT_APP_stripe_key,
          
        );
        const result = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId,
        });
        setUiLoading(false);
      }
    } catch (error) {
      console.error(error);
      setUiLoading(false);
      showError("Cannot create a subscription");
    }
  };

  // if (subscriptionData) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: "/subscribe",
  //         state: subscriptionData,
  //       }}
  //     />
  //   );
  // }

  return (
    <div className={classes.root}>
      <h1>Select a plan</h1>
      {uiLoading ? <LinearProgress /> : null}
      <div className="price-list">
        <Grid container alignItems="left" spacing={2}>
          {prices.map((price) => {
            return (
              <Grid item md={3} xs={10}>
                <Card {...rest} className={clsx(classes.root, className)}>
                  <CardContent>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          {price.product.name}
                        </Typography>
                        <Typography variant="h3">
                          {" "}
                          ${price.unit_amount / 100} / month
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Avatar className={classes.avatar}>
                          <FastfoodIcon className={classes.icon} />
                        </Avatar>
                      </Grid>
                    </Grid>
                    <Grid item spacing={1}>
                    <Typography  style={{color: indigo[400]}}>
                    {price.product.description}
                    </Typography>

                    </Grid>
                    <Grid item>
                      { uiLoading ?  <CircularProgress size={20} className={classes.progess} /> :
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => createSubscriptionSession(price.id)}
                      >
                        Select
                      </Button>
                      }
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

Products.propTypes = {
  className: PropTypes.string,
};

export default Products;
