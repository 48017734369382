import React, { useState, useEffect } from "react";


import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";

import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
  CardHeader,
  Box,
  Typography,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import clsx from "clsx";

import { useSelector, useDispatch } from "react-redux";


import API from "util/API";
import { useHistory } from "react-router-dom";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import BookingTables from "./BookingTables";
import { v4 as uuid } from "uuid";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {
    padding: theme.spacing(3),
  },
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
}));

function required(displayName) {
  return function validateRequired(value) {
    // console.log("VALIDATING: ", displayName, value);
    return value !== null || `${displayName} is required.`;
  };
}

const defaultValues = {
  guestNum: null,
  startTime: null,
  endTime: null,
};

const BookingSession = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
  const session = useSelector((state) => state.restaurant.selectedBookingSession);

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues,
  });

  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [tables, setTables] = useState([]);
  const [depositRequired, setDepositRequired] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [disableEndTime, setDisableEndTime] = useState(false);
  const [hideTableNo, setHideTableNo] = useState(false);
  

  const loadTables = async () => {
    try {
      const response = await API.get("/tableInfo");
      const data = await response.data;
      if (data && data.tableInfo && data.tableInfo.areas) {
        //console.log(data);
        const areas = data.tableInfo.areas;
        const allTables = areas.flatMap((floor) => floor.hasTables);
        //console.log(allTables);
        const tableList = allTables.map((table) => {
          return {
            id: uuid(),
            tableNumber: table.tableNumber,
            guestNumber: table.numberOfGuests,
            minNumOfGuests:
              table.minNumOfGuests !== null && table.minNumOfGuests !== undefined
                ? (table.minNumOfGuests === 0 ? table.numberOfGuests : table.minNumOfGuests)
                : table.numberOfGuests,
          };
        });
        //console.log(tableList);
        setTables(tableList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setSpecificTime = (time) => {
    if (time && time.split(':').length === 2) {
      const hourAndMinutes = time.split(':');
      return moment().set('hour', hourAndMinutes[0]).set('minute', hourAndMinutes[1]);
    }
    return null;
  };

  const updateSessionData = () => {
    reset(session);
    //console.log(moment().startOf(session.startTime));
    const oldStartTime = setSpecificTime(session.startTime);
    if (oldStartTime) {
      setStartTime(oldStartTime);
    }
    const oldEndTime = setSpecificTime(session.endTime);
    if (oldEndTime) {
      setEndTime(oldEndTime);
    }
    setTables(session.tables)
    setDepositRequired(session.depositRequired);

    if (session.disableEndTime !== undefined) {
      setDisableEndTime(session.disableEndTime);
    }
    if (session.hideTableNo !== undefined) {
      setHideTableNo(session.hideTableNo); 
    }
  };

  useEffect(() => {
    if (!session) {
      loadTables();
    } else {
      updateSessionData();
    }
  }, [props, session]);

  const saveSession = async (sessionObj) => {

    try {
      setButtonLoading(true);
      let response;
      if (session) {
        response = await API.put("/bookingSession", { ...sessionObj, sessionId: session.sessionId });
      } else {
        response = await API.post("/bookingSession", sessionObj);
      }

      const data = await response.data;
      if (data) {
        //console.log(data);
        setButtonLoading(false);
        showSuccess('Saved successfully!');
        history.push('/bookingSessions');
      }
    } catch (err) {
      console.log(err);
      setButtonLoading(false);
      showError("Something went wrong! Cannot save the session!");
    }
  };

  const onSubmit = (data) => {
    if (startTime > endTime) {
      showError("The end time cannot be greater than the start time.");
      return;
    }

    const sessionObj = {
      ...data,
      tables: tables.map((table) => ({ ...table, guestNumber: Number(table.guestNumber), minNumOfGuests: Number(table.minNumOfGuests) })),
      guestNum: Number(data.guestNum)
    };
    // console.log(sessionObj, Number(data.guestNum));
    saveSession(sessionObj);

  };

  const handleStartTime = (date) => {
    setStartTime(date);
    setValue("startTime", moment(date).format("HH:mm"));
  };

  const handleEndTime = (date) => {
    setEndTime(date);
    setValue("endTime", moment(date).format("HH:mm"));
  };

  useEffect(() => {
    register(
      { name: "startTime", type: "text" },
      { validate: required("Start time") }
    );
    register(
      { name: "endTime", type: "text" },
      { validate: required("End time") }
    );
  }, [props]);

  const toggleDepositChecked = () => {
    setDepositRequired((depositRequired) => !depositRequired);
  };

  const toggleEndTime = () => {
    setDisableEndTime((disableEndTime) => !disableEndTime);
  };

  const toggleTableNo = () => {
    setHideTableNo((hideTableNo) => !hideTableNo);
  };

  return (
    <div className={classes.content}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className={clsx(classes.root, classes)}>
          <CardHeader title="Booking Session" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={3} xs={6}>
                <Typography>Session Enabled</Typography>
                <Controller
                  name="sessionEnabled"
                  defaultValue={true}
                  control={control}

                  render={props =>
                    <Switch
                      onChange={e => props.onChange(e.target.checked)}
                      checked={props.value}
                    />
                  }
                />
              </Grid>

              <Grid item md={3} xs={6}>
                <Typography>Session Name</Typography>

                <Controller
                  as={TextField}
                  name="name"
                  control={control}
                  rules={{ required: true }}
                />
                {errors.name && (
                  <Typography>
                    <Box color="error.main">Name is required</Box>
                  </Typography>
                )}
              </Grid>

              <Grid item md={3} xs={6}>
                <Typography>Session Description</Typography>

                <Controller
                  as={TextField}
                  name="sessionDesription"
                  control={control}
                />
              </Grid>

              <Grid item md={3} xs={6}>
                <Typography>Guest Number limit</Typography>

                <Controller
                  as={TextField}
                  name="guestNum"
                  type="number"
                  control={control}
                  rules={{ required: true, min: 1 }}
                />

                {errors.guestNum && (
                  <Typography>
                    <Box color="error.main">
                      Guest number is required and must be greater than 0
                    </Box>
                  </Typography>
                )}
              </Grid>

              <Grid item md={3} xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Box mr={1}>
                    <Typography>Start Time:</Typography>
                  </Box>
                  <TimePicker
                    name="startTime"
                    value={startTime}
                    onChange={handleStartTime}
                    error={errors.hasOwnProperty("startTime")}
                    helperText={errors.startTime && errors.startTime.message}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item md={3} xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Box mr={1}>
                    <Typography>End Time:</Typography>
                  </Box>

                  <TimePicker
                    name="endTime"
                    value={endTime}
                    onChange={handleEndTime}
                    error={errors.hasOwnProperty("endTime")}
                    helperText={errors.endTime && errors.endTime.message}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item md={3} xs={6}>
                <Typography>Hide End Time</Typography>
                <Controller
                  name="disableEndTime"
                  defaultValue={false}
                  control={control}

                  render={props =>
                    <Switch
                      onChange={e => {props.onChange(e.target.checked); toggleEndTime();}}
                      checked={props.value}
                    />
                  }
                />
              </Grid>

              <Grid item md={3} xs={6}>
                <Typography>Hide Table Number</Typography>
                <Controller
                  name="hideTableNo"
                  defaultValue={false}
                  control={control}

                  render={props =>
                    <Switch
                      onChange={e => {props.onChange(e.target.checked); toggleTableNo();}}
                      checked={props.value}
                    />
                  }
                />
              </Grid>

              <Grid item md={3} xs={6}>


                <Typography>Need deposit</Typography>
                <Controller
                  name="depositRequired"
                  defaultValue={false}
                  control={control}

                  render={props =>
                    <Switch
                      onChange={e => { props.onChange(e.target.checked); toggleDepositChecked(); }}
                      checked={props.value}
                    />
                  }
                />
              </Grid>
              {depositRequired ? (
                <>
                  <Grid item md={3} xs={6}>
                    <Typography>Deposit</Typography>

                    <Controller
                      as={TextField}
                      name="deposit"
                      type="number"
                      control={control}
                      rules={{ required: depositRequired, min: 1 }}
                    />

                    {errors.deposit && (
                      <Typography>
                        <Box color="error.main">
                          Deposit is required and must be greater than 0
                        </Box>
                      </Typography>
                    )}
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12}>
                <BookingTables updateTables={setTables} tablesList={tables} />
              </Grid>
            </Grid>
          </CardContent>

          <CardActions />
        </Card>

        <Button
          color="primary"
          name="button"
          variant="contained"
          type="submit"
          className={classes.submitButton}
          disabled={buttonLoading}
        >
          Save Session
          {buttonLoading && (
            <CircularProgress size={30} className={classes.progess} />
          )}
        </Button>
      </form>
    </div>
  );
};

export default BookingSession;
