import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { updateSelectedBookingSessionIds, setBookingSessionDetail } from 'store/actions/restaurant'
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
   // minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textLink: {
		textDecoration: 'underline',
	},
}));

const BookingSessionsTable = props => {
  const { className,  sessions, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
 
  const history = useHistory();
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const updateBookingSessionIds = (ids) => dispatch(updateSelectedBookingSessionIds(ids));
  const updateBookingSessionDetails = (session) => dispatch(setBookingSessionDetail(session));
  

  const handleSelectAll = event => {
    const { restaurants } = props;

    let selectedRestaurants;

    if (event.target.checked) {
      selectedRestaurants = restaurants.map(restaurant => restaurant.urlIdentifier);
    } else {
      selectedRestaurants = [];
    }

    setSelectedSessions(selectedRestaurants);
  };

  const handleSelectOne = (event, sessionId) => {
    const selectedIndex = selectedSessions.indexOf(sessionId);
    let newSelectedSessions = [];

    if (selectedIndex === -1) {
      newSelectedSessions = newSelectedSessions.concat(selectedSessions, sessionId);
    } else if (selectedIndex === 0) {
      newSelectedSessions = newSelectedSessions.concat(selectedSessions.slice(1));
    } else if (selectedIndex === selectedSessions.length - 1) {
      newSelectedSessions = newSelectedSessions.concat(selectedSessions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedSessions = newSelectedSessions.concat(
        selectedSessions.slice(0, selectedIndex),
        selectedSessions.slice(selectedIndex + 1)
      );
    }

    setSelectedSessions(newSelectedSessions);
    updateBookingSessionIds(newSelectedSessions);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const openSessionDetail = (e, session) => {
    updateBookingSessionDetails(session);
    history.push(`/bookingSession/${session.sessionId}`);
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell >
                    
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Total Guests</TableCell>
                  <TableCell>Start Time - End Time</TableCell>
                 
                  <TableCell>Require Deposit</TableCell>
                  <TableCell>Updated At</TableCell>
              
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions.sort((a, b) => a.startTime.localeCompare(b.startTime)).slice(page * rowsPerPage, rowsPerPage * page + rowsPerPage).map(session => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={session.sessionId}
                    selected={selectedSessions.indexOf(session.sessionId) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedSessions.indexOf(session.sessionId) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, session.sessionId)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell >

                    <Button onClick={e => openSessionDetail(e, session)}>
                        <div className={classes.textLink}>
                          <Typography variant="body1">{session.name}</Typography>
                        </div>
                      </Button>

                     
                    </TableCell>
                    <TableCell>{session.guestNum}</TableCell>
                    <TableCell>{`${session.startTime} - ${session.endTime}`}</TableCell>
                    <TableCell>{session.depositRequired ? 'Yes' : 'No'}</TableCell>
                   
                  
                    <TableCell>
                      {moment(new Date(session.updatedAt._seconds * 1000)).format('DD/MM/YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={sessions.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

BookingSessionsTable.propTypes = {
  className: PropTypes.string,
  sessions: PropTypes.array.isRequired
};

export default BookingSessionsTable;
