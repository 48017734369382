import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import moment from "moment";
import RefundAmount from "../OrderPayments/RefundAmount";

import API from "util/API";

import { checkOrderRefundPermission } from "util/userUtils";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const OrderStripePayments = (props) => {
  const { payment, order } = props;
  const classes = useStyles();
  const history = useHistory();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.account.user);

  const orderCreateDate = (order) => {
    const dateFormat = "DD-MM-YY";
    if ("_seconds" in order.createdAt) {
      return moment(new Date(order.createdAt._seconds * 1000)).format(
        dateFormat
      );
    }
    return moment(order.createdAt).format(dateFormat);
  };

  const paymentTime = (created) => {
    if (!created) {
      return "";
    }
    const dateFormat = "DD/MM/YYYY HH:mm:ss";

    return moment(created * 1000).format(dateFormat);
  };

  const doRefundAmount = (amount) => {
    console.log("refundalling");
    if (!payment || !order) {
      console.log("payment or order is nil");
      return;
    }

    const formRequest = {
      partnerOrderId: payment.partner_order_id,
      refundAmount: amount,
      paymentIntent: order.paymentIntent,
      orderDate: orderCreateDate(order),
    };

    // authMiddleWare(history);
    // const authToken = localStorage.getItem('AuthToken');
    // instance.defaults.headers.common['Authorization'] = authToken;

    setButtonLoading(true);
    API.post("/onlinePaymentRefund", formRequest)
      .then((response) => {
        console.log(response.data);
        setButtonLoading(false);
        history.replace("/orders");
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
        // setOpenErrorSnackbar(true)
      });
  };

  const refundAll = () => {
    if (payment.real_fee) {
      doRefundAmount(payment.real_fee);
    } else if (payment.amount_received) {
      doRefundAmount(payment.amount_received);
    }
  };

  const refundAmount = () => {
    setOpen(true);
  };

  const getCharge = () => {
    //console.log('payment.charges.data[0]', payment.charges.data[0]);
    if (
      payment.charges &&
      payment.charges.data &&
      payment.charges.data.length > 0
    ) {
      return payment.charges.data[0];
    } else {
      return null;
    }
  };

  const getChannel = () => {
    const charge = getCharge();
    if (charge && charge.payment_method_details) {
      const paymentMethodDetials = charge.payment_method_details;
      if (paymentMethodDetials.card && paymentMethodDetials.card.brand) {
        if (
          paymentMethodDetials.card.wallet &&
          paymentMethodDetials.card.wallet.apple_pay
        ) {
          return `${paymentMethodDetials.card.brand} - Apple Pay`;
        } else if (
          paymentMethodDetials.card.wallet &&
          paymentMethodDetials.card.wallet.google_pay
        ) {
          return `${paymentMethodDetials.card.brand} - Google Pay`;
        } else {
          return paymentMethodDetials.card.brand;
        }
      }

      return paymentMethodDetials.type;
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      {buttonLoading ? <LinearProgress /> : null}
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableCell>Payment Details</TableCell>
            <TableCell align="right">
              {user && checkOrderRefundPermission(user) ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={refundAll}
                    disabled={
                      buttonLoading ||
                      !payment ||
                      payment.amount === payment.amount_refunded ||
                      payment.amount > payment.amount_refunded
                    }
                  >
                    Refund All
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={refundAmount}
                    disabled={
                      buttonLoading ||
                      !payment ||
                      payment.amount === payment.amount_refunded
                    }
                  >
                    Refund Amount
                  </Button>
                </>
              ) : null}
              <RefundAmount
                open={open}
                total={payment.real_fee}
                proxy={doRefundAmount}
              />
            </TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Payment Result Code: </TableCell>
              <TableCell align="left">{payment.status}</TableCell>
            </TableRow>

            {getCharge() ? (
              <>
                <TableRow>
                  <TableCell>Payment Method </TableCell>
                  <TableCell align="left">{getChannel()}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Name </TableCell>
                  <TableCell align="left">
                    {getCharge().billing_details &&
                      getCharge().billing_details.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email </TableCell>
                  <TableCell align="left">
                    {getCharge().billing_details &&
                      getCharge().billing_details.email}
                  </TableCell>
                </TableRow>
              </>
            ) : null}
            <TableRow>
              <TableCell>Create Time: </TableCell>
              <TableCell align="left">{paymentTime(payment.created)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Currency: </TableCell>
              <TableCell align="left">{payment.currency}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Amount: </TableCell>
              <TableCell align="left">
                ${(payment.amount / 100).toFixed(2)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Amount received: </TableCell>
              <TableCell align="left">
                {payment.amount_received &&
                  `$${(payment.amount_received / 100).toFixed(2)}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default OrderStripePayments;
