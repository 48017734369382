/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));





const SimpleItem = (props) => {
  const { page } = props;
  const classes = useStyles();
  return (

    <ListItem
      className={classes.item}
      disableGutters
      key={page.title}
    >
      <Button
  
        className={classes.button}
        component={CustomRouterLink}
        to={page.href}
      >
        <div className={classes.icon}>{page.icon}</div>
        {page.title}
      </Button>
    </ListItem>);

};


const CollapseItem = (props) => {
  const { page } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListItem
        className={classes.item}
        disableGutters
        key={page.title}
        onClick={handleClick}
   
      >
        <Button
         
          className={classes.button}

        >
          <div className={classes.icon}>{page.icon}</div>
          {page.title}
        </Button>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.nested}>
          {page.children && page.children.map(page =>
            <SimpleItem key={page.title} page={page} />
          )}
        </List>
      </Collapse>
    </React.Fragment>
  );

};

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map(page =>

        page.children.length == 0 ? <SimpleItem key={page.title} page={page} /> : <CollapseItem key={page.title} page={page} />

      )}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
