import React, { useState } from "react";
import {
  AppBar,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { createPaymentType } from "apis/paymentAPIs";
import { useMutation, useQueryClient } from "react-query";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const PaymentType = () => {
  const classes = useStyles();
  const history = useHistory();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [paymentType, setPaymentType] = useState("");
  const [customPaymentType, setCustomPaymentType] = useState("");
  const [surchargeRate, setSurchargeRate] = useState("");
  const [description, setDescription] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [sort, setSort] = useState(99);


  const dispatch = useDispatch();
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const handlePaymentTypeChange = (event) => {
    const value = event.target.value;
    setPaymentType(value);
    if (value === "OBPayments") {
      setSurchargeRate(0);
      setDescription("OB Payments");
    }
  };

  const handleCustomPaymentTypeChange = (event) => {
    setCustomPaymentType(event.target.value);
  };

  const handleSurchargeRateChange = (event) => {
    setSurchargeRate(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleEnabledChange = (event) => {
    setEnabled(event.target.checked);
  };


  const queryClient = useQueryClient();
  const mutation = useMutation(createPaymentType, {
    onSuccess: () => {
      queryClient.invalidateQueries("paymentTypes");
      showSuccessMessage("Payment type created successfully");
  }});

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = {};

    if (!paymentType && !customPaymentType) {
      errors.paymentType = "Payment type is required";
    }

    if (surchargeRate !== '' && (isNaN(surchargeRate) || parseFloat(surchargeRate) < 0 || parseFloat(surchargeRate) > 100)) {
      errors.surchargeRate = "Surcharge rate must be a number between 0 and 100";
    }

    // check sort is a number
    if (sort && isNaN(sort)) {
      errors.sort = "Sort must be a number";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // TODO: Save payment type data
    const selectedPaymentType = customPaymentType || paymentType;
    const paymentTypeData = {
      paymentType: selectedPaymentType,
      surchargeRate,
      description,
      sort,
      enabled,
    };

    // use react-query mutation to create payment type, to invalidate the cache and 
  

    mutation.mutate(paymentTypeData);
  };
    

  if (mutation.isLoading) {
    return (
      <main className={classes.content}>
       
      <CircularProgress size={150} className={classes.uiProgess} />
       
      </main>
    );
  }

  if (mutation.isError) {
    showErrorMessage(mutation.error.message);
  }

  if(mutation.isSuccess) {
    history.push("/paymentTypes");
  }

  // case CASH
  // case VISAMastercard
  // case EFTPOS
  // case WECHATPAY
  // case ALIPAY
  // case AMEX
  // case Online
  // case Terminal
  // case GROUPBUY
  // case NOTSELECTED = "NOT SELECTED"

  return (
    <>
<AppBar position="static" style={{ backgroundColor: "transparent", boxShadow: "none", margin: "10px" }}>
  <Toolbar style={{ margin: "10px" }}>
    <Button  variant="outlined" onClick={() => history.goBack()}>Back</Button>
    <Button  style={{ marginLeft: "8px" }} variant="outlined" onClick={() => history.push('/myrestaurant')}>My Restaurant</Button>
  </Toolbar>
</AppBar>
      <Card className={classes.card}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={isSmallScreen ? 2 : 4}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} error={!!errors.paymentType} fullWidth>
                <FormLabel>Payment Type</FormLabel>
                <Select value={paymentType} onChange={handlePaymentTypeChange}>
                 <MenuItem value="OBPayments">OB Payments</MenuItem>
                  <MenuItem value="CASH">Cash</MenuItem>
                  <MenuItem value="VISAMastercard">VISA/Master</MenuItem> 
                  <MenuItem value="EFTPOS">EFTPOS</MenuItem>
                  <MenuItem value="AMEX">AMEX</MenuItem>
                  <MenuItem value="WECHATPAY">WeChat Pay</MenuItem>
                  <MenuItem value="ALIPAY">AliPay</MenuItem>
                  <MenuItem value="UnionPay">UnionPay</MenuItem>
                  <MenuItem value="InternationalCard">International Card</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                {paymentType === "Other" && (
                  <TextField
                    value={customPaymentType}
                    margin="dense"
                
                    fullWidth
                    onChange={handleCustomPaymentTypeChange}
                    placeholder="Enter custom payment type"
                  />
                )}
                {errors.paymentType && <FormHelperText>{errors.paymentType}</FormHelperText>}
              </FormControl>
            </Grid>
            {paymentType !== "OBPayments" && (
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl} error={!!errors.surchargeRate} fullWidth>
                  <FormLabel>Surcharge Rate (%)</FormLabel>
                  <TextField value={surchargeRate} onChange={handleSurchargeRateChange} type="number"/>
                  {errors.surchargeRate && <FormHelperText>{errors.surchargeRate}</FormHelperText>}
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl className={classes.formControl} error={!!errors.description} fullWidth>
                <FormLabel>Description</FormLabel>
                <TextField value={description} onChange={handleDescriptionChange} />
                {errors.description && <FormHelperText>{errors.description}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
              className={classes.card}
                control={<Switch checked={enabled} onChange={handleEnabledChange} />}
                label="Enabled"
              />
            </Grid>
            <Grid item xs={6}>
            <FormControl className={classes.formControl} error={!!errors.sort} >
            <FormLabel>Sort Order</FormLabel>
            <TextField
                    value={sort}
                    number
                    margin="dense"
                
                    
                    onChange={e => setSort(e.target.value)}
                    placeholder="Sort (smaller number will be displayed first)"
                  />
                     {errors.sort && <FormHelperText>{errors.sort}</FormHelperText>}
              </FormControl>
            </Grid>
     


            <Grid item xs={12}>
              <Button className={classes.card} type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </>
  );
};

export default PaymentType;