import {
  FETCH_CATEGORIES_PENDING, FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_ERROR, SELECT_CATEGORY, UPDATE_SEARCH, UPDATE_HIDE_DISHES
} from '../actions/menu';

import { filterInactionDishes } from '../../util/orderUtils';


const initialState = {
  categories: [],
  menuLoading: false,
  loadingError: null,
  selectedCategoryIdx: 0,
  search: ''
}

const saveTableId = (tableId, restaurantId) => {
  try {

    localStorage.setItem(`http://orderbuddy.net.au:${restaurantId}:table`, tableId);

  }
  catch (err) {
    console.log(err);
  }
};

const saveRestaurant = (restaurantId, restaurant) => {
  try {

    localStorage.setItem(`http://orderbuddy.net.au:${restaurantId}`, restaurant);

  }
  catch (err) {
    console.log(err);
  }
};





const menuReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CATEGORIES_PENDING:
      return { ...state, menuLoading: true };
    case FETCH_CATEGORIES_SUCCESS:
      saveTableId(payload.tableId, payload.restaurantId);
      if(payload.restaurant) {
        saveRestaurant(payload.restaurantId, payload.restaurant);
      }
      return { ...state, categories: payload.data, menuLoading: false, restaurantId: action.payload.restaurantId, restaurant: action.payload.restaurant, tableId: action.payload.tableId };
    case FETCH_CATEGORIES_ERROR:
      return { ...state, menuLoading: false, loadingError: action.payload };
    case SELECT_CATEGORY:
      return { ...state, selectedCategoryIdx: action.payload };
    case UPDATE_SEARCH:
      if (payload.trim() === '') {
        return { ...state, search: payload, filterItems: [] };
      }
      const allItems = state.categories.reduce((item, curItem) => {
        return item.concat(curItem.items);
      }, []);

      // console.log(state.hidedDishes);

      const activeItems = filterInactionDishes(allItems, state.hidedDishes);

      const lowcaseQuery = payload.toLowerCase();
      const filterItems = activeItems.filter(item => {
        return (item.name.toLowerCase().includes(lowcaseQuery) ||
          (item.altName && item.altName.toLowerCase().includes(lowcaseQuery)) ||
          (item.description && item.description.toLowerCase().includes(lowcaseQuery)));
      });
      // console.log('filterItems', filterItems);
      return { ...state, search: payload, filterItems };
    case UPDATE_HIDE_DISHES:
      return { ...state, hidedDishes: payload };

    default:
      return state;
  }
};

export default menuReducer;
