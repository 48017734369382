import React, {  useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';



import { useSelector } from 'react-redux';


import {
    TransactionDetails,
    OperationHistory
  } from './components';


  const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    progess: {
        position: 'absolute'
    },
    table: {
        minWidth: 700,
    },
}));

const Transaction = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    
    const transaction = useSelector((state) => state.transaction.selectedTransaction);
    const user = useSelector((state) => state.account.user);
    const currencySymbol = (user && user.currencySymbol) ? user.currencySymbol : "$";

    // const itemTotal = () => {
    //     if (order) {
    //          return  order.order.items.reduce((count, curItem) => {
    //             return count + curItem.quantity * curItem.subTotal;
    //         }, 0);
    //     } else {
    //         return 0
    //     }
    // };
       


useEffect(() => {
    // if(!transaction) {
    //     history.push('/orders');
    // }

}, [transaction]);

    return (
        <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
        <Grid
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
        <TransactionDetails transaction={transaction} currencySymbol={currencySymbol}/>
        </Grid>

          <Grid
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
        <OperationHistory transaction={transaction} currencySymbol={currencySymbol}/>
        </Grid>
        </Grid>
        </div>
    );
};

Transaction.propTypes = {
    className: PropTypes.string,
    transaction: PropTypes.object
};

export default Transaction;
