import React, { useState, useEffect } from 'react';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import AddressForm from "components/AddressForm";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    autocomplete: {
        // position: 'sticky',
        top: 0,
        marginBottom: theme.spacing(2),
        zIndex: 999,
    },
}));

const AddressField = ({ addressObject,  onAddressChange }) => {
    const classes = useStyles();
    const [address, setAddress] = useState({
        country: '',
        line1: '',
        line2: '',
        line3: '',
        city: '',
        stateOrProvince: '',
        postalCode: '',
    });

    useEffect(() => {
        if (!addressObject) {
            return;
        }
        setAddress(addressObject);
    }, [addressObject]);
 

    const handlePlaceSelect = async (selected) => {
        // console.log(selected);

        // const value = address.value;



        const { value } = selected;

        const geocoder = new window.google.maps.Geocoder();
        const addressObjectArray = await geocoder.geocode({ 'placeId': value.place_id });
         console.log(JSON.stringify(addressObjectArray));

        if (!addressObjectArray || !addressObjectArray.results || addressObjectArray.results.length == 0) {
            return;
        }

        //   const addressObject = await geocodeByPlaceId(value.place_id);


        // // const addressObject = autocomplete.getPlace();
        const addressComponents = addressObjectArray.results[0].address_components;

        if (addressComponents) {
            const newAddress = {
                country: getComponent(addressComponents, 'country'),
                countryCode: getShortComponent(addressComponents, 'country'),
                line1: `${getComponent(addressComponents, 'street_number')} ${getComponent(addressComponents, 'route')}`,
                line2: `${getComponent(addressComponents, 'sublocality_level_1')}`,
                line3: '',
                city: getComponent(addressComponents, 'locality'),
                stateOrProvince: getComponent(addressComponents, 'administrative_area_level_1'),
                stateOrProvinceCode: getShortComponent(addressComponents, 'administrative_area_level_1'),
                postalCode: getComponent(addressComponents, 'postal_code'),
                location: addressObjectArray.results[0].geometry && addressObjectArray.results[0].geometry.location ? addressObjectArray.results[0].geometry.location : null,
            };



            setAddress(newAddress);

            //   setAddress(newAddress);

            // Pass the new address to the parent component.
            onAddressChange(newAddress);
        }
    }

    const getComponent = (components, type) => {
        let result = '';
        for (let i = 0; i < components.length; i++) {
            if (components[i].types.includes(type)) {
                result = components[i].long_name;
                break;
            }
        }
        return result;
    }

    const getShortComponent = (components, type) => {
        let result = '';
        for (let i = 0; i < components.length; i++) {
            if (components[i].types.includes(type)) {
                result = components[i].short_name;
                break;
            }
        }
        return result;
    }


    return (
        <div>


            <div className={classes.autocomplete}>
                <Typography  gutterBottom>
                    Enter your address:
                </Typography>
                <GooglePlacesAutocomplete

                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    withSessionToken={true}
                    selectProps={{
                       
                        onChange: handlePlaceSelect,
                    }}

                    autocompletionRequest={{
                        fields: ["address_components", "geometry", "icon", "name"],
                        strictBounds: false,
                        componentRestrictions: {
                            country: ['au', 'my', 'nz', 'sg' ],
                        }
                    }}
                />
            </div>
            <AddressForm address={address} setAddress={onAddressChange} style={{ marginTop: 4 }} />
            {/* <input id="autocomplete" /> */}
        </div>
    );
}



export default AddressField;
