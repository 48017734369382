import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import API from "util/API";
import { showErrorSnackbar } from "store/actions/snackbarMessage";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import { transformOrderId } from "util/orderUtils";
import AppIntroduction from "views/Checkout/AppIntroduction";

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  spacer: {
    flexGrow: 1,
  },
  card: {
    margin: theme.spacing(1),
  },
  image: {
    width: "96%",
    height: undefined,

    borderWidth: 0,
    borderRadius: 10,
    margin: theme.spacing(1),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

export default function OrderSuccess(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const id = props.match.params.menuId;
  const tableId = props.match.params.tableId;
  const type = props.match.params.type;
  const [checkAdyenPayment, setCheckAdyenPayment] = useState(false);


  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const reference = () => {
    return transformOrderId(props.match.params.reference);
  };

  const getUrlParam = (key) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
  };

  const viewOrder = () => {
    history.push(`/orderSuccessDetail/${id}/${tableId}/${reference()}`);
  };

  const imageName = () => {
    return type
      ? "/static/images/successUnpaid.png"
      : "/static/images/success_promo.png";
  };

  useEffect(() => {
    if (window && window.parent) {
      const redirectResultType = getUrlParam("type");
      if (redirectResultType === "success") {
        window.parent.postMessage(
          { type: "payment_success", success: true },
          "*"
        );
      }
    }
  }, [props]);


  const handleSuccessResult = () => {
    console.log("Payment completed");
    const orderNumber = props.match.params.reference;


    // emptyOrderCart({ ...order.adyenSession.order.order, id: orderNumber });
  };

  const updateAdyenPaymentDetails = async (redirectResult) => {
    try {
      const result = await API.post(`adyenPaymentDetails`, {
        payload: {
          details: { redirectResult },
        },
      });
      setCheckAdyenPayment(false);
      if (result && result.data && result.data.resultCode === "Authorised") {
        handleSuccessResult();
      } else {
        showError("Payment failed, please try again.");
        history.push("/online/" + id + "/" + tableId);
      }
    } catch (error) {
      showError("Payment failed, please try again.");
      history.push("/online/" + id + "/" + tableId);
    }
  };

  useEffect(() => {
    // check url params inlcude  redirectResult
    const redirectResult = getUrlParam("redirectResult");
    if (redirectResult) {
      setCheckAdyenPayment(true);
      updateAdyenPaymentDetails(redirectResult);
    }
  }, [props]);

  if (checkAdyenPayment === true) {
    return (
      <main className={classes.content}>
        <CircularProgress size={150} className={classes.uiProgess} />
      </main>
    );
  } else {
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton onClick={goBack} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <ArrowBackIosIcon />
            </IconButton> */}
            <Typography>{`Order Number: ${reference()}`}</Typography>
            <span className={classes.spacer} />
            <Button color="inherit" onClick={viewOrder}>
              View Order
            </Button>
          </Toolbar>
        </AppBar>
       
        <Grid alignItems="center">
      <Typography style={{ fontSize: '16px', color: '#333', lineHeight: '1.5', padding: '20px' }}>
  Thank you for your order! It has been successfully received and is being processed.
</Typography>
          <AppIntroduction />
          {/* <img className={classes.image} src={`${imageName()}`} alt="success" /> */}
        </Grid>
      </div>
    );
  }
}
