import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import {
  Card,
  TextField,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { useSelector, useDispatch } from "react-redux";

import { removeItemWithSlice } from "util/utils";
import API from "util/API";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
    minHeight: 500,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },

  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const PosPartnerSettings = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [uiLoading, setUiLoading] = useState(false);
  const [isPrepaid, setIsPrepaid] = useState(false);
  const [partnerName, setPartnerName] = useState("");
  const [posPartners, setPOSPartners] = useState([]);
  const [sort, setSort] = useState(0);
  // const [commissionPercentage, setCommissionPercentage] = useState(0);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );



  const save = () => {
    if (!restaurant) {
      showError("Please select a restaurant first.");
      return;
    }

    if (partnerName.trim() === "") {
      showError("The partner name cannot be empty");
      return;
    }

    if (posPartners) {
      const index = posPartners.findIndex((item) => {
        if (item.name.toLowerCase() === partnerName.toLowerCase()) {
          return true;
        }
        return false;
      });

      if (index !== -1) {
        showError("The partner name alreay exists.");
        return;
      }
    }

    let items = [];

    const item = { name: partnerName, isPrepaid, sort: Number(sort)};

    if (posPartners.length > 0) {
      items = [...posPartners, item];
    } else {
      items = [item];
    }
    setPOSPartners(items);
    updateRestaurantSettings(items);
  };

  const updateRestaurantSettings = (partners) => {
    if (!restaurant) {
      showError("Please go back to the restaurant screen and try again.");
      return;
    }

    setUiLoading(true);
    API.put("/updateRestaurantValues/", {
      posSettings: { ...restaurant.posSettings, posPartners: partners.sort((a, b) => a.sort - b.sort) },
    })
      .then((response) => {
        setUiLoading(false);
        showSuccess("Updated successfully");
        setPartnerName("");
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          props.history.push("/sign-in");
        }
        console.log(JSON.stringify(error));
        setUiLoading(false);
      });
  };

  const handleDelete = (partner) => {
    const deleteIndx = posPartners.findIndex(
      (item) => item.name === partner.name
    );
    if (deleteIndx !== -1) {
      const numbers = removeItemWithSlice(posPartners, deleteIndx);
      setPOSPartners(numbers);
      updateRestaurantSettings(numbers);
      setPartnerName("");
    }
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "isPrepaid") {
      setIsPrepaid(value);
    }
  };

  useEffect(() => {
    if (
      restaurant &&
      restaurant.posSettings &&
      restaurant.posSettings.posPartners
    ) {
      setPOSPartners(restaurant.posSettings.posPartners);
      setSort(restaurant.posSettings.posPartners.length);
    }
  }, [restaurant]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subheader="" title="POS payment partners settings" />

      <CardContent className={classes.content}>
        {uiLoading ? <LinearProgress /> : null}
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item md={5} xs={8}>
            <TextField
              fullWidth
              label="Payment partner Name"
              margin="dense"
              name="partnerName"
              variant="outlined"
              value={partnerName}
              onChange={(e) => setPartnerName(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={isPrepaid}
                  onChange={handleSwitchChange}
                  name="isPrepaid"
                  color="primary"
                />
              }
              label="Is Prepiad"
            />
          </Grid>
{/* 
          <Grid item>
            <TextField
              label="Commission Percentage"
              margin="dense"
              name="commissionPercentage"
              variant="outlined"
              type="number"
              value={commissionPercentage}
              onChange={(e) => setCommissionPercentage(Number(e.target.value))}
            />
            
          </Grid> */}

          <Grid item>
            <TextField
              label="Sort (ascending)"
              margin="dense"
              name="sort"
              variant="outlined"
              type="number"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
            />

          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={uiLoading}
              onClick={save}
            >
              Save
            </Button>
          </Grid>
          {/* <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              disabled={uiLoading}
              onClick={clear}
            >
              clear
            </Button>
          </Grid> */}
        </Grid>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="4">POS Payment Partners</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurant &&
                posPartners.length > 0 &&
                posPartners.sort((a, b) => a.sort - b.sort).map((item) => (
                  <TableRow className={classes.tableRow} hover key={item.name}>
                    <TableCell><Typography>{item.name}</Typography></TableCell>
                    <TableCell>{item.isPrepaid ? "prepaid" : "postpaid"}</TableCell>
                  
                    <TableCell>{item.sort}</TableCell>

                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        edge="end"
                        color="secondary"
                        onClick={(e) => handleDelete(item)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

PosPartnerSettings.propTypes = {
  className: PropTypes.string,
  // cookingCategories: PropTypes.array.isRequired
};

export default PosPartnerSettings;
