import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  Box,
} from "@material-ui/core";

import { useQuery, useMutation } from "react-query";
import {
  getPrinters,
  getTableInfo,
} from "apis/printAPIs";
import ZoneManagement from "./ZoneManagement";




const savePrinterTags = async ({ printerId, tags }) => {
  const response = await fetch(`/api/printers/${printerId}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ tags }),
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

const POSPrinterSettings = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedTables, setSelectedTables] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [floors, setFloors] = useState([]);
  

  const { data: printers, isLoading } = useQuery("printers", getPrinters);
  const { data: tableInfo } = useQuery("tables", getTableInfo);

  const mutation = useMutation(savePrinterTags);

  const handleSaveTags = (printerId) => {
    const tags = [
      ...selectedTags,
      ...`floor-${selectedFloor}`,
      ...selectedTables.map((table) => `${table}`),
    ];
    mutation.mutate({ printerId, tags });
  };

  useEffect(() => {
    if (tableInfo) {
      const areas = tableInfo.areas || [];
      const areaList = areas.map((area) => {
        const tables = area.hasTables.map((table) => table.tableNumber);
        return { name: area.areaName, tables };
      });
      setFloors(areaList);
    }
  }, [tableInfo]);

  const relatedTables =
    selectedArea && selectedArea !== ""
      ? floors.find((area) => area.name === selectedArea)?.tables
      : [];

  if (isLoading) return <LinearProgress />;

  return (
    <div>
      <Box padding={3}>
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          Printer Settings
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Device Type</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Tags</TableCell>
              {/* <TableCell>Manage Tags</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {printers?.map((printer) => (
              <TableRow key={printer.printerName}>
                <TableCell>{printer.printerName}</TableCell>
                <TableCell>{printer.deviceName}</TableCell>
                <TableCell>{printer.ipAddress}</TableCell>
                <TableCell>{printer.brand}</TableCell>
                <TableCell>{printer.tags && printer.tags.join(", ")}</TableCell>
                {/* <TableCell>
                  <Select
                    value={selectedFloor}
                    onChange={(e) => {
                      setSelectedArea(e.target.value);
                      setSelectedFloor(e.target.value);
                      setSelectedTables([]); // Clear table selection when changing floor
                    }}
                  >
                    {floors?.map((floor) => (
                      <MenuItem key={floor.name} value={floor.name}>
                        Floor {floor.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    multiple
                    value={selectedTables}
                    onChange={(e) => setSelectedTables(e.target.value)}
                  >
                    {relatedTables.map((tableNumber) => (
                      <MenuItem key={tableNumber} value={tableNumber}>
                        Table {tableNumber}
                      </MenuItem>
                    ))}
                  </Select>
                  <Autocomplete
                    multiple
                    freeSolo
                    value={selectedTags}
                    onChange={(_, newValue) => setSelectedTags(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Add Tags"
                      />
                    )}
                  />
                  <Button onClick={() => handleSaveTags(printer.id)}>
                    Save Tags
                  </Button>
                </TableCell>*/}
              </TableRow> 
            ))}
          </TableBody>
        </Table>
        <ZoneManagement floors={floors} printers={printers} />
      </Box>
    </div>
  );
};

export default POSPrinterSettings;
