import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { TableRow, TableSortLabel } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import Button from "@material-ui/core/Button";

import { CSVLink } from "react-csv";
import { useSelector } from 'react-redux';

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {},
  top: {
    marginTop: theme.spacing(1),
  },
  categoryRow: {
    backgroundColor: "#f5f5f5", // Change the background color as desired
  },
  tabContent: {
    marginTop: theme.spacing(2),
  },
}));

const DishReport = (props) => {
  const { reportData } = props;
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [showDishOptions, setShowDishOptions] = useState(true);
  const [groupedData, setGroupedData] = useState([]);
 const [allDishes, setAllDishes] = useState([]);
 const user = useSelector((state) => state.account.user);
 const currencySymbol = (user && user.currencySymbol) ? user.currencySymbol : "$";

  const handleToggleDishOptions = () => {
    setShowDishOptions(!showDishOptions);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884d8",
    "#8dd1e1",
    "#82ca9d",
    "#a4de6c",
    "#d0ed57",
    "#ffc658",
  ];
  //   const colors = ['#8884d8', '#82ca9d', '#ffc658', '#FF8042', '#FFBB28', '#0088FE', '#FFD700', '#FF4500', '#4B0082', '#2E8B57'];
  function renderPieChart(data) {
    const totalSales = data.reduce(
      (sum, category) =>
        sum +
        category.dishes.reduce(
          (categorySum, item) => categorySum + item.total,
          0
        ),
      0
    );

    const categoryPercentageData = data.map((category) => {
      const categoryTotal = category.dishes.reduce(
        (sum, item) => sum + item.total,
        0
      );
      const categoryPercentage = (categoryTotal / totalSales) * 100;
      return {
        name: category.category,
        value: Number(categoryTotal.toFixed(2)),
        percentage: Number(categoryPercentage.toFixed(2)),
      };
    });

    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart layout="vertical" data={categoryPercentageData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" width={150} />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" label={{ position: "right" }}>
            {categoryPercentageData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }

  function calculateDishStatistics(groupedDishes) {
    const allDishesDict = {};
    let total = 0;

    const result = groupedDishes.map(
      ({ category, dishes, totalSales, totalCounts }) => {
        total += totalSales;
        const updatedDishes = dishes.filter((dish) => dish.isOption !== true || (dish.isOption === true && showDishOptions === true)).map((dish) => {
          if (allDishesDict[dish.name]) {
            allDishesDict[dish.name].total += dish.total;
            allDishesDict[dish.name].count += dish.count;
          } else {
            allDishesDict[dish.name] = {
              total: dish.total,
              count: dish.count,
              isOption: dish.isOption,
              altName: dish.altName || "",
            };
          }
          return {
            ...dish,
            percentage: ((dish.total / totalSales) * 100).toFixed(2),
          };
        });

        return {
          category,
          categoryTotal: totalSales.toFixed(2),
          categoryCount: totalCounts,
          dishes: updatedDishes,
        };
      }
    );

    const dishes = Object.keys(allDishesDict).map((key) => {
      return {
        name: key,
        altName: allDishesDict[key].altName,
        total: allDishesDict[key].total,
        count: allDishesDict[key].count,
        isOption: allDishesDict[key].isOption,
        percentage: ((allDishesDict[key].total / total) * 100).toFixed(2),
      };
    });
    // sort dishes by count
    dishes.sort((a, b) => b.count - a.count);

    return { groupedData: result, allDishes: dishes };
  }

  useEffect(() => {
    const { groupedData, allDishes } = calculateDishStatistics(reportData);
    setGroupedData(groupedData);
    setAllDishes(allDishes);
  }, [reportData, showDishOptions]);

  // const { groupedData, allDishes } = calculateDishStatistics(reportData);

  const csvData = () => {
    let data = [];
    data.push(["Category", "Name", "Product Id", "Quantity", "Total", "Percent"]);

    groupedData.forEach((group) => {
      group.dishes.forEach((item) => {
        data.push([
          group.category,
          item.name,
          item.productId,
          item.count,
          item.total.toFixed(2),
          item.percentage,
        ]);
      });
    });
    //console.log('data', data);
    return data;
  };

  const [sortConfig, setSortConfig] = useState({
    key: "total",
    direction: "desc",
  });

  const [dishSortConfig, setDishSortConfig] = useState({
    key: "count",
    direction: "desc",
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleDishSort = (key) => {
    let direction = "asc";
    if (dishSortConfig.key === key && dishSortConfig.direction === "asc") {
      direction = "desc";
    }
    setDishSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    const sortableItems = groupedData.map((group) => {
      return {
        ...group,
        dishes: [...group.dishes].sort((a, b) => {
          if (sortConfig.direction === "asc") {
            return a[sortConfig.key] - b[sortConfig.key];
          } else {
            return b[sortConfig.key] - a[sortConfig.key];
          }
        }),
      };
    });

    return sortableItems;
  }, [groupedData, sortConfig]);

  const sortedDishData = React.useMemo(() => {
    const dishes = [...allDishes].sort((a, b) => {
      if (dishSortConfig.direction === "asc") {
        return a[dishSortConfig.key] - b[dishSortConfig.key];
      } else {
        return b[dishSortConfig.key] - a[dishSortConfig.key];
      }
    });

    return dishes;
  }, [allDishes, dishSortConfig]);

  return (
    <div className={classes.root}>
      <h3
        style={{
          fontSize: "1.5rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        Category Sales Breakdown
      </h3>
      <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        {renderPieChart(reportData)}
      </div>
      <h3
        style={{
          fontSize: "1.5rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        Dish Sales Data
      </h3>

      <div style={{ paddingBottom: "1rem" }}>
        {reportData && reportData.length > 0 ? (
          <CSVLink filename={"dish-report.csv"} data={csvData()}>
            <Button variant="outlined" color="primary">
              Download CSV
            </Button>
          </CSVLink>
        ) : null}
      </div>
      <div>
        <Button onClick={handleToggleDishOptions} style={{
    backgroundColor: "#4CAF50",
    border: "none",
    color: "white",
    padding: "10px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    borderRadius: "5px",
    cursor: "pointer",
  }}>
          {showDishOptions ? "Hide" : "Show"} Dish Options
        </Button>
      </div>
      
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Dish Sales Data" />
        <Tab label="Category Sales Breakdown" />
      </Tabs>

      {tabValue === 0 && (
        <Box className={classes.tabContent}>
          <h3
            style={{
              fontSize: "1.5rem",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            Dish Sales Data
          </h3>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Name and altName</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={dishSortConfig.key === "total"}
                      direction={dishSortConfig.direction}
                      onClick={() => handleDishSort("total")}
                    >
                      Total{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel
                      active={dishSortConfig.key === "count"}
                      direction={dishSortConfig.direction}
                      onClick={() => handleDishSort("count")}
                    >
                      Total Sold
                    </TableSortLabel>
                  </TableCell>
                  {/* <TableCell>Product ID</TableCell> */}
                  <TableCell>
                    {" "}
                    <TableSortLabel
                      active={dishSortConfig.key === "percentage"}
                      direction={dishSortConfig.direction}
                      onClick={() => handleDishSort("percentage")}
                    >
                      Percentage of Sales{" "}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedDishData.map((item, itemIndex) => (
                  <TableRow key={itemIndex}>
                    <TableCell>{itemIndex + 1}</TableCell>
                    <TableCell>
                      <Typography variant="body1">{item.name}</Typography>
                      {item.altName && (
                        <Typography variant="body2" color="textSecondary">
                          {item.altName}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>{currencySymbol}{item.total.toFixed(2)}</TableCell>
                    <TableCell>{item.count}</TableCell>
                    {/* <TableCell>{item.productId || '-'}</TableCell> */}
                    <TableCell>{item.percentage}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {tabValue === 1 && (
        <Box className={classes.tabContent}>
          <h3
            style={{
              fontSize: "1.5rem",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            Category Sales Breakdown
          </h3>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name and Alternative Name</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "total"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("total")}
                    >
                      Total{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel
                      active={sortConfig.key === "count"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("count")}
                    >
                      Total Sold
                    </TableSortLabel>
                  </TableCell>
                  {/* <TableCell>Product ID</TableCell> */}
                  <TableCell>
                    {" "}
                    <TableSortLabel
                      active={sortConfig.key === "percentage"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("percentage")}
                    >
                      Percentage of Category Sales{" "}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((group, index) => (
                  <React.Fragment key={index}>
                    <TableRow className={classes.categoryRow}>
                      <TableCell style={{ fontWeight: "bold" }} colSpan={6}>
                        {group.category} (Total: ${group.categoryTotal}, Total
                        Sold: {group.categoryCount})
                      </TableCell>
                    </TableRow>
                    {group.dishes.map((item, itemIndex) => (
                      <TableRow key={itemIndex}>
                        <TableCell>
                          <Typography variant="body1">{item.name}</Typography>
                          {item.altName && (
                            <Typography variant="body2" color="textSecondary">
                              {item.altName}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>${item.total.toFixed(2)}</TableCell>
                        <TableCell>{item.count}</TableCell>
                        {/* <TableCell>{item.productId || '-'}</TableCell> */}
                        <TableCell>{item.percentage}%</TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
};

export default DishReport;
