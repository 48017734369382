import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Switch,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import { useHistory } from "react-router-dom";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";

import moment from "moment";


import { useSelector, useDispatch } from 'react-redux';
import API from "util/API";

import { showSuccessSnackbar, showErrorSnackbar } from 'store/actions/snackbarMessage'
import { nanoid } from 'nanoid'
import { getSurchargeSettingsList } from "../SurchargeList/surchargeUtils";



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  submitButton: {
    width: 200,
  }
}));


const HolidaySurchargeSettings = (props) => {
  const { surchargeSetting } = props;
  const CHARACTER_LIMIT = 50;
  const classes = useStyles();

  const initialState = {
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: true,
    Sunday: true,
    isEnabled: false,
    isPercentage: true,
    isTax: false,
    isExclusive: false,
    isTaxBasedOnSubtotal: false,
    holidayDate: Date(),
    amount: 0,
    name: "",
    surchargeDescription: "",
    id: nanoid(),
  };

  const history = useHistory();
  const dispatch = useDispatch();


  const [publicHolidayDays, setPublicHolidayDays] = useState([]);
  const [uiLoading, setUiLoading] = useState(false);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));

  const [values, setValues] = useState(initialState);
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );
  const user = useSelector((state) => state.account.user);
  const surchargeId = props.match.params.surchargeId;

  

  const checkWeekdayIsChecked = (surcharge, weekday) => {
    return surcharge.weekdays && surcharge.weekdays.includes(weekday);
  };

  const loadValues = (surcharge) => {
    const prevousValues = {
      Monday: checkWeekdayIsChecked(surcharge, 'Monday'),
      Tuesday: checkWeekdayIsChecked(surcharge, 'Tuesday'),
      Wednesday: checkWeekdayIsChecked(surcharge, 'Wednesday'),
      Thursday: checkWeekdayIsChecked(surcharge, 'Thursday'),
      Friday: checkWeekdayIsChecked(surcharge, 'Friday'),
      Saturday: checkWeekdayIsChecked(surcharge, 'Saturday'),
      Sunday: checkWeekdayIsChecked(surcharge, 'Sunday'),
      isEnabled: surcharge.isEnabled,
      isTax: surcharge.isTax,
      isExclusive: surcharge.isExclusive,
      isTaxBasedOnSubtotal: surcharge.isTaxBasedOnSubtotal,
      isPercentage: surcharge.isPercentage,
      holidayDate: Date(),
      amount: surcharge.amount,
      name: surcharge.name,
      surchargeDescription: surcharge.surchargeDescription ? surcharge.surchargeDescription : "",
      id: surcharge.id,
    };
    setValues(prevousValues);
  };


  useEffect(() => {
    
    if (!surchargeId) {
      return;
    }
    const existingSurchargeSettings = getSurchargeSettingsList(restaurant);
    const surchargeSetting = existingSurchargeSettings.find(
      (s) => s.id === surchargeId
    );

    if (surchargeSetting) {
      loadValues(surchargeSetting);
      setPublicHolidayDays(surchargeSetting.publicHolidayDays);
    }
  }, [surchargeId, restaurant]);



  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value, type } = e.target;
    // console.log("name", name, value, type);
    if (type === "checkbox") {
      setValues({ ...values, [name]: !values[name] });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const updateSurchargeSettings = async () => {
    if (!restaurant) {
      showError('Please go back to the restaurant screen and try again.');
      return;
    }


    setUiLoading(true);
    try {
      await API.put("/updateRestaurantValues/", {
        surchargeSettings: preparePayload(),
      })

      setUiLoading(false);
      showSuccess('Updated successfully');
      history.push("/myRestaurant");
      // if (user.role === "restaurantAdmin") {
      //   history.push("/myRestaurant");
      // } else {
      //   history.push("/restaurants");
      // }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        props.history.push("/sign-in");
      }
      // console.log(JSON.stringify(error));
      setUiLoading(false);

    }
  };

  const handleDateChange = (date) => {
    // console.log("date", date);

    setValues({ ...values, holidayDate: date });
  };

  const addNewHoliday = () => {
    const newItem = moment(values.holidayDate).format("DD/MM/YYYY");
    let updateItems = [...publicHolidayDays];
    if (updateItems.findIndex((item) => item === newItem) > -1) {
      return;
    }

    updateItems.push(newItem);

    setPublicHolidayDays(updateItems);

    //console.log("publicHolidayDays", publicHolidayDays);
  };


  const removeItemWithSlice = (items, index) => {
    let newSelectedOptions = [];

    if (index === 0) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(1));
    } else if (index === items.length - 1) {
      newSelectedOptions = newSelectedOptions.concat(items.slice(0, -1));
    } else if (index > 0) {
      newSelectedOptions = newSelectedOptions.concat(
        items.slice(0, index),
        items.slice(index + 1)
      );
    }
    return newSelectedOptions;
  };
  const preparePayload = () => {

    let weekdays = [];
    if (values.Monday) weekdays.push("Monday");
    if (values.Tuesday) weekdays.push("Tuesday");
    if (values.Wednesday) weekdays.push("Wednesday");
    if (values.Thursday) weekdays.push("Thursday");
    if (values.Friday) weekdays.push("Friday");
    if (values.Saturday) weekdays.push("Saturday");
    if (values.Sunday) weekdays.push("Sunday");

    const payload = {
      publicHolidayDays,
      weekdays,
      isEnabled: values.isEnabled,
      isPercentage: values.isPercentage,
      isTax: values.isTax,
      isExclusive: values.isExclusive,
      isTaxBasedOnSubtotal: values.isTaxBasedOnSubtotal,
      amount: Number(values.amount),
      name: values.name,
      description: values.description,
      id: values.id,
    };
    const existingSurchargeSettings = getSurchargeSettingsList(restaurant);
    const idx = existingSurchargeSettings.findIndex(
      (item) => item.id === payload.id
    );
    if (idx > -1) {
      existingSurchargeSettings[idx] = payload;
    } else {
      existingSurchargeSettings.push(payload);
    }
    return existingSurchargeSettings;
  };

  
  const deleteDateItem = (date) => {
    const idx = publicHolidayDays.findIndex((item) => item === date);
    // console.log("idx", idx, publicHolidayDays, date);
    if (idx === -1) {
      return;
    }
    setPublicHolidayDays(removeItemWithSlice(publicHolidayDays, idx));
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    if (name === 'isEnabled') {
      setValues({ ...values, isEnabled: value });
      return;
    }

    if (name === 'isPercentage') {
      setValues({ ...values, isPercentage: value });
      return;
    }

    if (name === 'isTax') {
      setValues({ ...values, isTax: value });
      return;
    }

    if (name === 'isExclusive') {
      setValues({ ...values, isExclusive: value });
      return;
    }

    if (name === 'isTaxBasedOnSubtotal') {
      setValues({ ...values, isTaxBasedOnSubtotal: value });
      return;
    }
  };

  return (
    <div>
      {uiLoading ? <LinearProgress /> : null}
      <Card className={clsx(classes.root, classes)}>
        <CardHeader
          title="Surcharge Settings"
          subheader="Update surcharge"
        />
        <CardContent>
          <Grid container column spacing={2}>

            <Grid md={6} xs={12}>
              <TextField
                fullWidth
                label="Surcharge Name"
                margin="dense"
                name="name"
                variant="outlined"
                value={values.name}

                onChange={e => setValues({ ...values, name: e.target.value })}
              />
            </Grid>


            <Grid item md={6} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isEnabled}
                    onChange={handleSwitchChange}
                    name="isEnabled"
                    color="primary"
                  />
                }
                label="Is Enabled"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>

            <Grid item>
              <Typography>Effect on</Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.Monday}
                      onChange={handleInputChange}
                      name="Monday"
                      color="primary"
                    />
                  }
                  label="Monday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.Tuesday}
                      onChange={handleInputChange}
                      name="Tuesday"
                      color="primary"
                    />
                  }
                  label="Tuesday"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.Wednesday}
                      onChange={handleInputChange}
                      name="Wednesday"
                      color="primary"
                    />
                  }
                  label="Wednesday"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.Thursday}
                      onChange={handleInputChange}
                      name="Thursday"
                      color="primary"
                    />
                  }
                  label="Thursday"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.Friday}
                      onChange={handleInputChange}
                      name="Friday"
                      color="primary"
                    />
                  }
                  label="Friday"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.Saturday}
                      onChange={handleInputChange}
                      name="Saturday"
                      color="primary"
                    />
                  }
                  label="Saturday"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.Sunday}
                      onChange={handleInputChange}
                      name="Sunday"
                      color="primary"
                    />
                  }
                  label="Sunday"
                />
              </FormGroup>
              <Divider />
            </Grid>
            <Grid item container md={12} xs={12}>
            <Grid item md={2} xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isExclusive}
                      onChange={handleSwitchChange}
                      name="isExclusive"
                      color="primary"
                    />
                  }
                  label="For exclusive use only; not to be combined with other charges."
                />
              </Grid>
              <Grid item md={2} xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isTax}
                      onChange={handleSwitchChange}
                      name="isTax"
                      color="primary"
                    />
                  }
                  label="Is Tax"
                />
              </Grid>
              <Grid item md={2} xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isTaxBasedOnSubtotal}
                      onChange={handleSwitchChange}
                      name="isTaxBasedOnSubtotal"
                      color="primary"
                    />
                  }
                  label="Is Tax based on subtotal (before other charges)"
                />
              </Grid>
              </Grid>

            <Grid item container md={12} xs={12}>
          

          
              <Grid item md={2} xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isPercentage}
                      onChange={handleSwitchChange}
                      name="isPercentage"
                      color="primary"
                    />
                  }
                  label="Is Percentage"
                />
              </Grid>

              <Grid item md={3} xs={4}>
                <TextField
                  label={values.isPercentage ? 'Percentage (%)' : 'Amount'}
                  type="number"
                  margin="dense"
                  name="amount"
                  variant="outlined"
                  value={values.amount}
                  onChange={e => setValues({ ...values, amount: Number(e.target.value) })}

                />

              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>
            {publicHolidayDays.length > 0 ?
              <Grid item md={6} xs={6}>
                <Typography>Public holidays</Typography>
                <List dense={false}>
                  {publicHolidayDays.map(item => {
                    return (

                      <ListItem key={item}>
                        <ListItemAvatar>
                          <EventAvailableIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item}
                        />
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteDateItem(item)}>
                          <DeleteIcon />
                        </IconButton>
                        <ListItemSecondaryAction>

                        </ListItemSecondaryAction>
                      </ListItem>)
                  })}
                </List>

              </Grid> : null}

            <Grid item container md={12} row xs={12} alignItems="center">
              <Grid item md={2} xs={6}>
                <FormGroup row>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      name="holiday"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Choose a holiday"
                      value={values.holidayDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "choose date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormGroup>
              </Grid>

              <Grid item md={2} xs={6}>
                <FormGroup row>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={addNewHoliday}
                  >
                    Add a holiday
                  </Button>
                </FormGroup>
              </Grid>
            </Grid>

            <Grid md={6} xs={12}>
              <TextField

                id="descriptions"
                label="Descriptions"
                multiline
                rows={4}
                fullWidth
                inputProps={{
                  maxlength: CHARACTER_LIMIT,
                }}
                value={values.surchargeDescription}
                variant="outlined"
                helperText={`${values.surchargeDescription.length}/${CHARACTER_LIMIT}`}
                placeholder="Description"
                onChange={(e) => setValues({ ...values, surchargeDescription: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <CardActions>
            <Button
              className={classes.submitButton}
              variant="contained"
              disabled={uiLoading || !values.name || !values.amount }
              onClick={updateSurchargeSettings}
              color="primary"
            >
              Save
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};

export default HolidaySurchargeSettings;
