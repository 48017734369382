import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import { Alert } from "@material-ui/lab";

import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";

import { useSelector, useDispatch } from "react-redux";
import { addDishToCart } from "store/actions/order";
import DishGroup from "./components/DishGroup";
import {
  clearDish,
  chooseSize,
  updateQuantity,
  updateSpecial,
  updateIsFortakeaway,
} from "store/actions/dish";
import CallButton from "../Online/components/CallButton";

import Firebase from "firebase";
import { filterOpenHours, decodeRestaurantId } from "util/utils";
import { showErrorSnackbar } from "store/actions/snackbarMessage";
import { updateSearch } from "store/actions/menu";

import { isTakeawayDeliverPickup } from "util/restaurantUtils";
import { loadTableId, isTakeawayEnabled } from "util/orderUtils";

import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%",
  },
  appbar: {
    position: "-webkit-sticky",
    position: "sticky",
  },
  root: {
    padding: 0,
  },
  nested: {
    marginLeft: theme.spacing(2),
    padding: 0,
  },
  checkbox: {
    fontSize: 12,
  },
  toolbarButton: {
    marginLeft: "auto",
  },

  name: {
    marginBottom: theme.spacing(3),
  },

  bottomButtons : {
    marginTop: theme.spacing(2),
  },

  images: {
    width: "100%",
    height: "200",

    resizeMode: "contain",
  },
  dishImage: {
    width: 200,
    height: 200,
    borderWidth: 0,
    borderRadius: 10,
  },

  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  offset: theme.mixins.toolbar,
  bottomMargin: {
    marginTop: theme.spacing(1),
  },
}));

const scrollToRef = (ref) => {
  if (ref) {
    ref.current.scrollIntoView({ block: "start" });
  }
};

const DishDetail = (props) => {
  const classes = useStyles();
  const CHARACTER_LIMIT = 100;
  const dispatch = useDispatch();
  const history = useHistory();

  const [restaurantMessage, setRestaurantMessage] = useState("");
  const [qrCodeMessage, setQrCodeMessage] = useState("");
  const [isForTakeaway, setIsForTakeaway] = useState(false);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [realTableId, setRealTableId] = useState(null);

  const dish = useSelector((state) => state.dish);
  const removeDish = () => dispatch(clearDish());
  const selectSize = (size) => dispatch(chooseSize(size));
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const restaurantId = useSelector((state) => state.menu.restaurantId);

  const restaurantData = useSelector((state) => state.menu.restaurant);
  const searchItem = (query) => dispatch(updateSearch(query));
  const menuTableId = useSelector((state) => state.menu.tableId);

  // console.log('dish.isTakeawayDisabled', dish);
  //  console.log('restaurantData', restaurantData);
  const handleClose = () => {
    // props.closeCallback();
    // setOpen(false);
    searchItem("");
    history.goBack();
    // if (restaurant && restaurant.tableId) {
    //   history.push(`/online/${restaurantId}/${restaurant.tableId}`);
    // } else {
    //   history.goBack();
    // }

    // removeDish();
  };

  const addDish = (dishObj) => dispatch(addDishToCart(dishObj));
  const updateDishQty = (qty) => dispatch(updateQuantity(qty));
  const updateTakeaway = (flag) => dispatch(updateIsFortakeaway(flag));
  const updateSpecialInstructions = (special) => {
    setSpecialInstructions(special);
    dispatch(updateSpecial(special));
  };

  const checkOpenHours = () => {
    // console.log("restaurantData", restaurantData);
    if (restaurantData && restaurantData.openHours) {
      return filterOpenHours(restaurantData.openHours);
    }

    return true;
  };

  // const getThumbnail = (image) => {
  //   if (!image) {
  //     return null;
  //   }
  //   const filename = image.split("%2f").pop();
  //   const thumbImage = image
  //     .replace(filename, `thumbs%2f${filename}`)
  //     .replace(".jpg", "_100x100.jpg");
  //   //console.log(thumbImage, image, filename);
  //   return thumbImage;
  // };

  const addToCart = () => {
    if (!checkOpenHours() && !isTakeawayDeliverPickup(realTableId)) {
      showError(
        "The merchant is now not operating, Please order after opening."
      );
      return;
    }

    if (qrCodeMessage !== "") {
      showError(qrCodeMessage);
      return;
    }

    if (dish.quantity <= 0 || !dish.isReqiredSelected) {
      showError(
        "Please select the required options and options maximum quantity!"
      );
      return;
    }
    addDish(dish);
    searchItem("");
    removeDish();
    // props.closeCallback();
    // setOpen(false);
    history.goBack();
    // if (restaurant) {
    //   history.push(`/online/${restaurantId}/${restaurant.tableId}`);
    // } else {
    //   history.goBack();
    // }
  };

  const increaseQuantity = () => {
    updateDishQty(dish.quantity + 1);
  };

  const decreaseQuantity = () => {
    if (dish.quantity === 1) {
      return;
    }
    updateDishQty(dish.quantity - 1);
  };

  const handleSizeChange = (event) => {
    updateSize(event.target.value);
  };

  const updateSize = (value) => {
    // console.log('props.optionCallback=', props.optionCallback, !optionChecked);
    const selectedIndex = dish.dish.sizes.findIndex(
      (item) => item.id.toString() === value
    );
    // console.log("selectedIndex", selectedIndex, value);
    if (selectedIndex > -1) {
      const size = dish.dish.sizes[selectedIndex];
      selectSize(size);
    }
  };

  const checkRestaurantMessage = () => {

    if (!menuTableId) {
      return;
    }
    
    //console.log('restaurantId', restaurantId);
    let ref = Firebase.database().ref(`messages/${restaurantId}/DishDetail`);
    //console.log('ref', ref);
    ref.on("value", (snapshot) => {
      //console.log('snapshot', snapshot.val());
      if (snapshot && snapshot.val() && snapshot.val().isEnabled) {
        const message = snapshot.val().message;
        setRestaurantMessage(message);
      } else {
        setRestaurantMessage("");
      }
    });
    

    const tableId = menuTableId.includes(";") ? menuTableId.split(";")[0] : menuTableId;
    let refQrCode = Firebase.database().ref(
      // if menuTableId includes ;, split it and get the first one
      
      `messages/${restaurantId}/QrCodeSettings/${tableId}`
    );
    //console.log("menuTableId", menuTableId);
    refQrCode.on("value", (snapshot) => {
      // console.log('snapshot', snapshot.val());
      const todayString = moment().locale("au").format("DD/MM/YYYY");
      console.log('snapshot.val()', snapshot.val());
      if (
        snapshot &&
        snapshot.val() &&
        (snapshot.val().isOrderingDisabled === true ||
          snapshot.val().disabledDate === todayString)
      ) {
        setQrCodeMessage("Online ordering is closed. Please try again later.");
      } else {
        setQrCodeMessage("");
      }
    });
  };

  const setupTableId = () => {
    if (!menuTableId) {
      setRealTableId(loadTableId(decodeRestaurantId(restaurantId)));
      // console.log('realTableId get', realTableId, id);
    } else {
      setRealTableId(menuTableId);
    }
  };

  useEffect(() => {
    checkRestaurantMessage();
    executeScroll();
    setupTableId();
  }, [props]);

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "isForTakeaway") {
      setIsForTakeaway(value);
      updateTakeaway(value);
    }
  };

  return (
    <React.Fragment>
      <div ref={myRef}></div>
      <div className={classes.grow}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIosIcon />
            </IconButton>

            <Grid alignItems="center"  container justify="center">
              <Grid item>
                <CallButton />
              </Grid>
            </Grid>
            <Button
              autoFocus
              color="inherit"
              onClick={addToCart}
              disabled={qrCodeMessage !== ""}
              className={classes.toolbarButton}
            >
              Add to Cart
            </Button>
          </Toolbar>
          <Paper className={classes.grow} square={true} style={{ padding: 4 }}>
            <Grid container direction="row">
              <Grid xs={4} item container >
                {dish && dish.dish && dish.dish.image ? (
                  <Grid item>
                  <LazyLoadImage
                    alt={dish.dish.name}
                    effect="blur"
                    src={dish.dish.thumbImage ? dish.dish.thumbImage : dish.dish.image}
                    className={classes.dishImage}
                  />
                  </Grid>
                ) : null}
              </Grid>
              <Grid  container direction="column">
                <Typography>{dish.dish && dish.dish.name}</Typography>
                <Typography>
                  {dish.dish && dish.dish.altName ? dish.dish.altName : ""}
                </Typography>
                <Typography>{dish.dish && dish.dish.description}</Typography>

                <Typography variant="h6">
                  {restaurantData && restaurantData.currencySymbol}
                  {dish && dish.subTotal
                    ? Number(dish.subTotal).toFixed(2).toString()
                    : " "}{" "}
                  {dish.quantity > 1 ? " x " + dish.quantity.toString() : ""}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </AppBar>

        <div className={classes.content}>
          {restaurantData &&
          restaurantData.onlineSettings &&
          restaurantData.onlineSettings.allergyNotice &&
          restaurantData.onlineSettings.allergyNotice.length > 0 ? (
            <Paper className={classes.bottomMargin}>
              <Alert severity="warning">
                <strong>{restaurantData.onlineSettings.allergyNotice}</strong>
              </Alert>
            </Paper>
          ) : null}

          <Paper className={classes.bottomMargin}>
            {restaurantMessage !== "" ? (
              <Alert severity="info">
                <strong>{restaurantMessage}</strong>
              </Alert>
            ) : null}
          </Paper>

          <Paper className={classes.bottomMargin}>
            {qrCodeMessage !== "" ? (
              <Alert severity="info">
                <strong>{qrCodeMessage}</strong>
              </Alert>
            ) : null}
          </Paper>

          {restaurantData &&
          restaurantData.holidaySurcharge &&
          restaurantData.holidaySurcharge.isEnabled === true ? (
            <Paper>
              <Alert severity="info">
                <strong>
                  Please note a {restaurantData.holidaySurcharge.name} of{" "}
                  {`${restaurantData.holidaySurcharge.amount}${
                    restaurantData.holidaySurcharge.isPercentage === true
                      ? "% "
                      : "dollars "
                  } will be added to all bills.`}
                </strong>
              </Alert>
            </Paper>
          ) : null}

          {isTakeawayEnabled(realTableId, restaurantData) &&
          dish.dish &&
          dish.dish.isTakeawayDisabled !== true ? (
            <FormControlLabel
              control={
                <Switch
                  checked={isForTakeaway}
                  onChange={handleSwitchChange}
                  name="isForTakeaway"
                  color="secondary"
                />
              }
              label="For takeaway"
            />
          ) : null}
          {dish.dish && dish.dish.sizes.length > 0 ? (
            <List dense>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Size</FormLabel>
                <RadioGroup
                  aria-label="size"
                  name="size"
                  value={dish.size ? dish.size.id : null}
                  onChange={handleSizeChange}
                >
                  {dish.dish.sizes.map((size) => {
                    return (
                      <React.Fragment>
                        <FormControlLabel
                          key={size.id}
                          value={size.id}
                          control={<Radio color="primary" />}
                          label={`${size.name.split("#")[0]} - ${restaurantData && restaurantData.currencySymbol}${size.price}`}
                        />
                        {(dish.size ? dish.size.id : null) === size.id &&
                          size.groups &&
                          size.groups
                            .filter(
                              (x) =>
                                !x.name.startsWith("#") && x.hideForWeb !== true
                            )
                            .map((group) => {
                              return <DishGroup group={group} key={group.id} />;
                            })}
                      </React.Fragment>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </List>
          ) : null}

          {dish.dish &&
            dish.dish.groups &&
            dish.dish.groups
              .filter((x) => !x.name.startsWith("#") && x.hideForWeb !== true)
              .map((group) => {
                return <DishGroup group={group} key={group.id} currencySymbol={restaurantData && restaurantData.currencySymbol}/>;
              })}

          {restaurantData &&
          restaurantData.onlineSettings &&
          restaurantData.onlineSettings.hideSpecialRequirement === true ? (
            <Typography
              display="block"
              variant="subtitle1"
              paragraph="true"
              color="secondary"
            >
              {dish && dish.dish && dish.dish.description}
            </Typography>
          ) : (
            <TextField
              className={classes.bottomButtons}
              id="specialInstructions"
              label="Special instructions (optional)"
              multiline
              rows={4}
              fullWidth
              inputProps={{
                maxlength: CHARACTER_LIMIT,
              }}
              value={specialInstructions}
              variant="outlined"
              helperText={`${specialInstructions.length}/${CHARACTER_LIMIT}`}
              placeholder="Example: No pepper / sugar / salt please"
              onChange={(e) => updateSpecialInstructions(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}

          <Grid
            container
            direction="row"
            className={classes.bottomButtons}
            justify="space-between"
          >
            <Grid item container direction="row" xs={5} justify="space-between">
              <Fab
                color="primary"
                aria-label="add"
                size="small"
                onClick={decreaseQuantity}
              >
                <RemoveIcon />
              </Fab>
              <Typography variant="h6">{dish.quantity}</Typography>

              <Fab
                color="primary"
                aria-label="add"
                size="small"
                onClick={increaseQuantity}
              >
                <AddIcon />
              </Fab>
            </Grid>
            <Grid item xs={6} containter direction="row" alignItems="flex-end">
              <Button
                variant="contained"
                color="primary"
                disabled={qrCodeMessage !== ""}
                onClick={addToCart}
                style={{ width: 150, borderRadius: 25 }}
              >
                Add To Cart
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DishDetail;
