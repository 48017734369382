import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import Radio from '@material-ui/core/Radio';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";


import { v4 as uuid } from 'uuid';
import { removeItemWithSlice } from 'util/utils'



const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    buttonLowCase: {
        textTransform: "none"
    }
}));

const NewTables = (props) => {
    const { qrTypes } = props;

    const classes = useStyles();


    const [tables, setTables] = useState([]);
    const [orderCode, setOrderCode] = useState("");
    const [tableNumber, setTableNumber] = useState("");
    const [tableName, setTableName] = useState("");

    const handleCodeChange = (value, id) => {
        console.log(JSON.stringify(value));
        // find qr type by code
        const qrType = qrTypes.find(qrType => qrType.code === value);

        let newList = [...tables];
        const index = newList.findIndex(size => size.id === id);
        if (index !== -1) {
            let size = newList[index];
            newList[index] = { ...size, tableNumber: qrType.name, tableId: qrType.code };
            updateTables(newList, true);
        }
    };


    useEffect(() => {

        setTables(props.tableList);


    }, [props]);

    const updateTables = (newSizes, needUpdateParent) => {
        const theNewSizes = newSizes.map((item, index) => ({ ...item, sort: index }));
        setTables(theNewSizes);
        if (needUpdateParent) {
            props.updateTables(theNewSizes)
        }
    };


    const addNewTable = () => {
        let newList = [...tables];
        newList.push({ id: uuid(), tableNumber: '', tableId: '' });
        console.log('newTables', newList);
        updateTables(newList, false);
    };

    const updateName = (value, id) => {
        let newList = [...tables];
        const index = newList.findIndex(size => size.id === id);
        if (index !== -1) {
            let size = newList[index];
            newList[index] = { ...size, tableNumber: value };
            updateTables(newList, true);
        }
    };

    const updateRealTableNumber = (value, id) => {
        let newSizes = [...tables];
        const index = newSizes.findIndex(size => size.id === id);
        if (index !== -1) {
            let size = newSizes[index];

            newSizes[index] = { ...size, realTableNumber: value };
            updateTables(newSizes, true);
        }
    };

    const deleteTable = (id) => {
        const index = tables.findIndex(size => size.id === id);
        if (index !== -1) {
            const newSizes = removeItemWithSlice(tables, index);
            updateTables(newSizes, true);
        }
    };



    return (

        <Grid item container direction="column" spacing={2} >


            {tables && tables.length > 0 ? <>
                {tables.map((table) => {
                    return <Grid item container direction="row" spacing={1} key={table.id} alignItems="center">



                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Type</FormLabel>
                                <RadioGroup aria-label="choose type" name="radio-buttons-group" row onChange={e => handleCodeChange(e.target.value, table.id)}>
                                    {qrTypes.map((tableType) => (
                                        <FormControlLabel
                                            key={tableType.code}
                                            value={tableType.code}
                                            control={<Radio checked={table.tableId === String(tableType.code)} />}
                                            label={`${tableType.name}`}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <TextField 
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="tableName"
                                variant="outlined"
                                value={table.tableNumber}
                                onChange={e => updateName(e.target.value, table.id)}
                            />
                        </Grid>


                        {table.tableId === 'dinein' ?

                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    label="Table Number"
                                    margin="dense"
                                    name="tableNumber"
                                    variant="outlined"
                                    value={table.realTableNumber}
                                    onChange={e => updateRealTableNumber(e.target.value, table.id)}
                                />
                            </Grid>
                            : null}

                        <Grid item xs={2}>
                            <IconButton aria-label="more" className={classes.margin} edge='end' color="secondary" onClick={e => deleteTable(table.id)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Grid>

                    </Grid>
                })}
                <Grid item xs={12} md={12}>
                    <Button className={classes.buttonLowCase} variant="outlined" color="primary" size="small" onClick={addNewTable}>Add type</Button>
                </Grid>
            </> :
                <Grid item xs={4}>
                    <Button className={classes.buttonLowCase} variant="outlined" color="primary" onClick={addNewTable} size="medium">Add types</Button>
                </Grid>
            }
        </Grid>

    );
};

export default NewTables;
