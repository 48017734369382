import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";

import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@material-ui/core";


import { NavLink } from "react-router-dom";
import { updateSelectedUserIds } from "store/actions/account";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const StaffTable = (props) => {
  const { className, staffList, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const updateUserIds = (ids) => dispatch(updateSelectedUserIds(ids));
  const userFilter = useSelector((state) => state.account.filter);

  const handleSelectAll = (event) => {
    

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = staffList.map((user) => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
    updateUserIds(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const filterUsers = () => {
    if (!userFilter || userFilter === null || userFilter.trim() === "") {
      return staffList;
    }

    return staffList.filter(
      (user) =>
        (user.staffNumber && String(user.staffNumber).toLowerCase().includes(userFilter)) ||
        (user.firstName && String(user.firstName).toLowerCase().includes(userFilter)) ||
        (user.lastName &&  user.lastName.toLowerCase().includes(userFilter)) ||
        (user.phoneNumber && user.phoneNumber.toLowerCase().includes(userFilter)) ||
        (user.email && user.email.toLowerCase().includes(userFilter))
    );
  };

  const getTimeString = (date) => {
    if ('_seconds' in date) {
      return moment(new Date(date._seconds * 1000)).format('DD/MM/YYYY')
    }
    return moment(date).format('DD/MM/YYYY')
  };

  return (
    <Grid lg={12} md={12} xl={12} xs={12}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          {/* <PerfectScrollbar> */}
          <div className={classes.inner}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.length === staffList.length}
                        color="primary"
                        indeterminate={
                          selectedUsers.length > 0 &&
                          selectedUsers.length < staffList.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Staff Number</TableCell>
                    <TableCell>Email </TableCell>
                    <TableCell>Permissions</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Updated date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterUsers()
                    .slice(page * rowsPerPage, rowsPerPage * page + rowsPerPage)
                    .map((user) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={user.id}
                        selected={
                          selectedUsers.indexOf(user.id) !== -1
                        }
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedUsers.indexOf(user.id) !== -1
                            }
                            color="primary"
                            onChange={(event) =>
                              handleSelectOne(event, user.id)
                            }
                            value="true"
                          />
                        </TableCell>
                        <TableCell>
                          <div className={classes.nameContainer}>
                            <NavLink to={`/staff/${user.id}`}>
                              <Typography
                                variant="body1"
                                className={classes.textLink}
                              >
                                {" "}
                                {`${user.firstName} ${user.lastName}`}
                              </Typography>
                            </NavLink>
                          </div>
                        </TableCell>
                        <TableCell>{user.staffNumber}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          {user.permissions &&
                            user.permissions
                              .map((item) => item.name)
                              .map((item) => <Typography>{item}</Typography>)}
                        </TableCell>
                        <TableCell>{user.phoneNumber}</TableCell>
                        <TableCell>
                          {getTimeString(user.updatedAt)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filterUsers().length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

StaffTable.propTypes = {
  className: PropTypes.string,
  staffList: PropTypes.array.isRequired,
};

export default StaffTable;
