import React, { useState } from 'react'

import CategoryDisplay from './CategoryDisplay';
import CategoryEdit from './CategoryEdit';



const Category = (props) => {
    const { category, menuId } = props;


    const [isEdit, setIsEdit] = useState(false)

    const handleEdit = (flag) => {
        setIsEdit(flag);
    }

    return (
        <>
        { isEdit ? <CategoryEdit  menuId={menuId} category={category} editItem={handleEdit}/> : 
         <CategoryDisplay menuId={menuId} category={category}  editItem={handleEdit} deleteCategory={props.deleteCategory}  />
    }
    </>
    );
};

export default Category;
