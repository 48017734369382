import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';


import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const TodaySales = props => {
  const { className, todayData, isTodayIncrease, currencySymbol, ...rest } = props;
  
  const classes = useStyles();



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TODAY SALES
            </Typography>
            <Typography variant="h3">{currencySymbol}{todayData.toFixed(2)}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>

{/*            
         { isTodayIncrease != null && isTodayIncrease === true ? 
          <ArrowUpwardIcon className={classes.differenceIcon} />
         : null}
         
         { isTodayIncrease != null && isTodayIncrease === false ? 
          <ArrowDownwardIcon className={classes.differenceIcon} />
         : null} */}
    
          <Typography
            className={classes.caption}
            variant="caption"
          >
            {moment().format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

TodaySales.propTypes = {
  className: PropTypes.string,
  orderDashboard: PropTypes.object
};

export default TodaySales;
