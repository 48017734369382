import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

import { TransactionsToolbar, TransactionsTable } from './components';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setTransactions, setTransactionPage } from 'store/actions/transaction';
import {
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import API from 'util/API';
import {
  FormControlLabel,
  Switch,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%'
  }
}));




const TransactionList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiLoading, setUiLoading] = useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);


  const selectedTransactionIds = useSelector((state) => state.transaction.ids);
  const [showDeletedTransactions, setShowDeletedTransactions] = useState(false);
  const [canDeleteTransactions , setCanDeleteTransactions] = useState(false);
  const [canDeleteInvoiceTransctions, setCanDeleteInvoiceTransctions] = useState(false);
  const account = useSelector((state) => state.account.user);

  const selectedDate = useSelector((state) => state.restaurant.orderSelectedDate);
  const setPage = (page) => dispatch(setTransactionPage(page));
  const setNewTransactions = (trans) => dispatch(setTransactions(trans));
  const transactions = useSelector((state) => state.transaction.transactions);

  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const history = useHistory();

  const getPage = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get('page');
  }


  const fetchTransactions = async (flag = false) => {

    setUiLoading(true);
    // console.log('selectedDate', moment(selectedDate).format('DD-MM-YY'));
    try {
      const response = await API
        .post('/transactions?dateStr=' + moment(selectedDate).format('DD-MM-YY'), { showDeletedTransactions: flag })

      setNewTransactions(response.data.transactions);
      setCanDeleteTransactions(response.data.canDeleteTransactions);
      setCanDeleteInvoiceTransctions(response.data.canDeleteInvoiceTransctions);
      setUiLoading(false);
      if (!getPage()) {
        setPage(0);
      }

    }
    catch (error) {
      console.log(error);
      setUiLoading(false);
      if (error.response && error.response.status === 403) {
        history.push('/sign-in');
      }

      showErrorMessage('Error in retrieving the data');

    }
  };

  const selectOrders = (ids) => {
    if (ids.length > 0) {
      setSelectedOrderIds(ids);
    }
  };


  const updateTransactions = async (reason) => {
    if (!selectedTransactionIds || selectedTransactionIds.length === 0) {
      return
    }

    setUiLoading(true);

    try {
      const dateStr = moment(selectedDate).format("DD-MM-YY");
      const rewsponse = await API.post(`/updateTransactions`, { transactionIds: selectedTransactionIds, dateStr, reason });
      const data = await rewsponse.data;
      fetchTransactions();


    } catch (err) {
      console.log(err);
      setUiLoading(false);

    }
  };


  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    if (name === "showDeletedTransactions") {
      setShowDeletedTransactions(value);
      fetchTransactions(value);
      return;
    }
  };


  useEffect(() => {
    if (!getPage()) {
      fetchTransactions();
    }

  }, [props]);



  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {

    return (
      <div className={classes.root}>
        <TransactionsToolbar deleteProxy={updateTransactions} canDeleteTransactions={canDeleteTransactions} canDeleteInvoiceTransctions={canDeleteInvoiceTransctions} />
        <div className={classes.content}>
          {account && account.role && account.role  === "admin"  && canDeleteTransactions ?
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDeletedTransactions}
                    onChange={handleSwitchChange}
                    name="showDeletedTransactions"
                    color="primary"
                  />
                }
                label="Show deleted transactions"
              />
            </Grid>
            : null}
          <TransactionsTable transactions={transactions} selectorders={selectOrders} />
        </div>
      </div>
    );
  }
};

export default TransactionList;
