import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import CircularProgress from "@material-ui/core/CircularProgress";


import { NavLink } from "react-router-dom";

import { useDispatch } from "react-redux";



import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import { useConfirm } from "material-ui-confirm";
import ListContext from '../../ListContext';
import {  useMutation, useQueryClient } from "react-query";
import { updatePaymentTypes } from "apis/paymentAPIs";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const PaymentTypeListToolbar = (props) => {
  const { className, paymentTypes, ...rest } = props;
  const confirm = useConfirm();
  const [buttonLoading, setButtonLoading] = useState(false);
  
  const classes = useStyles();
  const dispatch = useDispatch();
  
 
  
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));
  
  const { selecteItemId } = useContext(ListContext);
 

  const deletePaymentType =  async () => {
    console.log("selecteItemId", selecteItemId);
    if (!selecteItemId) {
      showErrorMessage("Please select a type");
      return;
    }

    confirm({ description: "You are about to delete this type" })
      .then(() => {
        doUpdatePaymentTypes();
        // deleteOneUser();
      })
      .catch(() => {
        /* ... */
      });
  };

  

  const queryClient = useQueryClient();

 

  const mutation = useMutation(updatePaymentTypes, {
    onSuccess: () => {
      queryClient.invalidateQueries("paymentTypes");
          },
  });


  const preparePayload = () => {

   
   const filteredPaymentTypes = paymentTypes.filter(
      (paymentType) => paymentType.paymentTypeId !== selecteItemId
    );

    return filteredPaymentTypes;
  };


  const doUpdatePaymentTypes =  () => {
    const payload = preparePayload();
    mutation.mutate(payload);
  };

  if (mutation.isLoading) {
    return (
      <main className={classes.content}>

        <CircularProgress size={150} className={classes.uiProgess} />

      </main>
    );
  }

  if(mutation.isSuccess){
    showSuccessMessage("The payment type has been updated successfully");
    
  }


  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <IconButton
          aria-label="delete"
          onClick={deletePaymentType}
          disabled={buttonLoading}
        >
          <DeleteIcon />
        </IconButton>

     

        <NavLink to={"/newPaymentType"}>
          <Button color="primary" variant="outlined">
            Add New Payment Type
          </Button>
        </NavLink>
      </div>
      {/* <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search surcharge"
          value={searchText}
          onChange={handleChange}
        />
      </div> */}
    </div>
  );
};

PaymentTypeListToolbar.propTypes = {
  className: PropTypes.string,
};

export default PaymentTypeListToolbar;
