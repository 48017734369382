import React, { useState, useEffect, useRef } from "react";

import "react-awesome-lightbox/build/style.css";

import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";
import { fade, makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import Dish from "./components/Dish";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchMenuCategorySuccess,
  selectedCategory,
  updateHideDishes,
} from "../../store/actions/menu";
import {
  setRestaurantId,
  loadPreviousCart,
  updateMemberId,
} from "../../store/actions/order";

import DynamicCategories from "./components/DynamicCategories";
import CategorySections from "./components/CategorySections";

import { filterVisibility, decodeRestaurantId } from "util/utils";
import Firebase from "firebase";

import { ScrollingProvider } from "react-scroll-section";

import { loadOrdersSummary } from "util/orderUtils";

import { logError } from "apis/utilAPIs";
import { useQuery } from "react-query";
import { getMenu } from "apis/menuAPIs";
import HomeDialog from "./components/HomeDialog";

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  return isMounted;
}

const useStyles = makeStyles((theme) => ({
  grow: {
    maxWidth: "100%",
    // overflowX: 'hidden'
  },

  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    marginBottom: theme.spacing(1),
  },
  message: {
    marginBottom: theme.spacing(2),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  searchResults: {
    overflowX: "hidden",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    marginLeft: "auto",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    marginLeft: "auto",
  },

  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "16px",
    width: "16px",
    left: "50%",
    top: "50%",
  },
  toolbar: theme.mixins.toolbar,
  toolbarButtons: {
    marginLeft: "auto",
  },
  tabs: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  tabLabel: {
    fontSize: 12,
    fontWeight: "bold",
  },

  imageIcon: {
    width: "40px",
    height: undefined,
  },
  iconRoot: {
    textAlign: "center",
  },
  bell: {
    flexGrow: 1,
    alignItems: "center",
  },
}));

const OnlineMenu = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [filteredDishes, setFilteredDishes] = useState([]);
  const [tableIdDisplay, setTableIdDisplay] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dialogSettings, setDialogSettings] = useState({
    isDialogShown: false,
    dialogContent: "",
    dialogTitle: "",
  });

  const updateTheMemberId = (memberId) => dispatch(updateMemberId(memberId));

  const getUrlParam = (key) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
  };

  const [ordersSummary, setOrdersSummary] = useState(null);
  const savedOrders = useSelector((state) => state.order.ordersSummary);
  const filteredItems = useSelector((state) => state.menu.filterItems);
  const queryWord = useSelector((state) => state.menu.search);
  const id = props.match.params.menuId;
  const orderId = props.match.params.orderId;
  const tableId = props.match.params.tableId;
  // const showError = (msg) => dispatch(showErrorSnackbar(msg));

  const checkInactiveMenu = () => {
    //console.log('restaurantId', restaurantId);
    let ref = Firebase.database().ref(
      `inactiveMenus/${decodeRestaurantId(id)}`
    );
    //console.log('ref', ref);
    ref.on("value", (snapshot) => {
      //console.log('snapshot', snapshot.val());
      if (snapshot && snapshot.val() && snapshot.val().dishIds) {
        const inactiveIds = snapshot.val().dishIds;
        setFilteredDishes(inactiveIds);
        updateHideMenuItems(inactiveIds);
      } else {
        setFilteredDishes([]);
        updateHideMenuItems([]);
      }
    });
  };

  const checkDialog = (restaurantId) => {
    let isDialogShown = false;
    let content = "";
    let title = "";

    let refMenuSettings = Firebase.database().ref(
      `menuSettings/${restaurantId}`
    );
    refMenuSettings.on("value", (snapshot) => {
      if (
        snapshot &&
        snapshot.val() &&
        snapshot.val().showDialog !== undefined &&
        snapshot.val().showDialog !== null
      ) {
        isDialogShown = snapshot.val().showDialog;
        content = snapshot.val().dialogText;
        title = snapshot.val().dialogTitle;
        const dialogVersion = snapshot.val().dialogVersion;

        const currentDialogVersion = sessionStorage.getItem("dialogVersion");
        if (currentDialogVersion && currentDialogVersion === dialogVersion) {
          setDialogSettings({
            isDialogShown: false,
            dialogContent: "",
            dialogTitle: "",
          });
          return;
        }

        setDialogSettings({
          isDialogShown,
          dialogContent: content,
          dialogTitle: title,
          dialogVersion: dialogVersion,
        });
      } else {
        setDialogSettings({
          isDialogShown: false,
          dialogContent: "",
          dialogTitle: "",
        });
      }
    });
  };

  useEffect(() => {
    checkInactiveMenu();
    const memberId = new URLSearchParams(window.location.search).get(
      "memberId"
    );
    updateTheMemberId(memberId);
  }, [props]);

  useEffect(() => {
    if (window && window.parent) {
      const redirectResultType = getUrlParam("type");
      if (redirectResultType === "cancel" || redirectResultType === "error") {
        if (redirectResultType === "error") {
          alert("Payment failed");
        }
        window.parent.postMessage(
          { type: "payment_failure", success: true },
          "*"
        );
      }
    }
  }, [props]);

  const loadCategoriesSuccess = (categoryList) =>
    dispatch(fetchMenuCategorySuccess(categoryList));
  const updateRestaurantId = (restaurantId) =>
    dispatch(setRestaurantId(restaurantId));

  const setCategory = (idx) => dispatch(selectedCategory(idx));

  const loadCart = (restaurantId) => dispatch(loadPreviousCart(restaurantId));

  const categories = useSelector((state) => state.menu.categories);
  const updateHideMenuItems = (items) => dispatch(updateHideDishes(items));

  useEffect(() => {
    if (savedOrders) {
      setOrdersSummary(savedOrders);
    } else {
      const orders = loadOrdersSummary(id);
      setOrdersSummary(orders);
    }
  }, [props, id]);

  useEffect(() => {
    if (orderId && id) {
      loadCart(id);
    }
  }, [orderId, id]);

  const isMounted = useIsMounted();

  const processMenuData = (menuData) => {
    if (!menuData) {
      return;
    }

    if (menuData.restaurant && menuData.restaurant.redirectUrl) {
      window.location.assign(menuData.restaurant.redirectUrl);
      return;
    }

    setTableIdDisplay(menuData.tableId);
    const menu = menuData.menu;
    if (!menu || !menuData.restaurant || !menu.categories) {
      return;
    }

    if (isMounted.current) {
      //console.log('response.data.restaurant', response.data.restaurant);

      const sortedCategories = menu.categories
        .sort((a, b) => (a.sort < b.sort ? -1 : 1))
        .filter((category) => filterVisibility(category));

      loadCategoriesSuccess({
        data: sortedCategories,
        restaurant: menuData.restaurant,
        restaurantId: menuData.restaurant.restaurantId,
        tableId: menuData.tableId,
      });
      if (sortedCategories.length > 0) {
        setCategory(0);
      }
    } else {
      loadCategoriesSuccess({
        data: [],
        restaurant: menuData.restaurant,
        restaurantId: menuData.restaurant.restaurantId,
        tableId: menuData.restaurant.tableId,
      });
    }
  };

  useEffect(() => {
    const restaurantId = decodeRestaurantId(id);

    updateRestaurantId({ restaurantId: restaurantId, tableId: tableId });
  }, [id, tableId]);

  const { isLoading, error, refetch } = useQuery(
    ["menu", id, tableId],
    () => getMenu(id, tableId, processMenuData),
    {
      retry: 3, // retry failed requests up to 3 times

      retryDelay: 1000, // wait 1 second between retries

      staleTime: 100000, // cache data for 10 minutes

      refetchOnWindowFocus: false, // don't refetch when the window regains focus

      suspense: false, // use suspense mode

      timeout: 10000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    checkDialog(decodeRestaurantId(id));
  }, [id]);

  useEffect(() => {
    setShowDialog(dialogSettings.isDialogShown);
  }, [dialogSettings]);

  if (error) {
    const info = {
      restaurantId: id,
      tableId: tableId,
      responseMessage:
        error.response && error.response.data
          ? error.response.data.message
          : "",
      responseStatus:
        error.response && error.response.data ? error.response.data.status : "",
    };

    const errorData = {
      title: "Online menu Error",
      message: `${error.message}, info: ${JSON.stringify(info)}`,
    };

    logError(errorData);

    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.message}>
          An error has occurred. Please try reloading the page.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
        >
          Reload
        </Button>
      </div>
    );
  }

  if (isLoading || !categories || categories.length === 0) {
    return (
      <div className={classes.root}>
        <CircularProgress className={classes.uiProgess} />
        {dialogSettings ? (
          <HomeDialog
            open={showDialog}
            title={dialogSettings.dialogTitle}
            content={dialogSettings.dialogContent}
            onClose={() => {
              setShowDialog(false);
              sessionStorage.setItem("dialogVersion", dialogSettings.dialogVersion);
            }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return (
      <div className={classes.grow}>
        <ScrollingProvider offset={-250}>
          <DynamicCategories
            key={categories.length}
            categories={categories}
            tableIdDisplay={tableIdDisplay}
            menuId={id}
            tableId={tableId}
            queryWord={queryWord}
            ordersSummary={ordersSummary}
          />
          {filteredItems && filteredItems.length > 0 ? (
            <>
              <div className={classes.searchResults}>
                {filteredItems
                  .filter((dish) => {
                    if (filteredDishes.indexOf(dish.id) > -1) {
                      return false;
                    }

                    return filterVisibility(dish);
                  })
                  .map((item) => {
                    return <Dish key={item.id} item={item} />;
                  })}
              </div>
            </>
          ) : (
            <CategorySections
              key={id}
              categories={categories}
              restaurantId={id}
            />
          )}
        </ScrollingProvider>
        {dialogSettings ? (
          <HomeDialog
            open={showDialog}
            title={dialogSettings.dialogTitle}
            content={dialogSettings.dialogContent}
            onClose={() => {
              setShowDialog(false);
              sessionStorage.setItem("dialogVersion", dialogSettings.dialogVersion);
            }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
};

export default OnlineMenu;
