import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";

import {
    saveMenuGroupOption,

} from 'store/actions/restaurantMenu';
import {  useDispatch } from 'react-redux';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },

}));

const NewGroupOption = (props) => {
    const { group } = props;
    
    const dispatch = useDispatch();
    const saveOption = (values) => dispatch(saveMenuGroupOption(values));
    const [hideForWeb, setHideForWeb] = useState(false);
    const [shortName, setShortName] = useState('');

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [isDefault, setIsDefault] = useState(false);

    const close = () => {
        props.setIsNewOption(false);
    };




    const isSaveButtonDisabled = () => {
        return !name || !price
    };

    const save = () => {
        const option = {
            name,
            shortName,
            price: Number(price),
            groupId: group.id,
            menuId: group.menuId,
            default: isDefault,
            sort: 999,
            hideForWeb
        };
        saveOption(option);
        close();
    }


    const handleSwitchChange = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        if (name === 'hideForWeb') {
            setHideForWeb(value);
        }
    };

    return (
        <Grid container direction="column" >
            <Grid container direction="row" >
                <TextField
                    margin="normal"
                    name="name"
                    fullWidth
                    
                    id="name"
                    label="Name"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />

                <TextField
                    size="small"
                    label="Short Name"
                    margin="dense"
                    name="shortName"
                    type="text"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={shortName}
                    onChange={e => setShortName(e.target.value)}

                />

                <TextField
                    margin="normal"
                    name="price"
                    size="small"
                    id="price"
                    label="Price"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={price}
                    onChange={e => setPrice(e.target.value)}
                />
            </Grid>
        
            <Grid container direction="row" alignItems="center">

            <FormControlLabel
                    control={
                        <Switch
                            checked={hideForWeb}
                            onChange={handleSwitchChange}
                            name="hideForWeb"
                            color="primary"
                        />
                    }
                    label="Hide For Web"
                />
                <Checkbox
                    name="allow_quantity"
                    color="primary"
                    value={isDefault}
                    onChange={e => setIsDefault(e.target.checked)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Typography>
                    Pre-select
                </Typography>
                <Tooltip title="This item will be appear as pre-selected in the menu." arrow>
                    <InfoIcon />
                </Tooltip>

            </Grid>
            <Grid container direction="row" justify="space-between" spacing={1}>
                <Button variant="outlined" onClick={close}>Cancel</Button>
                <Button variant="outlined"
                    disabled={isSaveButtonDisabled()}
                    color="primary" onClick={save}>
                    Save
                </Button>
            </Grid>

        </Grid>
    );
};

export default NewGroupOption;