import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';


import {  useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';


import { showSuccessSnackbar, showErrorSnackbar } from 'store/actions/snackbarMessage'
import { publishMenu, publishSecondaryMenu, unpublishQRMenu } from 'store/actions/restaurantMenu';
import { useConfirm } from 'material-ui-confirm';
import API from 'util/API';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  rightSpacer: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const MenuToolbar = props => {
  const { className, menu, menuId, ...rest } = props;
  
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const publishTheMenu = (id, qrCodeType) => dispatch(publishMenu(id, qrCodeType));
  const unpublishTheQRMenu = (id, qrCodeType) => dispatch(unpublishQRMenu(id, qrCodeType));
  
  const publishTheSecondaryMenu = (id) => dispatch(publishSecondaryMenu(id));

  const classes = useStyles();

  const handlePublish = () => {
    if (menu.isMainMenu === false) {
      publishTheSecondaryMenu(menuId);
    } else {
      confirm({ description: 'You are about to publish this menu to the website!' })
        .then(() => {
          publishTheMenu(menuId);
        })
        .catch(() => { /* ... */ });

    }
  };

  const handlePublishOnline = () => {
    if (menu.qrCodeType.length === 0) {
      showError('Please a QR code type for this menu.')
      return;
    } else {
      confirm({ description: 'You are about to publish this menu online!' })
        .then(() => {
          publishTheMenu(menuId, menu.qrCodeType);
        })
        .catch(() => { /* ... */ });

    }
  };

  const handleUnpublish = () => {
    if (menu.qrCodeType.length === 0) {
      showError('Please a QR code type for this menu.')
      return;
    } else {
      confirm({ description: 'You are about to publish this menu online!' })
        .then(() => {
          unpublishTheQRMenu(menuId, menu.qrCodeType);
        })
        .catch(() => { /* ... */ });

    }
  };

  const handleOpitonLinksRefresh = async () => {
      try {
        await API.post('/refreshOptionLinks/' + menuId);
        showSuccessMessage('Refreshed successfully');
      } catch (err) {
        showError('Something went wrong, please try again.');
    }
  };



  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
      <span className={classes.spacer} />
        <Button variant="outlined" color="primary" onClick={handleOpitonLinksRefresh}>Refresh opiton links</Button>
        <span className={classes.rightSpacer} />
        <Button variant="outlined" color="primary" onClick={handlePublish}>Publish Main</Button>
        <span className={classes.rightSpacer} />
        <Button variant="outlined" color="primary" onClick={handlePublishOnline}>Publish QR Code Type</Button>
        <span className={classes.rightSpacer} />
        <Button variant="outlined" color="primary" onClick={handleUnpublish}>Unpublish QR Code Type</Button>
        <span className={classes.rightSpacer} />

        <NavLink to={'/menuCookingCategoryList'}>
          <Button
            color="primary"
            variant="outlined"
          >
            Edit Cooking Category
        </Button>
        </NavLink>
        <span className={classes.rightSpacer} />

        <NavLink to={'/menuCategory/' + props.menuId}>
          <Button
            color="primary"
            variant="outlined"
          >
            Edit Category
        </Button>
        </NavLink>
      </div>


    </div>
  );
};

MenuToolbar.propTypes = {
  className: PropTypes.string
};

export default MenuToolbar;
