import React, { useRef, forwardRef } from "react";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useQuery } from "react-query";
import { getInvoice } from "apis/orderAPIs";
import { Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const InvoiceComponent = forwardRef(({ data }, ref) => {
  console.log("invoice", data);
  const { order, totalAmount, id, restaurant } = data;


  const shortOrderNumber = id.substr(id.length - 4).toUpperCase();

  const getOptionString = (row) => {
    if (!row || !row.options || row.options.length === 0) {
      return "";
    }
    const optionList = row.options.map((option) => {
      return `${option.name} x ${option.quantity} $${option.price}`;
    });
    return `/n ${optionList.join("/n")}`;
  };

  

  return (
    <div ref={ref} id="invoice" style={{padding: 16}}>
      <h1>Invoice</h1>
      {/* Display invoice data here */}
      {restaurant ? (
        <>
          <p>{restaurant.name}</p>
          <p>{restaurant.address}</p>
          <p>{restaurant.phone}</p>
          <p>ABN: {restaurant.abn}</p>
        </>
      ) : null}
      <br/>
      <p>Invoice Number: {shortOrderNumber}</p>
      <p>Date: {`${order.orderTime}`}</p>
      <Table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "0px solid black",
          padding: "5px 10px 5px 0",
        }}
      >
        <TableBody>
          {order.items.map((item) => {
            const subTotal = (item.subTotal * item.quantity)
              .toFixed(2)
              .toString();
            return (
              <TableRow>
                <TableCell
                  style={{ padding: "5px 10px 5px 0" }}
                  width="80%"
                  align="left"
                >
                  <Typography>
                    {item.dish.name}
                    {item.size ? `( ${item.size.name} )` : ""}
                    {item.dish.altName ? item.dish.altName : ""}x{" "}
                    {item.quantity} $
                    {item.size && item.size.price
                      ? item.size.price
                      : item.dish.price}
                    {getOptionString(item)}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ padding: "5px 0" }}
                  width="20%"
                  align="left"
                >
                  <Typography>${subTotal}</Typography>
                </TableCell>
              </TableRow>
            );
          })}

          {order.surcharges.map((item) => {
            return (
              <TableRow>
                <TableCell
                  style={{ padding: "5px 10px 5px 0" }}
                  width="80%"
                  align="left"
                >
                  <Typography>{item.name}</Typography>
                </TableCell>
                <TableCell
                  style={{ padding: "5px 0" }}
                  width="20%"
                  align="left"
                >
                  <Typography>${item.amount}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell
              style={{ padding: "5px 10px 5px 0" }}
              width="80%"
              align="left"
            >
              <Typography>Paid Amount</Typography>
            </TableCell>
            <TableCell style={{ padding: "5px 0" }} width="20%" align="left">
              <Typography>${(totalAmount / 100).toFixed(2)}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
});

const InvoicePage = (props) => {
  const classes = useStyles();
  const invoiceRef = useRef();

  // const handleDownloadPdf = async () => {
  //   const element = invoiceRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   let pdf = new JsPDF();
  //   pdf.addImage(data, "PNG", 0, 0);
  //   pdf.save("invoice.pdf");
  // };

  const handleDownloadPdf = async () => {
    if (invoiceRef.current) {
      const element = invoiceRef.current;

      // Adjust the scale for better quality or size
      const scale = 2; // Increase the scale for higher resolution

      const canvas = await html2canvas(element, {
        scale: scale,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");

      // Adjust PDF page size - you might need to experiment with these values
      const pdfWidth = canvas.width / scale;
      const pdfHeight = canvas.height / scale;

      const pdf = new JsPDF({
        orientation: pdfWidth > pdfHeight ? "l" : "p",
        unit: "pt",
        format: [pdfWidth, pdfHeight],
      });

      // Adjust image scaling and position
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice.pdf");
    } else {
      console.error("Element not available for conversion.");
    }
  };

  const restaurantId = props.match.params.restaurantId;
  const orderId = props.match.params.orderId;

  const {
    isLoading,
    isError,
    data: invoiceData,
  } = useQuery([`invoice-${orderId}`, restaurantId, orderId], () =>
    getInvoice(restaurantId, orderId)
  );
  // console.log("invoiceData", invoiceData);

  if (isError) {
    return (
      <Alert severity="error">{`Cannot get the invoice, Please try it later`}</Alert>
    );
  }

  if (isLoading) {
    return <CircularProgress size={150} className={classes.uiProgess} />;
  }

  return (
    <div className={classes.content}>
      {invoiceData && invoiceData.order ? (
        <div>
          <InvoiceComponent ref={invoiceRef} data={invoiceData} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadPdf}
          >
            Download as PDF
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default InvoicePage;
