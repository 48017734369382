import React, { useState, useEffect } from "react";
import {
  Switch,
  TextField,
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { useSelector, useDispatch } from "react-redux";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import { updateRestaurantValues } from "store/actions/restaurant";

const useStyles = makeStyles((theme) => ({
  root: {},
  progess: {
    position: "absolute",
  },
  bottomButtons: {
    marginTop: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1),
  },
}));

function Settings() {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [titleText, setTitleText] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const updateRestaurant = (values) => dispatch(updateRestaurantValues(values));

  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  useEffect(() => {
    if (!restaurant) return;
    if (restaurant && restaurant.onlineSettings) {
      const onlineSettings = restaurant.onlineSettings;
      if (
        onlineSettings.showDialog !== undefined &&
        onlineSettings.showDialog !== null
      ) {
        setShowDialog(onlineSettings.showDialog);
      }

      if (
        onlineSettings.dialogText !== undefined &&
        onlineSettings.dialogText !== null
      ) {
        setDialogText(onlineSettings.dialogText);
      }

      if (
        onlineSettings.dialogTitle !== undefined &&
        onlineSettings.dialogTitle !== null
      ) {
        setTitleText(onlineSettings.dialogTitle);
      }
    }
  }, [restaurant]);

  const handleToggleChange = () => {
    setShowDialog(!showDialog);
  };

  const handleUpdateText = (event) => {
    event.preventDefault();

  // check title length > 0 and < 50 
    if (titleText.length === 0 || titleText.length > 50) {
      showError("Title must be between 1 and 50 characters");
      return;
    }

    // check text length > 0 and < 200
    if (dialogText.length === 0 || dialogText.length > 200) {
      showError("Dialog text must be between 1 and 200 characters");
      return;
    }

  

    setButtonLoading(true);
    let onlineSettings = {
      showDialog: showDialog,
      dialogText: dialogText,
      dialogTitle: titleText,
    };

    if (restaurant && restaurant.onlineSettings) {
      onlineSettings = {
        ...restaurant.onlineSettings,
        ...onlineSettings,
      };
    }

    updateRestaurant({ onlineSettings });
    setButtonLoading(false);
  };

  return (
    <Card>
      <CardHeader title="Home Dialog Settings" />
      <CardContent>
        <div>
          <Switch checked={showDialog} onChange={handleToggleChange} />
          <label>Show Dialog</label>
        </div>

        <div>
          <TextField
            label="Dialog Title"
            variant="outlined"
            value={titleText}
            onChange={(e) => setTitleText(e.target.value)}
            fullWidth
          />
        </div>

        <div className={classes.bottomButtons}>
          <TextField
            label="Dialog Content"
            variant="outlined"
            multiline
            rows={4}
            value={dialogText}
            onChange={(e) => setDialogText(e.target.value)}
            fullWidth
          />
          <Button
            onClick={handleUpdateText}
            variant="outlined"
            disabled={buttonLoading}
            color="primary"
            className={classes.bottomButtons}
          >
            Update Dialog Settings
            {buttonLoading && (
              <CircularProgress size={30} className={classes.progess} />
            )}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default Settings;
