import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  useTheme,
} from '@material-ui/core';



const HomeDialog = ({ open, content, title, onClose }) => {
  const theme = useTheme();

  const buttonStyle = {
    color: theme.palette.type === 'dark' ? '#fff' : '#000',
    backgroundColor: '#dec05d',
    '&:hover': {
      backgroundColor: '#bca74d',
    },
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={buttonStyle} fullWidth>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HomeDialog;
