import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";

const initValues = [
    { type: "dinein", message: "" },
    { type: "takeaway", message: "" },
    { type: "foodcourt", message: "" },
  ];

const OrderCompleteMessage = ({ onValueChange, messages }) => {
  const [formValues, setFormValues] = useState(initValues);


    useEffect(() => {
        if (messages) {
            setFormValues(messages);
        }
    }, [messages]);
  

  const handleChangeOption = (e, index) => {
    const newFormValues = [...formValues];
    newFormValues[index].type = e.target.value;
    setFormValues(newFormValues);
    onValueChange(newFormValues);
  };

  const handleChangeText = (e, index) => {
    const newFormValues = [...formValues];
    newFormValues[index].message = e.target.value;
    setFormValues(newFormValues);
    onValueChange(newFormValues);
  };

  return (
    <div>
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        Order Completion Message
      </Typography>

      {formValues.map((field, index) => (
        <div key={index} style={{ margin: "20px 0" }}>
          <FormControl variant="outlined" style={{ marginRight: "20px" }}>
            <InputLabel id={`select-option-label-${index}`}>Type</InputLabel>
            <Select
              labelId={`select-option-label-${index}`}
              value={field.type}
              onChange={(e) => handleChangeOption(e, index)}
              label="Type"
            >
              <MenuItem value="dinein">
                Dine In Pay at Counter
              </MenuItem>
              <MenuItem value="takeaway">
                Takeaway Pay at Counter
              </MenuItem>
              <MenuItem value="foodcourt">
                Foodcourt Pay at Counter
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            label="Message"
            style={{ margin: "20px 0" }}
            multiline
            fullWidth
            rowsMax={4}
            inputProps={{ maxLength: 150 }}
            helperText={`${field.message.length}/150`}
            value={field.message}
            onChange={(e) => handleChangeText(e, index)}
          />
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default OrderCompleteMessage;
